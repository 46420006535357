<div class="main">
    <i class="fa fa-times close" (click)="close()" aria-hidden="true"></i>
    <div class="succes-msg">
        <i *ngIf="notification?.errorType === 'success'" class="fa fa-check " aria-hidden="true"></i>
        <i *ngIf="notification?.errorType === 'error'" class="fa fa-times" aria-hidden="true"></i>
        <i *ngIf="notification?.errorType === 'warning'" class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        <h2>{{notification?.message}}</h2>
    </div>
</div>



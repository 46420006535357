<div class="profilesetup_wrap">
  <div class="signupinner">
    <div class="conta_iner">
      <div class="flex">
        <div class="haeder_left">
          <figure class="logo_cm" (click)="goTouhomepage()">
            <img src="assets/uimages/logo.png" />
          </figure>
        </div>
        <div class="multiple_content">
          <div class="lm" style="margin-right: 16px">
            <div class="language-select" (click)="showLang = showLang ? false : true">
              <div class="sel-bar" *ngIf="languageService?.selectedLang">
                <figure>
                  <img *ngIf="languageService?.selectedLang?.logo" [src]="baseUrl + languageService?.selectedLang?.logo" />{{
                    languageService?.selectedLang?.code?.toUpperCase()
                  }}
                </figure>
              </div>
              <p *ngIf="!languageService?.selectedLang">Select Language</p>
              <div class="ndpicon">
                <i *ngIf="!showLang" class="fa fa-caret-down" aria-hidden="true"></i>
                <i *ngIf="showLang" class="fa fa-caret-up" aria-hidden="true"></i>
              </div>
            </div>
            <div class="dp-lang" *ngIf="showLang">
              <figure *ngFor="let language of languageService?.allLanguages" (click)="selectLang(language)">
                <img *ngIf="language?.logo" [src]="baseUrl + language?.logo" />{{ language?.code?.toUpperCase() }}
              </figure>
            </div>
          </div>
          <div class="back_home hmbtn">
            <button class="driverap_btn" style="cursor: pointer" (click)="goTouhomepage()">
              {{ "Back To Home" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="conta_iner">
      <div class="profile_main">
        <div class="toggleprofile sides" (click)="toggle()" id="bt">
          <svg [class]="'ham hamRotate ham1 ' + (tgshow ? '' : 'active')" viewBox="0 0 100 100" width="80" onclick="this.classList.toggle('active')">
            <path
              class="line top"
              d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"
            />
            <path class="line middle" d="m 30,50 h 40" />
            <path
              class="line bottom"
              d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"
            />
          </svg>
        </div>
        <div class="prfile_left bg sides2" [ngClass]="tgshow ? 'hdiv' : 'sdiv'">
          <ul class="nav nav-justified nav-tabs" id="justifiedTab" role="tablist">
            <li class="first" (click)="continue(0)">
              <a aria-controls="home" aria-selected="true" class="nav-link active" data-toggle="tab" href="#profile" id="home-tab" role="tab">
                <div class="user_profile">
                  <div class="user_profile_img">
                    <figure>
                      <img *ngIf="commonService?.userUpdateImage" [src]="baseUrl + commonService?.userUpdateImage" />
                      <img *ngIf="!commonService?.userUpdateImage" src="assets/images/user.png" />
                    </figure>
                  </div>
                  <div class="profile_camera1">
                    <h4>{{ commonService?.loggedInUser?.firstName + " " + commonService?.loggedInUser?.lastName }}</h4>
                    <div class="driver_rating mrgn-l">
                      <figure>
                        <img src="assets/uimages/rate_icon.svg" alt="" />
                      </figure>
                      <p class="avg-rtng">{{ commonService?.loggedInUser?.avgRating }}</p>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          </ul>

          <ul class="ul2">
            <li
              class="nav-item"
              *ngFor="let data of linkArr; let i = index"
              (click)="i === linkArr?.length - 2 ? Alert(i, 'Are you sure you want to logout?', 'logout') : toggleClick(i, data)"
            >
              <a [ngClass]="{ 'nav-link active': selectedItem === i, 'nav-link': selectedItem != i }">{{ data?.name }}</a>
            </li>
          </ul>
        </div>
        <div class="profile_right">
          <div class="tab-content" id="justifiedTabContent">
            <div *ngIf="nextPopup === 0">
              <div class="profile_content">
                <form [formGroup]="profileForm">
                  <div class="user_profile">
                    <div class="user_profile_img">
                      <figure>
                        <img *ngIf="!commonService?.userUpdateImage" src="assets/images/user.png" />
                        <img *ngIf="commonService?.userUpdateImage" [src]="baseUrl + commonService?.userUpdateImage" />
                      </figure>
                    </div>
                    <div class="profile_camera" *ngIf="editable">
                      <figure>
                        <img src="assets/images/editpencil.svg" />
                      </figure>
                      <input type="file" #fileUpload formControlName="proImage" (change)="onFileUpload($event)" id="file-input" class="upload_file" />
                    </div>
                  </div>
                  <ul class="formn_group_main">
                    <li>
                      <label>{{ "First name" | translate }}</label>
                      <div class="form-group">
                        <mat-form-field [floatLabel]="'never'" *ngIf="editable">
                          <input
                            matInput
                            class="form-control"
                            maxlength="50"
                            (change)="onChangeFirst($event.target.value)"
                            (keypress)="onKeyPress($event)"
                            formControlName="firstName"
                            type="text"
                            placeholder="First name"
                            name="name"
                          />
                          <mat-error *ngIf="submitted && validateFirstArr.includes(true) && !profileForm.controls['firstName'].hasError('required')">
                            {{ "Please enter valid first name" | translate }}.</mat-error
                          >
                          <mat-error *ngIf="submitted && profileForm.controls['firstName'].hasError('required')">
                            {{ "First name is required" | translate }}.</mat-error
                          >
                        </mat-form-field>
                        <span *ngIf="!editable" class="form-control">{{ commonService?.loggedInUser?.firstName }}</span>
                      </div>
                    </li>
                    <li>
                      <label>{{ "Last name" | translate }}</label>
                      <div class="form-group">
                        <mat-form-field [floatLabel]="'never'" *ngIf="editable">
                          <input
                            matInput
                            class="form-control"
                            maxlength="50"
                            (change)="onChangeSecond($event.target.value)"
                            (keypress)="onKeyPress($event)"
                            formControlName="lastName"
                            type="text"
                            placeholder="Last name"
                            name="name"
                          />
                          <mat-error *ngIf="submitted && validateSecondArr.includes(true) && !profileForm.controls['lastName'].hasError('required')">
                            {{ "Please enter valid last name" | translate }}.</mat-error
                          >
                          <mat-error *ngIf="submitted && profileForm.controls['lastName'].hasError('required')">
                            {{ "Last name is required" | translate }}.</mat-error
                          >
                        </mat-form-field>
                        <span *ngIf="!editable" class="form-control">{{ commonService?.loggedInUser?.lastName }}</span>
                      </div>
                    </li>
                    <!-- <li>
                      <label>Gender</label>
                      <div class="form-group">
                        <mat-form-field *ngIf="editable">
                          <mat-label class="grey_color">Gender</mat-label>
                          <mat-select [(value)]="selected" formControlName="gender" class="form-control">
                            <mat-option value="MALE">Male</mat-option>
                            <mat-option value="FEMALE">Female</mat-option>
                            <mat-option value="NO_PREFRENCE">Other</mat-option>
                          </mat-select>
                          <mat-error *ngIf="submitted && profileForm.controls['gender'].hasError('required')">Gender is required.</mat-error>
                        </mat-form-field>
                        <span *ngIf="!editable" class="form-control">{{user?.gender}}</span>
                      </div>
                    </li> -->
                    <li>
                      <label>{{ "Email address" | translate }}</label>
                      <span class="vrfd second" *ngIf="isEamilVerified === true"
                        ><i class="fa fa-check-circle" aria-hidden="true"></i> {{ "Verified" | translate }}</span
                      >
                      <span class="unvrfd" *ngIf="isEamilVerified === false && !editable"
                        ><i class="fa fa-exclamation-circle" aria-hidden="true"></i> {{ "Verify Now" | translate }}</span
                      >
                      <span class="unvrfd" style="cursor: pointer" (click)="verifyEmail()" *ngIf="isEamilVerified === false && editable"
                        ><i class="fa fa-exclamation-circle" aria-hidden="true"></i> {{ "Verify Now" | translate }}</span
                      >
                      <div class="form-group emailWd">
                        <mat-form-field [floatLabel]="'never'" *ngIf="editable">
                          <input
                            class="form-control emailW"
                            matInput
                            formControlName="email"
                            type="email"
                            placeholder="{{ 'Email address' | translate }}"
                            name="name"
                          />
                          <mat-error class="sorry-error" *ngIf="submitted && profileForm.controls['email'].hasError('pattern')">
                            {{ "Sorry, only letters (a-z), number (0-9), and periods (.) are allowed" | translate }}.
                          </mat-error>
                          <mat-error *ngIf="submitted && profileForm.controls['email'].hasError('required')">
                            {{ "Email address is required" | translate }}.</mat-error
                          >
                        </mat-form-field>
                        <mat-error
                          *ngIf="
                            submitted &&
                            isEmailExist &&
                            !profileForm.controls['email'].hasError('required') &&
                            !profileForm.controls['email'].hasError('pattern')
                          "
                        >
                          {{ "Email already exist" | translate }}.</mat-error
                        >
                        <mat-error
                          *ngIf="
                            submitted &&
                            !isEmailExist &&
                            !isEamilVerified &&
                            !profileForm.controls['email'].hasError('required') &&
                            !profileForm.controls['email'].hasError('pattern')
                          "
                        >
                          {{ "Please verify your email" | translate }}.</mat-error
                        >
                        <span *ngIf="!editable" class="form-control">{{ commonService?.loggedInUser?.email }}</span>
                      </div>
                    </li>
                    <li>
                      <label>{{ "Phone number" | translate }}</label>
                      <span class="vrfd" *ngIf="isPhoneVerified === true"
                        ><i class="fa fa-check-circle" aria-hidden="true"></i> {{ "Verified" | translate }}</span
                      >
                      <span class="unvrfd" *ngIf="isPhoneVerified === false && !editable"
                        ><i class="fa fa-exclamation-circle" aria-hidden="true"></i> {{ "Verify Now" | translate }}</span
                      >
                      <span class="unvrfd" style="cursor: pointer" (click)="verifyPhone()" *ngIf="isPhoneVerified === false && editable"
                        ><i class="fa fa-exclamation-circle" aria-hidden="true"></i> {{ "Verify Now" | translate }}</span
                      >
                      <div class="form-group phoneNo">
                        <div *ngIf="editable">
                          <!-- <mat-form-field [floatLabel]="'never'" > -->
                          <ngx-intl-tel-input
                            class="form-control"
                            [inputId]="'adsasd'"
                            [cssClass]="'custom'"
                            [preferredCountries]="preferredCountries"
                            [enableAutoCountrySelect]="false"
                            [enablePlaceholder]="true"
                            [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                            [selectFirstCountry]="false"
                            [enableAutoCountrySelect]="true"
                            [selectedCountryISO]="CountryISO.Canada"
                            [maxLength]="15"
                            [tooltipField]="TooltipLabel.Name"
                            [phoneValidation]="true"
                            [separateDialCode]="separateDialCode"
                            name="phone"
                            formControlName="phoneNo"
                          >
                          </ngx-intl-tel-input>
                          <mat-error *ngIf="submitted && profileForm.controls['phoneNo'].hasError('required')">
                            {{ "Phone number is required" | translate }}.</mat-error
                          >
                          <mat-error *ngIf="submitted && profileForm.controls['phoneNo'].hasError('validatePhoneNumber')">
                            {{ "Please enter valid phone number" | translate }}.</mat-error
                          >
                          <mat-error
                            *ngIf="
                              submitted &&
                              isPhoneExist &&
                              !profileForm.controls['phoneNo'].hasError('validatePhoneNumber') &&
                              !profileForm.controls['phoneNo'].hasError('required')
                            "
                          >
                            {{ "Phone number already exist" | translate }}.</mat-error
                          >
                          <mat-error
                            *ngIf="
                              submitted &&
                              !isPhoneExist &&
                              !isPhoneVerified &&
                              !profileForm.controls['phoneNo'].hasError('required') &&
                              !profileForm.controls['phoneNo'].hasError('validatePhoneNumber')
                            "
                          >
                            {{ "Please verify your phone number" | translate }}.</mat-error
                          >
                          <!-- <input class="form-control" matInput formControlName="phoneNo" type="number"
                            placeholder="Phone number" name="name" />
                          <mat-error *ngIf="submitted && profileForm.controls['phoneNo'].hasError('required')">Phone number is required.</mat-error>
                          <mat-error *ngIf="submitted && profileForm.controls['phoneNo'].hasError('minLength')">Please enter valid number.</mat-error> -->
                          <!-- </mat-form-field> -->
                        </div>
                        <span *ngIf="!editable" class="form-control">{{
                          commonService?.loggedInUser?.countryCode + "
                          " + commonService?.loggedInUser?.phoneNo
                        }}</span>
                      </div>
                    </li>
                  </ul>
                  <div class="commen_btn_outer">
                    <button class="btn btn-primary" *ngIf="!editable" (click)="edit()">{{ "Edit" | translate }}</button>
                    <button class="btn btn-primary" *ngIf="editable" (click)="verification()">{{ "Update" | translate }}</button>
                    <button class="btn btn-default canc-btn" *ngIf="editable" (click)="cancel()">{{ "Cancel" | translate }}</button>
                  </div>
                </form>
              </div>
            </div>
            <div *ngIf="nextPopup === 1">
              <div class="orderdetail_content">
                <mat-tab-group (selectedTabChange)="onTabChange('RIDE', $event.index)">
                  <mat-tab [label]="'Ongoing' | translate">
                    <div *ngIf="selectedTabIndex === 0 && bookingDataUpcomingRide.length > 0">
                      <ul class="order_detail">
                        <li type="" class="btn-demo" *ngFor="let upcoming of bookingDataUpcomingRide">
                          <!-- <div>
                            <p (click)="cancelBooking(upcoming)">Cencel</p>
                          </div> -->
                          <div class="order_detail_conten flex borderbtm">
                            <div
                              class="order_detail_left"
                              *ngIf="
                                upcoming?.bookingStatus === constant?.BOOKING_STATUS?.ACCEPTED ||
                                upcoming?.bookingStatus === constant?.BOOKING_STATUS?.ARRIVED ||
                                upcoming?.bookingStatus === constant?.BOOKING_STATUS?.STARTED ||
                                upcoming?.bookingStatus === constant?.BOOKING_STATUS?.PARTIAL ||
                                upcoming?.bookingStatus === constant?.BOOKING_STATUS?.TRANSIT ||
                                upcoming?.bookingStatus === constant?.BOOKING_STATUS?.ONGOING
                              "
                            >
                              <figure *ngIf="upcoming?.bookingStatus != constant?.BOOKING_STATUS?.PENDING">
                                <img *ngIf="!upcoming?.driverId?.image" src="assets/images/user.png" />
                                <img *ngIf="upcoming?.driverId?.image" [src]="baseUrl + upcoming?.driverId?.image" />
                              </figure>
                            </div>
                            <div class="order_detail_right">
                              <!-- //abc -->
                              <div
                                *ngIf="
                                  upcoming?.bookingStatus === constant?.BOOKING_STATUS?.ACCEPTED ||
                                  upcoming?.bookingStatus === constant?.BOOKING_STATUS?.ARRIVED ||
                                  upcoming?.bookingStatus === constant?.BOOKING_STATUS?.STARTED ||
                                  upcoming?.bookingStatus === constant?.BOOKING_STATUS?.PARTIAL ||
                                  upcoming?.bookingStatus === constant?.BOOKING_STATUS?.TRANSIT ||
                                  upcoming?.bookingStatus === constant?.BOOKING_STATUS?.ONGOING
                                "
                              >
                                <h4>{{ upcoming?.driverId?.firstName }} {{ upcoming?.driverId?.lastName }}</h4>
                                <!-- <div class="driver_rating">
                                  <figure>
                                    <img src="assets/uimages/rate_icon.svg" alt="" />
                                  </figure>
                                  <p>{{upcoming?.driverId?.avgRating}}</p>
                                </div> -->
                                <div class="driver_payment_detail">
                                  <div class="driver_payment_item">
                                    <p>
                                      {{ "Grand Total" | translate }} <span>$ {{ upcoming?.riderFare }}</span>
                                    </p>
                                  </div>
                                  <div class="driver_payment_item">
                                    <p>
                                      {{ "Date/Time" | translate }}<span>{{ upcoming?.bookingDate | date: "d/MM/yy, h:mm a" }}</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                *ngIf="
                                  upcoming?.bookingStatus === constant?.BOOKING_STATUS?.PENDING ||
                                  upcoming?.bookingStatus === constant?.BOOKING_STATUS?.SCHEDULED ||
                                  upcoming?.bookingStatus === constant?.BOOKING_STATUS?.UPCOMING
                                "
                              >
                                <h4>{{ "Driver not assigned" | translate }}.</h4>
                              </div>
                            </div>
                            <!-- <p class="complete" *ngIf="upcoming?.bookingStatus === constant?.BOOKING_STATUS?.STARTED">
                              ONGOING</p> -->
                            <!-- <p class="complete"
                              *ngIf="upcoming?.bookingStatus !== constant?.BOOKING_STATUS?.STARTED && upcoming?.bookingStatus !== constant?.BOOKING_STATUS?.PENDING">
                              {{constant?.SHOW_STATUS_BOOKING[upcoming?.bookingStatus]}}</p> -->
                            <div class="cancel-box">
                              <p class="complete-status" style="color: black">{{ constant?.SHOW_STATUS_BOOKING[upcoming?.bookingStatus] }}</p>
                              <p
                                class="complete-status"
                                style="cursor: pointer"
                                (click)="cancelBooking(upcoming, 'RIDE', 'totalPagesUpcomingRide', 'currentPageUpcomingRide', 3)"
                              >
                                {{ "CANCEL" | translate }}
                              </p>
                            </div>
                          </div>
                          <div class="order_detail_conten flex" style="cursor: pointer">
                            <div class="order_detail_left">
                              <div class="overlay_bg">
                                <figure class="">
                                  <img *ngIf="!upcoming?.driverId?.image" src="assets/images/user.png" />
                                  <img *ngIf="upcoming?.driverId?.image" [src]="baseUrl + upcoming?.driverId?.image" />
                                </figure>
                              </div>
                              <div class="location_white">
                                <figure class="location_white">
                                  <img src="assets/images/location_white.svg" />
                                </figure>
                              </div>
                            </div>
                            <div (click)="openBookingStatusModal(upcoming, 'RIDE')" class="view-booking-dtl">
                              <p>{{ "View Booking Details" | translate }}</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <nav aria-label="..." class="pagination">
                        <ul class="pagination justify-content-end mb-0">
                          <li class="{{ previousBtnEnabled ? 'page-item' : 'page-item disabled' }}">
                            <a
                              class="page-link"
                              href="javascript:void(0)"
                              (click)="onPrevPage('RIDE', 'totalPagesUpcomingRide', 'currentPageUpcomingRide', 3)"
                            >
                              <i></i>
                              <span>{{ "Previous" | translate }}</span>
                            </a>
                          </li>
                          <li
                            *ngFor="let a of [].constructor(totalPagesUpcomingRide); let i = index"
                            class="{{ currentPageUpcomingRide === i + 1 ? 'page-item active' : 'page-item' }}"
                          >
                            <a
                              class="page-link"
                              *ngIf="currentPageUpcomingRide < i + 1 ? i + 1 - currentPageUpcomingRide < 3 : currentPageUpcomingRide - (i + 1) < 3"
                              href="javascript:void(0)"
                              (click)="onChnagePage(i + 1, 'RIDE', 'totalPagesUpcomingRide', 'currentPageUpcomingRide', 3)"
                              >{{ i + 1 }}</a
                            >
                          </li>
                          <li class="{{ nextBtnEnabled ? 'page-item' : 'page-item disabled' }}">
                            <a
                              class="page-link"
                              href="javascript:void(0)"
                              (click)="onNextPage('RIDE', 'totalPagesUpcomingRide', 'currentPageUpcomingRide', 3)"
                            >
                              <i></i>
                              <span>{{ "Next" | translate }}</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div *ngIf="bookingDataUpcomingRide.length === 0" class="del-main">
                      <h3>{{ "No data found!" | translate }}</h3>
                    </div>
                  </mat-tab>

                  <mat-tab [label]="'Completed' | translate">
                    <div *ngIf="selectedTabIndex === 1 && bookingDataCompletedRide.length > 0">
                      <ul class="order_detail">
                        <li type="" class="btn-demo" *ngFor="let completed of bookingDataCompletedRide">
                          <div class="order_detail_conten flex borderbtm">
                            <div class="order_detail_left">
                              <figure>
                                <img *ngIf="!completed?.driverId?.image" src="assets/images/user.png" />
                                <img *ngIf="completed?.driverId?.image" [src]="baseUrl + completed?.driverId?.image" />
                              </figure>
                            </div>
                            <div class="order_detail_right flex">
                              <div class="">
                                <h4>{{ completed?.driverId?.firstName + " " + completed?.driverId?.lastName }}</h4>
                                <ul class="flex orders_star">
                                  <li *ngIf="completed?.driverRating > 0">
                                    <i class="fa fa-star" aria-hidden="true"
                                      ><span class="avg-rtng">{{ completed?.driverRating }}</span></i
                                    >
                                  </li>
                                </ul>
                                <h4 class="blue_color">{{ completed?.vehicleId?.vehicleModel }} - {{ completed?.vehicleId?.licensePlateNumber }}</h4>
                                <div class="driver_payment_detail">
                                  <div class="driver_payment_item">
                                    <p>
                                      {{ "Grand Total" | translate }}
                                      <span> {{ (completed?.riderFare + completed?.tip)?.toFixed(2) | currency: "USD" }}</span>
                                    </p>
                                  </div>
                                  <div class="driver_payment_item">
                                    <p>
                                      {{ "Date/Time" | translate }}<span>{{ completed?.bookingDate | date: "d/MM/yy, h:mm a" }}</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <button *ngIf="completed?.driverRating === 0" class="complete rate_now" (click)="openRatingModal(completed)">
                                {{ "RATE NOW" | translate }}
                              </button>
                              <button class="complete">{{ "COMPLETED" | translate }}</button>
                            </div>
                          </div>
                          <div class="order_detail_conten flex">
                            <div class="order_detail_left">
                              <div class="overlay_bg">
                                <figure class="">
                                  <img *ngIf="!completed?.driverId?.image" src="assets/images/user.png" />
                                  <img *ngIf="completed?.driverId?.image" [src]="baseUrl + completed?.driverId?.image" />
                                </figure>
                              </div>
                              <div class="location_white">
                                <figure class="location_white">
                                  <img src="assets/images/location_white.svg" />
                                </figure>
                              </div>
                            </div>
                            <div (click)="openBookingStatusModal(completed, 'RIDE')" class="view-booking-dtl">
                              <p>{{ "View Booking Details" | translate }}</p>
                            </div>
                            <!-- <div class="order_detail_right">
                              <div class="start_trip_text">
                                <div class="mm">
                                  <div class="blm">
                                    <div class="bb"></div>
                                  </div>
                                  <div class="mkl">
                                    <p>{{completed?.pickUpAddress}}</p>
                                  </div>
                                </div>

                                <div class="mm">
                                  <div class="blm">
                                    <div class="blc"></div>
                                  </div>
                                  <div class="mkl">
                                    <p>{{completed?.dropUpAddress}}</p>
                                  </div>
                                </div>
                              </div>
                            </div> -->
                          </div>
                        </li>
                      </ul>
                      <nav aria-label="..." class="pagination">
                        <ul class="pagination justify-content-end mb-0">
                          <li class="{{ previousBtnEnabled ? 'page-item' : 'page-item disabled' }}">
                            <a
                              class="page-link"
                              href="javascript:void(0)"
                              (click)="onPrevPage('RIDE', 'totalPagesCompletedRide', 'currentPageCompletedRide', 1)"
                            >
                              <i></i>
                              <span>{{ "Previous" | translate }}</span>
                            </a>
                          </li>
                          <li
                            *ngFor="let a of [].constructor(totalPagesCompletedRide); let i = index"
                            class="{{ currentPageCompletedRide === i + 1 ? 'page-item active' : 'page-item' }}"
                          >
                            <a
                              class="page-link"
                              *ngIf="currentPageCompletedRide < i + 1 ? i + 1 - currentPageCompletedRide < 3 : currentPageCompletedRide - (i + 1) < 3"
                              href="javascript:void(0)"
                              (click)="onChnagePage(i + 1, 'RIDE', 'totalPagesCompletedRide', 'currentPageCompletedRide', 1)"
                              >{{ i + 1 }}</a
                            >
                          </li>
                          <li class="{{ nextBtnEnabled ? 'page-item' : 'page-item disabled' }}">
                            <a
                              class="page-link"
                              href="javascript:void(0)"
                              (click)="onNextPage('RIDE', 'totalPagesCompletedRide', 'currentPageCompletedRide', 1)"
                            >
                              <i></i>
                              <span>{{ "Next" | translate }}</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div *ngIf="bookingDataCompletedRide.length === 0" class="del-main">
                      <h3>{{ "No data found!" | translate }}</h3>
                    </div>
                  </mat-tab>
                  <mat-tab [label]="'Canceled' | translate">
                    <div *ngIf="selectedTabIndex === 2 && bookingDataCanceledRide.length > 0">
                      <ul class="order_detail">
                        <li type="" class="btn-demo" *ngFor="let calceled of bookingDataCanceledRide">
                          <div class="order_detail_conten flex borderbtm">
                            <div class="order_detail_left" *ngIf="calceled.driverId">
                              <figure>
                                <img *ngIf="!calceled?.driverId?.image" src="assets/images/user.png" />
                                <img *ngIf="calceled?.driverId?.image" [src]="baseUrl + calceled?.driverId?.image" />
                              </figure>
                            </div>
                            <div class="order_detail_right flex">
                              <div class="" *ngIf="calceled.driverId">
                                <h4>{{ calceled?.driverId?.firstName + " " + calceled?.driverId?.lastName }}</h4>
                                <ul class="flex orders_star">
                                  <li *ngIf="calceled?.driverRating > 0">
                                    <i class="fa fa-star" aria-hidden="true"
                                      ><span class="avg-rtng">{{ calceled?.driverRating }}</span></i
                                    >
                                  </li>
                                </ul>
                                <h4 class="blue_color">{{ calceled?.vehicleId?.vehicleModel }} - {{ calceled?.vehicleId?.licensePlateNumber }}</h4>
                                <div class="driver_payment_detail">
                                  <div class="driver_payment_item">
                                    <p>
                                      {{ "Cancellation Fee" | translate }}
                                      <span>{{ calceled?.cancelAmount?.toFixed(2) | currency: "USD" }}</span>
                                    </p>
                                  </div>
                                  <div class="driver_payment_item">
                                    <p>
                                      {{ "Date/Time" | translate }}<span>{{ calceled?.bookingDate | date: "d/MM/yy, h:mm a" }}</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div *ngIf="!calceled.driverId">
                                <h4>{{ "Driver not assigned" | translate }}.</h4>
                              </div>
                              <button
                                *ngIf="calceled.driverId && calceled?.driverRating === 0"
                                class="complete rate_now"
                                (click)="openRatingModal(calceled)"
                              >
                                {{ "RATE NOW" | translate }}
                              </button>
                              <button class="cancel">{{ "CANCELED" | translate }}</button>
                            </div>
                          </div>
                          <div class="order_detail_conten flex">
                            <div class="order_detail_left">
                              <div class="overlay_bg">
                                <figure class="">
                                  <img *ngIf="!calceled?.driverId?.image" src="assets/images/user.png" />
                                  <img *ngIf="calceled?.driverId?.image" [src]="baseUrl + calceled?.driverId?.image" />
                                </figure>
                              </div>
                              <div class="location_white">
                                <figure class="location_white">
                                  <img src="assets/images/location_white.svg" />
                                </figure>
                              </div>
                            </div>
                            <div (click)="openBookingStatusModal(calceled, 'RIDE')" class="view-booking-dtl">
                              <p>{{ "View Booking Details" | translate }}</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <nav aria-label="..." class="pagination">
                        <ul class="pagination justify-content-end mb-0">
                          <li class="{{ previousBtnEnabled ? 'page-item' : 'page-item disabled' }}">
                            <a
                              class="page-link"
                              href="javascript:void(0)"
                              (click)="onPrevPage('RIDE', 'totalPagesCanceledRide', 'currentPageCanceledRide', 2)"
                            >
                              <i></i>
                              <span>{{ "Previous" | translate }}</span>
                            </a>
                          </li>
                          <li
                            *ngFor="let a of [].constructor(totalPagesCanceledRide); let i = index"
                            class="{{ currentPageCanceledRide === i + 1 ? 'page-item active' : 'page-item' }}"
                          >
                            <a
                              class="page-link"
                              *ngIf="currentPageCanceledRide < i + 1 ? i + 1 - currentPageCanceledRide < 3 : currentPageCanceledRide - (i + 1) < 3"
                              href="javascript:void(0)"
                              (click)="onChnagePage(i + 1, 'RIDE', 'totalPagesCanceledRide', 'currentPageCanceledRide', 2)"
                              >{{ i + 1 }}</a
                            >
                          </li>
                          <li class="{{ nextBtnEnabled ? 'page-item' : 'page-item disabled' }}">
                            <a
                              class="page-link"
                              href="javascript:void(0)"
                              (click)="onNextPage('RIDE', 'totalPagesCanceledRide', 'currentPageCanceledRide', 2)"
                            >
                              <i></i>
                              <span>{{ "Next" | translate }}</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div *ngIf="bookingDataCanceledRide.length === 0" class="del-main">
                      <h3>{{ "No data found!" | translate }}</h3>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>

            <div *ngIf="nextPopup === 2">
              <div class="orderdetail_content">
                <mat-tab-group (selectedTabChange)="onTabChange('DELIVERY', $event.index)">
                  <mat-tab [label]="'Ongoing' | translate">
                    <div *ngIf="selectedTabIndex === 0 && bookingDataUpcomingDelivery.length > 0">
                      <ul class="order_detail">
                        <li type="" class="btn-demo" *ngFor="let upcoming of bookingDataUpcomingDelivery">
                          <div class="order_detail_conten flex borderbtm">
                            <div
                              class="order_detail_left"
                              *ngIf="
                                upcoming?.bookingStatus === constant?.BOOKING_STATUS?.ACCEPTED ||
                                upcoming?.bookingStatus === constant?.BOOKING_STATUS?.ARRIVED ||
                                upcoming?.bookingStatus === constant?.BOOKING_STATUS?.STARTED ||
                                upcoming?.bookingStatus === constant?.BOOKING_STATUS?.PARTIAL ||
                                upcoming?.bookingStatus === constant?.BOOKING_STATUS?.ONGOING
                              "
                            >
                              <figure *ngIf="upcoming?.bookingStatus != constant?.BOOKING_STATUS?.PENDING">
                                <img *ngIf="!upcoming?.driverId?.image" src="assets/images/user.png" />
                                <img *ngIf="upcoming?.driverId?.image" [src]="baseUrl + upcoming?.driverId?.image" />
                              </figure>
                            </div>
                            <div class="order_detail_right">
                              <!-- //abc -->
                              <div
                                *ngIf="
                                  upcoming?.bookingStatus === constant?.BOOKING_STATUS?.ACCEPTED ||
                                  upcoming?.bookingStatus === constant?.BOOKING_STATUS?.ARRIVED ||
                                  upcoming?.bookingStatus === constant?.BOOKING_STATUS?.STARTED ||
                                  upcoming?.bookingStatus === constant?.BOOKING_STATUS?.PARTIAL ||
                                  upcoming?.bookingStatus === constant?.BOOKING_STATUS?.ONGOING
                                "
                              >
                                <h4>{{ upcoming?.driverId?.firstName }} {{ upcoming?.driverId?.lastName }}</h4>
                                <!-- <div class="driver_rating">
                                  <figure>
                                    <img src="assets/uimages/rate_icon.svg" alt="" />
                                  </figure>
                                  <p>{{upcoming?.driverId?.avgRating}}</p>
                                </div> -->
                                <div class="driver_payment_detail">
                                  <div class="driver_payment_item">
                                    <p>
                                      {{ "Grand Total" | translate }} <span>$ {{ upcoming?.booKingAmount }}</span>
                                    </p>
                                  </div>
                                  <div class="driver_payment_item">
                                    <p>
                                      {{ "Date/Time" | translate }}<span>{{ upcoming?.bookingDate | date: "d/MM/yy, h:mm a" }}</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                *ngIf="
                                  upcoming?.bookingStatus === constant?.BOOKING_STATUS?.PENDING ||
                                  upcoming?.bookingStatus === constant?.BOOKING_STATUS?.SCHEDULED ||
                                  upcoming?.bookingStatus === constant?.BOOKING_STATUS?.UPCOMING
                                "
                              >
                                <h4>{{ "Driver not assigned" | translate }}.</h4>
                              </div>
                            </div>
                            <!-- <p class="complete" *ngIf="upcoming?.bookingStatus === constant?.BOOKING_STATUS?.STARTED">
                              ONGOING</p> -->
                            <!-- <p class="complete"
                              *ngIf="upcoming?.bookingStatus !== constant?.BOOKING_STATUS?.STARTED && upcoming?.bookingStatus !== constant?.BOOKING_STATUS?.PENDING">
                              {{constant?.SHOW_STATUS_BOOKING[upcoming?.bookingStatus]}}</p> -->
                            <div class="cancel-box">
                              <p class="complete-status" style="color: black">{{ constant?.SHOW_STATUS_BOOKING[upcoming?.bookingStatus] }}</p>
                              <p
                                class="complete-status"
                                style="cursor: pointer"
                                (click)="cancelBooking(upcoming, 'DELIVERY', 'totalPagesUpcomingDelivery', 'currentPageUpcomingDelivery', 3)"
                              >
                                {{ "CANCEL" | translate }}
                              </p>
                            </div>
                          </div>
                          <div class="order_detail_conten flex" style="cursor: pointer">
                            <div class="order_detail_left">
                              <div class="overlay_bg">
                                <figure class="">
                                  <img *ngIf="!upcoming?.driverId?.image" src="assets/images/user.png" />
                                  <img *ngIf="upcoming?.driverId?.image" [src]="baseUrl + upcoming?.driverId?.image" />
                                </figure>
                              </div>
                              <div class="location_white">
                                <figure class="location_white">
                                  <img src="assets/images/location_white.svg" />
                                </figure>
                              </div>
                            </div>
                            <div (click)="openBookingStatusModal(upcoming, 'DELIVERY')" class="view-booking-dtl">
                              <p>{{ "View Booking Details" | translate }}</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <nav aria-label="..." class="pagination">
                        <ul class="pagination justify-content-end mb-0">
                          <li class="{{ previousBtnEnabled ? 'page-item' : 'page-item disabled' }}">
                            <a
                              class="page-link"
                              href="javascript:void(0)"
                              (click)="onPrevPage('DELIVERY', 'totalPagesUpcomingDelivery', 'currentPageUpcomingDelivery', 3)"
                            >
                              <i></i>
                              <span>{{ "Previous" | translate }}</span>
                            </a>
                          </li>
                          <li
                            *ngFor="let a of [].constructor(totalPagesUpcomingDelivery); let i = index"
                            class="{{ currentPageUpcomingDelivery === i + 1 ? 'page-item active' : 'page-item' }}"
                          >
                            <a
                              class="page-link"
                              *ngIf="
                                currentPageUpcomingDelivery < i + 1
                                  ? i + 1 - currentPageUpcomingDelivery < 3
                                  : currentPageUpcomingDelivery - (i + 1) < 3
                              "
                              href="javascript:void(0)"
                              (click)="onChnagePage(i + 1, 'Delivery', 'totalPagesUpcomingDelivery', 'currentPageUpcomingDelivery', 3)"
                              >{{ i + 1 }}</a
                            >
                          </li>
                          <li class="{{ nextBtnEnabled ? 'page-item' : 'page-item disabled' }}">
                            <a
                              class="page-link"
                              href="javascript:void(0)"
                              (click)="onNextPage('DELIVERY', 'totalPagesUpcomingDelivery', 'currentPageUpcomingDelivery', 3)"
                            >
                              <i></i>
                              <span>{{ "Next" | translate }}</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div *ngIf="bookingDataUpcomingDelivery.length === 0" class="del-main">
                      <h3>{{ "No data found!" | translate }}</h3>
                    </div>
                  </mat-tab>

                  <mat-tab [label]="'Completed' | translate">
                    <div *ngIf="selectedTabIndex === 1 && bookingDataCompletedDelivery.length > 0">
                      <ul class="order_detail">
                        <li type="" class="btn-demo" *ngFor="let completed of bookingDataCompletedDelivery">
                          <div class="order_detail_conten flex borderbtm">
                            <div class="order_detail_left">
                              <figure>
                                <img *ngIf="!completed?.driverId?.image" src="assets/images/user.png" />
                                <img *ngIf="completed?.driverId?.image" [src]="baseUrl + completed?.driverId?.image" />
                              </figure>
                            </div>
                            <div class="order_detail_right flex">
                              <div class="">
                                <h4>{{ completed?.driverId?.firstName + " " + completed?.driverId?.lastName }}</h4>
                                <ul class="flex orders_star">
                                  <li *ngIf="completed?.driverRating > 0">
                                    <i class="fa fa-star" aria-hidden="true"
                                      ><span class="avg-rtng">{{ completed?.driverRating }}</span></i
                                    >
                                  </li>
                                </ul>
                                <h4 class="blue_color">
                                  {{ completed?.vehicleId?.vehicleModel }} - {{ completed?.vehicleId?.licensePlateNumber }}
                                  <!-- <span>(white)</span> -->
                                </h4>
                                <div class="driver_payment_detail">
                                  <div class="driver_payment_item">
                                    <p>
                                      {{ "Grand Total" | translate }}
                                      <span> {{ (completed?.riderFare + completed?.tip)?.toFixed(2) | currency: "USD" }}</span>
                                    </p>
                                  </div>
                                  <div class="driver_payment_item">
                                    <p>
                                      {{ "Date/Time" | translate }}<span>{{ completed?.bookingDate | date: "d/MM/yy, h:mm a" }}</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <button *ngIf="completed?.driverRating === 0" class="complete rate_now" (click)="openRatingModal(completed)">
                                {{ "RATE NOW" | translate }}
                              </button>
                              <button class="complete">{{ "COMPLETED" | translate }}</button>
                            </div>
                          </div>
                          <div class="order_detail_conten flex">
                            <div class="order_detail_left">
                              <div class="overlay_bg">
                                <figure class="">
                                  <img *ngIf="!completed?.driverId?.image" src="assets/images/user.png" />
                                  <img *ngIf="completed?.driverId?.image" [src]="baseUrl + completed?.driverId?.image" />
                                </figure>
                              </div>
                              <div class="location_white">
                                <figure class="location_white">
                                  <img src="assets/images/location_white.svg" />
                                </figure>
                              </div>
                            </div>
                            <div (click)="openBookingStatusModal(completed, 'DELIVERY')" class="view-booking-dtl">
                              <p>{{ "View Booking Details" | translate }}</p>
                            </div>
                            <!-- <div class="order_detail_right">
                              <div class="start_trip_text">
                                <div class="mm">
                                  <div class="blm">
                                    <div class="bb"></div>
                                  </div>
                                  <div class="mkl">
                                    <p>{{completed?.pickUpAddress}}</p>
                                  </div>
                                </div>

                                <div class="mm">
                                  <div class="blm">
                                    <div class="blc"></div>
                                  </div>
                                  <div class="mkl">
                                    <p>{{completed?.dropUpAddress}}</p>
                                  </div>
                                </div>
                              </div>
                            </div> -->
                          </div>
                        </li>
                      </ul>
                      <nav aria-label="..." class="pagination">
                        <ul class="pagination justify-content-end mb-0">
                          <li class="{{ previousBtnEnabled ? 'page-item' : 'page-item disabled' }}">
                            <a
                              class="page-link"
                              href="javascript:void(0)"
                              (click)="onPrevPage('DELIVERY', 'totalPagesCompletedDelivery', 'currentPageCompletedDelivery', 1)"
                            >
                              <i></i>
                              <span>{{ "Previous" | translate }}</span>
                            </a>
                          </li>
                          <li
                            *ngFor="let a of [].constructor(totalPagesCompletedDelivery); let i = index"
                            class="{{ currentPageCompletedDelivery === i + 1 ? 'page-item active' : 'page-item' }}"
                          >
                            <a
                              class="page-link"
                              *ngIf="
                                currentPageCompletedDelivery < i + 1
                                  ? i + 1 - currentPageCompletedDelivery < 3
                                  : currentPageCompletedDelivery - (i + 1) < 3
                              "
                              href="javascript:void(0)"
                              (click)="onChnagePage(i + 1, 'DELIVERY', 'totalPagesCompletedDelivery', 'currentPageCompletedDelivery', 1)"
                              >{{ i + 1 }}</a
                            >
                          </li>
                          <li class="{{ nextBtnEnabled ? 'page-item' : 'page-item disabled' }}">
                            <a
                              class="page-link"
                              href="javascript:void(0)"
                              (click)="onNextPage('DELIVERY', 'totalPagesCompletedDelivery', 'currentPageCompletedDelivery', 1)"
                            >
                              <i></i>
                              <span>{{ "Next" | translate }}</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div *ngIf="bookingDataCompletedDelivery.length === 0" class="del-main">
                      <h3>{{ "No data found!" | translate }}</h3>
                    </div>
                  </mat-tab>
                  <mat-tab [label]="'Canceled' | translate">
                    <div *ngIf="selectedTabIndex === 2 && bookingDataCanceledDelivery.length > 0">
                      <ul class="order_detail">
                        <li type="" class="btn-demo" *ngFor="let calceled of bookingDataCanceledDelivery">
                          <div class="order_detail_conten flex borderbtm">
                            <div class="order_detail_left" *ngIf="calceled.driverId">
                              <figure>
                                <img *ngIf="!calceled?.driverId?.image" src="assets/images/user.png" />
                                <img *ngIf="calceled?.driverId?.image" [src]="baseUrl + calceled?.driverId?.image" />
                              </figure>
                            </div>
                            <div class="order_detail_right flex">
                              <div class="" *ngIf="calceled.driverId">
                                <h4>{{ calceled?.driverId?.firstName + " " + calceled?.driverId?.lastName }}</h4>
                                <ul class="flex orders_star">
                                  <li *ngIf="calceled?.driverRating > 0">
                                    <i class="fa fa-star" aria-hidden="true"
                                      ><span class="avg-rtng">{{ calceled?.driverRating }}</span></i
                                    >
                                  </li>
                                </ul>
                                <h4 class="blue_color">
                                  {{ calceled?.vehicleId?.vehicleModel }} - {{ calceled?.vehicleId?.licensePlateNumber }}
                                  <!-- <span>(white)</span> -->
                                </h4>
                                <div class="driver_payment_detail">
                                  <div class="driver_payment_item">
                                    <p>
                                      {{ "Cancellation Fee" | translate }}
                                      <span>{{ calceled?.cancelAmount?.toFixed(2) | currency: "USD" }}</span>
                                    </p>
                                  </div>
                                  <div class="driver_payment_item">
                                    <p>
                                      {{ "Date/Time" | translate
                                      }}<span>{{ calceled?.bookingDate | date: "d/MM/yy, h:mm
                                        a" }}</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div *ngIf="!calceled.driverId">
                                <h4>{{ "Driver not assigned" | translate }}.</h4>
                              </div>
                              <button
                                *ngIf="calceled.driverId && calceled?.driverRating === 0"
                                class="complete rate_now"
                                (click)="openRatingModal(calceled)"
                              >
                                {{ "RATE NOW" | translate }}
                              </button>
                              <button class="cancel">{{ "CANCELED" | translate }}</button>
                            </div>
                          </div>
                          <div class="order_detail_conten flex">
                            <div class="order_detail_left">
                              <div class="overlay_bg">
                                <figure class="">
                                  <img *ngIf="!calceled?.driverId?.image" src="assets/images/user.png" />
                                  <img *ngIf="calceled?.driverId?.image" [src]="baseUrl + calceled?.driverId?.image" />
                                </figure>
                              </div>
                              <div class="location_white">
                                <figure class="location_white">
                                  <img src="assets/images/location_white.svg" />
                                </figure>
                              </div>
                            </div>
                            <div (click)="openBookingStatusModal(calceled, 'DELIVERY')" class="view-booking-dtl">
                              <p>{{ "View Booking Details" | translate }}</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <nav aria-label="..." class="pagination">
                        <ul class="pagination justify-content-end mb-0">
                          <li class="{{ previousBtnEnabled ? 'page-item' : 'page-item disabled' }}">
                            <a
                              class="page-link"
                              href="javascript:void(0)"
                              (click)="onPrevPage('DELIVERY', 'totalPagesCanceledDelivery', 'currentPageCanceledDelivery', 2)"
                            >
                              <i></i>
                              <span>{{ "Previous" | translate }}</span>
                            </a>
                          </li>
                          <li
                            *ngFor="let a of [].constructor(totalPagesCanceledDelivery); let i = index"
                            class="{{ currentPageCanceledDelivery === i + 1 ? 'page-item active' : 'page-item' }}"
                          >
                            <a
                              class="page-link"
                              *ngIf="
                                currentPageCanceledDelivery < i + 1
                                  ? i + 1 - currentPageCanceledDelivery < 3
                                  : currentPageCanceledDelivery - (i + 1) < 3
                              "
                              href="javascript:void(0)"
                              (click)="onChnagePage(i + 1, 'DELIVERY', 'totalPagesCanceledDelivery', 'currentPageCanceledDelivery', 2)"
                              >{{ i + 1 }}</a
                            >
                          </li>
                          <li class="{{ nextBtnEnabled ? 'page-item' : 'page-item disabled' }}">
                            <a
                              class="page-link"
                              href="javascript:void(0)"
                              (click)="onNextPage('DELIVERY', 'totalPagesCanceledDelivery', 'currentPageCanceledDelivery', 2)"
                            >
                              <i></i>
                              <span>{{ "Next" | translate }}</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div *ngIf="bookingDataCanceledDelivery.length === 0" class="del-main">
                      <h3>{{ "No data found!" | translate }}</h3>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>

            <div *ngIf="nextPopup === 3333">
              <div class="profile_content">
                <div class="title_bar">
                  <h3 class="notification_title">{{ "Payment Options" | translate }}</h3>
                </div>
                <div class="wallent_main">
                  <figure><img src="assets/images/pay.svg" /></figure>
                  <h3><span>Wallet</span>$15.50</h3>
                  <a href="###" class="add_money">Add Money</a>
                </div>
                <div class="crediti_card">
                  <figure>
                    <img src="assets/uimages/credit_card.png" alt="" />
                  </figure>
                </div>
                <div class="credit_card_type">
                  <div class="credit_card_item">
                    <mat-radio-group aria-label="Select an option">
                      <mat-radio-button value="1">
                        <div class="card_detailcs">
                          <h3>XXXX XXXX XXXX 2567<span></span></h3>
                          <figure>
                            <img src="assets/uimages/visa_icon.png" alt="" />
                          </figure>
                        </div>
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
                <div class="commen_btn_outer">
                  <button class="btn btn-primary" data-toggle="modal" data-target="#add_payment">Add New Card</button>
                </div>
              </div>
            </div>
            <div *ngIf="nextPopup === 7">
              <div class="payment_content">
                <!-- <div class="wallent_main">
                  <figure><img src="assets/images/pay.svg" /></figure>
                  <h3><span>Wallet</span>$15.50</h3>
                </div> -->
                <div class="request_ride_inner border_remove paymernt">
                  <div class="title_bar sec">
                    <h3 class="notification_title">{{ "Payment Options" | translate }}</h3>
                  </div>
                  <ul class="payment_last fullw">
                    <li>
                      <div class="card1">
                        <div class="card-innre-payment">
                          <div class="wallet-img">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-wallet2"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"
                              />
                            </svg>
                          </div>
                          <div class="balance">
                            <p>{{ allSavedCardData?.walletAmount?.toFixed(2) | currency: "USD" }}</p>
                            <p>{{ "Wallet Balance" | translate }}</p>
                          </div>
                        </div>
                        <div class="add-btn add-mny">
                          <i class="fa fa-plus" title="Add Money" style="cursor: pointer" aria-hidden="true" (click)="addWalletModal('block')"></i>
                        </div>
                      </div>
                    </li>
                    <!-- <li class="borderbtm waletli">
                      <div class="nsflex">
                        <div class="list_inner">
                          <mat-checkbox [checked]="isWalletSelected" (change)="onWalletSelected($event.checked)">
                            {{ "Pay via APPTUNIX Wallet" | translate }}</mat-checkbox
                          >
                        </div>
                      </div>
                    </li>
                    <li class="borderbtm">
                      <div class="list_inner">
                        <mat-checkbox>{{ "Pay With Paypal" | translate }}</mat-checkbox>
                      </div>
                    </li> -->
                    <li class="borderbtm newli" *ngIf="allSavedCardData.cards.length > 0">
                      <mat-radio-group aria-label="Select an option" (change)="onCardChange($event.value)">
                        <mat-radio-button [checked]="card?.isSelected" [value]="i + 1" *ngFor="let card of allSavedCard; let i = index">
                          <div class="card_detailcs">
                            <div>
                              <h3>
                                {{ card?.cardNumber
                                }}<span>{{ card?.brand }} - {{ card?.expiryDate.slice(0, 2) }}/{{ card?.expiryDate.slice(2) }}</span>
                              </h3>
                            </div>
                            <div class="dlt-radio">
                              <h3 *ngIf="card?.isDefault">
                                <span class="text-success">{{ "PRIMARY CARD" | translate }}</span>
                              </h3>
                              <h3 *ngIf="!card?.isDefault" (click)="changeDefaultCard(card?._id)">
                                <span style="color: #0171bd">{{ "MAKE PRIMARY" | translate }}</span>
                              </h3>
                              <i class="fa fa-trash" (click)="deleteCard(card?._id)" aria-hidden="true"></i>
                            </div>
                          </div>
                        </mat-radio-button>
                      </mat-radio-group>
                    </li>
                    <div class="pay-op">
                      <div (click)="AddCardModal('block')" class="commen_btn_outer">
                        <button class="btn btn-primary">{{ "Add Payment Card" | translate }}</button>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>

            <div *ngIf="nextPopup === 8">
              <div class="profile_content">
                <div class="profile title_bar">
                  <h3 class="notification_title">
                    {{ "Change Password" | translate }}
                  </h3>
                </div>

                <form [formGroup]="changePassForm">
                  <ul class="">
                    <li>
                      <label>{{ "Current password" | translate }}</label>
                      <div class="form-group">
                        <mat-form-field [floatLabel]="'never'">
                          <input
                            matInput
                            formControlName="password"
                            class="form-control"
                            type="password"
                            [placeholder]="'Current password' | translate"
                            name="name"
                          />
                          <mat-error *ngIf="changePassSubmitted && changePassForm.controls['password'].hasError('required')">
                            {{ "Current password" | translate }} {{ "is required" | translate }}.</mat-error
                          >
                        </mat-form-field>
                      </div>
                    </li>
                    <li>
                      <label> {{ "New password" | translate }}</label>
                      <div class="form-group">
                        <mat-form-field [floatLabel]="'never'">
                          <input
                            matInput
                            formControlName="newPassword"
                            class="form-control"
                            type="password"
                            [placeholder]="'New password' | translate"
                            name="name"
                          />
                          <mat-error *ngIf="changePassSubmitted && changePassForm.controls['newPassword'].hasError('required')">
                            {{ "New password" | translate }} {{ "is required" | translate }}.</mat-error
                          >
                          <mat-error *ngIf="changePassSubmitted && changePassForm.controls['newPassword'].hasError('minlength')">
                            {{ "New password" | translate }}
                            {{ "length must be 6 characters" | translate }}.</mat-error
                          >
                        </mat-form-field>
                      </div>
                    </li>
                    <li>
                      <label>{{ "Confirm new password" | translate }}</label>
                      <div class="form-group">
                        <mat-form-field [floatLabel]="'never'">
                          <input
                            matInput
                            formControlName="confirmPassword"
                            class="form-control"
                            type="password"
                            [placeholder]="'Confirm new password' | translate"
                            name="name"
                          />
                          <mat-error *ngIf="changePassSubmitted && changePassForm.controls['confirmPassword'].hasError('required')">
                            {{ "Confirm new password" | translate }} {{ "is required" | translate }}.</mat-error
                          >
                          <mat-error *ngIf="changePassSubmitted && changePassForm.controls['confirmPassword'].hasError('mustMatch')">
                            {{ "Password and confirm password must match" | translate }}.</mat-error
                          >
                        </mat-form-field>
                      </div>
                    </li>
                  </ul>
                  <div class="commen_btn_outer">
                    <button class="btn btn-primary" (click)="changePassword()">{{ "Save" | translate }}</button>
                  </div>
                </form>
              </div>
            </div>
            <!-----start loyality points-->
            <div *ngIf="nextPopup === 3">
              <div class="profile title_bar">
                <h3 class="notification_title">{{ "Loyalty points" | translate }}</h3>
                <!-- <h2>{{ "Loyalty points" | translate }}</h2> -->
              </div>
              <!-- <div class="grey_line payment_grey_line"></div> -->
              <div class="prnt-ly">
                <ul class="payment_last">
                  <li>
                    <div class="card1">
                      <div class="card-innre-payment">
                        <div class="wallet-img">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-wallet2"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"
                            />
                          </svg>
                        </div>
                        <div class="balance">
                          <p>{{ loyalityData?.loyalityTotalAmount?.toFixed(2) | currency: "USD" }}</p>
                          <p>{{ "Loyalty points" | translate }}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div class="counter-div">
                <p class="counter-text">{{ "After redeeming you have only 00:30 seconds to get code scanned" | translate }}.</p>
              </div>
              <div class="loyality-main">
                <ul *ngIf="loyalityData?.loyalityData?.length > 0">
                  <li *ngFor="let data of loyalityData?.loyalityData">
                    <div class="loyal-minnre">
                      <div class="loyal-bg">
                        <img class="loyal-bg" [src]="imgBaseurl + data?.loyalityId?.couponIcon" />
                      </div>
                      <div class="loyality-inner-para">
                        <h5>{{ data?.loyalityId?.companyName }}</h5>
                        <!-- <p>Expiry: {{data?.loyalityId?.expiryDate | date: 'M/d/yy'}}</p> -->
                        <p>{{ data?.loyalityId?.coupon }}</p>
                        <div class="sp">
                          <p>
                            {{ data?.loyalityId?.couponType === 1 ? "$" + data?.loyalityId?.couponValue : data?.loyalityId?.couponValue }}
                            {{ data?.loyalityId?.couponType === 2 ? "%" : "" }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <button
                      [style]="data?.isRedeemed ? 'margin-top: 40px;' : ''"
                      [class]="data?.isRedeemed ? 'btn btn-success' : 'btn btn-primary'"
                      (click)="redeemLoyality(data)"
                    >
                      {{ data?.isRedeemed ? "Redeemed" : "Redeem" }}
                    </button>
                  </li>
                </ul>
                <h3 *ngIf="loyalityData?.loyalityData?.length == 0" style="text-align: center">
                  {{ "You don't have any Loyality Points now" | translate }}.
                </h3>
              </div>
            </div>
            <!---end loyality ponits-->
            <!---start notification sec-->
            <div *ngIf="nextPopup === 5">
              <div class="profile_content">
                <h3 class="notification_title">{{ "Notifications" | translate }}</h3>
                <div class="recent_notification_sec">
                  <span class="recent_notifi_title">{{ "Recent" | translate }}</span>
                  <div *ngFor="let data of notificationData; let i = index">
                    <div class="notification_item" *ngIf="!data?.isRead" (click)="openBookingModal(data)" style="cursor: pointer">
                      <figure>
                        <img src="assets/images/favicon.ico" alt="" />
                      </figure>
                      <div class="flexs">
                        <p>
                          {{ data?.message }}
                        </p>
                        <p>{{ data?.createdAt | date: "dd-mm-yyy, HH:mm a" }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="recent_notification_sec older_notification">
                  <span class="recent_notifi_title">{{ "Older Notifications" | translate }}</span>
                  <div *ngFor="let data of notificationData; let i = index">
                    <div class="notification_item" *ngIf="data?.isRead" (click)="openBookingModal(data)" style="cursor: pointer">
                      <figure>
                        <img src="assets/images/favicon.ico" alt="" />
                      </figure>
                      <div class="flexs">
                        <p>
                          {{ data?.message }}
                        </p>
                        <p>{{ data?.createdAt | date: "dd-mm-yyy, HH:mm a" }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <nav aria-label="..." class="pagination" *ngIf="notificationData?.length !== 0">
                <ul class="pagination justify-content-end mb-0">
                  <li class="{{ previousBtnEnabledN ? 'page-item' : 'page-item disabled' }}">
                    <a class="page-link" href="javascript:void(0)" (click)="onPrevPageN()">
                      <i></i>
                      <span>{{ "Previous" | translate }}</span>
                    </a>
                  </li>
                  <li
                    *ngFor="let a of [].constructor(totalPagesN); let i = index"
                    class="{{ currentPageN === i + 1 ? 'page-item active' : 'page-item' }}"
                  >
                    <a
                      class="page-link"
                      *ngIf="currentPageN < i + 1 ? i + 1 - currentPageN < 3 : currentPageN - (i + 1) < 3"
                      href="javascript:void(0)"
                      (click)="onChnagePageN(i + 1)"
                      >{{ i + 1 }}</a
                    >
                  </li>
                  <li class="{{ nextBtnEnabledN ? 'page-item' : 'page-item disabled' }}">
                    <a class="page-link" href="javascript:void(0)" (click)="onNextPageN()">
                      <i></i>
                      <span>{{ "Next" | translate }}</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div *ngIf="nextPopup === 4">
              <div class="profile_content">
                <h3 class="notification_title">{{ "Review and Rating" | translate }}</h3>
                <div class="recent_notification_sec">
                  <div class="review-page">
                    <!-- <a href="#"><span><i class="fa fa-chevron-left" aria-hidden="true"></i></span> Back</a> -->
                    <div class="review-content">
                      <p>{{ "Ratings" | translate }}</p>
                      <div class="d-flex">
                        <figure>
                          <img src="assets/uimages/rate_icon.svg" alt="" />
                        </figure>
                        <p class="rt-rw" style="margin-left: 8px; margin-top: 3px">{{ commonService?.loggedInUser?.avgRating }}</p>
                      </div>
                      <!-- <div class="review-star">
                        <a href="#"
                          ><span><i class="fa fa-star" aria-hidden="true"></i></span
                        ></a>
                        <a href="#"
                          ><span><i class="fa fa-star" aria-hidden="true"></i></span
                        ></a>
                        <a href="#"
                          ><span><i class="fa fa-star" aria-hidden="true"></i></span
                        ></a>
                        <a href="#"
                          ><span><i class="fa fa-star" aria-hidden="true"></i></span
                        ></a>
                      </div> -->
                      <div class="user-review pt-3">
                        <a href="#" class="d-flex">
                          <figure class="rat-icon">
                            <img src="assets/icons/user.png" />
                          </figure>
                          {{ ratingReviewData.count }} {{ "Users" | translate }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="same-ht" *ngIf="ratingReviewData?.bookingData?.length > 0">
                  <div class="reviewmina" *ngFor="let review of ratingReviewData?.bookingData">
                    <div class="rvmain-left">
                      <figure>
                        <img *ngIf="review?.driverId?.image" src="" />
                        <img *ngIf="review?.driverId?.image" [src]="baseUrl + review?.driverId?.image" />
                      </figure>
                    </div>
                    <div class="rvmain-right">
                      <div>
                        <h5>{{ review?.driverId?.firstName + " " + review?.driverId?.lastName }}</h5>
                        <p>{{ review?.driverReview || "" }}</p>
                        <!-- <ul>
                      <li><i class="fa fa-star" aria-hidden="true"></i></li>
                      <li><i class="fa fa-star" aria-hidden="true"></i></li>
                      <li><i class="fa fa-star" aria-hidden="true"></i></li>
                    </ul> -->
                        <div class="d-flex">
                          <figure>
                            <img src="assets/uimages/rate_icon.svg" alt="" />
                          </figure>
                          <p class="rt-rw" style="margin-left: 8px; margin-top: 3px">{{ review?.driverRating }}</p>
                        </div>
                      </div>

                      <div class="date-rtng">
                        <p>{{ review?.bookingDate | date: "d/MM/yy, h:mm a" }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="ratingReviewData?.bookingData?.length === 0" class="del-main">
                  <h3>{{ "No data found!" | translate }}</h3>
                </div>
                <nav aria-label="..." class="pagination" *ngIf="ratingReviewData?.bookingData?.length !== 0">
                  <ul class="pagination justify-content-end mb-0">
                    <li class="{{ previousBtnEnabledR ? 'page-item' : 'page-item disabled' }}">
                      <a class="page-link" href="javascript:void(0)" (click)="onPrevPageR()">
                        <i></i>
                        <span>{{ "Previous" | translate }}</span>
                      </a>
                    </li>
                    <li
                      *ngFor="let a of [].constructor(totalPagesR); let i = index"
                      class="{{ currentPageR === i + 1 ? 'page-item active' : 'page-item' }}"
                    >
                      <a
                        class="page-link"
                        *ngIf="currentPageR < i + 1 ? i + 1 - currentPageR < 3 : currentPageR - (i + 1) < 3"
                        href="javascript:void(0)"
                        (click)="onChnagePageR(i + 1)"
                        >{{ i + 1 }}</a
                      >
                    </li>
                    <li class="{{ nextBtnEnabledR ? 'page-item' : 'page-item disabled' }}">
                      <a class="page-link" href="javascript:void(0)" (click)="onNextPageR()">
                        <i></i>
                        <span>{{ "Next" | translate }}</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <!----end notification sec-->
            <!-----start manage address sec-->
            <div *ngIf="nextPopup === 6">
              <div class="profile_content" style="padding-bottom: 20px">
                <div class="outr-sc d-flex">
                  <h3 class="notification_title">{{ "Manage Addresses" | translate }}</h3>
                  <div class="ml-auto">
                    <button class="btn driverap_btn my-0" data-toggle="modal" data-target="#add_address" (click)="openAddFavModal()">
                      <i class="fa fa-plus"></i> {{ "Address" | translate }}
                    </button>
                  </div>
                </div>
                <div class="same-htt" *ngIf="favAddData?.length > 0">
                  <div class="manage_address_item" *ngFor="let add of favAddData">
                    <!-- <div class="map">
                    <agm-map [latitude]="add.latitude" [longitude]="add.longitude" [zoom]="12" (mapReady)="onMapReady($event)">
                      <agm-marker [latitude]="add.latitude" [longitude]="add.longitude" [markerDraggable]="false"> </agm-marker>
                    </agm-map>
                  </div> -->

                    <!-- <figure class="address_mapn">
                    <img src="assets/uimages/address_map.png" alt="" />
                  </figure> -->
                    <div class="manage_address_text">
                      <h3
                        style="font-weight: 500"
                        *ngIf="add?.name.toLowerCase() === 'work' || add?.name.toLowerCase() === 'home' || add?.name.toLowerCase() === 'office'"
                      >
                        {{ add?.name | titlecase }}
                      </h3>
                      <h3
                        style="font-weight: 500"
                        *ngIf="add?.name.toLowerCase() !== 'work' && add?.name.toLowerCase() !== 'home' && add?.name.toLowerCase() !== 'office'"
                      >
                        {{ add?.type | titlecase }}
                      </h3>
                      <p>{{ add?.address }}</p>
                    </div>
                    <figure class="manage_addres_edit">
                      <img src="assets/uimages/address_edit.png" alt="" (click)="editfavAdd(add)" style="cursor: pointer" />
                      <i class="fa fa-trash trsh" aria-hidden="true" (click)="deleteFavAdd(add?._id)" style="cursor: pointer"></i>
                    </figure>
                  </div>
                </div>
              </div>
              <div *ngIf="favAddData?.length === 0" class="del-main">
                <h3>{{ "No data found!" | translate }}</h3>
              </div>
              <nav aria-label="..." class="pagination" *ngIf="favAddData?.length !== 0" style="margin-top: 5px">
                <ul class="pagination justify-content-end mb-0">
                  <li class="{{ previousBtnEnabledA ? 'page-item' : 'page-item disabled' }}">
                    <a class="page-link" href="javascript:void(0)" (click)="onPrevPageA()">
                      <i></i>
                      <span>{{ "Previous" | translate }}</span>
                    </a>
                  </li>
                  <li
                    *ngFor="let a of [].constructor(totalPagesA); let i = index"
                    class="{{ currentPageA === i + 1 ? 'page-item active' : 'page-item' }}"
                  >
                    <a
                      class="page-link"
                      *ngIf="currentPageA < i + 1 ? i + 1 - currentPageA < 3 : currentPageA - (i + 1) < 3"
                      href="javascript:void(0)"
                      (click)="onChnagePageA(i + 1)"
                      >{{ i + 1 }}</a
                    >
                  </li>
                  <li class="{{ nextBtnEnabledA ? 'page-item' : 'page-item disabled' }}">
                    <a class="page-link" href="javascript:void(0)" (click)="onNextPageA()">
                      <i></i>
                      <span>{{ "Next" | translate }}</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>

            <div *ngIf="nextPopup === 9">
              <div class="profile_content">
                <div class="title_bar">
                  <h3 class="notification_title">{{ "Favourite Drivers" | translate }}</h3>
                </div>
                <div *ngIf="favouriteDriverData.length > 0" class="same-htt">
                  <div class="del-main" *ngFor="let data of favouriteDriverData">
                    <div class="flex-in2">
                      <div class="del-l">
                        <figure>
                          <img *ngIf="!data?.driverId?.image" src="assets/images/man_user.jpg" />
                          <img *ngIf="data?.driverId?.image" [src]="baseUrl + data?.driverId?.image" />
                        </figure>
                      </div>
                      <div class="del-r sec">
                        <h5>{{ data?.driverId?.firstName }} {{ data?.driverId?.lastName }}</h5>
                        <div class="flex-m">
                          <div class="rate-l">
                            <h6>
                              {{ "Rating" | translate }} <span>{{ data?.driverId?.avgRating }}/5</span>
                            </h6>
                          </div>
                          <div class="rev-r">
                            <h6>
                              {{ "Review" | translate }} <span>{{ data?.driverId?.reviewTotal }}</span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="heart">
                      <!-- <i class="fa fa-heart-o redcolor" *ngIf="!data?.driverId?.isFav" aria-hidden="true" (click)="favouriteDriver(data?.driverId?._id,true)"></i> -->
                      <i
                        class="fa fa-heart redcolor"
                        style="cursor: pointer"
                        aria-hidden="true"
                        (click)="favouriteDriver(data?.driverId?._id, false)"
                      ></i>
                    </div>
                  </div>
                </div>
                <div *ngIf="favouriteDriverData.length === 0" class="del-main">
                  <h3>{{ "No data found!" | translate }}</h3>
                </div>
              </div>
            </div>
            <!----end manages address sec-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="ratingModal" role="dialog" [ngStyle]="{ display: ratingModal, opacity: 1 }">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-body">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" (click)="closeratingModal()">
            <span><i class="fa fa-times" aria-hidden="true"></i></span>
          </button>
        </div>
        <div class="title_bar">
          <h2 class="arrving_time">{{ "Rate Your" | translate }} {{ constant?.SHOW_BOOKING_TYPE[bookingResponseData?.bookingType] | titlecase }}</h2>
        </div>
        <div class="request_ride_inner border_remove">
          <div class="driver_info_bx">
            <div class="rate-screen">
              <!-- <h6>Your Delivery</h6> -->
            </div>
            <div class="rl">
              <div class="del-main">
                <div class="del-l">
                  <figure>
                    <img *ngIf="!driverReviewData?.image" src="assets/images/man_user.jpg" />
                    <img *ngIf="driverReviewData?.image" [src]="baseUrl + driverReviewData?.image" />
                  </figure>
                </div>
                <div class="main-c">
                  <div class="del-r del-u">
                    <h5>{{ driverReviewData?.firstName + " " + driverReviewData?.lastName }}</h5>
                  </div>
                  <div class="driver_rate_pop del-b">
                    <p>
                      {{ "Rating" | translate }}<span>{{ driverReviewData?.avgRating }}/5</span>
                    </p>
                    <p>
                      {{ "Review" | translate }}<span>{{ driverReviewData?.reviewTotal }}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="heart">
                <i (click)="addFavouriteDriver()" *ngIf="!driverReviewData?.isFav" class="fa fa-heart-o redcolor" aria-hidden="true"></i>
                <i (click)="removeFavouriteDriver()" *ngIf="driverReviewData?.isFav" class="fa fa-heart redcolor" aria-hidden="true"></i>
              </div>
            </div>
            <div class="rating">
              <p class="title">{{ "Rate" | translate }}</p>
              <div class="star-rating">
                <button
                  mat-icon-button
                  *ngFor="let ratingId of ratingArr; index as i"
                  [id]="'star_' + i"
                  (click)="onClick(i + 1)"
                  [matTooltip]="ratingId + 1"
                  matTooltipPosition="above"
                >
                  <mat-icon>
                    {{ showIcon(i) }}
                  </mat-icon>
                </button>
              </div>
              <p class="rating-err" *ngIf="ratingSubmitted && rating === 0">{{ "Please give rating" | translate }}.</p>
            </div>
            <ul class="bages" *ngIf="rating > 2">
              <li
                [ngClass]="selectedDriverReview === i ? 'badge badge-secondary active' : 'badge badge-secondary'"
                *ngFor="let data of reviewData; let i = index"
                (click)="selectReview(i, data)"
              >
                {{ data?.name }}
              </li>
              <!-- <li [ngClass]="{'badge badge-secondary': data?.isActive === false,'badge badge-secondary active': data?.isActive === true}" *ngFor="let data of reviewData">{{data?.name}}</li> -->
            </ul>
            <div class="fgi sec" *ngIf="rating <= 2 && rating > 0">
              <textarea id="w3review" class="from-control" placeholder="Enter your feedback here..." [formControl]="review"></textarea>
            </div>
            <p class="rating-err" *ngIf="ratingSubmitted && review.value === '' && rating > 0 && rating <= 2">
              {{ "Please give feedback" | translate }}.
            </p>
            <p class="rating-err" *ngIf="ratingSubmitted && selectedReviewData === '' && rating > 2">{{ "Please give feedback" | translate }}.</p>
          </div>
          <div class="tip-title">
            <p>{{ "Add a tip" | translate }}</p>
          </div>
          <div class="bb-hldr">
            <div
              [ngClass]="selectedTip === i ? 'bbxx active' : 'bbxx'"
              *ngFor="let item of dummy; let i = index"
              (click)="selectTip(i, item?.amount)"
            >
              {{ "$ " + item?.amount }}
            </div>
          </div>
          <div class="custom-tip">
            <mat-form-field class="example-full-width">
              <input
                class="tip-input"
                type="number"
                (change)="onTipChange($event.target.value)"
                (keyup)="keypresshandler($event)"
                [formControl]="customRate"
                matInput
                [placeholder]="'Enter custom amount' | translate"
              />
            </mat-form-field>
          </div>
          <div class="feedback-links">
            <h5 class="fdd-links">{{ "Feedback Links" | translate }}</h5>
            <h6 class="dd">{{ "Driver" | translate }}</h6>
            <ul>
              <li>
                {{ "Was the driver on time?" | translate }}
                <div class="tp">
                  <mat-radio-group aria-label="Select an option" [formControl]="wasTheDriverOnTime">
                    <mat-radio-button [value]="true">{{ "Yes" | translate }}</mat-radio-button>
                    <mat-radio-button [value]="false">{{ "No" | translate }}</mat-radio-button>
                  </mat-radio-group>
                  <mat-error *ngIf="ratingSubmitted && wasTheDriverOnTime.hasError('required')">Please select an option</mat-error>
                </div>
              </li>
              <li>
                {{ "Did the driver make your ride comfortabel?" | translate }}
                <div class="tp">
                  <mat-radio-group aria-label="Select an option" [formControl]="driverMakeYouComfortabel">
                    <mat-radio-button [value]="true">{{ "Yes" | translate }}</mat-radio-button>
                    <mat-radio-button [value]="false">{{ "No" | translate }}</mat-radio-button>
                  </mat-radio-group>
                  <mat-error *ngIf="ratingSubmitted && driverMakeYouComfortabel.hasError('required')">Please select an option</mat-error>
                </div>
              </li>
              <li>
                {{ "Was the ride safe?" | translate }}
                <div class="tp">
                  <mat-radio-group aria-label="Select an option" [formControl]="wasTheRideSafe">
                    <mat-radio-button [value]="true">{{ "Yes" | translate }}</mat-radio-button>
                    <mat-radio-button [value]="false">{{ "No" | translate }}</mat-radio-button>
                  </mat-radio-group>
                  <mat-error *ngIf="ratingSubmitted && wasTheRideSafe.hasError('required')">Please select an option</mat-error>
                </div>
              </li>
              <li class="imp">
                {{ "What can we improve for next time?" | translate }}
                <div class="tp">
                  <mat-form-field class="example-full-width">
                    <textarea matInput [formControl]="comment" placeholder="Comment"></textarea>
                  </mat-form-field>
                </div>
              </li>
            </ul>
          </div>
          <div class="bk-hm">
            <button class="btn btn-primary first-btn" (click)="addRating(0)">{{ "Submit" | translate }}</button>
            <!-- <button class="btn btn-outline second-btn" (click)="closeratingModal()" >Skip</button> -->
          </div>
        </div>
      </div>
      <!-- <div class="commen_btn_outer">
        <button class="btn btn-primary">Save</button>
      </div> -->
    </div>
  </div>
</div>

<!-- ///verify email dialog -->
<div class="modal fade" id="verifyEmail" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="verify-email">
      <figure><img src="assets/images/verify_email_logo.png" /></figure>
      <h3>{{ "Verifying your email" | translate }}</h3>
      <p>
        {{ "Please check your email account for" | translate }} <b>{{ commonService?.emailForVerification }}</b> {{ "and click the" | translate }} "{{
          "verify link inside" | translate
        }}".
      </p>
      <div class="btn_outer">
        <button class="btn btn-primary margin_top20">
          {{ "Next" | translate }}
        </button>
        <button class="blue-c">{{ "Resend" | translate }}</button>
      </div>
    </div>
  </div>
</div>

<!-- ///verify phone dialog -->
<div class="modal fade" id="verifyPhone" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="verify-email">
      <figure><img src="assets/images/verify_email_logo.png" /></figure>
      <h3>{{ "Verifying your email" | translate }}</h3>
      <p>
        {{ "Please check your email account for" | translate }} <b>{{ commonService?.emailForVerification }}</b> {{ "and click the" | translate }} "{{
          "verify link inside" | translate
        }}".
      </p>
      <div class="btn_outer">
        <button class="btn btn-primary margin_top20">
          {{ "Next" | translate }}
        </button>
        <button class="blue-c">{{ "Resend" | translate }}</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="add_payment" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span><i class="fa fa-times" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h3 class="add_card_title">Add a card</h3>
        <div class="card_card_form">
          <div class="add_card_input_itms">
            <input type="text" class="form-control" placeholder="card holder name" />
          </div>
          <div class="add_card_input_itms">
            <input type="text" class="form-control" placeholder="XXX XXX XXX " />
          </div>
          <div class="add_card_input_itms">
            <select class="form-control">
              <option>Month</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
              <option>6</option>
              <option>7</option>
              <option>8</option>
            </select>
          </div>
          <div class="add_card_input_itms">
            <select class="form-control">
              <option>year</option>
              <option>2015</option>
              <option>2016</option>
              <option>2017</option>
              <option>2018</option>
              <option>2019</option>
              <option>2020</option>
            </select>
          </div>
          <div class="add_card_input_itms">
            <input class="form-control" type="text" placeholder="CCV" />
          </div>
        </div>
      </div>
      <div class="commen_btn_outer">
        <button class="btn btn-primary">Save</button>
      </div>
    </div>
  </div>
</div>

<!-----start loyality package detail-->
<div class="modal fade loyality_offer_popup" id="loyality_offer_popup" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span><i class="fa fa-times" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h3>Hot Breakfast</h3>
        <div class="loyality_offer_item">
          <div class="loyality_offer_img">
            <figure>
              <img src="assets/uimages/offer_img.png" alt="" />
            </figure>
          </div>
          <div class="loyality_offer_disc">
            <table>
              <tr>
                <td>Total Points</td>
                <td>300.00</td>
              </tr>
              <tr>
                <td>Redeem Points</td>
                <td>300.00</td>
              </tr>
              <tr>
                <td>Remaining Points</td>
                <td>300.00</td>
              </tr>
            </table>
            <div class="commen_btn_outer">
              <button class="btn btn-primary">Redeem</button>
            </div>

            <!-------condition base show this sec-->
            <div class="loyality_congratulations">
              <h4>
                Congratulations !!!
                <span>Your Redeem has been Successfull</span>
              </h4>

              <div class="promo_offer_bx">
                <figure>
                  <img src="assets/uimages/promo_code_icon.png" alt="" />
                </figure>
                <h4>#1256edt556rf</h4>
              </div>
              <p>Show the above Coupon code to the payment counter to redeem.</p>
            </div>
            <!-------end condition base show this sec-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!----start booking detail popup-->

<div
  class="modal right fade profile_side_popup"
  id="myModal2"
  [ngStyle]="{ display: myModal2, opacity: myModal2 === 'block' ? 1 : 0 }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel2"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeBookingStatusModal()">
          <span><i class="fa fa-times" aria-hidden="true"></i></span>
        </button>
        <h4 class="modal-title" id="myModalLabel2">{{ "Booking Details" | translate }}</h4>
      </div>

      <div class="modal-body">
        <div class="request_ride_inner">
          <div class="driver_info" *ngIf="bookingsData?.bookingData?.driverId">
            <div class="driver_info_img">
              <figure>
                <img *ngIf="!bookingsData?.bookingData?.driverId?.image" src="assets/images/user.png" alt="" />
                <img *ngIf="bookingsData?.bookingData?.driverId?.image" [src]="baseUrl + bookingsData?.bookingData?.driverId?.image" alt="" />
              </figure>
            </div>
            <div class="driver_info_text">
              <h3>{{ bookingsData?.bookingData?.driverId?.firstName }} {{ bookingsData?.bookingData?.driverId?.lastName }}</h3>
              <!-- <figure>
                <img src="assets/uimages/star_rateing.png" alt="" />
              </figure> -->
              <span [hidden]="bookingsData?.bookingData?.driverRating === 0"
                ><i class="fa fa-star" aria-hidden="true"></i><span class="rtng">{{ bookingsData?.bookingData?.driverRating }}</span></span
              >
              <p>
                {{ bookingsData?.bookingData?.vehicleId?.vehicleMake }}, {{ bookingsData?.bookingData?.vehicleId?.vehicleModel }}:
                <span>{{ bookingsData?.bookingData?.vehicleId?.licensePlateNumber }}</span>
              </p>
            </div>
          </div>
          <div class="no-driver" *ngIf="!bookingsData?.bookingData?.driverId">
            <h4>{{ "Driver not assigned" | translate }}.</h4>
          </div>
          <!-- <div class="no-driver" *ngIf="selectedTabIndex !== 2 && 
          (bookingsData?.bookingData?.bookingStatus === constant?.BOOKING_STATUS?.PENDING || 
          bookingsData?.bookingData?.bookingStatus === constant?.BOOKING_STATUS?.CANCELED ||
          bookingsData?.bookingData?.bookingStatus === constant?.BOOKING_STATUS?.UPCOMING ||
          bookingsData?.bookingData?.bookingStatus === constant?.BOOKING_STATUS?.SCHEDULED
          )">
            <h4>{{'Driver not assigned' | translate}}.</h4>
          </div> -->
          <div class="hdr-outr">
            <div class="trip_info">
              <p>
                {{ "Type of trip" | translate }}: {{ bookingsData?.bookingData?.isSheduledBooking ? ("Later" | translate) : ("Now" | translate) }}
              </p>
              <p>{{ bookingsData?.bookingData?.bookingDate | date: "d/MM/yy, h:mm a" }}</p>
              <p>
                <span>{{ "Booking No." | translate }}:- </span>{{ bookingsData?.bookingData?.bookingNo }}
              </p>
            </div>
            <div
              class="trck-now"
              *ngIf="
                selectedTabIndex === 0 &&
                (bookingsData?.bookingData?.bookingStatus === constant?.BOOKING_STATUS?.ACCEPTED ||
                  bookingsData?.bookingData?.bookingStatus === constant?.BOOKING_STATUS?.ARRIVED ||
                  bookingsData?.bookingData?.bookingStatus === constant?.BOOKING_STATUS?.STARTED ||
                  bookingsData?.bookingData?.bookingStatus === constant?.BOOKING_STATUS?.ONGOING ||
                  bookingsData?.bookingData?.bookingStatus === constant?.BOOKING_STATUS?.PARTIAL ||
                  bookingsData?.bookingData?.bookingStatus === constant?.BOOKING_STATUS?.TRANSIT ||
                  (bookingsData?.bookingData?.bookingStatus === constant?.BOOKING_STATUS?.PENDING && !bookingsData?.bookingData?.isSheduledBooking))
              "
            >
              <p (click)="goToStatusPageOfRide(bookingsData)">{{ "Track Now" | translate }}</p>
            </div>
          </div>
          <div class="start_trip_text" *ngIf="bookingsData?.bookingData?.bookingType != 2">
            <div class="mm" *ngFor="let pick of bookingsData?.subBookingData">
              <div *ngIf="pick?.isPickUp">
                <div class="blm">
                  <div class="bb"></div>
                </div>
                <div class="mkl">
                  <p>{{ pick?.address }}</p>
                </div>
              </div>

              <!-- <div class="stts" *ngIf="bookingsData?.bookingData?.bookingStatus !== constant.BOOKING_STATUS.COMPLETED">
                <p *ngIf="pick?.isPickUp && pick?.bookingStatus === constant.BOOKING_STATUS.PICKED">
                  {{constant.SHOW_STATUS_BOOKING[pick?.bookingStatus]}}</p>
              </div> -->
            </div>

            <div class="mm" *ngFor="let drop of bookingsData?.subBookingData">
              <div *ngIf="!drop?.isPickUp">
                <div class="blm">
                  <div class="blc"></div>
                </div>
                <div class="mkl">
                  <p>{{ drop?.address }}</p>
                </div>
              </div>
              <div>
                <p class="stts" *!drop?.isPickUp && ngIf="drop?.bookingStatus === constant.BOOKING_STATUS.DROPED">
                  {{ constant.SHOW_STATUS_BOOKING[drop?.bookingStatus] }}
                </p>
              </div>
            </div>
          </div>
          <div class="start_trip_text" *ngIf="bookingsData?.bookingData?.bookingType == 2">
            <div class="card-body p-0">
              <div class="flex">
                <div class="ml">
                  <span class="pickk">{{ "Pick up Address" | translate }}:</span>
                  <div class="mm nmm" *ngFor="let pick of bookingsData?.subBookingData">
                    <div *ngIf="pick?.isPickUp">
                      <div class="blm">
                        <div class="bb"></div>
                      </div>
                      <div class="mkl">
                        <p>{{ pick?.address }}</p>
                      </div>
                    </div>
                  </div>
                  <span class="dropp">{{ "Package Details" | translate }}:</span>
                  <ngx-slick-carousel class="carousel-status" #slickModal="slick-carousel" [config]="carouselConfig">
                    <div ngxSlickItem *ngFor="let package of allPackagesDetail" class="slide">
                      <div class="add_vehicle_honda flex">
                        <div class="add_honda_left">
                          <div class="file">
                            <div class="file-inner">
                              <div class="nsf">
                                <figure>
                                  <img [src]="baseUrl + package?.image" />
                                </figure>
                                <h5>
                                  <span>{{ package?.packageType.split(",")[0] }}</span
                                  >{{ package?.desc }}
                                </h5>
                              </div>
                              <div class="nsf-secc">
                                <span class="capitaAL_SVU">{{ "Quantity" | translate }}: {{ package?.quantity }}</span>
                                <span class="capitaAL_SVU" *ngIf="package?.bookingStatus"
                                  >{{ "Status" | translate }}: {{ constant.SHOW_STATUS_BOOKING[package?.bookingStatus] }}</span
                                >
                              </div>
                              <div class="nsf sec">
                                <div [ngClass]="package?.bookingStatus === constant.BOOKING_STATUS.AVAILABLE ? 'blue_sqr' : 'red_circle'"></div>
                                <div class="dtl">
                                  <p>
                                    {{ "Address" | translate }}:
                                    <span class="capitaAL_SVU">
                                      {{ package?.addressDetails?.itemDropup?.address }}
                                      {{ package?.bookingStatus }}</span
                                    >
                                  </p>
                                  <p
                                    *ngIf="
                                      package?.bookingStatus === constant.BOOKING_STATUS.CANCELED ||
                                      package?.bookingStatus === constant.BOOKING_STATUS.canceled ||
                                      package?.bookingStatus === constant.BOOKING_STATUS.END_RETURN
                                    "
                                  >
                                    {{ "Reason" | translate }}:
                                    <span class="capitaAL_SVU"> {{ package?.cancelReason }}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ngx-slick-carousel>
                </div>
              </div>
            </div>
          </div>
          <div class="driver_name_info">
            <p *ngIf="bookingsData?.bookingData?.bookingType == 1 && bookingsData?.bookingData?.cancelReason">
              <span>{{ "Reason" | translate }}: </span>{{ bookingsData?.bookingData?.cancelReason }}
            </p>
            <p>{{ bookingsData?.bookingData?.userId?.firstName }} {{ bookingsData?.bookingData?.userId?.lastName }}</p>
            <p>{{ bookingsData?.bookingData?.userId?.countryCode }} {{ bookingsData?.bookingData?.userId?.phoneNo }}</p>
            <p *ngIf="bookingsData?.bookingData?.additionalInfo">{{ bookingsData?.bookingData?.additionalInfo }}</p>
          </div>
          <div class="fare" *ngIf="bookingsData?.bookingData?.bookingStatus !== constant?.BOOKING_STATUS?.CANCELED">
            <div class="fare-data">
              <span
                ><b>{{ "Sub Total Amount" | translate }} :</b>
              </span>
              <p>{{ (bookingsData?.bookingData?.riderFare + bookingsData?.bookingData?.loyalityAmount)?.toFixed(2) | currency: "USD" }}</p>
            </div>
            <div class="fare-data">
              <span
                ><b>{{ "Loyalty points" | translate }} : </b></span
              >
              <p>-{{ bookingsData?.bookingData?.loyalityAmount?.toFixed(2) | currency: "USD" }}</p>
            </div>
            <div class="fare-data">
              <span
                ><b>{{ "Payable Amount" | translate }} : </b></span
              >
              <p>{{ bookingsData?.bookingData?.riderFare?.toFixed(2) | currency: "USD" }}</p>
            </div>
            <div class="fare-data">
              <span
                ><b>{{ "Driver Tip" | translate }} : </b></span
              >
              <p>+{{ bookingsData?.bookingData?.tip?.toFixed(2) | currency: "USD" }}</p>
            </div>
            <!-- <div class="fare-data">
                <span>Tax : </span>
                <p>+{{bookingsData?.bookingData?.tax?.toFixed(2) | currency: "USD"}}</p>
              </div> -->
            <div class="fare-data">
              <span
                ><b>{{ "Grand Total" | translate }} : </b></span
              >
              <p>{{ (bookingsData?.bookingData?.riderFare + bookingsData?.bookingData?.tip)?.toFixed(2) | currency: "USD" }}</p>
            </div>
          </div>
          <div class="fare" *ngIf="bookingsData?.bookingData?.bookingStatus === constant?.BOOKING_STATUS?.CANCELED">
            <div class="fare-data">
              <span
                ><b>{{ "Cancellation Fee" | translate }} :</b>
              </span>
              <p>{{ bookingsData?.bookingData?.cancelAmount?.toFixed(2) | currency: "USD" }}</p>
            </div>
            <div class="fare-data">
              <span
                ><b>{{ "Driver Tip" | translate }} : </b></span
              >
              <p>+{{ bookingsData?.bookingData?.tip?.toFixed(2) | currency: "USD" }}</p>
            </div>
            <div class="fare-data">
              <span
                ><b>{{ "Grand Total" | translate }} : </b></span
              >
              <p>{{ (bookingsData?.bookingData?.cancelAmount + bookingsData?.bookingData?.tip)?.toFixed(2) | currency: "USD" }}</p>
            </div>
          </div>
          <div class="pymnt-d">
            <div class="pymnt-type pt-4">
              <p>
                <b>{{ "Payment Mode" | translate }}</b>
              </p>
              <p *ngIf="bookingsData?.bookingData?.paymentMode === constant?.PAYMENT_MODE?.WALLET">{{ "Wallet" | translate }}</p>
              <div *ngIf="bookingsData?.bookingData?.paymentMode !== constant?.PAYMENT_MODE?.WALLET" style="display: flex">
                <p>VISA</p>
                <p>****9099</p>
              </div>
            </div>
          </div>
          <div class="total_pay">
            <h5 *ngIf="bookingsData?.bookingData?.bookingStatus !== constant?.BOOKING_STATUS?.CANCELED">
              {{ (bookingsData?.bookingData?.riderFare + bookingsData?.bookingData?.tip)?.toFixed(2) | currency: "USD"
              }}<span> {{ "incl.Tax" | translate }}</span>
            </h5>
            <h5 *ngIf="bookingsData?.bookingData?.bookingStatus === constant?.BOOKING_STATUS?.CANCELED">
              {{ (bookingsData?.bookingData?.cancelAmount + bookingsData?.bookingData?.tip)?.toFixed(2) | currency: "USD"
              }}<span>{{ "incl. Tax" | translate }}</span>
            </h5>
          </div>
          <!-- <div class="commen_btn_outer review_driver">
            <button class="btn btn-primary" (click)="closeBookingStatusModal()">Cancel</button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="add_address" role="dialog" [ngStyle]="{ display: favAddModal, opacity: 1 }">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="favAddModal = 'none'">
          <span><i class="fa fa-times" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h3 class="add_card_title">{{ favAddPayload?.addressId ? ("Edit Address" | translate) : ("Add Address" | translate) }}</h3>
        <div class="card_card_form">
          <div class="add_card_input_itms addimg">
            <div class="addimgbg">
              <figure>
                <img src="assets/icons/street21.jpg" />
              </figure>
            </div>
            <input type="text" class="form-control" [(ngModel)]="favAddPayload.name" placeholder="{{ 'Place name' | translate }}" />
            <mat-error *ngIf="favAddressSubmitted && favAddPayload.name === ''">{{ "Place name is required" | translate }}.</mat-error>
          </div>
          <div class="add_card_input_itms">
            <div class="addimgbg">
              <figure>
                <img src="assets/icons/pointer21.jpg" />
              </figure>
            </div>
            <input
              matInput
              placeholder="{{ 'Search for your address' | translate }}"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="off"
              type="text"
              class="form-control"
              ngx-google-places-autocomplete
              #placesRef="ngx-places"
              (keyup)="onKeyUp($event.target.value)"
              (change)="onAddChanged($event.target.value)"
              (onAddressChange)="handleLocationChnage($event)"
              [(ngModel)]="favAddPayload.address"
            />
            <div class="addimgbg sec">
              <figure>
                <img src="assets/icons/map21.jpg" />
              </figure>
            </div>
            <mat-error *ngIf="favAddressSubmitted && favAddPayload.address === ''">{{ "Address is required" | translate }}.</mat-error>
          </div>
        </div>
      </div>
      <div class="commen_btn_outer">
        <button class="btn btn-primary" (click)="submitFavAdd()">{{ "Save" | translate }}</button>
      </div>
    </div>
  </div>
</div>
<!-- </div> -->
<div id="verification" class="modal" tabindex="-1" role="dialog" [ngStyle]="{ display: display }">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <form>
        <div class="modal-body">
          <h4>{{ "Let's verify your information" | translate }}</h4>
          <p>{{ "Security and safety are very important for us" | translate }}.</p>
          <div class="rb">
            <mat-radio-group aria-label="Select an option">
              <mat-radio-button value="phone" [checked]="true">{{ verificationData }}</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="flex signup_btn">
            <button class="btn btn-primary" (click)="sendOtp()">
              {{ "Continue" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<!-----end manage address popup-->

<div class="modal fade" id="add_payment" [ngStyle]="{ display: addCardModal, opacity: 1 }">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="AddCardModal('none')">
          <span aria-hidden="true ">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h3 class="add_card_title">{{ "Add Payment Card" | translate }}</h3>
        <div class="card_card_form">
          <div class="nsflex">
            <div class="add_card_input_itms mr-2">
              <label>{{ "First name" | translate }}</label>
              <input
                class="form-control"
                type="text"
                maxlength="50"
                (change)="onChangeFirst($event.target.value)"
                (keypress)="onKeyPress($event)"
                [formControl]="cardOwnerFirstName"
                placeholder="First name"
              />
              <mat-error *ngIf="cardSubmitted && cardOwnerFirstName.hasError('required')">
                {{ "First name" | translate }} {{ "is required" | translate }}.</mat-error
              >
              <mat-error *ngIf="cardSubmitted && validateFirstArr.includes(true)"> {{ "Please enter valid first name" | translate }}.</mat-error>
            </div>
            <div class="add_card_input_itms mr-2">
              <label>{{ "Last name" | translate }}</label>
              <input
                class="form-control"
                maxlength="50"
                (change)="onChangeSecond($event.target.value)"
                (keypress)="onKeyPress($event)"
                type="text"
                [formControl]="cardOwnerLastName"
                placeholder="Last name"
              />
              <mat-error *ngIf="cardSubmitted && cardOwnerLastName.hasError('required')">
                {{ "Last name" | translate }} {{ "is required" | translate }}.</mat-error
              >
              <mat-error *ngIf="cardSubmitted && cardOwnerLastName.value !== '' && validateFirstArr.includes(true)">
                {{ "Please enter valid last name" | translate }}.</mat-error
              >
            </div>
          </div>
          <div class="add_card_input_itms" style="width: 100%">
            <form [formGroup]="cardNumberGroup">
              <label>{{ "Card Number" | translate }}</label>
              <input
                class="form-control"
                [formControl]="getCardNumberControl()"
                [textMask]="{ mask: cardMaskFunction, guide: false, showMask: true }"
                placeholder="**** **** **** ****"
              />
              <div *ngIf="cardSubmitted">
                <p
                  style="color: red; text-align: left !important"
                  *ngIf="cardNumberGroup.status === 'INVALID' && !cardNumberGroup.controls['cardNumber'].hasError('required')"
                >
                  {{ "Card number is invalid" | translate }}.
                </p>
                <!-- <p style="color: green; text-align: left !important;" *ngIf="cardNumberGroup.status === 'VALID'">
                                  Card number is valid.</p> -->
                <p style="color: red; text-align: left !important" *ngIf="cardNumberGroup.controls['cardNumber'].hasError('required')">
                  {{ "Card number is required" | translate }}.
                </p>
              </div>
            </form>
          </div>
          <div class="nsflex">
            <div class="add_card_input_itms months mr-2">
              <label>{{ "Month" | translate }}</label>
              <select class="form-control" [formControl]="expiryMonth">
                <option [value]="null" selected disabled>{{ "Month" | translate }}</option>
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
              <mat-error *ngIf="cardSubmitted && expiryMonth.hasError('required')"> {{ "Expiry month is required" | translate }}.</mat-error>
            </div>
            <div class="add_card_input_itms mr-2">
              <label>{{ "Year" | translate }}</label>
              <select class="form-control" [formControl]="expiryYear">
                <option [value]="null" selected disabled>{{ "Year" | translate }}</option>
                <option [value]="y" *ngFor="let y of expiryYears; let i = index">{{ y }}</option>
              </select>
              <mat-error *ngIf="cardSubmitted && expiryYear.hasError('required')"> {{ "Expiry year is required" | translate }}.</mat-error>
            </div>
            <div class="add_card_input_itms">
              <label>{{ "CCV/CVV" | translate }}</label>
              <input
                [formControl]="cvv"
                (keypress)="keypresshandler($event)"
                placeholder="CCV/CVV"
                class="form-control"
                type="password"
                (copy)="(false)"
                (paste)="(false)"
                onKeyPress="if(this.value.length==4) return false;"
                placeholder=""
              />
              <mat-error *ngIf="cardSubmitted && cvv.hasError('required')"> {{ "CCV/CVV is required" | translate }}. </mat-error>
            </div>
          </div>
        </div>
      </div>
      <button class="add_card" style="cursor: pointer" (click)="addDebitCard()">Save</button>
    </div>
  </div>
</div>

<div id="addpayment" class="modal fade" role="dialog" [ngStyle]="{ display: addWalletMoneyModal, opacity: 1 }">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button " class="close" (click)="addWalletModal('none')">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
      <div class="modal-body">
        <h4>{{ "Add Wallet Money" | translate }}</h4>
        <div class="seachbar-payment">
          <mat-form-field [floatLabel]="'never'">
            <input
              class="form-control"
              (keypress)="keypresshandler($event)"
              min="1"
              [formControl]="addWalletAmount"
              matInput
              type="text"
              placeholder="Amount"
            />
          </mat-form-field>
          <mat-error *ngIf="walletMoneySubmitted && addWalletAmount.hasError('required')">{{ "Wallet amount is required" | translate }}. </mat-error>
        </div>
        <ul class="payment-lt">
          <li>
            <div class="list_inner">
              <mat-radio-group aria-label="Select an option">
                <mat-radio-button
                  (change)="onWalletCardChange(card)"
                  [checked]="card?.isSelected"
                  [value]="i + 1"
                  *ngFor="let card of allSavedCard; let i = index"
                >
                  <div class="card_detailcs">
                    <div>
                      <h3>
                        {{ card?.cardNumber }}<span>{{ card?.brand }} - {{ card?.expiryDate.slice(0, 2) }}/{{ card?.expiryDate.slice(2) }}</span>
                      </h3>
                    </div>
                    <div class="dlt-radio">
                      <h3 *ngIf="card?.isDefault">
                        <span class="text-success" style="margin-left: 86px"> {{ "PRIMARY CARD" | translate }}</span>
                      </h3>
                    </div>
                  </div>
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </li>
        </ul>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="addWalletMoney()">{{ "Proceed" | translate }}</button>
      </div>
    </div>
  </div>
</div>

<div id="loyalitymodal" class="modal fade" role="dialog" [ngStyle]="{ display: redeemLoyalityModal, opacity: 1 }">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button " class="close" (click)="redeemLoyalityModals('none')">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
      <div class="modal-body">
        <p *ngIf="cancelCounter !== 0">{{ "You have only" | translate }} {{ cancelCounter }} {{ "seconds left to get code scanned" | translate }}.</p>
        <h4 class="loyal-title">{{ selectedLoyalityData?.loyalityId?.companyName }}</h4>
        <div class="seachbar-payment">
          <figure class="img-lylty">
            <img [src]="imgBaseurl + selectedLoyalityData?.loyalityId?.couponIcon" />
          </figure>
        </div>
        <div>
          <div class="lylty-d">
            <p>{{ "Total Points" | translate }}</p>
            <p>
              {{
                selectedLoyalityData?.loyalityId?.couponType === 1
                  ? "$" + selectedLoyalityData?.loyalityId?.couponValue
                  : selectedLoyalityData?.loyalityId?.couponValue
              }}
              {{ selectedLoyalityData?.loyalityId?.couponType === 2 ? "%" : "" }}
            </p>
          </div>
          <div class="lylty-d" *ngIf="!loyaltyBarCode">
            <p>{{ "Expiry date" | translate }}</p>
            <p>{{ selectedLoyalityData?.loyalityId?.expiryDate | date: "M/d/yy" }}</p>
          </div>
        </div>
        <div class="cong" *ngIf="loyaltyBarCode">
          <h6>{{ "Congratulations" | translate }} !!!</h6>
          <p>{{ "Your coupon has been redeemed successfully" | translate }}.</p>
        </div>
        <div class="barcode" *ngIf="loyaltyBarCode">
          <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tag"
            viewBox="0 0 16 16">
            <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z" />
            <path
              d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586l7 7L13.586 9l-7-7H2v4.586z" />
          </svg> -->
          <figure><img [src]="imgBaseurl + loyaltyBarCode" alt="" /></figure>
          <!-- <ngx-barcode [bc-value]="loyaltyBarCode" [bc-display-value]="true" 
            >
          </ngx-barcode> -->
        </div>
        <div *ngIf="!loyaltyBarCode">
          <button class="btn btn-primary rdm" (click)="redeemLoyalityPoint()">{{ "Reedem" | translate }}</button>
        </div>
        <p *ngIf="loyaltyBarCode">{{ "Show the above Coupon code to the payment counter to redeem" | translate }}.</p>
      </div>
    </div>
  </div>
</div>

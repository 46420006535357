<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="conta_iner">
    <div class="nav_inner">
      <div class="logo">
        <a routerLink="/uhomepage">
          <figure>
            <img src="assets/uimages/logo.png" alt="" />
          </figure>
        </a>
      </div>
      <div class="lang-main">
        <div class="togglm">
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
              <li [ngClass]="{'nav_active dd-link shutter half': navItem?.isActiveClass === true}"
                *ngFor="let navItem of navLinkArr">
                <a routerLink="/{{navItem?.url}}" [ngClass]="{'active': navItem?.isActiveClass === true}"
                  routerLinkActive="active">{{navItem?.name}}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="lm">
          <div class="language-select" (click)="showLang = showLang?false:true">
            <div class="sel-bar" *ngIf="languageService?.selectedLang">
              <figure><img *ngIf="languageService?.selectedLang?.logo" [src]="baseUrl+languageService?.selectedLang?.logo">{{languageService?.selectedLang?.code?.toUpperCase()}}
              </figure>
            </div>
            <p *ngIf="!languageService?.selectedLang">Select Language</p>
            <div class="ndpicon">
              <i *ngIf="!showLang" class="fa fa-caret-down" aria-hidden="true"></i>
              <i *ngIf="showLang" class="fa fa-caret-up" aria-hidden="true"></i>
            </div>
          </div>
          <div class="dp-lang" *ngIf="showLang">
            <figure *ngFor="let language of languageService?.allLanguages" (click)="selectLang(language)">
              <img *ngIf="language?.logo" [src]="baseUrl+language?.logo">{{language?.code?.toUpperCase()}}
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
<div class="login_wrap signup">
  <app-unavbar id="navbar"></app-unavbar>
  <!-- <div class="signupinner">
        <div class="conta_iner">
            <div class="flex">
                <div class="haeder_left">
                    <figure class="logo_cm" style="cursor: pointer;" (click)="goTouhomepage()">
                        <img src="assets/uimages/logo.png" />
                    </figure>
                </div>
                <div class="back_home">
                    <button class="driverap_btn" style="cursor: pointer;" (click)="goTouhomepage()">
                        Back To Home
                    </button>
                </div>
            </div>
        </div>
    </div> -->

  <div class="login" *ngIf="nextPopup == 0">
    <div class="conta_iner">
      <div class="login_main">
        <div class="login_form driver-type">
          <div class="login_img_sec">
            <figure class="login_img">
              <img src="assets/uimages/signup-icon.svg" />
            </figure>
            <div class="type-text">
              <h4>{{ "Select your driver type" | translate }}</h4>
            </div>
          </div>
          <button (click)="goToSignupStep('RIDE')" class="btn btn-primary margin_top20">
            {{ "Rides" | translate }}
          </button>
          <button (click)="goToSignupStep('DELIVERY')" class="btn btn-primary margin_top20">
            {{ "Deliveries" | translate }}
          </button>
          <button (click)="goToSignupStep('BOTH')" class="btn btn-primary margin_top20">
            {{ "Rides and Deliveries" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="login" *ngIf="nextPopup == 1">
    <div class="conta_iner">
      <div class="login_main">
        <div class="login_form">
          <div class="login_img_sec">
            <!-- <figure class="login_img">
                            <img src="assets/uimages/signup-icon.svg" />
                        </figure> -->
            <h4>{{ "APPTUNIX Drivers Pre-Registration Form" | translate }}</h4>
            <p>
              {{ "Dear friend, thank you for your interest in being a driver with us" | translate }}.
              {{ "We hope you will be amazed at the opportunities APPTUNIX offers" | translate }}.
              {{ "Please fill up this pre-registration form and send it to us as soon as possible" | translate }}.
              {{
                "We will send you a special notification and an invitation for you to complete your application as soon as we start operations in your city"
                  | translate
              }}. {{ "Thank you and let's make a lot of money together" | translate }}!
            </p>
          </div>

          <form class="form_control_form Pre-Registration" [formGroup]="signupForm">
            <div class="form-group">
              <mat-form-field [floatLabel]="'never'">
                <label>{{ "First name" | translate }}</label>
                <input
                  class="form-control"
                  maxlength="50"
                  (change)="onChangeFirst($event.target.value)"
                  (keypress)="onKeyPress($event)"
                  formControlName="firstName"
                  matInput
                  type="text"
                  placeholder="{{ 'First name' | translate }}"
                />
              </mat-form-field>
              <mat-error *ngIf="submitted && signupForm.controls['firstName'].hasError('required')">
                {{ "First name is required" | translate }}.</mat-error
              >
              <mat-error *ngIf="submitted && validateFirstArr.includes(true)"> {{ "Please enter valid first name" | translate }}.</mat-error>
            </div>
            <div class="form-group">
              <mat-form-field [floatLabel]="'never'">
                <label>{{ "Last name" | translate }}</label>
                <input
                  class="form-control"
                  maxlength="50"
                  (change)="onChangeSecond($event.target.value)"
                  (keypress)="onKeyPress($event)"
                  formControlName="lastName"
                  matInput
                  type="text"
                  placeholder="{{ 'Last name' | translate }}"
                />
              </mat-form-field>
              <mat-error *ngIf="submitted && signupForm.controls['lastName'].hasError('required')">
                {{ "Last name is required" | translate }}.</mat-error
              >
              <mat-error *ngIf="submitted && validateSecondArr.includes(true)"> {{ "Please enter valid last name" | translate }}.</mat-error>
            </div>
            <div class="form-group">
              <mat-form-field [floatLabel]="'never'">
                <label class="algn-lft">{{ "Street no. and name" | translate }}.</label>
                <input class="form-control" formControlName="street" matInput type="text" placeholder="{{ 'Street no. and name' | translate }}." />
              </mat-form-field>
              <mat-error *ngIf="submitted && signupForm.controls['street'].hasError('required')">
                {{ "Street no. and name are required" | translate }}.</mat-error
              >
            </div>
            <div class="form-group">
              <mat-form-field [floatLabel]="'never'">
                <label class="algn-lft">{{ "Additional details (unit #, name of business, etc)" | translate }}</label>
                <input
                  class="form-control"
                  formControlName="localAddress"
                  matInput
                  type="text"
                  placeholder="{{ 'Additional details (unit #, name of business, etc)' | translate }}"
                />
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field [floatLabel]="'never'">
                <label class="algn-lft">{{ "City, state/province and country" | translate }}.</label>
                <input
                  class="form-control"
                  matInput
                  placeholder="{{ 'Search for your city, state/province and country' | translate }}"
                  autocorrect="off"
                  autocapitalize="off"
                  spellcheck="off"
                  type="text"
                  ngx-google-places-autocomplete
                  #placesRef="ngx-places"
                  (keyup)="onKeyUp($event.target.value)"
                  (change)="onAddChanged($event.target.value)"
                  (onAddressChange)="handleAddressChnage($event)"
                  formControlName="address"
                />
              </mat-form-field>
              <mat-error *ngIf="submitted && signupForm.controls['address'].hasError('required')">
                {{ "City, state/province and country" | translate }} {{ "are required" | translate }}.</mat-error
              >
            </div>
            <div class="form-group">
              <mat-form-field [floatLabel]="'never'">
                <label class="algn-lft">{{ "Zip code" | translate }}</label>
                <input class="form-control" formControlName="zipCode" matInput type="text" placeholder="{{ 'Zip code' | translate }}" />
              </mat-form-field>
              <mat-error *ngIf="submitted && signupForm.controls['zipCode'].hasError('required')">
                {{ "Zip code is required" | translate }}.</mat-error
              >
            </div>
            <div class="form-group">
              <mat-form-field [floatLabel]="'never'">
                <label class="algn-lft">{{ "Email address" | translate }}</label>
                <input class="form-control" formControlName="email" matInput type="email" placeholder="{{ 'Email address' | translate }}" />
              </mat-form-field>
              <mat-error *ngIf="submitted && signupForm.controls['email'].hasError('required')"> {{ "Email is required" | translate }}.</mat-error>
              <mat-error *ngIf="submitted && signupForm.controls['email'].hasError('pattern')">
                {{ "Please enter valid email" | translate }}.</mat-error
              >
            </div>
            <div class="form-group number">
              <!-- <mat-form-field [floatLabel]="'never'"> -->
              <label class="algn-lft">{{ "Phone number" | translate }}</label>
              <ngx-intl-tel-input
                [inputId]="'adsasd'"
                matInput
                [cssClass]="'custom'"
                [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="true"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="CountryISO.Canada"
                [maxLength]="15"
                [tooltipField]="TooltipLabel.Name"
                [phoneValidation]="true"
                [separateDialCode]="separateDialCode"
                name="phone"
                formControlName="phoneNo"
              >
              </ngx-intl-tel-input>
              <!-- </mat-form-field> -->
              <mat-error *ngIf="submitted && signupForm.controls['phoneNo'].hasError('required')">
                {{ "Phone number is required" | translate }}.</mat-error
              >
              <mat-error *ngIf="submitted && signupForm.controls['phoneNo'].hasError('validatePhoneNumber')">
                {{ "Please enter valid number" | translate }}.</mat-error
              >
            </div>
            <div class="form-group">
              <mat-form-field [floatLabel]="'never'">
                <label class="algn-lft">{{ "DL number" | translate }}</label>
                <input class="form-control" formControlName="dlNumber" matInput type="text" placeholder="{{ 'DL number' | translate }}" />
              </mat-form-field>
              <mat-error *ngIf="submitted && signupForm.controls['dlNumber'].hasError('required')">
                {{ "DL number is required" | translate }}.</mat-error
              >
            </div>
            <!-- <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <input class="form-control" formControlName="password" matInput type="password"
                                    placeholder="Password" />
                            </mat-form-field>
                            <mat-error *ngIf="submitted && signupForm.controls['password'].hasError('required')">
                                Password is required.</mat-error>
                            <mat-error *ngIf="submitted && signupForm.controls['password'].hasError('minlength')">
                                Password should be 6 character long.</mat-error>
                        </div>
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <input class="form-control" formControlName="confirmPassword" matInput type="password"
                                    placeholder="Confirm Password" />
                            </mat-form-field>
                            <mat-error *ngIf="submitted && signupForm.controls['confirmPassword'].hasError('required')">
                                Confirm password is required.</mat-error>
                            <mat-error
                                *ngIf="submitted && signupForm.controls['confirmPassword'].hasError('mustMatch')">
                                Password and confirm password must match.</mat-error>
                            <mat-error
                                *ngIf="submitted && signupForm.controls['confirmPassword'].hasError('minlength')">
                                Password should be 6 character long.</mat-error>
                        </div> -->
            <div class="form-group check pre-check">
              <mat-checkbox formControlName="term" class="example-margin">
                {{ "By clicking this box you agree to share this information with APPTUNIX" | translate }}
                {{ "and receive notifications and announcements over email or SMS" | translate }}.</mat-checkbox
              >
              <mat-error *ngIf="submitted && signupForm.controls['term'].hasError('required')"> {{ "Checkbox is required" | translate }}.</mat-error>
            </div>
            <div class="flex submit_btn">
              <button class="btn btn-primary" (click)="goToVerificationPopup()">
                {{ "Submit" | translate }}
              </button>
            </div>
          </form>
          <!-- <p class="dont_account">
                        Do you have an account? <a (click)="goTologin()">Login</a>
                    </p> -->
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="login_images"></div> -->
</div>
<div id="verification" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <form [formGroup]="verificationTypeForm">
        <div class="modal-body">
          <h4>{{ "Let's verify your information" | translate }}</h4>
          <p>{{ "Security and safety are very important for us" | translate }}.</p>
          <div class="rb">
            <mat-radio-group aria-label="Select an option" formControlName="verifyBy">
              <mat-radio-button value="email">{{ email }}</mat-radio-button>
              <mat-radio-button value="phone" [checked]="true">{{ phoneNo }}</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="flex signup_btn">
            <button class="btn btn-primary" (click)="goToriderotppage()">
              {{ "Continue" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div id="verifyPassword" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <form [formGroup]="driverVerificationForm">
        <div class="modal-body">
          <h4>{{ "We will restor your account when we verify your information and identity" | translate }}.</h4>
          <p>{{ "Security and safety are very important for us" | translate }}.</p>
          <div class="rb">
            <input type="password" placeholder="{{ 'Enter your old password' | translate }}" formControlName="password" />
          </div>
          <div class="flex signup_btn">
            <button class="btn btn-primary" (click)="goToriderotppage()">
              {{ "Continue" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language/language.service';
import { environment } from 'src/environments/environment';
import { Constant } from '../core/constant';
import { MustMatch } from '../core/must-match.validator';
import { CommonService } from '../services/common/common.service';
import { HttpsService } from '../services/https/https.service';
@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.scss']
})
export class ForgetpasswordComponent implements OnInit {
  changePassForm: FormGroup
  changePassSubmitted: boolean = false;
  passwordResetToken: any;
  languageCode: string;
  showLang: boolean = false;
  baseUrl = environment.imageUrl;
  isDriver: boolean = false;

  constructor(public commonService: CommonService,
    private router: Router,
    private http: HttpsService,
    private constant: Constant,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private location: LocationStrategy,
    public translate: TranslateService,
    public languageService: LanguageService) {
      this.route.queryParams.subscribe((res: any) => {
        if(res.resetToken !== undefined) {
          this.passwordResetToken = res.resetToken;
        }
        if(res.isDriver !== undefined) {
          this.isDriver = true;
        }
      })
      if (localStorage.languageCode !== undefined) {
        this.languageCode = localStorage.getItem('languageCode');
      }
      this.translate.use(this.languageCode);
      this.languageService.translateLanguage(this.languageCode);
    }

  ngOnInit(): void {
    this.changePassForm = this.fb.group({
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
    }, {
      validator: MustMatch('newPassword', 'confirmPassword')
    });
    this.preventBackButton();
  }

  selectLang(language) {
    this.languageService.selectedLang = language;
    this.languageService.translateLanguage(language.code);
    this.showLang = false;
  }

  preventBackButton() {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }
  goTouhomepage() {
    this.router.navigate(["/goTouhomepage"]);
  }

  changePassword() {
    this.changePassSubmitted = true;
    if(this.changePassForm.valid) {
      var param = {
        // "passwordResetToken":this.passwordResetToken,
        "resetToken": this.passwordResetToken,
        "password":this.changePassForm.value.confirmPassword
      }
      if(this.isDriver) {
        this.http.httpPost('forgotChangePassword',param).subscribe((res: any) => {
          if(res.statusCode == 200) {
            this.commonService.OpenNotificationModal('success','top-end',res.message);
            this.router.navigate(['/login']);
          }
        })
      } else {
        this.http.httpPostUser('forgotChangePassword',param).subscribe((res: any) => {
          if(res.statusCode == 200) {
            this.commonService.OpenNotificationModal('success','top-end',res.message);
            this.router.navigate(['/riderlogin']);
          }
        })
      }
    }
  }

}

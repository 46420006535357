<div class="riderequest">
  <div class="map">
    <agm-map [latitude]="pickup.lat" [longitude]="pickup.lng" [zoom]="zoom" (mapReady)="onMapReady($event)">
      <div *ngIf="!isDriveingStart">
        <agm-overlay
          *ngFor="let driver of nearByDriversData"
          [latitude]="driver?.latitude"
          [longitude]="driver?.longitude"
          [bounds]="{ x: { latitude: -0.003, longitude: -0.0052 }, y: { latitude: 0.003, longitude: 0.0052 } }"
        >
          <div>
            <img style="cursor: pointer; width: 50px; height: 50px" [src]="ubericon" />
          </div>
        </agm-overlay>
      </div>
      <div *ngFor="let pick of pickupfieldsArr">
        <agm-marker
          *ngIf="showPickMarker && pick?.lat !== 0 && pick?.lng !== 0"
          [iconUrl]="originMarkerIcon"
          [latitude]="pick?.lat"
          [longitude]="pick?.lng"
          [markerDraggable]="true"
          (dragEnd)="markerDragEndOrigin($event, pick)"
        ></agm-marker>
      </div>
      <div *ngFor="let drop of allPackagesDetail">
        <agm-marker
          *ngIf="showDropMarker && drop?.addressDetails?.itemDropup?.lat !== 0 && drop?.addressDetails?.itemDropup?.lng !== 0"
          [iconUrl]="destinationMarkerIcon"
          [latitude]="drop?.addressDetails?.itemDropup?.lat"
          [longitude]="drop?.addressDetails?.itemDropup?.lng"
          [markerDraggable]="true"
          (dragEnd)="markerDragEndDestination($event, drop)"
        ></agm-marker>
      </div>

      <!-- <div *ngIf="bookingResponseData?.bookingStatus === 'ARRIVED'">
                <agm-marker [iconUrl]="driverIcon" [latitude]="bookingResponseData.pickUplatitude"
                    [longitude]="bookingResponseData.pickUplongitude" [markerDraggable]="false"></agm-marker>
            </div> -->
      <agm-direction
        *ngIf="showDirections"
        [origin]="origin"
        [destination]="destination"
        [renderOptions]="renderOptions"
        [markerOptions]="markerOptions"
        [waypoints]="waypoints"
      >
      </agm-direction>
    </agm-map>
  </div>
  <div class="login_map_toper flex">
    <div class="timestart" (click)="goToNotification()">
      <div class="bell"><i class="fa fa-bell" aria-hidden="true"></i></div>
      <div class="timesup" *ngIf="messagingService.notificationCount > 0">{{ messagingService.notificationCount }}</div>
    </div>
    <div class="nametext" (click)="goToprofilesetup()">
      <div class="login_map_toper_left">
        <figure>
          <img class="proImage" *ngIf="!user?.image" src="assets/images/user.png" />
          <img class="proImage" *ngIf="user?.image" [src]="baseUrl + user?.image" />
        </figure>
      </div>
      <div class="login_map_toper_right">
        <h4>{{ user?.firstName ? user?.firstName : "" + " " + user?.lastName ? user?.lastName : "" }}</h4>
      </div>
      <div class="fa_arrow"><i class="fa fa-angle-down" aria-hidden="true"></i></div>
    </div>
  </div>
  <div class="ride_request_main">
    <div class="ride_request_right">
      <div class="ride_request_right2">
        <figure class="ride_request_logo" (click)="continue(0)">
          <img src="assets/uimages/logo.png" />
        </figure>
      </div>
      <div class="ride_request_right2 fbtn">
        <figure class="ride_request_logo" style="cursor: pointer" (click)="goToHome()">
          <img src="assets/uimages/logo.png" />
        </figure>
        <div class="multiple_content">
          <div class="lm">
            <div class="language-select" (click)="showLang = showLang ? false : true">
              <div class="sel-bar" *ngIf="languageService?.selectedLang">
                <figure>
                  <img *ngIf="languageService?.selectedLang?.logo" [src]="baseUrl + languageService?.selectedLang?.logo" />{{
                    languageService?.selectedLang?.code?.toUpperCase()
                  }}
                </figure>
              </div>
              <p *ngIf="!languageService?.selectedLang">Select Language</p>
              <div class="ndpicon">
                <i *ngIf="!showLang" class="fa fa-caret-down" aria-hidden="true"></i>
                <i *ngIf="showLang" class="fa fa-caret-up" aria-hidden="true"></i>
              </div>
            </div>
            <div class="dp-lang" *ngIf="showLang">
              <figure *ngFor="let language of languageService?.allLanguages" (click)="selectLang(language)">
                <img *ngIf="language?.logo" [src]="baseUrl + language?.logo" />{{ language?.code?.toUpperCase() }}
              </figure>
            </div>
          </div>
          <div class="back_home hmbtn">
            <button class="driverap_btn" style="cursor: pointer" (click)="goToHome()">
              {{ "Back To Home" | translate }}
            </button>
          </div>
        </div>
      </div>
      <!-- <div class="ride_request_right2 fbtn">
                <figure class="ride_request_logo" style="cursor: pointer;" (click)="goToHome()">
                    <img src="assets/uimages/logo.png" />
                </figure>
                <div class="back_home hmbtn">
                    <button class="driverap_btn" style="cursor: pointer;" (click)="goToHome()">
                        {{'Back To Home' | translate}}
                    </button>
                </div>
            </div> -->
      <div class="request_ride_inner" *ngIf="nextPopup == 0">
        <div class="select_ride_cate">
          <mat-radio-group aria-label="Select an option">
            <mat-radio-button value="1" style="margin-right: 15px" class="choose_ride_radio" (click)="goToriderequest()">
              <div class="delivery-tab">
                <h4>{{ "Book a Ride" | translate }}</h4>
                <figure>
                  <img src="assets/images/1car.png" />
                </figure>
              </div>
            </mat-radio-button>
            <mat-radio-button value="2" class="choose_ride_radio">
              <div class="delivery-tab activedt">
                <h4>{{ "Book a Delivery" | translate }}</h4>
                <figure>
                  <img src="assets/images/2car.png" />
                </figure>
              </div>
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <!----// start Rider Pikup Location Input bxes sec  //-->
        <!-- <form class="choose_pickup_input" [formGroup]="bookingForm"> -->
        <div class="title_bar">
          <h2>{{ "Request a Delivery now" | translate }}</h2>
        </div>
        <form [formGroup]="pickupForm">
          <div class="form-group choose_pickup_outer">
            <div class="form_grp_request_left"></div>
            <div class="form_grp_request_right">
              <mat-label class="ride_request">{{ "Choose your pick up" | translate }}</mat-label>
              <div class="multiple_add_outer" *ngFor="let pickup of pickupfieldsArr; let i = index">
                <mat-form-field class="example-full-width">
                  <div class="multiple-add">
                    <div class="blue_circle"></div>
                    <input
                      matInput
                      class="fv-icons"
                      placeholder="{{ 'Search for pick up location' | translate }}"
                      autocorrect="off"
                      autocapitalize="off"
                      spellcheck="off"
                      type="text"
                      ngx-google-places-autocomplete
                      #placesRef="ngx-places"
                      (change)="pickupChange(pickup)"
                      (keyup)="onKeyUp($event.target.value, pickup)"
                      (onAddressChange)="handlePickUpChnage($event, pickup)"
                      formControlName="{{ pickup?.name }}"
                    />
                    <figure class="add-fav-icon" (click)="goToFavAdd('pick', 20, i)">
                      <img src="assets/images/fav-add-icon.png" />
                    </figure>
                    <div *ngIf="dropupfieldsArr.length === 1">
                      <!-- <button class="rotate" *ngIf="i !== pickupfieldsArr?.length - 1 || i > 2"
                                                (click)="removePickupField(i)">+</button>
                                            <button [disabled]="pickupForm.controls[pickup?.name].hasError('required')"
                                                class="plusBtn" *ngIf="i === pickupfieldsArr?.length - 1 && i<= 2"
                                                (click)="addPickupField()">+</button> -->
                    </div>
                  </div>
                  <p style="color: red" *ngIf="rideSubmited === true && pickupForm?.controls[pickup?.name].hasError('required')">
                    {{ "Pickup address is required" | translate }}.
                  </p>
                  <!-- <p style="color: red;"
                                        *ngIf="rideSubmited === true && !isPickupChanged && !bookingForm.controls['ridePickUpAddress'].hasError('required')">
                                        Please enter valid source.</p> -->
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
        <!-- <form [formGroup]="dropupForm">
                    <div class="form-group choose_pickup_outer">
                        <div class="form_grp_request_left">
                        </div>
                        <div class="form_grp_request_right sec">
                            <mat-label class="ride_request">Choose your drop off </mat-label>
                            <div class="multiple_add_outer" *ngFor="let drop of dropupfieldsArr; let i = index">
                                <mat-form-field class="example-full-width">
                                    <div class="multiple-add">
                                        <div class="red_circle"></div>
                                        <input matInput placeholder="Search for drop off location" autocorrect="off"
                                            autocapitalize="off" spellcheck="off" type="text"
                                            ngx-google-places-autocomplete #placesRef="ngx-places"
                                            (change)="dropChange()" (onAddressChange)="handleDropChnage($event,drop)"
                                            formControlName="{{drop?.name}}" />
                                        <div *ngIf="pickupfieldsArr.length === 1">
                                            <button class="rotate" *ngIf="i !== dropupfieldsArr?.length - 1 || i > 2"
                                                (click)="removeDropupField(i)">+</button>
                                            <button [disabled]="dropupForm.controls[drop?.name].hasError('required')"
                                                class="plusBtn" *ngIf="i === dropupfieldsArr?.length - 1 && i <= 2"
                                                (click)="addDropupField()">+</button>
                                        </div>
                                    </div>
                                    <p style="color: red;"
                                        *ngIf="rideSubmited === true && dropupForm.controls[drop?.name].hasError('required')">
                                        Drop off address is required.</p>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </form> -->
        <!----// End Rider Pikup Location Input bxes sec //-->
        <div class="btn_divde margin_top25">
          <div class="btn_outer">
            <button [ngClass]="{ 'btn-active': activeBtn === 'Now', btn: true, 'btn-outline': activeBtn != 'Now' }" (click)="startNow('10')">
              {{ "Start now" | translate }}
            </button>
          </div>
          <div class="btn_outer">
            <button [ngClass]="{ 'btn-active': activeBtn === 'Later', btn: true, 'btn-outline': activeBtn != 'Later' }" (click)="openDateTimeModal()">
              {{ "Schedule a delivery" | translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="request_ride_inner part2" *ngIf="nextPopup == 1">
        <div class="car_using_main">
          <div class="car_using_left">
            <h4>{{ "Package Size" | translate }}</h4>
            <ul class="car_using_left_inner">
              <li
                [ngClass]="{ active_car_bx: selectedSizeIndex === i }"
                *ngFor="let packageSize of packageSizeData; let i = index"
                (click)="selectPackageSize(packageSize, i)"
              >
                <div class="yellow_bg">
                  <figure>
                    <img [src]="baseUrl + packageSize?.blackImage" alt="" />
                  </figure>
                </div>
                <h5>{{ packageSize?.packageSizeName }}</h5>
                <p>{{ packageSize?.size }}</p>
              </li>
            </ul>
          </div>
        </div>

        <div class="car_using_main margin_top30">
          <div class="car_using_left">
            <h4 class="choose-v">{{ "Choose Vehicle" | translate }}</h4>
            <div class="tabel-responsive">
              <ul class="car_using_left_inner sec" *ngIf="vehicleCategoryData?.length > 0">
                <li
                  [ngClass]="{ active_car_bx: selectedRideIndex === i }"
                  *ngFor="let category of vehicleCategoryData; let i = index"
                  (click)="selectVehicle(category, i)"
                >
                  <div class="categoryinner">
                    <div class="yellow_bg">
                      <figure>
                        <img [src]="category?.vehicleImage" />
                      </figure>
                    </div>
                    <h5>{{ category?.vehicleCategory }}</h5>
                    <p *ngIf="category?.booKingAmount !== null" [ngClass]="{ discount: category?.discount !== undefined }">
                      <span>{{ category?.booKingAmount }}</span>
                    </p>
                    <p class="mb-0" *ngIf="category?.booKingAmount !== null && category?.discount !== undefined">
                      <span>{{ category?.discount }}</span>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <!-- <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="vehicleCarouselConfig">
                                <div [ngClass]="{'active_car_bx': selectedRideIndex === i}" ngxSlickItem
                                *ngFor="let category of vehicleCategoryData; let i = index" class="slide">
                                    <div (click)='selectVehicle(category,i);' class="slide-bg">
                                        <div class="yellow_bg">
                                            <figure>
                                                <img [src]="category?.vehicleImage" />
                                            </figure>
                                        </div>
                                        <h5>{{category?.vehicleCategory}}</h5>
                                        <p *ngIf="category?.booKingAmount !== null"
                                            [ngClass]="{'discount':category?.discount !== undefined}">US
                                            $<span>{{category?.booKingAmount}}</span></p>
                                        <p class="mb-0"
                                            *ngIf="category?.booKingAmount !== null && category?.discount !== undefined">US
                                            $<span>{{category?.discount}}</span></p>
                                    </div>
                                </div>
                            </ngx-slick-carousel> -->

            <h3 class="colorRed" style="text-align: center" *ngIf="vehicleCategoryData.length <= 0">
              {{ "No driver available nearby, please try again later" | translate }}.
            </h3>
          </div>
        </div>

        <div class="add_promo_sec">
          <div class="visa_card chng arrw" style="cursor: pointer" (click)="openPaymentOptions(2)">
            <div class="card_no">
              <p *ngIf="!isWalletSelected && !selectedCardDetails?.cardNumber">
                {{ defaultCardDetails?.cardNumber }} <span> {{ defaultCardDetails?.brand }}</span>
              </p>
              <p *ngIf="!isWalletSelected && selectedCardDetails?.cardNumber">
                {{ selectedCardDetails?.cardNumber }} <span> {{ selectedCardDetails?.brand }}</span>
              </p>
              <p
                [ngClass]="!(selectedCardDetails || isWalletSelected) ? 'colorRed' : 'abc'"
                *ngIf="!isWalletSelected && !defaultCardDetails?.cardNumber && !selectedCardDetails?.cardNumber"
              >
                {{ "Select Payment Method" | translate }}
              </p>
              <p *ngIf="isWalletSelected">{{ "Wallet" | translate }}</p>
            </div>
            <div class="cni"><i class="fa fa-angle-down" aria-hidden="true"></i></div>
          </div>
          <div class="add_promo">
            <a style="cursor: pointer" (click)="loyalityModals('block')"
              ><span><img src="assets/uimages/promo_icon.png" alt="" /></span>{{ totalLoyality === "" ? "Loyalty Money" : totalLoyality }}</a
            >
          </div>
        </div>
        <div class="btn_divde custom_margin">
          <div class="btn_outer inactive">
            <button class="btn btn-secondary" (click)="continue('11')">
              {{ "Back" | translate }}
            </button>
          </div>
          <div class="btn_outer">
            <button class="btn btn-primary" (click)="goToPreviewBooking(12)">
              <!-- (click)="continue('3')" -->
              {{ "Continue" | translate }}
            </button>
          </div>
        </div>
      </div>

      <div class="request_ride_inner part2" *ngIf="nextPopup == 20">
        <div class="car_using_main">
          <div class="nsfavret">
            <div class="fav-header">
              <div class="facback" style="cursor: pointer" (click)="continue(addressType === 'drop' ? 11 : 0)"><a>Back</a></div>
              <div class="headerfav">
                <h5>My Favourites</h5>
              </div>
              <div class="addfav">
                <h6 (click)="createFavAddForm(); continue(21)" style="cursor: pointer">Add Favourite</h6>
              </div>
            </div>
            <div class="myy-favret">
              <ul>
                <li style="cursor: pointer" (click)="patchFavAddress(0, add)" *ngFor="let add of favAddData">
                  <div class="favret-main">
                    <div class="favret-left">
                      <i aria-hidden="true" class="fa fa-home" *ngIf="add?.name.toLowerCase() === 'home'"></i>
                      <i aria-hidden="true" class="fa fa-shopping-bag" *ngIf="add?.name.toLowerCase() === 'work'"></i>
                      <i
                        aria-hidden="true"
                        class="fa fa-map-marker"
                        *ngIf="add?.name.toLowerCase() !== 'work' && add?.name.toLowerCase() !== 'home'"
                      ></i>
                    </div>

                    <div class="favret-right">
                      <h3
                        style="font-weight: 500"
                        *ngIf="add?.name.toLowerCase() === 'work' || add?.name.toLowerCase() === 'home' || add?.name.toLowerCase() === 'office'"
                      >
                        {{ add?.name | titlecase }}
                      </h3>
                      <h3
                        style="font-weight: 500"
                        *ngIf="add?.name.toLowerCase() !== 'work' && add?.name.toLowerCase() !== 'home' && add?.name.toLowerCase() !== 'office'"
                      >
                        {{ add?.type | titlecase }}
                      </h3>
                      <p>{{ add?.address }}</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <h6 style="text-align: center; color: black; font-size: 16px" *ngIf="favAddData?.length === 0">Data not found!</h6>
            <nav aria-label="..." class="pagination" *ngIf="favAddData?.length !== 0" style="float: right">
              <ul class="pagination justify-content-end mb-0">
                <li class="{{ previousBtnEnabledA ? 'page-item' : 'page-item disabled' }}">
                  <a class="page-link" href="javascript:void(0)" (click)="onPrevPageA()">
                    <i></i>
                    <span>{{ "Previous" | translate }}</span>
                  </a>
                </li>
                <li
                  *ngFor="let a of [].constructor(totalPagesA); let i = index"
                  class="{{ currentPageA === i + 1 ? 'page-item active' : 'page-item' }}"
                >
                  <a
                    class="page-link"
                    *ngIf="currentPageA < i + 1 ? i + 1 - currentPageA < 3 : currentPageA - (i + 1) < 3"
                    href="javascript:void(0)"
                    (click)="onChnagePageA(i + 1)"
                    >{{ i + 1 }}</a
                  >
                </li>
                <li class="{{ nextBtnEnabledA ? 'page-item' : 'page-item disabled' }}">
                  <a class="page-link" href="javascript:void(0)" (click)="onNextPageA()">
                    <i></i>
                    <span>{{ "Next" | translate }}</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div class="request_ride_inner part2" *ngIf="nextPopup == 21">
        <div class="car_using_main">
          <div class="nsfavret">
            <div class="fav-header">
              <div class="facback" style="cursor: pointer" (click)="continue(20)"><a>Back</a></div>
              <div class="headerfav">
                <h5>{{ favAddPayload?.addressId ? "Edit" : "Add" }} Favourites</h5>
              </div>
              <div class="addfav">
                <!-- <h6 (click)="createFavAddForm(); continue(21)" style="cursor: pointer">Add Favourite</h6> -->
              </div>
            </div>
            <div class="myy-favret">
              <ul class="formn_group_main">
                <li class="remove-brdr">
                  <div class="form-group" style="display: grid">
                    <mat-form-field floatLabel="never">
                      <div class="addimgbg">
                        <figure>
                          <img src="assets/icons/street21.jpg" />
                        </figure>
                      </div>
                      <input
                        type="text"
                        matInput
                        class="form-control"
                        [(ngModel)]="favAddPayload.name"
                        placeholder="{{ 'Place name' | translate }}"
                      />
                    </mat-form-field>
                    <mat-error *ngIf="favAddressSubmitted && favAddPayload.name === ''">Place name is required.</mat-error>
                  </div>
                </li>
                <li class="mt-4 remove-brd" style="border-bottom: none">
                  <div class="form-group" style="display: grid">
                    <mat-form-field floatLabel="never">
                      <div class="addimgbg">
                        <figure>
                          <img src="assets/icons/pointer21.jpg" />
                        </figure>
                      </div>
                      <input
                        matInput
                        placeholder="{{ 'Search for your address' | translate }}"
                        autocorrect="off"
                        autocapitalize="off"
                        spellcheck="off"
                        type="text"
                        class="form-control"
                        ngx-google-places-autocomplete
                        #placesRef="ngx-places"
                        (keyup)="onKeyUpAdd($event.target.value)"
                        (change)="onAddChanged($event.target.value)"
                        (onAddressChange)="handleLocationChnage($event)"
                        [(ngModel)]="favAddPayload.address"
                      />
                      <div class="addimgbg sec">
                        <figure>
                          <img src="assets/icons/map21.jpg" />
                        </figure>
                      </div>
                    </mat-form-field>
                    <mat-error *ngIf="favAddressSubmitted && favAddPayload.address === ''">Address is required.</mat-error>
                  </div>
                </li>
              </ul>
              <button class="btn btn-primary mt-4" (click)="submitFavAdd()">Save</button>
            </div>
          </div>
        </div>
      </div>

      <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 2">
        <div class="title_bar">
          <h2>{{ "Payment-Options" | translate }}</h2>
        </div>
        <ul class="payment_last">
          <li>
            <div class="card1">
              <div class="card-innre-payment">
                <div class="wallet-img">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-wallet2" viewBox="0 0 16 16">
                    <path
                      d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"
                    />
                  </svg>
                </div>
                <div class="balance">
                  <p>{{ allSavedCardData?.walletAmount?.toFixed(2) | currency: "USD" || "0.0" }}</p>
                  <p>{{ "Wallet Balance" | translate }}</p>
                </div>
              </div>
              <div class="add-btn add-mny">
                <i class="fa fa-plus" title="Add Money" style="cursor: pointer" aria-hidden="true" (click)="addWalletModal('block')"></i>
              </div>
            </div>
          </li>
          <li class="borderbtm waletli">
            <div class="nsflex">
              <div class="list_inner">
                <mat-checkbox [checked]="isWalletSelected" (change)="onWalletSelected($event.checked)">
                  {{ "Pay via APPTUNIX Wallet" | translate }}</mat-checkbox
                >
              </div>
            </div>
          </li>
          <li class="borderbtm">
            <div class="list_inner">
              <ngx-paypal [config]="payPalConfig"></ngx-paypal>
            </div>
          </li>
          <li class="borderbtm newli" *ngIf="allSavedCardData?.cards?.length > 0">
            <mat-radio-group aria-label="Select an option" (change)="onCardChange($event.value)">
              <mat-radio-button [checked]="card?.isSelected" [value]="i + 1" *ngFor="let card of allSavedCard; let i = index">
                <div class="card_detailcs">
                  <div>
                    <h3>
                      {{ card?.cardNumber }}<span>{{ card?.brand }} - {{ card?.expiryDate.slice(0, 2) }}/{{ card?.expiryDate.slice(2) }}</span>
                    </h3>
                  </div>
                  <div class="dlt-radio">
                    <h3 *ngIf="card?.isDefault">
                      <span class="text-success">{{ "PRIMARY CARD" | translate }}</span>
                    </h3>
                    <h3 *ngIf="!card?.isDefault" (click)="changeDefaultCard(card?._id)">
                      <span style="color: #0171bd">{{ "MAKE PRIMARY" | translate }}</span>
                    </h3>
                    <i class="fa fa-trash" (click)="deleteCard(card?._id)" aria-hidden="true"></i>
                  </div>
                </div>
              </mat-radio-button>
            </mat-radio-group>
          </li>
        </ul>
        <div class="pay-op">
          <div (click)="AddCardModal('block')">
            <button class="btn btn-primary">{{ "Add Payment Card" | translate }}</button>
          </div>
          <div>
            <button class="btn btn-primary" (click)="continue('1')">{{ "Next" | translate }}</button>
          </div>
        </div>
      </div>
      <!-----start rider accept -->
      <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 3" style="height: 100%">
        <div class="driver_info_bx sec">
          <div class="spinners"><i class="fa fa-spinner fa-spin"></i></div>
          <p class="arrving_time">{{ "We are processing your booking request" | translate }}..</p>
          <p class="arrving_time">{{ "A notification will be received soon" | translate }}.</p>

          <div class="ride_can_btn">
            <button class="ride_cancle_btn" (click)="cancelConfirmToast(bookingId, null)">{{ "Cancel" | translate }}</button>
          </div>
        </div>

        <!-- <div class="flex reqst driverap_btn">
                        <button (click)="continue('1')">Next</button>
                    </div> -->

        <!-----end rider accept-->
      </div>
      <!-----start call me reach me screen-->
      <!-----start rider accept -->
      <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 4">
        <div class="driver_info_bx">
          <p class="arrving_time" *ngIf="duration > 1">{{ driverMessage }}</p>
          <p class="arrving_time" *ngIf="duration <= 1">{{ "Your driver arrived to pick you up your items" | translate }}.</p>
          <div class="about_driver">
            <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image === ''">
              <img src="assets/images/user.png" alt=" " />
            </figure>
            <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image != ''">
              <img [src]="baseUrl + bookingResponseData?.driverId?.image" alt=" " />
            </figure>
            <div class="driver_name_sec">
              <p class="driver_name">{{ bookingResponseData?.driverId?.firstName }}</p>
              <span><i class="fa fa-star" aria-hidden="true"></i>{{ bookingResponseData?.driverId?.avgRating }}</span>
              <span><i class="fa fa-map-marker" aria-hidden="true"></i>{{ distance?.toFixed(2) }} km</span>
            </div>
            <div class="car_img">
              <!-- <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage === ''">
                                <img src="assets/uimages/ride_car.png " alt=" " />
                            </figure> -->
              <!-- <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage != ''">
                                <img [src]="baseUrl+bookingResponseData?.vehicleId?.vehicleImage" alt=" " />
                            </figure> -->
              <p>
                {{ bookingResponseData?.vehicleId?.vehicleModel }}:
                <span>{{ bookingResponseData?.vehicleId?.licensePlateNumber }}</span>
              </p>
            </div>
          </div>
          <h4 class="trip_title">{{ "Trip" | translate }}</h4>
          <Div class="start_trip">
            <div class="start_trip_text">
              <div class="mm" *ngFor="let pick of bookingResponseData?.subBookingData">
                <div *ngIf="pick?.isPickUp">
                  <div class="blm">
                    <div class="bb"></div>
                  </div>
                  <div class="mkl">
                    <p>{{ pick?.address }}</p>
                  </div>
                </div>
                <div class="stts" *ngIf="pick?.isPickUp && pick?.bookingStatus !== constant.BOOKING_STATUS.ACCEPTED">
                  <p>{{ constant.SHOW_STATUS_BOOKING[pick?.bookingStatus] }}</p>
                </div>
              </div>
              <div class="mm" *ngFor="let drop of bookingResponseData?.subBookingData">
                <div
                  *ngIf="
                    !drop?.isPickUp &&
                    drop?.bookingStatus !== constant?.BOOKING_STATUS?.NOT_AVAILABLE &&
                    drop?.bookingStatus !== constant?.BOOKING_STATUS?.NONE
                  "
                >
                  <div class="blm">
                    <div class="blc"></div>
                  </div>
                  <div class="mkl">
                    <p>{{ drop?.address }}</p>
                  </div>
                </div>
                <div class="stts" *ngIf="!drop?.isPickUp && drop?.bookingStatus !== constant.BOOKING_STATUS.ACCEPTED">
                  <p>{{ constant.SHOW_STATUS_BOOKING[drop?.bookingStatus] }}</p>
                </div>
              </div>
            </div>
          </Div>
          <div class="moniter_your_ride" *ngIf="bookingResponseData?.subBookingData[0].bookingStatus >= 6">
            <p (click)="openMonitorModal(bookingResponseData?.id)">
              {{ "Monitor your delivery" | translate }}
              <i class="fa fa-angle-right"></i>
            </p>
          </div>
          <hr />
          <div class="note_call" *ngIf="bookingResponseData?.additionalInfo">
            <p>{{ "Note" | translate }} : {{ bookingResponseData?.additionalInfo }}.</p>
          </div>

          <div class="trip_payment">
            <h4>{{ "Payment" | translate }}</h4>
            <div class="visa_card">
              <!-- <figure style="cursor: pointer;" (click)="continue('2')">
                                <img src="assets/uimages/visa_card.png " alt=" " />
                            </figure> -->
              <div class="card_no">
                <p *ngIf="bookingResponseData?.paymentMode !== constant?.PAYMENT_MODE?.WALLET">
                  {{ defaultCardDetails?.cardNumber }} <span> {{ defaultCardDetails?.brand }}</span>
                </p>
                <p *ngIf="bookingResponseData?.paymentMode === constant?.PAYMENT_MODE?.WALLET">Wallet</p>
              </div>
            </div>
          </div>
          <!----start cancel call btn-->
          <div class="ride_can_btn" *ngIf="bookingResponseData?.subBookingData[0].bookingStatus < 4">
            <button
              class="ride_cancle_btn"
              *ngIf="bookingResponseData?.subBookingData[0].bookingStatus !== constant.BOOKING_STATUS.PICKED"
              (click)="cancelConfirmToast(bookingResponseData._id, 'cancel')"
            >
              Cancel
              <div class="duration" *ngIf="commonService?.cancelCounter > 0">{{ commonService?.cancelCounter }}</div>
            </button>
            <button class="call_btn" (click)="goToContactDetail()">Call</button>
          </div>
          <div class="add_vehicle_honda driver-info flex" id="driverInfo" *ngIf="!isContactDetailHide">
            <div class="add_honda_left">
              <div class="file">
                <figure class="driver-img">
                  <img class="driverd-pro" *ngIf="!bookingResponseData?.driverId?.image" src="assets/images/user.png" />
                  <img class="driverd-pro" *ngIf="bookingResponseData?.driverId?.image" [src]="baseUrl + bookingResponseData?.driverId?.image" />
                </figure>
                <h5>
                  <span>{{ bookingResponseData?.driverId?.countryCode }} {{ bookingResponseData?.driverId?.phoneNo }}</span>
                  {{ bookingResponseData?.driverId?.firstName }}
                  {{ bookingResponseData?.driverId?.lastName }}
                  <!-- <span class="capitaAL_SVU">Quantity:</span> -->
                </h5>
              </div>
            </div>
            <div class="add_vehicle_right flex">
              <div class="add_vehicle_right_icon">
                <figure>
                  <img src="assets/images/phone-call.svg" />
                </figure>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="flex reqst driverap_btn ">
                        <button (click)="continue( '1') ">Next</button>
                    </div> -->

        <!-----end rider accept-->
      </div>
      <!-----End  call me reach me screen-->

      <!-----start driver reach -->
      <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 6">
        <div class="driver_info_bx">
          <p class="arrving_time">{{ "Your driver has arrived" | translate }}.</p>
          <div class="about_driver">
            <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image === ''">
              <img src="assets/images/user.png" alt=" " />
            </figure>
            <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image != ''">
              <img [src]="baseUrl + bookingResponseData?.driverId?.image" alt=" " />
            </figure>
            <div class="driver_name_sec">
              <p class="driver_name">{{ bookingResponseData?.driverId?.firstName }}</p>
              <span><i class="fa fa-star" aria-hidden="true"></i>{{ bookingResponseData?.driverId?.avgRating }}</span>
              <span><i class="fa fa-map-marker" aria-hidden="true"></i>{{ distance?.toFixed(2) }} km</span>
            </div>
            <div class="car_img">
              <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage === ''">
                <img src="assets/uimages/ride_car.png " alt=" " />
              </figure>
              <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage != ''">
                <img [src]="baseUrl + bookingResponseData?.vehicleId?.vehicleImage" alt=" " />
              </figure>
              <p>
                {{ bookingResponseData?.vehicleId?.vehicleModel }}:
                <span>{{ bookingResponseData?.vehicleId?.licensePlateNumber }}</span>
              </p>
            </div>
          </div>
          <h4 class="trip_title">Trip</h4>
          <Div class="start_trip">
            <div class="start_trip_text">
              <div class="mm" *ngFor="let pick of bookingResponseData?.subBookingData">
                <div *ngIf="pick?.isPickUp">
                  <div class="blm">
                    <div class="bb"></div>
                  </div>
                  <div class="mkl">
                    <p>{{ pick?.address }}</p>
                  </div>
                </div>
                <div class="stts" *ngIf="pick?.isPickUp && pick?.bookingStatus !== constant.BOOKING_STATUS.ACCEPTED">
                  <p>{{ constant.SHOW_STATUS_BOOKING[pick?.bookingStatus] }}</p>
                </div>
              </div>

              <div class="mm" *ngFor="let drop of bookingResponseData?.subBookingData">
                <div *ngIf="!drop?.isPickUp">
                  <div class="blm">
                    <div class="blc"></div>
                  </div>
                  <div class="mkl">
                    <p>{{ drop?.address }}</p>
                  </div>
                </div>
                <div class="stts" *ngIf="!drop?.isPickUp && drop?.bookingStatus !== constant.BOOKING_STATUS.ACCEPTED">
                  <p>{{ constant.SHOW_STATUS_BOOKING[drop?.bookingStatus] }}</p>
                </div>
              </div>
            </div>
          </Div>
          <div class="note_call" *ngIf="bookingResponseData?.note != null">
            <p>{{ "Note" | translate }} : {{ bookingResponseData?.note }}.</p>
          </div>
          <div class="trip_payment">
            <h4>{{ "Payment" | translate }}</h4>
            <div class="visa_card">
              <figure (click)="continue('2')">
                <img src="assets/uimages/visa_card.png " alt=" " />
              </figure>
            </div>
          </div>
          <!----start cancel call btn-->
          <div class="ride_can_btn">
            <button class="call_btn" (click)="goToContactDetail()">{{ "Call" | translate }}</button>
          </div>
          <div class="add_vehicle_honda driver-info flex" id="driverInfo" *ngIf="!isContactDetailHide">
            <div class="add_honda_left">
              <div class="file">
                <div class="file-left">
                  <figure class="driver-img">
                    <img class="driverd-pro" *ngIf="!bookingResponseData?.driverId?.image" src="assets/images/user.png" />
                    <img class="driverd-pro" *ngIf="bookingResponseData?.driverId?.image" [src]="baseUrl + bookingResponseData?.driverId?.image" />
                  </figure>
                </div>
                <div class="file-right">
                  <h5>
                    <span>{{ bookingResponseData?.driverId?.countryCode }} {{ bookingResponseData?.driverId?.phoneNo }}</span>
                    {{ bookingResponseData?.driverId?.firstName }}
                    {{ bookingResponseData?.driverId?.lastName }}
                    <!-- <span class="capitaAL_SVU">Quantity:</span> -->
                  </h5>
                </div>
              </div>
            </div>
            <div class="add_vehicle_right flex">
              <div class="add_vehicle_right_icon">
                <figure>
                  <img class="clr-bl" src="assets/images/phone-call.svg" />
                </figure>
              </div>
            </div>
          </div>
        </div>
        <!-----end rider accept-->
      </div>
      <!-----End  call me driver  me screen-->

      <!-----start rider accept -->
      <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 5">
        <div class="driver_info_bx">
          <p class="arrving_time">{{ "Driver is arriving" | translate }}.</p>
          <div class="about_driver">
            <figure class="driver_img">
              <img *ngIf="!bookingResponseData?.driverId?.image" src="assets/uimages/profile_image.png " alt=" " />
              <img *ngIf="bookingResponseData?.driverId?.image" [src]="baseUrl + bookingResponseData?.driverId?.image" alt=" " />
            </figure>
            <div class="driver_name_sec">
              <p class="driver_name">{{ bookingResponseData?.driverId?.firstName }}</p>
              <span><i class="fa fa-star" aria-hidden="true"></i>{{ bookingResponseData?.driverId?.avgRating }}</span>
              <span><i class="fa fa-map-marker" aria-hidden="true"></i>{{ distanceInKM }}</span>
            </div>
            <div class="car_img">
              <figure>
                <img *ngIf="!bookingResponseData?.vehicleId?.vehicleImage" src="assets/uimages/ride_car.png " alt=" " />
                <img *ngIf="bookingResponseData?.vehicleId?.vehicleImage" [src]="baseUrl + bookingResponseData?.vehicleId?.vehicleImage" alt=" " />
              </figure>
              <p>
                {{ bookingResponseData?.vehicleId?.vehicleMake }}:
                <span>{{ bookingResponseData?.vehicleId?.licensePlateNumber }}</span>
              </p>
            </div>
          </div>
          <div class="priceRide">
            <h5 class="amnt">{{ "Price" | translate }}: ${{ bookingResponseData?.booKingAmount }}</h5>
          </div>
          <h4 class="trip_title">{{ "Trip" | translate }}</h4>
          <Div class="start_trip">
            <div class="start_trip_text">
              <div class="mm">
                <div class="blm">
                  <div class="bb"></div>
                </div>
                <div class="mkl">
                  <p>{{ bookingResponseData?.pickUpAddress }}</p>
                </div>
              </div>

              <div class="mm">
                <div class="blm">
                  <div class="blc"></div>
                </div>
                <div class="mkl">
                  <p>{{ bookingResponseData?.dropUpAddress }}</p>
                </div>
              </div>
            </div>
          </Div>
          <!-- /// package detail start -->
          <div class="card">
            <div class="card-header p-2" id="review">
              <div class="fs collapsed" data-toggle="collapse" data-target="#reviewsOne">
                <div class="fd">
                  <h5 class="mb-0">
                    {{ "Delivery Details" | translate }}
                  </h5>
                </div>
                <div class="arrow"><i class="fa fa-angle-down"></i></div>
              </div>
            </div>

            <div id="reviewsOne" class="collapse" aria-labelledby="review" data-parent="#faqExample">
              <div class="card-body">
                <div class="delivery_detail_form">
                  <div class="delivery_view_item">
                    <label for="">{{ "Delivery To" | translate }}</label>
                    <p>{{ bookingResponseData?.addressType }}</p>
                  </div>
                  <div class="delivery_view_item">
                    <label for="">{{ "Street Address" | translate }}</label>
                    <p>{{ bookingResponseData?.streetAddress }}</p>
                  </div>
                  <div class="delivery_view_item">
                    <label for="">{{ "Building/apt/suite" | translate }}</label>
                    <p>{{ bookingResponseData?.suit }}</p>
                  </div>

                  <div class="delivery_view_item">
                    <label for="">{{ "City" | translate }}</label>
                    <p>{{ bookingResponseData?.city }}</p>
                  </div>
                  <div class="delivery_view_item">
                    <label for="">{{ "Province" | translate }}</label>
                    <p>{{ bookingResponseData?.province }}</p>
                  </div>
                  <div class="delivery_view_item">
                    <label for="">{{ "Postal/Zip code" | translate }}:</label>
                    <p>{{ bookingResponseData?.zipCode }}</p>
                  </div>
                  <div class="delivery_view_item">
                    <label for="">{{ "Phone Number" | translate }}</label>
                    <p>{{ bookingResponseData?.phoneNo }}</p>
                  </div>
                  <div class="delivery_view_item" *ngIf="bookingResponseData?.additionalInfo">
                    <label for="">{{ "Additional Details" | translate }}</label>
                    <p>{{ bookingResponseData?.additionalInfo }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header p-2" id="review2">
              <div class="fs collapsed" type="button" data-toggle="collapse" data-target="#reviewstwo">
                <div class="fd">
                  <h5 class="mb-0">
                    {{ "Package Details" | translate }}
                  </h5>
                </div>
                <div class="arrow"><i class="fa fa-angle-down"></i></div>
              </div>
            </div>
            <div id="reviewstwo" class="collapse" aria-labelledby="review2" data-parent="#faqExample">
              <div class="card-body">
                <div class="flex">
                  <div class="ml">
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="carouselConfig">
                      <div ngxSlickItem *ngFor="let package of bookingResponseData?.packageDetails" class="slide">
                        <div class="add_vehicle_honda flex">
                          <div class="add_honda_left">
                            <div class="file">
                              <figure>
                                <img [src]="baseUrl + package?.itemImage" />
                              </figure>
                              <h5>
                                <span class="capitaAL_SVU">{{ "Type" | translate }}: {{ package?.packageName }}</span>
                                <span class="capitaAL_SVU">{{ "Description" | translate }}: {{ package?.itemDescription }}</span>
                                <span class="capitaAL_SVU">{{ "Quantity" | translate }}: {{ package?.itemQuantity }}</span>
                                <span class="capitaAL_SVU">{{ "Status" | translate }}: Not Picked</span>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ngx-slick-carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- package detail end  -->
          <div class="moniter_your_ride">
            <p (click)="openMonitorModal(bookingResponseData?.id)">
              {{ "Monitor your delivery" | translate }}
              <i class="fa fa-angle-right"></i>
            </p>
          </div>
          <div class="ride_cost_sec">
            <div class="cost_item">
              <p>
                {{ "Cost" | translate }} <span>US ${{ bookingResponseData?.booKingAmount }}</span>
              </p>
            </div>
            <div class="cost_item">
              <p>
                {{ "Duration" | translate }} <span>{{ duration }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="trip_payment">
          <h4>{{ "Payment" | translate }}</h4>
          <div class="visa_card">
            <figure (click)="continue('2')">
              <img src="assets/uimages/visa_card.png " alt=" " />
            </figure>
          </div>
        </div>
        <!----start cancel call btn-->
        <div class="ride_can_btn">
          <button class="call_btn" (click)="goToContactDetail()">{{ "Call" | translate }}</button>
        </div>
        <div class="add_vehicle_honda driver-info flex" id="driverInfo" *ngIf="!isContactDetailHide">
          <div class="add_honda_left">
            <div class="file">
              <figure class="driver-img">
                <img class="driverd-pro" *ngIf="!bookingResponseData?.driverId?.image" src="assets/images/user.png" />
                <img class="driverd-pro" *ngIf="bookingResponseData?.driverId?.image" [src]="baseUrl + bookingResponseData?.driverId?.image" />
              </figure>
              <h5>
                <span>{{ bookingResponseData?.driverId?.countryCode }} {{ bookingResponseData?.driverId?.phoneNo }}</span>
                {{ bookingResponseData?.driverId?.firstName }} {{ bookingResponseData?.driverId?.lastName }}
                <!-- <span class="capitaAL_SVU">Quantity:</span> -->
              </h5>
            </div>
          </div>
          <div class="add_vehicle_right flex">
            <div class="add_vehicle_right_icon">
              <figure>
                <img class="clr-bl" src="assets/images/phone-call.svg" />
              </figure>
            </div>
          </div>
        </div>
        <!-----end rider accept-->
      </div>
      <!-----End  call me reach me screen-->
      <!-----you ride started page-->

      <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 7">
        <div class="driver_info_bx">
          <p class="arrving_time">Your packages has been picked up.</p>
          <div class="about_driver">
            <figure class="driver_img">
              <img *ngIf="!bookingResponseData?.driverId?.image" src="assets/uimages/profile_image.png " alt=" " />
              <img *ngIf="bookingResponseData?.driverId?.image" [src]="baseUrl + bookingResponseData?.driverId?.image" alt=" " />
            </figure>
            <div class="driver_name_sec">
              <p class="driver_name">{{ bookingResponseData?.driverId?.firstName }}</p>
              <span><i class="fa fa-star" aria-hidden="true"></i>{{ bookingResponseData?.driverId?.avgRating }}</span>
              <span><i class="fa fa-map-marker" aria-hidden="true"></i>{{ distanceInKM }}</span>
            </div>
            <div class="car_img">
              <figure>
                <img *ngIf="!bookingResponseData?.vehicleId?.vehicleImage" src="assets/uimages/ride_car.png " alt=" " />
                <img *ngIf="bookingResponseData?.vehicleId?.vehicleImage" [src]="baseUrl + bookingResponseData?.vehicleId?.vehicleImage" alt=" " />
              </figure>
              <p>
                {{ bookingResponseData?.vehicleId?.vehicleMake }}:
                <span>{{ bookingResponseData?.vehicleId?.licensePlateNumber }}</span>
              </p>
            </div>
          </div>
          <div class="priceRide">
            <h5 class="amnt">Price: ${{ bookingResponseData?.booKingAmount }}</h5>
          </div>
          <h4 class="trip_title">Trip</h4>
          <Div class="start_trip">
            <div class="start_trip_text">
              <div class="mm">
                <div class="blm">
                  <div class="bb"></div>
                </div>
                <div class="mkl">
                  <p>{{ bookingResponseData?.pickUpAddress }}</p>
                </div>
              </div>

              <div class="mm">
                <div class="blm">
                  <div class="blc"></div>
                </div>
                <div class="mkl">
                  <p>{{ bookingResponseData?.dropUpAddress }}</p>
                </div>
              </div>
            </div>
          </Div>
          <!-- /// package detail start -->
          <div class="card">
            <div class="card-header p-2" id="review">
              <div class="fs collapsed" data-toggle="collapse" data-target="#reviewsOne">
                <div class="fd">
                  <h5 class="mb-0">Delivery Details</h5>
                </div>
                <div class="arrow"><i class="fa fa-angle-down"></i></div>
              </div>
            </div>

            <div id="reviewsOne" class="collapse" aria-labelledby="review" data-parent="#faqExample">
              <div class="card-body">
                <div class="delivery_detail_form">
                  <div class="delivery_view_item">
                    <label for="">Delivery To</label>
                    <p>{{ bookingResponseData?.addressType }}</p>
                  </div>
                  <div class="delivery_view_item">
                    <label for="">Street Address</label>
                    <p>{{ bookingResponseData?.streetAddress }}</p>
                  </div>
                  <div class="delivery_view_item">
                    <label for="">Building/apt/suite</label>
                    <p>{{ bookingResponseData?.suit }}</p>
                  </div>

                  <div class="delivery_view_item">
                    <label for="">City</label>
                    <p>{{ bookingResponseData?.city }}</p>
                  </div>
                  <div class="delivery_view_item">
                    <label for="">Province</label>
                    <p>{{ bookingResponseData?.province }}</p>
                  </div>
                  <div class="delivery_view_item">
                    <label for="">Postal/Zip code:</label>
                    <p>{{ bookingResponseData?.zipCode }}</p>
                  </div>
                  <div class="delivery_view_item">
                    <label for="">Phone Number</label>
                    <p>{{ bookingResponseData?.phoneNo }}</p>
                  </div>
                  <div class="delivery_view_item" *ngIf="bookingResponseData?.additionalInfo">
                    <label for="">Additional Details</label>
                    <p>{{ bookingResponseData?.additionalInfo }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header p-2" id="review2">
              <div class="fs collapsed" type="button" data-toggle="collapse" data-target="#reviewstwo">
                <div class="fd">
                  <h5 class="mb-0">Package Details</h5>
                </div>
                <div class="arrow"><i class="fa fa-angle-down"></i></div>
              </div>
            </div>
            <div id="reviewstwo" class="collapse" aria-labelledby="review2" data-parent="#faqExample">
              <div class="card-body">
                <div class="flex">
                  <div class="ml">
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="carouselConfig">
                      <div ngxSlickItem *ngFor="let package of bookingResponseData?.packageDetails" class="slide">
                        <div class="add_vehicle_honda flex">
                          <div class="add_honda_left">
                            <div class="file">
                              <figure>
                                <img [src]="baseUrl + package?.itemImage" />
                              </figure>
                              <h5>
                                <span>Type: {{ package?.packageName }}</span>
                                <span>Description: {{ package?.itemDescription }}</span>
                                <span class="capitaAL_SVU">Quantity: {{ package?.itemQuantity }}</span>
                                <span class="capitaAL_SVU">Status: {{ package?.status }}</span>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ngx-slick-carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- package detail end  -->
          <div class="trip_payment">
            <h4>Payment</h4>
            <div class="visa_card">
              <figure (click)="continue('2')">
                <img src="assets/uimages/visa_card.png " alt=" " />
              </figure>
            </div>
          </div>
          <!----start cancel call btn-->
          <div class="ride_can_btn">
            <button class="call_btn" (click)="goToContactDetail()">Call</button>
          </div>
          <div class="add_vehicle_honda driver-info flex" id="driverInfo" *ngIf="!isContactDetailHide">
            <div class="add_honda_left">
              <div class="file">
                <figure class="driver-img">
                  <img class="driverd-pro" *ngIf="!bookingResponseData?.driverId?.image" src="assets/images/user.png" />
                  <img class="driverd-pro" *ngIf="bookingResponseData?.driverId?.image" [src]="baseUrl + bookingResponseData?.driverId?.image" />
                </figure>
                <h5>
                  <span>{{ bookingResponseData?.driverId?.countryCode }} {{ bookingResponseData?.driverId?.phoneNo }}</span>
                  {{ bookingResponseData?.driverId?.firstName }}
                  {{ bookingResponseData?.driverId?.lastName }}
                  <!-- <span class="capitaAL_SVU">Quantity:</span> -->
                </h5>
              </div>
            </div>
            <div class="add_vehicle_right flex">
              <div class="add_vehicle_right_icon">
                <figure>
                  <img class="clr-bl" src="assets/images/phone-call.svg" />
                </figure>
              </div>
            </div>
          </div>
        </div>

        <!-----end rider accept-->
      </div>

      <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 8">
        <div class="driver_info_bx">
          <p class="arrving_time">Driver is out for delivery.</p>
          <div class="about_driver">
            <figure class="driver_img">
              <img *ngIf="!bookingResponseData?.driverId?.image" src="assets/uimages/profile_image.png " alt=" " />
              <img *ngIf="bookingResponseData?.driverId?.image" [src]="baseUrl + bookingResponseData?.driverId?.image" alt=" " />
            </figure>
            <div class="driver_name_sec">
              <p class="driver_name">{{ bookingResponseData?.driverId?.firstName }}</p>
              <span><i class="fa fa-star" aria-hidden="true"></i>{{ bookingResponseData?.driverId?.avgRating }}</span>
              <span><i class="fa fa-map-marker" aria-hidden="true"></i>{{ distanceInKM }}</span>
            </div>
            <div class="car_img">
              <figure>
                <img *ngIf="!bookingResponseData?.vehicleId?.vehicleImage" src="assets/uimages/ride_car.png " alt=" " />
                <img *ngIf="bookingResponseData?.vehicleId?.vehicleImage" [src]="baseUrl + bookingResponseData?.vehicleId?.vehicleImage" alt=" " />
              </figure>
              <p>
                {{ bookingResponseData?.vehicleId?.vehicleMake }}:
                <span>{{ bookingResponseData?.vehicleId?.licensePlateNumber }}</span>
              </p>
            </div>
          </div>
          <div class="priceRide">
            <h5 class="amnt">Price: ${{ bookingResponseData?.booKingAmount }}</h5>
          </div>
          <h4 class="trip_title">Trip</h4>
          <Div class="start_trip">
            <div class="start_trip_text">
              <div class="mm">
                <div class="blm">
                  <div class="bb"></div>
                </div>
                <div class="mkl">
                  <p>{{ bookingResponseData?.pickUpAddress }}</p>
                </div>
              </div>

              <div class="mm">
                <div class="blm">
                  <div class="blc"></div>
                </div>
                <div class="mkl">
                  <p>{{ bookingResponseData?.dropUpAddress }}</p>
                </div>
              </div>
            </div>
          </Div>
          <!-- /// package detail start -->
          <div class="card">
            <div class="card-header p-2" id="review">
              <div class="fs collapsed" data-toggle="collapse" data-target="#reviewsOne">
                <div class="fd">
                  <h5 class="mb-0">Delivery Details</h5>
                </div>
                <div class="arrow"><i class="fa fa-angle-down"></i></div>
              </div>
            </div>

            <div id="reviewsOne" class="collapse" aria-labelledby="review" data-parent="#faqExample">
              <div class="card-body">
                <div class="delivery_detail_form">
                  <div class="delivery_view_item">
                    <label for="">Delivery To</label>
                    <p>{{ bookingResponseData?.addressType }}</p>
                  </div>
                  <div class="delivery_view_item">
                    <label for="">Street Address</label>
                    <p>{{ bookingResponseData?.streetAddress }}</p>
                  </div>
                  <div class="delivery_view_item">
                    <label for="">Building/apt/suite</label>
                    <p>{{ bookingResponseData?.suit }}</p>
                  </div>

                  <div class="delivery_view_item">
                    <label for="">City</label>
                    <p>{{ bookingResponseData?.city }}</p>
                  </div>
                  <div class="delivery_view_item">
                    <label for="">Province</label>
                    <p>{{ bookingResponseData?.province }}</p>
                  </div>
                  <div class="delivery_view_item">
                    <label for="">Postal/Zip code:</label>
                    <p>{{ bookingResponseData?.zipCode }}</p>
                  </div>
                  <div class="delivery_view_item">
                    <label for="">Phone Number</label>
                    <p>{{ bookingResponseData?.phoneNo }}</p>
                  </div>
                  <div class="delivery_view_item" *ngIf="bookingResponseData?.additionalInfo">
                    <label for="">Additional Details</label>
                    <p>{{ bookingResponseData?.additionalInfo }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header p-2" id="review2">
              <div class="fs collapsed" type="button" data-toggle="collapse" data-target="#reviewstwo">
                <div class="fd">
                  <h5 class="mb-0">Package Details</h5>
                </div>
                <div class="arrow"><i class="fa fa-angle-down"></i></div>
              </div>
            </div>
            <div id="reviewstwo" class="collapse" aria-labelledby="review2" data-parent="#faqExample">
              <div class="card-body">
                <div class="flex">
                  <div class="ml">
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="carouselConfig">
                      <div ngxSlickItem *ngFor="let package of bookingResponseData?.packageDetails" class="slide">
                        <div class="add_vehicle_honda flex">
                          <div class="add_honda_left">
                            <div class="file">
                              <figure>
                                <img [src]="baseUrl + package?.itemImage" />
                              </figure>
                              <h5>
                                <span>Type: {{ package?.packageName }}</span>
                                <span>Description: {{ package?.itemDescription }}</span>
                                <span class="capitaAL_SVU">Quantity: {{ package?.itemQuantity }}</span>
                                <span class="capitaAL_SVU">Status: {{ package?.status }}</span>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ngx-slick-carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- package detail end  -->
          <div class="moniter_your_ride">
            <p class="mntr_title" (click)="openMonitorModal(bookingResponseData?.id)">
              Monitor your delivery
              <i class="fa fa-angle-right"></i>
            </p>
          </div>
          <hr />
          <div class="ftr-dtl">
            <div class="ride_cost_sec">
              <div class="cost_item">
                <p>
                  Cost <span>US ${{ bookingResponseData?.booKingAmount }}</span>
                </p>
              </div>
              <div class="cost_item">
                <p>
                  Duration <span>{{ duration }}</span>
                </p>
              </div>
            </div>
            <div class="trip_payment">
              <h4 class="pymnt">Payment</h4>
              <div class="visa_card">
                <figure (click)="continue(2)">
                  <img src="assets/uimages/visa_card.png " alt=" " />
                </figure>
              </div>
            </div>
          </div>
          <!----start cancel call btn-->
          <div class="ride_can_btn">
            <button class="call_btn" (click)="goToContactDetail()">Call</button>
          </div>
          <div class="add_vehicle_honda driver-info flex" id="driverInfo" *ngIf="!isContactDetailHide">
            <div class="add_honda_left">
              <div class="file">
                <figure class="driver-img">
                  <img class="driverd-pro" *ngIf="!bookingResponseData?.driverId?.image" src="assets/images/user.png" />
                  <img class="driverd-pro" *ngIf="bookingResponseData?.driverId?.image" [src]="baseUrl + bookingResponseData?.driverId?.image" />
                </figure>
                <h5>
                  <span>{{ bookingResponseData?.driverId?.countryCode }} {{ bookingResponseData?.driverId?.phoneNo }}</span>
                  {{ bookingResponseData?.driverId?.firstName }}
                  {{ bookingResponseData?.driverId?.lastName }}
                  <!-- <span class="capitaAL_SVU">Quantity:</span> -->
                </h5>
              </div>
            </div>
            <div class="add_vehicle_right flex">
              <div class="add_vehicle_right_icon">
                <figure>
                  <img class="clr-bl" src="assets/images/phone-call.svg" />
                </figure>
              </div>
            </div>
          </div>
        </div>

        <!-----end rider accept-->
      </div>

      <!-----End you ride started page-->

      <!----start next 2 screen ride start now-->

      <!----end next 2 screen ride start now-->

      <!-----you ride started page-->

      <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 9">
        <div class="flex-m">
          <div class="driver_info_bx">
            <p class="arrving_time">{{ driverMessage }}</p>
            <div class="about_driver">
              <figure class="driver_img">
                <img *ngIf="!bookingResponseData?.driverId?.image" src="assets/uimages/profile_image.png " alt=" " />
                <img *ngIf="bookingResponseData?.driverId?.image" [src]="baseUrl + bookingResponseData?.driverId?.image" alt=" " />
              </figure>
              <div class="driver_name_sec">
                <p class="driver_name">{{ bookingResponseData?.driverId?.firstName }}</p>
                <span><i class="fa fa-star" aria-hidden="true"></i>{{ bookingResponseData?.driverId?.avgRating }}</span>
                <span><i class="fa fa-map-marker" aria-hidden="true"></i>{{ distance?.toFixed(2) }} km</span>
              </div>
              <div class="car_img">
                <!-- <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage === ''">
                                    <img src="assets/uimages/ride_car.png " alt=" " />
                                </figure>
                                <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage != ''">
                                    <img [src]="baseUrl+bookingResponseData?.vehicleId?.vehicleImage" alt=" " />
                                </figure> -->
                <p>
                  {{ bookingResponseData?.vehicleId?.vehicleMake }}:
                  <span>{{ bookingResponseData?.vehicleId?.licensePlateNumber }}</span>
                </p>
              </div>
            </div>
            <div class="priceRide">
              <h5 class="amnt">{{ "Order Id" | translate }}: {{ bookingResponseData?.bookingNo }}</h5>
            </div>
            <!-- <Div class="start_trip">

                            <div class="start_trip_text">
                                <div class="mm" *ngFor="let pick of bookingResponseData?.subBookingData">
                                    <div *ngIf="pick?.isPickUp">
                                        <div class="blm">
                                            <div class="bb"></div>
                                        </div>
                                        <div class="mkl">
                                            <p>{{pick?.address}}</p>
                                        </div>

                                    </div>
                                    <div class="stts"
                                        *ngIf="pick?.isPickUp && pick?.bookingStatus !== constant.BOOKING_STATUS.ACCEPTED">
                                        <p>{{constant.SHOW_STATUS_BOOKING[pick?.bookingStatus]}}</p>
                                    </div>
                                </div>

                                <div class="mm last" *ngFor="let drop of bookingResponseData?.subBookingData">
                                    <div *ngIf="!drop?.isPickUp">
                                        <div class="blm">
                                            <div class="blc"></div>
                                        </div>
                                        <div class="mkl">
                                            <p>{{drop?.address}}</p>
                                        </div>
                                    </div>
                                    <div class="stts"
                                        *ngIf="!drop?.isPickUp && drop?.bookingStatus !== constant.BOOKING_STATUS.ACCEPTED">
                                        <p>{{constant.SHOW_STATUS_BOOKING[drop?.bookingStatus]}}</p>
                                    </div>
                                </div>
                            </div>
                        </Div> -->
            <!-- /// package detail start -->
            <div class="fs">
              <div class="fd">
                <h5 class="mb-0">
                  {{ "Pick up Address" | translate }}
                </h5>
              </div>
            </div>
            <div>
              <div class="">
                <div class="pickup_input" *ngFor="let pick of bookingResponseData?.subBookingData">
                  <div class="form-group border_remove" *ngIf="pick?.isPickUp">
                    <div class="form_grp_request_left">
                      <div class="blue_circle"></div>
                    </div>
                    <div class="form_grp_request_right">
                      <mat-form-field
                        class="
                          mat-form-field
                          example-full-width
                          ng-tns-c52-89
                          mat-primary
                          mat-form-field-type-mat-input
                          mat-form-field-appearance-fill
                          mat-form-field-can-float
                          mat-form-field-has-label
                          mat-form-field-hide-placeholder
                          mat-form-field-disabled
                        "
                      >
                        <div class="mat-form-field-wrapper ng-tns-c52-89">
                          <div class="mat-form-field-flex ng-tns-c52-89">
                            <div class="mat-form-field-infix ng-tns-c52-89">
                              <input
                                matInput
                                placeholder="{{ 'Choose your pickup address' | translate }}"
                                disabled=""
                                class="mat-input-element mat-form-field-autofill-control ng-tns-c52-89 cdk-text-field-autofill-monitored"
                                id="mat-input-57"
                                aria-invalid="false"
                                aria-required="false"
                              />
                              <span class="mat-form-field-label-wrapper ng-tns-c52-89">
                                <label
                                  class="mat-form-field-label ng-tns-c52-89 mat-empty mat-form-field-empty ng-star-inserted"
                                  id="mat-form-field-label-147"
                                  for="mat-input-57"
                                  aria-owns="mat-input-57"
                                >
                                  <mat-label _ngcontent-rbn-c120="" class="ng-tns-c52-89 ng-star-inserted">
                                    {{ pick?.address }}
                                  </mat-label>
                                </label>
                              </span>
                            </div>
                          </div>
                          <div class="mat-form-field-underline ng-tns-c52-89 ng-star-inserted">
                            <span class="mat-form-field-ripple ng-tns-c52-89"></span>
                          </div>
                          <div class="mat-form-field-subscript-wrapper ng-tns-c52-89">
                            <div
                              class="mat-form-field-hint-wrapper ng-tns-c52-89 ng-trigger ng-trigger-transitionMessages ng-star-inserted"
                              style="opacity: 1; transform: translateY(0%)"
                            >
                              <div class="mat-form-field-hint-spacer ng-tns-c52-89"></div>
                            </div>
                          </div>
                        </div>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="fs">
              <div class="fd">
                <h5 class="mb-0">
                  {{ "Package Details" | translate }}
                </h5>
              </div>
            </div>
            <div>
              <div class="card-body">
                <div class="flex">
                  <div class="ml">
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="carouselConfig">
                      <div ngxSlickItem *ngFor="let package of allPackagesDetail" class="slide">
                        <div class="add_vehicle_honda flex">
                          <div class="add_honda_left">
                            <div class="file">
                              <div class="file-inner">
                                <div class="nsf">
                                  <figure>
                                    <img [src]="baseUrl + package?.image" />
                                  </figure>
                                  <h5>
                                    <span>{{ package?.packageType.split(",")[0] }}</span
                                    >{{ package?.desc }}
                                  </h5>
                                </div>
                                <div class="nsf-secc">
                                  <span class="capitaAL_SVU">{{ "Quantity" | translate }}: {{ package?.quantity }}</span>
                                  <span class="capitaAL_SVU" *ngIf="package?.bookingStatus"
                                    >{{ "Status" | translate }}: {{ constant.SHOW_STATUS_BOOKING[package?.bookingStatus] }}</span
                                  >
                                </div>
                                <div class="nsf sec">
                                  <div [ngClass]="package?.bookingStatus === constant.BOOKING_STATUS.AVAILABLE ? 'blue_sqr' : 'red_circle'"></div>
                                  <div class="dtl">
                                    <p>
                                      {{ "Address" | translate }}:
                                      <span class="capitaAL_SVU">
                                        {{ package?.addressDetails?.itemDropup?.address }}
                                        {{ package?.bookingStatus }}</span
                                      >
                                    </p>
                                    <p
                                      *ngIf="
                                        package?.bookingStatus === constant.BOOKING_STATUS.CANCELED ||
                                        package?.bookingStatus === constant.BOOKING_STATUS.canceled ||
                                        package?.bookingStatus === constant.BOOKING_STATUS.END_RETURN
                                      "
                                    >
                                      {{ "Reason" | translate }}:
                                      <span class="capitaAL_SVU"> {{ package?.cancelReason }}</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ngx-slick-carousel>
                  </div>
                </div>
              </div>
            </div>

            <div id="reviewstwo" class="collapse" aria-labelledby="review2" data-parent="#faqExample"></div>
            <div class="card-body">
              <div class="flex">
                <div class="ml">
                  <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="carouselConfig">
                    <div ngxSlickItem *ngFor="let package of bookingResponseData?.packageDetails" class="slide">
                      <div class="add_vehicle_honda flex">
                        <div class="add_honda_left">
                          <div class="file">
                            <figure>
                              <img [src]="baseUrl + package?.itemImage" />
                            </figure>
                            <h5>
                              <span>{{ "Type" | translate }}: {{ package?.packageName }}</span>
                              <span>{{ "Desc" | translate }}: {{ package?.itemDescription }}</span>
                              <span class="capitaAL_SVU">{{ "Quantity" | translate }}: {{ package?.itemQuantity }}</span>
                              <span class="capitaAL_SVU">{{ "Status" | translate }}: {{ package?.status }}</span>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ngx-slick-carousel>
                </div>
              </div>
            </div>
          </div>
          <!-- package detail end  -->
          <!-- <div class="price-detail">
                        <div class="price-title">Total Price</div>
                        <div class="price-outer">
                            <div>Promo Discount</div>
                            <div>$ 10.00</div>
                        </div>
                        <div class="price-outer">
                            <div>Tax</div>
                            <div>$ 10.00</div>
                        </div>
                        <div class="price-outer">
                            <div>Delivery Charges</div>
                            <div>$ 10.00</div>
                        </div>
                        <div class="price-outer">
                            <div>Service Charges</div>
                            <div>$ 10.00</div>
                        </div>
                    </div> -->
          <hr />
          <div class="complt-price">
            <p class="amount-d">{{ bookingResponseData?.riderFare.toFixed(2) | currency: "USD" }}</p>
            <!-- <p class="grn-txt">Order is completed.</p> -->
          </div>
        </div>
        <div class="share_feebback">
          <div class="two-btn">
            <button class="btn btn-primary firstBtn rwdr" (click)="continue(14)">{{ "Review Driver" | translate }}</button>
            <!-- <button class="btn btn-outline secondBtn">View Bill</button> -->
          </div>
        </div>
      </div>
      <!-----end rider accept-->

      <div class="request_ride_inner border_remove" *ngIf="nextPopup == 14">
        <div class="driver_info_bx">
          <p class="arrving_time">{{ "Rate Your Delivery" | translate }}.</p>
          <div class="rate-screen">
            <!-- <h6>Your Delivery</h6> -->
          </div>
          <div class="rl">
            <div class="del-main">
              <div class="del-l">
                <figure style="border: 1px solid #cecece">
                  <img *ngIf="!driverReviewData?.image" src="assets/images/user.png" />
                  <img *ngIf="driverReviewData?.image" [src]="baseUrl + driverReviewData?.image" />
                </figure>
              </div>
              <div class="main-c">
                <div class="del-r del-u">
                  <h5>{{ driverReviewData?.firstName + " " + driverReviewData?.lastName }}</h5>
                </div>
                <div class="driver_rate_pop del-b">
                  <p>
                    {{ "Rating" | translate }}<span>{{ driverReviewData?.avgRating }}/5</span>
                  </p>
                  <p>
                    {{ "Review" | translate }}<span>{{ driverReviewData?.reviewTotal }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="heart">
              <i (click)="addFavouriteDriver()" *ngIf="!driverReviewData?.isFav" class="fa fa-heart-o redcolor" aria-hidden="true"></i>
              <i (click)="removeFavouriteDriver()" *ngIf="driverReviewData?.isFav" class="fa fa-heart redcolor" aria-hidden="true"></i>
            </div>
          </div>
          <div class="rating">
            <p class="title">{{ "Rate" | translate }}</p>
            <div class="star-rating">
              <button
                mat-icon-button
                *ngFor="let ratingId of ratingArr; index as i"
                [id]="'star_' + i"
                (click)="onClick(i + 1)"
                [matTooltip]="ratingId + 1"
                matTooltipPosition="above"
              >
                <mat-icon>
                  {{ showIcon(i) }}
                </mat-icon>
              </button>
            </div>
            <p class="rating-err" *ngIf="ratingSubmitted && rating === 0">{{ "Please give rating" | translate }}.</p>
          </div>
          <ul class="bages" *ngIf="rating > 2">
            <li
              [ngClass]="selectedDriverReview === i ? 'badge badge-secondary active' : 'badge badge-secondary'"
              *ngFor="let data of reviewData; let i = index"
              (click)="selectReview(i, data)"
            >
              {{ data?.name }}
            </li>
            <!-- <li [ngClass]="{'badge badge-secondary': data?.isActive === false,'badge badge-secondary active': data?.isActive === true}" *ngFor="let data of reviewData">{{data?.name}}</li> -->
          </ul>
          <div class="fgi sec" *ngIf="rating <= 2 && rating > 0">
            <textarea id="w3review" class="from-control" placeholder="Enter your feedback here..." [formControl]="review"></textarea>
          </div>
          <p class="rating-err" *ngIf="ratingSubmitted && selectedReviewData === '' && rating > 2">{{ "Please give feedback" | translate }}.</p>
          <p class="rating-err" *ngIf="ratingSubmitted && review.value === '' && rating <= 2">{{ "Please give feedback" | translate }}.</p>
        </div>
        <div class="tip-title">
          <p>{{ "Add a tip" | translate }}</p>
        </div>
        <div class="bb-hldr">
          <div [ngClass]="selectedTip === i ? 'bbxx active' : 'bbxx'" *ngFor="let item of dummy; let i = index" (click)="selectTip(i, item?.amount)">
            {{ "$ " + item?.amount }}
          </div>
        </div>
        <div class="custom-tip">
          <mat-form-field class="example-full-width">
            <input class="tip-input" type="number" [formControl]="customRate" matInput placeholder="Enter custom amount." />
          </mat-form-field>
        </div>
        <div class="feedback-links">
          <h5 class="fdd-links">{{ "Feedback Links" | translate }}</h5>
          <h6 class="dd">{{ "Driver" | translate }}</h6>
          <ul>
            <li>
              {{ "Was the driver on time?" | translate }}
              <div class="tp">
                <mat-radio-group aria-label="Select an option" [formControl]="wasTheDriverOnTime">
                  <mat-radio-button [value]="true">{{ "Yes" | translate }}</mat-radio-button>
                  <mat-radio-button [value]="false">{{ "No" | translate }}</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="ratingSubmitted && wasTheDriverOnTime.hasError('required')">Please select an option</mat-error>
              </div>
            </li>
            <li>
              {{ "Did the driver make your ride comfortabel?" | translate }}
              <div class="tp">
                <mat-radio-group aria-label="Select an option" [formControl]="driverMakeYouComfortabel">
                  <mat-radio-button [value]="true">{{ "Yes" | translate }}</mat-radio-button>
                  <mat-radio-button [value]="false">{{ "No" | translate }}</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="ratingSubmitted && driverMakeYouComfortabel.hasError('required')">Please select an option</mat-error>
              </div>
            </li>
            <li>
              {{ "Was the ride safe?" | translate }}
              <div class="tp">
                <mat-radio-group aria-label="Select an option" [formControl]="wasTheRideSafe">
                  <mat-radio-button [value]="true">{{ "Yes" | translate }}</mat-radio-button>
                  <mat-radio-button [value]="false">{{ "No" | translate }}</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="ratingSubmitted && wasTheRideSafe.hasError('required')">Please select an option</mat-error>
              </div>
            </li>
            <li class="imp">
              {{ "What can we improve for next time?" | translate }}
              <div class="tp">
                <mat-form-field class="example-full-width">
                  <textarea matInput [formControl]="comment" placeholder="Comment"></textarea>
                </mat-form-field>
              </div>
            </li>
          </ul>
        </div>
        <div class="bk-hm">
          <button class="btn btn-primary first-btn" (click)="addRating(0)">{{ "Submit" | translate }}</button>
          <button class="btn btn-outline second-btn" (click)="continue(0)">{{ "Skip" | translate }}</button>
        </div>
      </div>

      <!-------start delivery page html-->
      <div class="request_ride_inner part2" *ngIf="nextPopup == 10">
        <div class="car_using_main">
          <form [formGroup]="deliveryDetailForm">
            <div class="car_using_left">
              <h4>{{ "Delivery Details" | translate }}</h4>
              <div class="delivery_detail_form">
                <div class="form-group secl">
                  <div class="fgi">
                    <label>{{ "Delivery To" | translate }}</label>
                    <mat-form-field appearance="fill">
                      <mat-label>{{ "Delivery Type" | translate }}</mat-label>
                      <mat-select formControlName="deliveryTo">
                        <mat-option *ngFor="let food of foods" [value]="food.value">
                          {{ food.viewValue }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-error *ngIf="detailSubmitted && deliveryDetailForm.controls['deliveryTo'].hasError('required')">
                      {{ "Delivery type is required" | translate }}.</mat-error
                    >
                  </div>
                </div>
                <!-- <div class="form-group">
                                    <div class="fgi">
                                        <label>Choose your item drop off location</label>
                                        <input matInput class="from-control"
                                            placeholder="Search for item drop off location" autocorrect="off"
                                            autocapitalize="off" spellcheck="off" type="text"
                                            ngx-google-places-autocomplete #placesRef="ngx-places"
                                            (onAddressChange)="handleItemDropChnage($event)"
                                            formControlName="itemDropup" />
                                        <mat-error
                                            *ngIf="detailSubmitted && deliveryDetailForm.controls['itemDropup'].hasError('required')">
                                            Item Drop off location is required.</mat-error>
                                    </div>
                                </div> -->
                <div class="form-group">
                  <div class="fgi">
                    <label>{{ "Street Address" | translate }}</label>
                    <input type="text" formControlName="streetAdd" class="from-control" placeholder="{{ 'Street name/Street no' | translate }}." />
                    <mat-error *ngIf="detailSubmitted && deliveryDetailForm.controls['streetAdd'].hasError('required')">
                      {{ "Street address is required" | translate }}.</mat-error
                    >
                  </div>
                </div>
                <div class="form-group">
                  <div class="fgi">
                    <label>{{ "Suite/apt/office" | translate }}</label>
                    <input type="text" class="from-control" formControlName="suiteAptOffice" placeholder="{{ 'Suite/apt/office' | translate }}" />
                    <mat-error *ngIf="detailSubmitted && deliveryDetailForm.controls['suiteAptOffice'].hasError('required')">
                      {{ "Suite/apt/office is required" | translate }}.</mat-error
                    >
                  </div>
                </div>
                <div class="form-group">
                  <div class="fgi">
                    <label>{{ "City" | translate }}</label>
                    <input type="text" class="from-control" formControlName="city" placeholder="{{ 'Enter Your City Name' | translate }}" />
                    <mat-error *ngIf="detailSubmitted && deliveryDetailForm.controls['city'].hasError('required')">
                      {{ "City name is required" | translate }}.</mat-error
                    >
                  </div>
                </div>
                <div class="form-group">
                  <div class="fgi">
                    <label>{{ "Province" | translate }}</label>
                    <input type="text" class="from-control" formControlName="province" placeholder="{{ 'Enter Province' | translate }}" />
                    <mat-error *ngIf="detailSubmitted && deliveryDetailForm.controls['province'].hasError('required')">
                      {{ "Province is required" | translate }}.</mat-error
                    >
                  </div>
                </div>
                <div class="form-group">
                  <div class="fgi">
                    <label>{{ "Postal/Zip code" | translate }}</label>
                    <input type="text" class="from-control" formControlName="zipCode" placeholder="{{ 'Enter Postal/Zip code' | translate }}" />
                    <mat-error *ngIf="detailSubmitted && deliveryDetailForm.controls['zipCode'].hasError('required')">
                      {{ "Postal/Zip code is required" | translate }}.</mat-error
                    >
                  </div>
                </div>
                <div class="form-group">
                  <div class="fgi">
                    <label>{{ "Phone Number" | translate }}</label>
                    <ngx-intl-tel-input
                      [inputId]="'adsasd'"
                      [cssClass]="'custom'"
                      [preferredCountries]="preferredCountries"
                      [enableAutoCountrySelect]="false"
                      [enablePlaceholder]="true"
                      [searchCountryFlag]="true"
                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                      [selectFirstCountry]="false"
                      [selectedCountryISO]="CountryISO.Canada"
                      [maxLength]="15"
                      [tooltipField]="TooltipLabel.Name"
                      [phoneValidation]="true"
                      [separateDialCode]="separateDialCode"
                      name="phone"
                      formControlName="phoneNo"
                    >
                    </ngx-intl-tel-input>
                    <mat-error *ngIf="detailSubmitted && deliveryDetailForm.controls['phoneNo'].hasError('required')">
                      {{ "Phone number is required" | translate }}.</mat-error
                    >
                    <mat-error *ngIf="detailSubmitted && deliveryDetailForm.controls['phoneNo'].hasError('validatePhoneNumber')">
                      {{ "Please enter valid phone number" | translate }}.</mat-error
                    >
                  </div>
                </div>
                <div class="form-group">
                  <div class="fgi">
                    <label>{{ "Additional Details" | translate }}</label>
                    <!-- <input type="text" class="from-control" formControlName="additionalInfo"
                                            placeholder="Additional Details" /> -->
                    <textarea id="w3review" class="from-control" formControlName="additionalInfo"> </textarea>
                  </div>
                </div>
              </div>
              <div class="two_btn">
                <!-- <div class="commen_btn_outer">
                                    <button class="btn btn-secondary" (click)="continue('0')">Back</button>
                                </div> -->
                <div class="commen_btn_outer">
                  <button class="btn btn-primary" (click)="goToPackageDetail(11)">{{ "Next" | translate }}</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-------End delivery page html-->

      <!-----start package deatail page-->
      <div class="request_ride_inner part2" *ngIf="nextPopup == 11">
        <div class="car_using_main">
          <div class="car_using_left">
            <h4>{{ "Package Details" | translate }}</h4>
            <div class="ml">
              <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="carouselConfig">
                <div ngxSlickItem *ngFor="let package of allPackagesDetail" class="slide">
                  <div class="add_vehicle_honda flex">
                    <div class="add_honda_left">
                      <div class="file">
                        <div class="file-inner">
                          <div class="nsf">
                            <figure>
                              <img [src]="baseUrl + package?.image" />
                            </figure>
                            <h5>
                              <span>{{ package?.packageType.split(",")[1] }}</span>
                              <span class="desc-txt">{{ package?.desc }}</span>
                              <span class="capitaAL_SVU">{{ "Quantity" | translate }}: {{ package?.quantity }}</span>
                            </h5>
                          </div>
                          <div class="nsf sec">
                            <div class="red_circle"></div>
                            <h5>
                              <span class="capitaAL_SVU">{{ "Address" | translate }}: {{ package?.addressDetails?.itemDropup?.address }}</span>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="add_vehicle_right flex">
                      <div class="add_vehicle_right_icon" style="cursor: pointer" (click)="editPackageDetail(package)">
                        <figure>
                          <svg id="pencil" xmlns="http://www.w3.org/2000/svg" width="15.229" height="15.23" viewBox="0 0 15.229 15.23">
                            <g id="Group_11780" data-name="Group 11780" transform="translate(0 0)">
                              <g id="Group_11779" data-name="Group 11779" transform="translate(0 0)">
                                <path
                                  id="Path_24244"
                                  data-name="Path 24244"
                                  d="M11.288,81.128a.356.356,0,0,0-.489,0L4.215,87.717a.346.346,0,0,0,.09.555l.54.271v.91l-1.656.474L1.518,88.254,1.991,86.6H2.9l.27.541a.347.347,0,0,0,.254.187.342.342,0,0,0,.055,0,.345.345,0,0,0,.245-.1l6.583-6.589a.347.347,0,0,0,0-.49L9.036,78.874a.346.346,0,0,0-.245-.1h0a.346.346,0,0,0-.245.1L1.485,86.006s0,.009-.007.012a.339.339,0,0,0-.081.137l-.6,2.1L.013,91.005a.347.347,0,0,0,.333.442.352.352,0,0,0,.095-.013l2.743-.784,2.1-.6c.006,0,.011-.007.017-.009a.641.641,0,0,0,.117-.069s.01-.005.015-.01l7.125-7.067a.347.347,0,0,0,0-.491Z"
                                  transform="translate(0 -76.217)"
                                  fill="#0171bd"
                                />
                                <path
                                  id="Path_24245"
                                  data-name="Path 24245"
                                  d="M295.708,2.474,293.631.4a1.415,1.415,0,0,0-1.959,0L290.4,1.677a.347.347,0,0,0,0,.489L293.939,5.7a.346.346,0,0,0,.488,0l1.28-1.27a1.386,1.386,0,0,0,0-1.959Z"
                                  transform="translate(-280.884 -0.002)"
                                  fill="#0171bd"
                                />
                              </g>
                            </g>
                          </svg>
                        </figure>
                      </div>
                      <div class="add_vehicle_right_icon" style="cursor: pointer" (click)="deletePackageDetail(package)">
                        <figure>
                          <svg id="delete_2_" xmlns="http://www.w3.org/2000/svg" width="14.169" height="16.193" viewBox="0 0 14.169 16.193">
                            <path
                              id="Path_24246"
                              data-name="Path 24246"
                              d="M64.444,139.01a2.026,2.026,0,0,0,2.024,2.024h8.1a2.026,2.026,0,0,0,2.024-2.024V128.889H64.444Z"
                              transform="translate(-63.432 -124.841)"
                              fill="#FF0000"
                            />
                            <path
                              id="Path_24247"
                              data-name="Path 24247"
                              d="M41.331,1.012V0H37.282V1.012h-5.06V3.036H46.391V1.012Z"
                              transform="translate(-32.222)"
                              fill="#FF0000"
                            />
                          </svg>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </ngx-slick-carousel>
            </div>
            <form [formGroup]="packageDetailForm">
              <div class="delivery_detail_form">
                <div class="form-group secl">
                  <div class="fgi">
                    <label>{{ "Package Type" | translate }}</label>
                    <mat-form-field appearance="fill">
                      <mat-label>{{ "Select package type" | translate }}</mat-label>
                      <mat-select formControlName="packageType">
                        <mat-option *ngFor="let pckg of packageType" [value]="pckg?._id + ',' + pckg?.packageName">
                          {{ pckg?.packageName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-error *ngIf="packageSubmitted && packageDetailForm.controls['packageType'].hasError('required')">
                      {{ "Package type is required" | translate }}.</mat-error
                    >
                  </div>
                </div>

                <div class="form-group">
                  <div class="fgi">
                    <label>{{ "Quantity" | translate }}</label>
                    <input
                      type="number"
                      min="1"
                      max="1000"
                      class="from-control"
                      formControlName="quantity"
                      placeholder="{{ 'Quantity' | translate }}"
                    />
                    <mat-error *ngIf="packageSubmitted && packageDetailForm.controls['quantity'].hasError('required')">
                      {{ "Quantity is required" | translate }}.</mat-error
                    >
                    <mat-error *ngIf="packageSubmitted && negativeValidator && !packageDetailForm.controls['quantity'].hasError('required')">
                      {{ "Quantity should be greater then zero and less than One thousand" | translate }}</mat-error
                    >
                  </div>
                </div>

                <div class="form-group">
                  <div class="fgi">
                    <label class="ride_request">{{ "Choose your item drop off location" | translate }} </label>
                    <mat-form-field class="example-full-width">
                      <input
                        class="from-control fv-icon"
                        matInput
                        placeholder="{{ 'Search for item drop off location' | translate }}"
                        autocorrect="off"
                        autocapitalize="off"
                        spellcheck="off"
                        type="text"
                        ngx-google-places-autocomplete
                        #placesRef="ngx-places"
                        (change)="dropChange()"
                        (onAddressChange)="handleItemDropChnage($event)"
                        formControlName="itemDropup"
                      />
                      <figure class="add-fav-icon-drp" (click)="goToFavAdd('drop', 20, allPackagesDetail.length - 1)">
                        <img src="assets/images/fav-add-icon.png" />
                      </figure>
                      <p style="color: red" *ngIf="packageSubmitted && packageDetailForm.controls['itemDropup'].hasError('required')">
                        {{ "Item drop off address is required" | translate }}.
                      </p>
                    </mat-form-field>

                    <!-- <p style="color: red;"
                                                *ngIf="rideSubmited === true && !isDropChanged && !bookingForm.controls['rideDropAddress'].hasError('required')">
                                                Please enter valid destination.</p> -->
                  </div>
                </div>
                <div class="form-group">
                  <div class="fgi">
                    <label class="ride_request">{{ "Item image" | translate }} </label>
                    <div class="fg sec">
                      <div class="fgi" style="cursor: pointer">
                        <figure (click)="fileUpload.click()">
                          <svg
                            *ngIf="!fileDataUrl"
                            height="511pt"
                            fill="#ccc"
                            viewBox="0 -21 511.98744 511"
                            width="511pt"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="m133.320312 373.828125c-34.152343 0-64.53125-21.867187-75.5625-54.421875l-.746093-2.453125c-2.601563-8.621094-3.691407-15.871094-3.691407-23.125v-145.453125l-51.753906 172.757812c-6.65625 25.410157 8.511719 51.753907 33.960938 58.773438l329.878906 88.34375c4.117188 1.066406 8.234375 1.578125 12.289062 1.578125 21.246094 0 40.660157-14.101563 46.101563-34.882813l19.21875-61.117187zm0 0"
                            />
                            <path
                              d="m191.988281 149.828125c23.53125 0 42.664063-19.136719 42.664063-42.667969s-19.132813-42.667968-42.664063-42.667968-42.667969 19.136718-42.667969 42.667968 19.136719 42.667969 42.667969 42.667969zm0 0"
                            />
                            <path
                              d="m458.652344.492188h-320c-29.394532 0-53.332032 23.9375-53.332032 53.335937v234.664063c0 29.398437 23.9375 53.335937 53.332032 53.335937h320c29.398437 0 53.335937-23.9375 53.335937-53.335937v-234.664063c0-29.398437-23.9375-53.335937-53.335937-53.335937zm-320 42.667968h320c5.890625 0 10.667968 4.777344 10.667968 10.667969v151.445313l-67.390624-78.636719c-7.148438-8.382813-17.496094-12.863281-28.609376-13.117188-11.050781.0625-21.417968 4.96875-28.5 13.460938l-79.234374 95.101562-25.8125-25.75c-14.589844-14.589843-38.335938-14.589843-52.90625 0l-58.878907 58.859375v-201.363281c0-5.890625 4.777344-10.667969 10.664063-10.667969zm0 0"
                            />
                          </svg>
                          <img *ngIf="fileDataUrl" [src]="baseUrl + fileDataUrl" />
                        </figure>
                        <p *ngIf="!fileDataUrl">{{ "Upload image" | translate }}</p>
                      </div>

                      <input
                        type="file"
                        accept="image/*"
                        style="cursor: pointer"
                        #fileUpload
                        formControlName="file"
                        (change)="onFileChange($event)"
                        id="myfile"
                        name="myfile"
                      />
                      <mat-error *ngIf="packageSubmitted && packageDetailForm.controls['file'].hasError('required')">
                        {{ "Package image is required" | translate }}.</mat-error
                      >
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="fgi sec">
                    <label>{{ "Item description" | translate }}</label>
                    <textarea id="w3review" placeholder="Item description.." class="from-control" formControlName="itemDesc"> </textarea>
                    <mat-error *ngIf="packageSubmitted && packageDetailForm.controls['itemDesc'].hasError('required')">
                      {{ "Item description is required" | translate }}.</mat-error
                    >
                  </div>
                </div>
              </div>
            </form>
            <div class="package_btn">
              <div class="commen_btn_outer">
                <button class="btn btn-primary borders" (click)="continue(0)">{{ "Back" | translate }}</button>
              </div>
              <div class="commen_btn_outer">
                <button class="btn btn-primary borders" (click)="addItem()">{{ "More Items" | translate }}</button>
              </div>
              <div class="commen_btn_outer">
                <button class="btn btn-primary" (click)="goToSelectPackageSize(1)">
                  {{ "Next" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-----End package deatail page-->
      <!----start Review Detail page-->

      <!-----start package deatail page-->
      <div class="request_ride_inner part2" *ngIf="nextPopup == 12">
        <div class="car_using_main review_Detail">
          <!---=======================================================-->
          <!---------start Faq Section--------->
          <!---=======================================================-->
          <div class="container py-3" style="width: 95%">
            <div class="row">
              <div class="faq car_using_left review-detail">
                <h4>{{ "Review Details" | translate }}</h4>

                <div class="fs">
                  <div class="fd">
                    <h5 class="mb-0">
                      {{ "Pick up Address" | translate }}
                    </h5>
                  </div>
                </div>
                <div>
                  <div class="">
                    <div class="pickup_input" *ngFor="let pick of pickupfieldsArr">
                      <div class="form-group border_remove">
                        <div class="form_grp_request_left">
                          <div class="blue_circle"></div>
                        </div>
                        <div class="form_grp_request_right">
                          <mat-form-field
                            class="
                              mat-form-field
                              example-full-width
                              ng-tns-c52-89
                              mat-primary
                              mat-form-field-type-mat-input
                              mat-form-field-appearance-fill
                              mat-form-field-can-float
                              mat-form-field-has-label
                              mat-form-field-hide-placeholder
                              mat-form-field-disabled
                            "
                          >
                            <div class="mat-form-field-wrapper ng-tns-c52-89">
                              <div class="mat-form-field-flex ng-tns-c52-89">
                                <div class="mat-form-field-infix ng-tns-c52-89">
                                  <input
                                    matInput
                                    placeholder="{{ 'Choose your Pickup' | translate }}"
                                    disabled=""
                                    class="mat-input-element mat-form-field-autofill-control ng-tns-c52-89 cdk-text-field-autofill-monitored"
                                    id="mat-input-57"
                                    aria-invalid="false"
                                    aria-required="false"
                                  />
                                  <span class="mat-form-field-label-wrapper ng-tns-c52-89">
                                    <label
                                      class="mat-form-field-label ng-tns-c52-89 mat-empty mat-form-field-empty ng-star-inserted"
                                      id="mat-form-field-label-147"
                                      for="mat-input-57"
                                      aria-owns="mat-input-57"
                                    >
                                      <mat-label _ngcontent-rbn-c120="" class="ng-tns-c52-89 ng-star-inserted">
                                        {{ pickupForm?.controls[pick?.name].value }}
                                      </mat-label>
                                    </label>
                                  </span>
                                </div>
                              </div>
                              <div class="mat-form-field-underline ng-tns-c52-89 ng-star-inserted">
                                <span class="mat-form-field-ripple ng-tns-c52-89"></span>
                              </div>
                              <div class="mat-form-field-subscript-wrapper ng-tns-c52-89">
                                <div
                                  class="mat-form-field-hint-wrapper ng-tns-c52-89 ng-trigger ng-trigger-transitionMessages ng-star-inserted"
                                  style="opacity: 1; transform: translateY(0%)"
                                >
                                  <div class="mat-form-field-hint-spacer ng-tns-c52-89"></div>
                                </div>
                              </div>
                            </div>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fs">
                  <div class="fd">
                    <h5 class="mb-0">
                      {{ "Package Details" | translate }}
                    </h5>
                  </div>
                </div>
                <div>
                  <div class="card-body">
                    <div class="flex">
                      <div class="ml">
                        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="carouselConfig">
                          <div ngxSlickItem *ngFor="let package of allPackagesDetail" class="slide">
                            <div class="add_vehicle_honda flex">
                              <div class="add_honda_left">
                                <div class="file">
                                  <div class="file-inner">
                                    <div class="nsf">
                                      <figure>
                                        <img [src]="baseUrl + package?.image" />
                                      </figure>
                                      <h5>
                                        <span>{{ package?.packageType.split(",")[1] }}</span>
                                        <span class="dewsc-txt">{{ package?.desc }}</span>
                                        <span class="capitaAL_SVU">{{ "Quantity" | translate }}: {{ package?.quantity }}</span>
                                      </h5>
                                    </div>
                                    <div class="nsf sec">
                                      <div class="red_circle"></div>
                                      <h5>
                                        <span class="capitaAL_SVU rvw"
                                          >{{ "Address" | translate }}: {{ package?.addressDetails?.itemDropup?.address }}</span
                                        >
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ngx-slick-carousel>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fs">
                  <div class="fd">
                    <h5 class="mb-0">
                      {{ "Choices" | translate }}
                    </h5>
                  </div>
                </div>

                <div>
                  <div class="">
                    <ul>
                      <li>
                        <h6>{{ "Package Sizes" | translate }}</h6>
                        <div class="fl">
                          <div class="flleft">
                            <figure><img [src]="baseUrl + selectedPackageSIze?.blackImage" /></figure>
                          </div>
                          <div class="flright">
                            <h5>
                              {{ selectedPackageSIze?.packageSizeName }}
                              <span>{{ selectedPackageSIze?.size }}</span>
                            </h5>
                          </div>
                        </div>
                      </li>
                      <li>
                        <h6>{{ "Vehicle" | translate }}</h6>
                        <div class="fl">
                          <div class="flleft">
                            <figure><img [src]="selectedVehicleData?.vehicleImage" /></figure>
                          </div>
                          <div class="flright">
                            <h5>
                              {{ selectedVehicleData?.vehicleCategory }}
                              <span
                                >${{
                                  selectedVehicleData?.discount !== undefined ? selectedVehicleData?.discount : selectedVehicleData?.booKingAmount
                                }}</span
                              >
                            </h5>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="estimated_cost">
                  <h4>{{ "Estimated Price" | translate }}</h4>
                  <h5>{{ selectedVehicleData?.discount !== undefined ? selectedVehicleData?.discount : selectedVehicleData?.booKingAmount }}</h5>
                </div>
                <!-- <div class="package_btn">
                                    <div class="commen_btn_outer preview_btn order_items_btn">
                                        <button class="btn btn-primary" (click)="requestBooking('3')">
                                            Confirm and request booking
                                        </button>
                                    </div>
                                </div> -->
                <!-- <div class="two_btn">
                                    <div class="commen_btn_outer">
                                        <button class="btn btn-secondary" (click)="continue(1)">Back</button>
                                    </div>
                                    <div class="commen_btn_outer">
                                        <button class="btn btn-primary" (click)="requestBooking('3')">
                                            Confirm and request booking
                                        </button>
                                    </div>
                                </div> -->
                <div class="btn_divde custom_margin">
                  <div class="btn_outer inactive">
                    <button class="btn btn-secondary" (click)="continue(1)">{{ "Back" | translate }}</button>
                  </div>
                  <div class="btn_outer">
                    <button class="btn btn-primary" (click)="requestBooking('3')">{{ "Request booking" | translate }}</button>
                  </div>
                </div>
              </div>
            </div>
            <!--/row-->
          </div>
          <!---=======================================================-->
          <!---------End Faq Section--------->
          <!---=======================================================-->
          <!-- 

                    <div class="car_using_left">
                        <h4>Review Details</h4>
                        <div class="pickup_input">
                            <div class="form-group border_remove">
                                <div class="form_grp_request_left">
                                    <div class="blue_circle">

                                    </div>
                                </div>
                                <div class="form_grp_request_right">
                                    <mat-form-field
                                        class="mat-form-field example-full-width ng-tns-c52-89 mat-primary mat-form-field-type-mat-input mat-form-field-appearance-fill mat-form-field-can-float mat-form-field-has-label mat-form-field-hide-placeholder mat-form-field-disabled">
                                        <div class="mat-form-field-wrapper ng-tns-c52-89">
                                            <div class="mat-form-field-flex ng-tns-c52-89">
                                                <div class="mat-form-field-infix ng-tns-c52-89">
                                                    <input matInput="" placeholder="Choose your Pickup" disabled=""
                                                        class="mat-input-element mat-form-field-autofill-control ng-tns-c52-89 cdk-text-field-autofill-monitored"
                                                        id="mat-input-57" aria-invalid="false" aria-required="false">
                                                    <span class="mat-form-field-label-wrapper ng-tns-c52-89">
                                                        <label
                                                            class="mat-form-field-label ng-tns-c52-89 mat-empty mat-form-field-empty ng-star-inserted"
                                                            id="mat-form-field-label-147" for="mat-input-57"
                                                            aria-owns="mat-input-57">
                                                           
                                                            <mat-label _ngcontent-rbn-c120=""
                                                                class="ng-tns-c52-89 ng-star-inserted">{{bookingForm.controls['ridePickUpAddress'].value}}</mat-label>
                                                        </label>
                                                        </span>
                                                </div>
                                               
                                            </div>
                                            <div class="mat-form-field-underline ng-tns-c52-89 ng-star-inserted">
                                                <span class="mat-form-field-ripple ng-tns-c52-89"></span></div>
                                            
                                            <div class="mat-form-field-subscript-wrapper ng-tns-c52-89">
                                               
                                                <div class="mat-form-field-hint-wrapper ng-tns-c52-89 ng-trigger ng-trigger-transitionMessages ng-star-inserted"
                                                    style="opacity: 1; transform: translateY(0%);">
                                                    
                                                    <div class="mat-form-field-hint-spacer ng-tns-c52-89"></div>
                                                </div>
                                              
                                            </div>
                                        </div>
                                    </mat-form-field>
                                </div>
                            </div>


                        </div>
                        <div class="pickup_input">
                            <div class="form-group border_remove last">
                                <div class="form_grp_request_left">
                                    <div class="yellow_circle">

                                    </div>
                                </div>
                                <div class="form_grp_request_right">
                                    <mat-form-field
                                        class="mat-form-field example-full-width ng-tns-c52-89 mat-primary mat-form-field-type-mat-input mat-form-field-appearance-fill mat-form-field-can-float mat-form-field-has-label mat-form-field-hide-placeholder mat-form-field-disabled">
                                        <div class="mat-form-field-wrapper ng-tns-c52-89">
                                            <div class="mat-form-field-flex ng-tns-c52-89">
                                                <div class="mat-form-field-infix ng-tns-c52-89">
                                                    <input matInput="" placeholder="Choose your Pickup" disabled=""
                                                        class="mat-input-element mat-form-field-autofill-control ng-tns-c52-89 cdk-text-field-autofill-monitored"
                                                        id="mat-input-57" aria-invalid="false" aria-required="false">
                                                    <span class="mat-form-field-label-wrapper ng-tns-c52-89">
                                                        <label
                                                            class="mat-form-field-label ng-tns-c52-89 mat-empty mat-form-field-empty ng-star-inserted"
                                                            id="mat-form-field-label-147" for="mat-input-57"
                                                            aria-owns="mat-input-57">
                                                         
                                                            <mat-label _ngcontent-rbn-c120=""
                                                                class="ng-tns-c52-89 ng-star-inserted">{{bookingForm.controls['rideDropAddress'].value}}</mat-label>
                                                        </label>
                                                        </span>
                                                </div>
                                               
                                            </div>
                                            <div class="mat-form-field-underline ng-tns-c52-89 ng-star-inserted">
                                                <span class="mat-form-field-ripple ng-tns-c52-89"></span></div>
                                            
                                            <div class="mat-form-field-subscript-wrapper ng-tns-c52-89">
                                                
                                                <div class="mat-form-field-hint-wrapper ng-tns-c52-89 ng-trigger ng-trigger-transitionMessages ng-star-inserted"
                                                    style="opacity: 1; transform: translateY(0%);">
                                                  
                                                    <div class="mat-form-field-hint-spacer ng-tns-c52-89"></div>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </mat-form-field>
                                </div>
                            </div>


                        </div>
                        <div class="delivery_detail_form">
                            <div class="delivery_view_item">
                                <label for="">Delivery To</label>
                                <p>{{deliveryDetailForm.controls['province'].value}}</p>
                            </div>
                            <div class="delivery_view_item">
                                <label for="">Street Address</label>
                                <p>{{deliveryDetailForm.controls['streetAdd'].value}}</p>
                            </div>
                            <div class="delivery_view_item">
                                <label for="">Building/apt/suite</label>
                                <p>{{deliveryDetailForm.controls['suiteAptOffice'].value}}</p>
                            </div>

                            <div class="delivery_view_item">
                                <label for="">City</label>
                                <p>{{deliveryDetailForm.controls['city'].value}}</p>
                            </div>
                            <div class="delivery_view_item">
                                <label for="">Province</label>
                                <p>{{deliveryDetailForm.controls['province'].value}}</p>
                            </div>
                            <div class="delivery_view_item">
                                <label for="">Postal/Zip code:</label>
                                <p>{{deliveryDetailForm.controls['zipCode'].value}}</p>
                            </div>
                            <div class="delivery_view_item">
                                <label for="">Phone Number</label>
                                <p>{{deliveryDetailForm.controls['phoneNo'].value.e164Number}}</p>
                            </div>
                            <div class="delivery_view_item">
                                <label for="">Additional Details</label>
                                <p>{{deliveryDetailForm.controls['additionalInfo'].value}}</p>
                            </div>
                        </div>
                      
                        <div class="estimated_cost">
                            <h4>Estimated cost</h4>
                            <h5>${{getFare(selectedVehicleData?.pricePerMile)}}</h5>
                        </div>
                        <div class="package_btn">
                            <div class="commen_btn_outer preview_btn order_items_btn">
                                <button class="btn btn-primary" (click)="requestBooking('3')">
                                    Confirm And Request Booking
                                </button>
                            </div>
                        </div>
                    </div> -->
        </div>
      </div>
      <!-----End package deatail page-->
      <!----End Review Detail page-->

      <!-----start package deatail page-->
      <div class="request_ride_inner part2" *ngIf="nextPopup == 13">
        <div class="car_using_main review_using_detail">
          <div class="car_using_left">
            <h4>{{ "Order Details" | translate }}</h4>
            <div class="accordion" id="faqExample">
              <div class="card">
                <div class="card-header p-2" id="headingTwo">
                  <div class="fs collapsed" type="button" data-toggle="collapse" data-target="#collapseone1">
                    <div class="fd">
                      <h5 class="mb-0">
                        {{ "Delivery Details" | translate }}
                      </h5>
                    </div>
                    <div class="arrow"><i class="fa fa-angle-down"></i></div>
                  </div>
                </div>
                <div id="collapseone1" class="collapse" aria-labelledby="headingTwo" data-parent="#faqExample">
                  <div class="card-body">
                    <div class="pickup_input">
                      <div class="form-group border_remove">
                        <div class="form_grp_request_left">
                          <div class="blue_circle"></div>
                        </div>
                        <div class="form_grp_request_right">
                          <mat-form-field
                            class="
                              mat-form-field
                              example-full-width
                              ng-tns-c52-89
                              mat-primary
                              mat-form-field-type-mat-input
                              mat-form-field-appearance-fill
                              mat-form-field-can-float
                              mat-form-field-has-label
                              mat-form-field-hide-placeholder
                              mat-form-field-disabled
                            "
                          >
                            <div class="mat-form-field-wrapper ng-tns-c52-89">
                              <div class="mat-form-field-flex ng-tns-c52-89">
                                <div class="mat-form-field-infix ng-tns-c52-89">
                                  <input
                                    matInput
                                    placeholder="Choose your Pickup"
                                    disabled=""
                                    class="mat-input-element mat-form-field-autofill-control ng-tns-c52-89 cdk-text-field-autofill-monitored"
                                    id="mat-input-57"
                                    aria-invalid="false"
                                    aria-required="false"
                                  />
                                  <span class="mat-form-field-label-wrapper ng-tns-c52-89">
                                    <label
                                      class="mat-form-field-label ng-tns-c52-89 mat-empty mat-form-field-empty ng-star-inserted"
                                      id="mat-form-field-label-147"
                                      for="mat-input-57"
                                      aria-owns="mat-input-57"
                                    >
                                      <!---->
                                      <mat-label _ngcontent-rbn-c120="" class="ng-tns-c52-89 ng-star-inserted">
                                        {{ bookingForm.controls["ridePickUpAddress"].value }}
                                      </mat-label>
                                    </label>
                                    <!---->
                                  </span>
                                </div>
                                <!---->
                              </div>
                              <div class="mat-form-field-underline ng-tns-c52-89 ng-star-inserted">
                                <span class="mat-form-field-ripple ng-tns-c52-89"></span>
                              </div>
                              <!---->
                              <div class="mat-form-field-subscript-wrapper ng-tns-c52-89">
                                <!---->
                                <div
                                  class="mat-form-field-hint-wrapper ng-tns-c52-89 ng-trigger ng-trigger-transitionMessages ng-star-inserted"
                                  style="opacity: 1; transform: translateY(0%)"
                                >
                                  <!---->
                                  <div class="mat-form-field-hint-spacer ng-tns-c52-89"></div>
                                </div>
                                <!---->
                              </div>
                            </div>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <!--===========================================-->
                    <!------Start Yellow Icon input------->
                    <!--===========================================-->
                    <div class="pickup_input">
                      <div class="form-group border_remove last">
                        <div class="form_grp_request_left">
                          <div class="yellow_circle"></div>
                        </div>
                        <div class="form_grp_request_right">
                          <mat-form-field
                            class="
                              mat-form-field
                              example-full-width
                              ng-tns-c52-89
                              mat-primary
                              mat-form-field-type-mat-input
                              mat-form-field-appearance-fill
                              mat-form-field-can-float
                              mat-form-field-has-label
                              mat-form-field-hide-placeholder
                              mat-form-field-disabled
                            "
                          >
                            <div class="mat-form-field-wrapper ng-tns-c52-89">
                              <div class="mat-form-field-flex ng-tns-c52-89">
                                <div class="mat-form-field-infix ng-tns-c52-89">
                                  <input
                                    matInput
                                    placeholder="Choose your Pickup"
                                    disabled=""
                                    class="mat-input-element mat-form-field-autofill-control ng-tns-c52-89 cdk-text-field-autofill-monitored"
                                    id="mat-input-57"
                                    aria-invalid="false"
                                    aria-required="false"
                                  />
                                  <span class="mat-form-field-label-wrapper ng-tns-c52-89">
                                    <label
                                      class="mat-form-field-label ng-tns-c52-89 mat-empty mat-form-field-empty ng-star-inserted"
                                      id="mat-form-field-label-147"
                                      for="mat-input-57"
                                      aria-owns="mat-input-57"
                                    >
                                      <!---->
                                      <mat-label _ngcontent-rbn-c120="" class="ng-tns-c52-89 ng-star-inserted">
                                        {{ bookingForm.controls["rideDropAddress"].value }}
                                      </mat-label>
                                    </label>
                                    <!---->
                                  </span>
                                </div>
                                <!---->
                              </div>
                              <div class="mat-form-field-underline ng-tns-c52-89 ng-star-inserted">
                                <span class="mat-form-field-ripple ng-tns-c52-89"></span>
                              </div>
                              <!---->
                              <div class="mat-form-field-subscript-wrapper ng-tns-c52-89">
                                <!---->
                                <div
                                  class="mat-form-field-hint-wrapper ng-tns-c52-89 ng-trigger ng-trigger-transitionMessages ng-star-inserted"
                                  style="opacity: 1; transform: translateY(0%)"
                                >
                                  <!---->
                                  <div class="mat-form-field-hint-spacer ng-tns-c52-89"></div>
                                </div>
                                <!---->
                              </div>
                            </div>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="order_address">
                      <div class="delivery_detail_form">
                        <div class="delivery_view_item">
                          <label for="">{{ "Delivery To" | translate }}</label>
                          <p>{{ bookingResponseData?.addressType }}</p>
                        </div>
                        <div class="delivery_view_item">
                          <label for="">{{ "Street Address" | translate }}</label>
                          <p>{{ bookingResponseData?.streetAddress }}</p>
                        </div>
                        <div class="delivery_view_item">
                          <label for="">{{ "Building/apt/suite" | translate }}</label>
                          <p>{{ bookingResponseData?.suit }}</p>
                        </div>

                        <div class="delivery_view_item">
                          <label for="">{{ "City" | translate }}</label>
                          <p>{{ bookingResponseData?.city }}</p>
                        </div>
                        <div class="delivery_view_item">
                          <label for="">{{ "Province" | translate }}</label>
                          <p>{{ bookingResponseData?.province }}</p>
                        </div>
                        <div class="delivery_view_item">
                          <label for="">{{ "Postal/Zip code" | translate }}:</label>
                          <p>{{ bookingResponseData?.zipCode }}</p>
                        </div>
                        <div class="delivery_view_item">
                          <label for="">{{ "Phone Number" | translate }}</label>
                          <p>{{ bookingResponseData?.phoneNo }}</p>
                        </div>
                        <div class="delivery_view_item" *ngIf="bookingResponseData?.additionalInfo">
                          <label for="">{{ "Additional Details" | translate }}</label>
                          <p>{{ bookingResponseData?.additionalInfo }}</p>
                        </div>
                      </div>
                      <div class="order_form_input">
                        <label> {{ "Phone Number" | translate }} </label>
                        <p>{{ bookingResponseData?.phoneNo }}</p>
                      </div>
                      <div class="order_form_input" *ngIf="bookingResponseData?.additionalInfo">
                        <label> {{ "Additional Details" | translate }} </label>
                        <p>{{ bookingResponseData?.additionalInfo }}</p>
                      </div>
                    </div>
                    <div class="order_total_price">
                      <h3>{{ "Total Price" | translate }}</h3>
                      <table>
                        <tr>
                          <td>Promo Discount</td>
                          <td>$10.00</td>
                        </tr>
                        <tr>
                          <td>Tax</td>
                          <td>$10.00</td>
                        </tr>
                        <tr>
                          <td>Delivery Charges</td>
                          <td>$10.00</td>
                        </tr>
                        <tr>
                          <td>Service charge</td>
                          <td>$10.00</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="package_click">
                <div class="card">
                  <div class="card-header p-2" id="headingTwo">
                    <div class="fs collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo2">
                      <div class="fd">
                        <h5 class="mb-0">
                          {{ "Package Details" | translate }}
                        </h5>
                      </div>
                      <div class="arrow"><i class="fa fa-angle-down"></i></div>
                    </div>
                  </div>
                  <div id="collapseTwo2" class="collapse" aria-labelledby="headingTwo" data-parent="#faqExample">
                    <div class="card-body">
                      <div class="flex">
                        <div class="ml">
                          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="carouselConfig">
                            <div ngxSlickItem *ngFor="let package of bookingResponseData?.packageDetails" class="slide">
                              <div class="add_vehicle_honda flex">
                                <div class="add_honda_left">
                                  <div class="file">
                                    <figure>
                                      <img [src]="baseUrl + package?.itemImage" />
                                    </figure>
                                    <h5>
                                      <span>{{ package?.packageName }}</span
                                      >{{ package?.itemDescription
                                      }}<span class="capitaAL_SVU">{{ "Quantity" | translate }}: {{ package?.itemQuantity }}</span>
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ngx-slick-carousel>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-2" id="headingThree">
                    <div class="fs collapsed" type="button" data-toggle="collapse" data-target="#collapseThree3">
                      <div class="fd">
                        <h5 class="mb-0">
                          {{ "Choices" | translate }}
                        </h5>
                      </div>
                      <div class="arrow"><i class="fa fa-angle-down"></i></div>
                    </div>
                  </div>
                  <div id="collapseThree3" class="collapse" aria-labelledby="headingThree" data-parent="#faqExample">
                    <div class="card-body">
                      <ul>
                        <li>
                          <h6>{{ "Package Sizes" | translate }}</h6>
                          <div class="fl">
                            <div class="flleft">
                              <figure><img [src]="baseUrl + selectedPackageSIze?.blackImage" /></figure>
                            </div>
                            <div class="flright">
                              <h5>
                                {{ selectedPackageSIze?.packageSizeName }}
                                <span>{{ selectedPackageSIze?.size }}</span>
                              </h5>
                            </div>
                          </div>
                        </li>
                        <li>
                          <h6>{{ "Vehicle" | translate }}</h6>
                          <div class="fl">
                            <div class="flleft">
                              <figure><img [src]="baseUrl + selectedVehicleData?.vehicleImage" /></figure>
                            </div>
                            <div class="flright">
                              <h5>
                                {{ selectedVehicleData?.vehicleCategory }}
                                <span>${{ bookingResponseData?.booKingAmount }}</span>
                              </h5>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="estimated_cost">
                <p>{{ bookingResponseData?.riderFare.toFixed(2) | currency: "USD" }}</p>
                <!-- <label>Order is completed</label> -->
              </div>
              <div class="package_btn">
                <div class="commen_btn_outer preview_btn order_items_btn">
                  <button class="btn btn-primary rwdr" data-toggle="modal" data-target="#myModal23_user">
                    {{ "Review Driver" | translate }}
                  </button>
                  <!-- <button class="btn btn-primary">View Bill</button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-----End package deatail page-->

<!-- Modal -->
<!-- <div class="modal fade" id="exampleModal " tabindex="-1 " role="dialog " aria-labelledby="exampleModalLabel " aria-hidden="true ">
                    <div class="modal-dialog" role="document ">
                        <div class="modal-content">
                            <div class=" ">
                                <button type="button " class="close" data-dismiss="modal " aria-label="Close ">
                <span aria-hidden="true ">&times;</span>
              </button>
                            </div>
                            <div class="modal-body">
                                <div class="add_vehicle">
                                    <h4>Add vehicle</h4>
                                    <div class="vehicle_form">
                                        <div class="form-group">
                                            <mat-form-field [floatLabel]="'never'">
                                                <input matInput type="text " placeholder="Vehicle make " name="name " />
                                            </mat-form-field>
                                        </div>
                                        <div class="form-group">
                                            <mat-form-field [floatLabel]="'never'">
                                                <input matInput type="text " placeholder="Vehicle Model " name="name " />
                                            </mat-form-field>
                                        </div>
                                        <div class="form-group">
                                            <mat-form-field>
                                                <mat-label>Vehicle type</mat-label>
                                                <mat-select [(value)]="selected">
                                                    <mat-option value="option1 ">SUV</mat-option>
                                                    <mat-option value="option2 ">SUV</mat-option>
                                                    <mat-option value="option3 ">SUV</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="form-group upload_form_group">
                                            <figure class="ipload_img_vehicle">
                                                <svg xmlns="http://www.w3.org/2000/svg " width="114.195 " height="97.656 " viewBox="0 0 114.195 97.656 ">
                        <g id="surface1 " transform="translate(0) ">
                          <path
                            id="Path_24238 "
                            data-name="Path 24238 "
                            d="M110.78,160.375H3.416a3.39,3.39,0,0,0-2.583,1.164,3.25,3.25,0,0,0-.8,2.7l7.069,50.644a8.6,8.6,0,0,0,8.431,7.381H98.8a8.581,8.581,0,0,0,8.432-7.409l6.926-50.645a3.343,3.343,0,0,0-.795-2.7A3.461,3.461,0,0,0,110.78,160.375ZM75.267,191.744a2.266,2.266,0,0,1-1.533.6,2.418,2.418,0,0,1-1.675-.71l-12.69-13.655v32.448a2.271,2.271,0,0,1-4.543,0V177.976L42.137,191.6a2.269,2.269,0,1,1-3.321-3.094l16.635-17.857a2.272,2.272,0,0,1,1.675-.709,2.318,2.318,0,0,1,1.675.709l16.635,17.857A2.372,2.372,0,0,1,75.267,191.744Zm0,0 "
                            transform="translate(0 -124.605) "
                            fill="#ccc "
                          />
                          <path
                            id="Path_24239 "
                            data-name="Path 24239 "
                            d="M39.057,31.228h96.152V24.045a8.379,8.379,0,0,0-8.432-8.432H91.263A13.123,13.123,0,0,1,80.078,9.2L77.126,4.173A8.569,8.569,0,0,0,69.83,0H46.58a8.468,8.468,0,0,0-6.161,2.7,8.563,8.563,0,0,0-2.357,6.33Zm0,0 "
                            transform="translate(-29.566) "
                            fill="#ccc "
                          />
                        </g>
                      </svg>
                                            </figure>
                                            <h5>Upload insurance document</h5>
                                            <input type="file " id="file-input " class="upload_file" />
                                        </div>
                                    </div>
                                    <div class="driverap_btn">
                                        <button>Add Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
<!-- request_modale -->

<div
  class="modal fade"
  id="exampleModall"
  [ngStyle]="{ display: display, opacity: 1 }"
  tabindex="-1 "
  role="dialog "
  aria-labelledby="exampleModalLabel "
  aria-hidden="true "
>
  <div class="modal-dialog" role="document ">
    <div class="modal-content" style="width: 501px">
      <div class="modal-header">
        <button type="button " class="close" (click)="closeDateTimeModal()" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true ">&times;</span>
        </button>
      </div>
      <div class="modal-body second">
        <div class="add_vehicle">
          <h4>{{ "Pick a day and time to book a vehicle" | translate }}</h4>
          <div class="vehicle_form">
            <form [formGroup]="requestForLaterForm">
              <div class="form-group">
                <input [owlDateTimeTrigger]="dt" formControlName="dateTime" placeholder="Select Date and Time" [min]="mindate" [owlDateTime]="dt" />
                <owl-date-time #dt></owl-date-time>
                <mat-error *ngIf="isDateTimeSubmit && requestForLaterForm.controls['dateTime'].hasError('required')">
                  {{ "Please select Date and Time" | translate }}</mat-error
                >
              </div>
            </form>
          </div>
          <div class="driverap_btn">
            <button style="cursor: pointer" (click)="getVehicleForLater()">{{ "Request For Later" | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-----add payment card modal-->
<!-- Modal -->
<div class="modal fade" id="add_payment" [ngStyle]="{ display: addCardModal, opacity: 1 }">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="AddCardModal('none')">
          <span aria-hidden="true "><i class="fa fa-times" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h3 class="add_card_title">{{ "Add Payment Card" | translate }}</h3>
        <div class="card_card_form">
          <div class="nsflex">
            <div class="add_card_input_itms mr-2">
              <label>{{ "First name" | translate }}</label>
              <input
                class="form-control"
                type="text"
                maxlength="50"
                (paste)="(false)"
                (change)="onChangeFirst($event.target.value)"
                (keypress)="onKeyPress($event)"
                [formControl]="cardOwnerFirstName"
                placeholder="{{ 'First name' | translate }}"
              />
              <mat-error *ngIf="cardSubmitted && cardOwnerFirstName.hasError('required')"> {{ "First name is required" | translate }}.</mat-error>
              <mat-error *ngIf="cardSubmitted && !cardOwnerFirstName.hasError('required') && validateFirstArr.includes(true)">
                {{ "Please enter valid first name" | translate }}.</mat-error
              >
            </div>
            <div class="add_card_input_itms mr-2">
              <label>{{ "Last name" | translate }}</label>
              <input
                class="form-control"
                maxlength="50"
                (paste)="(false)"
                placeholder="{{ 'Last name' | translate }}"
                (change)="onChangeSecond($event.target.value)"
                (keypress)="onKeyPress($event)"
                type="text"
                [formControl]="cardOwnerLastName"
              />
              <mat-error *ngIf="cardSubmitted && cardOwnerLastName.hasError('required')"> {{ "Last name is required" | translate }}.</mat-error>
              <mat-error *ngIf="cardSubmitted && cardOwnerLastName.value !== '' && validateFirstArr.includes(true)">
                {{ "Please enter valid last name" | translate }}.</mat-error
              >
            </div>
          </div>
          <div class="add_card_input_itms" style="width: 100%">
            <form [formGroup]="cardNumberGroup">
              <label>{{ "Card Number" | translate }}</label>
              <input
                class="form-control"
                [formControl]="getCardNumberControl()"
                (copy)="(false)"
                (paste)="(false)"
                [textMask]="{ mask: cardMaskFunction, guide: false, showMask: true }"
                placeholder=""
              />

              <div *ngIf="cardSubmitted">
                <p style="color: red" *ngIf="cardNumberGroup.status === 'INVALID' && !cardNumberGroup?.controls['cardNumber'].hasError('required')">
                  {{ "Card number is invalid" | translate }}.
                </p>
                <!-- <p style="color: green;" *ngIf="cardNumberGroup.status === 'VALID'">
                                    Card number is valid.</p> -->
                <p style="color: red" *ngIf="cardNumberGroup?.controls['cardNumber'].hasError('required')">
                  {{ "Card number is required" | translate }}.
                </p>
              </div>
            </form>
          </div>
          <div class="nsflex">
            <div class="add_card_input_itms months mr-2">
              <label>{{ "Month" | translate }}</label>
              <select class="form-control" [formControl]="expiryMonth">
                <option [value]="null" selected disabled>{{ "Month" | translate }}</option>
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
              <mat-error *ngIf="cardSubmitted && expiryMonth.hasError('required')"> {{ "Expiry month is required" | translate }}.</mat-error>
            </div>
            <div class="add_card_input_itms mr-2">
              <label>{{ "Year" | translate }}</label>
              <select class="form-control" [formControl]="expiryYear">
                <option [value]="null" selected disabled>Year</option>
                <option [value]="y" *ngFor="let y of expiryYears; let i = index">{{ y }}</option>
              </select>
              <mat-error *ngIf="cardSubmitted && expiryYear.hasError('required')"> {{ "Expiry year is required" | translate }}.</mat-error>
            </div>
            <div class="add_card_input_itms">
              <label>{{ "CCV/CVV" | translate }}</label>
              <input
                [formControl]="cvv"
                (copy)="(false)"
                (paste)="(false)"
                (keypress)="keypresshandler($event)"
                placeholder="{{ 'CCV/CVV' | translate }}"
                class="form-control"
                type="password"
                onKeyPress="if(this.value.length==4) return false;"
                placeholder=""
              />
              <mat-error *ngIf="cardSubmitted && cvv.hasError('required')">{{ "CCV/CVV is required" | translate }}.</mat-error>
            </div>
          </div>
        </div>
      </div>
      <button class="add_card" style="cursor: pointer" (click)="addDebitCard()">{{ "Save" | translate }}</button>
    </div>
  </div>
</div>

<!----start promo card popup-->

<!-- Modal -->
<div class="modal fade promo_page" id="add_promo" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span><i class="fa fa-times" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h3 class="add_card_title">{{ "Add Promo code" | translate }}</h3>
        <div class="enter_promo_code">
          <input class="from-control" type="text " placeholder="{{ 'Enter Your Promotion Code' | translate }} " />
          <div class="commen_btn_outer">
            <button class="btn btn-primary">{{ "Apply" | translate }}</button>
          </div>
        </div>
        <!----start promo code offer-->
        <div class="promo_offer_main">
          <div class="promo_offer_bx">
            <figure>
              <img src="assets/uimages/promo_code_icon.png " alt=" " />
            </figure>
            <h4>
              10% of base rate on your next rental
              <span>Valid to 10/10/2020</span>
            </h4>

            <mat-checkbox></mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!----end promo card popup-->
<!-- Modal -->
<div
  class="modal fade promo_page"
  id="add_moniter"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel2"
  [ngStyle]="{ display: monitorModal, opacity: 1 }"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button " class="close" data-dismiss="modal" aria-label="Close" (click)="monitorModal = 'none'">
          <span><i class="fa fa-times" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="monitorForm">
          <h3 class="add_card_title">{{ "Monitor your delivery" | translate }}</h3>
          <p class="moniter_para">
            {{ "Please, add the name(s) and mobile number(s) or click on other to share your current locationwith friend or relatives" | translate }}.
          </p>

          <input
            style="margin-bottom: 18px"
            type="text"
            class="from-control moniter_input"
            formControlName="name"
            placeholder="{{ 'Name' | translate }}"
          />
          <mat-error *ngIf="monitorSubmit && monitorForm.controls['name'].hasError('required')"> {{ "Name is required" | translate }}. </mat-error>
          <ngx-intl-tel-input
            [inputId]="'adsasd'"
            [cssClass]="'custom'"
            [preferredCountries]="preferredCountries"
            [enableAutoCountrySelect]="false"
            [enablePlaceholder]="true"
            [searchCountryFlag]="true"
            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
            [selectFirstCountry]="false"
            [selectedCountryISO]="CountryISO.Canada"
            [maxLength]="15"
            [tooltipField]="TooltipLabel.Name"
            [phoneValidation]="true"
            [separateDialCode]="separateDialCode"
            name="phone"
            formControlName="phoneNo"
          >
          </ngx-intl-tel-input>
          <mat-error *ngIf="monitorSubmit && monitorForm.controls['phoneNo'].hasError('required')">
            {{ "Phone number is required" | translate }}.</mat-error
          >
          <mat-error *ngIf="monitorSubmit && monitorForm.controls['phoneNo'].hasError('validatePhoneNumber')">
            {{ "Enter valid number" | translate }}.</mat-error
          >
          <div class="share_btn" style="cursor: pointer">
            <button (click)="monitorRide()">{{ "Share" | translate }}</button>
          </div>
          <p class="share-other">{{ "Or you can share it with other" | translate }}.</p>
          <div class="share-btn">
            <button
              style="cursor: pointer"
              mat-icon-button
              shareButton="facebook"
              #fbBtn="shareButton"
              [style.color]="fbBtn?.color"
              [url]="'https://demo.appdukaan.com/u2go/web/tracking/' + bookingId"
            >
              <i class="fa fa-facebook-square" aria-hidden="true" style="font-size: 40px"></i></button
            ><button
              style="cursor: pointer"
              mat-icon-button
              shareButton="twitter"
              #twitterBtn="shareButton"
              [style.color]="twitterBtn?.color"
              [url]="'https://demo.appdukaan.com/u2go/web/tracking/' + bookingId"
            >
              <i class="fa fa-twitter" aria-hidden="true" style="font-size: 40px"></i></button
            ><button
              style="cursor: pointer"
              mat-icon-button
              shareButton="linkedin"
              #linkedinBtn="shareButton"
              [style.color]="linkedinBtn?.color"
              [url]="'https://demo.appdukaan.com/u2go/web/tracking/' + bookingId"
            >
              <i class="fa fa-linkedin" aria-hidden="true" style="font-size: 40px"></i></button
            ><button
              style="cursor: pointer"
              mat-icon-button
              shareButton="whatsapp"
              #whatsappBtn="shareButton"
              [style.color]="whatsappBtn?.color"
              [url]="'https://demo.appdukaan.com/u2go/web/tracking/' + bookingId"
            >
              <i class="fa fa-whatsapp" aria-hidden="true" style="font-size: 40px"></i></button
            ><button
              style="cursor: pointer"
              mat-icon-button
              shareButton="copy"
              (click)="showClipBoardMessage()"
              #copyBtn="shareButton"
              [style.color]="copyBtn?.color"
              [url]="'https://demo.appdukaan.com/u2go/web/tracking/' + bookingId"
            >
              <i class="fa fa-copy" aria-hidden="true" style="font-size: 40px"></i></button
            ><button
              style="cursor: pointer"
              mat-icon-button
              shareButton="print"
              #printBtn="shareButton"
              [style.color]="printBtn?.color"
              [url]="'https://demo.appdukaan.com/u2go/web/tracking/' + bookingId"
            >
              <i class="fa fa-print" aria-hidden="true" style="font-size: 40px"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!----end promo card popup-->

<div
  class="modal right fade profile_side_popup driver_info_pop"
  id="myModal23_user"
  [ngStyle]="{ display: myModal23_user }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel23"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" id="myModalLabel2"></h4>
      </div>

      <div class="modal-body">
        <div class="request_ride_inner sec">
          <div class="title_bar">
            <h2>{{ "Rate Your Driver" }}</h2>
          </div>
          <div class="driver_info">
            <div class="driver_info_img">
              <figure>
                <img src="assets/uimages/driver_img.png" alt="" />
              </figure>
            </div>
            <div class="driver_info_text">
              <h3>Evan Guzman</h3>
              <div class="driver_rate_pop">
                <p>{{ "Rated" | translate }}<span>4.2/5</span></p>
                <p>{{ "Review" | translate }}<span>142</span></p>
              </div>
            </div>
          </div>

          <div class="rate_screen">
            <h3>{{ "Rate" | translate }}</h3>
            <figure>
              <img src="assets/uimages/five_star.png" alt="" />
            </figure>

            <div class="driver_performance">
              <p>Excellent</p>
              <p>Very Good</p>
              <p>Good</p>
              <p>Package delivered as picked-up?</p>
              <p>Picked-up and delivered on time</p>
            </div>
          </div>
          <div class="add_driver_tip">
            <h3>{{ "Add A tip" | translate }}</h3>
            <div class="add_drive_tipmain">
              <p>$2</p>
              <p>$5</p>
              <p>$8</p>
              <p>$10</p>
            </div>
            <p>Enter Custom Amount</p>
          </div>
          <div class="driver_feedback_links">
            <h3>Feedback Links</h3>
            <div class="driver_feedback_main">
              <h4>Driver</h4>
              <div class="driver_feedback_item">
                <p>Was the driver on time?</p>
                <mat-radio-group aria-label="Select an option">
                  <mat-radio-button value="1">Yes</mat-radio-button>
                  <mat-radio-button value="2">No</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="driver_feedback_item">
                <p>Did the driver make your ride comfortable?</p>
                <mat-radio-group aria-label="Select an option">
                  <mat-radio-button value="1">Yes</mat-radio-button>
                  <mat-radio-button value="2">No</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="driver_feedback_item">
                <p>Was the ride safe?</p>
                <mat-radio-group aria-label="Select an option">
                  <mat-radio-button value="1">Yes</mat-radio-button>
                  <mat-radio-button value="2">No</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="driver_feedback_item">
                <p>What can we improve for next time?</p>
                <textarea placeholder="Comment"> </textarea>
              </div>
            </div>
          </div>
          <div class="rate_sub_btn">
            <div class="commen_btn_outer review_driver">
              <button class="btn btn-primary" (click)="continue('0')">Submit</button>
            </div>
            <div class="commen_btn_outer review_driver">
              <button class="btn btn-primary btn_sec" (click)="continue('0')">Skip</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="addpayment" class="modal fade" role="dialog" [ngStyle]="{ display: addWalletMoneyModal, opacity: 1 }">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button " class="close" (click)="addWalletModal('none')">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
      <div class="modal-body">
        <h4>{{ "Wallet Add Money" | translate }}</h4>
        <div class="seachbar-payment">
          <mat-form-field [floatLabel]="'never'">
            <input
              class="form-control"
              (keypress)="keypresshandler($event)"
              min="1"
              [formControl]="addWalletAmount"
              matInput
              type="text"
              placeholder="{{ 'Amount' | translate }}"
            />
          </mat-form-field>
          <mat-error *ngIf="walletMoneySubmitted && addWalletAmount.hasError('required')"> {{ "Wallet amount is required" | translate }}.</mat-error>
        </div>
        <ul class="payment-lt">
          <li>
            <div class="list_inner">
              <!-- <mat-checkbox [checked]="isWalletSelected" (change)="onWalletSelected($event.checked)">
                                Pay
                                via APPTUNIX Wallet</mat-checkbox>
                            {{allSavedCardData?.walletAmount}} -->
              <mat-radio-group aria-label="Select an option">
                <mat-radio-button
                  (change)="onWalletCardChange(card)"
                  [checked]="card?.isSelected"
                  [value]="i + 1"
                  *ngFor="let card of allSavedCard; let i = index"
                >
                  <div class="card_detailcs">
                    <div>
                      <h3>
                        {{ card?.cardNumber }}<span>{{ card?.brand }} - {{ card?.expiryDate.slice(0, 2) }}/{{ card?.expiryDate.slice(2) }}</span>
                      </h3>
                    </div>
                    <div class="dlt-radio">
                      <h3 *ngIf="card?.isDefault">
                        <span class="text-success">{{ "PRIMARY CARD" | translate }}</span>
                      </h3>
                    </div>
                  </div>
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </li>
        </ul>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="addWalletMoney()">{{ "Proceed" | translate }}</button>
      </div>
    </div>
  </div>
</div>

<!-- //loyality modal -->
<div class="modal fade promo_page" id="add_promo" [ngStyle]="{ display: loyalityModal, opacity: 1 }">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="loyalityModals('none')">
          <span aria-hidden="true ">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h3 class="add_card_title">{{ "Loyality Money" | translate }}</h3>
        <div class="loyality-main">
          <ul *ngIf="loyalityData?.length > 0">
            <li *ngFor="let coupon of loyalityData">
              <div class="loyal-minnre">
                <div class="loyal-bg">
                  <img class="loyal-bg" [src]="baseUrl + coupon?.couponIcon" />
                </div>
                <div class="loyality-inner-para">
                  <h5 [title]="coupon?.companyName">{{ coupon?.companyName }}</h5>
                  <!-- <p>Expiry: {{coupon?.expiryDate | date: 'M/d/yy'}}</p> -->
                  <p>{{ coupon?.coupon }}</p>
                  <div
                    title="{{
                      coupon?.couponType === 1 || coupon?.couponType === 3
                        ? '$' + ((coupon?.couponValue).toFixed(2) | number: '1.0':'en-US')
                        : ((coupon?.couponValue).toFixed(2) | number: '1.0':'en-US')
                    }} {{ coupon?.couponType === 2 ? '%' : 'Points' }}"
                    class="sp"
                  >
                    <p>
                      {{
                        coupon?.couponType === 1 || coupon?.couponType === 3
                          ? ((coupon?.couponValue).toFixed(2) | currency: "USD")
                          : ((coupon?.couponValue).toFixed(2) | currency: "USD")
                      }}
                      {{ coupon?.couponType === 2 ? "%" : "Points" }}
                    </p>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary" (click)="redeemCoupon(coupon)">{{ "Redeem" | translate }}</button>
            </li>
          </ul>
          <h3 style="text-align: center" *ngIf="loyalityData?.length === 0">{{ "You don't have any Loyality Money now" | translate }}.</h3>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="addpayment" class="modal fade" role="dialog" [ngStyle]="{ display: cancelDialog, opacity: 1 }">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content" style="width: 361px">
      <!-- <div class="modal-header">
        <button type="button " class="close" (click)="cancelDialog = 'none'">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div> -->
      <div class="modal-body" style="width: 361px">
        <h2 style="text-align: center">{{ cancelationMessage }}</h2>
        <p style="padding-top: 5px">
          <span>{{ "Driver canceled delivery at address" | translate }}:- </span>{{ canceledAddress }}
        </p>
        <p>
          <span>{{ "Reason" | translate }}:- </span>{{ cancelReasons }}
        </p>
      </div>
      <div class="modal-footer" style="border-top: unset">
        <button type="button" class="btn btn-primary" (click)="cancelDialog = 'none'">{{ "Ok" | translate }}</button>
      </div>
    </div>
  </div>
</div>

<div class="login_wrap top">
  <app-f-unavbar id="navbar"></app-f-unavbar>
  <!-- <div class="signupinner">
        <div class="conta_iner">
            <div class="flex">
                <div class="haeder_left">
                    <figure class="logo_cm" (click)="goTouhomepage()">
                        <img src="assets/uimages/logo.png" />
                    </figure>
                </div>
                <div class="back_home">
                    <button class="driverap_btn" (click)="goTouhomepage()">
                        Back To Home
                    </button>
                </div>
            </div>
        </div>
    </div> -->
  <!-- <div class="back_home">
        <button class="driverap_btn" (click)="goTouhomepage()">Back To Home</button>
    </div> -->
  <div class="login">
    <div class="conta_iner">
      <div class="login_main">
        <div class="login_form center">
          <figure class="login_logo">
            <img src="assets/uimages/logo.png" />
          </figure>
          <h4>
            {{ "Let's Get Started" | translate }} <span>{{ "Create an account" | translate }}</span>
          </h4>

          <form class="form_control_form" [formGroup]="signupForm" *ngIf="!commonService?.isThisDeliveryPartner">
            <div class="form-group">
              <mat-form-field [floatLabel]="'never'">
                <label>{{ "First name" | translate }}</label>
                <input
                  class="form-control"
                  maxlength="50"
                  (change)="onChangeFirst($event.target.value)"
                  (keypress)="onKeyPress($event)"
                  formControlName="firstName"
                  matInput
                  type="text"
                  placeholder="{{ 'First name' | translate }}"
                />
              </mat-form-field>
              <mat-error *ngIf="submitted && signupForm.controls['firstName'].hasError('required')"
                >{{ "First name is required" | translate }}.</mat-error
              >
              <mat-error *ngIf="submitted && validateFirstArr.includes(true)"> {{ "Please enter valid first name" | translate }}.</mat-error>
            </div>
            <div class="form-group">
              <mat-form-field [floatLabel]="'never'">
                <label>{{ "Last name" | translate }}</label>
                <input
                  class="form-control"
                  maxlength="50"
                  (change)="onChangeSecond($event.target.value)"
                  (keypress)="onKeyPress($event)"
                  formControlName="lastName"
                  matInput
                  type="text"
                  placeholder="{{ 'Last name' | translate }}"
                />
              </mat-form-field>
              <mat-error *ngIf="submitted && signupForm.controls['lastName'].hasError('required')">
                {{ "Last name is required" | translate }}.</mat-error
              >
              <mat-error *ngIf="submitted && validateSecondArr.includes(true)"> {{ "Please enter valid last name" | translate }}.</mat-error>
            </div>
            <div class="form-group">
              <mat-form-field [floatLabel]="'never'">
                <label class="algn-lft">{{ "Email address" | translate }}</label>
                <input class="form-control" formControlName="email" matInput type="email" placeholder="{{ 'Email address' | translate }}" />
              </mat-form-field>
              <mat-error *ngIf="submitted && signupForm.controls['email'].hasError('required')">{{ "Email is required" | translate }}.</mat-error>
              <mat-error *ngIf="submitted && signupForm.controls['email'].hasError('pattern')">
                {{ "Sorry, only letters (a-z), number (0-9), and periods (.) are allowed" | translate }}.</mat-error
              >
            </div>
            <div class="form-group">
              <label class="algn-lft">{{ "Phone number" | translate }}</label>
              <ngx-intl-tel-input
                [inputId]="'adsasd'"
                matInput
                [cssClass]="'custom'"
                [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="true"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="CountryISO.Canada"
                [maxLength]="15"
                [tooltipField]="TooltipLabel.Name"
                [phoneValidation]="true"
                [separateDialCode]="separateDialCode"
                name="phone"
                formControlName="phoneNo"
              >
              </ngx-intl-tel-input>
              <mat-error *ngIf="submitted && signupForm.controls['phoneNo'].hasError('required')"
                >{{ "Phone number is required" | translate }}.</mat-error
              >
              <mat-error *ngIf="submitted && signupForm.controls['phoneNo'].hasError('validatePhoneNumber')">
                {{ "Enter valid number" | translate }}.</mat-error
              >
            </div>

            <div class="form-group">
              <mat-form-field [floatLabel]="'never'">
                <label class="algn-lft">{{ "Password" | translate }}</label>
                <input class="form-control" formControlName="password" matInput type="password" placeholder="{{ 'Password' | translate }}" />
              </mat-form-field>
              <mat-error *ngIf="submitted && signupForm.controls['password'].hasError('required')">
                {{ "Password is required" | translate }}.</mat-error
              >
              <mat-error *ngIf="submitted && signupForm.controls['password'].hasError('minlength')">
                {{ "Password should be 6 character long" | translate }}.</mat-error
              >
            </div>
            <div class="form-group">
              <mat-form-field [floatLabel]="'never'">
                <label class="algn-lft">{{ "Confirm password" | translate }}</label>
                <input
                  class="form-control"
                  formControlName="confirmPassword"
                  matInput
                  type="password"
                  placeholder="{{ 'Confirm password' | translate }}"
                />
              </mat-form-field>
              <mat-error *ngIf="submitted && signupForm.controls['confirmPassword'].hasError('required')">
                {{ "Confirm password is required" | translate }}.</mat-error
              >
              <mat-error *ngIf="submitted && signupForm.controls['confirmPassword'].hasError('mustMatch')">
                {{ "Password and confirm password must match" | translate }}.</mat-error
              >
            </div>

            <div class="flex submit_btn">
              <button class="btn btn-primary" (click)="goToVerificationPopup()">
                {{ "Sign Up" | translate }}
              </button>
            </div>

            <p class="dont_account">
              {{ "Already have an account?" | translate }}
              <a (click)="goToriderlogin()">{{ "login" | translate }}</a>
            </p>
          </form>

          <!-- //delivery partner form -->
          <form class="form_control_form" [formGroup]="deliveryPartnerForm" *ngIf="commonService?.isThisDeliveryPartner">
            <div class="profile_uolad">
              <div class="putop">
                <figure>
                  <img *ngIf="!fileDataUrl" src="assets/images/user.png" />
                  <img *ngIf="fileDataUrl" [src]="fileDataUrl" />
                </figure>
              </div>
              <div class="pubottom">
                <div class="edit-bg" style="cursor: pointer" (click)="fileUpload.click()">
                  <i style="color: white; cursor: pointer" class="fa fa-pencil"></i>
                </div>
                <input type="file" #fileUpload (change)="onFileChange($event)" id="myfile" name="myfile" />
              </div>
            </div>
            <div class="form-group">
              <mat-form-field [floatLabel]="'never'">
                <label>{{ "First name" | translate }}</label>
                <input
                  class="form-control"
                  maxlength="50"
                  (change)="onChangeFirst($event.target.value)"
                  (keypress)="onKeyPress($event)"
                  formControlName="firstName"
                  matInput
                  type="text"
                  placeholder="{{ 'First name' | translate }}"
                />
              </mat-form-field>
              <mat-error *ngIf="submitted && deliveryPartnerForm.controls['firstName'].hasError('required')"
                >{{ "First name is required" | translate }}.</mat-error
              >
              <mat-error *ngIf="submitted && validateFirstArr.includes(true)"> {{ "Please enter valid first name" | translate }}.</mat-error>
            </div>
            <div class="form-group">
              <mat-form-field [floatLabel]="'never'">
                <label>{{ "Last name" | translate }}</label>
                <input
                  class="form-control"
                  maxlength="50"
                  (change)="onChangeSecond($event.target.value)"
                  (keypress)="onKeyPress($event)"
                  formControlName="lastName"
                  matInput
                  type="text"
                  placeholder="{{ 'Last name' | translate }}"
                />
              </mat-form-field>
              <mat-error *ngIf="submitted && deliveryPartnerForm.controls['lastName'].hasError('required')"
                >{{ "Last name is required" | translate }}.</mat-error
              >
              <mat-error *ngIf="submitted && validateSecondArr.includes(true)"> {{ "Please enter valid last name" | translate }}.</mat-error>
            </div>
            <div class="form-group">
              <mat-form-field [floatLabel]="'never'">
                <label>{{ "Email address" | translate }}</label>
                <input class="form-control" formControlName="email" matInput type="email" placeholder="{{ 'Email address' | translate }}" />
              </mat-form-field>
              <mat-error *ngIf="submitted && deliveryPartnerForm.controls['email'].hasError('required')">
                {{ "Email is required" | translate }}.</mat-error
              >
              <mat-error *ngIf="submitted && deliveryPartnerForm.controls['email'].hasError('pattern')">
                {{ "Sorry, only letters (a-z), number (0-9), and periods (.) are allowed" | translate }}.</mat-error
              >
            </div>
            <div class="form-group">
              <label class="algn-lft">{{ "Phone number" | translate }}</label>
              <!-- <mat-form-field [floatLabel]="'never'"> -->
              <ngx-intl-tel-input
                [inputId]="'adsasd'"
                matInput
                [cssClass]="'custom'"
                [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="true"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="CountryISO.Canada"
                [maxLength]="15"
                [tooltipField]="TooltipLabel.Name"
                [phoneValidation]="true"
                [separateDialCode]="separateDialCode"
                name="phone"
                formControlName="phoneNo"
              >
              </ngx-intl-tel-input>
              <!-- </mat-form-field> -->
              <mat-error *ngIf="submitted && deliveryPartnerForm.controls['phoneNo'].hasError('required')">
                {{ "Phone number is required" | translate }}.</mat-error
              >
              <mat-error *ngIf="submitted && deliveryPartnerForm.controls['phoneNo'].hasError('validatePhoneNumber')">
                {{ "Enter valid number" | translate }}.</mat-error
              >
            </div>
            <div class="form-group">
              <mat-form-field [floatLabel]="'never'">
                <label>{{ "Company name" | translate }}</label>
                <input class="form-control" formControlName="companyName" matInput type="text" placeholder="{{ 'Company name' | translate }}" />
              </mat-form-field>
              <mat-error *ngIf="submitted && deliveryPartnerForm.controls['companyName'].hasError('required')">
                {{ "Company name is required" | translate }}.</mat-error
              >
            </div>

            <div class="form-group">
              <mat-form-field [floatLabel]="'never'">
                <label>{{ "Company street no. and name" | translate }}</label>
                <input
                  class="form-control"
                  formControlName="companyStreet"
                  matInput
                  type="text"
                  placeholder="{{ 'Company street no. and name' | translate }}"
                />
              </mat-form-field>
              <mat-error *ngIf="submitted && deliveryPartnerForm.controls['companyStreet'].hasError('required')">
                {{ "Company street no. and name are required" | translate }}.</mat-error
              >
            </div>
            <div class="form-group">
              <mat-form-field [floatLabel]="'never'">
                <label>{{ "Additional details (unit #, name of business, etc)" | translate }}.</label>
                <input
                  class="form-control"
                  formControlName="localAddress"
                  matInput
                  type="text"
                  placeholder="{{ 'Additional details (unit #, name of business, etc)' | translate }}"
                />
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field [floatLabel]="'never'">
                <label>{{ "Company city, state/province and country" | translate }}.</label>
                <input
                  class="form-control"
                  matInput
                  placeholder="{{ 'Search for your Company city, state/province and country' | translate }}"
                  autocorrect="off"
                  autocapitalize="off"
                  spellcheck="off"
                  type="text"
                  ngx-google-places-autocomplete
                  #placesRef="ngx-places"
                  (keyup)="onKeyUp($event.target.value)"
                  (change)="onAddChanged($event.target.value)"
                  (onAddressChange)="handleLocationChnage($event)"
                  formControlName="address"
                />
              </mat-form-field>
              <mat-error *ngIf="submitted && deliveryPartnerForm.controls['address'].hasError('required')">
                {{ "Company city, state/province and country are required" | translate }}.</mat-error
              >
            </div>
            <div class="form-group">
              <mat-form-field [floatLabel]="'never'">
                <label>{{ "Company zip code" | translate }}</label>
                <input
                  class="form-control"
                  formControlName="companyZipCode"
                  matInput
                  type="text"
                  placeholder="{{ 'Company zip code' | translate }}"
                />
              </mat-form-field>
              <mat-error *ngIf="submitted && deliveryPartnerForm.controls['companyZipCode'].hasError('required')">
                {{ "Company zip code is required" | translate }}.</mat-error
              >
            </div>

            <!-- <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <input class="form-control" formControlName="companyCertificateNo" matInput type="text"
                                    placeholder="Company certificate no.">
                            </mat-form-field>
                            <mat-error
                                *ngIf="submitted && deliveryPartnerForm.controls['companyCertificateNo'].hasError('required')">
                                Company country is required.</mat-error>
                        </div> -->
            <div class="form-group img-txt">
              <label class="algn-lft">{{ "Company certificate" | translate }}</label>
              <div class="data-b">
                <input type="file" style="cursor: pointer" (change)="onFileUpload($event)" #companyCertificate />
                <div class="paracc">
                  <p (click)="companyCertificate.click()" *ngIf="!preview" class="preview-dta mt-0 mb-0 text-left">
                    {{ "Company certificate" | translate }}
                  </p>
                  <p (click)="companyCertificate.click()" *ngIf="preview" class="preview-dta mt-0 mb-0 text-left">{{ preview }}</p>
                </div>
              </div>
            </div>
            <div class="form-group">
              <mat-form-field [floatLabel]="'never'">
                <label>{{ "Business incorporation number" | translate }}</label>
                <input
                  class="form-control"
                  formControlName="bussinessIncorporation"
                  matInput
                  type="text"
                  placeholder="{{ 'Business incorporation number' | translate }}"
                />
              </mat-form-field>
            </div>
            <div class="form-group">
              <label class="algn-lft">{{ "Industry name" | translate }}</label>
              <mat-form-field [floatLabel]="'never'">
                <mat-label>{{ "Industry name" | translate }}</mat-label>
                <mat-select formControlName="industryName" class="form-control">
                  <mat-optgroup *ngFor="let group of industries" [label]="group.group">
                    <mat-option *ngFor="let item of group.values" [value]="item">{{ item }} </mat-option>
                  </mat-optgroup>
                </mat-select>
              </mat-form-field>
              <mat-error *ngIf="submitted && deliveryPartnerForm.controls['industryName'].hasError('required')">
                {{ "Industry name is required" | translate }}.</mat-error
              >
            </div>
            <div class="form-group">
              <label class="algn-lft">{{ "Account number" | translate }}<span class="red">*</span></label>
              <mat-form-field [floatLabel]="'never'">
                <input
                  class="form-control"
                  placeholder="{{ 'Account number' | translate }}"
                  matInput
                  type="text"
                  formControlName="accNumber"
                  name="name "
                />
                <mat-error *ngIf="deliveryPartnerForm.controls['accNumber'].hasError('required')">
                  {{ "Account number" | translate }} {{ "is required" | translate }}.
                </mat-error>
                <mat-error
                  *ngIf="
                    !deliveryPartnerForm.controls['accNumber'].hasError('required') && deliveryPartnerForm.controls['accNumber'].hasError('minlength')
                  "
                >
                  {{ "Please enter valid acc. no." | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <label class="algn-lft">{{ "Transit" | translate }}<span class="red">*</span></label>
              <mat-form-field [floatLabel]="'never'">
                <mat-label>{{ "Transit" | translate }}</mat-label>
                <input class="form-control" matInput type="text" formControlName="transit" name="name " />
                <mat-error *ngIf="deliveryPartnerForm.controls['transit'].hasError('required')">
                  {{ "Transit" | translate }} {{ "is required" | translate }}.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <label class="algn-lft">{{ "Bank name" | translate }}<span class="red">*</span></label>
              <mat-form-field [floatLabel]="'never'">
                <mat-label>{{ "Bank name" | translate }}</mat-label>
                <!-- <input class="form-control" matInput type="text" formControlName="bankName"
                                                name="name " /> -->
                <mat-select class="form-control" formControlName="bankName">
                  <mat-option *ngFor="let bank of bankNameArr" (click)="changeBank(bank)" [value]="bank?.name">
                    {{ bank?.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="deliveryPartnerForm.controls['bankName'].hasError('required')">
                  {{ "Bank name" | translate }} {{ "is required" | translate }}.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <label class="algn-lft">{{ "Institution Number" | translate }}<span class="red">*</span></label>
              <mat-form-field [floatLabel]="'never'">
                <mat-label>{{ "Institution Number" | translate }}</mat-label>
                <input class="form-control" matInput type="text" formControlName="institutionNumber" name="name " readonly />
                <mat-error *ngIf="deliveryPartnerForm.controls['institutionNumber'].hasError('required')">
                  {{ "Institution Number" | translate }} {{ "is required" | translate }}.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <label class="algn-lft">{{ "Branch address" | translate }}<span class="red">*</span></label>
              <mat-form-field [floatLabel]="'never'">
                <mat-label>{{ "Branch address" | translate }}</mat-label>
                <input class="form-control" matInput type="text" formControlName="branchAdd" name="name " />
                <mat-error *ngIf="deliveryPartnerForm.controls['branchAdd'].hasError('required')">
                  {{ "Branch address" | translate }} {{ "is required" | translate }}.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field [floatLabel]="'never'">
                <label>{{ "Password" | translate }}</label>
                <input class="form-control" formControlName="password" matInput type="password" placeholder="{{ 'Password' | translate }}" />
              </mat-form-field>
              <mat-error *ngIf="submitted && deliveryPartnerForm.controls['password'].hasError('required')">
                {{ "Password is required" | translate }}.</mat-error
              >
              <mat-error *ngIf="submitted && deliveryPartnerForm.controls['password'].hasError('minlength')">
                {{ "Password should be 6 character long" | translate }}.</mat-error
              >
            </div>
            <div class="form-group">
              <mat-form-field [floatLabel]="'never'">
                <label>{{ "Confirm password" | translate }}</label>
                <input
                  class="form-control"
                  formControlName="confirmPassword"
                  matInput
                  type="password"
                  placeholder="{{ 'Confirm password' | translate }}"
                />
              </mat-form-field>
              <mat-error *ngIf="submitted && deliveryPartnerForm.controls['confirmPassword'].hasError('required')">
                {{ "Confirm password is required" | translate }}.</mat-error
              >
              <mat-error *ngIf="submitted && deliveryPartnerForm.controls['confirmPassword'].hasError('mustMatch')">
                {{ "Password and confirm password must match" | translate }}.</mat-error
              >
            </div>
            <div class="agr agr--rider-detail">
              <mat-checkbox formControlName="agree"
                >{{ "I agree to the APPTUNIX" | translate
                }}<a
                  ><sup style="margin-right: 4px">{{ "TM" | translate }}</sup></a
                >
              </mat-checkbox>

              <a (click)="goToCmsPages('/cms/privacy-policy')" class="link-new cmsLink"> {{ "Privacy Policy" | translate }}</a> and
              <a (click)="goToCmsPages('/cms/service-delivery-contracts')" class="link-new cmsLink">
                {{ "Service Delivery Contract" | translate }}.</a
              >
            </div>
            <mat-error *ngIf="submitted && deliveryPartnerForm.controls['agree'].invalid"> {{ "Checkbox is required" | translate }}.</mat-error>

            <div class="flex submit_btn">
              <button class="btn btn-primary" (click)="goToVerificationPopup()">
                {{ "Sign up" | translate }}
              </button>
            </div>

            <p class="dont_account">
              {{ "Already have an account?" | translate }}
              <a (click)="goToriderlogin()">{{ "login" | translate }}</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="login_images"></div> -->
</div>

<div id="verification" class="modal" tabindex="-1" role="dialog" [ngStyle]="{ display: display }">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <form [formGroup]="verificationTypeForm">
        <div class="modal-body">
          <h4>{{ "Let's verify your information" | translate }}</h4>
          <p>{{ "Security and safety are very important for us" | translate }}.</p>
          <div class="rb">
            <mat-radio-group aria-label="Select an option" formControlName="verifyBy">
              <mat-radio-button value="email">{{ email }}</mat-radio-button>
              <mat-radio-button value="phone" [checked]="true">{{ phoneNo }}</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="flex signup_btn">
            <button class="btn btn-primary" (click)="goToriderotppage()">
              {{ "Continue" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!------start homapge page-->
<div class="driver_page">
  <app-unavbar id="navbar"></app-unavbar>
  <div class="header_padding_class">
    <!-----start our Riders section-->
    <section class="homepage_ourdriver driver_alldrives">
      <div class="conta_iner">
        <div class="homepage_ourdriver_main">
          <div class="homepage_ourdriver_img">
            <figure>
              <img src="assets/uimages/driver_page_firstsec.png" alt="" />
            </figure>
          </div>
          <div class="homepage_ourdriver_content">
            <div class="main_headings">
              <h2>{{ "Our Drivers Are True Partners In The Business" | translate }}</h2>
              <h3>{{ "APPTUNIX™ offers" | translate }}:</h3>
              <ul class="ourdriver_list">
                <li>
                  <span>{{ "High competitive compensation" | translate }}</span>
                </li>
                <li>
                  <span>{{ "Company ownership possibilities" | translate }}</span>
                </li>
                <li>
                  <span>{{ "Multiple sources of income (rides, deliveries, tips, bonuses, etc)" | translate }}</span>
                </li>
                <li>
                  <span>{{ "Coupons, discounts and other benefits" | translate }}</span>
                </li>
                <li>
                  <span>{{ "Loans to buy new cars or fix existing ones" | translate }}</span>
                </li>
              </ul>
              <h3 class="join_us">
                {{ "Join us, make money and explore all the opportunities APPTUNIX™ has to offer!" | translate }}
              </h3>
              <div class="two_btn">
                <button (click)="goToPreRegister()">{{ "Go to Pre-Registration" | translate }}</button>
              </div>
              <div class="two_btn buttn">
                <button class="first" (click)="commonService.preLaunchAlert(constant?.MSGS?.PRELAUNCH_DRIVER)">{{ "Sign up" | translate }}</button>
                <button class="onlyborder_fillbtn scnd" (click)="commonService.preLaunchAlert(constant?.MSGS?.PRELAUNCH_DRIVER)">
                  {{ "Login" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="slider-img udriver">
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let slide of slides" class="slide">
          <h4 class="mainh">{{ slide.main }}</h4>
          <p>{{ slide.content }}</p>
          <figure><img src="{{ slide.img }}" alt="" width="100%" /></figure>
        </div>
      </ngx-slick-carousel>
    </div>

    <!--------------deilvery patners sec-->
    <section class="all_sec homepage_ourdriver driver_lookingdriver" id="our_driver">
      <div class="conta_iner">
        <div class="homepage_ourdriver_main">
          <div class="homepage_ourdriver_img">
            <figure>
              <img src="assets/uimages/driver_looking.svg" alt="" />
            </figure>
          </div>
          <div class="homepage_ourdriver_content">
            <div class="main_headings">
              <h2>{{ "We are looking for" | translate }}</h2>
              <h3>
                {{ "full time and part-time drivers with good driving records who are dedicated" | translate }}
                {{ "and willing to provide excellent customer services" | translate }}.
              </h3>
              <ul class="ourdriver_list">
                <li>
                  <span>{{ "Monthly electronic payments" | translate }}</span>
                </li>
                <li>
                  <span
                    >{{ "Customer rides and delivery rides (e.g. food, medicines, parts, parcels, etc.)" | translate }}
                    {{ "enabling our drivers to make more money" | translate }}</span
                  >
                </li>
                <li>
                  <span>{{ "Independence, flexibility and corporate support" | translate }}</span>
                </li>
                <li>
                  <span>{{ "Loans for purchasing new cars or fixing cars" | translate }}</span>
                </li>
                <li>
                  <span>{{ "Discounts in selected car garages" | translate }}</span>
                </li>
                <li>
                  <span>{{ "Bonuses and company ownership based on performance" | translate }}</span>
                </li>
                <li>
                  <span>{{ "Referral fees and loyalty programs" | translate }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-----start our app section-->
    <section class="our_app" id="our_app">
      <div class="conta_iner">
        <div class="our_app_main">
          <div class="our_app_screen">
            <figure>
              <img src="assets/uimages/app_screen.png" alt="" />
            </figure>
          </div>
          <div class="our_app_content">
            <h2>
              {{ "Download our Apps and start experiencing a new world of possibilities" | translate }}
            </h2>
            <div class="app_downloadlink">
              <a style="cursor: pointer" (click)="commonService?.preLaunchAlert(constant?.MSGS?.PRELAUNCH_DRIVER)">
                <img src="assets/uimages/google_store.png" alt="" />
              </a>
              <a style="cursor: pointer" (click)="commonService?.preLaunchAlert(constant?.MSGS?.PRELAUNCH_DRIVER)">
                <img src="assets/uimages/app_store.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <app-ufooter></app-ufooter>
  </div>
</div>
<!------download popup -->
<div class="modal fade app-download" id="app-download" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="download-popuptitle">
          <h4>{{ "Download Your Driver Application" | translate }}</h4>
          <p>lipsum as it is sometimes known, is dummy text used in laying out print</p>
        </div>
        <div class="driverapp-download-link">
          <div class="driverapp-item">
            <figure style="cursor: pointer" (click)="commonService?.preLaunchAlert(constant?.MSGS?.PRELAUNCH_DRIVER)">
              <img src="assets/uimages/google_store.png" alt="" />
            </figure>
          </div>
          <div class="driverapp-item">
            <figure style="cursor: pointer" (click)="commonService?.preLaunchAlert(constant?.MSGS?.PRELAUNCH_DRIVER)">
              <img src="assets/uimages/app_store.png" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="login_wrap top">
    <!-- <div class="back_home">
        <button class="driverap_btn" (click)="goTouhomepage()">Back To Home</button>
    </div> -->
    <div class="signupinner">
        <div class="conta_iner">
            <div class="flex">
                <div class="haeder_left">
                    <figure class="logo_cm" style="cursor: pointer;" routerLink="/uhomepage">
                        <img src="assets/uimages/logo.png" />
                    </figure>
                </div>
            </div>
        </div>
    </div>
    <div class="content-pages">
        <div class="conta_iner">
            <div class="login_main">
                <div class="policy_form center policy">
                    <div *ngIf="cmsData" class="login_img_sec">
                        <!-- <ckeditor [editor]="Editor" [(ngModel)]="cmsData" data="" [disabled]="true"></ckeditor> -->
                        <ejs-richtexteditor  [(ngModel)]="cmsData" [disabled]="false" [readonly]="true"></ejs-richtexteditor>
                    </div>
                    <div *ngIf="pdfData" class="login_img_sec">
                        <pdf-viewer (on-progress)="onProgress($event)" (after-load-complete)="callBackFn($event)" [src]="docUrl+pdfData" [original-size]="false" [render-text]="true" [ngStyle]="{'display':'block'}"></pdf-viewer>
                        <!-- <ngx-doc-viewer [url]="baseUrl+pdfData" viewer="google" style="width:100%;height:100vh;"></ngx-doc-viewer> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
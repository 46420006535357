<div class="riderequest">
  <div class="map">
    <agm-map [latitude]="pickup.lat" [longitude]="pickup.lng" [zoom]="zoom" (mapReady)="onMapReady($event)">
      <div *ngIf="!isDriveingStart">
        <agm-overlay
          *ngFor="let driver of nearByDriversData"
          [latitude]="driver?.latitude"
          [longitude]="driver?.longitude"
          [bounds]="{ x: { latitude: -0.003, longitude: -0.0052 }, y: { latitude: 0.003, longitude: 0.0052 } }"
        >
          <div>
            <img style="cursor: pointer; width: 50px; height: 50px" [src]="ubericon" />
          </div>
        </agm-overlay>
      </div>
      <div *ngFor="let pick of pickupfieldsArr">
        <agm-marker
          *ngIf="showPickMarker && pick?.lat !== 0 && pick?.lng !== 0"
          [iconUrl]="originMarkerIcon"
          [latitude]="pick?.lat"
          [longitude]="pick?.lng"
          [markerDraggable]="true"
          (dragEnd)="markerDragEndOrigin($event, pick)"
        ></agm-marker>
      </div>
      <div *ngFor="let drop of dropupfieldsArr">
        <agm-marker
          *ngIf="showDropMarker && drop?.lat !== 0 && drop?.lng !== 0"
          [iconUrl]="destinationMarkerIcon"
          [latitude]="drop?.lat"
          [longitude]="drop?.lng"
          [markerDraggable]="true"
          (dragEnd)="markerDragEndDestination($event, drop)"
        ></agm-marker>
      </div>
      <!-- <div *ngIf="bookingResponseData?.bookingStatus === 'ARRIVED'">
                <agm-marker [iconUrl]="driverIcon" [latitude]="pickup.lat" [longitude]="pickup.lng"
                    [markerDraggable]="false"></agm-marker>
            </div> -->
      <agm-direction
        *ngIf="showDirections"
        [origin]="origin"
        [destination]="destination"
        [renderOptions]="renderOptions"
        [markerOptions]="markerOptions"
        [waypoints]="waypoints"
      >
      </agm-direction>
    </agm-map>
  </div>
  <div class="login_map_toper flex">
    <div class="timestart" (click)="goToNotification()">
      <div class="bell"><i class="fa fa-bell" aria-hidden="true"></i></div>
      <div class="timesup" *ngIf="messagingService.notificationCount > 0">{{ messagingService.notificationCount }}</div>
    </div>
    <div class="nametext" (click)="goToprofilesetup()">
      <div class="login_map_toper_left">
        <figure>
          <img class="proImage" *ngIf="!user?.image" src="assets/images/user.png" />
          <img class="proImage" *ngIf="user?.image" [src]="baseUrl + user?.image" />
        </figure>
      </div>
      <div class="login_map_toper_right">
        <h4>{{ user?.firstName ? user?.firstName : "" + " " + user?.lastName ? user?.lastName : "" }}</h4>
      </div>
      <div class="fa_arrow"><i class="fa fa-angle-down" aria-hidden="true"></i></div>
    </div>
  </div>
  <div class="ride_request_main">
    <div class="ride_request_right">
      <div class="ride_request_right2">
        <figure class="ride_request_logo" (click)="continue(0)">
          <img src="assets/uimages/logo.png" />
        </figure>
      </div>
      <!-- <div class="language-select">
                <select name="cars" id="cars" [(ngModel)]="languageCode"
                    (change)="commonService.translateLanguage($event.target.value)">
                    <option [value]="null" disabled>Select Language</option>
                    <option value="en">English</option>
                    <option value="es">Spanish</option>
                </select>
            </div> -->
      <div class="ride_request_right2 fbtn">
        <figure class="ride_request_logo" style="cursor: pointer" (click)="goToHome()">
          <img src="assets/uimages/logo.png" />
        </figure>
        <div class="multiple_content">
          <div class="lm">
            <div class="language-select" (click)="showLang = showLang ? false : true">
              <div class="sel-bar" *ngIf="languageService?.selectedLang">
                <figure>
                  <img *ngIf="languageService?.selectedLang?.logo" [src]="baseUrl + languageService?.selectedLang?.logo" />{{
                    languageService?.selectedLang?.code?.toUpperCase()
                  }}
                </figure>
              </div>
              <p *ngIf="!languageService?.selectedLang">Select Language</p>
              <div class="ndpicon">
                <i *ngIf="!showLang" class="fa fa-caret-down" aria-hidden="true"></i>
                <i *ngIf="showLang" class="fa fa-caret-up" aria-hidden="true"></i>
              </div>
            </div>
            <div class="dp-lang" *ngIf="showLang">
              <figure *ngFor="let language of languageService?.allLanguages" (click)="selectLang(language)">
                <img *ngIf="language?.logo" [src]="baseUrl + language?.logo" />{{ language?.code?.toUpperCase() }}
              </figure>
            </div>
          </div>
          <div class="back_home hmbtn">
            <button class="driverap_btn" style="cursor: pointer" (click)="goToHome()">
              {{ "Back To Home" | translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="request_ride_inner" *ngIf="nextPopup == 0">
        <div class="select_ride_cate">
          <mat-radio-group aria-label="Select an option">
            <mat-radio-button value="ride" style="margin-right: 15px" class="choose_ride_radio">
              <div class="delivery-tab activedt">
                <h4>{{ "Book a Ride" | translate }}</h4>
                <figure>
                  <img src="assets/images/1car.png" />
                </figure>
              </div>
            </mat-radio-button>
            <mat-radio-button value="delivery" class="choose_ride_radio" (click)="goToriderdeliverybooking()">
              <div class="delivery-tab">
                <h4>{{ "Book a Delivery" | translate }}</h4>
                <figure>
                  <img src="assets/images/2car.png" />
                </figure>
              </div>
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <!-- <form class="choose_pickup_input" [formGroup]="bookingForm"> -->
        <div class="title_bar">
          <h2>{{ "Request a Ride now" | translate }}</h2>
        </div>
        <form [formGroup]="pickupForm">
          <div class="form-group choose_pickup_outer">
            <div class="form_grp_request_left"></div>
            <div class="form_grp_request_right" *ngIf="pickupfieldsArr?.length > 0">
              <mat-label class="ride_request">{{ "Choose your pick up" | translate }}</mat-label>
              <div class="multiple_add_outer" *ngFor="let pickup of pickupfieldsArr; let i = index">
                <mat-form-field class="example-full-width">
                  <div [ngClass]="i === pickupfieldsArr?.length - 1 ? 'multiple-add last' : 'multiple-add'">
                    <div class="blue_circle"></div>
                    <input
                      matInput
                      class="fv-icons"
                      placeholder="{{ 'Search for pick up location' | translate }}"
                      autocorrect="off"
                      autocapitalize="off"
                      spellcheck="off"
                      type="text"
                      ngx-google-places-autocomplete
                      #placesRef="ngx-places"
                      (change)="pickupChange(pickup, $event.target.value)"
                      (kayup)="pickeyUp(pickup, $event.target.value)"
                      (onAddressChange)="handlePickUpChnage($event, pickup)"
                      formControlName="{{ pickup?.name }}"
                    />
                    <figure class="add-fav-icon" (click)="goToFavAdd('pick', 20, i)">
                      <img src="assets/images/fav-add-icon.png" />
                    </figure>
                    <div *ngIf="dropupfieldsArr?.length === 1">
                      <button class="rotate" *ngIf="i !== pickupfieldsArr?.length - 1 || i > 2" (click)="removePickupField(i)">+</button>
                      <button
                        [disabled]="pickupForm?.controls[pickup?.name].hasError('required')"
                        class="plusBtn"
                        *ngIf="i === pickupfieldsArr?.length - 1 && i <= 2"
                        (click)="addPickupField(pickup)"
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <!-- [options]="{
                                        types: [],
                                        componentRestrictions: { country: [pickup.country] }
                                      }" -->
                  <!-- <p style="color: red;"
                                        *ngIf="rideSubmited === true && pickupForm?.controls[pickup?.name].hasError('required')">
                                        Pick up address is required.</p> -->
                  <!-- <p style="color: red;"
                                    *ngIf="rideSubmited === true && !isPickupChanged && !bookingForm?.controls['ridePickUpAddress'].hasError('required')">
                                    Please enter valid source.</p> -->
                </mat-form-field>
              </div>
              <p style="color: red" *ngIf="rideSubmited === true && pickupForm.status === 'INVALID'">
                {{ "Pick up address is required" | translate }}.
              </p>
            </div>
          </div>
        </form>
        <form [formGroup]="dropupForm">
          <div class="form-group choose_pickup_outer">
            <div class="form_grp_request_left"></div>
            <div class="form_grp_request_right sec" *ngIf="dropupfieldsArr?.length > 0">
              <mat-label class="ride_request">{{ "Choose your drop off" | translate }} </mat-label>
              <div class="multiple_add_outer" *ngFor="let drop of dropupfieldsArr; let i = index">
                <mat-form-field class="example-full-width">
                  <!-- <mat-label>Search for drop off location </mat-label> -->
                  <div [ngClass]="i === dropupfieldsArr?.length - 1 ? 'multiple-add last' : 'multiple-add'">
                    <div class="red_circle"></div>
                    <input
                      matInput
                      class="fv-icons"
                      placeholder="{{ 'Search for drop off location' | translate }}"
                      autocorrect="off"
                      autocapitalize="off"
                      spellcheck="off"
                      type="text"
                      ngx-google-places-autocomplete
                      #placesRef="ngx-places"
                      [options]="{
                        types: [],
                        componentRestrictions: { country: [drop.country] }
                      }"
                      (change)="dropChange(drop, $event.target.value)"
                      (keyup)="dropKeyup(drop, $event.target.value)"
                      (onAddressChange)="handleDropChnage($event, drop)"
                      formControlName="{{ drop?.name }}"
                    />
                    <figure class="add-fav-icon" (click)="goToFavAdd('drop', 20, i)">
                      <img src="assets/images/fav-add-icon.png" />
                    </figure>
                    <div *ngIf="pickupfieldsArr?.length === 1">
                      <button class="rotate" *ngIf="i !== dropupfieldsArr?.length - 1 || i > 2" (click)="removeDropupField(i)">+</button>
                      <button
                        [disabled]="dropupForm?.controls[drop?.name].hasError('required')"
                        class="plusBtn"
                        *ngIf="i === dropupfieldsArr?.length - 1 && i <= 2"
                        (click)="addDropupField(drop)"
                      >
                        +
                      </button>
                    </div>
                  </div>

                  <!-- <p style="color: red;"
                                        *ngIf="rideSubmited === true && dropupForm?.controls[drop?.name].hasError('required')">
                                        Drop off address is required.</p> -->
                </mat-form-field>
              </div>
              <p style="color: red" *ngIf="rideSubmited === true && dropupForm?.status === 'INVALID'">
                {{ "Drop off address is required" | translate }}.
              </p>
              <!-- <p style="color: red;"
                                *ngIf="rideSubmited === true && !isDropChanged && !bookingForm?.controls['rideDropAddress'].hasError('required')">
                                Please enter valid destination.</p> -->
            </div>
          </div>
        </form>
        <!-- </form> -->
        <!-- <div class="addfav" style="cursor: pointer">
          <p (click)="goToFavAdd(20)">My Favourites</p>
        </div> -->
        <div class="btn_divde margin_top25">
          <div class="btn_outer">
            <button [ngClass]="{ 'btn-active': activeBtn === 'Now', btn: true, 'btn-outline': activeBtn != 'Now' }" (click)="bookRideForNow('Now')">
              {{ "Start now" | translate }}
            </button>
          </div>
          <div class="btn_outer">
            <!-- data-toggle="modal" data-target="#exampleModall" -->
            <button [ngClass]="{ 'btn-active': activeBtn === 'Later', btn: true, 'btn-outline': activeBtn != 'Later' }" (click)="openDateTimeModal()">
              {{ "Schedule a ride" | translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="request_ride_inner part2" *ngIf="nextPopup == 1">
        <div class="car_using_main">
          <div class="car_using_left">
            <h4>
              {{ "Suggested vehicle" | translate }}
              <span class="suggted-car">({{ "options subject to
                availability" | translate }})</span>
            </h4>

            <!-- <ul class="car_using_left_inner" *ngIf="vehicleCategoryData?.length > 0">
                                <li [ngClass]="{'active_car_bx': selectedItem === i}"
                                    *ngFor="let category of vehicleCategoryData; let i = index"
                                    (click)='selectVehicle(category,i);'>
                                    <div class="yellow_bg">
                                        <figure>
                                            <img [src]="category?.vehicleImage" />
                                        </figure>
                                    </div>
                                    <h5>{{category?.vehicleCategory}}</h5>
                                    <p *ngIf="category?.booKingAmount !== null"
                                        [ngClass]="{'discount':category?.discount}">US
                                        $<span>{{category?.booKingAmount}}</span></p>
                                    <p *ngIf="category?.booKingAmount !== null && category?.discount">US
                                        $<span>{{category?.discount}}</span></p>
                                </li>
                            </ul> -->
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="carouselConfig">
              <div
                [ngClass]="{ active_car_bx: selectedItem === i }"
                ngxSlickItem
                *ngFor="let category of vehicleCategoryData; let i = index"
                class="slide"
              >
                <div (click)="selectVehicle(category, i)" class="slide-bg">
                  <div class="yellow_bg">
                    <figure>
                      <img [src]="category?.vehicleImage" />
                    </figure>
                  </div>
                  <h5>{{ category?.vehicleCategory }}</h5>
                  <p *ngIf="category?.booKingAmount !== null" [ngClass]="{ discount: category?.discount !== undefined }">
                    <span>{{ category?.booKingAmount }}</span>
                  </p>
                  <p class="mb-0" *ngIf="category?.booKingAmount !== null && category?.discount !== undefined">
                    <span>{{ category?.discount }}</span>
                  </p>
                </div>
              </div>
            </ngx-slick-carousel>

            <h3 class="colorRed" style="text-align: center" *ngIf="vehicleCategoryData?.length <= 0">
              {{ "No driver available nearby, please try again later" | translate }}.
            </h3>
          </div>
        </div>
        <div class="note_driver">
          <h3>{{ "Note to driver" | translate }}</h3>
          <span>{{ "Let your driver know more about your request" | translate }}.</span>
          <div class="note_driver_name">
            <form [formGroup]="driverNoteForm">
              <mat-form-field class="example-full-width">
                <input
                  type="text"
                  matInput
                  formControlName="phoneNo"
                  class="form-control"
                  placeholder="{{ 'Enter Your Phone Number' | translate }}"
                />
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <input type="text" matInput formControlName="name" class="form-control" placeholder="{{ 'Enter Your Name' | translate }}" />
              </mat-form-field>
              <textarea placeholder="{{ 'Note' | translate }}" maxlength="100" formControlName="notes" id="" cols="30" rows="10"></textarea>
            </form>
          </div>
        </div>
        <div class="add_promo_sec">
          <div class="visa_card chng">
            <!-- <div>
                            <figure (click)="continue(2)">
                                <img src="assets/uimages/visa_icon.png" alt="" />
                            </figure>
                        </div> -->
            <div class="card_no" style="cursor: pointer" (click)="openPaymentOptions(2)">
              <p *ngIf="!isWalletSelected && !selectedCardDetails?.cardNumber">
                {{ defaultCardDetails?.cardNumber }} <span> {{ defaultCardDetails?.brand }}</span>
              </p>
              <p *ngIf="!isWalletSelected && selectedCardDetails?.cardNumber">
                {{ selectedCardDetails?.cardNumber }} <span> {{ selectedCardDetails?.brand }}</span>
              </p>
              <p
                [ngClass]="!(selectedCardDetails || isWalletSelected) ? 'colorRed' : 'abc'"
                *ngIf="!isWalletSelected && !defaultCardDetails?.cardNumber && !selectedCardDetails?.cardNumber"
              >
                {{ "Select Payment Method" | translate }}
              </p>
              <p *ngIf="isWalletSelected">{{ "Wallet" | translate }}</p>
              <div class="cni"><i class="fa fa-angle-down" aria-hidden="true"></i></div>
            </div>
          </div>
          <div class="add_promo">
            <a style="cursor: pointer" (click)="loyalityModals('block')"
              ><span><img src="assets/uimages/promo_icon.png" alt="" /></span>{{ totalLoyality === "" ? messagesData?.U2GO_MONEY : totalLoyality }}</a
            >
          </div>
        </div>
        <div class="btn_divde margin_custom">
          <div class="btn_outer inactive">
            <button class="btn btn-primary" (click)="continue(0)">
              {{ "Back" | translate }}
            </button>
          </div>
          <div class="btn_outer">
            <button class="btn btn-primary" (click)="CreateRideBooking(3)">
              {{ "Request Booking" | translate }}
            </button>
          </div>
        </div>
      </div>

      <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 2">
        <div class="title_bar">
          <h2>{{ "Payment-Options" | translate }}</h2>
        </div>
        <ul class="payment_last">
          <li>
            <div class="card1">
              <div class="card-innre-payment">
                <div class="wallet-img">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-wallet2" viewBox="0 0 16 16">
                    <path
                      d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"
                    />
                  </svg>
                </div>
                <div class="balance">
                  <p>{{ allSavedCardData?.walletAmount?.toFixed(2) | currency: "USD" || "$0.00" }}</p>
                  <p>{{ "Wallet Balance" | translate }}</p>
                </div>
              </div>
              <div class="add-btn add-mny">
                <i class="fa fa-plus" title="Add Money" style="cursor: pointer" aria-hidden="true" (click)="addWalletModal('block')"></i>
              </div>
            </div>
          </li>
          <li class="borderbtm waletli">
            <div class="nsflex">
              <div class="list_inner">
                <mat-checkbox [checked]="isWalletSelected" (change)="onWalletSelected($event.checked)">
                  {{ "Pay via APPTUNIX Wallet" | translate }}</mat-checkbox
                >
              </div>
            </div>
          </li>
          <li class="borderbtm">
            <div class="list_inner">
              <ngx-paypal [config]="payPalConfig"></ngx-paypal>
            </div>
          </li>
          <li class="borderbtm newli" *ngIf="allSavedCardData?.cards?.length > 0">
            <mat-radio-group aria-label="Select an option" (change)="onCardChange($event.value)">
              <mat-radio-button [checked]="card?.isSelected" [value]="i + 1" *ngFor="let card of allSavedCard; let i = index">
                <div class="card_detailcs">
                  <div>
                    <h3>
                      {{ card?.cardNumber }}<span>{{ card?.brand }} - {{ card?.expiryDate.slice(0, 2) }}/{{ card?.expiryDate.slice(2) }}</span>
                    </h3>
                  </div>
                  <div class="dlt-radio">
                    <h3 *ngIf="card?.isDefault">
                      <span class="text-success">{{ "PRIMARY CARD" | translate }}</span>
                    </h3>
                    <h3 *ngIf="!card?.isDefault" (click)="changeDefaultCard(card?._id)">
                      <span style="color: #0171bd">{{ "MAKE PRIMARY" | translate }}</span>
                    </h3>
                    <i class="fa fa-trash" (click)="deleteCard(card?._id)" aria-hidden="true"></i>
                  </div>
                </div>
              </mat-radio-button>
            </mat-radio-group>
          </li>
        </ul>
        <div class="pay-op">
          <div (click)="AddCardModal('block')">
            <button class="btn btn-primary">{{ "Add Payment Card" | translate }}</button>
          </div>
          <div>
            <button class="btn btn-primary" (click)="continue('1')">{{ "Next" | translate }}</button>
          </div>
        </div>
      </div>
      <!-----start rider accept -->
      <div class="request_ride_inner border_remove paymernt sec" *ngIf="nextPopup == 3">
        <div class="driver_info_bx sec">
          <div class="spinners"><i class="fa fa-spinner fa-spin"></i></div>
          <p class="arrving_time">{{ "We are processing your booking request" | translate }}..</p>
          <p class="arrving_time">{{ "A notification will be received soon" | translate }}.</p>

          <div class="ride_can_btn">
            <button class="ride_cancle_btn" (click)="cancelConfirmToast('cancel')">{{ "Cancel" | translate }}</button>
          </div>
        </div>

        <!-- <div class="flex reqst driverap_btn">
                        <button (click)="continue('1')">Next</button>
                    </div> -->

        <!-----end rider accept-->
      </div>
      <!-----start call me reach me screen-->
      <!-----start rider accept -->
      <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 4">
        <div class="driver_info_bx">
          <p class="arrving_time">{{ driverMessage }}</p>
          <div class="about_driver">
            <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image === ''">
              <img src="assets/images/user.png" alt=" " />
            </figure>
            <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image != ''">
              <img [src]="baseUrl + bookingResponseData?.driverId?.image" alt=" " />
            </figure>
            <div class="driver_name_sec">
              <p class="driver_name">{{ bookingResponseData?.driverId?.firstName }}</p>
              <span><i class="fa fa-star" aria-hidden="true"></i>{{ bookingResponseData?.driverId?.avgRating }}</span>
              <span><i class="fa fa-map-marker" aria-hidden="true"></i>{{ distance?.toFixed(2) }} km</span>
            </div>
            <div class="car_img">
              <!-- <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage === ''">
                                <img src="assets/uimages/ride_car.png " alt=" " />
                            </figure>
                            <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage != ''">
                                <img [src]="baseUrl+bookingResponseData?.vehicleId?.vehicleImage" alt=" " />
                            </figure> -->
              <p>
                {{ bookingResponseData?.vehicleId?.vehicleModel }}:
                <span>{{ bookingResponseData?.vehicleId?.licensePlateNumber }}</span>
              </p>
            </div>
          </div>
          <h4 class="trip_title">{{ "Trip" | translate }}</h4>
          <Div class="start_trip">
            <div class="start_trip_text">
              <div class="mm" *ngFor="let pick of bookingResponseData?.subBookingData">
                <div *ngIf="pick?.isPickUp">
                  <div class="blm">
                    <div class="bb"></div>
                  </div>
                  <div class="mkl">
                    <p>{{ pick?.address }}</p>
                  </div>
                </div>
              </div>

              <div class="mm" *ngFor="let drop of bookingResponseData?.subBookingData">
                <div *ngIf="!drop?.isPickUp">
                  <div class="blm">
                    <div class="blc"></div>
                  </div>
                  <div class="mkl">
                    <p>{{ drop?.address }}</p>
                  </div>
                </div>
              </div>
            </div>
          </Div>
          <div class="moniter_your_ride" *ngIf="bookingResponseData?.subBookingData[0].bookingStatus >= 6">
            <p style="cursor: pointer" (click)="openMonitorModal(bookingResponseData?.id)">
              {{ "Monitor your ride" | translate }}
              <i class="fa fa-angle-right"></i>
            </p>
          </div>
          <hr />
          <div class="note_call" *ngIf="bookingResponseData?.additionalInfo">
            <p>{{ "Note" | translate }} : {{ bookingResponseData?.additionalInfo }}.</p>
          </div>
          <div class="trip_payment">
            <h4>{{ "Payment" | translate }}</h4>
            <div class="visa_card">
              <!-- <figure style="cursor: pointer;" (click)="continue('2')">
                                <img src="assets/uimages/visa_card.png " alt=" " />
                            </figure> -->
              <div class="card_no">
                <p
                  *ngIf="bookingResponseData?.paymentMode !== constant?.PAYMENT_MODE?.WALLET && !isWalletSelected && !selectedCardDetails?.cardNumber"
                >
                  {{ defaultCardDetails?.cardNumber }} <span> {{ defaultCardDetails?.brand }}</span>
                </p>
                <p
                  *ngIf="bookingResponseData?.paymentMode !== constant?.PAYMENT_MODE?.WALLET && !isWalletSelected && selectedCardDetails?.cardNumber"
                >
                  {{ selectedCardDetails?.cardNumber }} <span> {{ selectedCardDetails?.brand }}</span>
                </p>
                <p *ngIf="bookingResponseData?.paymentMode === constant?.PAYMENT_MODE?.WALLET">
                  {{ "Wallet" | translate }}
                </p>
              </div>
            </div>
          </div>
          <!----start cancel call btn-->
          <div class="ride_can_btn" *ngIf="bookingResponseData?.subBookingData[0].bookingStatus < 4">
            <button class="ride_cancle_btn" (click)="cancelConfirmToast(null)">
              {{ "Cancel" | translate }}
              <div class="duration" *ngIf="commonService?.cancelCounter > 0">{{ commonService?.cancelCounter }}</div>
            </button>
            <button class="call_btn" (click)="goToContactDetail()">{{ "Call" | translate }}</button>
          </div>
          <div class="add_vehicle_honda driver-info flex" id="driverInfo" *ngIf="!isContactDetailHide">
            <div class="add_honda_left">
              <div class="file">
                <figure class="driver-img">
                  <img class="driverd-pro" *ngIf="!bookingResponseData?.driverId?.image" src="assets/images/user.png" />
                  <img class="driverd-pro" *ngIf="bookingResponseData?.driverId?.image" [src]="baseUrl + bookingResponseData?.driverId?.image" />
                </figure>
                <h5>
                  <span>{{ bookingResponseData?.driverId?.countryCode }} {{ bookingResponseData?.driverId?.phoneNo }}</span>
                  {{ bookingResponseData?.driverId?.firstName }}
                  {{ bookingResponseData?.driverId?.lastName }}
                  <!-- <span class="capitaAL_SVU">Quantity:</span> -->
                </h5>
              </div>
            </div>
            <div class="add_vehicle_right flex">
              <div class="add_vehicle_right_icon">
                <figure>
                  <img src="assets/images/phone-call.svg" />
                </figure>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="flex reqst driverap_btn ">
                        <button (click)="continue( '1') ">Next</button>
                    </div> -->

        <!-----end rider accept-->
      </div>
      <!-----End  call me reach me screen-->

      <!-----start driver reach -->
      <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 5">
        <div class="driver_info_bx">
          <p class="arrving_time">Your driver has arrived.</p>
          <div class="about_driver">
            <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image === ''">
              <img src="assets/images/user.png" alt=" " />
            </figure>
            <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image != ''">
              <img [src]="baseUrl + bookingResponseData?.driverId?.image" alt=" " />
            </figure>
            <div class="driver_name_sec">
              <p class="driver_name">{{ bookingResponseData?.driverId?.firstName }}</p>
              <span><i class="fa fa-star" aria-hidden="true"></i>{{ bookingResponseData?.driverId?.avgRating }}</span>
              <span><i class="fa fa-map-marker" aria-hidden="true"></i>{{ distance?.toFixed(2) }} km</span>
            </div>
            <div class="car_img">
              <!-- <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage === ''">
                                <img src="assets/uimages/ride_car.png " alt=" " />
                            </figure>
                            <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage != ''">
                                <img [src]="baseUrl+bookingResponseData?.vehicleId?.vehicleImage" alt=" " />
                            </figure> -->
              <p>
                {{ bookingResponseData?.vehicleId?.vehicleModel }}:
                <span>{{ bookingResponseData?.vehicleId?.licensePlateNumber }}</span>
              </p>
            </div>
          </div>
          <h4 class="trip_title">Trip</h4>
          <Div class="start_trip">
            <div class="start_trip_text">
              <div class="mm" *ngFor="let pick of bookingResponseData?.subBookingData">
                <div *ngIf="pick?.isPickUp">
                  <div class="blm">
                    <div class="bb"></div>
                  </div>
                  <div class="mkl">
                    <p>{{ pick?.address }}</p>
                  </div>
                </div>
                <div class="stts" *ngIf="pick?.isPickUp && pick?.bookingStatus !== constant.BOOKING_STATUS.ACCEPTED">
                  <p>{{ constant.SHOW_STATUS_BOOKING[pick?.bookingStatus] }}</p>
                </div>
              </div>

              <div class="mm" *ngFor="let drop of bookingResponseData?.subBookingData">
                <div *ngIf="!drop?.isPickUp">
                  <div class="blm">
                    <div class="blc"></div>
                  </div>
                  <div class="mkl">
                    <p>{{ drop?.address }}</p>
                  </div>
                </div>
                <div class="stts" *ngIf="!drop?.isPickUp && drop?.bookingStatus !== constant.BOOKING_STATUS.ACCEPTED">
                  <p>{{ constant.SHOW_STATUS_BOOKING[drop?.bookingStatus] }}</p>
                </div>
              </div>
            </div>
          </Div>
          <div class="note_call" *ngIf="bookingResponseData?.note != null">
            <p>Note : {{ bookingResponseData?.note }}.</p>
          </div>
          <div class="trip_payment">
            <h4>Payment</h4>
            <div class="visa_card">
              <figure (click)="continue('2')">
                <img src="assets/uimages/visa_card.png " alt=" " />
              </figure>
            </div>
          </div>
          <!----start cancel call btn-->
          <div class="ride_can_btn">
            <button class="call_btn" (click)="goToContactDetail()">Call</button>
          </div>
          <div class="add_vehicle_honda driver-info flex" id="driverInfo" *ngIf="!isContactDetailHide">
            <div class="add_honda_left">
              <div class="file">
                <div class="file-left">
                  <figure class="driver-img">
                    <img class="driverd-pro" *ngIf="!bookingResponseData?.driverId?.image" src="assets/images/user.png" />
                    <img class="driverd-pro" *ngIf="bookingResponseData?.driverId?.image" [src]="baseUrl + bookingResponseData?.driverId?.image" />
                  </figure>
                </div>
                <div class="file-right">
                  <h5>
                    <span>{{ bookingResponseData?.driverId?.countryCode }} {{ bookingResponseData?.driverId?.phoneNo }}</span>
                    {{ bookingResponseData?.driverId?.firstName }}
                    {{ bookingResponseData?.driverId?.lastName }}
                    <!-- <span class="capitaAL_SVU">Quantity:</span> -->
                  </h5>
                </div>
              </div>
            </div>
            <div class="add_vehicle_right flex">
              <div class="add_vehicle_right_icon">
                <figure>
                  <img class="clr-bl" src="assets/images/phone-call.svg" />
                </figure>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="flex reqst driverap_btn ">
                        <button (click)="continue( '1') ">Next</button>
                    </div> -->

        <!-----end rider accept-->
      </div>
      <!-----End  call me driver  me screen-->

      <!----start next 2 screen ride start now-->
      <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 6">
        <div class="driver_info_bx">
          <p class="arrving_time">Your ride has started.</p>
          <div class="about_driver">
            <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image === ''">
              <img src="assets/images/user.png" alt=" " />
            </figure>
            <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image != ''">
              <img [src]="baseUrl + bookingResponseData?.driverId?.image" alt=" " />
            </figure>
            <div class="driver_name_sec">
              <p class="driver_name">{{ bookingResponseData?.driverId?.firstName }}</p>
              <span><i class="fa fa-star" aria-hidden="true"></i>{{ bookingResponseData?.driverId?.avgRating }}</span>
              <span><i class="fa fa-map-marker" aria-hidden="true"></i>{{ distance?.toFixed(2) }} km</span>
            </div>
            <div class="car_img">
              <!-- <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage === ''">
                                <img src="assets/uimages/ride_car.png " alt=" " />
                            </figure>
                            <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage != ''">
                                <img [src]="baseUrl+bookingResponseData?.vehicleId?.vehicleImage" alt=" " />
                            </figure> -->
              <p>
                {{ bookingResponseData?.vehicleId?.vehicleModel }}:
                <span>{{ bookingResponseData?.vehicleId?.licensePlateNumber }}</span>
              </p>
            </div>
          </div>
          <h4 class="trip_title">Trip</h4>
          <Div class="start_trip">
            <div class="start_trip_text">
              <div class="mm" *ngFor="let pick of bookingResponseData?.subBookingData">
                <div *ngIf="pick?.isPickUp">
                  <div class="blm">
                    <div class="bb"></div>
                  </div>
                  <div class="mkl">
                    <p>{{ pick?.address }}</p>
                  </div>
                </div>
              </div>

              <div class="mm" *ngFor="let drop of bookingResponseData?.subBookingData">
                <div *ngIf="!drop?.isPickUp">
                  <div class="blm">
                    <div class="blc"></div>
                  </div>
                  <div class="mkl">
                    <p>{{ drop?.address }}</p>
                  </div>
                </div>
              </div>
            </div>
          </Div>
          <div class="moniter_your_ride">
            <p (click)="openMonitorModal(bookingResponseData?.id)">
              Monitor your ride
              <i class="fa fa-angle-right"></i>
            </p>
          </div>
          <div class="ride_cost_sec">
            <div class="cost_item">
              <p>
                Cost <span>US ${{ bookingResponseData?.booKingAmount }}</span>
              </p>
            </div>
            <div class="cost_item">
              <p>
                Duration <span>{{ duration.toFixed(0) }} mins.</span>
              </p>
            </div>
          </div>
        </div>
        <!-----end rider accept-->
      </div>

      <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 7">
        <div class="driver_info_bx">
          <p class="arrving_time">You will reach your destination in next 5 minutes.</p>
          <div class="about_driver">
            <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image === ''">
              <img src="assets/images/user.png" alt=" " />
            </figure>
            <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image != ''">
              <img [src]="baseUrl + bookingResponseData?.driverId?.image" alt=" " />
            </figure>
            <div class="driver_name_sec">
              <p class="driver_name">Jhon</p>

              <span><i class="fa fa-star" aria-hidden="true"></i>4.8</span>
              <span><i class="fa fa-map-marker" aria-hidden="true"></i>2:30 min</span>
            </div>
            <div class="car_img">
              <figure>
                <img src="assets/uimages/ride_car.png " alt=" " />
              </figure>
              <p>Toyota: <span>8CF4829</span></p>
            </div>
          </div>
          <h4 class="trip_title">Trip</h4>
          <Div class="start_trip">
            <div class="start_trip_text">
              <div class="mm" *ngFor="let pick of bookingResponseData?.subBookingData">
                <div *ngIf="pick?.isPickUp">
                  <div class="blm">
                    <div class="bb"></div>
                  </div>
                  <div class="mkl">
                    <p>{{ pick?.address }}</p>
                  </div>
                </div>
              </div>

              <div class="mm" *ngFor="let drop of bookingResponseData?.subBookingData">
                <div *ngIf="!drop?.isPickUp">
                  <div class="blm">
                    <div class="blc"></div>
                  </div>
                  <div class="mkl">
                    <p>{{ drop?.address }}</p>
                  </div>
                </div>
              </div>
            </div>
          </Div>
          <div class="moniter_your_ride">
            <p (click)="openMonitorModal(bookingResponseData?.id)">
              Monitor your ride
              <i class="fa fa-angle-right"></i>
            </p>
          </div>
          <div class="ride_cost_sec">
            <div class="cost_item">
              <p>
                Cost <span>US ${{ bookingResponseData?.booKingAmount }}</span>
              </p>
            </div>
            <div class="cost_item">
              <p>
                Duration <span>{{ duration.toFixed(0) }} mins.</span>
              </p>
            </div>
          </div>
        </div>
        <!-----end rider accept-->
      </div>
      <!----end next 2 screen ride start now-->

      <!-----you ride started page-->

      <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 8">
        <div class="driver_info_bx">
          <p class="arrving_time">{{ messagesData?.YOU_ARRIVED_AT_YOUR_DESTINATION }}</p>
          <div class="about_driver">
            <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image === ''">
              <img src="assets/images/user.png" alt=" " />
            </figure>
            <figure class="driver_img" *ngIf="bookingResponseData?.driverId?.image != ''">
              <img [src]="baseUrl + bookingResponseData?.driverId?.image" alt=" " />
            </figure>
            <div class="driver_name_sec">
              <p class="driver_name">{{ bookingResponseData?.driverId?.firstName }}</p>
              <span><i class="fa fa-star" aria-hidden="true"></i>{{ bookingResponseData?.driverId?.avgRating }}</span>
              <span><i class="fa fa-map-marker" aria-hidden="true"></i>{{ distance?.toFixed(2) }} km</span>
            </div>
            <div class="car_img">
              <!-- <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage === ''">
                                <img src="assets/uimages/ride_car.png " alt=" " />
                            </figure>
                            <figure *ngIf="bookingResponseData?.vehicleId?.vehicleImage != ''">
                                <img [src]="baseUrl+bookingResponseData?.vehicleId?.vehicleImage" alt=" " />
                            </figure> -->
              <p>
                {{ bookingResponseData?.vehicleId?.vehicleModel }}:
                <span>{{ bookingResponseData?.vehicleId?.licensePlateNumber }}</span>
              </p>
            </div>
          </div>
          <div>
            <div class="trip">
              <p>{{ "Type of trip" | translate }}: {{ bookingResponseData?.isSheduledBooking ? messagesData?.LATER : messagesData?.NOW }}</p>
              <p>{{ bookingResponseData?.bookingDate | date: "MMM d, y, h:mm a" }}</p>
            </div>
            <Div class="start_trip">
              <div class="start_trip_text">
                <div class="mm" *ngFor="let pick of bookingResponseData?.subBookingData">
                  <div *ngIf="pick?.isPickUp">
                    <div class="blm">
                      <div class="bb"></div>
                    </div>
                    <div class="mkl">
                      <p>{{ pick?.address }}</p>
                    </div>
                  </div>
                </div>

                <div class="mm" *ngFor="let drop of bookingResponseData?.subBookingData">
                  <div *ngIf="!drop?.isPickUp">
                    <div class="blm">
                      <div class="blc"></div>
                    </div>
                    <div class="mkl">
                      <p>{{ drop?.address }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Div>
            <div class="details">
              <p>{{ bookingResponseData?.name }}</p>
              <hr />
              <p>{{ bookingResponseData?.phoneNo }}</p>
              <hr />
              <p *ngIf="bookingResponseData?.additionalInfo">{{ bookingResponseData?.additionalInfo }}</p>
              <hr *ngIf="bookingResponseData?.additionalInfo" />
            </div>
            <div class="amount">
              <div class="price">{{ bookingResponseData?.booKingAmount | currency: "USD" || "$0.00" }}</div>
              <p class="tax">{{ "incl. Tax" | translate }}</p>
            </div>
          </div>
          <div class="ride_can_btn rvw">
            <button class="call_btn btn btn-primary rwdr" (click)="reviewDriver(14)">{{ "Review Driver" | translate }}</button>
          </div>
        </div>
        <!-----end rider accept-->
      </div>

      <div class="request_ride_inner border_remove" *ngIf="nextPopup == 14">
        <div class="driver_info_bx">
          <p class="arrving_time">{{ "Rate Your Ride" | translate }}.</p>
          <div class="rate-screen"></div>
          <div class="rl">
            <div class="del-main">
              <div class="del-l">
                <figure style="border: 1px solid #cecece">
                  <img *ngIf="!driverReviewData?.image" src="assets/images/user.png" />
                  <img *ngIf="driverReviewData?.image" [src]="baseUrl + driverReviewData?.image" />
                </figure>
              </div>
              <div class="main-c">
                <div class="del-r del-u">
                  <h5>{{ driverReviewData?.firstName + " " + driverReviewData?.lastName }}</h5>
                </div>
                <div class="driver_rate_pop del-b">
                  <p>
                    {{ "Rating" | translate }}<span>{{ driverReviewData?.avgRating }}/5</span>
                  </p>
                  <p>
                    {{ "Review" | translate }}<span>{{ driverReviewData?.reviewTotal }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="heart">
              <i (click)="addFavouriteDriver()" *ngIf="!driverReviewData?.isFav" class="fa fa-heart-o redcolor" aria-hidden="true"></i>
              <i (click)="removeFavouriteDriver()" *ngIf="driverReviewData?.isFav" class="fa fa-heart redcolor" aria-hidden="true"></i>
            </div>
          </div>
          <div class="rating">
            <p class="title">{{ "Rate" | translate }}</p>
            <div class="star-rating">
              <button
                mat-icon-button
                *ngFor="let ratingId of ratingArr; index as i"
                [id]="'star_' + i"
                (click)="onClick(i + 1)"
                [matTooltip]="ratingId + 1"
                matTooltipPosition="above"
              >
                <mat-icon>
                  {{ showIcon(i) }}
                </mat-icon>
              </button>
            </div>
            <p class="rating-err" *ngIf="ratingSubmitted && rating === 0">{{ "Please give rating" | translate }}.</p>
          </div>
          <ul class="bages" *ngIf="rating > 2">
            <li
              [ngClass]="selectedDriverReview === i ? 'badge badge-secondary active' : 'badge badge-secondary'"
              *ngFor="let data of reviewData; let i = index"
              (click)="selectReview(i, data)"
            >
              {{ data?.name }}
            </li>
            <!-- <li [ngClass]="{'badge badge-secondary': data?.isActive === false,'badge badge-secondary active': data?.isActive === true}" *ngFor="let data of reviewData">{{data?.name}}</li> -->
          </ul>
          <div class="fgi sec" *ngIf="rating <= 2 && rating > 0">
            <textarea id="w3review" class="from-control" placeholder="Enter your feedback here..." [formControl]="review"></textarea>
          </div>
          <p class="rating-err" *ngIf="ratingSubmitted && selectedReviewData === '' && rating > 2">{{ "Please give feedback" | translate }}.</p>
          <p class="rating-err" *ngIf="ratingSubmitted && review.value === '' && rating <= 2">{{ "Please give feedback" | translate }}.</p>
        </div>
        <div class="tip-title">
          <p>{{ "Add a tip" | translate }}</p>
        </div>
        <div class="bb-hldr">
          <div [ngClass]="selectedTip === i ? 'bbxx active' : 'bbxx'" *ngFor="let item of dummy; let i = index" (click)="selectTip(i, item?.amount)">
            {{ "$ " + item?.amount }}
          </div>
        </div>
        <div class="custom-tip">
          <mat-form-field class="example-full-width">
            <input
              class="tip-input"
              type="number"
              (change)="onTipChange($event.target.value)"
              (keyup)="keypresshandler($event)"
              [formControl]="customRate"
              matInput
              placeholder="{{ 'Enter custom amount' | translate }}."
            />
          </mat-form-field>
        </div>
        <div class="feedback-links">
          <h5 class="fdd-links">{{ "Feedback Links" | translate }}</h5>
          <h6 class="dd">{{ "Driver" | translate }}</h6>
          <ul>
            <li>
              {{ "Was the driver on time?" | translate }}
              <div class="tp">
                <mat-radio-group aria-label="Select an option" [formControl]="wasTheDriverOnTime">
                  <mat-radio-button [value]="true">{{ "Yes" | translate }}</mat-radio-button>
                  <mat-radio-button [value]="false">{{ "No" | translate }}</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="ratingSubmitted && wasTheDriverOnTime.hasError('required')">Please select an option</mat-error>
              </div>
            </li>
            <li>
              {{ "Did the driver make your ride comfortabel?" | translate }}
              <div class="tp">
                <mat-radio-group aria-label="Select an option" [formControl]="driverMakeYouComfortabel">
                  <mat-radio-button [value]="true">{{ "Yes" | translate }}</mat-radio-button>
                  <mat-radio-button [value]="false">{{ "No" | translate }}</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="ratingSubmitted && driverMakeYouComfortabel.hasError('required')">Please select an option</mat-error>
              </div>
            </li>
            <li>
              {{ "Was the ride safe?" | translate }}
              <div class="tp">
                <mat-radio-group aria-label="Select an option" [formControl]="wasTheRideSafe">
                  <mat-radio-button [value]="true">{{ "Yes" | translate }}</mat-radio-button>
                  <mat-radio-button [value]="false">{{ "No" | translate }}</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="ratingSubmitted && wasTheRideSafe.hasError('required')">Please select an option</mat-error>
              </div>
            </li>
            <li class="imp">
              {{ "What can we improve for next time?" | translate }}
              <div class="tp">
                <mat-form-field class="example-full-width">
                  <textarea matInput [formControl]="comment" placeholder="Comment"></textarea>
                </mat-form-field>
              </div>
            </li>
          </ul>
        </div>
        <div class="bk-hm">
          <button class="btn btn-primary first-btn" (click)="addRating(0)">{{ "Submit" | translate }}</button>
          <button class="btn btn-outline second-btn" (click)="continue(0)">{{ "Skip" | translate }}</button>
        </div>
      </div>

      <!--  <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 10" (click)="continue('0')">
                <div class="driver_info_bx">
                    <p class="arrving_time">Ride is completed</p>
                 
                        <div class="driver_info">
                            <div class="driver_info_img">
                              <figure>
                                <img src="assets/uimages/driver_img.png" alt="" />
                              </figure>
                            </div>
                            <div class="driver_info_text">
                              <h3>Evan Guzman</h3>
                              <figure>
                                <img src="assets/uimages/star_rateing.png" alt="" />
                              </figure>
                              <p>Toyota Corolla:<span>8CF4829</span></p>
                            </div>
                          </div>
                          <div class="trip_info">
                            <p>Type of trip: Now</p>
                            <p>July 24, 2020, 9:58 AM</p>
                          </div>
                          <div class="trip_pickup_info">
                            <div class="trip_info_img">
                              <figure>
                                <img src="assets/uimages/blue_yellow_circle.png" alt="" />
                              </figure>
                            </div>
                            <div class="trip_detail">
                              <h5><label>Pick-Up</label>199 Brown Estate Apt. 866</h5>
                              <h5><label>Destination</label>199 Brown Estate Apt. 866</h5>
                            </div>
                          </div>
                          <div class="driver_name_info">
                            <p>sandeep singh</p>
                            <p>+40-0767217315</p>
                          </div>
                        
                          <div class="booking-complete">
                              <h4>Booking Completed</h4>
                          </div>
                  
                </div>
              
            </div>-->

      <!-- Modal -->
      <div class="modal fade" id="exampleModal " tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel " aria-hidden="true ">
        <div class="modal-dialog" role="document ">
          <div class="modal-content">
            <div class="">
              <button type="button " class="close" data-dismiss="modal " aria-label="Close ">
                <span aria-hidden="true ">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="add_vehicle">
                <h4>Add vehicle</h4>
                <div class="vehicle_form">
                  <div class="form-group">
                    <mat-form-field [floatLabel]="'never'">
                      <input matInput type="text " placeholder="Vehicle make " name="name " />
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <mat-form-field [floatLabel]="'never'">
                      <input matInput type="text " placeholder="Vehicle Model " name="name " />
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <mat-form-field>
                      <mat-label>Vehicle type</mat-label>
                      <mat-select [(value)]="selected">
                        <mat-option value="option1 ">SUV</mat-option>
                        <mat-option value="option2 ">SUV</mat-option>
                        <mat-option value="option3 ">SUV</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="form-group upload_form_group">
                    <figure class="ipload_img_vehicle">
                      <svg xmlns="http://www.w3.org/2000/svg " width="114.195 " height="97.656 " viewBox="0 0 114.195 97.656 ">
                        <g id="surface1 " transform="translate(0) ">
                          <path
                            id="Path_24238 "
                            data-name="Path 24238 "
                            d="M110.78,160.375H3.416a3.39,3.39,0,0,0-2.583,1.164,3.25,3.25,0,0,0-.8,2.7l7.069,50.644a8.6,8.6,0,0,0,8.431,7.381H98.8a8.581,8.581,0,0,0,8.432-7.409l6.926-50.645a3.343,3.343,0,0,0-.795-2.7A3.461,3.461,0,0,0,110.78,160.375ZM75.267,191.744a2.266,2.266,0,0,1-1.533.6,2.418,2.418,0,0,1-1.675-.71l-12.69-13.655v32.448a2.271,2.271,0,0,1-4.543,0V177.976L42.137,191.6a2.269,2.269,0,1,1-3.321-3.094l16.635-17.857a2.272,2.272,0,0,1,1.675-.709,2.318,2.318,0,0,1,1.675.709l16.635,17.857A2.372,2.372,0,0,1,75.267,191.744Zm0,0 "
                            transform="translate(0 -124.605) "
                            fill="#ccc "
                          />
                          <path
                            id="Path_24239 "
                            data-name="Path 24239 "
                            d="M39.057,31.228h96.152V24.045a8.379,8.379,0,0,0-8.432-8.432H91.263A13.123,13.123,0,0,1,80.078,9.2L77.126,4.173A8.569,8.569,0,0,0,69.83,0H46.58a8.468,8.468,0,0,0-6.161,2.7,8.563,8.563,0,0,0-2.357,6.33Zm0,0 "
                            transform="translate(-29.566) "
                            fill="#ccc "
                          />
                        </g>
                      </svg>
                    </figure>
                    <h5>Upload insurance document</h5>
                    <input type="file " id="file-input " class="upload_file" />
                  </div>
                </div>
                <div class="driverap_btn">
                  <button>Add Now</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- request_modale -->

      <div
        class="modal fade"
        id="exampleModall"
        tabindex="-1"
        [ngStyle]="{ display: display, opacity: 1 }"
        role="dialog "
        aria-labelledby="exampleModalLabel "
        aria-hidden="true "
      >
        <div class="modal-dialog" role="document ">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button " class="close" (click)="closeScheduleModal()" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true ">&times;</span>
              </button>
            </div>
            <div class="modal-body second">
              <div class="add_vehicle">
                <h4>{{ "Pick a date and time to book a vehicle" | translate }}</h4>
                <div class="vehicle_form">
                  <form [formGroup]="requestForLaterForm">
                    <!-- <div class="form-group">
                                            <mat-form-field>
                                                <mat-label>Choose a date</mat-label>
                                                <input matInput formControlName="date" [matDatepicker]="picker" />
                                                <mat-datepicker-toggle matSuffix [for]="picker">
                                                </mat-datepicker-toggle>

                                                <mat-datepicker #picker> </mat-datepicker>
                                                <div class="alm_modale clender">
                                                    <figure class="alarm_modale">
                                                        <img src="../../assets/images/alarm_modale.svg " />
                                                    </figure>
                                                </div>
                                            </mat-form-field>
                                        </div>
                                        <div class="form-group">
                                            <div class="default-time-example">
                                                <input aria-label="default time" formControlName="time"
                                                    [ngxTimepicker]="defaultValue" [value]="'05:11 pm'" readonly />
                                                <ngx-material-timepicker #defaultValue></ngx-material-timepicker>
                                            </div>
                                            <div class="alm_modale">
                                                <figure class="alarm_modale">
                                                    <img src="../../assets/images/timer_modale.svg " />
                                                </figure>
                                            </div>
                                        </div> -->
                    <div class="form-group">
                      <input
                        [owlDateTimeTrigger]="dt"
                        formControlName="dateTime"
                        placeholder="{{ 'Select Date and Time' | translate }}"
                        [min]="mindate"
                        [owlDateTime]="dt"
                      />
                      <owl-date-time #dt></owl-date-time>
                      <mat-error *ngIf="isDateTimeSubmit && requestForLaterForm?.controls['dateTime'].hasError('required')">
                        {{ "Please select Date and Time" | translate }}</mat-error
                      >
                    </div>
                  </form>
                </div>
                <div class="driverap_btn">
                  <button style="cursor: pointer" (click)="bookRideForLater()">{{ "Request For Later" | translate }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-----add payment card modal-->
      <!-- Modal -->
      <div class="modal fade" id="add_payment" [ngStyle]="{ display: addCardModal, opacity: 1 }">
        <div class="modal-dialog">
          <!-- Modal content-->
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" (click)="AddCardModal('none')">
                <span aria-hidden="true ">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <h3 class="add_card_title">{{ "Add Payment Card" | translate }}</h3>
              <div class="card_card_form">
                <div class="nsflex">
                  <div class="add_card_input_itms mr-2">
                    <label>{{ "First name" | translate }}</label>
                    <input
                      class="form-control"
                      (paste)="(false)"
                      type="text"
                      maxlength="50"
                      (change)="onChangeFirst($event.target.value)"
                      (keypress)="onKeyPress($event)"
                      [formControl]="cardOwnerFirstName"
                      placeholder="First name"
                    />
                    <mat-error *ngIf="cardSubmitted && cardOwnerFirstName.hasError('required')">
                      {{ "First name is required" | translate }}.</mat-error
                    >
                    <mat-error *ngIf="cardSubmitted && !cardOwnerFirstName.hasError('required') && validateFirstArr.includes(true)">
                      {{ "Please enter valid first name" | translate }}.</mat-error
                    >
                  </div>
                  <div class="add_card_input_itms mr-2">
                    <label>{{ "Last name" | translate }}</label>
                    <input
                      class="form-control"
                      (paste)="(false)"
                      maxlength="50"
                      placeholder="Last name"
                      (change)="onChangeSecond($event.target.value)"
                      (keypress)="onKeyPress($event)"
                      type="text"
                      [formControl]="cardOwnerLastName"
                    />
                    <mat-error *ngIf="cardSubmitted && cardOwnerLastName.hasError('required')">
                      {{ "Last name is
                      required" | translate }}.</mat-error
                    >
                    <mat-error *ngIf="cardSubmitted && cardOwnerLastName.value !== '' && validateFirstArr.includes(true)">
                      {{ "Please enter valid last name" | translate }}.</mat-error
                    >
                  </div>
                </div>
                <div class="add_card_input_itms" style="width: 100%">
                  <form [formGroup]="cardNumberGroup">
                    <label>{{ "Card Number" | translate }}</label>
                    <input
                      class="form-control"
                      [formControl]="getCardNumberControl()"
                      (copy)="(false)"
                      (paste)="(false)"
                      [textMask]="{ mask: cardMaskFunction, guide: false, showMask: true }"
                      placeholder="**** **** **** ****"
                    />

                    <div *ngIf="cardSubmitted">
                      <p
                        style="color: red"
                        *ngIf="cardNumberGroup.status === 'INVALID' && !cardNumberGroup?.controls['cardNumber'].hasError('required')"
                      >
                        {{ "Card number is invalid" | translate }}.
                      </p>
                      <!-- <p style="color: green;" *ngIf="cardNumberGroup.status === 'VALID'">
                                                Card number is valid.</p> -->
                      <p style="color: red" *ngIf="cardNumberGroup?.controls['cardNumber'].hasError('required')">
                        {{ "Card number is required" | translate }}.
                      </p>
                    </div>
                  </form>
                </div>
                <div class="nsflex">
                  <div class="add_card_input_itms months mr-2">
                    <label>{{ "Month" | translate }}</label>
                    <select class="form-control" [formControl]="expiryMonth">
                      <option [value]="null" selected disabled>Month</option>
                      <option value="01">01</option>
                      <option value="02">02</option>
                      <option value="03">03</option>
                      <option value="04">04</option>
                      <option value="05">05</option>
                      <option value="06">06</option>
                      <option value="07">07</option>
                      <option value="08">08</option>
                      <option value="09">09</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                    <mat-error *ngIf="cardSubmitted && expiryMonth.hasError('required')"> {{ "Expiry month is required" | translate }}.</mat-error>
                  </div>
                  <div class="add_card_input_itms mr-2">
                    <label>{{ "Year" | translate }}</label>
                    <select class="form-control" [formControl]="expiryYear">
                      <option [value]="null" selected disabled>{{ "Year" | translate }}</option>
                      <option [value]="y" *ngFor="let y of expiryYears; let i = index">{{ y }}</option>
                    </select>
                    <mat-error *ngIf="cardSubmitted && expiryYear.hasError('required')"> {{ "Expiry year is required" | translate }}.</mat-error>
                  </div>
                  <div class="add_card_input_itms">
                    <label>{{ "CCV/CVV" | translate }}</label>
                    <input
                      [formControl]="cvv"
                      (copy)="(false)"
                      (keypress)="keypresshandler($event)"
                      (paste)="(false)"
                      placeholder="{{ 'CCV/CVV' | translate }}"
                      class="form-control"
                      type="password"
                      onKeyPress="if(this.value.length==4) return false;"
                      placeholder=""
                    />
                    <mat-error *ngIf="cardSubmitted && cvv.hasError('required')"> {{ "CCV/CVV is required" | translate }}.</mat-error>
                  </div>
                </div>
              </div>
            </div>
            <button class="add_card" style="cursor: pointer" (click)="addDebitCard()">{{ "Save" | translate }}</button>
          </div>
        </div>
      </div>

      <!----start promo card popup-->

      <div class="request_ride_inner part2" *ngIf="nextPopup == 20">
        <div class="car_using_main">
          <div class="nsfavret">
            <div class="fav-header">
              <div class="facback" style="cursor: pointer" (click)="continue(0)"><a>Back</a></div>
              <div class="headerfav">
                <h5>My Favourites</h5>
              </div>
              <div class="addfav">
                <h6 (click)="createFavAddForm(); continue(21)" style="cursor: pointer">Add Favourite</h6>
              </div>
            </div>
            <div class="myy-favret">
              <ul>
                <li style="cursor: pointer" (click)="patchFavAddress(0, add)" *ngFor="let add of favAddData">
                  <div class="favret-main">
                    <div class="favret-left">
                      <i aria-hidden="true" class="fa fa-home" *ngIf="add?.name.toLowerCase() === 'home'"></i>
                      <i aria-hidden="true" class="fa fa-shopping-bag" *ngIf="add?.name.toLowerCase() === 'work'"></i>
                      <i
                        aria-hidden="true"
                        class="fa fa-map-marker"
                        *ngIf="add?.name.toLowerCase() !== 'work' && add?.name.toLowerCase() !== 'home'"
                      ></i>
                    </div>

                    <div class="favret-right">
                      <h3
                        style="font-weight: 500"
                        *ngIf="add?.name.toLowerCase() === 'work' || add?.name.toLowerCase() === 'home' || add?.name.toLowerCase() === 'office'"
                      >
                        {{ add?.name | titlecase }}
                      </h3>
                      <h3
                        style="font-weight: 500"
                        *ngIf="add?.name.toLowerCase() !== 'work' && add?.name.toLowerCase() !== 'home' && add?.name.toLowerCase() !== 'office'"
                      >
                        {{ add?.type | titlecase }}
                      </h3>
                      <p>{{ add?.address }}</p>
                    </div>
                    <!-- <figure class="manage_addres_edit" style="cursor: pointer" (click)="editfavAdd(add)">
                      <img src="assets/uimages/address_edit.png" alt="" />
                    </figure> -->
                  </div>
                </li>
              </ul>
            </div>
            <h6 style="text-align: center; color: black; font-size: 16px" *ngIf="favAddData?.length === 0">Data not found!</h6>
            <nav aria-label="..." class="pagination" *ngIf="favAddData?.length !== 0" style="float: right">
              <ul class="pagination justify-content-end mb-0">
                <li class="{{ previousBtnEnabledA ? 'page-item' : 'page-item disabled' }}">
                  <a class="page-link" href="javascript:void(0)" (click)="onPrevPageA()">
                    <i></i>
                    <span>{{ "Previous" | translate }}</span>
                  </a>
                </li>
                <li
                  *ngFor="let a of [].constructor(totalPagesA); let i = index"
                  class="{{ currentPageA === i + 1 ? 'page-item active' : 'page-item' }}"
                >
                  <a
                    class="page-link"
                    *ngIf="currentPageA < i + 1 ? i + 1 - currentPageA < 3 : currentPageA - (i + 1) < 3"
                    href="javascript:void(0)"
                    (click)="onChnagePageA(i + 1)"
                    >{{ i + 1 }}</a
                  >
                </li>
                <li class="{{ nextBtnEnabledA ? 'page-item' : 'page-item disabled' }}">
                  <a class="page-link" href="javascript:void(0)" (click)="onNextPageA()">
                    <i></i>
                    <span>{{ "Next" | translate }}</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div class="request_ride_inner part2" *ngIf="nextPopup == 21">
        <div class="car_using_main">
          <div class="nsfavret">
            <div class="fav-header">
              <div class="facback" style="cursor: pointer" (click)="continue(20)"><a>Back</a></div>
              <div class="headerfav">
                <h5>{{ favAddPayload?.addressId ? "Edit" : "Add" }} Favourites</h5>
              </div>
              <div class="addfav">
                <!-- <h6 (click)="createFavAddForm(); continue(21)" style="cursor: pointer">Add Favourite</h6> -->
              </div>
            </div>
            <div class="myy-favret">
              <ul class="formn_group_main">
                <li class="remove-brdr">
                  <div class="form-group" style="display: grid">
                    <mat-form-field floatLabel="never">
                      <div class="addimgbg">
                        <figure>
                          <img src="assets/icons/street21.jpg" />
                        </figure>
                      </div>
                      <input
                        type="text"
                        matInput
                        class="form-control"
                        [(ngModel)]="favAddPayload.name"
                        placeholder="{{ 'Place name' | translate }}"
                      />
                    </mat-form-field>
                    <mat-error *ngIf="favAddressSubmitted && favAddPayload.name === ''">Place name is required.</mat-error>
                  </div>
                </li>
                <li class="mt-4 remove-brd" style="border-bottom: none">
                  <div class="form-group" style="display: grid">
                    <mat-form-field floatLabel="never">
                      <div class="addimgbg">
                        <figure>
                          <img src="assets/icons/pointer21.jpg" />
                        </figure>
                      </div>
                      <input
                        matInput
                        placeholder="{{ 'Search for your address' | translate }}"
                        autocorrect="off"
                        autocapitalize="off"
                        spellcheck="off"
                        type="text"
                        class="form-control"
                        ngx-google-places-autocomplete
                        #placesRef="ngx-places"
                        (keyup)="onKeyUp($event.target.value)"
                        (change)="onAddChanged($event.target.value)"
                        (onAddressChange)="handleLocationChnage($event)"
                        [(ngModel)]="favAddPayload.address"
                      />
                      <div class="addimgbg sec">
                        <figure>
                          <img src="assets/icons/map21.jpg" />
                        </figure>
                      </div>
                    </mat-form-field>
                    <mat-error *ngIf="favAddressSubmitted && favAddPayload.address === ''">Address is required.</mat-error>
                  </div>
                </li>
              </ul>
              <button class="btn btn-primary mt-4" (click)="submitFavAdd()">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade promo_page" id="add_promo" [ngStyle]="{ display: loyalityModal, opacity: 1 }">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="loyalityModals('none')">
          <span aria-hidden="true ">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h3 class="add_card_title">{{ "Loyality Money" | translate }}</h3>
        <div class="loyality-main">
          <ul *ngIf="loyalityData?.length > 0">
            <li *ngFor="let coupon of loyalityData">
              <div class="loyal-minnre">
                <div class="loyal-bg">
                  <img class="loyal-bg" [src]="baseUrl + coupon?.couponIcon" />
                </div>
                <div class="loyality-inner-para">
                  <h5 [title]="coupon?.companyName">{{ coupon?.companyName }}</h5>
                  <!-- <p>Expiry: {{coupon?.expiryDate | date: 'M/d/yy'}}</p> -->
                  <p>{{ coupon?.coupon }}</p>
                  <div
                    title="{{
                      coupon?.couponType === 1 || coupon?.couponType === 3
                        ? '$' + ((coupon?.couponValue).toFixed(2) | number: '1.0':'en-US')
                        : ((coupon?.couponValue).toFixed(2) | number: '1.0':'en-US')
                    }} {{ coupon?.couponType === 2 ? '%' : 'Points' }}"
                    class="sp"
                  >
                    <p>
                      {{
                        coupon?.couponType === 1 || coupon?.couponType === 3
                          ? ((coupon?.couponValue).toFixed(2) | currency: "USD")
                          : ((coupon?.couponValue).toFixed(2) | currency: "USD")
                      }}
                      {{ coupon?.couponType === 2 ? "%" : "Points" }}
                    </p>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary" (click)="redeemCoupon(coupon)">{{ "Redeem" | translate }}</button>
            </li>
          </ul>
          <h3 *ngIf="loyalityData?.length === 0">{{ "You don't have any Loyality Money now" | translate }}.</h3>
        </div>
      </div>
    </div>
  </div>
</div>
<!----end promo card popup-->
<!-- Modal -->
<div class="modal fade promo_page" id="add_moniter" [ngStyle]="{ display: monitorRideModal, opacity: 1 }">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button " class="close" (click)="hideMonitorModal()">
          <span><i class="fa fa-times" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="monitorForm">
          <h3 class="add_card_title">{{ "Monitor your ride" | translate }}</h3>
          <p class="moniter_para">
            {{
              "Please, add the name(s) and mobile number(s) or click on other to share your current location with friend or relatives" | translate
            }}.
          </p>
          <div class="monitor_outer">
            <input type="text" class="from-control moniter_input" formControlName="name" placeholder="{{ 'Name' | translate }}" />
            <mat-error class="monitor_err sec" *ngIf="monitorSubmit && monitorForm?.controls['name'].hasError('required')">
              {{ "Name is required" | translate }}.
            </mat-error>
            <ngx-intl-tel-input
              [inputId]="'adsasd'"
              [cssClass]="'custom'"
              [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="false"
              [enablePlaceholder]="true"
              [searchCountryFlag]="true"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
              [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.Canada"
              [maxLength]="15"
              [tooltipField]="TooltipLabel.Name"
              [phoneValidation]="true"
              [separateDialCode]="separateDialCode"
              name="phone"
              formControlName="phoneNo"
            >
            </ngx-intl-tel-input>
            <mat-error class="monitor_err" *ngIf="monitorSubmit && monitorForm?.controls['phoneNo'].hasError('required')">
              {{ "Phone number is required" | translate }}.</mat-error
            >
            <mat-error class="monitor_err" *ngIf="monitorSubmit && monitorForm?.controls['phoneNo'].hasError('validatePhoneNumber')">
              {{ "Enter valid number" | translate }}.</mat-error
            >
          </div>
          <div class="share_btn" style="cursor: pointer">
            <button (click)="monitorRide()" style="cursor: pointer">{{ "Share" | translate }}</button>
          </div>
          <p class="share-other">{{ "Or you can share it with other" | translate }}.</p>
          <div class="share-btn">
            <button
              style="cursor: pointer"
              mat-icon-button
              shareButton="facebook"
              #fbBtn="shareButton"
              [style.color]="fbBtn?.color"
              [url]="'https://demo.appdukaan.com/u2go/web/tracking/' + bookingId"
            >
              <i class="fa fa-facebook-square" aria-hidden="true" style="font-size: 40px"></i></button
            ><button
              style="cursor: pointer"
              mat-icon-button
              shareButton="twitter"
              #twitterBtn="shareButton"
              [style.color]="twitterBtn?.color"
              [url]="'https://demo.appdukaan.com/u2go/web/tracking/' + bookingId"
            >
              <i class="fa fa-twitter" aria-hidden="true" style="font-size: 40px"></i></button
            ><button
              style="cursor: pointer"
              mat-icon-button
              shareButton="linkedin"
              #linkedinBtn="shareButton"
              [style.color]="linkedinBtn?.color"
              [url]="'https://demo.appdukaan.com/u2go/web/tracking/' + bookingId"
            >
              <i class="fa fa-linkedin" aria-hidden="true" style="font-size: 40px"></i></button
            ><button
              style="cursor: pointer"
              mat-icon-button
              shareButton="whatsapp"
              #whatsappBtn="shareButton"
              [style.color]="whatsappBtn?.color"
              [url]="'https://demo.appdukaan.com/u2go/web/tracking/' + bookingId"
            >
              <i class="fa fa-whatsapp" aria-hidden="true" style="font-size: 40px"></i></button
            ><button
              style="cursor: pointer"
              mat-icon-button
              shareButton="copy"
              #copyBtn="shareButton"
              (click)="showClipBoardMessage()"
              [style.color]="copyBtn?.color"
              [url]="'https://demo.appdukaan.com/u2go/web/tracking/' + bookingId"
            >
              <i class="fa fa-copy" aria-hidden="true" style="font-size: 40px"></i></button
            ><button
              style="cursor: pointer"
              mat-icon-button
              shareButton="print"
              #printBtn="shareButton"
              [style.color]="printBtn?.color"
              [url]="'https://demo.appdukaan.com/u2go/web/tracking/' + bookingId"
            >
              <i class="fa fa-print" aria-hidden="true" style="font-size: 40px"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!----end promo card popup-->

<div id="addpayment" class="modal fade" role="dialog" [ngStyle]="{ display: addWalletMoneyModal, opacity: 1 }">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button " class="close" (click)="addWalletModal('none')">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
      <div class="modal-body">
        <h4>{{ "Wallet Add Money" | translate }}</h4>
        <div class="seachbar-payment">
          <mat-form-field [floatLabel]="'never'">
            <input
              class="form-control"
              (keypress)="keypresshandler($event)"
              min="1"
              [formControl]="addWalletAmount"
              matInput
              type="text"
              placeholder="Amount"
            />
          </mat-form-field>
          <mat-error *ngIf="walletMoneySubmitted && addWalletAmount.hasError('required')"> {{ "Wallet amount is required" | translate }}.</mat-error>
        </div>
        <ul class="payment-lt">
          <li>
            <div class="list_inner">
              <!-- <mat-checkbox [checked]="isWalletSelected" (change)="onWalletSelected($event.checked)">
                                Pay
                                via APPTUNIX Wallet</mat-checkbox>
                            {{allSavedCardData?.walletAmount}} -->
              <mat-radio-group aria-label="Select an option">
                <mat-radio-button
                  (change)="onWalletCardChange(card)"
                  [checked]="card?.isSelected"
                  [value]="i + 1"
                  *ngFor="let card of allSavedCard; let i = index"
                >
                  <div class="card_detailcs">
                    <div>
                      <h3>
                        {{ card?.cardNumber }}<span>{{ card?.brand }} - {{ card?.expiryDate.slice(0, 2) }}/{{ card?.expiryDate.slice(2) }}</span>
                      </h3>
                    </div>
                    <div class="dlt-radio">
                      <h3 *ngIf="card?.isDefault">
                        <span class="text-success" style="margin-left: 68px !important">{{ "PRIMARY CARD" | translate }}</span>
                      </h3>
                    </div>
                  </div>
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </li>
        </ul>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="addWalletMoney()">{{ "Proceed" | translate }}</button>
      </div>
    </div>
  </div>
</div>

<div id="addpayment" class="modal fade" role="dialog" [ngStyle]="{ display: cancelDialog, opacity: 1 }">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content" style="width: 361px">
      <!-- <div class="modal-header">
        <button type="button " class="close" (click)="cancelDialog = 'none'">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div> -->
      <div class="modal-body" style="width: 361px">
        <h2 style="text-align: center">{{ cancelationMessage }}</h2>
        <p style="padding-top: 5px">
          <span>{{ "Driver canceled ride at address" | translate }}:- </span>{{ canceledAddress }}
        </p>
        <p>
          <span>{{ "Reason" | translate }}:- </span>{{ cancelReasons }}
        </p>
      </div>
      <div class="modal-footer" style="border-top: unset">
        <button type="button" class="btn btn-primary" (click)="cancelDialog = 'none'">{{ "Ok" | translate }}</button>
      </div>
    </div>
  </div>
</div>

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpsService } from '../services/https/https.service';
import { CommonService } from '../services/common/common.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-ubusinesspartners',
  templateUrl: './ubusinesspartners.component.html',
  styleUrls: ['./ubusinesspartners.component.scss']
})
export class UbusinesspartnersComponent implements OnInit, AfterViewInit {

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Canada, CountryISO.India];
  address = {
    lat: 0,
    lng: 0
  }
  partnerForm: FormGroup;
  fullNameRequired: boolean = false;
  submitted = false;
  notifier = new Subject();
  geoCoder: google.maps.Geocoder;
  charCodeArr: any = [96, 49, 50, 51, 52, 53, 54, 55, 56, 57, 48, 45, 61, 91, 93, 92, 59, 44, 46, 47, 126, 33, 64, 35, 36, 37, 94, 38, 42, 40, 41, 95, 43, 123, 125, 124, 58, 34, 60, 62, 63];
  validateFirstArr: any[];
  validateSecondArr: any[];

  constructor(
    private fb: FormBuilder,
    private httpService: HttpsService,
    private commonService: CommonService,
    private mapsAPILoader: MapsAPILoader,
    private router: Router,
    public translate: TranslateService
  ) {

  }

  ngOnInit(): void {
    this.partnerForm = this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null, [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
      phoneNo: [null, Validators.required],
      comment: [null, Validators.required],
    });
    // this.partnerForm.get('fullName').valueChanges.subscribe((res: any) => {
    //   if (res) {
    //     var fullName = res.split(' ');
    //     if (fullName.length < 2 || fullName[fullName.length - 1] === "") {
    //       this.fullNameRequired = true;
    //     } else {
    //       this.fullNameRequired = false;
    //     }
    //   }
    // });
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
    });
  }

  ngAfterViewInit() {
    var ele = document.getElementById("bp");
    localStorage.bpSection !== undefined && ele.click();localStorage.removeItem('bpSection');
  }

  scrollToFormSection() {
    document.getElementById("partner").scrollIntoView();
  }

  submit() {
    this.submitted = true;
    if (this.partnerForm.valid && !this.validateFirstArr.includes(true) && !this.validateSecondArr.includes(true)) {
      var codeLength = this.partnerForm.value.phoneNo.dialCode.length;
      var param = {
        "firstName": this.partnerForm.value.firstName.trim(),
        "lastName": this.partnerForm.value.lastName.trim(),
        "email": this.partnerForm.value.email.toLowerCase(),
        "phoneNo": this.partnerForm.value.phoneNo.e164Number.slice(codeLength),
        "comment": this.partnerForm.value.comment.trim(),
        "countryCode": this.partnerForm.value.phoneNo.dialCode,
        // "isPreLaunch": true
      }
      this.httpService.httpPostUser('becomeBusinessPartner', param).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
        if (res.statusCode === 200) {
          this.commonService.OpenNotificationModal('success', 'top-end', 'Thank you for your inquiry. We will get back to you shortly.');
          this.partnerForm.reset();
          this.submitted = false;
        }
      })
    }
  }


  onKeyPress(event) {
    if (event.charCode !== 32 && event.charCode !== 39) {
      return (event.charCode > 64 &&
        event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)
    }
  }

  onChangeFirst(value) {
    this.validateFirstArr = [];
    for (let i = 0; i < value.length; i++) {
      var charCode = value.charCodeAt(i);
      this.validateFirstArr.push(this.charCodeArr.includes(charCode));
    }
  }

  onChangeSecond(value) {
    this.validateSecondArr = [];
    for (let i = 0; i < value.length; i++) {
      var charCode = value.charCodeAt(i);
      this.validateSecondArr.push(this.charCodeArr.includes(charCode));
    }
  }

}

import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ParentService } from 'src/app/services/parent/parent.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-advertise',
  templateUrl: './advertise.component.html',
  styleUrls: ['./advertise.component.scss']
})
export class AdvertiseComponent implements OnInit, OnDestroy {
  id: NodeJS.Timeout;
  baseUrl = environment.imageUrl

  constructor(
    public dialogRef: MatDialogRef<AdvertiseComponent>,
    public parentService: ParentService
  ) { }

  ngOnInit(): void {
    // var time = (this.parentService.advertisementData.duration)*1000;
    // this.id = setInterval(() => {
    //   this.close(); 
    // }, time);
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.id) {
      clearInterval(this.id);
    }
    this.close();
  }

}

import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialogRef } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../services/common/common.service';
import { TranslateService } from "@ngx-translate/core";
declare var $: any;

@Component({
  selector: "app-ridercongratulations",
  templateUrl: "./ridercongratulations.component.html",
  styleUrls: ["./ridercongratulations.component.scss"],
})
export class RidercongratulationsComponent implements OnInit, OnDestroy {
  id: NodeJS.Timeout;
  isHide: boolean = true;
  
  constructor(private router: Router,
    public dialogRef: MatDialogRef<RidercongratulationsComponent>,
    private ngxService: NgxUiLoaderService,
    public commonService: CommonService,
    public translate: TranslateService) {}

  ngOnInit(): void {
    // this.id = setTimeout(() => {
    //   this.dialogRef.close();
    // }, 10000);
    if(this.commonService.isThisCongrats) {
      this.ngxService.start()
      $('#main_img').on('load', function(){
        this.ngxService.stop();
        this.isHide = false;
      });
    }
    
  }

  goTouhomepage() {
    this.dialogRef.close();
    // this.router.navigate(["/riderequest"]);
  }

  ngOnDestroy() {
    if (this.id) {
      clearInterval(this.id);
    }
  }
}

<div class="profile_wrap">
  <div class="signupinner">
    <div class="conta_iner">
      <div class="flex">
        <div class="haeder_left">
          <figure class="logo_cm" (click)="goTouhomepage()">
            <img src="assets/uimages/logo.png " />
          </figure>
        </div>
        <div class="multiple_content">
          <div class="lm">
            <div class="language-select" (click)="showLang = showLang ? false : true">
              <div class="sel-bar" *ngIf="languageService?.selectedLang">
                <figure><img [src]="baseUrl + languageService?.selectedLang?.logo" />{{ languageService?.selectedLang?.code?.toUpperCase() }}</figure>
              </div>
              <p *ngIf="!languageService?.selectedLang">Select Language</p>
              <div class="ndpicon">
                <i *ngIf="!showLang" class="fa fa-caret-down" aria-hidden="true"></i>
                <i *ngIf="showLang" class="fa fa-caret-up" aria-hidden="true"></i>
              </div>
            </div>
            <div class="dp-lang" *ngIf="showLang">
              <figure *ngFor="let language of languageService?.allLanguages" (click)="selectLang(language)">
                <img [src]="baseUrl + language?.logo" />{{ language?.code }}
              </figure>
            </div>
          </div>
          <div class="back_home hmbtn">
            <button class="driverap_btn" style="cursor: pointer" (click)="goTouhomepage()">
              {{ "Back To Home" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="prfile riderdetail">
    <div class="conta_iner">
      <div class="profile_main">
        <mat-horizontal-stepper [linear]="isLinear" #stepper class="mg-a">
          <mat-step class="" [stepControl]="firstFormGroup" [editable]="editable">
            <form [formGroup]="firstFormGroup">
              <ng-template matStepLabel>{{ "Personal details" | translate }}</ng-template>
              <!-- <mat-form-field>
                        <mat-label>Name</mat-label>
                        <input matInput placeholder="Last name, First name " formControlName="firstCtrl " required />
                    </mat-form-field> -->
              <div class="deatil_form_outer">
                <div class="detail_form">
                  <div class="driver_profile_img">
                    <div class="pr_man">
                      <figure *ngIf="!fileDataUrl">
                        <img src="assets/images/user.png " />
                      </figure>
                      <figure *ngIf="fileDataUrl">
                        <img [src]="baseUrl + fileDataUrl" />
                      </figure>
                    </div>
                    <div class="profile_camera">
                      <figure>
                        <img src="assets/uimages/edit.svg" (click)="fileInput.click()" />
                      </figure>
                      <input
                        class="upload_file"
                        formControlName="image"
                        #fileUpload
                        (change)="onFileUpload($event)"
                        id="file-input"
                        #fileInput
                        type="file"
                      />
                    </div>
                  </div>
                  <div class="detail_form_items">
                    <mat-form-field [floatLabel]="'never'">
                      <label>{{ "First name" | translate }}</label>
                      <input
                        class="form-control"
                        formControlName="firstName"
                        matInput
                        type="text"
                        maxlength="50"
                        (change)="onChangeFirst($event.target.value)"
                        (keypress)="onKeyPress($event)"
                        placeholder="{{ 'First name' | translate }} "
                        name="name "
                      />
                    </mat-form-field>
                    <mat-error *ngIf="submitted && firstFormGroup.controls['firstName'].hasError('required')"
                      >{{ "First name is required" | translate }}.</mat-error
                    >
                    <mat-error *ngIf="submitted && validateFirstArr.includes(true) && !firstFormGroup.controls['firstName'].hasError('required')"
                      >{{ "Please enter valid first name" | translate }}.</mat-error
                    >
                  </div>
                  <div class="detail_form_items">
                    <mat-form-field [floatLabel]="'never'">
                      <label>{{ "Last name" | translate }}</label>
                      <input
                        class="form-control"
                        maxlength="50"
                        (change)="onChangeSecond($event.target.value)"
                        (keypress)="onKeyPress($event)"
                        formControlName="lastName"
                        matInput
                        type="text "
                        placeholder="{{ 'Last name' | translate }} "
                        name="name "
                      />
                    </mat-form-field>
                    <mat-error *ngIf="submitted && firstFormGroup.controls['lastName'].hasError('required')"
                      >{{ "Last name is required" | translate }}.</mat-error
                    >
                    <mat-error *ngIf="submitted && validateSecondArr.includes(true) && !firstFormGroup.controls['lastName'].hasError('required')"
                      >{{ "Please enter valid last name" | translate }}.</mat-error
                    >
                  </div>
                  <div class="detail_form_items">
                    <mat-form-field [floatLabel]="'never'">
                      <label>{{ "Username" | translate }}</label>
                      <input
                        class="form-control"
                        formControlName="userName"
                        matInput
                        type="text "
                        placeholder="{{ 'Username' | translate }} "
                        name="name "
                      />
                      <mat-error>{{ "Username is required" | translate }}.</mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="userNameAlreadyExist">{{ "Username already exist" | translate }}.</mat-error>
                  </div>
                  <div class="detail_form_items" *ngIf="userData?.email !== ''">
                    <mat-form-field [floatLabel]="'never'">
                      <label>{{ "Email address" | translate }}</label>
                      <input
                        class="form-control"
                        formControlName="email"
                        matInput
                        type="email"
                        placeholder="{{ 'Email address' | translate }}"
                        name="name"
                        readonly
                      />
                      <mat-error *ngIf="submitted && firstFormGroup.controls['email'].hasError('required')">
                        {{ "Email address is required" | translate }}.
                      </mat-error>
                      <mat-error
                        *ngIf="
                          submitted && !firstFormGroup.controls['email'].hasError('required') && firstFormGroup.controls['email'].hasError('pattern')
                        "
                      >
                        {{ "Please Enter Valid Email" | translate }}.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items" *ngIf="userData?.email === ''">
                    <mat-form-field [floatLabel]="'never'">
                      <label>{{ "Email address" | translate }}</label>
                      <input
                        class="form-control"
                        formControlName="email"
                        matInput
                        type="email"
                        placeholder="{{ 'Email address' | translate }}"
                        name="name"
                      />
                      <mat-error *ngIf="submitted && firstFormGroup.controls['email'].hasError('required')">
                        {{ "Email address is required" | translate }}.
                      </mat-error>
                      <mat-error
                        *ngIf="
                          submitted && !firstFormGroup.controls['email'].hasError('required') && firstFormGroup.controls['email'].hasError('pattern')
                        "
                      >
                        {{ "Please Enter Valid Email" | translate }}.
                      </mat-error>
                    </mat-form-field>
                    <mat-error
                      *ngIf="
                        submitted &&
                        isEmailExist &&
                        !firstFormGroup.controls['email'].hasError('email') &&
                        !firstFormGroup.controls['email'].hasError('required')
                      "
                    >
                      {{ "Email address already exist" | translate }}.</mat-error
                    >
                  </div>
                  <div class="detail_form_items">
                    <mat-form-field [floatLabel]="'never'">
                      <label>{{ "Company name" | translate }}</label>
                      <input
                        class="form-control"
                        formControlName="companyName"
                        matInput
                        type="text "
                        placeholder="{{ 'Company name' | translate }}"
                        name="name "
                      />
                      <mat-error>{{ "Company name is required" | translate }}.</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items" *ngIf="userData?.phoneNo !== ''">
                    <mat-form-field [floatLabel]="'never'">
                      <label>{{ "Phone number" | translate }}</label>
                      <input class="form-control" formControlName="phone" matInput type="text " placeholder="Phone number" name="name " readonly />
                      <mat-error>{{ "Phone number is required" | translate }}.</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items" *ngIf="userData?.phoneNo === ''">
                    <label>{{ "Phone number" | translate }}</label>
                    <ngx-intl-tel-input
                      class="form-control"
                      [inputId]="'sprt'"
                      [cssClass]="'custom spprt'"
                      [preferredCountries]="preferredCountries"
                      [enablePlaceholder]="true"
                      [searchCountryFlag]="true"
                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                      [selectFirstCountry]="false"
                      [enableAutoCountrySelect]="true"
                      [selectedCountryISO]="CountryISO.Canada"
                      [maxLength]="15"
                      [tooltipField]="TooltipLabel.Name"
                      [phoneValidation]="true"
                      [separateDialCode]="separateDialCode"
                      name="phone"
                      formControlName="phone"
                    >
                    </ngx-intl-tel-input>
                    <mat-error *ngIf="submitted && firstFormGroup.controls['phone'].hasError('required')">
                      {{ "Phone number is required" | translate }}.</mat-error
                    >
                    <mat-error *ngIf="submitted && firstFormGroup.controls['phone'].hasError('validatePhoneNumber')">
                      {{ "Please enter valid phone number" | translate }}.</mat-error
                    >
                    <mat-error
                      *ngIf="
                        submitted &&
                        isPhoneExist &&
                        !firstFormGroup.controls['phone'].hasError('validatePhoneNumber') &&
                        !firstFormGroup.controls['phone'].hasError('required')
                      "
                    >
                      {{ "Phone number already exist" | translate }}.</mat-error
                    >
                  </div>
                </div>
              </div>

              <div class="driver_nex-btn mb-4">
                <button class="btn btn-primary" mat-button (click)="updateProfile('1', 'firstFormGroup', 'step1')">
                  {{ "Next" | translate }}
                </button>
              </div>

              <!-----start fileds-->

              <!-----End fileds-->
            </form>
          </mat-step>
          <mat-step class="" [stepControl]="secondFormGroup" [editable]="editable">
            <form [formGroup]="secondFormGroup">
              <ng-template matStepLabel>Address</ng-template>
              <div class="deatil_form_outer">
                <div class="detail_form">
                  <div class="detail_form_items">
                    <mat-form-field [floatLabel]="'never'">
                      <label class="algn-lft">{{ "Street no. and name" | translate }}.</label>
                      <input
                        class="form-control"
                        formControlName="street"
                        matInput
                        type="text "
                        placeholder="{{ 'Street no. and name' | translate }}"
                        name="name "
                      />
                    </mat-form-field>
                    <mat-error *ngIf="submitted && secondFormGroup.controls['street'].hasError('required')"
                      >{{ "Street no. and name" | translate }} {{ "are required" | translate }}.</mat-error
                    >
                  </div>
                  <div class="detail_form_items">
                    <mat-form-field [floatLabel]="'never'">
                      <label class="algn-lft">{{ "Additional details (unit #, name of business, etc)" | translate }}.</label>
                      <input
                        class="form-control"
                        formControlName="localAddress"
                        matInput
                        type="text "
                        placeholder="{{ 'Additional details (unit #, name of business, etc)' | translate }}"
                        name="name"
                      />
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items">
                    <mat-form-field [floatLabel]="'never'">
                      <label class="algn-lft">{{ "City, state/province and country" | translate }}.</label>
                      <input
                        matInput
                        placeholder="{{ 'Search for your City, state/province and country' | translate }}"
                        autocorrect="off"
                        autocapitalize="off"
                        spellcheck="off"
                        type="text"
                        class="form-control"
                        ngx-google-places-autocomplete
                        #placesRef="ngx-places"
                        (keyup)="onKeyUp($event.target.value)"
                        (change)="onAddChanged($event.target.value)"
                        (onAddressChange)="handleLocationChnage($event)"
                        formControlName="location"
                      />
                    </mat-form-field>
                    <mat-error *ngIf="submitted && secondFormGroup.controls['location'].hasError('required')"
                      >{{ "City, state/province and country" | translate }} {{ "are required" | translate }}.</mat-error
                    >
                  </div>
                  <div class="detail_form_items">
                    <mat-form-field [floatLabel]="'never'">
                      <label class="algn-lft">{{ "Postal/Zip Code" | translate }}.</label>
                      <input
                        class="form-control"
                        formControlName="zipCode"
                        matInput
                        type="text "
                        placeholder="{{ 'Postal/Zip Code' | translate }}"
                        name="name"
                      />
                    </mat-form-field>
                    <mat-error *ngIf="submitted && secondFormGroup.controls['zipCode'].hasError('required')"
                      >{{ "Postal/Zip Code" | translate }} {{ "is required" | translate }}.</mat-error
                    >
                  </div>
                </div>
                <div class="agr agr--rider-detail mr-setup">
                  <mat-checkbox formControlName="agree"
                    >{{ "I agree to the APPTUNIX" | translate
                    }}<a class="ml-0 mr-0" style="cursor: default"
                      ><sup>{{ "TM" | translate }}</sup></a
                    >
                  </mat-checkbox>

                  <a style="cursor: pointer" (click)="goToCmsPages('/cms/terms-of-service')">{{ "Terms of Service" | translate }}</a
                  >, <a style="cursor: pointer" (click)="goToCmsPages('/cms/privacy-policy')"> {{ "Privacy Policy" | translate }}</a>
                  {{ "and" | translate }}
                  <a style="cursor: pointer" (click)="goToCmsPages('/cms/rider-contracts')"> {{ "Rider Contracts" | translate }}.</a>
                </div>
                <mat-error *ngIf="submitted && secondFormGroup.controls['agree'].invalid"> {{ "Checkbox is required" | translate }}.</mat-error>
              </div>
              <div class="address_btn">
                <div class="driver_nex-btn">
                  <button class="btn btn-secoundry" mat-button (click)="backToPrevious()">
                    {{ "Back" | translate }}
                  </button>
                </div>
                <div class="driver_nex-btn">
                  <button class="btn btn-primary" mat-button matStepperNext (click)="updateProfile('2', 'secondFormGroup', 'step2')">
                    {{ "Next" | translate }}
                  </button>
                </div>
              </div>
            </form>
          </mat-step>
          <!------start driver info-->
          <mat-step [stepControl]="fourFormGroup" [editable]="editable">
            <form [formGroup]="fourFormGroup">
              <ng-template matStepLabel>{{ "Payment" | translate }}</ng-template>
              <h3 class="pymnt-msg">{{ "We will collect the payment information once we launch in your city" | translate }}.</h3>

              <div class="deatil_form_outer">
                <ul class="payment_last">
                  <li class="borderbtm">
                    <div class="list_inner checkbox_cu fr">
                      <mat-checkbox [disabled]="true">{{ "Pay with APPTUNIX wallet" | translate }} $300</mat-checkbox>
                    </div>
                  </li>
                  <li class="borderbtm">
                    <div class="list_inner sc checkbox_cu">
                      <mat-checkbox [disabled]="true">{{ "Pay With Paypal" | translate }}</mat-checkbox>
                    </div>
                  </li>
                  <li class="half secv">
                    <div class="not-allowed">
                      <!-- data-toggle="modal" data-target="#add_payment" -->
                      <a class="add-card">{{ "Add new card" | translate }}</a>
                    </div>
                  </li>
                  <!-- <li class="borderbtm radio_cu">
                                        <mat-radio-group aria-label="Select an option">
                                            <mat-radio-button value="1">
                                                <div class="card_detailcs">
                                                    <h3>
                                                        •••• •••• •••• 4561<span>Mastercard - 06/23</span>
                                                    </h3>
                                                </div>
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </li>
                                    <li class="radio_cu">
                                        <mat-radio-group aria-label="Select an option">
                                            <mat-radio-button value="1">
                                                <div class="card_detailcs">
                                                    <h3>
                                                        •••• •••• •••• 4561<span>Visacard - 06/23</span>
                                                    </h3>
                                                </div>
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </li> -->
                  <li>
                    <div class="redeem-promo-code">
                      <mat-form-field [floatLabel]="'never'">
                        <input
                          class="form-control"
                          matInput
                          type="text "
                          placeholder="{{ 'Redeem Loyalty points' | translate }}"
                          name="name"
                          readonly
                        />
                      </mat-form-field>
                      <figure>
                        <img src="assets/uimages/Shape.svg" alt="" />
                      </figure>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="address_btn">
                <div class="driver_nex-btn">
                  <button class="btn btn-secoundry" mat-button (click)="goToAddressTab(1)">
                    {{ "Back" | translate }}
                  </button>
                </div>
                <div class="driver_nex-btn">
                  <button class="btn btn-primary" mat-button matStepperNext (click)="goToridercongratulations()">
                    {{ "Done" | translate }}
                  </button>
                </div>
              </div>
            </form>
          </mat-step>
          <!------end driver info-->
          <!-----start vehicle detail-->
        </mat-horizontal-stepper>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="add_payment" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
      <div class="modal-body">
        <h3 class="add_card_title">Add a card</h3>
        <div class="card_card_form">
          <div class="add_card_input_itms">
            <input class="form-control" type="text" placeholder="Card holder name" />
          </div>
          <div class="add_card_input_itms">
            <input class="form-control" type="text" placeholder="XXX XXX XXX " />
          </div>
          <div class="add_card_input_itms">
            <select class="form-control">
              <option>Month</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
              <option>6</option>
              <option>7</option>
              <option>8</option>
            </select>
          </div>
          <div class="add_card_input_itms">
            <select class="form-control">
              <option>year</option>
              <option>2020</option>
              <option>2021</option>
              <option>2022</option>
              <option>2023</option>
              <option>2024</option>
            </select>
          </div>
          <div class="add_card_input_itms">
            <input class="form-control" type="text" placeholder="CVV" />
          </div>
        </div>
      </div>
      <div class="btn_outer">
        <button class="btn btn-primary">Save</button>
      </div>
    </div>
  </div>
</div>

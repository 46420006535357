import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { HttpsService } from '../../prelaunch/services/https/https.service';
import { CommonService } from '../../prelaunch/services/common/common.service';
import *  as  countryCodeData from '../../prelaunch/core/countryCode.json';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { takeUntil, take } from 'rxjs/operators';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { MustMatch } from '../../prelaunch/core/must-match.validator';
import { MapsAPILoader } from '@agm/core';
import { environment } from "src/environments/environment";
import Swal from 'sweetalert2';
import { TranslateService } from "@ngx-translate/core";
declare var $: any;

@Component({
  selector: 'app-pre-register',
  templateUrl: './pre-register.component.html',
  styleUrls: ['./pre-register.component.scss']
})
export class PreRegisterComponent implements OnInit {
  nextPopup: any = 1;
  selectedCountryCode: string;

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.Canada];
  signupForm: FormGroup
  submitted: boolean = false;
  isPhoneValid: boolean = true;
  email: any;
  phoneNo: string;
  verificationTypeForm: FormGroup;
  redirectTo = '/riderotppage';
  geoCoder: google.maps.Geocoder;
  fullAddress = { address: '', lat: 0, lng: 0 };
  latitude: number = 0;
  longitude: number = 0;
  charCodeArr: any = [96, 49, 50, 51, 52, 53, 54, 55, 56, 57, 48, 45, 61, 91, 93, 92, 59, 44, 46, 47, 126, 33, 64, 35, 36, 37, 94, 38, 42, 40, 41, 95, 43, 123, 125, 124, 58, 34, 60, 62, 63];
  validateFirstArr = [];
  validateSecondArr = [];
  driverVerificationForm: FormGroup;


  constructor(private router: Router,
    private httpService: HttpsService,
    private commponService: CommonService,
    private fb: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.signupForm = this.fb.group({
      phoneNo: ["", Validators.required],
      address: ["", Validators.required],
      city: ["", Validators.required],
      province: ["", Validators.required],
      country: ["", Validators.required],
      zipCode: ["", Validators.required],
      street: ["", Validators.required],
      dlNumber: ["", Validators.required],
      localAddress: [''],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      term: ["", Validators.requiredTrue],
      email: ["", [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]]
      // password: ["", [Validators.required, Validators.minLength(6)]],
      // confirmPassword: ["", [Validators.required, Validators.minLength(6)]],
    });
    this.verificationTypeForm = this.fb.group({
      verifyBy: ['phone', Validators.required]
    });
    this.driverVerificationForm = this.fb.group({
      password: ['password', [Validators.required, Validators.minLength(6)]]
    })
    this.verificationTypeForm.get('verifyBy').valueChanges.subscribe((res: any) => {
      if (res) {
        if (res === 'email') {
          this.redirectTo = '/verifyemail'
        } else {
          this.redirectTo = '/riderotppage';
        }
      }
    })
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      // this.initializeMapPosition();
    });
    this.getCurrentLocation();
  }

  ngAfterViewInit() {
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
      }, err => {
        console.log(err)
      });
    }
  }

  handleAddressChnage(address) {
    if (address.formatted_address) {
      console.log(address.formatted_address)
      var lat = (address.geometry.location.lat());
      var lng = (address.geometry.location.lng());
      this.fullAddress.lat = lat;
      this.fullAddress.lng = lng;
      // if (this.latitude == 0 && this.longitude == 0) {
        this.latitude = lat;
        this.longitude = lng;
      // }
      this.getPickupAddress(lat, lng, address.formatted_address);
    }
  }

  onKeyUp(value) {
    if (value === "") {
      this.fullAddress.lat = 0;
      this.fullAddress.lng = 0;
      this.signupForm.controls['address'].patchValue(null);
    }
  }

  onAddChanged(value) {
    if (this.fullAddress.lat === 0 && this.fullAddress.lng === 0) {
      this.signupForm.controls['address'].patchValue(null);
      // this.submitted = true;
    } else if (this.fullAddress.address !== value) {
      this.signupForm.controls['address'].patchValue(null);
      // this.submitted = true;
    }
  }

  getPickupAddress(lat, lng, formatted_address) {
    this.geoCoder.geocode({ 'location': { lat: lat, lng: lng } }, (results, status) => {
      if (status === 'OK') {
        if (results.length > 0) {
          console.log(results);
          var isAddSimilar = false;
          let length = results[0].address_components.length;
          // setTimeout(() => {
          //   if(!this.signupForm.controls['zipCode'].value) {
          //     if(results[0].address_components[length-1].types.includes("postal_code")) {
          //       this.signupForm.controls['zipCode'].patchValue(results[0].address_components[length-1].long_name);
          //     }
          //   }
          // }, 1000);
          results.forEach(addresses => {
            if (addresses.formatted_address === formatted_address && !isAddSimilar) {
              isAddSimilar = true;
              console.log('exist');
              this.fetchAddress(addresses.address_components);
            }
          })
          if (!isAddSimilar) {
            console.log('not-exist');
            this.fetchAddress(results[0].address_components);
          }
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
    // this.checkstatusMap();
  }

  fetchAddress(addressArr) {
    var city = null;
    var state = null;
    var zipCode = null;
    var country = null;
    addressArr.forEach(element => {
      if (element.types.includes("locality") || element.types.includes("postal_town") || element.types.includes("sublocality") ||
        element.types.includes("sublocality_level_1") || element.types.includes("sublocality_level_2") || element.types.includes("sublocality_level_3") ||
        element.types.includes("sublocality_level_4") || element.types.includes("sublocality_level_5")) {
        city = element.long_name;
      }
      if (element.types.includes("administrative_area_level_1") || element.types.includes("administrative_area_level_2") ||
        element.types.includes("administrative_area_level_3") || element.types.includes("administrative_area_level_4") || element.types.includes("administrative_area_level_5")) {
        state = element.long_name;
      }
      if (element.types.includes("postal_code")) {
        zipCode = element.long_name;
      }
      if (element.types.includes("country")) {
        country = element.long_name;
      }
    });
    this.fullAddress.address = city + ', ' + state + ', ' + country;
    this.signupForm.controls['address'].patchValue(city + ', ' + state + ', ' + country);
    this.signupForm.controls['city'].patchValue(city);
    this.signupForm.controls['province'].patchValue(state);
    this.signupForm.controls['country'].patchValue(country);
    // this.signupForm.controls['zipCode'].patchValue(zipCode);
  }

  continue(popupType) {
    this.nextPopup = popupType;
  }

  goTologin() {
    this.router.navigate(["login"]);
  }
  goToVerificationPopup() {
    this.submitted = true;
    if (this.signupForm.valid && !this.validateFirstArr.includes(true) && !this.validateSecondArr.includes(true)) {
      this.checkDriverExist().then((data: any) => {
        if (!data.isExist && !data.isDeleted) {
          this.register();
        } else if(data.isExist && data.isDeleted) {
          this.accRecover();
        } else if(data.isExist && !data.isDeleted) {
          this.commponService.OpenNotificationModal('error','top-end','Your driver license number has been already used in our system. You can contact our support team for further information.');
        }
      });
    }
  }

  register() {
    var codeLength = this.signupForm.value.phoneNo.dialCode.length;
    var street = this.signupForm.value.street;
    var city = this.signupForm.value.city;
    var state = this.signupForm.value.province;
    var country = this.signupForm.value.country;
    var paramData = $.extend({}, {
      "phoneNo": this.signupForm.value.phoneNo.e164Number.slice(codeLength),
      "countryCode": this.signupForm.value.phoneNo.dialCode,
      "firstName": this.signupForm.value.firstName.trim(),
      "lastName": this.signupForm.value.lastName.trim(),
      "email": this.signupForm.value.email.toLowerCase(),
      "address": this.signupForm.value.address,
      "state": state,
      "city": city,
      "country": country,
      "zipCode": this.signupForm.value.zipCode ? this.signupForm.value.zipCode : undefined,
      "streetName": street,
      "password": this.signupForm.value.password,
      "deviceType": "WEB",
      "deviceToken": new Date(),
      // "role": 'DRIVER',
      "isPreLaunch": true,
      "localAddress": this.signupForm.value.localAddress ? this.signupForm.value.localAddress : undefined,
      "dlNumber": this.signupForm.value.dlNumber.trim(),
      "latitude": this.latitude !== 0 ? this.latitude : undefined,
      "longitude": this.longitude !== 0 ? this.longitude : undefined
    });
    this.httpService.httpPost('register', paramData).subscribe((res: any) => {
      if (res.statusCode === 200) {
        this.commponService.OpenNotificationModal('success', 'top-end', 'Driver pre-registered successfully.');
        this.router.navigate(['uhomepage']);
        // this.commponService.emailForVerification = res.data.email;
        // this.commonService.localStorageCLear();
        // localStorage.setItem('accessToken', res.data.accessToken);
        // localStorage.setItem('userId', res.data.id);
        // localStorage.setItem('userType', 'rider');
        // this.httpService.httpPostWithHeaderUser('sendOtp', { phoneNo: res.data.phoneNo }).subscribe((item: any) => {
        //   if (item.statusCode == 200) {
        //     this.commponService.setVerificationData({ countryCode: item.data.countryCode, phoneNo: item.data.phoneNo, otpCode: item.data.otpCode })
        //     this.email = res.data.email;
        //     this.phoneNo = item.data.countryCode + ' ' + item.data.phoneNo;
        //     this.commponService.isThisPreUser = true;
        //     $("#verification").modal('show');
        //   }
        // });
      }
    });
  }

  checkDriverExist() {
    return new Promise((resolve, reject) => {
      this.httpService.httpPostWithHeader('checkDriverExist', { dlNumber: this.signupForm.value.dlNumber.trim() }).subscribe((res: any) => {
        if (res.statusCode == 200) {
          return resolve(res.data);
        }
      });
    });
  }

  goToSignupStep(type) {
    this.commponService.driverType = type;
    this.nextPopup = 1;
  }

  goTouhomepage() {
    this.router.navigate(["/uhomepage"]);
  }
  goToriderotppage() {
    this.router.navigate([this.redirectTo]);
  }

  onKeyPress(event) {
    if (event.charCode !== 32 && event.charCode !== 39) {
      return (event.charCode > 64 &&
        event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)
    }
  }

  onChangeFirst(value) {
    this.validateFirstArr = [];
    for (let i = 0; i < value.length; i++) {
      var charCode = value.charCodeAt(i);
      this.validateFirstArr.push(this.charCodeArr.includes(charCode));
    }
  }

  onChangeSecond(value) {
    this.validateSecondArr = [];
    for (let i = 0; i < value.length; i++) {
      var charCode = value.charCodeAt(i);
      this.validateSecondArr.push(this.charCodeArr.includes(charCode));
    }
  }

  goToCmsPages(url) {
    window.open(environment.webUrl + url, '_blank');
  }

  accRecover() {
    Swal.fire({
      title: 'Do you want to restore your previous account?',
      // text: "You won't be able to revert this!",
      icon: 'info',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Restore',
      cancelButtonText: 'Close',
      allowOutsideClick: false,
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        this.register();
      } else {
        this.commponService.localStorageCLear();
      }
    });
  }

}

import { AfterViewInit, Component, OnInit, ViewChild, NgZone, AfterViewChecked, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
// import * as $ from "jquery";
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from "@angular/forms";
import { HttpsService } from "../services/https/https.service";
import { CommonService } from "../services/common/common.service";
import { MatStepper } from "@angular/material/stepper";
import { MapsAPILoader } from "@agm/core";
import { BehaviorSubject, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { takeUntil } from "rxjs/operators";
import { fConstant } from "../core/constant";
import { CountryISO, SearchCountryField, TooltipLabel } from "ngx-intl-tel-input";
import { luhnValidator } from "../core/validators/luhnValidator";
import { getValidationConfigFromCardNo } from "../core/helpers/card.helper";
import Swal from "sweetalert2";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "src/app/services/language/language.service";
interface Ready {
  value: string;
  viewValue: string;
}
interface driver {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-riderdetail",
  templateUrl: "./riderdetail.component.html",
  styleUrls: ["./riderdetail.component.scss"],
})
export class RiderdetailFComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {
  @ViewChild("stepper", { static: false }) public stepper: MatStepper;
  @ViewChild("step1", { static: false }) public step1: any;
  @ViewChild("step2", { static: false }) public step2: any;
  isLinear: boolean = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  threeFormGroup: FormGroup;
  fourFormGroup: FormGroup;
  pick: Ready[] = [
    { value: "5 Days", viewValue: "lorem" },
    { value: "1 Days", viewValue: "lorem" },
  ];
  pick2: driver[] = [
    { value: "DELIVERY", viewValue: "Delivery Driver " },
    { value: "RIDE", viewValue: "Ride Driver" },
    { value: "BOTH", viewValue: "Both " },
  ];
  pick4: driver[] = [
    { value: "male", viewValue: "Male" },
    { value: "female", viewValue: "Female" },
    { value: "others", viewValue: "Others" },
  ];

  modalYear = [];
  addDriverForm: FormGroup;
  submitted: boolean = false;
  pickup: any;
  pickupAddress: any;
  geoCoder: google.maps.Geocoder;
  public onStepsChange: Subject<string> = new Subject<string>();
  getOnStepChange = this.onStepsChange.asObservable();
  public goToBack: BehaviorSubject<boolean> = new BehaviorSubject(null);
  getGoToBack = this.goToBack.asObservable();
  fileDataUrl: any;
  baseUrl: any;
  userNameAlreadyExist: boolean = false;
  editable: boolean = false;
  notifier = new Subject();
  processing: boolean = true;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.Canada];
  isPhoneExist: boolean = false;
  userData: any;
  isEmailExist: boolean = false;
  fullAddress = { address: "", lat: 0, lng: 0 };
  latitude: number = 0;
  longitude: number = 0;
  charCodeArr: any = [
    96, 49, 50, 51, 52, 53, 54, 55, 56, 57, 48, 45, 61, 91, 93, 92, 59, 44, 46, 47, 126, 33, 64, 35, 36, 37, 94, 38, 42, 40, 41, 95, 43, 123, 125,
    124, 58, 34, 60, 62, 63,
  ];
  validateFirstArr = [];
  validateSecondArr = [];
  validateSecondCardArr = [];
  cardNumberGroup: FormGroup;
  cardOwnerFirstName: FormControl = new FormControl("", Validators.required);
  cardOwnerLastName: FormControl = new FormControl("");
  expiryMonth: FormControl = new FormControl(null, Validators.required);
  expiryYear: FormControl = new FormControl(null, Validators.required);
  cvv: FormControl = new FormControl(null, Validators.required);
  expiryYears = [];
  cardSubmitted: boolean = false;
  allSavedCard: any = [];
  defaultCardDetails: any;
  selectedCardDetails: any;
  allSavedCardData: any = [];
  addCardModal: string = "none";
  isWalletSelected: boolean = false;
  selectedWalletCardId: any;
  addWalletMoneyModal: string = "none";
  addWalletAmount: FormControl = new FormControl("", Validators.required);
  walletMoneySubmitted: boolean = false;
  languageCode: string;
  showLang: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private http: HttpsService,
    public commonService: CommonService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private constant: fConstant,
    private ngxService: NgxUiLoaderService,
    public translate: TranslateService,
    public languageService: LanguageService
  ) {
    this.baseUrl = environment.imageUrl;
    if (localStorage.languageCode !== undefined) {
      this.languageCode = localStorage.getItem("languageCode");
    }
    this.translate.use(this.languageCode);
  }

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      userName: ["", Validators.required],
      email: ["", [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
      phone: ["", Validators.required],
      companyName: [""],
      image: [""],
    });
    this.firstFormGroup.get("phone").valueChanges.subscribe((value: any) => {
      if (value) {
        this.isPhoneExist = false;
      }
    });
    this.firstFormGroup.get("email").valueChanges.subscribe((value: any) => {
      if (value) {
        this.isEmailExist = false;
      }
    });
    this.secondFormGroup = this._formBuilder.group({
      street: ["", Validators.required],
      location: ["", Validators.required],
      zipCode: ["", Validators.required],
      city: ["", Validators.required],
      state: ["", Validators.required],
      country: ["", Validators.required],
      localAddress: [""],
      agree: ["", Validators.requiredTrue],
    });
    this.threeFormGroup = this._formBuilder.group({
      secondCtrl: ["", Validators.required],
    });

    this.fourFormGroup = this._formBuilder.group({
      secondCtrl: ["", Validators.required],
    });
    this.getProfileData();
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
    });
    this.getOnStepChange.subscribe((res: any) => {
      if (res) {
        this.cdr.detectChanges();
        console.log("index", this.stepper.selectedIndex, JSON.parse(res));
        this.stepper.selectedIndex = JSON.parse(res);
        this.isLinear = true;
      }
    });
    this.getGoToBack.subscribe((res: any) => {
      if (res) {
        this.cdr.detectChanges();
        this.stepper.selectedIndex--;
        this.isLinear = true;
        this.getProfileData();
      }
    });
    this.validateUserName();
    this.cardNumberGroup = new FormGroup({
      cardNumber: new FormControl("", [Validators.required, Validators.minLength(12), luhnValidator()]),
    });
    //Determine the Current Year.
    var currentYear = new Date().getFullYear();
    for (var i = currentYear; i <= currentYear + 15; i++) {
      this.expiryYears.push(i.toString().slice(2));
    }
    this.getAllCard();
  }

  selectLang(language) {
    this.languageCode = language.code;
    this.languageService.selectedLang = language;
    this.languageService.translateLanguage(this.languageCode);
    this.showLang = false;
  }

  validateUserName() {
    this.firstFormGroup
      .get("userName")
      .valueChanges.pipe(takeUntil(this.notifier))
      .subscribe((value: any) => {
        if (value) {
          this.http
            .httpPostUser("checkUserName", { userName: value })
            .pipe(takeUntil(this.notifier))
            .subscribe((res: any) => {
              if (res.statusCode == 200) {
                this.userNameAlreadyExist = res.data.isUserExist;
                this.http
                  .httpGetWithHeaderUser("userProfile", "")
                  .pipe(takeUntil(this.notifier))
                  .subscribe((res1: any) => {
                    if (res1.data.userName != "") {
                      this.userNameAlreadyExist = false;
                    }
                  });
              }
            });
        }
      });
  }

  ngAfterViewInit() {
    this.http
      .httpGetWithHeaderUser("userProfile", "")
      .pipe(takeUntil(this.notifier))
      .subscribe((res: any) => {
        console.log(res);
        this.processing = false;
        if (res.data.userName === "") {
          this.isLinear = false;
          this.stepper.selectedIndex = 0;
          this.isLinear = true;
          this.editable = false;
        } else if (res.data.address === "") {
          this.isLinear = false;
          this.stepper.selectedIndex = 1;
          this.isLinear = true;
          this.editable = false;
        } else if (res.data.address != "" && !res.data.isAccountCompleted) {
          this.isLinear = false;
          this.stepper.selectedIndex = 2;
          this.isLinear = true;
          this.editable = false;
        } else if (res.data.isAccountCompleted && !this.commonService.isUserRecovered) {
          // this.router.navigate(['/riderequest']);
          this.isLinear = false;
          this.stepper.selectedIndex = 2;
          this.isLinear = true;
          this.editable = false;
        }
      });
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  handleLocationChnage(address) {
    if (address.formatted_address) {
      console.log(address.formatted_address);
      let lat = address.geometry.location.lat();
      let lng = address.geometry.location.lng();
      this.fullAddress.lat = lat;
      this.fullAddress.lng = lng;
      if (this.userData.latitude === "" && this.userData.longitude === "") {
        this.latitude = lat;
        this.longitude = lng;
      }
      this.getPickupAddress(lat, lng, address.formatted_address);
    }
  }

  onKeyUp(value) {
    if (value === "") {
      this.fullAddress.lat = 0;
      this.fullAddress.lng = 0;
      this.secondFormGroup.controls["location"].patchValue(null);
    }
  }

  onAddChanged(value) {
    if (this.fullAddress.lat === 0 && this.fullAddress.lng === 0) {
      this.secondFormGroup.controls["location"].patchValue(null);
      // this.submitted = true;
    } else if (this.fullAddress.address !== value) {
      this.secondFormGroup.controls["location"].patchValue(null);
      // this.submitted = true;
    }
  }

  getPickupAddress(lat, lng, formatted_address) {
    this.geoCoder.geocode({ location: { lat: lat, lng: lng } }, (results, status) => {
      if (status === "OK") {
        if (results.length > 0) {
          console.log(results);
          var isAddSimilar = false;
          let length = results[0].address_components.length;
          // setTimeout(() => {
          //   if(!this.secondFormGroup.controls['zipCode'].value) {
          //     if(results[0].address_components[length-1].types.includes("postal_code")) {
          //       this.secondFormGroup.controls['zipCode'].patchValue(results[0].address_components[length-1].long_name);
          //     }
          //   }
          // }, 1000);
          results.forEach((addresses) => {
            if (addresses.formatted_address === formatted_address && !isAddSimilar) {
              isAddSimilar = true;
              console.log("exist");
              this.fetchAddress(addresses.address_components);
              return false;
            }
          });
          if (!isAddSimilar) {
            console.log("not-exist");
            this.fetchAddress(results[0].address_components);
          }
        } else {
          window.alert("No results found");
        }
      } else {
        window.alert("Geocoder failed due to: " + status);
      }
    });
  }

  fetchAddress(addressArr) {
    var city = null;
    var state = null;
    var zipCode = null;
    var country = null;
    addressArr.forEach((element) => {
      if (
        element.types.includes("locality") ||
        element.types.includes("postal_town") ||
        element.types.includes("sublocality") ||
        element.types.includes("sublocality_level_1") ||
        element.types.includes("sublocality_level_2") ||
        element.types.includes("sublocality_level_3") ||
        element.types.includes("sublocality_level_4") ||
        element.types.includes("sublocality_level_5")
      ) {
        city = element.long_name;
      }
      if (
        element.types.includes("administrative_area_level_1") ||
        element.types.includes("administrative_area_level_2") ||
        element.types.includes("administrative_area_level_3") ||
        element.types.includes("administrative_area_level_4") ||
        element.types.includes("administrative_area_level_5")
      ) {
        state = element.long_name;
      }
      if (element.types.includes("postal_code")) {
        zipCode = element.long_name;
      }
      if (element.types.includes("country")) {
        country = element.long_name;
      }
    });
    this.fullAddress.address = city + ", " + state + ", " + country;
    this.secondFormGroup.controls["location"].patchValue(city + ", " + state + ", " + country);
    this.secondFormGroup.controls["city"].patchValue(city);
    this.secondFormGroup.controls["state"].patchValue(state);
    this.secondFormGroup.controls["country"].patchValue(country);
    // this.secondFormGroup.controls['zipCode'].patchValue(zipCode);
  }

  getProfileData() {
    this.http
      .httpGetWithHeaderUser("userProfile", "")
      .pipe(takeUntil(this.notifier))
      .subscribe((res: any) => {
        this.userData = res.data;
        this.firstFormGroup.controls["firstName"].patchValue(res.data.firstName);
        this.firstFormGroup.controls["lastName"].patchValue(res.data.lastName);
        this.firstFormGroup.controls["email"].patchValue(res.data.email);
        // this.firstFormGroup.controls['phone'].patchValue(res.data.phoneNo);
        this.firstFormGroup.controls["userName"].patchValue(res.data.userName);
        this.firstFormGroup.controls["companyName"].patchValue("N/A");
        this.secondFormGroup.controls["location"].patchValue(res.data.address);
        this.secondFormGroup.controls["street"].patchValue(res.data.streetName);
        this.secondFormGroup.controls["zipCode"].patchValue(res.data.zipCode);
        this.secondFormGroup.controls["localAddress"].patchValue(res.data.localAddress);
        if (res.data.phoneNo !== "") {
          this.firstFormGroup.get("phone").patchValue(res.data.countryCode + " " + res.data.phoneNo);
        }
        if (res.data.city !== "") {
          this.secondFormGroup.get("city").patchValue(res.data.city);
        }
        if (res.data.country !== "") {
          this.secondFormGroup.get("country").patchValue(res.data.country);
        }
        if (res.data.state !== "") {
          this.secondFormGroup.get("state").patchValue(res.data.state);
        }
        if (this.commonService.isUserRecovered && res.data.address !== "") {
          this.secondFormGroup.controls["agree"].patchValue(true);
        }
        if (res.data.image != "") {
          this.fileDataUrl = res.data.image;
        }
        this.firstFormGroup.markAllAsTouched();
      });
  }

  goToAddressTab(index) {
    this.editable = true;
    this.http
      .httpGetWithHeaderUser("userProfile", "")
      .pipe(takeUntil(this.notifier))
      .subscribe((res: any) => {
        this.secondFormGroup.controls["location"].patchValue(res.data.address);
        this.secondFormGroup.controls["street"].patchValue(res.data.streetName);
        this.secondFormGroup.controls["zipCode"].patchValue(res.data.zipCode);
        this.secondFormGroup.controls["agree"].patchValue(true);
        this.editable = true;
        this.isLinear = false;
        this.stepper.selectedIndex = index;
      });
  }

  backToPrevious() {
    this.editable = true;
    this.submitted = false;
    this.isLinear = false;
    this.goToBack.next(true);
  }

  updateProfile(step, formName, stepC) {
    this.submitted = true;
    var formData = new FormData();
    if (this[formName].valid) {
      if (
        step === "1" &&
        (this.validateFirstArr.length === 0 || !this.validateFirstArr.includes(true)) &&
        (this.validateSecondArr.length === 0 || !this.validateSecondArr.includes(true))
      ) {
        if (this.userNameAlreadyExist === true) {
          return;
        }
        if (this.userData.phoneNo === "") {
          var codeLength = this.firstFormGroup.value.phone.dialCode.length;
          this.http
            .httpPostUser("checkIfPhoneNumberExist", { phoneNo: this.firstFormGroup.value.phone.e164Number.slice(codeLength) })
            .subscribe((val: any) => {
              if (val.data.isValid) {
                this.isPhoneExist = false;
                formData.append("phoneNo", this.firstFormGroup.value.phone.e164Number.slice(codeLength));
                formData.append("countryCode", this.firstFormGroup.value.phone.dialCode);
                if (this.fileDataUrl != undefined) {
                  formData.append("image", this.fileDataUrl);
                }
                formData.append("firstName", this.firstFormGroup.value.firstName.trim());
                formData.append("lastName", this.firstFormGroup.value.lastName.trim());
                if (this.firstFormGroup.value.companyName != "") {
                  formData.append("companyName", this.firstFormGroup.value.companyName);
                }
                formData.append("userName", this.firstFormGroup.value.userName);
                this.update(formData, step);
              } else {
                this.isPhoneExist = true;
                return;
              }
            });
        } else if (this.userData.email === "") {
          this.http.httpPostUser("checkIfEmailExist", { email: this.firstFormGroup.value.email.toLowerCase() }).subscribe((val: any) => {
            if (val.data.isValid) {
              this.isEmailExist = false;
              formData.append("email", this.firstFormGroup.value.email.toLowerCase());
              if (this.fileDataUrl != undefined) {
                formData.append("image", this.fileDataUrl);
              }
              formData.append("firstName", this.firstFormGroup.value.firstName);
              formData.append("lastName", this.firstFormGroup.value.lastName);
              if (this.firstFormGroup.value.companyName != "") {
                formData.append("companyName", this.firstFormGroup.value.companyName);
              }
              formData.append("userName", this.firstFormGroup.value.userName);
              this.update(formData, step);
            } else {
              this.isEmailExist = true;
              return;
            }
          });
        } else {
          if (this.fileDataUrl != undefined) {
            formData.append("image", this.fileDataUrl);
          }
          formData.append("firstName", this.firstFormGroup.value.firstName);
          formData.append("lastName", this.firstFormGroup.value.lastName);
          if (this.firstFormGroup.value.companyName != "") {
            formData.append("companyName", this.firstFormGroup.value.companyName);
          }
          formData.append("userName", this.firstFormGroup.value.userName);
          this.update(formData, step);
        }
      } else if (step === "2") {
        if (this.secondFormGroup.value.agree === true) {
          formData.append("streetName", this.secondFormGroup.value.street);
          formData.append("address", this.secondFormGroup.value.location);
          if (this.secondFormGroup.value.city) {
            formData.append("city", this.secondFormGroup.value.city);
          }
          if (this.secondFormGroup.value.state) {
            formData.append("state", this.secondFormGroup.value.state);
          }
          if (this.secondFormGroup.value.country) {
            formData.append("country", this.secondFormGroup.value.country);
          }
          formData.append("zipCode", this.secondFormGroup.value.zipCode);
          this.secondFormGroup.value.localAddress && formData.append("localAddress", this.secondFormGroup.value.localAddress);
          if (this.userData.latitude == "" && this.userData.longitude == "") {
            formData.append("latitude", this.latitude.toString());
            formData.append("longitude", this.longitude.toString());
          }
        }
        this.update(formData, step);
      }
    }
  }

  update(formData, index) {
    this.http
      .httpPostWithFormDataHeader("updateUserProfile", formData)
      .pipe(takeUntil(this.notifier))
      .subscribe((res: any) => {
        if (res.statusCode === 200) {
          this.editable = true;
          this.submitted = false;
          this.isLinear = false;
          this.onStepsChange.next(index);
        }
      });
  }

  onFileUpload(fileInput: any) {
    const fileData = <File>fileInput.target.files[0];
    this.http
      .userFileUpload(fileData)
      .pipe(takeUntil(this.notifier))
      .subscribe((res) => {
        this.fileDataUrl = JSON.parse(JSON.stringify(res)).data.orignal;
      });
  }

  goTovechicle() {
    this.router.navigate(["vechicle"]);
  }

  goTouhomepage() {
    this.router.navigate(["uhomepage"]);
  }
  goToriderlogin() {
    this.router.navigate(["riderlogin"]);
  }

  goToridercongratulations() {
    var formData = new FormData();
    formData.append("isAccountCompleted", "true");
    this.http
      .httpPostWithFormDataHeader("updateUserProfile", formData)
      .pipe(takeUntil(this.notifier))
      .subscribe((res: any) => {
        if (res.statusCode === 200) {
          this.submitted = false;
          this.isLinear = false;
          // this.onStepsChange.next(true);
          this.commonService.openCongratsModal(this.constant.MSGS.CONGRATS, true);
          // this.matDialog.open(RidercongratulationsComponent);
          this.router.navigate(["/riderequest"]);
        }
      });
    // this.router.navigate(["/ridercongratulations"]);
  }
  onKeyPress(event) {
    if (event.charCode !== 32 && event.charCode !== 39) {
      return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123);
    }
  }

  onChangeFirst(value) {
    this.validateFirstArr = [];
    for (let i = 0; i < value.length; i++) {
      var charCode = value.charCodeAt(i);
      this.validateFirstArr.push(this.charCodeArr.includes(charCode));
    }
  }

  onChangeSecond(value, form) {
    if (form == "firstFormGroup") {
      let formValue = this.firstFormGroup.get("lastName").value;
      if (value === formValue) {
        this.validateSecondArr = [];
        for (let i = 0; i < value.length; i++) {
          var charCode = value.charCodeAt(i);
          this.validateSecondArr.push(this.charCodeArr.includes(charCode));
        }
      }
    } else {
      let formValue = this.cardOwnerLastName.value;
      if (value === formValue) {
        this.validateSecondCardArr = [];
        for (let i = 0; i < value.length; i++) {
          var charCode = value.charCodeAt(i);
          this.validateSecondCardArr.push(this.charCodeArr.includes(charCode));
        }
      }
    }
  }

  goToCmsPages(url) {
    window.open(environment.webUrl + url, "_blank");
  }

  getCardNumberControl(): AbstractControl | null {
    return this.cardNumberGroup && this.cardNumberGroup.get("cardNumber");
  }

  cardMaskFunction(rawValue: string) {
    const card = getValidationConfigFromCardNo(rawValue);
    console.log(card);
    // if (this.cvvLength) {
    //   this.cvvLength = card.cvvLength[0];
    // }
    if (card) {
      return card.mask;
    }
    return [/\d/];
  }

  onCvvPress(value) {
    console.log(value);
    // if (value.length !== this.cvvLength) {
    //   return false;
    // }
  }

  AddCardModal(display) {
    this.addCardModal = display;
  }

  addDebitCard() {
    this.cardSubmitted = true;
    if (
      this.cardNumberGroup.valid &&
      this.cardOwnerFirstName.valid &&
      this.expiryMonth.valid &&
      !this.validateFirstArr.includes(true) &&
      this.expiryYear.valid &&
      this.cvv.valid
    ) {
      var param = {
        cardNumber: this.cardNumberGroup.get("cardNumber").value,
        expDate: this.expiryMonth.value + this.expiryYear.value,
        cvv: this.cvv.value,
        firstName: this.cardOwnerFirstName.value,
        lastName: this.cardOwnerLastName.value,
      };
      this.ngxService.start();
      this.http.httpPostWithHeaderUser("userCard", param).subscribe(
        (res: any) => {
          this.ngxService.stop();
          if (res.statusCode == 200) {
            this.addCardModal = "none";
            this.commonService.presentsToast("success", "top-end", "Your payment card has been added successfully.");
            this.cardSubmitted = false;
            this.getAllCard();
            this.cardOwnerFirstName.reset();
            this.cardOwnerLastName.reset();
            this.expiryMonth.patchValue(null);
            this.expiryYear.patchValue(null);
            this.cvv.patchValue(null);
            this.cardNumberGroup.get("cardNumber").reset();
          }
        },
        (err) => this.ngxService.stop()
      );
    }
  }

  getAllCard() {
    this.http.httpGetWithHeaderOnly("userCard").subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.allSavedCard = [];
        this.allSavedCardData = res.data;
        if (res.data.cards.length > 0) {
          res.data.cards.forEach((data: any) => {
            if (data.isDefault) {
              this.defaultCardDetails = data;
              this.selectedCardDetails = data;
              data["isSelected"] = true;
              this.allSavedCard.push(data);
            } else {
              data["isSelected"] = false;
              this.allSavedCard.push(data);
            }
          });
        }
        this.isWalletSelected = false;
      }
    });
  }

  changeDefaultCard(cardId) {
    this.http.httpPostWithHeaderUser("defaultCard", { id: cardId }).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.getAllCard();
      }
    });
  }

  deleteCard(cardId) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.http.httpDeleteByIdUser("userCard", cardId).subscribe((res: any) => {
          if (res.statusCode == 200) {
            Swal.fire("Deleted!", "Card has been deleted.", "success");
            this.getAllCard();
          }
        });
      }
    });
  }

  onCardChange(index) {
    this.allSavedCard = [];
    this.selectedCardDetails = this.allSavedCardData.cards[index - 1];
    this.isWalletSelected = false;
    this.allSavedCardData.cards.forEach((data: any) => {
      if (data.id === this.selectedCardDetails?.id) {
        data["isSelected"] = true;
        this.allSavedCard.push(data);
      } else {
        data["isSelected"] = false;
        this.allSavedCard.push(data);
      }
    });
  }

  onWalletSelected(value) {
    this.isWalletSelected = value;
    if (value) {
      this.allSavedCard = [];
      this.allSavedCardData.cards.forEach((data: any) => {
        data["isSelected"] = false;
        this.allSavedCard.push(data);
      });
    } else {
      this.getAllCard();
    }
  }

  addWalletModal(style) {
    if (this.allSavedCardData.cards.length > 0 && style === "block") {
      this.addWalletMoneyModal = style;
      this.defaultCardDetails.id && (this.selectedWalletCardId = this.defaultCardDetails.id);
    } else if (this.allSavedCardData.cards.length === 0 && style === "block") {
      this.commonService.OpenNotificationModal("success", "top-end", "Please add payment card first.");
    }
    if (style === "none") {
      this.addWalletMoneyModal = style;
    }
  }

  onWalletCardChange(card) {
    this.selectedWalletCardId = card.id;
  }

  addWalletMoney() {
    this.walletMoneySubmitted = true;
    if (this.addWalletAmount.valid && parseInt(this.addWalletAmount.value) > 0) {
      this.ngxService.start();
      this.http.httpPostWithHeaderUser("wallet", { amount: this.addWalletAmount.value, cardId: this.selectedWalletCardId }).subscribe(
        (res: any) => {
          this.ngxService.stop();
          if (res.statusCode == 200) {
            this.addWalletModal("none");
            this.addWalletAmount.reset();
            this.getAllCard();
          }
        },
        (err) => this.ngxService.stop()
      );
    } else if (parseInt(this.addWalletAmount.value) <= 0) {
      this.addWalletAmount.patchValue(null);
    }
  }

  keypresshandler(event) {
    var charCode = event.keyCode;
    //Non-numeric character range
    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
  }

  // openPaymentOptions(index) {
  //   // this.getAllCard();
  //   this.continue(index);
  // }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../services/common/common.service';
// import { CommonService } from '../../prelaunch/services/common/common.service';
// import { Checkout } from  'Checkout.js'
declare var Checkout: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  callback: { onError: (error: any) => void; onDeclined: (response: any) => void; onApproval: (response: any) => void; onDCCDecision: (response: any) => void; };
  paymentForm: FormGroup;
  
  constructor(private commonService: CommonService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.paymentForm = this.fb.group({
      cardNumber: ['', Validators.required],
      expiry: ['', Validators.required],
      cvv: ['', Validators.required],
    });
    this.callback = {
      onError: function (error) {
          this.commonService.OpenNotificationModal("error", 'top-end', error);
      },
      onDeclined: function (response) {
          this.commonService.OpenNotificationModal("declined", 'top-end', JSON.stringify(response));
      },
      onApproval: function (response) {
          this.commonService.OpenNotificationModal("approval", 'top-end', JSON.stringify(response));
      },
      onDCCDecision: function (response) {
          // document.getElementById('ssl_conversion_rate').value = response.ssl_conversion_rate;
          this.commonService.OpenNotificationModal("DCC decision", 'top-end', JSON.stringify(response));
      }
  };
  }

  // initiateCheckoutJS() {
  //   var tokenRequest = {
  //     ssl_first_name: document.getElementById('name').value,
  //     ssl_last_name: document.getElementById('lastname').value,
  //     ssl_amount: document.getElementById('ssl_amount').value
  //   };
  //   $.post("checkoutjscurlrequestdevportal.php", tokenRequest, function (data) {
  //     document.getElementById('token').value = data;
  //     transactionToken = data;
  //   });
  //   return false;
  // }

  pay () {
    var token = '';
    var card = this.paymentForm.value.cardNumber;
    var exp = this.paymentForm.value.expiry;
    var cvv = this.paymentForm.value.cvv;
    var paymentData = {
            ssl_txn_auth_token: token,
            ssl_card_number: card,
            ssl_exp_date: exp,
            ssl_cvv2cvc2: cvv
    };
    Checkout.ConvergeEmbeddedPayment.pay(paymentData, this.callback);
    return false;
}

}

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { CommonService } from "../services/common/common.service";
import { HttpsService } from "../services/https/https.service";
import { MustMatch } from "../core/must-match.validator";
import { SearchCountryField, TooltipLabel, CountryISO } from "ngx-intl-tel-input";
import { environment } from "src/environments/environment";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { MapsAPILoader } from "@agm/core";
import * as industryData from "../core/industries.json";
import { TranslateService } from "@ngx-translate/core";
declare var $: any;

@Component({
  selector: "app-ridersignup",
  templateUrl: "./ridersignup.component.html",
  styleUrls: ["./ridersignup.component.scss"],
})
export class RidersignupFComponent implements OnInit {
  signupForm: FormGroup;
  submitted: boolean = false;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Canada, CountryISO.India];
  fullNameRequired: boolean = false;
  verifyBy: FormControl = new FormControl("phone", Validators.required);
  email: any;
  phoneNo: any;
  verificationTypeForm: FormGroup;
  redirectTo: string = "/riderotppage";
  deliveryPartnerForm: FormGroup;
  minDate = new Date();
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$";
  fileDataUrl: any;
  baseUrl: string;
  fileData: any;
  display = "none";
  companyCertificateImage: any;
  geoCoder: google.maps.Geocoder;
  fullAddress = { address: "", lat: 0, lng: 0 };
  industries = industryData.data;
  latitude: number = 0;
  longitude: number = 0;
  imgBaseUrl = environment.imageUrl;
  preview: string;
  charCodeArr: any = [
    96, 49, 50, 51, 52, 53, 54, 55, 56, 57, 48, 45, 61, 91, 93, 92, 59, 44, 46, 47, 126, 33, 64, 35, 36, 37, 94, 38, 42, 40, 41, 95, 43, 123, 125,
    124, 58, 34, 60, 62, 63,
  ];
  validateFirstArr = [];
  validateSecondArr = [];
  bankNameArr: any = [];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private http: HttpsService,
    public commonService: CommonService,
    private ngxService: NgxUiLoaderService,
    private mapsAPILoader: MapsAPILoader,
    public translate: TranslateService
  ) {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 31);
    this.minDate = tomorrow;
    this.baseUrl = environment.imageUrl;
    if (this.router.url === "/delivery-partner-signup") {
      this.commonService.isThisDeliveryPartner = true;
    } else {
      this.commonService.isThisDeliveryPartner = false;
    }
  }

  ngOnInit(): void {
    this.getBankName();
    if (this.router.url === "/delivery-partner-signup") {
      this.commonService.isThisDeliveryPartner = true;
    } else {
      this.commonService.isThisDeliveryPartner = false;
    }
    this.signupForm = this.fb.group(
      {
        phoneNo: ["", Validators.required],
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        email: ["", [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
        password: ["", [Validators.required, Validators.minLength(6)]],
        confirmPassword: ["", [Validators.required, Validators.minLength(6)]],
      },
      {
        validator: MustMatch("password", "confirmPassword"),
      }
    );
    this.deliveryPartnerForm = this.fb.group(
      {
        phoneNo: ["", Validators.required],
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        email: ["", [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
        password: ["", [Validators.required, Validators.minLength(6)]],
        confirmPassword: ["", [Validators.required, Validators.minLength(6)]],
        companyName: ["", Validators.required],
        companyStreet: ["", Validators.required],
        companyCity: ["", Validators.required],
        companyState: ["", Validators.required],
        companyCountry: ["", Validators.required],
        // description: ['', Validators.required],
        companyZipCode: [""],
        registrationCertificateNumber: [""],
        bussinessIncorporation: [""],
        // creditExpiry: [''],
        industryName: ["", Validators.required],
        // bankCardExpiry: [''],
        // loyalityCardExpiry: ['', Validators.required],
        address: ["", Validators.required],
        profilePhoto: [""],
        localAddress: [""],
        accNumber: new FormControl(null, [Validators.required, Validators.minLength(4)]),
        transit: new FormControl(null, Validators.required),
        bankName: new FormControl(null, Validators.required),
        branchAdd: new FormControl(null, Validators.required),
        institutionNumber: new FormControl(null, Validators.required),
        agree: ["", Validators.requiredTrue],
        // companyCertificateNo: ['', Validators.required]
      },
      {
        validator: MustMatch("password", "confirmPassword"),
      }
    );
    this.verificationTypeForm = this.fb.group({
      verifyBy: ["phone", Validators.required],
    });
    this.verificationTypeForm.get("verifyBy").valueChanges.subscribe((res: any) => {
      if (res) {
        if (res === "email") {
          this.redirectTo = "/verifyemail";
          localStorage.setItem("email", this.commonService.emailForVerification);
        } else {
          this.redirectTo = "/riderotppage";
          localStorage.setItem("phone", this.commonService.phoneNoForVerification);
        }
      }
    });
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
    });
    this.getCurrLocation();
  }

  getCurrLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        console.log(this.latitude, this.longitude);
      });
    }
  }

  goToriderlogin() {
    if (this.commonService.isThisDeliveryPartner) {
      this.router.navigate(["/delivery-partner-login"]);
    } else {
      this.router.navigate(["/riderlogin"]);
    }
  }
  goTouhomepage() {
    this.router.navigate(["/uhomepage"]);
  }
  goToriderdetail() {
    this.router.navigate(["/riderdetail"]);
  }

  onFileChange(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      var reader = new FileReader();
      this.fileData = fileInput.target.files[0];
      reader.readAsDataURL(this.fileData);
      reader.onload = (event) => {
        this.fileDataUrl = reader.result as string;
      };
    }
  }

  onFileUpload(fileInput: any) {
    const fileData = <File>fileInput.target.files[0];
    this.http.userFileUploadWithoutAuth(fileData).subscribe((res: any) => {
      this.preview = fileData.name;
      this.companyCertificateImage = JSON.parse(JSON.stringify(res)).data.orignal;
    });
  }

  goToVerificationPopup() {
    this.submitted = true;
    var paramData;
    if (this.commonService.isThisDeliveryPartner) {
      if (this.deliveryPartnerForm.valid) {
        var codeLength = this.deliveryPartnerForm.value.phoneNo.dialCode.length;
        var formData = new FormData();
        var street = this.deliveryPartnerForm.value.companyStreet.trim();
        var city = this.deliveryPartnerForm.value.companyCity;
        var state = this.deliveryPartnerForm.value.companyState;
        var country = this.deliveryPartnerForm.value.companyCountry;
        formData.append("phoneNo", this.deliveryPartnerForm.value.phoneNo.e164Number.slice(codeLength));
        formData.append("countryCode", this.deliveryPartnerForm.value.phoneNo.dialCode);
        formData.append("firstName", this.deliveryPartnerForm.value.firstName.trim());
        formData.append("lastName", this.deliveryPartnerForm.value.lastName.trim());
        formData.append("email", this.deliveryPartnerForm.value.email.toLowerCase());
        formData.append("password", this.deliveryPartnerForm.value.password.trim());
        formData.append("deviceType", "WEB");
        formData.append("deviceToken", new Date().toString());
        formData.append("role", "DELIVERY_PARTNER");
        formData.append("companyName", this.deliveryPartnerForm.value.companyName.trim());
        formData.append("streetName", street);
        formData.append("city", city);
        formData.append("country", country);
        formData.append("bussinessIncorporation", this.deliveryPartnerForm.value.bussinessIncorporation);
        formData.append("industryName", this.deliveryPartnerForm.value.industryName);
        formData.append("accountNumber", this.deliveryPartnerForm.value.accNumber.toString());
        formData.append("transit", this.deliveryPartnerForm.value.transit);
        formData.append("bankName", this.deliveryPartnerForm.value.bankName);
        formData.append("bankAddress", this.deliveryPartnerForm.value.branchAdd);
        formData.append("institutionNumber", this.deliveryPartnerForm.value.institutionNumber);
        // formData.append("creditCardExpiryDate", this.deliveryPartnerForm.value.creditExpiry);
        // formData.append("bankCardExpiryDate", this.deliveryPartnerForm.value.bankCardExpiry);
        // "loyalityCardExpiryDate" : this.deliveryPartnerForm.value.bankCardExpiry,
        if (this.companyCertificateImage !== "") {
          formData.append("companyCertificate", this.companyCertificateImage);
        }
        formData.append("state", state);
        this.deliveryPartnerForm.value.companyZipCode && formData.append("zipCode", this.deliveryPartnerForm.value.companyZipCode);
        formData.append("address", this.deliveryPartnerForm.value.address);
        formData.append("isAccountCompleted", true.toString());
        this.deliveryPartnerForm.value.localAddress && formData.append("localAddress", this.deliveryPartnerForm.value.localAddress);
        this.fileData && formData.append("image", this.fileData);
        formData.append("isPreLaunch", "false");
        if (this.latitude !== 0 && this.longitude !== 0) {
          formData.append("latitude", this.latitude.toString());
          formData.append("longitude", this.longitude.toString());
        }
        this.http.httpPostWithFormDataWithoutAuth("register", formData).subscribe((res: any) => {
          if (res.statusCode === 200) {
            this.ngxService.start();
            this.commonService.emailForVerification = res.data.email;
            this.commonService.localStorageCLear();
            localStorage.setItem("accessToken", res.data.accessToken);
            localStorage.setItem("userId", res.data.id);
            localStorage.setItem("userType", "DELIVERY_PARTNER");
            this.commonService.phoneNoForVerification = res.data.phoneNo;
            this.commonService.countryCodeForVerification = res.data.countryCode;
            this.phoneNo = res.data.countryCode + " " + res.data.phoneNo;
            this.email = res.data.email;
            this.ngxService.stop();
            this.display = "block";
          }
        });
      }
    } else {
      if (this.signupForm.valid) {
        var codeLength2 = this.signupForm.value.phoneNo.dialCode.length;
        paramData = $.extend(
          {},
          {
            phoneNo: this.signupForm.value.phoneNo.e164Number.slice(codeLength2),
            countryCode: this.signupForm.value.phoneNo.dialCode,
            firstName: this.signupForm.value.firstName.trim(),
            lastName: this.signupForm.value.lastName.trim(),
            email: this.signupForm.value.email.toLowerCase(),
            password: this.signupForm.value.password.trim(),
            deviceType: "WEB",
            deviceToken: new Date(),
            role: "RIDER",
            latitude: this.latitude !== 0 ? this.latitude : undefined,
            longitude: this.longitude !== 0 ? this.longitude : undefined,
          }
        );
        this.http.httpPostUser("register", paramData).subscribe((res: any) => {
          if (res.statusCode === 200) {
            this.ngxService.start();
            this.commonService.emailForVerification = res.data.email;
            this.commonService.localStorageCLear();
            localStorage.setItem("accessToken", res.data.accessToken);
            localStorage.setItem("userId", res.data.id);
            localStorage.setItem("userType", "RIDER");
            this.commonService.phoneNoForVerification = res.data.phoneNo;
            this.commonService.countryCodeForVerification = res.data.countryCode;
            this.phoneNo = res.data.countryCode + " " + res.data.phoneNo;
            this.email = res.data.email;
            this.ngxService.stop();
            this.display = "block";
          }
        });
      }
    }
  }

  handleLocationChnage(address) {
    if (address.formatted_address) {
      console.log(address.formatted_address);
      let lat = address.geometry.location.lat();
      let lng = address.geometry.location.lng();
      this.fullAddress.lat = lat;
      this.fullAddress.lng = lng;
      if (this.latitude == 0 && this.longitude == 0) {
        this.latitude = lat;
        this.longitude = lng;
      }
      this.getPickupAddress(lat, lng, address.formatted_address);
    }
  }

  onKeyUp(value) {
    if (value === "") {
      this.fullAddress.lat = 0;
      this.fullAddress.lng = 0;
      this.deliveryPartnerForm.controls["address"].patchValue(null);
    }
  }

  onAddChanged(value) {
    if (this.fullAddress.lat === 0 && this.fullAddress.lng === 0) {
      this.deliveryPartnerForm.controls["address"].patchValue(null);
      // this.submitted = true;
    } else if (this.fullAddress.address !== value) {
      this.deliveryPartnerForm.controls["address"].patchValue(null);
      // this.submitted = true;
    }
  }

  getPickupAddress(lat, lng, formatted_address) {
    this.geoCoder.geocode({ location: { lat: lat, lng: lng } }, (results, status) => {
      if (status === "OK") {
        if (results.length > 0) {
          console.log(results);
          var isAddSimilar = false;
          let length = results[0].address_components.length;
          // setTimeout(() => {
          //   if (!this.deliveryPartnerForm.controls['companyZipCode'].value) {
          //     if (results[0].address_components[length - 1].types.includes("postal_code")) {
          //       this.deliveryPartnerForm.controls['companyZipCode'].patchValue(results[0].address_components[length - 1].long_name);
          //     }
          //   }
          // }, 1000);
          results.forEach((addresses) => {
            if (addresses.formatted_address === formatted_address && !isAddSimilar) {
              isAddSimilar = true;
              console.log("exist");
              this.fetchAddress(addresses.address_components);
              return false;
            }
          });
          if (!isAddSimilar) {
            console.log("not-exist");
            this.fetchAddress(results[0].address_components);
          }
        } else {
          window.alert("No results found");
        }
      } else {
        window.alert("Geocoder failed due to: " + status);
      }
    });
    // this.checkstatusMap();
  }

  fetchAddress(addressArr) {
    var city = null;
    var state = null;
    var zipCode = null;
    var country = null;
    addressArr.forEach((element) => {
      if (
        element.types.includes("locality") ||
        element.types.includes("postal_town") ||
        element.types.includes("sublocality") ||
        element.types.includes("sublocality_level_1") ||
        element.types.includes("sublocality_level_2") ||
        element.types.includes("sublocality_level_3") ||
        element.types.includes("sublocality_level_4") ||
        element.types.includes("sublocality_level_5")
      ) {
        city = element.long_name;
      }
      if (
        element.types.includes("administrative_area_level_1") ||
        element.types.includes("administrative_area_level_2") ||
        element.types.includes("administrative_area_level_3") ||
        element.types.includes("administrative_area_level_4") ||
        element.types.includes("administrative_area_level_5")
      ) {
        state = element.long_name;
      }
      if (element.types.includes("postal_code")) {
        zipCode = element.long_name;
      }
      if (element.types.includes("country")) {
        country = element.long_name;
      }
    });
    this.fullAddress.address = city + ", " + state + ", " + country;
    this.deliveryPartnerForm.controls["address"].patchValue(city + ", " + state + ", " + country);
    this.deliveryPartnerForm.controls["companyCity"].patchValue(city);
    this.deliveryPartnerForm.controls["companyState"].patchValue(state);
    this.deliveryPartnerForm.controls["companyCountry"].patchValue(country);
    // this.deliveryPartnerForm.controls['companyZipCode'].patchValue(zipCode);
  }

  getbankCardExpiryDate(event: any) {
    this.deliveryPartnerForm.get("bankCardExpiry").patchValue(event.value);
  }

  getcreditExpiryDate(event: any) {
    this.deliveryPartnerForm.get("creditExpiry").patchValue(event.value);
  }
  getLoyalityCardExpiryDate(event: any) {
    this.deliveryPartnerForm.get("loyalityCardExpiry").patchValue(event.value);
  }

  goToriderotppage() {
    if (this.redirectTo === "/riderotppage") {
      this.http.httpPostWithHeaderUser("sendOtp", { phoneNo: this.commonService.phoneNoForVerification }).subscribe((item: any) => {
        if (item.statusCode == 200) {
          localStorage.setItem("phone", item.data.phoneNo);
          localStorage.setItem("phoneNo", item.data.phoneNo);
          localStorage.setItem("countryCode", item.data.countryCode);
          this.commonService.OpenNotificationModal("success", "top-end", item.message);
          this.commonService.setVerificationData({ countryCode: item.data.countryCode, phoneNo: item.data.phoneNo, otpCode: item.data.otpCode });
          this.router.navigate([this.redirectTo]);
          // $("#verification").modal('show');
        }
      });
    } else if (this.redirectTo === "/verifyemail") {
      this.http.httpPostWithHeaderUser("sendOtp", { email: this.commonService.emailForVerification, isPrelaunch: false }).subscribe((item: any) => {
        if (item.statusCode == 200) {
          localStorage.setItem("email", item.data.email);
          this.commonService.OpenNotificationModal("success", "top-end", "Verification link has been sent to your email.");
          this.commonService.setVerificationData({ countryCode: item.data.countryCode, phoneNo: item.data.phoneNo, otpCode: item.data.otpCode });
          this.router.navigate([this.redirectTo]);
          // $("#verification").modal('show');
        }
      });
    }
  }

  onKeyPress(event) {
    if (event.charCode !== 32 && event.charCode !== 39) {
      return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123);
    }
  }

  onChangeFirst(value) {
    this.validateFirstArr = [];
    for (let i = 0; i < value.length; i++) {
      var charCode = value.charCodeAt(i);
      this.validateFirstArr.push(this.charCodeArr.includes(charCode));
    }
  }

  onChangeSecond(value) {
    this.validateSecondArr = [];
    for (let i = 0; i < value.length; i++) {
      var charCode = value.charCodeAt(i);
      this.validateSecondArr.push(this.charCodeArr.includes(charCode));
    }
  }

  goToCmsPages(url) {
    window.open(environment.webUrl + url, "_blank");
  }

  getBankName() {
    this.http.httpGet("bankNames").subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.bankNameArr = res.data;
      }
    });
  }

  changeBank(bank) {
    this.deliveryPartnerForm.get("institutionNumber").patchValue(bank.institutionNumber);
  }
}

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CountryISO, SearchCountryField, TooltipLabel } from "ngx-intl-tel-input";
import { CommonService } from "../services/common/common.service";
import { HttpsService } from "../services/https/https.service";

@Component({
  selector: "app-customer-service",
  templateUrl: "./customer-service.component.html",
  styleUrls: ["./customer-service.component.scss"],
})
export class CustomerServiceComponent implements OnInit {
  contactForm: FormGroup;
  submitted = false;
  fullNameRequired: boolean = false;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Canada, CountryISO.India];
  charCodeArr: any = [
    96, 49, 50, 51, 52, 53, 54, 55, 56, 57, 48, 45, 61, 91, 93, 92, 59, 44, 46, 47, 126, 33, 64, 35, 36, 37, 94, 38, 42, 40, 41, 95, 43, 123, 125,
    124, 58, 34, 60, 62, 63,
  ];
  validateFirstArr = [];
  validateSecondArr = [];

  constructor(private fb: FormBuilder, private httpService: HttpsService, private commonService: CommonService) {}

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      phoneNo: ["", Validators.required],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
      desc: ["", Validators.required],
    });
    // this.contactForm.get('fullName').valueChanges.subscribe((res: any) => {
    //   if (res) {
    //     var fullName = res.split(' ');
    //     if (fullName.length < 2 || fullName[fullName.length - 1] === "") {
    //       this.fullNameRequired = true;
    //     } else {
    //       this.fullNameRequired = false;
    //     }
    //   }
    // });
  }

  submit() {
    this.submitted = true;
    if (this.contactForm.valid && !this.validateFirstArr.includes(true) && !this.validateSecondArr.includes(true)) {
      var codeLength2 = this.contactForm.value.phoneNo.dialCode.length;
      let payload = {
        email: this.contactForm.value.email,
        message: this.contactForm.value.desc,
        firstName: this.contactForm.value.firstName,
        lastName: this.contactForm.value.lastName,
        phoneNo: this.contactForm.value.phoneNo.e164Number.slice(codeLength2),
        countryCode: this.contactForm.value.phoneNo.dialCode,
      };
      this.httpService.httpPostUser("userQuery", payload).subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.commonService.messageAlert("success", "Thank you", "The form was submitted successfully.");
          this.submitted = false;
          this.contactForm.reset();
        }
      });
    }
  }

  onKeyPress(event) {
    if (event.charCode !== 32 && event.charCode !== 39) {
      return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123);
    }
  }

  onChangeFirst(value) {
    this.validateFirstArr = [];
    for (let i = 0; i < value.length; i++) {
      var charCode = value.charCodeAt(i);
      this.validateFirstArr.push(this.charCodeArr.includes(charCode));
    }
  }

  onChangeSecond(value) {
    this.validateSecondArr = [];
    for (let i = 0; i < value.length; i++) {
      var charCode = value.charCodeAt(i);
      this.validateSecondArr.push(this.charCodeArr.includes(charCode));
    }
  }

  openGmail() {
    window.open("mailto:info@apptunix.com");
  }
}

import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from "@angular/forms";
import { HttpsService } from "../services/https/https.service";
import { environment } from "src/environments/environment";
import { CommonService } from "../services/common/common.service";
import { MustMatch } from "../core/must-match.validator";
import Swal from "sweetalert2";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { fConstant } from "../core/constant";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CountryISO, SearchCountryField, TooltipLabel } from "ngx-intl-tel-input";
import { luhnValidator } from "../core/validators/luhnValidator";
import { getValidationConfigFromCardNo } from "../core/helpers/card.helper";
import { BehaviorSubject } from "rxjs";
import { MessagingServiceService } from "src/app/services/messaging-service.service";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "src/app/services/language/language.service";
import { SocketioService } from "../services/sockets/socketio.service";
declare var $: any;
import { Event } from "../core/models/event";
import { MapsAPILoader } from "@agm/core";
import * as moment from "moment";

@Component({
  selector: "app-profilesetup",
  templateUrl: "./profilesetup.component.html",
  styleUrls: ["./profilesetup.component.scss"],
})
export class ProfilesetupFComponent implements OnInit, OnDestroy {
  public show: boolean = false;
  public buttonName: any = "Show";
  selected3 = "";
  selected1 = "";
  selected2 = "";
  selected = "";
  name = "Angular 4";
  nextPopup: number = 0;
  private _opened: boolean = false;
  changePassForm: any;
  firstName: string;
  user: any;
  editable: boolean;
  baseUrl: string;
  imageUrl: string;
  profileForm: FormGroup;
  bookingStatusData: any = [];
  tgshow: boolean = true;
  currentPageUpcomingRide = 1;
  currentPageCompletedRide = 1;
  currentPageCanceledRide = 1;
  currentPageUpcomingDelivery = 1;
  currentPageCompletedDelivery = 1;
  currentPageCanceledDelivery = 1;
  currentPageDelivery = 1;
  rowsPerPage = 10;
  totalPages = 1;
  previousBtnEnabled = false;
  nextBtnEnabled = false;
  totalPagesUpcomingRide = 1;
  totalPagesCompletedRide = 1;
  totalPagesCanceledRide = 1;
  totalPagesUpcomingDelivery = 1;
  totalPagesCompletedDelivery = 1;
  totalPagesCanceledDelivery = 1;
  bookingStatusDataRide = [];
  bookingStatusDataDelivery: any = [];
  linkArr = [
    { name: "My Rides", isActive: false },
    { name: "My Deliveries", isActive: false },
    { name: "Loyalty Points", isActive: false },
    { name: "Review and Rating", isActive: false },
    { name: "Notifications", isActive: false },
    { name: "Manage Addresses", isActive: false },
    { name: "Payment Options", isActive: false },
    { name: "Change Password", isActive: false },
    { name: "Favourite Drivers", isActive: false },
    { name: "Logout", isActive: false },
    { name: "Delete Account", isActive: false },
  ];
  selectedItem: any;
  nextScreen: any;
  favouriteDriverData: any = [];
  isDriverFavourite: boolean = true;
  selectedTabIndex = 0;
  bookingDataUpcomingRide: any = [];
  bookingDataCanceledRide: any = [];
  bookingDataCompletedRide: any = [];
  bookingDataUpcomingDelivery: any = [];
  bookingDataCanceledDelivery: any = [];
  bookingDataCompletedDelivery: any = [];

  public ratingArr = [];
  isFeedBackSelected: boolean = false;
  feedBackQue = [{ que: "Was the driver on time?" }, { que: "Did the driver make your ride comfortabel?" }, { que: "Was the ride safe?" }];
  selectedFeedbckQna: any = [];
  wasTheDriverOnTime: FormControl = new FormControl(null, [Validators.required]);
  driverMakeYouComfortabel: FormControl = new FormControl(null, [Validators.required]);
  wasTheRideSafe: FormControl = new FormControl(null, Validators.required);
  selectedDriverReview: number;
  customRate: FormControl = new FormControl(null);
  selectedTipAmount: any;
  comment: FormControl = new FormControl(null);
  rating: number = 0;
  ratingSubmitted: boolean = false;
  selectedReviewData: any = "";
  driverReviewData: any;
  dummy: any[] = [
    { amount: 2, isActive: false },
    { amount: 5, isActive: false },
    { amount: 8, isActive: false },
    { amount: 10, isActive: false },
  ];
  bookingId: any;
  selectedTip: any;

  @Input("starCount") private starCount: number = 5;
  @Input("color") private color: string = "#0171bd";
  @Output() private ratingUpdated = new EventEmitter();
  reviewData = [
    { name: "Excellent", isActive: false },
    { name: "Very Good", isActive: false },
    { name: "Friendly", isActive: false },
    { name: "Good", isActive: false },
    { name: "Enjoy Service", isActive: false },
  ];
  bookingResponseData: any;

  review: FormControl = new FormControl("");
  notifier = new Subject();
  changePassSubmitted: boolean = false;
  bookingsData: any;
  redirectTo: string = "";
  submitted: boolean = false;
  myModal2: string = "none";
  cursor = "pointer";
  isPhoneValid: boolean = true;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.Canada];
  verifyBy: any;
  display: string = "none";
  redirectToVerification: any;
  isPhoneVerified: boolean = false;
  isEamilVerified: boolean = false;
  isPhoneExist: boolean = false;
  isEmailExist: boolean = false;
  verificationData: string;
  charCodeArr: any = [
    96, 49, 50, 51, 52, 53, 54, 55, 56, 57, 48, 45, 61, 91, 93, 92, 59, 44, 46, 47, 126, 33, 64, 35, 36, 37, 94, 38, 42, 40, 41, 95, 43, 123, 125,
    124, 58, 34, 60, 62, 63,
  ];
  validateFirstArr = [];
  validateSecondArr = [];
  cardNumberGroup: FormGroup;
  cardOwnerFirstName: FormControl = new FormControl("", Validators.required);
  cardOwnerLastName: FormControl = new FormControl("");
  expiryMonth: FormControl = new FormControl(null, Validators.required);
  expiryYear: FormControl = new FormControl(null, Validators.required);
  cvv: FormControl = new FormControl(null, Validators.required);
  expiryYears = [];
  cardSubmitted: boolean = false;
  allSavedCard: any = [];
  defaultCardDetails: any;
  selectedCardDetails: any;
  allSavedCardData: any = [];
  addCardModal: string = "none";
  isWalletSelected: boolean = false;
  selectedWalletCardId: any;
  addWalletMoneyModal: string = "none";
  addWalletAmount: FormControl = new FormControl("", Validators.required);
  walletMoneySubmitted: boolean = false;
  loyalityData: any = [];
  redeemLoyalityModal: string = "none";
  loyaltyBarCode: string;
  imgBaseurl = environment.imageUrl;
  ratingModal: string = "none";
  selectedLoyalityData: any;
  timeLeft: number;
  timerId: NodeJS.Timeout;
  cancelCounter = 0;
  timerStart: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  intervalSubscription: any;
  notificationData: any;
  currentPageN: any = 1;
  previousBtnEnabledN: boolean = false;
  nextBtnEnabledN: boolean = false;
  totalPagesN: any = 1;
  currentPageR: any = 1;
  previousBtnEnabledR: boolean = false;
  nextBtnEnabledR: boolean = false;
  totalPagesR: any = 1;
  currentPageA: any = 1;
  previousBtnEnabledA: boolean = false;
  nextBtnEnabledA: boolean = false;
  totalPagesA: any = 1;
  languageCode: string;
  showLang: boolean = false;
  ratingReviewData: any = [];
  favAddData: any = [];
  geoCoder: google.maps.Geocoder;
  favAddPayload: any;
  favAddModal: string = "none";
  favAddressSubmitted: boolean = false;
  carouselConfig = {
    infinite: false,
    arrows: false,
    dots: true,
  };
  allPackagesDetail: any = [];
  pickupFieldsArr: any = [];
  logoutAlertTitle: any;
  deleteAlertTitle: any;
  Yes: any;
  No: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private http: HttpsService,
    public commonService: CommonService,
    public constant: fConstant,
    private ngxService: NgxUiLoaderService,
    private messagingService: MessagingServiceService,
    public translate: TranslateService,
    public languageService: LanguageService,
    private socketService: SocketioService,
    private mapsAPILoader: MapsAPILoader
  ) {
    let nextPopup = this.route.snapshot.paramMap.get("nextPopup");
    nextPopup && (this.nextPopup = JSON.parse(nextPopup));
    nextPopup && (this.selectedItem = JSON.parse(nextPopup) - 1);
    this.baseUrl = environment.imageUrl;
    if (localStorage.languageCode !== undefined) {
      this.languageCode = localStorage.getItem("languageCode");
    }
    // this.translate.use(this.languageCode);
    // this.languageService.translateLanguage(this.languageCode)
  }

  ngOnInit() {
    if (this.nextPopup == 1) {
      this.getBookingHistory(this.constant.BOOKING_TYPE.RIDE, "currentPageUpcomingRide", 3);
    }
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
    });
    this.listenSocketEvent();
    this.createFavAddForm();
    this.getChange();
    this.languageService.isLangChange.subscribe((isChange: boolean) => {
      if (isChange) {
        console.log(this.router.url);

        this.getChange();
      }
    });
    if (localStorage.languageCode !== undefined) {
      this.languageCode = localStorage.getItem("languageCode");
    }
    // this.translate.use(this.languageCode);
    // this.languageService.translateLanguage(this.languageCode);
    if (localStorage.isNotificationActive !== undefined) {
      this.selectedItem = 4;
      this.nextPopup = 5;
      this.currentPageN = 1;
      this.getNotiFication(1);
      localStorage.removeItem("isNotificationActive");
    }
    this.getNotiFication(1);
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
    this.customRate.valueChanges.subscribe((value: any) => {
      this.selectedTip = 10000;
    });
    this.getUser();
    this.getRatingAndReview();
    this.getFavAddress();
    this.changePasswordFormSetup();
    this.createProfileForm();
    this.getFavDrivers();
    this.review.valueChanges.subscribe((value: any) => {
      // if (value) {
      //   this.selectedReviewData = "";
      // } else {
      //   this.selectedReviewData = "";
      // }
    });
    if (localStorage.isThisUpdatedUser !== undefined) {
      this.commonService.isThisUpdatedUser = JSON.parse(localStorage.getItem("isThisUpdatedUser"));
    } else {
      this.commonService.isThisUpdatedUser = false;
    }
    if (this.commonService.isThisUpdatedUser) {
      this.patchValue();
    } else {
      this.commonService.getUser().then((response) => {
        if (response) {
          this.isPhoneVerified = this.commonService.loggedInUser.isPhoneVerified;
          this.isEamilVerified = this.commonService.loggedInUser.isEamilVerified;
        }
      });
    }
    if (this.commonService.loggedInUser) {
      this.isPhoneVerified = this.commonService.loggedInUser.isPhoneVerified;
      this.isEamilVerified = this.commonService.loggedInUser.isEamilVerified;
    }
    this.profileForm.get("phoneNo").valueChanges.subscribe((value: any) => {
      if (value.e164Number) {
        this.isPhoneExist = false;
        if (
          this.commonService.loggedInUser.countryCode + this.commonService.loggedInUser.phoneNo === value.e164Number &&
          this.commonService.loggedInUser.isPhoneVerified
        ) {
          this.isPhoneVerified = true;
        } else {
          this.isPhoneVerified = false;
        }
      }
    });
    this.profileForm.get("email").valueChanges.subscribe((value: any) => {
      if (value) {
        this.isEmailExist = false;
        if (this.commonService.loggedInUser.email === value.toLowerCase() && this.commonService.loggedInUser.isEamilVerified) {
          this.isEamilVerified = true;
        } else {
          this.isEamilVerified = false;
        }
      }
    });
    this.cardNumberGroup = new FormGroup({
      cardNumber: new FormControl("", [Validators.required, Validators.minLength(12), luhnValidator()]),
    });
    //Determine the Current Year.
    var currentYear = new Date().getFullYear();
    for (var i = currentYear; i <= currentYear + 15; i++) {
      this.expiryYears.push(i.toString().slice(2));
    }
    this.getAllCard();
    this.getLoyalityData();
    this.timerStart.pipe(takeUntil(this.notifier)).subscribe((val: boolean) => {
      if (val) {
        this.startCounter();
      } else {
        this.cancelCounter = 0;
      }
    });
  }

  selectLang(language) {
    this.languageCode = language.code;
    this.languageService.selectedLang = language;
    this.languageService.translateLanguage(this.languageCode);
    this.showLang = false;
  }

  getChange() {
    this.translate
      .get([
        "My Rides",
        "My Deliveries",
        "Loyalty Points",
        "Review and Rating",
        "Notifications",
        "Manage Addresses",
        "Payment Options",
        "Change Password",
        "Favourite Drivers",
        "Logout",
        "Delete Account",
        "Are you sure you want to logout?",
        "Are you sure you want to delete your account?",
        "Yes",
        "No",
      ])
      .subscribe((translations) => {
        this.linkArr = [
          // { name: translations["My Rides"], isActive: false },
          // { name: translations["My Deliveries"], isActive: false },
          // { name: translations["Loyalty Points"], isActive: false },
          // // { name: translations["  "], isActive: false },
          // { name: translations["Review and Rating"], isActive: false },
          // { name: translations["Manage Addresses"], isActive: false },
          // { name: translations["Payment Options"], isActive: false },
          // { name: translations["Change Password"], isActive: false },
          // { name: translations["Favourite Drivers"], isActive: false },
          // { name: translations["Logout"], isActive: false },
          // { name: translations["Delete Account"], isActive: false },
          { name: translations["My Rides"], isActive: false },
          { name: translations["My Deliveries"], isActive: false },
          { name: translations["Loyalty Points"], isActive: false },
          { name: translations["Review and Rating"], isActive: false },
          { name: translations["Notifications"], isActive: false },
          { name: translations["Manage Addresses"], isActive: false },
          { name: translations["Payment Options"], isActive: false },
          { name: translations["Change Password"], isActive: false },
          { name: translations["Favourite Drivers"], isActive: false },
          { name: translations["Logout"], isActive: false },
          { name: translations["Delete Account"], isActive: false },
        ];
        this.logoutAlertTitle = translations["Are you sure you want to logout?"];
        this.deleteAlertTitle = translations["Are you sure you want to delete your account?"];
        this.Yes = translations["Yes"];
        this.No = translations["No"];
      });
  }

  startCounter() {
    this.cancelCounter = 30;
    if (this.intervalSubscription) {
      clearInterval(this.intervalSubscription);
      this.cancelCounter = 30;
    }
    this.intervalSubscription = setInterval(() => {
      if (this.cancelCounter <= 0) {
        clearInterval(this.intervalSubscription);
        this.redeemLoyalityModals("none");
        // localStorage.isCancelCounterStopped = true;
      }
      this.cancelCounter--;
    }, 1000);
  }

  patchValue() {
    this.commonService.getUser();
    this.editable = true;
    this.imageUrl = this.commonService.userUpdateImage;
    if (localStorage.userUpdateForm !== undefined) {
      this.commonService.userUpdateForm = JSON.parse(localStorage.getItem("userUpdateForm"));
    }
    this.profileForm.patchValue({
      firstName: this.commonService.userUpdateForm.firstName,
      lastName: this.commonService.userUpdateForm.lastName,
      email: this.commonService.userUpdateForm.email,
    });
    this.profileForm.get("phoneNo").patchValue(this.commonService.userUpdateForm.phoneNo);
  }

  getRatingAndReview() {
    var param = {
      pageNo: this.currentPageR,
    };
    this.http
      .httpPostWithHeaderUser("getRatingAndReview", param)
      .pipe(takeUntil(this.notifier))
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          // this.currentPageR = 1;
          this.ratingReviewData = res.data;
          this.totalPagesR = res.data.totalPages;
          this.nextBtnEnabledR = this.currentPageR < this.totalPagesR;
          this.previousBtnEnabledR = this.currentPageR > 1;
        }
      });
  }

  getFavDrivers() {
    this.http.httpPostWithHeaderUser("getAllFavouriteDriver", { pageNo: 1 }).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.favouriteDriverData = res.data.favouriteDriverData;
        console.log(this.favouriteDriverData);
      }
    });
  }

  toggle() {
    this.tgshow = !this.tgshow;
  }

  toggleClick(index, data) {
    var itemIndex = 1;
    if (index === 0) {
      this.getBookingHistory(this.constant.BOOKING_TYPE.RIDE, "currentPageUpcomingRide", 3);
      this.nextPopup = index + itemIndex;
      this.selectedItem = index;
      return;
    } else if (index === 1) {
      this.getBookingHistory(this.constant.BOOKING_TYPE.DELIVERY, "currentPageUpcomingRide", 3);
      this.nextPopup = index + itemIndex;
      this.selectedItem = index;
      return;
    } else if (index === this.linkArr.length - 1) {
      this.Alert(10, "Are you sure you want to delete your account?", "accDelete");
      return;
    } else if (data.name === 8) {
      this.getFavDrivers();
    }
    this.nextPopup = index + itemIndex;
    this.selectedItem = index;
    if (this.nextPopup === 4) {
      this.getNotiFication(1);
    }
  }

  Alert(index, title, type) {
    // if(type === 'logout') {
    this.selectedItem = index;
    // }
    Swal.fire({
      title: type === "logout" ? this.logoutAlertTitle : this.deleteAlertTitle,
      // text: "You won't be able to revert this!",
      icon: "info",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: this.Yes,
      cancelButtonText: this.No,
      allowOutsideClick: false,
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        if (type === "logout") {
          this.logout();
        } else if (type === "accDelete") {
          this.deleteAccount();
        }
      } else {
      }
    });
  }

  deleteAccount() {
    this.http.httpPostWithHeaderUser("deleteUser", "").subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.commonService.localStorageCLear();
        this.router.navigate(["/riderlogin"]);
      }
    });
  }

  getUser() {
    this.ngxService.start();
    this.http.httpGetWithHeaderUser("userProfile", "").subscribe((res: any) => {
      if (res.statusCode === 200) {
        this.user = res.data;
        this.imageUrl = this.user.image;
        if (res.data.role === "DELIVERY_PARTNER") {
          this.commonService.isThisDeliveryPartner = true;
        } else if (res.data.role === "RIDER") {
          this.commonService.isThisDeliveryPartner = false;
        }
        this.ngxService.stop();
      }
    });
  }

  createProfileForm() {
    this.profileForm = this.fb.group({
      proImage: [""],
      phoneNo: [null, [Validators.required, Validators.minLength(6)]],
      firstName: [null, [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]],
      lastName: [null, [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]],
      email: ["", [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
      // gender: [null, Validators.required],
    });
  }

  edit() {
    this.editable = true;
    this.imageUrl = this.commonService.userUpdateImage;
    this.profileForm.patchValue({
      firstName: this.commonService.loggedInUser.firstName,
      lastName: this.commonService.loggedInUser.lastName,
      email: this.commonService.loggedInUser.email,
    });
    this.profileForm.get("phoneNo").patchValue({
      dialCode: this.commonService?.loggedInUser?.countryCode,
      e164Number: this.commonService?.loggedInUser?.countryCode + this.commonService?.loggedInUser?.phoneNo,
      number: this.commonService?.loggedInUser?.countryCode + " " + this.commonService?.loggedInUser?.phoneNo,
    });
    this.isEamilVerified = this.commonService.loggedInUser.isEamilVerified;
    this.isPhoneVerified = this.commonService.loggedInUser.isPhoneVerified;
  }

  changePasswordFormSetup() {
    this.changePassForm = this.fb.group(
      {
        password: ["", Validators.required],
        newPassword: ["", [Validators.required, Validators.minLength(6)]],
        confirmPassword: ["", Validators.required],
      },
      {
        validator: MustMatch("newPassword", "confirmPassword"),
      }
    );
  }

  changePassword() {
    this.changePassSubmitted = true;
    if (this.changePassForm.valid) {
      let data = {
        password: this.changePassForm.value.password.trim(),
        newPassword: this.changePassForm.value.newPassword.trim(),
      };
      this.http.httpPostWithHeaderUser("changePassword", data).subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.commonService.OpenNotificationModal("success", "top-end", res.message);
          this.changePassForm.reset();
          this.changePassSubmitted = false;
        }
      });
    }
  }

  onFileUpload(fileInput: any) {
    const fileData = <File>fileInput.target.files[0];
    this.http.userFileUpload(fileData).subscribe((res) => {
      this.imageUrl = JSON.parse(JSON.stringify(res)).data.orignal;
      this.commonService.userUpdateImage = this.imageUrl;
      localStorage.setItem("userUpdateImage", this.imageUrl);
    });
  }

  goTouhomepage() {
    if (this.commonService.isThisDeliveryPartner) {
      this.router.navigate(["riderdeliverybooking"]);
    } else {
      this.router.navigate(["/riderequest"]);
    }
  }

  goTowelcome() {
    this.router.navigate(["/welcome"]);
  }
  goToriderequest() {
    this.router.navigate(["/riderequest"]);
  }

  logout() {
    this.commonService.isThisUpdatedUser = false;
    localStorage.removeItem("isThisUpdatedUser");
    this.http.httpPostWithHeaderUser("logout", "").subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.commonService.localStorageCLear();
        this.router.navigate(["/riderlogin"]);
      }
    });
    this.commonService.localStorageCLear();
    this.router.navigate(["/riderlogin"]);
    this.commonService.origin = { lat: 0, lng: 0, address: "" };
    this.commonService.destination = { lat: 0, lng: 0, address: "" };
  }
  goToriderdetail() {
    this.router.navigate(["/riderdetail"]);
  }

  continue(popupType) {
    if (popupType === 0) {
      this.selectedItem = 1000;
      this.editable = false;
      this.commonService.getUser().then((response) => {
        if (response) {
          this.isPhoneVerified = this.commonService.loggedInUser.isPhoneVerified;
          this.isEamilVerified = this.commonService.loggedInUser.isEamilVerified;
        }
      });
    }
    this.nextPopup = popupType;
  }

  verifyPhone() {
    this.submitted = true;
    if (this.profileForm.get("phoneNo").valid) {
      var codeLength = this.profileForm.value.phoneNo.dialCode.length;
      var param = $.extend(
        {},
        {
          phoneNo: this.profileForm.value.phoneNo.e164Number.slice(codeLength),
          userId: localStorage.userId !== undefined ? localStorage.userId : undefined,
        }
      );
      this.http.httpPostUser("checkIfPhoneNumberExist", param).subscribe((val: any) => {
        if (val.data.isValid) {
          this.isPhoneExist = false;
          this.submitted = false;
          this.verifyBy = {
            type: "PHONE",
            phoneNo: this.profileForm.value.phoneNo.e164Number.slice(codeLength),
            countryCode: this.profileForm.value.phoneNo.dialCode,
          };
          this.verificationData = this.profileForm.value.phoneNo.dialCode + " " + this.profileForm.value.phoneNo.e164Number.slice(codeLength);
          this.display = "block";
          this.editable = false;
        } else {
          this.submitted = true;
          this.isPhoneExist = true;
        }
      });
    }
  }

  verifyEmail() {
    this.submitted = true;
    if (this.profileForm.get("email").valid) {
      var param = $.extend(
        {},
        {
          email: this.profileForm.value.email.toLowerCase(),
          userId: localStorage.userId !== undefined ? localStorage.userId : undefined,
        }
      );
      this.http.httpPostUser("checkIfEmailExist", param).subscribe((value: any) => {
        if (value.data.isValid) {
          this.submitted = false;
          this.verifyBy = {
            type: "EMAIL",
            email: this.profileForm.value.email,
          };
          this.verificationData = this.profileForm.value.email;
          var codeLength = this.profileForm.value.phoneNo.dialCode.length;
          var param = {
            phoneNo: this.profileForm.value.phoneNo.e164Number.slice(codeLength),
            countryCode: this.profileForm.value.phoneNo.dialCode,
          };
          this.display = "block";
          this.editable = false;
          // this.http.httpPostWithHeaderUser('sendOtpWithAuth', { email: this.profileForm.value.email }).subscribe((res: any) => {
          //   if (res.statusCode == 200) {
          //     this.commonService.setVerificationData({ countryCode: param?.countryCode, phoneNo: param?.phoneNo, otpCode: res.data.otpCode })
          //     this.commonService.userUpdateForm = this.profileForm;
          //     // this.commonService.userUpdateImage = this.imageUrl;
          //     this.commonService.emailForVerification = this.profileForm.value.email;
          //     this.commonService.isThisUpdatedUser = true;
          //     this.redirectToVerification = '/verifyemail';
          //     this.display = 'block';
          //     this.editable = false;
          //   }
          // });
        } else {
          this.submitted = true;
          this.isEmailExist = true;
        }
      });
    }
  }

  sendOtp() {
    var newParam;
    if (this.verifyBy.type === "EMAIL") {
      newParam = {
        email: this.verifyBy.email,
        isPrelaunch: false,
      };
    } else if (this.verifyBy.type === "PHONE") {
      newParam = {
        phoneNo: this.verifyBy.phoneNo,
        countryCode: this.verifyBy.countryCode,
      };
    }
    this.http.httpPostWithHeaderUser("sendOtpWithAuth", newParam).subscribe((res: any) => {
      if (res.statusCode == 200) {
        if (this.verifyBy.type === "PHONE") {
          localStorage.setItem("phone", this.verifyBy.phoneNo);
          localStorage.setItem("phoneNo", this.verifyBy.phoneNo);
          localStorage.setItem("countryCode", this.verifyBy.countryCode);
          this.redirectToVerification = "/riderotppage";
        } else {
          this.redirectToVerification = "/verifyemail";
          this.commonService.isThisUpdatedUser = true;
          localStorage.setItem("email", this.profileForm.value.email);
          this.commonService.emailForVerification = this.profileForm.value.email;
        }
        this.commonService.setVerificationData({
          countryCode: this.verifyBy?.countryCode,
          phoneNo: this.verifyBy?.phoneNo,
          otpCode: res.data.otpCode,
        });
        this.commonService.userUpdateForm = this.profileForm.value;
        localStorage.setItem("userUpdateForm", JSON.stringify(this.profileForm.value));
        this.commonService.userUpdateImage = this.imageUrl;
        localStorage.setItem("userUpdateImage", this.imageUrl);
        this.commonService.isThisUpdatedUser = true;
        localStorage.setItem("isThisUpdatedUser", "true");
        this.display = "none";
        this.editable = false;
        // var formData = new FormData();
        // formData.append('isEamilVerified', "false");
        // formData.append('email', this.commonService.userUpdateForm.value.email);
        // this.http.httpPostWithFormDataHeader('updateUserProfile', formData).subscribe();
        this.goToriderotppage();
      }
    });
  }

  verification() {
    this.submitted = true;
    if (this.profileForm.valid && this.isEamilVerified && this.isPhoneVerified && !this.isEmailExist && !this.isPhoneExist) {
      this.editable = false;
      this.commonService.userUpdateForm = this.profileForm.value;
      localStorage.setItem("userUpdateForm", JSON.stringify(this.profileForm.value));
      this.commonService.updateUser();
    }
  }

  goToriderotppage() {
    this.commonService.isThisUpdatedUser = true;
    localStorage.setItem("isThisUpdatedUser", "true");
    this.router.navigate([this.redirectToVerification]);
  }

  cancel() {
    this.editable = false;
    this.submitted = false;
    this.commonService.getUser().then((response) => {
      if (response) {
        this.isPhoneVerified = this.commonService.loggedInUser.isPhoneVerified;
        this.isEamilVerified = this.commonService.loggedInUser.isEamilVerified;
        this.commonService.userUpdateImage = this.commonService.loggedInUser.image;
        localStorage.setItem("userUpdateImage", this.commonService.loggedInUser.image);
      }
    });
  }

  getBookingHistory(type, currentPage, bookingStatus) {
    var param = {
      bookingType: type,
      pageNo: this[currentPage],
      bookingStatus: bookingStatus,
    };
    this.http.httpPostWithHeaderUser("getBookingHistory", param).subscribe((res: any) => {
      if (res.statusCode === 200) {
        // this.bookingStatusDataRide = [];
        // this.bookingStatusDataDelivery = [];
        if (res.data.bookingType === this.constant.BOOKING_TYPE.RIDE) {
          // this.bookingStatusDataRide = res.data.bookingData;
          if (res.data.bookingStatus === 3) {
            this.bookingDataUpcomingRide = [];
            this.bookingDataUpcomingRide = res.data.bookingData;
            this.totalPagesUpcomingRide = res.data.totalPages;
          } else if (res.data.bookingStatus === 2) {
            this.bookingDataCanceledRide = [];
            this.bookingDataCanceledRide = res.data.bookingData;
            this.totalPagesCanceledRide = res.data.totalPages;
          } else if (res.data.bookingStatus === 1) {
            this.bookingDataCompletedRide = [];
            this.bookingDataCompletedRide = res.data.bookingData;
            this.totalPagesCompletedRide = res.data.totalPages;
          }
        } else if (res.data.bookingType === this.constant.BOOKING_TYPE.DELIVERY) {
          // this.bookingStatusDataDelivery = res.data.bookingData;
          if (res.data.bookingStatus === 3) {
            this.bookingDataUpcomingDelivery = [];
            this.bookingDataUpcomingDelivery = res.data.bookingData;
            this.totalPagesUpcomingDelivery = res.data.totalPages;
          } else if (res.data.bookingStatus === 2) {
            this.bookingDataCanceledDelivery = [];
            this.bookingDataCanceledDelivery = res.data.bookingData;
            this.totalPagesCanceledDelivery = res.data.totalPages;
          } else if (res.data.bookingStatus === 1) {
            this.bookingDataCompletedDelivery = [];
            this.bookingDataCompletedDelivery = res.data.bookingData;
            this.totalPagesCompletedDelivery = res.data.totalPages;
          }
        }
      }
    });
  }

  onNextPage(type, totalPages, currentPage, bookingStatus) {
    if (type === "RIDE") {
      this[currentPage]++;
      this.updateTableRide(totalPages, currentPage, bookingStatus);
    } else {
      this[currentPage]++;
      this.updateTableDelivery(totalPages, currentPage, bookingStatus);
    }
  }
  onChnagePage(page, type, totalPages, currentPage, bookingStatus) {
    if (type === "RIDE") {
      this[currentPage] = page;
      this.updateTableRide(totalPages, currentPage, bookingStatus);
    } else {
      this[currentPage] = page;
      this.updateTableDelivery(totalPages, currentPage, bookingStatus);
    }
  }
  onPrevPage(type, totalPages, currentPage, bookingStatus) {
    if (type === "RIDE") {
      this[currentPage]--;
      this.updateTableRide(totalPages, currentPage, bookingStatus);
    } else {
      this[currentPage]--;
      this.updateTableDelivery(totalPages, currentPage, bookingStatus);
    }
  }

  updateTableRide(totalPages, currentPage, bookingStatus) {
    this.previousBtnEnabled = this[currentPage] > 1;
    this.nextBtnEnabled = this[currentPage] < this[totalPages];
    this.getBookingHistory(this.constant.BOOKING_TYPE.RIDE, currentPage, bookingStatus);
  }

  updateTableDelivery(totalPages, currentPage, bookingStatus) {
    this.previousBtnEnabled = this[currentPage] > 1;
    this.nextBtnEnabled = this[currentPage] < this[totalPages];
    this.getBookingHistory(this.constant.BOOKING_TYPE.DELIVERY, currentPage, bookingStatus);
  }

  onTabChange(type, index) {
    this.selectedTabIndex = index;
    if (type === "RIDE") {
      if (index === 0) {
        this.onChnagePage(1, type, "totalPagesUpcomingRide", "currentPageUpcomingRide", 3);
      } else if (index === 1) {
        this.onChnagePage(1, type, "totalPagesCompletedRide", "currentPageCompletedRide", 1);
      } else if (index === 2) {
        this.onChnagePage(1, type, "totalPagesCanceledRide", "currentPageCanceledRide", 2);
      }
    } else if (type === "DELIVERY") {
      if (index === 0) {
        this.onChnagePage(1, type, "totalPagesUpcomingDelivery", "currentPageUpcomingDelivery", 3);
      } else if (index === 1) {
        this.onChnagePage(1, type, "totalPagesCompletedDelivery", "currentPageCompletedDelivery", 1);
      } else if (index === 2) {
        this.onChnagePage(1, type, "totalPagesCanceledDelivery", "currentPageCanceledDelivery", 2);
      }
    }
  }

  favouriteDriver(driverId, isFav) {
    if (isFav) {
      this.http
        .httpPostWithHeaderUser("addFavouriteDriver", {
          driverId: driverId,
          isFav: isFav,
        })
        .subscribe((res: any) => {
          if (res.statusCode == 200) {
            this.getFavDrivers();
          }
        });
    } else {
      this.http
        .httpPostWithHeaderUser("addFavouriteDriver", {
          driverId: driverId,
          isFav: isFav,
        })
        .subscribe((res: any) => {
          if (res.statusCode == 200) {
            this.getFavDrivers();
          }
        });
    }
  }

  addFavouriteDriver() {
    this.http
      .httpPostWithHeaderUser("addFavouriteDriver", {
        driverId: this.bookingResponseData.driverId.id,
        isFav: true,
      })
      .pipe(takeUntil(this.notifier))
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.isDriverFavourite = true;
          this.commonService.OpenNotificationModal("success", "top-end", res.message);
          this.getDriverRatingsReview();
        }
      });
  }

  removeFavouriteDriver() {
    this.http
      .httpPostWithHeaderUser("addFavouriteDriver", {
        driverId: this.bookingResponseData.driverId.id,
        isFav: false,
      })
      .pipe(takeUntil(this.notifier))
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.isDriverFavourite = false;
          this.commonService.OpenNotificationModal("success", "top-end", res.message);
          this.getDriverRatingsReview();
        }
      });
  }

  //for rating
  onClick(rating: number) {
    // console.log(rating)
    // this.snackBar.open('You rated ' + rating + ' / ' + this.starCount, '', {
    //   duration: this.snackBarDuration
    // });
    this.ratingUpdated.emit(rating);
    this.rating = rating;
    if (this.rating > 0 && this.rating < 3) {
      this.review.setValidators(Validators.required);
      this.review.updateValueAndValidity();
    } else {
      this.review.clearValidators();
      this.review.updateValueAndValidity();
    }
    return false;
  }

  showIcon(index: number) {
    if (this.rating >= index + 1) {
      return "star";
    } else {
      return "star_border";
    }
  }

  selectReview(index, data) {
    this.selectedDriverReview = index;
    this.selectedReviewData = data.isActive ? "" : data.name;
  }

  selectTip(i, amount) {
    this.selectedTip = i;
    this.selectedTipAmount = amount;
    if (this.customRate.value != null) {
      this.customRate.patchValue(null);
      this.selectedTip = i;
    }
  }

  onTipChange(value) {
    if (value <= 0) {
      this.customRate.patchValue(null);
    }
  }

  addRating(index) {
    this.ratingSubmitted = true;
    if (
      this.rating > 0 &&
      (this.rating <= 2 ? this.review.value != "" : this.selectedReviewData != "") &&
      this.wasTheDriverOnTime.value != null &&
      this.wasTheRideSafe.value != null &&
      this.driverMakeYouComfortabel.value != null
    ) {
      this.selectedFeedbckQna = [];
      this.selectedFeedbckQna.push({
        question: "Was the driver on time?",
        answere: this.wasTheDriverOnTime.value,
      });
      this.selectedFeedbckQna.push({
        question: "Was the ride safe?",
        answere: this.wasTheRideSafe.value,
      });
      this.selectedFeedbckQna.push({
        question: "Did the driver make your ride comfortabel?",
        answere: this.driverMakeYouComfortabel.value,
      });
      this.selectedFeedbckQna.forEach((element) => {
        if (element.answere === null) {
          const index = this.selectedFeedbckQna.indexOf(element);
          this.selectedFeedbckQna.splice(index, 1);
        }
      });
      this.selectedFeedbckQna.forEach((element) => {
        if (element.answere === null) {
          const index = this.selectedFeedbckQna.indexOf(element);
          this.selectedFeedbckQna.splice(index, 1);
        }
      });
      var param = $.extend(
        {},
        {
          bookingId: this.bookingId,
          driverId: this.bookingResponseData.driverId.id,
          driverRating: this.rating,
          driverReview: this.rating < 3 ? this.review.value : this.selectedReviewData,
          tip: this.customRate.value ? this.customRate.value : this.selectedTipAmount,
          comment: this.comment.value ? this.comment.value : undefined,
          feedBack: this.selectedFeedbckQna,
        }
      );
      this.http
        .httpPostWithHeaderUser("addRatings", param)
        .pipe(takeUntil(this.notifier))
        .subscribe((res: any) => {
          if (res.statusCode == 200) {
            // this.commonService.OpenNotificationModal('success', 'top-end', res.message);
            this.rating = 0;
            this.ratingSubmitted = false;
            this.customRate.patchValue(null);
            this.selectedReviewData = "";
            this.comment.patchValue("");
            this.selectedFeedbckQna = [];
            this.review.patchValue("");
            this.selectedDriverReview = null;
            this.wasTheDriverOnTime.patchValue(null);
            this.wasTheRideSafe.patchValue(null);
            this.driverMakeYouComfortabel.patchValue(null);
            this.closeratingModal();
            this.onChnagePage(1, "RIDE", "totalPagesCompletedRide", "currentPageCompletedRide", 1);
            this.onChnagePage(1, "DELIVERY", "totalPagesCompletedDelivery", "currentPageCompletedDelivery", 1);
            this.onChnagePage(1, "RIDE", "totalPagesCanceledRide", "currentPageCanceledRide", 2);
            this.onChnagePage(1, "DELIVERY", "totalPagesCanceledDelivery", "currentPageCanceledDelivery", 2);
          }
        });
    }
  }

  getDriverRatingsReview() {
    this.http
      .httpPostWithHeaderUser("getDriverRatingsReview", {
        driverId: this.bookingResponseData.driverId._id,
      })
      .pipe(takeUntil(this.notifier))
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.driverReviewData = res.data.driverData;
        }
      });
  }

  goToStatusPageOfRide(bookingData) {
    this.commonService.bookingResponseData = bookingData.bookingData;
    this.commonService.bookingResponseData["subBookingData"] = bookingData.subBookingData;
    this.commonService.bookingDataForLanguageUpdateOnly = JSON.parse(JSON.stringify(this.commonService.bookingResponseData));
    console.log(this.commonService.bookingResponseData);
    // this.openBookingStatusModal(bookingData);
    this.router.navigate([this.redirectTo]);
    let todayDate = moment(new Date(bookingData.bookingData.bookingAcceptedDate));
    let endDate = moment(new Date(new Date()));
    let diff = endDate.diff(todayDate, "seconds");
    console.log("diffrence in seconds", diff);
    if (diff <= 10) {
      this.commonService.cancelationTimerStart.next({ isActive: true, time: diff });
    } else {
      this.commonService.cancelationTimerStart.next({ isActive: false, time: 0 });
    }
  }

  goToStatusPageOfDelivery(bookingData) {
    this.commonService.bookingResponseData = bookingData.bookingData;
    this.commonService.bookingResponseData["subBookingData"] = bookingData.subBookingData;
    this.commonService.bookingDataForLanguageUpdateOnly = JSON.parse(JSON.stringify(this.commonService.bookingResponseData));
    // this.openBookingStatusModal(bookingData);
    this.router.navigate(["/riderdeliverybooking"]);
  }

  openRatingModal(response) {
    this.bookingResponseData = response;
    this.getDriverRatingsReview();
    console.log(response);
    this.bookingId = response.id;
    this.driverReviewData = response.driverId;
    this.ratingModal = "block";
    // $("#ratingModal").modal('show');
  }

  closeratingModal() {
    this.ratingModal = "none";
    // $("#ratingModal").modal('hide');
  }

  openBookingStatusModal(response, bookingType) {
    this.http
      .httpPostWithHeaderUser("getBookingDetails", { bookingId: response.id })
      .pipe(takeUntil(this.notifier))
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.bookingsData = res.data;
          this.bookingId = response.id;
          this.driverReviewData = response.driverId;
          this.commonService.bookingResponseData = null;
          this.commonService.bookingDataForLanguageUpdateOnly = null;
          // this.commonService.bookingResponseData["subBookingData"] = res.data.subBookingData;
          if (bookingType === "RIDE") {
            this.redirectTo = "riderequest";
          } else {
            this.allPackagesDetail = [];
            this.redirectTo = "riderdeliverybooking";
            this.bookingsData.subBookingData.forEach((element) => {
              if (element.isPickUp) {
                this.pickupFieldsArr.push({
                  name: null,
                  lat: element.latitude,
                  lng: element.longitude,
                  address: null,
                  distance: 0,
                });
              } else if (
                element.bookingStatus !== this.constant.BOOKING_STATUS.NOT_AVAILABLE &&
                element.bookingStatus !== this.constant.BOOKING_STATUS.NONE
              ) {
                let itemDropDetails = {
                  deliveryTo: element.addressType,
                  itemDropup: {
                    lat: element.latitude,
                    lng: element.longitude,
                    address: element.address,
                    distance: 0,
                  },
                  streetAdd: element.streetAddress,
                  suiteAptOffice: element.suitAddress,
                  city: element.city,
                  province: element.province,
                  zipCode: element.zipCode,
                  additionalInfo: element.note,
                  phoneNo: element.phoneNo,
                  countryCode: element.countryCode,
                };
                this.allPackagesDetail.push({
                  packageType: element.packageName + "," + element.packageTypeId,
                  desc: element.itemDescription,
                  quantity: element.itemQuantity,
                  image: element.itemImage,
                  addressDetails: itemDropDetails,
                  cancelReason: element.cancelReason ? element.cancelReason : this.bookingsData.bookingData.cancelReason,
                  bookingStatus: element.bookingStatus,
                });
              }
            });
            let arr = this.allPackagesDetail.map((val) => val.cancelReason);
            if (arr.includes(false)) {
            }
          }
          // this.commonService.bookingResponseData = this.bookingsData.bookingData;
          // this.commonService.bookingResponseData["subBookingData"] = this.bookingsData.subBookingData;
          this.myModal2 = "block";
          // $("#myModal2").modal('show');
        }
      });
  }

  openBookingModal(data) {
    this.http
      .httpPostWithHeaderUser("getBookingDetails", {
        bookingId: data.bookingId,
      })
      .pipe(takeUntil(this.notifier))
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.bookingsData = res.data;
          this.bookingId = data.bookingId;
          this.driverReviewData = data.receiverId;
          // this.commonService.bookingResponseData = res.data.bookingData;
          // this.commonService.bookingResponseData["subBookingData"] = res.data.subBookingData;
          if (res.data.bookingData.bookingType === 1) {
            this.redirectTo = "riderequest";
          } else {
            this.redirectTo = "riderdeliverybooking";
          }
          this.myModal2 = "block";
          this.getNotiFication(this.currentPageN);
          // $("#myModal2").modal('show');
        }
      });
  }

  closeBookingStatusModal() {
    this.myModal2 = "none";
    // $("#myModal2").modal('hide');
  }

  onKeyPress(event) {
    if (event.charCode !== 32 && event.charCode !== 39) {
      return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123);
    }
  }

  onChangeFirst(value) {
    this.validateFirstArr = [];
    for (let i = 0; i < value.length; i++) {
      var charCode = value.charCodeAt(i);
      this.validateFirstArr.push(this.charCodeArr.includes(charCode));
    }
  }

  onChangeSecond(value) {
    this.validateSecondArr = [];
    for (let i = 0; i < value.length; i++) {
      var charCode = value.charCodeAt(i);
      this.validateSecondArr.push(this.charCodeArr.includes(charCode));
    }
  }

  goToCmsPages(url) {
    window.open(environment.webUrl + url, "_blank");
  }

  getCardNumberControl(): AbstractControl | null {
    return this.cardNumberGroup && this.cardNumberGroup.get("cardNumber");
  }

  cardMaskFunction(rawValue: string) {
    const card = getValidationConfigFromCardNo(rawValue);
    console.log(card);
    // if (this.cvvLength) {
    //   this.cvvLength = card.cvvLength[0];
    // }
    if (card) {
      return card.mask;
    }
    return [/\d/];
  }

  onCvvPress(value) {
    console.log(value);
    // if (value.length !== this.cvvLength) {
    //   return false;
    // }
  }

  AddCardModal(display) {
    this.addCardModal = display;
  }

  addDebitCard() {
    this.cardSubmitted = true;
    if (
      this.cardNumberGroup.valid &&
      this.cardOwnerFirstName.valid &&
      this.expiryMonth.valid &&
      !this.validateFirstArr.includes(true) &&
      this.expiryYear.valid &&
      this.cvv.valid
    ) {
      var param = {
        cardNumber: this.cardNumberGroup.get("cardNumber").value,
        expDate: this.expiryMonth.value + this.expiryYear.value,
        cvv: this.cvv.value,
        firstName: this.cardOwnerFirstName.value,
        lastName: this.cardOwnerLastName.value,
      };
      this.ngxService.start();
      this.http.httpPostWithHeaderUser("userCard", param).subscribe(
        (res: any) => {
          this.ngxService.stop();
          if (res.statusCode == 200) {
            this.addCardModal = "none";
            this.commonService.presentsToast("success", "top-end", "Your payment card has been added successfully.");
            this.cardSubmitted = false;
            this.getAllCard();
            this.cardOwnerFirstName.reset();
            this.cardOwnerLastName.reset();
            this.expiryMonth.patchValue(null);
            this.expiryYear.patchValue(null);
            this.cvv.patchValue(null);
            this.cardNumberGroup.get("cardNumber").reset();
          }
        },
        (err) => this.ngxService.stop()
      );
    }
  }

  getAllCard() {
    this.http.httpGetWithHeaderOnly("userCard").subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.allSavedCard = [];
        this.allSavedCardData = res.data;
        if (res.data.cards.length > 0) {
          res.data.cards.forEach((data: any) => {
            if (data.isDefault) {
              this.defaultCardDetails = data;
              this.selectedCardDetails = data;
              data["isSelected"] = true;
              this.allSavedCard.push(data);
            } else {
              data["isSelected"] = false;
              this.allSavedCard.push(data);
            }
          });
        }
        this.isWalletSelected = false;
      }
    });
  }

  changeDefaultCard(cardId) {
    this.http.httpPostWithHeaderUser("defaultCard", { id: cardId }).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.getAllCard();
      }
    });
  }

  deleteCard(cardId) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.http.httpDeleteByIdUser("userCard", cardId).subscribe((res: any) => {
          if (res.statusCode == 200) {
            Swal.fire("Deleted!", "Card has been deleted.", "success");
            this.getAllCard();
          }
        });
      }
    });
  }

  onCardChange(index) {
    this.allSavedCard = [];
    this.selectedCardDetails = this.allSavedCardData.cards[index - 1];
    this.isWalletSelected = false;
    this.allSavedCardData.cards.forEach((data: any) => {
      if (data.id === this.selectedCardDetails?.id) {
        data["isSelected"] = true;
        this.allSavedCard.push(data);
      } else {
        data["isSelected"] = false;
        this.allSavedCard.push(data);
      }
    });
  }

  onWalletSelected(value) {
    this.isWalletSelected = value;
    if (value) {
      this.allSavedCard = [];
      this.allSavedCardData.cards.forEach((data: any) => {
        data["isSelected"] = false;
        this.allSavedCard.push(data);
      });
    } else {
      this.getAllCard();
    }
  }

  addWalletModal(style) {
    this.addWalletMoneyModal = style;
    if (this.allSavedCardData.cards.length > 0 && style === "block") {
      this.addWalletMoneyModal = style;
      this.defaultCardDetails.id && (this.selectedWalletCardId = this.defaultCardDetails.id);
    } else if (this.allSavedCardData.cards.length === 0 && style === "block") {
      this.commonService.OpenNotificationModal("success", "top-end", "Please add payment card first.");
    }
    if (style === "none") {
      this.addWalletMoneyModal = style;
    }
  }

  onWalletCardChange(card) {
    this.selectedWalletCardId = card.id;
  }

  addWalletMoney() {
    this.walletMoneySubmitted = true;
    if (this.addWalletAmount.valid && parseInt(this.addWalletAmount.value) > 0) {
      this.ngxService.start();
      this.http
        .httpPostWithHeaderUser("wallet", {
          amount: this.addWalletAmount.value,
          cardId: this.selectedWalletCardId,
        })
        .subscribe(
          (res: any) => {
            this.ngxService.stop();
            if (res.statusCode == 200) {
              this.addWalletModal("none");
              this.addWalletAmount.reset();
              this.getAllCard();
            }
          },
          (err) => this.ngxService.stop()
        );
    } else if (parseInt(this.addWalletAmount.value) <= 0) {
      this.addWalletAmount.patchValue(null);
    }
  }

  keypresshandler(event) {
    var charCode = event.keyCode;
    //Non-numeric character range
    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
  }

  getLoyalityData() {
    this.http.httpPostWithHeaderUser("getAllLoyalityPoint", "").subscribe((res: any) => {
      console.log(res);
      if (res.statusCode == 200) {
        this.loyalityData = res.data;
      }
    });
  }

  redeemLoyality(data) {
    if (!data.isRedeemed) {
      this.redeemLoyalityModals("block");
      // this.redeemLoyalityPoint(data);
      this.selectedLoyalityData = data;
      console.log(this.selectedLoyalityData);
    } else {
      // this.selectedLoyalityData = data;
      // this.redeemLoyalityModals('block');
      // this.commonService.OpenNotificationModal('success','top-end','This loyalty already been used.')
    }
  }

  redeemLoyalityModals(style) {
    this.redeemLoyalityModal = style;
    if (style === "none") {
      this.getLoyalityData();
      this.selectedLoyalityData = "";
      this.loyaltyBarCode = "";
    }
  }

  redeemLoyalityPoint() {
    this.getLoyalityData();
    this.http
      .httpPostWithHeaderUser("redeemLoyalityPoint", {
        loyalityId: this.selectedLoyalityData._id,
      })
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.loyaltyBarCode = this.selectedLoyalityData.loyalityId.barCode;
          this.redeemLoyalityModals("block");
          this.timerStart.next(true);
          setTimeout(() => {
            this.redeemLoyalityModals("none");
            this.getLoyalityData();
          }, 30000);
        }
      });
  }

  countdown() {
    if (this.timeLeft == -1) {
      clearTimeout(this.timerId);
      this.redeemLoyalityModals("none");
    } else {
      this.timeLeft--;
    }
  }

  getNotiFication(pageNo) {
    this.http.httpPostWithHeaderUser("getAllNotification", { pageNo: pageNo }).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.notificationData = res.data.notificationData;
        this.totalPagesN = res.data.pageCount;
        this.nextBtnEnabledN = this.currentPageN < this.totalPagesN;
        this.previousBtnEnabledN = this.currentPageN > 1;
        this.messagingService.notificationCount = 0;
      }
    });
  }

  cancelBooking(data, type, totalPages, currentPage, bookingStatus) {
    Swal.fire({
      title: "Are you sure?",
      icon: "info",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      allowOutsideClick: false,
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        this.ngxService.start();
        this.http
          .httpPostWithHeaderUser("cancelBooking", { bookingId: data._id })
          .pipe(takeUntil(this.notifier))
          .subscribe(
            (res: any) => {
              if (res.statusCode === 200) {
                if (type === "RIDE") {
                  this.updateTableRide(totalPages, currentPage, bookingStatus);
                } else {
                  this.updateTableDelivery(totalPages, currentPage, bookingStatus);
                }
              }
            },
            (err) => this.ngxService.stop()
          );
      } else {
      }
    });
  }

  listenSocketEvent() {
    this.socketService
      .onEvent(Event.sendNotificationToUser)
      .pipe(takeUntil(this.notifier))
      .subscribe((response: any) => {
        console.log(response);
        this.bookingsData = { bookingData: response.data };
        this.commonService.bookingResponseData = response;
        this.commonService.bookingDataForLanguageUpdateOnly = response;
        this.onTabChange("RIDE", this.selectedTabIndex);
        this.onTabChange("DELIVERY", this.selectedTabIndex);
      });
  }

  handleLocationChnage(address) {
    if (address.formatted_address) {
      console.log(address.formatted_address);
      let lat = address.geometry.location.lat();
      let lng = address.geometry.location.lng();
      this.favAddPayload.latitude = lat;
      this.favAddPayload.longitude = lng;
      this.getPickupAddress(lat, lng, address.formatted_address);
    }
  }

  onKeyUp(value) {
    if (value === "") {
      this.favAddPayload.latitude = 0;
      this.favAddPayload.longitude = 0;
      this.favAddPayload.address = "";
    }
  }

  onAddChanged(value) {
    if (this.favAddPayload.latitude === 0 && this.favAddPayload.longitude === 0) {
      this.favAddPayload.address = "";
    } else if (this.favAddPayload.localAddress !== value) {
      this.favAddPayload.address = "";
      this.favAddPayload.localAddress = "";
    }
  }

  getPickupAddress(lat, lng, formatted_address) {
    this.geoCoder.geocode({ location: { lat: lat, lng: lng } }, (results, status) => {
      if (status === "OK") {
        if (results.length > 0) {
          console.log(results);
          var isAddSimilar = false;
          let length = results[0].address_components.length;
          this.favAddPayload.latitude = lat;
          this.favAddPayload.longitude = lng;
          this.favAddPayload.address = formatted_address;
          this.favAddPayload.localAddress = formatted_address;
          setTimeout(() => {
            if (!this.favAddPayload.zipCode) {
              if (results[0].address_components[length - 1].types.includes("postal_code")) {
                this.favAddPayload.zipCode = results[0].address_components[length - 1].long_name;
              }
            }
          }, 1000);
          results.forEach((addresses) => {
            if (addresses.formatted_address === formatted_address && !isAddSimilar) {
              isAddSimilar = true;
              console.log("exist");
              this.fetchAddress(addresses.address_components);
              return false;
            }
          });
          if (!isAddSimilar) {
            console.log("not-exist");
            this.fetchAddress(results[0].address_components);
          }
        } else {
          // window.alert('No results found');
        }
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  fetchAddress(addressArr) {
    var city = null;
    var state = null;
    var zipCode = null;
    var country = null;
    addressArr.forEach((element) => {
      if (
        element.types.includes("locality") ||
        element.types.includes("postal_town") ||
        element.types.includes("sublocality") ||
        element.types.includes("sublocality_level_1") ||
        element.types.includes("sublocality_level_2") ||
        element.types.includes("sublocality_level_3") ||
        element.types.includes("sublocality_level_4") ||
        element.types.includes("sublocality_level_5")
      ) {
        city = element.long_name;
      }
      if (
        element.types.includes("administrative_area_level_1") ||
        element.types.includes("administrative_area_level_2") ||
        element.types.includes("administrative_area_level_3") ||
        element.types.includes("administrative_area_level_4") ||
        element.types.includes("administrative_area_level_5")
      ) {
        state = element.long_name;
      }
      if (element.types.includes("postal_code")) {
        zipCode = element.long_name;
      }
      if (element.types.includes("country")) {
        country = element.long_name;
      }
    });
    this.favAddPayload.city = city;
    this.favAddPayload.province = state;
    this.favAddPayload.country = country;
    console.log(this.favAddPayload, "favAddPayload");
    // this.secondFormGroup.controls['zipCode'].patchValue(zipCode);
  }

  createFavAddForm() {
    this.favAddPayload = {
      streetAddress: "",
      city: "",
      province: "",
      zipCode: "",
      country: "",
      name: "",
      latitude: 0,
      longitude: 0,
      address: "",
      localAddress: "",
    };
  }

  submitFavAdd() {
    this.favAddressSubmitted = true;
    if (this.favAddPayload.name && this.favAddPayload.address) {
      if (this.favAddPayload.addressId) {
        this.http.httpPostWithHeaderUser("editAddress", this.favAddPayload).subscribe((res: any) => {
          if (res.statusCode === 200) {
            this.favAddModal = "none";
            this.favAddressSubmitted = false;
            this.getFavAddress();
            this.createFavAddForm();
          }
        });
      } else {
        this.http.httpPostWithHeaderUser("addAddress", this.favAddPayload).subscribe((res: any) => {
          if (res.statusCode === 200) {
            this.favAddModal = "none";
            this.favAddressSubmitted = false;
            this.getFavAddress();
            this.createFavAddForm();
          }
        });
      }
    }
  }

  getFavAddress() {
    var param = {
      pageNo: this.currentPageA,
    };
    this.http
      .httpPostWithHeaderUser("getAddress", param)
      .pipe(takeUntil(this.notifier))
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.favAddData = res.data.addressData;
          this.totalPagesA = res.data.totalPages;
          this.nextBtnEnabledA = this.currentPageA < this.totalPagesA;
          this.previousBtnEnabledA = this.currentPageA > 1;
        }
      });
  }

  onMapReady(event) {}

  editfavAdd(data) {
    this.favAddPayload = {
      streetAddress: data.streetAddress,
      city: data.city,
      province: data.province,
      zipCode: data.zipCode,
      country: data.country,
      name: data.name,
      latitude: data.latitude,
      longitude: data.longitude,
      address: data.address,
      localAddress: data.localAddress,
      addressId: data._id,
    };
    this.favAddModal = "block";
  }

  deleteFavAdd(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.http.httpPostWithHeaderUser("deleteAddress", { addressId: id }).subscribe((res) => {
          this.getFavAddress();
        });
        Swal.fire("Deleted!", "Your Favourite address has been deleted.", "success");
      }
    });
  }

  openAddFavModal() {
    this.createFavAddForm();
    this.favAddModal = "block";
    this.favAddPayload?.addressId ? delete this.favAddPayload?.addressId : "";
  }

  //pagination
  onNextPageN() {
    this.currentPageN++;
    this.getNotiFication(this.currentPageN);
  }

  onChnagePageN(page) {
    this.currentPageN = page;
    this.getNotiFication(page);
  }

  onPrevPageN() {
    this.currentPageN--;
    this.getNotiFication(this.currentPageN);
  }

  updateTableN(currentPage) {
    this.previousBtnEnabledN = currentPage > 1;
    this.nextBtnEnabledN = currentPage < this.totalPagesN;
    this.getNotiFication(currentPage);
  }

  onNextPageR() {
    this.currentPageR++;
    this.getNotiFication(this.currentPageR);
  }

  onChnagePageR(page) {
    this.currentPageR = page;
    this.getRatingAndReview();
  }

  onPrevPageR() {
    this.currentPageR--;
    this.getRatingAndReview();
  }

  updateTableR(currentPage) {
    this.previousBtnEnabledR = currentPage > 1;
    this.nextBtnEnabledR = currentPage < this.totalPagesR;
    this.getRatingAndReview();
  }

  onNextPageA() {
    this.currentPageA++;
    this.getFavAddress();
  }

  onChnagePageA(page) {
    this.currentPageA = page;
    this.getFavAddress();
  }

  onPrevPageA() {
    this.currentPageA--;
    this.getFavAddress();
  }

  updateTableA(currentPage) {
    this.previousBtnEnabledA = currentPage > 1;
    this.nextBtnEnabledA = currentPage < this.totalPagesA;
    this.getFavAddress();
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }
}

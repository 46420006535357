export class apiFList {
  sendOtp: string = "driver/sendOtp";
  verifyOtp: string = "driver/verifyOtp";
  uploadFile: string = "driver/uploadFile";
  register: string = "driver/register";
  login: string = "driver/login";
  updateProfile: string = "driver/updateProfile";
  getDefaultDriverDocument: string = "driver/getDefaultDriverDocument";
  getDriverProfile: string = "driver/getProfile";
  addDriverDocument: string = "driver/addDriverDocument";
  forgotPassword: string = "driver/forgotPassword";
  logout: string = "driver/logout";
  getVehicleCategory: string = "driver/getVehicleCategory";
  checkIfPhoneNumberExist: string = "driver/checkIfPhoneNumberExist";
  checkIfEmailExist: string = "driver/checkIfEmailExist";
  checkDriverExist: string = "driver/checkDriverExist";
  activateDriverAccount: string = "driver/activateDriverAccount";
  forgotPasswordByLicense: string = "driver/forgotPasswordByLicense";
  returnSubBookingStatus: string = "driver/returnSubBookingStatus";
  forgotChangePassword: string = "driver/forgotChangePassword";
  bankNames: string = "driver/bankNames";
}

export class apiFListUser {
  register: string = "user/register";
  sendOtp: string = "user/sendOtp";
  reSendOtp: string = "user/reSendOtp";
  verifyOtp: string = "user/verifyPhoneOtp";
  uploadFile: string = "user/uploadFile";
  uploadFileWithOutAuth: string = "user/uploadFileWithOutAuth";
  userProfile: string = "user/getProfile";
  updateUserProfile: string = "user/updateProfile";
  userLogin: string = "user/login";
  changePassword: string = "user/changePassword";
  forgotPassword: string = "user/forgotPassword";
  getVehicleCategoryInGeofence: string = "user/getVehicleCategoryInGeofence";
  createRideBooking: string = "user/createRideBooking";
  checkUserName: string = "user/checkUserName";
  logout: string = "user/logout";
  getPackageSize: string = "user/getPackageSize";
  getPackageType: string = "user/getPackageType";
  createDeliveryBooking: string = "user/createDeliveryBooking";
  getNearbyDriversWithGeoFenceId: string = "user/getNearbyDriversWithGeoFenceId";
  cancelBooking: string = "user/cancelBooking";
  getBookingHistory: string = "user/getBookingHistory";
  becomeBusinessPartner: string = "user/becomeBusinessPartner";
  becomeDeliveryPartner: string = "user/becomeDeliveryPartner";
  addFavouriteDriver: string = "user/addFavouriteDriver";
  deleteFavouriteDriver: string = "user/deleteFavouriteDriver";
  getAllFavouriteDriver: string = "user/getAllFavouriteDriver";
  getDriverRatingsReview: string = "user/getDriverRatingsReview";
  addRatings: string = "user/addRatings";
  shareYourRide: string = "user/shareYourRide";
  getShareYourRide: string = "user/getShareYourRide";
  deleteUser: string = "user/deleteUser";
  getRatingAndReview: string = "user/getRatingAndReview";
  getBookingDetails: string = "user/getBookingDetails";
  sendOtpWithAuth: string = "user/sendOtpWithAuth";
  socialLogin: string = "user/socialLogin";
  userQuery: string = "user/userQuery";
  checkIfPhoneNumberExist: string = "user/checkIfPhoneNumberExist";
  checkIfEmailExist: string = "user/checkIfEmailExist";
  verifyEmail: string = "user/verifyEmail";
  checkUserByProviderId: string = "user/checkUserByProviderId";
  checkPrelaunchLocation: string = "user/checkPrelaunchLocation";
  forgotChangePassword: string = "user/forgotChangePassword";
  getCms: string = "admin/getCms";
  userCard: string = "user/Card";
  defaultCard: string = "user/defaultCard";
  wallet: string = "user/wallet";
  getLoyalityDiscounts: string = "user/getLoyalityDiscounts";
  getAllLoyalityPoint: string = "user/getAllLoyalityPoint";
  redeemLoyalityPoint: string = "user/redeemLoyalityPoint";
  getAllNotification: string = "user/getAllNotification";
  allowNewBooking: string = "user/allowNewBooking";
  bankNames: string = "user/bankNames";
  getAddress: string = "user/getAddress";
  addAddress: string = "user/addAddress";
  editAddress: string = "user/editAddress";
  deleteAddress: string = "user/deleteAddress";
}

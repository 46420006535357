import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-requestaccept",
  templateUrl: "./requestaccept.component.html",
  styleUrls: ["./requestaccept.component.scss"],
})
export class RequestacceptComponent implements OnInit {
  selected = "label";
  lat: number = 51.678418;
  lng: number = 7.809007;
  public map: any = { lat: 51.678418, lng: 7.809007 };
  constructor(private router: Router) {}

  ngOnInit(): void {}
  goToprofilesetup() {
    this.router.navigate(["/profilesetup"]);
  }
}

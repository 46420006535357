<footer class="all_sec footer">
  <div class="conta_iner">
    <div class="footer_main">
      <div class="footer_links">
        <div class="footer_logo_bx">
          <div class="footer_logo">
            <figure>
              <img src="assets/uimages/logo.png" routerLink="/uhomepage" alt="" />
            </figure>
            <p>{{ "You can find us in Social Media too" | translate }}</p>
          </div>
          <ul class="social_icons">
            <li>
              <a (click)="goToFbPage()" style="cursor: pointer">
                <img src="assets/uimages/facebook.png" alt="" />
              </a>
            </li>
            <li>
              <a (click)="goToInstaPage()" style="cursor: pointer">
                <img src="assets/uimages/instagram.png" alt="" />
              </a>
            </li>
            <li>
              <a (click)="goToLinkedInPage()" style="cursor: pointer">
                <img src="assets/uimages/linkedin.png" alt="" />
              </a>
            </li>
            <li>
              <a (click)="goToTwitterPage()" style="cursor: pointer">
                <img src="assets/uimages/twitter.png" alt="" />
              </a>
            </li>
          </ul>
          <div class="accept">
            <h3>{{ "We Accept" | translate }}</h3>
            <img src="assets/uimages/accept.png" alt="" />
          </div>
        </div>
        <div class="footer_quicklinks_bx">
          <h3>{{ "Quick Links" | translate }}</h3>
          <div class="quick_links_list">
            <ul>
              <!-- <li><a routerLink="/">Home</a></li> -->
              <li *ngIf="router.url !== '/uhomepage'">
                <a id="whyId" (click)="scrollUp('why_id')" style="cursor: pointer">{{ "Why APPTUNIX™" | translate }} </a>
              </li>
              <li *ngIf="router.url === '/uhomepage'">
                <a id="whyId" href="#why_id" style="cursor: pointer">{{ "Why APPTUNIX™" | translate }} </a>
              </li>
              <li>
                <a routerLink="/udriver" style="cursor: pointer">{{ "Drivers" | translate }} </a>
              </li>
              <li>
                <a routerLink="/urider" style="cursor: pointer">{{ "Riders" | translate }}</a>
              </li>
              <li>
                <a routerLink="/udeliverypartners" style="cursor: pointer">{{ "Delivery Partners" | translate }}</a>
              </li>
              <li>
                <a (click)="goToCmsPages('/cms/FAQs')" style="cursor: pointer">{{ "FAQs" | translate }} </a>
              </li>
            </ul>
            <ul>
              <li>
                <a routerLink="/customer-services" style="cursor: pointer">{{ "Customer Service" | translate }} </a>
              </li>
              <li>
                <a (click)="goToTracking()" style="cursor: pointer">{{ "Track your ride or delivery" | translate }} </a>
              </li>
              <li>
                <a (click)="goToCmsPages('/cms/privacy-policy')" style="cursor: pointer">{{ "Privacy Policy" | translate }} </a>
              </li>
              <li>
                <a (click)="goToCmsPages('/cms/contact-support')" style="cursor: pointer">{{ "Contact Support" | translate }} </a>
              </li>
              <li>
                <a (click)="goToCmsPages('/cms/legal')" style="cursor: pointer">{{ "Legal" | translate }} </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-----footer bottom-->
    </div>
  </div>
  <div class="footer_bottom">
    <p>
      © {{ currentYear }} {{ "All Rights Reserved by APPTUNIX™" | translate }} <span>{{ "SSL Certification" | translate }}</span>
    </p>
  </div>
</footer>

<div class="login_wrap top">
    <app-f-unavbar id="navbar"></app-f-unavbar>
    <!-- <div class="back_home">
        <button class="driverap_btn" (click)="goTouhomepage()">Back To Home</button>
    </div> -->
    <!-- <div class="signupinner">
        <div class="conta_iner">
            <div class="flex">
                <div class="haeder_left">
                    <figure class="logo_cm" (click)="goTouhomepage()">
                        <img src="assets/uimages/logo.png" />
                    </figure>
                </div>
                <div class="back_home">
                    <button class="driverap_btn" (click)="goTouhomepage()">
                        Back To Home
                    </button>
                </div>
            </div>
        </div>
    </div> -->
    <div class="login">
        <div class="conta_iner">
            <div class="login_main">
                <div class="login_form center">
                    <div class="login_img_sec">
                        <figure class="login_img">
                            <img src="assets/uimages/login.svg" />
                        </figure>
                        <h4>{{'Welcome Back' | translate}}!</h4>
                        <p>{{'Lets make some money today' | translate}}!</p>
                    </div>

                    <form class="form_control_form login_form" [formGroup]="loginForm" (ngSubmit)="submitLogin()">
                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label>{{'Email address or Phone' | translate}}</label>
                                <input class="form-control" formControlName="phoneNo" matInput type="text"
                                    placeholder="Email address or Phone" />
                                <mat-error *ngIf="loginSubmitted && loginForm.controls['phoneNo'].hasError('required')">{{'Please enter your email or phone' | translate}}</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <mat-form-field [floatLabel]="'never'">
                                <label>{{'Password' | translate}}</label>
                                <input class="form-control" formControlName="password" type="password" matInput
                                    type="password" placeholder="Password" />
                                <mat-error *ngIf="loginSubmitted && loginForm.controls['password'].hasError('required')">{{'Password is required' | translate}}.</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="forget_text">
                            <a href="" data-toggle="modal" data-target="#forget_pass">{{'Forgot password?' | translate}}</a>
                        </div>

                        <div class="flex forget">
                            <div class="submit_btn">
                                <button type="submit" class="btn-primary btn">
                                    {{'Login' | translate}}
                                </button>
                            </div>
                        </div>

                        <p class="dont_account">
                            {{"Don't have an account?" | translate}} <a (click)="goTosignup()">{{'Sign up' | translate}}</a>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="login_images"></div> -->
</div>

<!-- <p data-toggle="modal" data-target="#app-download">download</p> -->

<!-- Modal -->
<div class="modal fade forget_password" id="forget_pass" role="dialog">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">
                    &times;
                </button>
            </div>
            <div class="modal-body">
                <h4>{{'Forgot password?' | translate}}</h4>
                <p>{{'Please Enter your Phone Number You Will recieve an OTP to verify' | translate}}.</p>
                <ngx-intl-tel-input [inputId]="'adsasd'" [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                    [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Canada" [maxLength]="15"
                    [tooltipField]="TooltipLabel.Name" [phoneValidation]="true" [separateDialCode]="separateDialCode"
                    name="phone" [formControl]="phoneNo">
                </ngx-intl-tel-input>
                <div class="commen_btn_outer">
                    <button class="btn btn-primary" (click)="forgotPassword()">{{'Submit' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <p (click)="goTorideremail()">click</p> -->
<!------download popup -->
<div class="modal fade app-download" id="app-download" role="dialog">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">
                    &times;
                </button>
            </div>
            <div class="modal-body">
                <div class="download-popuptitle">
                    <h4>{{'Download Your Driver Application' | translate}}</h4>
                    <p>lipsum as it is sometimes known, is dummy text used in laying out print</p>
                </div>
              <div class="driverapp-download-link">
                  <div class="driverapp-item">
                      <figure>
                          <img src="assets/uimages/google_store.png" alt="">
                      </figure>
                  </div>
                  <div class="driverapp-item">
                    <figure> <img src="assets/uimages/app_store.png" alt=""></figure>
                  </div>
              </div>

            </div>
        </div>
    </div>
</div>

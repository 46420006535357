import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpsService } from '../services/https/https.service';
import { Constant } from '../core/constant';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../core/must-match.validator';
import { ParentService } from 'src/app/services/parent/parent.service';
import { LocationStrategy } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'app-verifyemail',
  templateUrl: './verifyemail.component.html',
  styleUrls: ['./verifyemail.component.scss']
})
export class VerifyemailComponent implements OnInit {

  email: string = '';
  isThisForgot = false;
  isThisEmailPage: string = 'block';
  changePassForm: FormGroup;
  changePassSubmitted: boolean = false;
  otp: any;
  passwordResetToken: any;
  isThisForgotPassEmail: boolean = false;
  isThisPrelaunch: any = '';
  isPrelaunch: string;
  languageCode: string;
  showLang: boolean = false;
  baseUrl = environment.imageUrl;

  constructor(
    public commonService: CommonService,
    private router: Router,
    private http: HttpsService,
    private constant: Constant,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private parentService: ParentService,
    private location: LocationStrategy,
    public translate: TranslateService,
    public languageService: LanguageService
  ) {
    if (localStorage.email !== undefined) {
      this.commonService.emailForVerification = localStorage.getItem('email');
      this.email = localStorage.getItem('email');
    }
    if (localStorage.isThisUpdatedUser !== undefined) {
      this.commonService.isThisUpdatedUser = JSON.parse(localStorage.getItem('isThisUpdatedUser'));
    }
    if (localStorage.userType !== undefined) {
      this.commonService.isThisDeliveryPartner = localStorage.getItem('userType') === 'DELIVERY_PARTNER' ? true : false;
    }
    if (localStorage.languageCode !== undefined) {
      this.languageCode = localStorage.getItem('languageCode');
    }
    this.translate.use(this.languageCode);


  }

  preventBackButton() {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  ngOnInit(): void {
    this.changePassForm = this.fb.group({
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    }, {
      validator: MustMatch('newPassword', 'confirmPassword')
    });
    this.preventBackButton();
    this.isPrelaunch = this.route.snapshot.queryParamMap.get('isPrelaunch');
    if(this.isPrelaunch === 'true') {
      this.route.queryParams.subscribe((res: any) => {
        if (res.verificationToken !== undefined) {
          this.http.httpPostUser('verifyEmail', { resetToken: res.verificationToken }).subscribe((response: any) => {
            if (response.statusCode == 200) {
              this.parentService.isTokenUsed.next(true);
              // this.verifyEmail();
              let isThisPhoneUser = this.route.snapshot.queryParamMap.get('isPhone');
              if(isThisPhoneUser === 'true') {
                this.commonService.OpenNotificationModal('success', 'top-end','Your email has been verified successfully.');
                this.router.navigate(['uhomepage']);
              } else {
                this.goToNextPage();
              }
            }
          });
        } else if (res.forget === 'true') {
          this.isThisForgotPassEmail = true;
          // this.router.navigate(['/forgetpassword']);
        }
      });
    }
    // this.parentService.getPrelaunchStatus.subscribe((prelaunch: any) => {
    //   if (prelaunch) {
        
    //   }
    // });
  }

  selectLang(language) {
    this.languageService.selectedLang = language;
    this.languageService.translateLanguage(language.code);
    this.showLang = false;
  }

  verifyEmail() {
    if (localStorage.userUpdateForm !== undefined) {
      this.commonService.userUpdateForm = JSON.parse(localStorage.getItem('userUpdateForm'));
      console.log(this.commonService.userUpdateForm);
    }
    var formData = new FormData();
    formData.append('isEamilVerified', "true");
    formData.append('email', this.commonService.emailForVerification);
    this.http.httpPostWithFormDataHeader('updateUserProfile', formData).subscribe((res: any) => {
      if (res.statusCode == 200) {
        // this.commonService.getUser();
        this.goToNextPage();
      }
    });
  }

  goToNextPage() {
    var isThisForget = JSON.parse(this.route.snapshot.queryParamMap.get('forget'))
    if (isThisForget) {
      this.commonService.OpenNotificationModal('error', 'top-end', 'Please check your email first.');
      return false;
    }
    var verificationToken = this.route.snapshot.queryParamMap.get('verificationToken');
    if (verificationToken !== null) {
      var formData = new FormData();
      formData.append('isEamilVerified', 'true');
      this.commonService.getUser().then((value: any) => {
        if (value) {
          // if (this.commonService.loggedInUser.isEamilVerified === true && this.commonService.emailForVerification === this.commonService.loggedInUser.email) {
          if (this.commonService.isThisDeliveryPartner && !this.commonService.isThisUpdatedUser && this.commonService.loggedInUser.isUserDeleted) {
            this.commonService.accRecover();
            // this.commonService.OpenNotificationModal('success', 'top-end', 'Your account has been created successfully.');
            // this.router.navigate(["/riderequest"]);
            // this.commonService.localStorageCLear();
          } else if (this.commonService.isThisDeliveryPartner && this.commonService.isThisUpdatedUser) {
            this.verifyUpdate();
          } else if (this.commonService.isThisPreUser) {
            this.commonService.OpenNotificationModal('success', 'top-end', 'Your account has been created successfully.');
            this.commonService.preLaunchAlert(this.constant.MSGS.PRELAUNCH_DELIVERY);
            this.commonService.localStorageCLear();
          } else if (this.commonService.isThisUpdatedUser) {
            this.verifyUpdate();
          } else if (this.commonService.loggedInUser.isAccountCompleted && !this.commonService.isThisUpdatedUser && this.commonService.loggedInUser.isUserDeleted) {
                this.commonService.accRecover();
          } else if (this.commonService.loggedInUser.isAccountCompleted && !this.commonService.loggedInUser.isDeleted && !this.commonService.loggedInUser.isUserDeleted) {
            this.router.navigate(["/riderequest"]);
          } else {
            this.router.navigate(["/riderdetail"]);
          }
        }
      })
    } else {
      this.commonService.OpenNotificationModal('error', 'top-end', 'Please verify your email first.');
    }


  }

  verifyUpdate() {
    var formData = new FormData();
    formData.append('isEamilVerified', "true");
    if (localStorage.userUpdateForm !== undefined) {
      this.commonService.userUpdateForm = JSON.parse(localStorage.getItem('userUpdateForm'));
    }
    // formData.append('email', this.commonService.userUpdateForm.email);
    // this.http.httpPostWithFormDataHeader('updateUserProfile', formData).subscribe((res: any) => {
    //   if (res.statusCode === 200) {
    //     this.commonService.getUser().then(response => {
    //       if (response) {
    //         this.commonService.isEamilVerified = true;
    //         this.router.navigate(["/profilesetup"]);
    //       }
    //     })
    //   }
    // });
    this.commonService.updateUser().then((respond: boolean) => {
      if(respond) {
        this.commonService.isEamilVerified = true;
        this.router.navigate(["/profilesetup"]);
        localStorage.removeItem('isThisUpdatedUser');
      }
    })
  }

  changePassword() {
    this.changePassSubmitted = true;
    if (this.changePassForm.valid) {
      var param = {
        // "passwordResetToken":this.passwordResetToken,
        "resetToken": this.passwordResetToken,
        "password": this.changePassForm.value.confirmPassword
      }
      this.http.httpPostUser('forgotChangePassword', param).subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.commonService.OpenNotificationModal('success', 'top-end', res.message);
          this.router.navigate(['/riderlogin']);
        }
      })
    }
  }

  sendOTP() {
    if (localStorage.email !== undefined) {
      this.commonService.emailForVerification = localStorage.getItem('email');
    }
    this.route.queryParams.subscribe((param: any) => {
      if (param.forget === 'true') {
        this.isThisForgotPassEmail = true;
      }
    });
    if (this.isThisForgotPassEmail) {
      this.sendForgotLink(this.commonService.emailForVerification)
    } else {
      this.sendVerificatinLink(this.commonService.emailForVerification);
    }
  }

  sendVerificatinLink(email) {
    this.http.httpPostWithHeaderUser('sendOtpWithAuth', { email: email,isPrelaunch: true }).subscribe((res: any) => {
      this.otp = res.data.otpCode;
      // this.commonService.OpenNotificationModal('success', 'top-end', res.message);
      this.commonService.OpenNotificationModal('success', 'top-end', 'Verification link has been sent to your registered email address.');
      // this.ngOtpInput.setValue("");
    });
  }

  sendForgotLink(email) {
    this.http.httpPostUser('forgotPassword', { email: email }).subscribe((res: any) => {
      if (res.statusCode === 200) {
        this.commonService.OpenNotificationModal('success', 'top-end', res.message);
      }
    });
  }

  goTouhomepage() {
    this.router.navigate(["/goTouhomepage"]);
  }

}

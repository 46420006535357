<div class="login_wrap top">
    <div class="back_home">
        <button class="driverap_btn">Back To Home</button>
    </div>
    <div class="login_sec">
        <div class="conta_iner">
            <div class="login_outer_cls">
                <div class="otp_img">
                    <figure>
                        <img src="assets/uimages/email-icon.svg" alt="" />
                    </figure>
                </div>
                <!-----end otp img class-->
                <!----start text -->
                <div class="login_content_sec">
                    <h3 class="verify_number">{{'Verifying your Email' | translate}}!</h3>
                    <p class="user_num">
                        Please check your email account for
                        <span>Account@gmail.com</span>
                        and click the “verify link inside”
                    </p>
                     
                 
                    <!-- <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{ length: 5 }"></ng-otp-input> -->
                    <div class="commen_btn_outer">
                        <button class="btn btn-primary">
              Verify
            </button>
                    </div>
                    <p class="resend_opt">
                        <a class="lnkk">{{'Resend' | translate}}</a>
                    </p>
                </div>      
            </div>
        </div>
    </div>
</div>
import { Component, OnInit, ViewChild, ElementRef, NgZone, AfterViewInit, ChangeDetectorRef, AfterViewChecked } from "@angular/core";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CommonService } from "../services/common/common.service";
import { HttpsService } from "../services/https/https.service";
import { MatStepper } from "@angular/material/stepper";
import { MapsAPILoader } from "@agm/core";
import { MustMatch } from "../core/must-match.validator";
import { BehaviorSubject, Subject } from "rxjs";
import { HttpParams } from "@angular/common/http";
import Swal from "sweetalert2";
import { count } from "console";
import { environment } from "src/environments/environment";
import * as vehicleMakeModels from "../core/cars.json";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "src/app/services/language/language.service";
declare var $: any;
declare var google: any;
interface Ready {
  value: string;
  viewValue: string;
}
interface driver {
  value: string;
  viewValue: string;
}
@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileFComponent implements OnInit, AfterViewInit, AfterViewChecked {
  isLinear: boolean = false;
  personalFormGroup: FormGroup;
  addressFormGroup: FormGroup;
  bankFormGroup: FormGroup;
  vehicleFormGroup: FormGroup;
  drivingFormGroup: FormGroup;
  regulatoryFormGroup: FormGroup;
  documentFormGroup: FormGroup;

  status: boolean = false;

  pick: Ready[] = [
    { value: "5 Days", viewValue: "lorem" },
    { value: "1 Days", viewValue: "lorem" },
  ];
  pick2: driver[] = [
    { value: "DELIVERY", viewValue: "Delivery Driver " },
    { value: "RIDE", viewValue: "Ride Driver" },
    { value: "BOTH", viewValue: "Both " },
  ];
  pick4: driver[] = [
    { value: "male", viewValue: "Male" },
    { value: "female", viewValue: "Female" },
    { value: "others", viewValue: "Others" },
  ];

  modalYear = [];
  addDriverForm: FormGroup;
  fileDataUrl: any;
  allFileUploadUrl = {
    proImage: "",
    voidCheque: "",
    dlImage: "",
    dlImageFront: "",
    dlImageBack: "",
    criminalBg: "",
    drivingVoidCheque: "",
    insuranceImage: "",
    registrationImage: "",
    inspectionImage: "",
    abuseRegisterCheck: "",
    licensePlateImage: "",
    vehicleImage: "",
  };
  @ViewChild("stepper", { static: false }) public stepper: MatStepper;
  imageSrc: string;
  public searchControl: FormControl;
  @ViewChild("search") public searchElementRef: ElementRef;
  @ViewChild("stateSearch") public stateElementRef: ElementRef;
  submitted: boolean = false;
  phoneNo: any;
  countryCode: any;
  location: any;
  public onStepsChange: Subject<boolean> = new Subject();
  public onStepBack: Subject<boolean> = new Subject();
  getOnStepBack = this.onStepBack.asObservable();
  stateLocation: string;
  vehicleYears: any[];
  requiredDocumentsData: any = [];
  documentsImages: any = {};
  pickup = {
    lat: 0,
    lng: 0,
  };
  drop = {
    lat: 0,
    lng: 0,
  };
  pickupAddress: any;
  geoCoder: any;
  zoom: number;
  dropAddress: any;
  origin: { lat: number; lng: number };
  destination: { lat: number; lng: number };
  distance: number;
  minDate;
  isVehicleSubmit: boolean = false;
  getVehicleCategoryData: any = [];
  editable: boolean = true;
  inValidEmail: boolean = false;
  regularityFormSubmitted: boolean = false;
  fullAddress = { address: "", lat: 0, lng: 0 };
  countryFullAddress = { address: "", lat: 0, lng: 0 };
  stateFullAddress = { address: "", lat: 0, lng: 0 };
  servingAddress = { address: "", lat: 0, lng: 0 };
  dlNumberExist: boolean = false;
  drivingInfoSubmitted: boolean = false;
  driverProfileData: any;
  vehicleMakeData = vehicleMakeModels.make;
  vehicleModelData: any = [];
  today = new Date();
  accRecoverPass: FormControl = new FormControl("", Validators.required);
  accRecoverPassSubmitted: boolean = false;
  recoverModal: string = "none";
  validateDocument: Subject<boolean> = new Subject();
  getValidateDocument = this.validateDocument.asObservable();
  profileFormSubmitted: boolean = false;
  documentSubmitted: boolean = false;
  showLang: boolean = false;
  baseUrl = environment.imageUrl;
  bankNameArr: any = [];
  profileCompletedMsg = "Profile completed successfully. Please wait for admin to approve your account.";

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private commonService: CommonService,
    private httpService: HttpsService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    public translate: TranslateService,
    public languageService: LanguageService
  ) {
    this.getDriverDetails();
    const tomorrow = new Date(this.today);
    tomorrow.setDate(tomorrow.getDate() + 31);
    this.minDate = tomorrow;
    console.log(this.minDate, "minDate");
  }

  ngOnInit(): void {
    this.getBankName();
    this.createForm();
    this.languageService.isLangChange.subscribe((isChange: boolean) => {
      if (isChange) {
        this.getChange();
      }
    });
    this.commonService.getVerificationData().subscribe((res: any) => {
      if (res) {
        this.countryCode = res.countryCode;
        this.phoneNo = res.phoneNo;
        this.personalFormGroup.get("phoneNumber").patchValue(res.countryCode + " " + res.phoneNo);
      }
    });
    this.onStepsChange.subscribe((res: boolean) => {
      if (res) {
        this.cdr.detectChanges();
        this.stepper.selectedIndex++;
        this.isLinear = true;
      }
    });
    this.getOnStepBack.subscribe((res: boolean) => {
      if (res) {
        this.cdr.detectChanges();
        this.stepper.selectedIndex--;
        this.isLinear = true;
        this.getDriverData();
      }
    });
    this.getVehicleYear();
    // this.getCurrentLocation();
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
    });
    if (localStorage.accessToken != null || localStorage.accessToken != undefined) {
      if (localStorage.userType === "driver") {
        this.getDriverDetails();
        this.httpService.httpGetWithHeader("getDriverProfile", "").subscribe((res: any) => {
          if (res.statusCode === 200) {
            this.driverProfileData = res.data.driverData;
            this.driverProfileData.dlNumber && this.drivingFormGroup.get("dlNumber").patchValue(this.driverProfileData.dlNumber);
            if (res.data.driverData.zoneId && res.data.driverData.geofenceId) {
              this.getVehicleCatData(res.data.driverData.zoneId, res.data.driverData.geofenceId);
            }
            //  else {
            //   this.commonService.driverAlert();
            // }
          }
        });
      }
    }
  }

  getChange() {
    this.translate.get(["Profile completed successfully. Please wait for admin to approve your account."]).subscribe((translations) => {
      this.profileCompletedMsg = translations["Profile completed successfully. Please wait for admin to approve your account."];
    });
  }

  selectLang(language) {
    this.languageService.selectedLang = language;
    this.languageService.translateLanguage(language.code);
    this.showLang = false;
  }

  // handleLocationChnage(address) {
  //   if (address.formatted_address) {
  //     console.log(address.formatted_address)
  //     this.addressFormGroup.get('searchControl').patchValue(address.formatted_address);
  //     var lat = (address.geometry.location.lat())
  //     var lng = (address.geometry.location.lng())
  //     this.driverLatLng.lat = lat;
  //     this.driverLatLng.lng = lng;
  //     this.getAddress(lat, lng);
  //   }
  // }

  handleStateChnage(address) {
    if (address.formatted_address) {
      let lat = address.geometry.location.lat();
      let lng = address.geometry.location.lng();
      this.stateFullAddress.lat = lat;
      this.stateFullAddress.lng = lng;
      this.getStateCountry(lat, lng);
    }
  }
  onStateKeyUp(value) {
    if (value === "") {
      this.stateFullAddress.lat = 0;
      this.stateFullAddress.lng = 0;
      this.drivingFormGroup.get("dlState").patchValue(null);
    }
  }

  onStateAddChanged(value) {
    if (this.stateFullAddress.lat === 0 && this.stateFullAddress.lng === 0) {
      this.drivingFormGroup.get("dlState").patchValue(null);
      // this.submitted = true;
    } else if (this.stateFullAddress.address !== value) {
      this.drivingFormGroup.get("dlState").patchValue(null);
      // this.submitted = true;
    }
  }

  handleCountryChnage(address, key, addObj) {
    if (address.formatted_address) {
      let lat = address.geometry.location.lat();
      let lng = address.geometry.location.lng();
      this[addObj].lat = lat;
      this[addObj].lng = lng;
      key === "servingAddress" && (this[addObj].address = address.formatted_address);
      key === "servingAddress" && this.drivingFormGroup.get("servingAddress").patchValue(address.formatted_address);
      address.address_components.forEach((element) => {
        if (element.types.includes("country")) {
          key === "dlCountry" && (this[addObj].address = element.long_name);
          key === "dlCountry" && this.drivingFormGroup.get("dlCountry").patchValue(element.long_name);
        }
      });
      // this.getCountry(lat, lng);
    }
  }

  onCountryKeyUp(value, key, addObj) {
    if (value === "") {
      this[addObj].lat = 0;
      this[addObj].lng = 0;
      this.drivingFormGroup.get(key).patchValue(null);
    }
  }

  onCountryAddChanged(value, key, addObj) {
    if (this[addObj].lat === 0 && this[addObj].lng === 0) {
      this.drivingFormGroup.get(key).patchValue(null);
      // this.submitted = true;
    } else if (this[addObj].address !== value) {
      this.drivingFormGroup.get(key).patchValue(null);
      // this.submitted = true;
    }
  }

  getStateCountry(lat, lng) {
    this.geoCoder.geocode({ location: { lat: lat, lng: lng } }, (results, status) => {
      if (status === "OK") {
        console.log(results);
        if (results[0]) {
          this.pickupAddress = results[0].address_components;
          results[0].address_components.forEach((element) => {
            if (
              element.types.includes("administrative_area_level_1") ||
              element.types.includes("administrative_area_level_2") ||
              element.types.includes("administrative_area_level_3") ||
              element.types.includes("administrative_area_level_4") ||
              element.types.includes("administrative_area_level_5")
            ) {
              this.stateFullAddress.address = element.long_name;
              this.drivingFormGroup.get("dlState").patchValue(element.long_name);
            }
            if (element.types.includes("country")) {
              this.countryFullAddress.address = element.long_name;
              this.drivingFormGroup.get("dlCountry").patchValue(element.long_name);
            }
          });
          // this.addressFormGroup.get('zipCode').patchValue(geocode);
        } else {
          window.alert("No results found");
        }
      } else {
        window.alert("Geocoder failed due to: " + status);
      }
    });
    // this.checkstatusMap();
  }

  // getAddress(lat, lng) {
  //   this.geoCoder.geocode({ 'location': { lat: lat, lng: lng } }, (results, status) => {
  //     if (status === 'OK') {
  //       if (results[0]) {
  //         this.pickupAddress = results[0].formatted_address;
  //         var geocode = results[0].address_components[results[0].address_components.length - 1].long_name;
  //         this.addressFormGroup.get('zipCode').patchValue(geocode);
  //       } else {
  //         window.alert('No results found');
  //       }
  //     } else {
  //       window.alert('Geocoder failed due to: ' + status);
  //     }

  //   });
  //   // this.checkstatusMap();
  // }

  getVehicleYear() {
    let date = new Date().getFullYear();
    let edate = date - 31;
    this.vehicleYears = [];
    for (let i = edate; i <= date; i++) {
      this.vehicleYears.push(i);
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    if (localStorage.accessToken != null || localStorage.accessToken != undefined) {
      if (localStorage.userType === "driver") {
        this.httpService.httpGetWithHeader("getDriverProfile", "").subscribe((res: any) => {
          if (res.statusCode === 200) {
            this.driverProfileData = res.data.driverData;
            this.driverProfileData.dlNumber && this.drivingFormGroup.get("dlNumber").patchValue(this.driverProfileData.dlNumber);
            this.editable = true;
            this.isLinear = false;
            this.stepper.selectedIndex = 4;
            if (!res.data.driverData.firstName && !res.data.driverData.lastName) {
              this.stepper.selectedIndex = 0;
              this.isLinear = true;
              this.editable = false;
            } else if (!res.data.driverData.isFilledBankDetail) {
              this.stepper.selectedIndex = 2;
              this.isLinear = true;
              this.editable = false;
            } else if (!res.data.driverData.isDriverInfo) {
              this.stepper.selectedIndex = 3;
              this.isLinear = true;
              this.editable = false;
            } else if (!res.data.driverData.isVehicleInfo && !res.data.driverData.isVehicleAbout && res.data.driverData.zoneId) {
              this.stepper.selectedIndex = 4;
              this.isLinear = true;
              this.editable = false;
            } else if (!res.data.driverData.zoneId) {
              this.commonService.driverAlert();
              return;
            } else if (!res.data.driverData.isRegularCompliance) {
              this.stepper.selectedIndex = 5;
              this.isLinear = true;
              this.editable = false;
            } else if (res.data.driverData.isNewUser) {
              this.stepper.selectedIndex = 0;
              this.isLinear = true;
              this.editable = false;
            } else if (!res.data.driverData.isUploadDocument || !res.data.driverData.isAccountCompleted) {
              this.getValidateDocument.subscribe((isRequired: boolean) => {
                if (!isRequired) {
                  this.commonService.localStorageCLear();
                  this.commonService.goToDownloadSection.next(true);
                  this.router.navigate(["udriver"]);
                } else {
                  this.stepper.selectedIndex = 6;
                  this.isLinear = true;
                  this.editable = false;
                }
              });
              this.stepper.selectedIndex = 6;
              this.isLinear = true;
              this.editable = false;
            }
          }
        });
      }
    } else {
      this.editable = false;
      this.isLinear = true;
      if (localStorage.getItem("verifiedPhone") != undefined) {
        var verifiedPhoneDetail = JSON.parse(localStorage.getItem("verifiedPhone"));
        this.commonService.driverType = verifiedPhoneDetail.driverType;
        this.phoneNo = verifiedPhoneDetail.phoneNo;
        this.countryCode = verifiedPhoneDetail.countryCode;
        this.personalFormGroup.get("phoneNumber").patchValue(verifiedPhoneDetail.countryCode + " " + verifiedPhoneDetail.phoneNo);
      } else {
        this.router.navigate(["/signup"]);
      }
    }
    // this.commonService.getUserProfileAccessDetail().subscribe((res: any) => {
    //   if (res) {
    //     this.isLinear = false;
    //     if (res.isNewUser) {
    //       this.stepper.selectedIndex = 0;
    //       this.isLinear = true
    //       return;
    //     } else if (!res.isFilledBankDetail) {
    //       this.stepper.selectedIndex = 2;
    //       this.isLinear = true
    //     } else if (!res.isDriverInfo) {
    //       this.stepper.selectedIndex = 3;
    //       this.isLinear = true
    //     } else if (!res.isVehicleInfo) {
    //       this.stepper.selectedIndex = 4;
    //       this.isLinear = true
    //     } else if (!res.isRegularCompliance) {
    //       this.stepper.selectedIndex = 5;
    //       this.isLinear = true
    //     } else if (res.isNewUser) {
    //       this.stepper.selectedIndex = 0;
    //       this.isLinear = true
    //     } else if(!res.isUploadDocument) {
    //       this.stepper.selectedIndex = 6;
    //       this.isLinear = true
    //     }
    //   }
    // });
  }

  goToPersonalTab() {
    this.cdr.detectChanges();
    this.stepper.selectedIndex--;
    this.isLinear = true;
  }

  goBackToHome(url) {
    if (localStorage.accessBy !== undefined && localStorage.accessBy === "register") {
      this.commonService.localStorageCLear();
    }
    this.router.navigate([url]);
  }

  showBackMessage(url) {
    Swal.fire({
      title: "",
      text: "Your account has not be finished and you need to complete it before being accepted on APPTUNIX. Next time when you open the website/app, instead of click on the Sign-up option, click on the Login option using the current mobile number and password created, to continue your driver Sign-up process.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.value) {
        this.goBackToHome(url);
      }
    });
  }

  goToBack() {
    this.onStepBack.next(true);
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  createForm() {
    this.personalFormGroup = this._formBuilder.group({
      firstName: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]),
      lastName: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]),
      email: new FormControl(null, [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]),
      phoneNumber: new FormControl(null, Validators.required),
      dlNumber: new FormControl(null, Validators.required),
    });
    this.bankFormGroup = this._formBuilder.group({
      accNumber: new FormControl(null, [Validators.required, Validators.minLength(4)]),
      transit: new FormControl(null, Validators.required),
      bankName: new FormControl(null, Validators.required),
      branchAdd: new FormControl(null, Validators.required),
      // 'voidCheque': new FormControl(null),
      institutionNumber: new FormControl(null, Validators.required),
    });
    this.vehicleFormGroup = this._formBuilder.group({
      vehicleType: new FormControl(null, Validators.required),
      vehicleMake: new FormControl(null, Validators.required),
      vehicleModel: new FormControl(null, Validators.required),
      vehicleYear: new FormControl(null, Validators.required),
      insuranceExpiry: new FormControl(null, Validators.required),
      ownershipExpiry: new FormControl(null, Validators.required),
      // 'insuranceImage': new FormControl(null),
      // 'registrationImage': new FormControl(null),
      // 'inspectionImage': new FormControl(null),
      inspectionExpiry: new FormControl(null, Validators.required),
      licensePlateNo: new FormControl(null, Validators.required),
      // 'licensePlateImage': new FormControl(null),
      policyNumber: new FormControl(null, Validators.required),
      insuranceName: new FormControl(null, Validators.required),
      driveCoverage: new FormControl(null, Validators.required),
      isAcWorking: new FormControl(null, Validators.required),
      isHeatingWorking: new FormControl(null, Validators.required),
      isAnyDents: new FormControl(null, Validators.required),
      isInteriorClean: new FormControl(null, Validators.required),
      // 'vehicleImage': new FormControl(null),
    });

    this.drivingFormGroup = this._formBuilder.group({
      dlNumber: new FormControl(null, Validators.required),
      dlExpiry: new FormControl(null, Validators.required),
      dlState: new FormControl(null, Validators.required),
      dlCountry: new FormControl(null, Validators.required),
      servingAddress: new FormControl(null, Validators.required),
      servingLatitude: new FormControl(null, Validators.required),
      servingLongitude: new FormControl(null, Validators.required),
      // 'dlImage': new FormControl(null),
    });
    this.regulatoryFormGroup = this._formBuilder.group({
      // 'criminalBg': new FormControl(null),
      criminalBgExpiry: new FormControl(null, Validators.required),
      // 'TandC': new FormControl(null, Validators.requiredTrue),
      childAbuseExpiry: new FormControl(null, Validators.required),
      // 'abuseRegisterCheck': new FormControl(null),
    });
    this.addressFormGroup = this._formBuilder.group(
      {
        street: ["", Validators.required],
        location: ["", Validators.required],
        zipCode: ["", Validators.required],
        city: ["", Validators.required],
        state: ["", Validators.required],
        country: ["", Validators.required],
        localAddress: [""],
        password: new FormControl(null, [Validators.required, Validators.minLength(6)]),
        confirmPass: new FormControl(null, [Validators.required]),
        TandC: new FormControl(null, Validators.requiredTrue),
      },
      {
        validator: MustMatch("password", "confirmPass"),
      }
    );
    this.documentFormGroup = this._formBuilder.group({});
    this.personalFormGroup.get("dlNumber").valueChanges.subscribe((value: any) => {
      if (value) {
        this.dlNumberExist = false;
      }
    });
    this.addressFormGroup.get("password").valueChanges.subscribe((value: any) => {
      if (value) {
        this.addressFormGroup.get("password").patchValue(value.trim());
      }
    });
  }

  getDriverData() {
    let param = new HttpParams().set("userId", localStorage.userId);
    this.httpService.httpGetWithHeader("getDriverProfile", param).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.driverProfileData = res.data.driverData;
        this.driverProfileData.dlNumber && this.drivingFormGroup.get("dlNumber").patchValue(this.driverProfileData.dlNumber);
        if (res.data.driverData.isFilledBankDetail) {
          this.allFileUploadUrl.voidCheque = res.data.driverData.checkImage ? res.data.driverData.checkImage : "";
          this.bankFormGroup.patchValue({
            accNumber: res.data.driverData.accountNumber,
            transit: res.data.driverData.transit,
            bankName: res.data.driverData.bankName,
            branchAdd: res.data.driverData.bankAddress,
            institutionNumber: res.data.driverData.institutionNumber,
            // 'voidCheque': res.data.driverData.checkImage ? res.data.driverData.checkImage : '',
          });
        }
        if (res.data.driverData.isDriverInfo) {
          this.allFileUploadUrl.dlImage = res.data.driverData.dlImageFront ? res.data.driverData.dlImageFront : "";
          this.drivingFormGroup.get("dlExpiry").clearValidators();
          this.drivingFormGroup.get("dlExpiry").updateValueAndValidity();
          this.drivingFormGroup.patchValue({
            dlNumber: res.data.driverData.dlNumber,
            dlExpiry: res.data.driverData.dlExpiry,
            dlState: res.data.driverData.dlCountry,
            dlCountry: res.data.driverData.dlState,
            // 'dlImage': res.data.driverData.dlImageFront ? res.data.driverData.dlImageFront : '',
          });
        }
        if (res.data.driverData.isVehicleInfo) {
          this.allFileUploadUrl.insuranceImage = res.data.vehicle.insuranceImage ? res.data.vehicle.insuranceImage : "";
          this.allFileUploadUrl.registrationImage = res.data.vehicle.registrationCopyImage ? res.data.vehicle.registrationCopyImage : "";
          this.allFileUploadUrl.inspectionImage = res.data.vehicle.vehicleInspectionImage ? res.data.vehicle.vehicleInspectionImage : "";
          this.allFileUploadUrl.licensePlateImage = res.data.vehicle.licensePlate ? res.data.vehicle.licensePlate : "";
          this.allFileUploadUrl.vehicleImage = res.data.vehicle.vehicleImage ? res.data.vehicle.vehicleImage : "";
          this.vehicleFormGroup.get("insuranceExpiry").clearValidators();
          this.vehicleFormGroup.get("insuranceExpiry").updateValueAndValidity();
          this.vehicleFormGroup.get("inspectionExpiry").clearValidators();
          this.vehicleFormGroup.get("inspectionExpiry").updateValueAndValidity();
          vehicleMakeModels.models.forEach((element: any) => {
            if (element.make === res.data.vehicle.vehicleMake) {
              this.vehicleModelData = element.models;
              this.vehicleFormGroup.get("vehicleModel").patchValue(res.data.vehicle.vehicleModel);
            }
          });
          this.vehicleFormGroup.patchValue({
            vehicleMake: res.data.vehicle.vehicleMake,
            // 'vehicleModel': res.data.vehicle.vehicleModel,
            vehicleType: res.data.vehicle.vehicleCategoryId,
            vehicleYear: res.data.vehicle.vehicleYear ? parseInt(res.data.vehicle.vehicleYear) : "",
            insuranceExpiry: res.data.vehicle.insuranceExpiryDate,
            // 'insuranceImage': res.data.driverData.insuranceImage ? res.data.driverData.insuranceImage : '',
            // 'registrationImage': res.data.driverData.registrationCopyImage ? res.data.driverData.registrationCopyImage : '',
            // 'inspectionImage': res.data.vehicleInspectionImage ? res.data.vehicleInspectionImage : '',
            inspectionExpiry: res.data.vehicle.inspectioneExpiryDate,
            licensePlateNo: res.data.vehicle.licensePlateNumber,
            // 'licensePlateImage': res.data.driverData.licensePlate ? res.data.driverData.licensePlate : '',
            policyNumber: res.data.vehicle.insurancePolicyNumber,
            insuranceName: res.data.vehicle.insuranceName,
            driveCoverage: res.data.vehicle.shareDriverCoverage,
            isAcWorking: res.data.vehicle.isAcWorking,
            isHeatingWorking: res.data.vehicle.isheatingWorking,
            isAnyDents: res.data.vehicle.isDentRust,
            isInteriorClean: res.data.vehicle.isCleaning,
            // 'vehicleImage': res.data.vehicleImage ? res.data.vehicleImage : '',
            ownershipExpiry: res.data.driverData.registrationDate,
          });
        }
        if (res.data.driverData.isRegularCompliance) {
          this.allFileUploadUrl.criminalBg = res.data.vehicle.yourBackgroundImage ? res.data.vehicle.yourBackgroundImage : "";
          this.allFileUploadUrl.abuseRegisterCheck = res.data.vehicle.childAbuseCertificateImage ? res.data.vehicle.childAbuseCertificateImage : "";
          this.regulatoryFormGroup.get("criminalBgExpiry").clearValidators();
          this.regulatoryFormGroup.get("criminalBgExpiry").updateValueAndValidity();
          this.regulatoryFormGroup.get("childAbuseExpiry").clearValidators();
          this.regulatoryFormGroup.get("childAbuseExpiry").updateValueAndValidity();
          this.regulatoryFormGroup.patchValue({
            // 'criminalBg': res.data.driverData.yourBackgroundImage,
            criminalBgExpiry: res.data.vehicle.yourBackgroundCertificateExpiryDate,
            // 'TandC': res.data.driverData.isTermAndCondition,
            childAbuseExpiry: res.data.vehicle.childAbuseCertificateExpiryDate,
            // 'abuseRegisterCheck': res.data.driverData.childAbuseCertificateImage ? res.data.driverData.childAbuseCertificateImage : '',
          });
        }
      }
    });
  }

  onProfileUpload(fileInput: any, formField) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const fileData = <File>fileInput.target.files[0];
      const fileDataName = fileData.name;
      this.allFileUploadUrl[formField] = fileData;
      var reader = new FileReader();
      reader.readAsDataURL(fileInput.target.files[0]);
      reader.onload = (event) => {
        this.imageSrc = reader.result as string;
      };
    }
  }

  onFileChange(fileInput: any, formField) {
    const fileData = <File>fileInput.target.files[0];
    this.httpService.fileUpload(fileData).subscribe((res) => {
      this.fileDataUrl = JSON.parse(JSON.stringify(res)).data.orignal;
      this.allFileUploadUrl[formField] = this.fileDataUrl;
    });
  }

  goToStepTwo() {
    this.profileFormSubmitted = true;
    if (this.personalFormGroup.valid && this.imageSrc) {
      this.httpService.httpPost("checkIfEmailExist", { email: this.personalFormGroup.get("email").value }).subscribe((res: any) => {
        if (res.statusCode == 200) {
          if (res.data.isValid) {
            this.inValidEmail = false;
            this.checkDriverExist().then((driver: any) => {
              this.dlNumberExist = driver.isExist;
              if (!driver.isExist && !driver.isDeleted) {
                this.stepper.selectedIndex++;
              } else if (driver.isExist && driver.isDeleted) {
                Swal.fire({
                  title: "Do you want to restore your previous account?",
                  // text: "You won't be able to revert this!",
                  icon: "info",
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Restore",
                  cancelButtonText: "New",
                  allowOutsideClick: false,
                  showCancelButton: true,
                }).then((result) => {
                  if (result.value) {
                    this.recoverModals("block");
                  }
                });
              } else if (driver.isExist && !driver.isDeleted) {
                this.commonService.OpenNotificationModal(
                  "error",
                  "top-end",
                  "Your driver license number has been already used in our system. You can contact our support team for further information."
                );
              }
            });
          } else {
            this.inValidEmail = true;
          }
        }
      });
    }
  }

  goToAccRecover() {
    this.accRecoverPassSubmitted = true;
    if (this.accRecoverPass.valid) {
      var param = {
        dlNumber: this.personalFormGroup.value.dlNumber.trim(),
        password: this.accRecoverPass.value.trim(),
      };
      this.httpService.httpPost("activateDriverAccount", param).subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.commonService.OpenNotificationModal("success", "top-end", res.message);
          this.recoverModals("none");
        }
      });
    }
  }

  recoverModals(style) {
    this.recoverModal = style;
  }

  forgotPassword() {
    this.httpService.httpPost("forgotPasswordByLicense", { dlNumber: this.personalFormGroup.value.dlNumber.trim() }).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.commonService.OpenNotificationModal("success", "top-end", res.message);
        this.recoverModals("none");
      }
    });
  }

  handleLocationChnage(address) {
    if (address.formatted_address) {
      console.log(address.formatted_address);
      let lat = address.geometry.location.lat();
      let lng = address.geometry.location.lng();
      this.fullAddress.lat = lat;
      this.fullAddress.lng = lng;
      this.getPickupAddress(lat, lng, address.formatted_address);
    }
  }

  onKeyUp(value) {
    if (value === "") {
      this.fullAddress.lat = 0;
      this.fullAddress.lng = 0;
      this.addressFormGroup.controls["location"].patchValue(null);
    }
  }

  onAddChanged(value) {
    if (this.fullAddress.lat === 0 && this.fullAddress.lng === 0) {
      this.addressFormGroup.controls["location"].patchValue(null);
      // this.submitted = true;
    } else if (this.fullAddress.address !== value) {
      this.addressFormGroup.controls["location"].patchValue(null);
      // this.submitted = true;
    }
  }

  getPickupAddress(lat, lng, formatted_address) {
    this.geoCoder.geocode({ location: { lat: lat, lng: lng } }, (results, status) => {
      if (status === "OK") {
        if (results.length > 0) {
          console.log(results);
          var isAddSimilar = false;
          let length = results[0].address_components.length;
          // setTimeout(() => {
          //   if(!this.secondFormGroup.controls['zipCode'].value) {
          //     if(results[0].address_components[length-1].types.includes("postal_code")) {
          //       this.secondFormGroup.controls['zipCode'].patchValue(results[0].address_components[length-1].long_name);
          //     }
          //   }
          // }, 1000);
          results.forEach((addresses) => {
            if (addresses.formatted_address === formatted_address && !isAddSimilar) {
              isAddSimilar = true;
              console.log("exist");
              this.fetchAddress(addresses.address_components);
              return false;
            }
          });
          if (!isAddSimilar) {
            console.log("not-exist");
            this.fetchAddress(results[0].address_components);
          }
        } else {
          window.alert("No results found");
        }
      } else {
        window.alert("Geocoder failed due to: " + status);
      }
    });
  }

  fetchAddress(addressArr) {
    var city = null;
    var state = null;
    var zipCode = null;
    var country = null;
    addressArr.forEach((element) => {
      if (
        element.types.includes("locality") ||
        element.types.includes("postal_town") ||
        element.types.includes("sublocality") ||
        element.types.includes("sublocality_level_1") ||
        element.types.includes("sublocality_level_2") ||
        element.types.includes("sublocality_level_3") ||
        element.types.includes("sublocality_level_4") ||
        element.types.includes("sublocality_level_5")
      ) {
        city = element.long_name;
      }
      if (
        element.types.includes("administrative_area_level_1") ||
        element.types.includes("administrative_area_level_2") ||
        element.types.includes("administrative_area_level_3") ||
        element.types.includes("administrative_area_level_4") ||
        element.types.includes("administrative_area_level_5")
      ) {
        state = element.long_name;
      }
      if (element.types.includes("postal_code")) {
        zipCode = element.long_name;
      }
      if (element.types.includes("country")) {
        country = element.long_name;
      }
    });
    this.fullAddress.address = city + ", " + state + ", " + country;
    this.addressFormGroup.controls["location"].patchValue(city + ", " + state + ", " + country);
    this.addressFormGroup.controls["city"].patchValue(city);
    this.addressFormGroup.controls["state"].patchValue(state);
    this.addressFormGroup.controls["country"].patchValue(country);
    // this.secondFormGroup.controls['zipCode'].patchValue(zipCode);
  }

  checkDriverExist() {
    return new Promise((resolve, reject) => {
      this.httpService.httpPostWithHeader("checkDriverExist", { dlNumber: this.personalFormGroup.value.dlNumber.trim() }).subscribe((res: any) => {
        if (res.statusCode == 200) {
          return resolve(res.data);
        }
      });
    });
  }

  onStepChange(event) {
    console.log(event);
  }

  register(index: number, formName: string, action: string) {
    this.submitted = true;
    if (!this[formName].invalid) {
      // if(localStorage.length > 0) {
      //   if(localStorage.accessToken != null || localStorage.accessToken != undefined) {
      //     if(localStorage.userType === 'driver') {
      //       let param = new HttpParams().set('userId', localStorage.userId);
      //       this.httpService.httpGetWithHeader('getDriverProfile', param).subscribe((res: any) => {
      //         if (res.data.email) {
      //           this.editUpdate = true;
      //           this.stepper.selectedIndex++;
      //           return;
      //         }
      //       });
      //     }
      //   }
      // }
      var formdata = new FormData();
      formdata.append("firstName", this.personalFormGroup.value.firstName.trim());
      formdata.append("lastName", this.personalFormGroup.value.lastName.trim());
      formdata.append("email", this.personalFormGroup.value.email.trim().toLowerCase());
      formdata.append("countryCode", this.countryCode);
      formdata.append("phoneNo", this.phoneNo);
      formdata.append("address", this.addressFormGroup.value.location);
      formdata.append("zipCode", this.addressFormGroup.value.zipCode.trim());
      formdata.append("streetName", this.addressFormGroup.value.street);
      this.addressFormGroup.value.city && formdata.append("city", this.addressFormGroup.value.city);
      this.addressFormGroup.value.state && formdata.append("state", this.addressFormGroup.value.state);
      this.addressFormGroup.value.country && formdata.append("country", this.addressFormGroup.value.country);
      this.addressFormGroup.value.localAddress && formdata.append("localAddress", this.addressFormGroup.value.localAddress);
      formdata.append("deviceType", "WEB");
      formdata.append("deviceToken", new Date().toString());
      formdata.append("password", this.addressFormGroup.value.confirmPass.trim());
      formdata.append("driverType", this.commonService.driverType);
      formdata.append("latitude", this.fullAddress.lat.toString());
      formdata.append("longitude", this.fullAddress.lng.toString());
      if (this.allFileUploadUrl.proImage !== "") {
        formdata.append("image", this.allFileUploadUrl.proImage);
      }
      formdata.append("dlNumber", this.personalFormGroup.value.dlNumber.trim());
      this.httpService.httpPost("register", formdata).subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.getBankName();
          localStorage.setItem("accessToken", res.data.accessToken);
          localStorage.setItem("userId", res.data.id);
          localStorage.setItem("userType", "driver");
          localStorage.setItem("accessBy", "register");
          this.stepper.selectedIndex++;
          this.submitted = false;
        }
      });
    }
  }

  getBankName() {
    this.httpService.httpGetWithHeaderOnly("bankNames").subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.bankNameArr = res.data;
      }
    });
  }

  changeBank(bank) {
    this.bankFormGroup.get("institutionNumber").patchValue(bank.institutionNumber);
  }

  bankFormSubmit() {
    this.submitted = true;
    if (this.bankFormGroup.invalid) {
      return;
    }
    var param = {
      accountNumber: this.bankFormGroup.value.accNumber.toString(),
      transit: this.bankFormGroup.value.transit,
      bankName: this.bankFormGroup.value.bankName,
      bankAddress: this.bankFormGroup.value.branchAdd,
      institutionNumber: this.bankFormGroup.value.institutionNumber,
      checkImage: this.allFileUploadUrl.voidCheque !== "" ? this.allFileUploadUrl.voidCheque : this.fileDataUrl,
      isFilledBankDetail: true,
    };
    console.log("checkinstitutionNumber", param);
    this.httpService.httpPostWithHeader("updateProfile", param).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.submitted = false;
        this.driverProfileData = res.data;
        this.isLinear = false;
        this.onStepsChange.next(true);
        var details = {
          isFilledBankDetail: true,
          isDriverInfo: false,
          isVehicleInfo: false,
          isVehicleAbout: false,
          isRegularCompliance: false,
          isNewUser: false,
        };
        this.commonService.setUserProfileAccessDetail(details);
        this.driverProfileData.dlNumber && this.drivingFormGroup.get("dlNumber").patchValue(this.driverProfileData.dlNumber);
      }
    });
  }

  getDlExpiryDate(event: any) {
    this.drivingFormGroup.get("dlExpiry").patchValue(event.value);
  }

  getInsuranceExpiryDate(event: any) {
    this.vehicleFormGroup.get("insuranceExpiry").patchValue(event.value);
  }
  getOwnershipDate(event: any) {
    this.vehicleFormGroup.get("ownershipExpiry").patchValue(event.value);
  }

  getInspectionExpiryDate(event: any) {
    this.vehicleFormGroup.get("inspectionExpiry").patchValue(event.value);
  }

  drivingFormSubmit() {
    this.drivingInfoSubmitted = true;
    this.servingAddress.lat !== 0 && this.drivingFormGroup.get("servingLatitude").patchValue(this.servingAddress.lat);
    this.servingAddress.lng !== 0 && this.drivingFormGroup.get("servingLongitude").patchValue(this.servingAddress.lng);
    if (this.drivingFormGroup.invalid) {
      return;
    }
    var param = {
      dlNumber: this.drivingFormGroup.value.dlNumber,
      dlExpiry: this.drivingFormGroup.value.dlExpiry,
      dlCountry: this.drivingFormGroup.value.dlCountry,
      dlState: this.drivingFormGroup.value.dlState,
      dlImageFront: this.allFileUploadUrl.dlImage !== "" ? this.allFileUploadUrl.dlImage : this.fileDataUrl,
      isDriverInfo: true,
      servingAddress: this.servingAddress.address,
      servingLatitude: this.servingAddress.lat,
      servingLongitude: this.servingAddress.lng,
    };
    if (param.dlImageFront === "") {
      param.dlImageFront = this.allFileUploadUrl.dlImage;
    }
    console.log("image issue", param);
    // return;
    this.httpService.httpPostWithHeader("updateProfile", param).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.isLinear = false;
        this.driverProfileData = res.data;

        if (res.data.zoneId && res.data.geofenceId) {
          this.getVehicleCatData(res.data.zoneId, res.data.geofenceId);
          this.onStepsChange.next(true);
          var details = {
            isFilledBankDetail: true,
            isDriverInfo: true,
            isVehicleInfo: false,
            isVehicleAbout: false,
            isRegularCompliance: false,
            isNewUser: false,
          };
          this.commonService.setUserProfileAccessDetail(details);
        } else {
          this.commonService.driverAlert();
        }
      }
    });
  }

  getVehicleCatData(zoneId, geofenceId) {
    if (zoneId && geofenceId) {
      var param2 = {
        zoneId: zoneId,
        geofenceId: geofenceId,
      };
      this.httpService.httpPostWithHeader("getVehicleCategory", param2).subscribe((res: any) => {
        if (res.statusCode === 200) {
          this.getVehicleCategoryData = res.data.vehicleCategoryData;
        }
      });
    }
  }

  onVehicleMakeChange(vehicleMake) {
    vehicleMakeModels.models.forEach((element: any) => {
      if (element.make === vehicleMake) {
        this.vehicleModelData = element.models;
        this.vehicleFormGroup.get("vehicleModel").patchValue(null);
      }
    });
  }

  vehicleFormSubmit() {
    this.isVehicleSubmit = true;
    if (this.vehicleFormGroup.invalid) {
      return;
    }
    var param = {
      vehicleMake: this.vehicleFormGroup.value.vehicleMake,
      vehicleModel: this.vehicleFormGroup.value.vehicleModel,
      vehicleImage: this.allFileUploadUrl.vehicleImage,
      vehicleYear: this.vehicleFormGroup.value.vehicleYear.toString(),
      licensePlate: this.allFileUploadUrl.licensePlateImage,
      licensePlateNumber: this.vehicleFormGroup.value.licensePlateNo,
      isAcWorking: this.vehicleFormGroup.value.isAcWorking,
      isheatingWorking: this.vehicleFormGroup.value.isHeatingWorking,
      isDentRust: this.vehicleFormGroup.value.isAnyDents,
      isCleaning: this.vehicleFormGroup.value.isInteriorClean,
      registrationCopyImage: this.allFileUploadUrl.registrationImage,
      vehicleInspectionImage: this.allFileUploadUrl.inspectionImage,
      inspectioneExpiryDate: this.vehicleFormGroup.value.inspectionExpiry,
      insurancePolicyNumber: this.vehicleFormGroup.value.policyNumber,
      insuranceName: this.vehicleFormGroup.value.insuranceName,
      insuranceExpiryDate: this.vehicleFormGroup.value.insuranceExpiry,
      shareDriverCoverage: this.vehicleFormGroup.value.driveCoverage,
      insuranceImage: this.allFileUploadUrl.insuranceImage,
      registrationDate: this.vehicleFormGroup.value.ownershipExpiry,
      isVehicleInfo: true,
      isVehicleAbout: true,
      isPreLaunch: false,
      isVehicleInsurance: true,
      vehicleCategoryId: this.vehicleFormGroup.value.vehicleType,
    };
    this.httpService.httpPostWithHeader("updateProfile", param).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.driverProfileData = res.data;
        this.isLinear = false;
        this.onStepsChange.next(true);
        var details = {
          isFilledBankDetail: true,
          isDriverInfo: true,
          isVehicleInfo: true,
          isVehicleAbout: true,
          isRegularCompliance: false,
          isNewUser: false,
        };
        this.commonService.setUserProfileAccessDetail(details);
        this.getDriverDetails();
      }
    });
  }

  regularityFormSubmit() {
    this.regularityFormSubmitted = true;
    if (this.regulatoryFormGroup.invalid) {
      return;
    }
    var param = {
      yourBackgroundImage: this.allFileUploadUrl.criminalBg,
      yourBackgroundCertificateExpiryDate: this.regulatoryFormGroup.value.criminalBgExpiry,
      childAbuseCertificateImage: this.allFileUploadUrl.abuseRegisterCheck,
      childAbuseCertificateExpiryDate: this.regulatoryFormGroup.value.childAbuseExpiry,
      isTermAndCondition: true,
      isRegularCompliance: true,
    };
    this.httpService.httpPostWithHeader("updateProfile", param).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.driverProfileData = res.data;
        var details = {
          isFilledBankDetail: true,
          isDriverInfo: true,
          isVehicleInfo: true,
          isVehicleAbout: true,
          isRegularCompliance: true,
          isNewUser: false,
        };
        var validateDocument = this.requiredDocumentsData.map((data) => data.isMandate);
        if (validateDocument.includes(true)) {
          this.commonService.setUserProfileAccessDetail(details);
          this.isLinear = false;
          this.onStepsChange.next(true);
          this.getDriverDetails();
        } else {
          this.markAsCompleteProfile();
        }
      }
    });
  }

  getDriverDetails() {
    if (localStorage.userId === undefined || localStorage.userId === null) {
      return;
    }
    let param = new HttpParams().set("userId", localStorage.userId);
    this.httpService.httpGetWithHeader("getDriverProfile", param).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.driverProfileData = res.data.driverData;
        this.driverProfileData.dlNumber && this.drivingFormGroup.get("dlNumber").patchValue(this.driverProfileData.dlNumber);
        var details = {
          isFilledBankDetail: res.data.driverData.isFilledBankDetail,
          isDriverInfo: res.data.driverData.isDriverInfo,
          isVehicleInfo: res.data.driverData.isVehicleInfo,
          isVehicleAbout: res.data.driverData.isVehicleAbout,
          isRegularCompliance: res.data.driverData.isRegularCompliance,
          isNewUser: false,
        };
        this.commonService.setUserProfileAccessDetail(details);
        if (res.data.driverData.zoneId && res.data.driverData.geofenceId) {
          var params = {
            zoneId: res.data.driverData.zoneId,
            geofenceId: res.data.driverData.geofenceId,
            pageNo: 1,
          };
          this.httpService.httpPostWithHeader("getDefaultDriverDocument", params).subscribe((res: any) => {
            if (res.statusCode == 200) {
              this.requiredDocumentsData = res.data.documentData;
              var validateDocument = this.requiredDocumentsData.map((data) => data.isMandate);
              if (!validateDocument.includes(true)) {
                this.validateDocument.next(false);
              } else {
                this.validateDocument.next(true);
              }
              const group: any = {};
              this.requiredDocumentsData.forEach((data) => {
                if (data.isMandate) {
                  if (data.isFrontSideImage) {
                    group[data.documentName + "FrontSide"] = new FormControl(null, Validators.required);
                  }
                  if (data.isBackSideImage) {
                    group[data.documentName + "BackSide"] = new FormControl(null, Validators.required);
                  }
                  if (data.isExpirydate) {
                    group[data.documentName + "Expiry"] = new FormControl(null, Validators.required);
                  }
                } else {
                  if (data.isFrontSideImage) {
                    group[data.documentName + "FrontSide"] = new FormControl(null);
                  }
                  if (data.isBackSideImage) {
                    group[data.documentName + "BackSide"] = new FormControl(null);
                  }
                  if (data.isExpirydate) {
                    group[data.documentName + "Expiry"] = new FormControl(null);
                  }
                }
                this.documentsImages[data.documentName + "FrontSide"] = "";
                this.documentsImages[data.documentName + "BackSide"] = "";
                this.documentsImages[data.documentName + "id"] = data._id;
              });
              this.documentFormGroup = new FormGroup(group);
            }
          });
        }
      }
    });
  }

  getExpiryDateOfDocuments(event: any, formField: any) {
    this.documentFormGroup.get(formField).patchValue(event.value);
  }

  onDocumentUpload(fileInput: any, formField) {
    const fileData = <File>fileInput.target.files[0];
    this.httpService.fileUpload(fileData).subscribe((res) => {
      this.fileDataUrl = JSON.parse(JSON.stringify(res)).data.orignal;
      this.documentsImages[formField] = this.fileDataUrl;
    });
  }

  documentFormSubmit() {
    this.documentSubmitted = true;
    var documentData = this.requiredDocumentsData.map((data) => data.isMandate);
    if (!documentData.includes(true)) {
      this.markAsCompleteProfile();
    } else {
      if (this.documentFormGroup.invalid) {
        return;
      }
      var documents = [];
      this.requiredDocumentsData.forEach((element: any) => {
        var documentObj = $.extend(
          {},
          {
            documentId: element._id,
            documentFrontImage: element.isFrontSideImage === true ? this.documentsImages[element.documentName + "FrontSide"] : undefined,
            documentBackImage: element.isBackSideImage === true ? this.documentsImages[element.documentName + "BackSide"] : undefined,
            documentExpirydate: element.isExpirydate === true ? this.documentFormGroup.get(element.documentName + "Expiry").value : undefined,
          }
        );
        documents.push(documentObj);
      });
      var param = {
        documents: documents,
        isUploadDocument: true,
      };
      this.httpService.httpPostWithHeader("addDriverDocument", param).subscribe((res: any) => {
        if (res.statusCode == 200) {
          // this.commonService.openCongratsModal();
          this.markAsCompleteProfile();
          // this.commonService.OpenNotificationModal('success', 'top-end', 'your account created successfully.')
          // this.router.navigate(["/uhomepage"]);
          // this.router.navigate(["/ridercongratulations"]);
        }
      });
    }
  }

  markAsCompleteProfile() {
    var param = {
      isAccountCompleted: true,
    };
    this.httpService.httpPostWithHeader("updateProfile", param).subscribe((res: any) => {
      if (res.statusCode === 200) {
        this.driverProfileData = res.data;
        this.commonService.localStorageCLear();
        this.commonService.goToDownloadSection.next(true);
        this.router.navigate(["udriver"]);
        Swal.fire({
          title: this.profileCompletedMsg,
          // text: "You won't be able to revert this!",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Okay",
        }).then((result) => {
          if (result.value) {
            this.commonService.localStorageCLear();
            this.commonService.goToDownloadSection.next(true);
            this.router.navigate(["udriver"]);
          }
        });
      }
    });
  }

  goToCmsPages(url) {
    window.open(environment.webUrl + url, "_blank");
  }

  goTovechicle() {
    this.router.navigate(["vechicle"]);
  }
  goTologin() {
    this.router.navigate(["login"]);
  }

  goTouhomepage() {
    this.router.navigate(["uhomepage"]);
  }
}

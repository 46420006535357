import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { HttpsService } from '../services/https/https.service';
import { CommonService } from '../services/common/common.service';
import { HttpParams } from '@angular/common/http';
import { ViewportScroller } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { SocketioService } from "../services/sockets/socketio.service";
import { Event } from "../core/models/event";
import { ParentService } from "src/app/services/parent/parent.service";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { MessagingServiceService } from "src/app/services/messaging-service.service";
declare var $: any;

@Component({
  selector: "app-uhomepage",
  templateUrl: "./uhomepage.component.html",
  styleUrls: ["./uhomepage.component.scss"],
})
export class UhomepageFComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild("whyDiv") divView: ElementRef;
  notifier = new Subject();
  currentYear: number;
  advertisementData: any;
  baseUrl = environment.imageUrl;
  timerStart: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  totalCounter: number;
  intervalSubscription: any;
  adsDelay: number;
  cancelCounter: number;
  intervalSub: NodeJS.Timeout;
  adsArr: any = [];
  _this = this;
  @ViewChild('myVideo', { static: true }) myVideo: ElementRef;

  constructor(private router: Router,
    private http: HttpsService,
    private commonService: CommonService,
    private vps: ViewportScroller,
    private socketService: SocketioService,
    private parentService: ParentService,
    public translate: TranslateService,
    private messagingService: MessagingServiceService) {

  }

  ngOnInit(): void {
    console.log(this.parentService.latitude, this.parentService.longitude)
    if (this.parentService.latitude && this.parentService.longitude) {
      let d = new Date();
      var timeZone = d.getTimezoneOffset();
      if (Math.sign(timeZone) === 1) {
        timeZone = -Math.abs(timeZone);
      } else {
        timeZone = Math.abs(timeZone);
      }
      this.socketService.initAdSocket(this.parentService.latitude, this.parentService.longitude, timeZone)
    } else {
      this.getCurrentLocation();
    }
    var d = new Date();
    this.currentYear = d.getFullYear();
    this.commonService.getDownloadSection.pipe(takeUntil(this.notifier)).subscribe((value: any) => {
      if (value) {
        var element = document.getElementById('our_driver');
        var headerOffset = 250;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    });
    this.socketService.isWebsiteConnect.subscribe((isConnect: boolean) => {
      if (isConnect) {
        this.listenAdEvent();
      }
    })
    this.timerStart.pipe(takeUntil(this.notifier)).subscribe((val: boolean) => {
      if (val) {
        if (localStorage.isCancelCounterStopped !== undefined) {
          if (localStorage.isCancelCounterStopped === 'true') {
            this.cancelCounter = 0;
            localStorage.removeItem('isCancelCounterStopped');
          } else {
            this.startCounter();
          }
        } else {
          this.startCounter();
        }

      } else {
        this.cancelCounter = 0;
      }
    });
    $(document).click(function (e) {
      if (e.target.classList.value === 'modal fade') {
        console.log('click outside!');
        document.querySelector('video').pause();
        document.querySelector('video').currentTime = 0;
      }
    });
  }

  startCounter() {
    this.cancelCounter = 1;
    if (this.intervalSubscription) {
      clearInterval(this.intervalSubscription);
      this.cancelCounter = 10;
    }
    this.intervalSubscription = setInterval(() => {
      if (this.cancelCounter === this.totalCounter) {
        localStorage.isCancelCounterStopped = true;
        clearInterval(this.intervalSubscription);
      }
      this.cancelCounter++;
    }, this.adsDelay);
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        let lat = position.coords.latitude;
        let lng = position.coords.longitude;
        var d = new Date()
        var timeZone = d.getTimezoneOffset();
        if (Math.sign(timeZone) === 1) {
          timeZone = -Math.abs(timeZone);
        } else {
          timeZone = Math.abs(timeZone);
        }
        this.socketService.initAdSocket(lat, lng, timeZone)
      }, err => {
        console.log(err)
      });
    }
  }

  listenAdEvent() {
    this.socketService.onEvent('showAds').pipe(takeUntil(this.notifier)).subscribe((response: any) => {
      console.log('showAds', response);
      this.totalCounter = response.length;
      if (response.length > 1) {
        this.adsDelay = (response[0].duration) * 1000;
        this.adsArr = JSON.parse(JSON.stringify(response));
        this.advertisementData = this.adsArr[0];

        if (!this.advertisementData.isBlocked && !this.advertisementData.isDeleted && this.advertisementData.isPaymentConfirmed) {
          $("#add").modal('show');
          if (this.advertisementData.videoclip) {
            // document.querySelector('video').load();
            // document.querySelector('video').play();
          }
          this.adsArr.splice(0, 1);
          this.intervalSub = setInterval(this.adsFunction.bind(this), this.adsDelay);
        }
      } else {
        if (!response[0].isBlocked && !response[0].isDeleted) {

          this.advertisementData = response[0];
          $("#add").modal('show');
          if (this.advertisementData.videoclip) {
            // document.querySelector('video').play();
          }
          let delay = (response[0].duration) * 1000;
          setTimeout(() => {
            $("#add").modal('hide');
            if (this.advertisementData.videoclip) {
              document.querySelector('video').pause();
              document.querySelector('video').currentTime = 0;
            }
          }, delay)
        }
      }
    });
  }

  closeModal() {
    if (this.advertisementData.videoclip) {
      document.querySelector('video').pause();
      document.querySelector('video').currentTime = 0;
    }
  }

  adsFunction() {
    clearInterval(this.intervalSub);
    if (this.adsArr.length > 0) {
      if (this.advertisementData.isBlocked || this.advertisementData.isDeleted || !this.advertisementData.isPaymentConfirmed) {
        this.adsDelay = (this.advertisementData.duration) * 1000;
        this.adsArr.splice(0, 1);
        this.intervalSub = setInterval(this.adsFunction.bind(this), this.adsDelay);
      }
      this.advertisementData = this.adsArr[0];
      if (this.advertisementData.videoclip) {
        // document.querySelector('video').load();
        // document.querySelector('video').play();
      }
      this.adsDelay = (this.advertisementData.duration) * 1000;
      this.adsArr.splice(0, 1);
      this.intervalSub = setInterval(this.adsFunction.bind(this), this.adsDelay);
    } else {
      $("#add").modal('hide');
      if (this.advertisementData.videoclip) {
        document.querySelector('video').pause();
        document.querySelector('video').currentTime = 0;
      }
      clearInterval(this.intervalSub);
    }
  }


  ngAfterViewInit() {
    var ele = document.getElementById("whyU2go");
    localStorage.whyU2go !== undefined && ele.click(); localStorage.removeItem('whyU2go');
  }

  goTologin() {
    this.router.navigate(["/login"]);
    // if (localStorage.accessToken != null || localStorage.accessToken != undefined) {
    //   if (localStorage.getItem('userType') === 'driver') {
    //     let param = new HttpParams().set('userId', localStorage.userId);
    //     this.http.httpGetWithHeader('getDriverProfile', param).subscribe((res: any) => {
    //       if (res.data.driverData.isPhoneVerified === true) {
    //         if (!res.data.driverData.isUploadDocument) {
    //           this.router.navigate(["/profile"]);
    //         } else {
    //           this.router.navigate(["/login"]);
    //         }
    //       }
    //     })
    //   } else {
    //     this.router.navigate(["/login"]);
    //   }
    // } else {
    //   this.router.navigate(["/login"]);
    // }
  }
  goTosignup() {
    this.router.navigate(["/signup"]);
  }
  goToriderlogin() {
    if (localStorage.accessToken != null || localStorage.accessToken != undefined) {
      if (localStorage.getItem('userType') === 'rider') {
        this.http.httpGetWithHeaderUser('userProfile', "").subscribe((res: any) => {
          if (res.data.isEamilVerified === true || res.data.isPhoneVerified === true) {
            if (res.data.isAccountCompleted) {
              this.router.navigate(["/riderequest"]);
            } else {
              var detail = {
                isAddressInfo: true,
                isNewUser: false,
                isAccountCompleted: false
              }
              this.commonService.setRiderProfileAccessDetail(detail);
              this.router.navigate(["/riderdetail"]);
            }
          } else {
            this.http.httpPostWithHeaderUser('sendOtp', { phoneNo: res.data.phoneNo }).subscribe((item: any) => {
              this.commonService.setVerificationData({ countryCode: item.data.countryCode, phoneNo: item.data.phoneNo, otpCode: item.data.otpCode })
              this.router.navigate(["/riderotppage"]);
            });
          }
        })
      } else {
        this.router.navigate(["/riderlogin"]);
      }
    } else {
      this.router.navigate(["/riderlogin"]);
    }
  }
  goToridersignup() {
    this.router.navigate(["/ridersignup"]);
  }
  goToriderdeliverybooking() {
    this.router.navigate(["/riderdeliverybooking"]);
  }

  goToWhySecton() {
    document.getElementById('why_id').scrollIntoView();
  }

  goToFbPage() {
    window.open('https://www.facebook.com/u2gocanada', '_blank');
  }

  goToBP() {
    localStorage.setItem('bpSection', 'true');
    this.router.navigateByUrl("/ubusinesspartners");
  }

  pushNotify() {
    this.messagingService.permitToNotify();
  }

  ngOnDestroy() {
    this.socketService.close();
    this.notifier.next();
    this.notifier.complete();
  }

}

import { HttpClient } from "@angular/common/http";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { ParentService } from "./services/parent/parent.service";
declare var $: any;
import * as bootstrap from "bootstrap";
import { MessagingServiceService } from "./services/messaging-service.service";
import { TranslateService } from "@ngx-translate/core";
import { SwPush, SwUpdate } from "@angular/service-worker";
import { NotificationService } from "./services/notification/notification.service";
import firebase from "firebase/app";
import "firebase/messaging";
// declare var clients: any;
{
  window as any;
}
// import { NgxFreshdeskWebwidgetModule, NgxFreshdeskWebwidgetConfig, NgxFreshdeskWebwidgetService } from 'ngx-freshdesk-webwidget';
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = "APPTUNIX Website";
  latitude: number;
  longitude: number;
  display = "none";
  data = "";
  baseUrl = environment.imageUrl;
  counter: any;
  intervalSubscription: any;
  message: any;
  readonly VAPID_PUBLIC_KEY: string = environment.VAPID_PUBLIC_KEY;
  serviceWorkerAttempt = 0;
  notificationActions: any;

  constructor(
    private router: Router,
    private http: HttpClient,
    public parentService: ParentService,
    public notification: NotificationService,
    private messagingService: MessagingServiceService,
    translate: TranslateService,
    updates: SwUpdate,
    push: SwPush
  ) {
    navigator.serviceWorker.register("ngsw-worker.js");
    firebase.initializeApp(environment.firebaseConfig);
    const setInt = () => {
      navigator.serviceWorker.getRegistration().then((swr: any) => {
        this.serviceWorkerAttempt++;
        console.log("swr", swr);
        if (swr != undefined) {
          firebase.messaging().useServiceWorker(swr);
        } else {
          if (this.serviceWorkerAttempt > 0 && this.serviceWorkerAttempt < 3) {
            setInt();
          }
        }
      });
    };
    setInt();
    updates.available.subscribe((_) =>
      updates.activateUpdate().then(() => {
        console.log("reload for update");
        document.location.reload();
      })
    );
    push.messages.subscribe((msg: any) => {
      console.log("push message1", msg.data);
      this.notificationActions = JSON.parse(msg.data["gcm.notification.data"]);
      console.log("push message2", JSON.parse(msg.data["gcm.notification.data"]));
    });
    push.notificationClicks.subscribe((click: any) => {
      console.log("notification click", click);
      click.notification.data = this.notificationActions;
      click.notification.data.onActionClick.default.operation;
    });
    const self = window as any;
    self.addEventListener("notificationclick", function (event: any) {
      console.log("notification click event", event);
      var redirect_url = event.notification.data.click_action;
      event.notification.close();
      event.waitUntil(
        self.clients
          .matchAll({
            type: "window",
          })
          .then(function (clientList) {
            for (var i = 0; i < clientList.length; i++) {
              var client = clientList[i];
              if (client.url == "/" && "focus" in client) return client.focus();
            }
            if (self.clients.openWindow) return self.clients.openWindow("/");
          })
      );
    });
  }

  ngOnInit() {
    setTimeout(() => {
      console.log("run");
      this.permitToNotify();
      // this.messagingService.receiveMessage();
    }, 5000);
  }

  permitToNotify() {
    const messaging = firebase.messaging();
    messaging
      .requestPermission()
      .then(() =>
        messaging.getToken().then((token: any) => {
          console.log(token);

          this.messagingService.token = token;
        })
      )
      .catch((err: any) => {
        console.log("Unable to get permission to notify.", err);
      });
  }

  ngAfterViewInit() {}
}

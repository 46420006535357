import { Component, OnInit, NgModule, NgZone, OnDestroy, Input, Output, EventEmitter, ViewEncapsulation } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { HttpsService } from '../../prelaunch/services/https/https.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { CommonService } from '../../prelaunch/services/common/common.service';
import { environment } from 'src/environments/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { SocketioService } from '../../prelaunch/services/sockets/socketio.service';
import { Event } from '../../prelaunch/core/models/event';
import { Action } from '../../prelaunch/core/models/action';
import Swal from 'sweetalert2';
import { takeWhile, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { element } from 'protractor';
import { LocationStrategy, ViewportScroller } from '@angular/common';
import { Constant } from '../../prelaunch/core/constant';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "src/app/services/language/language.service";
declare var google: any;
declare var $: any;

@Component({
  selector: "app-riderequest",
  templateUrl: "./riderequest.component.html",
  styleUrls: ["./riderequest.component.scss"],
  encapsulation: ViewEncapsulation.Emulated
})
export class RiderequestComponent implements OnInit, OnDestroy {
  selected = "label";
  lat: number = 51.678418;
  lng: number = 7.809007;
  public map: any = { lat: 51.678418, lng: 7.809007 };
  nextPopup = 0;
  user: any;
  bookingForm: FormGroup;
  pickup = {
    lat: 0,
    lng: 0
  }
  pickupAddress = "";
  drop = {
    lat: 0,
    lng: 0
  }
  current = {
    lat: 0,
    lng: 0
  }
  dropAddress = "";
  geoCoder: any;
  zoom: number = 12;
  // origin: any;
  // destination: any;
  showPickMarker: boolean = true;
  showDropMarker: boolean = false;
  public renderOptions = {
    suppressMarkers: true,
  }
  originMarkerIcon: string = 'assets/uimages/originmarker.png';
  destinationMarkerIcon: string = 'assets/uimages/destination.png'
  marker1: string = 'assets/images/marker1.png';
  marker2: string = 'assets/images/marker2.png';
  marker3: string = 'assets/images/marker3.png';
  marker4: string = 'assets/images/marker4.png';
  markerOptions: any;
  // markerOptions = {
  //   origin: {
  //     icon: this.originMarkerIcon,
  //     label: this.pickupAddress,
  //   },
  //   destination: {
  //     icon: this.destinationMarkerIcon,
  //     label: this.dropAddress,
  //   },
  // }
  vehicleCategoryData: any = [];
  distance: any;
  rideSubmited = false;
  origin;
  destination;
  selectedItem = 0;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.Canada];
  driverNoteForm: FormGroup;
  DriverNoteFormSubmitted = false;
  selectedVehicleData: any;
  baseUrl: string;
  requestForLaterForm: FormGroup;
  mindate = new Date();
  bookingFor: string = 'Now';
  isDateTimeSubmit: boolean = false;
  activeBtn: string = 'Now';
  cancelCounter = 0;
  timerStart: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  distanceInKM: any;
  duration: any;
  driverAddress: any;
  driverLatLng: { lat: any; lng: any; };
  assignedDriverData: any;
  bookingId: any;
  showDirections: boolean = false;
  requestData: any;
  isDropChanged: boolean = false;
  isPickupChanged: boolean = false;
  nearByDriversData: any = [];
  bookingTimeInH: any;
  ubericon: string = "assets/images/ic_car.svg";
  isTollRoute: boolean = false;
  notifier = new Subject();
  bookingResponseData: any;
  //ratings
  @Input('rating') public rating: number = 0;
  @Input('starCount') public starCount: number = 5;
  @Input('color') private color: string = 'accent';
  @Output() private ratingUpdated = new EventEmitter();
  reviewData = [
    { name: 'Excellent', isActive: false },
    { name: 'Very Good', isActive: false },
    { name: 'Safe Driving', isActive: false },
    { name: 'Good', isActive: false },
    { name: 'Enjoy Full Ride', isActive: false }
  ];
  dummy: any[] = [
    { amount: 2, isActive: false },
    { amount: 5, isActive: false },
    { amount: 8, isActive: false },
    { amount: 10, isActive: false },
  ]
  selectedTip: number;

  private snackBarDuration: number = 2000;
  public ratingArr = [];
  isDriverFavourite: boolean = false;
  isFeedBackSelected: boolean = false;
  feedBackQue = [
    { que: 'Was the driver on time?' },
    { que: 'Did the driver make your ride comfortabel?' },
    { que: 'Was the ride safe?' }
  ]
  selectedFeedbckQna: any = [];
  wasTheDriverOnTime: FormControl = new FormControl(null);
  driverMakeYouComfortabel: FormControl = new FormControl(null);
  wasTheRideSafe: FormControl = new FormControl(null);
  selectedDriverReview: number;
  customRate: FormControl = new FormControl(null);
  selectedTipAmount: any;
  comment: FormControl = new FormControl(null);
  ratingSubmitted: boolean = false;
  selectedReviewData: any = '';
  driverReviewData: any;
  isDriveingStart: boolean = false;
  sportCar = "assets/images/sport-car.svg";
  driverIcon: any;
  isContactDetailHide: boolean = true;
  isThisOnlyTrackingPage: boolean = false;
  monitorForm: FormGroup;
  monitorSubmit: boolean = false;
  review: FormControl = new FormControl('');
  // driverIcon: any;
  intervalSubscription;
  pickupFieldsCount: number = 1;
  dropupFieldsCount: number = 1;
  pickupForm: FormGroup;
  dropupForm: FormGroup;
  pickupFormObj: {};
  dropupFormObj: {};
  waypoints = [];
  distancesForPath: any = [];
  calculated: boolean = true;
  calculateSubscription: any;
  directionStatus: any;
  options = '';
  currentCountry = ''
  pickupfieldsArr: any = [];
  dropupfieldsArr: any = [];
  @Input() transitOptions?: google.maps.TransitOptions;
  @Input() unitSystem?: google.maps.UnitSystem.IMPERIAL;
  @Input() travelMode?: google.maps.TravelMode.DRIVING;
  @Input() drivingOptions?: google.maps.DrivingOptions;
  languageCode: any;
  showLang: boolean = false;

  constructor(private router: Router,
    private http: HttpsService,
    private fb: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private ngxService: NgxUiLoaderService,
    private socketService: SocketioService,
    private commonService: CommonService,
    private snackBar: MatSnackBar,
    private location: LocationStrategy,
    private vps: ViewportScroller,
    public constant: Constant,
    private https: HttpClient,
    public translate: TranslateService,
    public languageService: LanguageService
  ) {
    this.baseUrl = environment.imageUrl;


  }

  ngOnInit(): void {
    this.socketService.initSocket();
    this.getCountry().then((stats: any) => {
      if (stats.status === "success") {
        this.createForm(stats.countryCode);
      } else {
        this.createForm('CA');
      }
    });

    // this.dropupForm.get('dropup1').valueChanges.subscribe(val2 => {
    //   if(val2 === "") {
    //     this.dropupfieldsArr[0].lat = 0;
    //     this.dropupfieldsArr[0].lng = 0;
    //     this.dropupfieldsArr[0].address = '';
    //     this.dropupfieldsArr[0].distance = 0;
    //   }
    // });

    this.markerOptions = {
      origin: {
        icon: this.marker1
      },
      destination: {
        icon: this.destinationMarkerIcon
      }
    }
    this.calculateSubscription = setInterval(() => {
      this.calculated = false;
      console.log('ok');
    }, 60000);
    this.preventBackButton();
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
    this.driverNoteForm = this.fb.group({
      phoneNo: [''],
      name: [''],
      notes: [''],
    });
    this.monitorForm = this.fb.group({
      name: ['', Validators.required],
      phoneNo: ['', Validators.required],
    })
    this.requestForLaterForm = this.fb.group({
      dateTime: [null, Validators.required]
    })
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      // this.initializeMapPosition();
    });
    this.getUser();

    this.customRate.valueChanges.subscribe((value: any) => {
      this.selectedTip = 10000;
    });
    this.review.valueChanges.subscribe((value: any) => {
      if (value) {
        this.selectedReviewData = value;
      } else {
        this.selectedReviewData = '';
      }
    });

  }

  selectLang(language) {
    this.languageCode = language.code;
    this.languageService.selectedLang = language;
    this.languageService.translateLanguage(this.languageCode);
    this.showLang = false;
  }

  getCountry() {
    return new Promise((resolve, reject) => {
      this.https.get('https://extreme-ip-lookup.com/json/').subscribe((location: any) => {
        this.currentCountry = location.countryCode;
        return resolve(location);
      });
    })
  }

  createForm(country) {
    this.pickupfieldsArr = [{ name: 'pickup1', lat: 0, lng: 0, address: '', distance: 0, country: country }];
    this.dropupfieldsArr = [{ name: 'dropup1', lat: 0, lng: 0, address: '', distance: 0, country: country }];
    this.pickupFormObj = {};
    this.pickupfieldsArr.forEach(element => {
      let key = element.name;
      this.pickupFormObj[key] = new FormControl('', Validators.required);
    });
    this.pickupForm = this.fb.group(this.pickupFormObj);
    this.pickupForm.get('pickup1').valueChanges.subscribe(val => {
      if (val === "") {
        this.pickupfieldsArr[0].lat = 0;
        this.pickupfieldsArr[0].lng = 0;
        this.pickupfieldsArr[0].address = '';
        this.pickupfieldsArr[0].distance = 0;
      }
    });
    this.dropupFormObj = {};
    this.dropupfieldsArr.forEach(element => {
      let key = element.name;
      this.dropupFormObj[key] = new FormControl('', Validators.required);
    });
    this.dropupForm = this.fb.group(this.dropupFormObj);
    // this.getCurrentLocation();
    if (this.commonService.origin && this.commonService.destination) {
      if (this.commonService.origin.lat !== 0 && this.commonService.destination.lat !== 0) {
        this.pickupForm.get('pickup1').patchValue(this.commonService.origin.address);
        this.dropupForm.get('dropup1').patchValue(this.commonService.destination.address);
        this.pickupfieldsArr[0].lat = this.commonService.origin.lat;
        this.pickupfieldsArr[0].lng = this.commonService.origin.lng;
        this.dropupfieldsArr[0].lat = this.commonService.destination.lat;
        this.dropupfieldsArr[0].lng = this.commonService.destination.lng;
        this.drawPath(this.commonService.origin, this.commonService.destination, this.originMarkerIcon);
      } else {
        this.getCurrentLocation();
      }
    } else {
      this.getCurrentLocation();
    }
  }

  preventBackButton() {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  getNearbyDrivers(position) {
    var param = {
      "latitude": position.lat,
      "longitude": position.lng,
      "pageNo": 1
    }
    this.http.httpPostWithHeaderUser('getNearbyDriversWithGeoFenceId', param).pipe(takeUntil(this.notifier)).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      if (res.statusCode === 200) {
        this.nearByDriversData = res.data.drivers;
      }
    });
  }

  getUser() {
    this.http.httpGetWithHeaderUser('userProfile', '').pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      if (res.statusCode === 200) {
        this.user = res.data;
        if (res.data.role === 'DELIVERY_PARTNER') {
          this.commonService.isThisDeliveryPartner = true;
          // this.router.navigate(['/riderdeliverybooking']);
        } else {
          this.commonService.isThisDeliveryPartner = false;
        }
      }
    });
  }

  initializeMapPosition() {
    this.commonService.getPosition().then(position => {
      this.pickup = position;
      // this.getPickupAddress(this.pickup);
    });
    // this.currentPosition = this.commonService.getLocalStorageItemParsed(Key.CURRENT_POSITION);
    // if (this.currentPosition) {
    //   this.pickup = this.currentPosition;
    //   this.emitNearByDrivers(this.currentPosition);
    // }
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.pickupfieldsArr[0].lat = position.coords.latitude;
        this.pickupfieldsArr[0].lng = position.coords.longitude;
        this.pickup.lat = position.coords.latitude;
        this.pickup.lng = position.coords.longitude;
        this.current.lat = position.coords.latitude;
        this.current.lng = position.coords.longitude;
        this.zoom = 15;
        this.getPickupAddress({ lat: position.coords.latitude, lng: position.coords.longitude }, this.pickupfieldsArr[0], '');
        // this.getNearbyDrivers({ lat: position.coords.latitude, lng: position.coords.longitude });
      }, err => {
        console.log(err)
      });
    }
  }

  onMapReady(map: any) {
    this.map = map;
    if (map) {
      map.setOptions({
        streetViewControl: false
      });
    }
  }

  markerDragEndOrigin(event: any, data) {
    console.log(event);
    if (event.latLng) {
      data.lat = event.latLng.lat();
      data.lng = event.latLng.lng();
      this.getPickupAddress({ lat: data.lat, lng: data.lng }, data, '');
      // this.getNearbyDrivers(this.pickup);
    } else if (event.coords) {
      data.lat = event.coords.lat;
      data.lng = event.coords.lng;
      this.getPickupAddress({ lat: data.lat, lng: data.lng }, data, '');
      // this.getPickupAddress(this.pickup);
      // this.getNearbyDrivers(this.pickup);
    }

  }
  markerDragEndDestination(event: any, data) {
    console.log(event);
    if (event.latLng) {
      data.lat = event.latLng.lat();
      data.lng = event.latLng.lng();
      this.getAddressDrop({ lat: data.lat, lng: data.lng }, data, '');
    } else if (event.coords) {
      data.lat = event.coords.lat;
      data.lng = event.coords.lng;
      // this.drop.lat = this.dropupfieldsArr[]
      this.getAddressDrop({ lat: data.lat, lng: data.lng }, data, '');
    }
  }

  handlePickUpChnage(address, data) {
    console.log(address);
    this.isPickupChanged = true;
    if (address.formatted_address) {
      this.pickup.lat = 0;
      this.pickup.lng = 0;
      this.pickupAddress = (address.formatted_address);
      var lat = (address.geometry.location.lat());
      var lng = (address.geometry.location.lng());
      console.log('pick latLng',lat,lng)
      this.pickupfieldsArr.forEach(element => {
        if (element.name === data.name) {
          element.lat = lat;
          element.lng = lng;
        }
      });
      this.pickup.lat = lat;
      this.pickup.lng = lng;
      // this.getPickupAddress(this.pickup,data,address.formatted_address);
      // this.getNearbyDrivers(this.pickup);
      data.address = (address.formatted_address);
      this.pickupForm.get(data.name).patchValue(this.pickupAddress);
      this.calculateDistanceMatrix({ lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, { lat: lat, lng: lng }, data);

    }
  }

  handleDropChnage(address, data) {
    console.log(address);
    this.drop.lat = 0;
    this.drop.lng = 0;
    if (address.formatted_address) {
      this.dropAddress = (address.formatted_address);
      var lat = (address.geometry.location.lat());
      var lng = (address.geometry.location.lng());
      this.dropupfieldsArr.forEach(element => {
        if (element.name === data.name) {
          element.lat = lat;
          element.lng = lng;
        }
      });
      var droplength = this.dropupfieldsArr.length;
      this.drop.lat = lat;
      this.drop.lng = lng;
      this.showDropMarker = true;
      // this.getDirection();
      // this.getDistanceMatrix(this.dropAddress, {lat: lat, lng: lng});
      this.calculateDistanceMatrix({ lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, { lat: this.drop.lat, lng: this.drop.lng }, data);
      // this.getAddressDrop(this.drop, data,address.formatted_address);
      data.address = address.formatted_address;
      this.dropupForm.controls[data.name].patchValue(address.formatted_address);
    }
    if (address.types) {
      if (address.types.includes("airport")) {
        this.isTollRoute = true;
      } else {
        this.isTollRoute = false;
      }
    }
  }

  dropKeyup(drop, value) {
    if (value === "") {
      drop.lat = 0;
      drop.lng = 0;
      drop.address = '';
      drop.distance = 0;
    }
  }

  dropChange(drop, value) {
    if (drop.lat === 0 && drop.lng === 0) {
      this.dropupForm.get(drop.name).patchValue(null);
      this.rideSubmited = true;
    } else if (drop.address !== value) {
      this.dropupForm.get(drop.name).patchValue(null);
      this.rideSubmited = true;
    }
  }

  pickeyUp(pickup, value) {
    if (value === "") {
      pickup.lat = 0;
      pickup.lng = 0;
      pickup.address = '';
      pickup.distance = 0;
    }
  }

  pickupChange(data, value) {
    if (data.lat === 0 && data.lng === 0) {
      this.pickupForm.get(data.name).patchValue(null);
      this.rideSubmited = true;
    } else if (data.address !== value) {
      this.pickupForm.get(data.name).patchValue(null);
      this.rideSubmited = true;
    }
  }

  getDirection() {
    if ((this.pickup.lng !== null) && (this.pickup.lng !== null) && (this.drop.lat !== null) && (this.drop.lng !== null)) {
      this.origin = { lat: this.pickup.lat, lng: this.pickup.lng };
      this.destination = { lat: this.drop.lat, lng: this.drop.lng };
      console.log('dir pick latlng',this.origin);
      console.log('dir drop latlng',this.destination);
      this.showDirections = true;
      this.showPickMarker = false;
      this.showDropMarker = false;
      this.calculateDistance();
    }
    this.showPickMarker = false;
    this.showDropMarker = false;

  }

  calculateDistance() {
    const start = new google.maps.LatLng(this.pickupfieldsArr[0].lat, this.pickupfieldsArr[0].lng);
    const end = new google.maps.LatLng(this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng);
    this.distance = google.maps.geometry.spherical.computeDistanceBetween(start, end); // distance in Meter
    return this.distance.toFixed(0);
  }

  getFare(pricePerMiles) {
    var d = this.distance / 1.609; //convert KM into MILES
    var fare = d * pricePerMiles;
    return Math.round(fare);
  }

  getPickupAddress(latLng, data, formatted_address) {
    this.geoCoder.geocode({ 'location': { lat: latLng.lat, lng: latLng.lng } }, (results, status) => {
      if (status === 'OK' && results.length > 0) {
        var isAddSimilar = false;
        results.forEach(addresses => {
          if (addresses.formatted_address === formatted_address && !isAddSimilar) {
            isAddSimilar = true;
            this.pickupForm.controls[data.name].patchValue(addresses.formatted_address);
            data.address = addresses.formatted_address;
            this.pickupAddress = addresses.formatted_address;
            return false;
          }
        });
        if (!isAddSimilar) {
          this.pickupForm.controls[data.name].patchValue(results[0].formatted_address);
          data.address = results[0].formatted_address;
          this.pickupAddress = results[0].formatted_address;
        }
        results[0].address_components.forEach(element => {
          if (element.types.includes("country")) {
            this.currentCountry = element.short_name;
          }
        })
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }

    });
    // this.checkstatusMap();
  }

  getAddressDrop(latLng, data, formatted_address) {
    this.geoCoder.geocode({ 'location': { lat: latLng.lat, lng: latLng.lng } }, (results, status) => {
      if (status === 'OK' && results.length > 0) {
        var isAddSimilar = false;
        results.forEach(addresses => {
          if (addresses.formatted_address === formatted_address && !isAddSimilar) {
            isAddSimilar = true;
            data.address = addresses.formatted_address;
            this.dropupForm.controls[data.name].patchValue(addresses.formatted_address);
            return false;
          }
        });
        if (!isAddSimilar) {
          data.address = results[0].formatted_address;
          this.dropupForm.controls[data.name].patchValue(results[0].formatted_address);
        }
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  bookRideForNow() {
    this.rideSubmited = true;
    this.checkFormFields();
    if (this.pickupForm.valid && this.dropupForm.valid) {
      this.pickupfieldsArr.sort((a, b) => {
        return a.distance - b.distance;
      });
      this.dropupfieldsArr.sort((a, b) => {
        return a.distance - b.distance;
      });
      this.drawPath(this.pickupfieldsArr[0], this.dropupfieldsArr[this.dropupfieldsArr.length - 1], this.originMarkerIcon);
      this.commonService.preLaunchAlert(this.constant.MSGS.PRELAUNCH_RIDE);
      this.bookingFor = 'Now';
      var date = new Date();
      var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
      var offset = date.getTimezoneOffset() / 60;
      this.bookingTimeInH = date.getHours();
    }

    // this.getVehicle();
  }

  checkFormFields() {
    for (let i = 0; i < this.pickupfieldsArr.length; i++) {
      if (this.pickupfieldsArr[i].lat === 0 && this.pickupfieldsArr[i].lng === 0) {
        if (this.pickupfieldsArr.length === 1) {
          this.pickupfieldsArr[i].address = '';
          this.pickupForm.get(this.pickupfieldsArr[i].name).patchValue(null);
        } else {
          this.removePickupField(i);
        }
      }
    }
    for (let i = 0; i < this.dropupfieldsArr.length; i++) {
      if (this.dropupfieldsArr[i].lat === 0 && this.dropupfieldsArr[i].lng === 0) {
        if (this.dropupfieldsArr.length === 1) {
          this.dropupfieldsArr[i].address = '';
          this.dropupForm.get(this.dropupfieldsArr[i].name).patchValue(null);
        } else {
          this.removeDropupField(i);
        }
      }
    }
  }

  getVehicle() {
    this.rideSubmited = true;
    this.activeBtn = 'Now';
    this.getUser();
    if (this.pickupForm.valid && this.dropupForm.valid && this.directionStatus === 'OK') {
      this.originPathOptimization();
      this.destinationPathOptimization();
      this.ngxService.start();
      this.calculateDistanceMatrix({ lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, { lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng }, null);
      // this.getDistanceMatrix(this.dropAddress, { lat: this.dropupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng });
      var param = {
        "pageNo": 1,
        "latitude": this.pickupfieldsArr[0].lat,
        "longitude": this.pickupfieldsArr[0].lng
      }
      this.http.httpPostWithHeaderUser('getVehicleCategoryInGeofence', param).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.ngxService.start();
          // this.vehicleCategoryData = res.data.vehicleCategoryData;
          // this.selectedVehicleData = res.data.vehicleCategoryData[0];
          this.driverNoteForm.get('name').patchValue(this.user.firstName);
          this.driverNoteForm.get('phoneNo').patchValue(this.user.phoneNo);
          this.continue(1);
          var arr = res.data.vehicleCategoryData;
          this.vehicleCategoryData = [];
          arr.forEach(element => {
            var totalFare: any;
            var d = this.distance / 1.609; //convert KM into MILES
            var priceA = d * element.pricePerMile;
            var pricePerMinute = element.pricePerHour / 60;
            let totalMinutesForRide = this.duration;
            var priceB = pricePerMinute * totalMinutesForRide;
            var basicFare = priceA * priceB + element.baseFare;
            var tax = (basicFare * 12) / 100;
            var booKingDriverAmount;
            if (this.bookingTimeInH > 9 && this.bookingTimeInH < 7) {
              var specialCharge = (basicFare * 11) / 100;
              totalFare = basicFare + tax + specialCharge;
            } else {
              totalFare = basicFare + tax;
            }
            if (this.isTollRoute) {
              totalFare = totalFare + 4;
            }
            booKingDriverAmount = (totalFare * 75) / 100;
            this.vehicleCategoryData.push($.extend({}, {
              airpotFess: element.airpotFess,
              booKingAmount: totalFare.toFixed(2),
              booKingDriverAmount: booKingDriverAmount,
              baseFare: element.baseFare,
              createdAt: element.createdAt,
              geofenceId: element.geofenceId,
              id: element.id,
              isBlocked: element.isBlocked,
              isDeleted: element.isDeleted,
              isDelivery: element.isDelivery,
              isRide: element.isRide,
              lateNightCharge: element.lateNightCharge,
              packageSizeId: element.packageSizeId,
              pricePerHour: element.pricePerHour,
              pricePerMile: element.pricePerMile,
              rideCapacity: element.rideCapacity,
              updatedAt: element.updatedAt,
              vehicleCategory: element.vehicleCategory,
              vehicleImage: element.vehicleImage,
              weatherEffectFees: element.weatherEffectFees,
              zoneId: element.zoneId,
              __v: element.__v,
              _id: element._id,
            }))
          });
          this.selectedVehicleData = this.vehicleCategoryData[0];
          this.ngxService.stop();
          this.pickupfieldsArr.sort((a, b) => {
            return a.distance - b.distance;
          });
          this.dropupfieldsArr.sort((a, b) => {
            return a.distance - b.distance;
          });
          this.drawPath(this.pickupfieldsArr[0], this.dropupfieldsArr[this.dropupfieldsArr.length - 1], this.originMarkerIcon);
        }
      });
    }
  }

  selectVehicle(vehicle, index) {
    this.selectedVehicleData = vehicle;
    this.selectedItem = index;
  }

  CreateRideBooking(poupType) {
    this.preLaunchAlert();
  }


  getDriverRatingsReview() {
    this.http.httpPostWithHeaderUser('getDriverRatingsReview', { driverId: this.bookingResponseData.driverId.id }).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.driverReviewData = res.data.driverData;
      }
    });
  }

  driverNotFoundAlert(message) {
    Swal.fire({
      title: message,
      // text: "You won't be able to revert this!",
      icon: 'info',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        this.continue(0);
      }
    })
  }

  getDriverLocation(lat, lng) {
    this.geoCoder.geocode({ 'location': { lat: lat, lng: lng } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 13;
          this.driverAddress = results[0].formatted_address;
          this.driverLatLng = { lat: lat, lng: lng };
          // this.getDistanceMatrix(this.driverAddress, this.driverLatLng);
          // this.calculateDistanceMatrix();
          // this.getDirection();
          // this.displayWaypoints = false;
        } else {
          // window.alert('No results found');
        }
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  calculateDistanceMatrix(pickup, drop, data) {
    let directionsService = new google.maps.DirectionsService();
    var request = {
      origin: pickup,
      destination: drop,
      optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.DRIVING,
    };
    directionsService.route(request, (response, status) => {
      this.directionStatus = status;
      if (status == google.maps.DirectionsStatus.OK) {
        this.distance = response?.routes[0]?.legs[0]?.distance.value / 1000; //convert m into km
        this.duration = response?.routes[0]?.legs[0]?.duration.value / 60; //convert seconds into minutes
        if (data !== null) {
          data.distance = this.distance;
        }
      } else {
        // this.commonService.OpenNotificationModal('error', 'top-end', 'No path found.');
        // if (data !== null) {
        //   this.dropupForm.get(data.name).patchValue('');
        // }
      }
      try {

      } catch (error) {
        console.log(error);
      }
    });
  }

  continue(popupType) {
    this.ngxService.start();
    if (popupType === 0) {
      this.rideSubmited = false;
      this.DriverNoteFormSubmitted = false;
      this.rideSubmited = false;
      this.isDateTimeSubmit = false;
      this.driverNoteForm.reset();
      // this.bookingForm.reset();
      this.requestForLaterForm.reset();
      this.pickupFieldsCount = 1;
      this.dropupFieldsCount = 1;
      this.pickupfieldsArr = [{ name: 'pickup1', lat: 0, lng: 0, address: '', distance: 0 }];
      this.dropupfieldsArr = [{ name: 'dropup1', lat: 0, lng: 0, address: '', distance: 0 }];
      this.dropupForm.get('dropup1').patchValue(null);
      this.getCurrentLocation();
      this.showDirections = false;
      this.showPickMarker = true;
      this.showDropMarker = false;
      this.isDriveingStart = false;
      this.nextPopup = popupType;
      this.ngxService.stop();
      this.drop.lat = 0;
      this.drop.lng = 0;
      this.rating = 0;
      this.selectedReviewData = '';
    }
    this.nextPopup = popupType;
    this.ngxService.stop();
  }

  bookRideForLater() {
    this.isDateTimeSubmit = true
    if (this.requestForLaterForm.valid) {
      var date = this.requestForLaterForm.value.dateTime;
      var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
      var offset = date.getTimezoneOffset() / 60;
      this.bookingTimeInH = date.getHours();
      $('#exampleModall').modal('hide');
      this.bookingFor = 'Later';
      this.getVehicle();
    }
  }

  openDateTimeModal() {
    this.rideSubmited = true;
    this.checkFormFields();
    if (this.pickupForm.valid && this.dropupForm.valid) {
      // $('#exampleModall').modal('show');
      this.pickupfieldsArr.sort((a, b) => {
        return a.distance - b.distance;
      });
      this.dropupfieldsArr.sort((a, b) => {
        return a.distance - b.distance;
      });
      this.drawPath(this.pickupfieldsArr[0], this.dropupfieldsArr[this.dropupfieldsArr.length - 1], this.originMarkerIcon);
      this.commonService.preLaunchAlert(this.constant.MSGS.PRELAUNCH_RIDE);
      this.activeBtn = 'Later';
    }
  }


  goToHome() {
    this.commonService.origin = { lat: 0, lng: 0, address: '' };
    this.commonService.destination = { lat: 0, lng: 0, address: '' };
    this.router.navigate(["/uhomepage"]);
  }
  goToprofilesetup() {
    if(localStorage.isThisUpdatedUser !== undefined) {
      localStorage.removeItem('isThisUpdatedUser');
    }
    // this.commonService.isThisUpdatedUser = false;
    this.router.navigate(["/profilesetup"]);
  }
  goTorequestaccept() {
    this.router.navigate(["/requestaccept"]);
  }
  goToriderdeliverybooking() {
    this.router.navigate(["/riderdeliverybooking"]);
  }


  //for rating
  onClick(rating: number) {
    // this.snackBar.open('You rated ' + rating + ' / ' + this.starCount, '', {
    //   duration: this.snackBarDuration
    // });
    this.ratingUpdated.emit(rating);
    this.rating = rating;
    if (this.rating > 0 && this.rating < 3) {
      this.review.setValidators(Validators.required);
      this.review.updateValueAndValidity();
    } else {
      this.review.clearValidators();
      this.review.updateValueAndValidity();
    }
    return false;
  }

  showIcon(index: number) {
    if (this.rating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }

  goToContactDetail() {
    this.isContactDetailHide = this.isContactDetailHide ? false : true;
    if (!this.isContactDetailHide) {
      this.vps.scrollToAnchor("driverInfo");
      // document.getElementById("driverInfo").scrollIntoView();
    }
  }

  addPickupField(pickup) {
    if (pickup.lat !== 0 && pickup.lng !== 0) {
      this.pickupFieldsCount++;
      if (!('pickup' + this.pickupFieldsCount in this.pickupFormObj)) {
        this.addFieldPick(pickup);
      } else {
        this.pickupFieldsCount++;
        this.addFieldPick(pickup);
      }
    } else {
      this.rideSubmited = true;
      pickup.address = '';
      this.pickupForm.get(pickup.name).patchValue(null);
    }

  }

  addFieldPick(pickup) {
    if (!('pickup' + this.pickupFieldsCount in this.pickupFormObj)) {
      this.pickupFormObj['pickup' + this.pickupFieldsCount] = new FormControl('', Validators.required);
      this.pickupForm = this.fb.group(this.pickupFormObj);
      this.pickupfieldsArr.push({ name: 'pickup' + this.pickupFieldsCount, lat: 0, lng: 0, address: '', distance: 0, country: this.currentCountry });
    } else {
      this.addPickupField(pickup);
    }
  }

  removePickupField(index) {
    this.pickupfieldsArr.splice(index, 1);
    Object.keys(this.pickupFormObj).forEach(element => {
      let strArray = this.pickupfieldsArr.map(element => element.name)
      if (!(strArray.includes(element))) {
        delete this.pickupFormObj[element];
      }
    });
    this.pickupForm = this.fb.group(this.pickupFormObj);
  }

  addDropupField(drop) {
    if (drop.lat !== 0 && drop.lng !== 0) {
      this.dropupFieldsCount++;
      if (!('dropup' + this.dropupFieldsCount in this.dropupFormObj)) {
        this.addFieldDrop(drop);
      } else {
        this.dropupFieldsCount++;
        this.addFieldDrop(drop);
      }
    } else {
      this.rideSubmited = true;
      drop.address = '';
      this.dropupForm.get(drop.name).patchValue(null);
    }
  }

  addFieldDrop(drop) {
    if (!('dropup' + this.dropupFieldsCount in this.dropupFormObj)) {
      this.dropupFormObj['dropup' + this.dropupFieldsCount] = new FormControl('', Validators.required);
      this.dropupForm = this.fb.group(this.dropupFormObj);
      this.dropupfieldsArr.push({ name: 'dropup' + this.dropupFieldsCount, lat: 0, lng: 0, address: '', distance: 0, country: this.currentCountry });
    } else {
      this.addDropupField(drop);
    }
  }

  removeDropupField(index) {
    // this.dropupFieldsCount--;
    // this.dropupfieldsArr.splice(index, 1);

    this.dropupfieldsArr.splice(index, 1);
    Object.keys(this.dropupFormObj).forEach(element => {
      let strArray = this.dropupfieldsArr.map(element => element.name)
      if (!(strArray.includes(element))) {
        delete this.dropupFormObj[element];
      }
    });
    this.dropupForm = this.fb.group(this.dropupFormObj);
  }

  drawPath(origin, destination, originIcon) {
    var wayPontsMarkerArr = [];
    this.waypoints = [];
    this.origin = '';
    this.destination = '';
    if (this.pickupfieldsArr.length > 1) {
      for (let i = 0; i < this.pickupfieldsArr.length; i++) {
        if (i !== 0) {
          this.waypoints.push({
            location: { lat: this.pickupfieldsArr[i].lat, lng: this.pickupfieldsArr[i].lng },
            stopover: false
          });
          wayPontsMarkerArr.push({ icon: this['marker' + [i + 1]] });
        } else {
          // wayPontsMarkerArr.push({ icon: 'http://i.imgur.com/7teZKif.png' });
        }
      }
      this.markerOptions = {
        origin: {
          icon: this.marker1
        },
        destination: {
          icon: this.destinationMarkerIcon
        },
        waypoints: wayPontsMarkerArr
      }
    } else if (this.dropupfieldsArr.length > 1) {
      for (let i = 0; i < this.dropupfieldsArr.length; i++) {
        if (i !== this.dropupfieldsArr.length - 1) {
          this.waypoints.push({
            location: { lat: this.dropupfieldsArr[i].lat, lng: this.dropupfieldsArr[i].lng },
            stopover: false
          });
          wayPontsMarkerArr.push({ icon: this['marker' + [i + 2]] });
        } else {
          // wayPontsMarkerArr.push({ icon: 'http://i.imgur.com/7teZKif.png' });
        }
      }
      this.markerOptions = {
        origin: {
          icon: this.marker1
        },
        destination: {
          icon: this.destinationMarkerIcon
        },
        waypoints: wayPontsMarkerArr
      }
    }
    this.showDirections = true;
    this.showPickMarker = false;
    this.showDropMarker = false;
    this.origin = { lat: origin.lat, lng: origin.lng };
    this.destination = { lat: destination.lat, lng: destination.lng };
  }

  originPathOptimization() {
    this.pickupfieldsArr.forEach(element => {
      let destination = { lat: element.lat, lng: element.lng };
      this.calculateDistanceForPathOpt(this.current, destination, element);
    });
  }

  destinationPathOptimization() {
    this.dropupfieldsArr.forEach(element => {
      let destination = { lat: element.lat, lng: element.lng };
      this.calculateDistanceForPathOpt(this.pickup, destination, element);
    });
  }

  calculateDistanceForPathOpt(pickup, drop, data) {
    let directionsService = new google.maps.DirectionsService();
    var request = {
      origin: pickup,
      destination: drop,
      optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.DRIVING,
    };
    directionsService.route(request, (response, status) => {
      if (status == google.maps.DirectionsStatus.OK) {
        data.distance = response?.routes[0]?.legs[0]?.distance.value;
      }
    });
  }

  preLaunchAlert() {
    Swal.fire({
      title: 'This is the Pre Launch website... we will get back to you soon!',
      // text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Okay'
    }).then((result) => {
      if (result.value) {
        this.continue(0);
      }
    })
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
    clearInterval(this.intervalSubscription);
    clearInterval(this.calculateSubscription);
  }
}

import { Component, OnInit, NgZone, OnDestroy } from "@angular/core";
import { SocketioService } from "../services/sockets/socketio.service";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpsService } from "../services/https/https.service";
import { FormBuilder } from "@angular/forms";
import { MapsAPILoader } from "@agm/core";
import { CommonService } from "../services/common/common.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LocationStrategy, ViewportScroller } from "@angular/common";
import { environment } from "src/environments/environment";
import { takeUntil } from "rxjs/operators";
import { BehaviorSubject, Subject } from "rxjs";
declare var google: any;
import { Event } from "../core/models/event";
import { Action } from "../core/models/action";
import { fConstant } from "../core/constant";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-tracking",
  templateUrl: "./tracking.component.html",
  styleUrls: ["./tracking.component.scss"],
})
export class TrackingFComponent implements OnInit, OnDestroy {
  lat: number = 51.678418;
  lng: number = 7.809007;
  pickup = {
    lat: 0,
    lng: 0,
  };
  pickupAddress = "";
  drop = {
    lat: 0,
    lng: 0,
  };
  dropAddress = "";
  geoCoder: any;
  zoom: number = 12;
  nextPopup = 6;
  origin;
  destination;
  showDirections: boolean = false;
  showPickMarker: boolean = false;
  public renderOptions = {
    suppressMarkers: true,
  };
  originMarkerIcon: string = "assets/uimages/originmarker.png";
  destinationMarkerIcon: string = "assets/uimages/destination.png";
  markerOptions: any;
  baseUrl: string;
  distanceInKM: any;
  duration: any;
  driverLatLng: { lat: any; lng: any };
  notifier = new Subject();
  bookingResponseData: any;
  bookingId: any;
  bookingData: any;
  waypoints: any[];
  pickupfieldsArr: any = [{ name: "pickup1", lat: 0, lng: 0, address: "", distance: 0 }];
  dropupfieldsArr: any = [{ name: "dropup1", lat: 0, lng: 0, address: "", distance: 0 }];
  showDropMarker: boolean;
  isDriveingStart: boolean;
  calculated: boolean = true;
  calculateSubscription: any;
  directionStatus: any;
  driverMessage: string;
  distanceDuration: any = "1 mins";
  driverDistance: number;
  isDriverLatLngUpdated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isDriverLatLngUpdated = this.isDriverLatLngUpdated$.asObservable();
  timerStart: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  cancelCounter: number;
  intervalSubscription: any;
  distance: number;
  marker1: string = "assets/images/marker1.png";
  marker2: string = "assets/images/marker2.png";
  marker3: string = "assets/images/marker3.png";
  marker4: string = "assets/images/marker4.png";
  itemDropAddress: { lat: any; lng: any; address: any; distance: number };
  itemDropDetails: {
    deliveryTo: any;
    itemDropup: { lat: any; lng: any; address: any; distance: number };
    streetAdd: any;
    suiteAptOffice: any;
    city: any;
    province: any;
    zipCode: any;
    additionalInfo: any;
    phoneNo: any;
    countryCode: any;
  };
  allPackagesDetail: any = [];
  map: any;

  constructor(
    private router: Router,
    private http: HttpsService,
    private fb: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private commonService: CommonService,
    private ngxService: NgxUiLoaderService,
    private socketService: SocketioService,
    private snackBar: MatSnackBar,
    private location: LocationStrategy,
    private vps: ViewportScroller,
    private route: ActivatedRoute,
    private constant: fConstant,
    public translate: TranslateService
  ) {
    this.bookingId = this.route.snapshot.paramMap.get("bookingId");
    this.baseUrl = environment.imageUrl;
  }

  ngOnInit(): void {
    this.markerOptions = {
      origin: {
        icon: this.iconObject(90),
      },
      destination: {
        icon: this.destinationMarkerIcon,
      },
    };
    this.timerStart.pipe(takeUntil(this.notifier)).subscribe((val: boolean) => {
      if (val) {
        this.cancelCounter = 10;
        if (this.intervalSubscription) {
          clearInterval(this.intervalSubscription);
          this.cancelCounter = 10;
        }
        this.intervalSubscription = setInterval(() => {
          if (this.cancelCounter <= 0) {
            clearInterval(this.intervalSubscription);
          }
          this.cancelCounter--;
        }, 1000);
      } else {
        this.cancelCounter = 0;
      }
    });
    this.preventBackButton();
    this.getCurrentLocation();
    this.getShareYourRide();
  }

  // goToBookingStatus(response) {
  //   this.showDirections = true;
  //   this.showPickMarker = false;
  //   this.origin = { lat: response.latitude, lng: response.longitude };
  //   this.driverLatLng = { lat: response.latitude, lng: response.longitude };
  //   this.destination = { lat: this.drop.lat, lng: this.drop.lng };
  //   this.drawPath(this.driverLatLng, this.dropupfieldsArr[this.dropupfieldsArr.length - 1], this.iconObject(response.bearing))
  // }

  onMapReady(map: any) {
    this.map = map;
    if (map) {
      map.setOptions({
        streetViewControl: false,
      });
    }
  }

  listenSocketEvent() {
    this.socketService
      .onEvent(Event.sendNotificationToUser)
      .pipe(takeUntil(this.notifier))
      .subscribe((response: any) => {
        console.log(response, "skcnscnkcnsn");
        if (response.eventType != 32) {
          if (response.data.bookingType === this.constant.BOOKING_TYPE.RIDE) {
            this.goToRideBookingStatus(response.data);
          } else if (response.data.bookingType === this.constant.BOOKING_TYPE.DELIVERY) {
            this.bookingResponseData = response.data;
            this.goToDeliveryBookingStatus(response.data);
          }
        }
      });
  }

  listenRideLatLngEvent() {
    this.socketService
      .onEvent(Event.bookingTracking)
      .pipe(takeUntil(this.notifier))
      .subscribe((response: any) => {
        // console.log(response);
        if (response.status == 200 && this.bookingResponseData.bookingStatus !== this.constant.BOOKING_STATUS.COMPLETED) {
          this.isDriveingStart = true;
          this.showDirections = true;
          this.showPickMarker = false;
          this.distanceDuration = response.distanceDuration;
          // this.markerOptions = {
          //   origin: {
          //     icon: this.iconObject(response.bearing)
          //   },
          //   destination: {
          //     icon: this.destinationMarkerIcon
          //   },
          // }
          if (this.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED) {
            var driverLatLng = { lat: response.latitude, lng: response.longitude };
            this.destination = { lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng };
            this.origin = { lat: response.latitude, lng: response.longitude };
            this.waypoints = [];
            this.drawPath(driverLatLng, this.pickupfieldsArr[this.pickupfieldsArr.length - 1], this.iconObject(response.bearing));
            if (this.calculated === false) {
              this.calculated = true;
              this.matrix(driverLatLng, this.destination, null);
            }
          }
          if (this.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.PARTIAL) {
            // this.destination = { lat: this.drop.lat, lng: this.drop.lng };
            this.isDriverLatLngUpdated$.next(true);
            var driverLatLng = { lat: response.latitude, lng: response.longitude };
            this.drawPath(driverLatLng, this.dropupfieldsArr[this.dropupfieldsArr.length - 1], this.iconObject(response.bearing));
            clearInterval(this.intervalSubscription);
          }
          if (this.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.TRANSIT) {
            var driverLatLng = { lat: response.latitude, lng: response.longitude };
            this.drawPath(driverLatLng, this.dropupfieldsArr[this.dropupfieldsArr.length - 1], this.iconObject(response.bearing));
            clearInterval(this.intervalSubscription);
          }
          // this.getDirection();
        } else {
          clearInterval(this.intervalSubscription);
        }
      });
  }

  drawPath(origin, destination, originIcon) {
    var wayPontsMarkerArr = [];
    this.waypoints = [];
    if (this.pickupfieldsArr.length > 1) {
      for (let i = 0; i < this.pickupfieldsArr.length; i++) {
        if (i !== 0) {
          this.waypoints.push({
            location: { lat: this.pickupfieldsArr[i].lat, lng: this.pickupfieldsArr[i].lng },
            stopover: false,
          });
          wayPontsMarkerArr.push({ icon: this["marker" + [i + 1]] });
        } else {
          // wayPontsMarkerArr.push({ icon: 'http://i.imgur.com/7teZKif.png' });
        }
      }
      this.markerOptions = {
        origin: {
          icon: originIcon,
        },
        destination: {
          icon: this.destinationMarkerIcon,
        },
        waypoints: wayPontsMarkerArr,
      };
    } else if (this.dropupfieldsArr.length > 1) {
      for (let i = 0; i < this.dropupfieldsArr.length; i++) {
        if (i !== this.dropupfieldsArr.length - 1) {
          this.waypoints.push({
            location: { lat: this.dropupfieldsArr[i].lat, lng: this.dropupfieldsArr[i].lng },
            stopover: false,
          });
          wayPontsMarkerArr.push({ icon: this["marker" + [i + 2]] });
        } else {
          // wayPontsMarkerArr.push({ icon: 'http://i.imgur.com/7teZKif.png' });
        }
      }
      this.markerOptions = {
        origin: {
          icon: originIcon,
        },
        destination: {
          icon: this.destinationMarkerIcon,
        },
        waypoints: wayPontsMarkerArr,
      };
    }
    this.showDirections = true;
    this.showPickMarker = false;
    this.showDropMarker = false;
    this.origin = { lat: origin.lat, lng: origin.lng };
    this.destination = { lat: destination.lat, lng: destination.lng };
  }

  getShareYourRide() {
    this.http.httpPostUser("getShareYourRide", { bookingId: this.bookingId }).subscribe((res: any) => {
      if (res.statusCode == 200) {
        localStorage.setItem("userId", res.data.bookingData.userId.id);
        this.socketService.initSocket();
        this.socketService.emitAction(Action.bookingTracking, { bookingId: this.bookingId });
        this.listenSocketEvent();
        this.bookingData = res.data.bookingData;
        this.bookingResponseData = res.data.bookingData;
        if (res.data.bookingData.bookingType === this.constant.BOOKING_TYPE.RIDE) {
          this.pickupfieldsArr = [];
          this.dropupfieldsArr = [];
          // this.duration = '';
          this.bookingResponseData.subBookingData.forEach((element) => {
            if (element.isPickUp) {
              this.pickupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
            } else {
              this.dropupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
            }
          });
          this.drawPath(this.pickupfieldsArr[0], this.dropupfieldsArr[this.dropupfieldsArr.length - 1], this.iconObject(90));
          this.goToRideBookingStatus(res.data.bookingData);
        } else if (res.data.bookingData.bookingType === this.constant.BOOKING_TYPE.DELIVERY) {
          this.pickupfieldsArr = [];
          this.dropupfieldsArr = [];
          // this.duration = '';
          this.bookingResponseData.subBookingData.forEach((element) => {
            if (element.isPickUp) {
              this.pickupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
            } else {
              this.dropupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
            }
          });
          this.goToDeliveryBookingStatus(res.data.bookingData);
        }
        this.listenRideLatLngEvent();
      }
    });
  }

  goToRideBookingStatus(response) {
    if (response.bookingType === this.constant.BOOKING_TYPE.RIDE) {
      if (response.bookingStatus === this.constant.BOOKING_STATUS.PENDING && !response.isSheduledBooking) {
        this.bookingId = response._id;
        this.nextPopup = 3;
        if (response.subBookingData[response.subBookingData.length - 1].bookingStatus === this.constant.BOOKING_STATUS.PENDING) {
          this.driverNotFoundAlert("Oops!.. No driver available nearby, please try again later.", "", 4);
        }
        var statusArr = response.subBookingData.map((val) => val.bookingStatus);
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED) {
        // this.duration = 0;
        this.commonService.bookingResponseData = response;

        this.bookingId = response._id;
        this.isDriveingStart = true;
        this.showDirections = true;
        this.bookingResponseData = response;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        // this.duration = '';
        this.bookingResponseData.subBookingData.forEach((element) => {
          if (element.isPickUp) {
            this.pickupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          } else {
            this.dropupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          }
        });
        this.matrix(
          { lat: response.driverId.latitude, lng: response.driverId.longitude },
          { lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng },
          null
        ).then((value: any) => {
          this.listenRideLatLngEvent();
          this.timerStart.next(true);
          for (let i = 0; i < response.subBookingData.length; i++) {
            if (
              response.subBookingData[i].isPickUp &&
              response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED &&
              this.pickupfieldsArr.length > 1 &&
              this.dropupfieldsArr.length === 1
            ) {
              if (value.duration.toFixed() !== "0") {
                this.driverMessage = "Your Driver is ariving in " + value.duration.toFixed() + " min.";
              } else {
                this.driverMessage = "Your driver arrived to pick up rider in location " + [i + 1] + ".";
              }
              this.duration = value.duration;
              this.continue(4);
              return;
            } else if (
              response.subBookingData[i].isPickUp &&
              response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED &&
              this.pickupfieldsArr.length === 1 &&
              this.dropupfieldsArr.length > 1
            ) {
              if (value.duration.toFixed() !== "0") {
                this.driverMessage = "Your driver is arriving " + value.duration.toFixed() + " min.";
              } else {
                this.driverMessage = "Your driver arrived to pick you all up.";
              }
              this.duration = value.duration;
              this.continue(4);
              return;
            } else if (
              response.subBookingData[i].isPickUp &&
              response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED &&
              this.pickupfieldsArr.length === 1 &&
              this.dropupfieldsArr.length === 1
            ) {
              if (value.duration.toFixed() !== "0") {
                this.driverMessage = "Your Driver is arriving in " + value.duration.toFixed() + " min.";
              } else {
                this.driverMessage = "Your driver arrived to pick you up.";
              }
              this.duration = value.duration;
              this.continue(4);
              return;
            }
          }
        });
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.TRANSIT) {
        this.commonService.bookingResponseData = response;

        this.bookingId = response._id;
        this.isDriveingStart = true;
        this.showDirections = true;
        this.bookingResponseData = response;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        // this.duration = '';
        this.bookingResponseData.subBookingData.forEach((element) => {
          if (element.isPickUp) {
            this.pickupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          } else {
            this.dropupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          }
        });
        this.matrix(
          { lat: response.driverId.latitude, lng: response.driverId.longitude },
          { lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng },
          null
        ).then((value: any) => {
          if (response.subBookingData[0].bookingStatus === this.constant.BOOKING_STATUS.ARRIVED_AT_PICKED) {
            this.driverMessage = "You driver arrive to pick you up.";
          }
          if (response.subBookingData[0].bookingStatus === this.constant.BOOKING_STATUS.PICKED) {
            this.driverMessage = "You started your ride.";
          }
          if (
            response.subBookingData[1].bookingStatus === this.constant.BOOKING_STATUS.ARRIVED_AT_DROPED ||
            response.subBookingData[1].bookingStatus === this.constant.BOOKING_STATUS.DROPED
          ) {
            this.driverMessage = "You arrived at your destination.";
          }
        });
        this.continue(4);
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.PARTIAL) {
        this.commonService.bookingResponseData = response;

        this.isDriveingStart = true;
        this.showDirections = true;
        this.bookingResponseData = response;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        this.bookingResponseData.subBookingData.forEach((element) => {
          if (element.isPickUp) {
            this.pickupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          } else {
            this.dropupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          }
        });
        this.listenRideLatLngEvent();
        this.matrix(
          { lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng },
          { lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng },
          null
        );
        this.continue(4);
        this.setRideStatus(response);
        this.isDriverLatLngUpdated.subscribe((value: boolean) => {
          if (value) {
            this.setRideStatus(response);
          }
        });
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.STARTED) {
        this.commonService.bookingResponseData = response;
        this.isDriveingStart = true;
        // this.markerOptions = {
        //   origin: {
        //     icon: this.iconObject(0)
        //   },
        //   destination: {
        //     icon: this.destinationMarkerIcon
        //   },
        // }
        // this.origin = { lat: response.pickUplatitude, lng: response.pickUplongitude };
        // this.destination = { lat: response.dropUplatitude, lng: response.dropUplongitude };
        this.showDirections = true;
        this.bookingResponseData = response;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        this.bookingResponseData.subBookingData.forEach((element) => {
          if (element.isPickUp) {
            this.pickupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          } else {
            this.dropupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          }
        });
        this.listenRideLatLngEvent();
        this.matrix(
          { lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng },
          { lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng },
          null
        );
        this.continue(6);
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.COMPLETED) {
        this.commonService.bookingResponseData = response;
        this.bookingId = response._id;
        this.showDirections = false;
        this.showPickMarker = false;
        // this.pickup.lat = response.pickUplatitude;
        // this.pickup.lng = response.pickUplongitude;
        // this.drop.lat = response.dropUplatitude;
        // this.drop.lng = response.dropUplongitude;
        this.bookingResponseData = response;
        // this.getDriverRatingsReview();
        this.ngxService.stop();
        this.continue(8);
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.CANCELED) {
        this.commonService.bookingResponseData = response;
        if (response.isCanceledByDriver) {
          // this.driverNotFoundAlert('Your delivery has been cancelled by driver.','',4);
        } else if (response.isCanceledByCron) {
          // this.driverNotFoundAlert('Oops!.. No driver available nearby, please try again later.','',4);
        }
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.PENDING) {
        // this.driverNotFoundAlert('Oops!.. No driver available nearby, please try again later.','',4);
      }
    } else {
      this.commonService.bookingResponseData = response;
      this.router.navigate(["/riderdeliverybooking"]);
    }
  }

  setRideStatus(response) {
    for (let i = 0; i < response.subBookingData.length; i++) {
      if (this.pickupfieldsArr.length > 1 && this.dropupfieldsArr.length === 1) {
        if (response.subBookingData[i].isPickUp && response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ARRIVED_AT_PICKED) {
          this.driverMessage = "Your driver arrived to pick up rider in location " + this.constant.ALPHAB_STATUS[i + 1] + ".";
        } else if (
          response.subBookingData[i].isPickUp &&
          response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.PICKED &&
          response.subBookingData.length != i + 2
        ) {
          this.driverMessage = "Your Driver is arriving in " + this.distanceDuration + ".";
          // this.driverMessage = 'Driver will  arrive in ' + this.distanceDuration + ' at location ' + [i + 2] + '.';
        } else if (
          response.subBookingData[response.subBookingData.length - 2].isPickUp &&
          response.subBookingData[response.subBookingData.length - 2].bookingStatus === this.constant.BOOKING_STATUS.PICKED
        ) {
          this.driverMessage = "Reach your destination in " + this.distanceDuration + ".";
          // this.driverMessage = 'Your ride has started.'
        }
        if (
          !response.subBookingData[response.subBookingData.length - 1].isPickUp &&
          (response.subBookingData[response.subBookingData.length - 1].bookingStatus === this.constant.BOOKING_STATUS.DROPED ||
            response.subBookingData[response.subBookingData.length - 1].bookingStatus === this.constant.BOOKING_STATUS.ARRIVED_AT_DROPED)
        ) {
          this.driverMessage = "Arrived at your destination.";
        }
      } else if (this.pickupfieldsArr.length === 1 && this.dropupfieldsArr.length > 1) {
        if (
          i === 0 &&
          response.subBookingData[i].isPickUp &&
          response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED &&
          response.subBookingData[i].bookingStatus !== this.constant.BOOKING_STATUS.ARRIVED_AT_PICKED
        ) {
          this.driverMessage = "Your Driver is arriving in " + this.duration + " min.";
        }
        if (
          i === 0 &&
          response.subBookingData[i].isPickUp &&
          response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ARRIVED_AT_PICKED &&
          response.subBookingData[i].bookingStatus !== this.constant.BOOKING_STATUS.PICKED
        ) {
          this.driverMessage = "Your driver arrived to pick you all up.";
        }
        if (
          i !== 1 &&
          response.subBookingData[i].isPickUp &&
          response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.PICKED &&
          response.subBookingData[i].bookingStatus !== this.constant.BOOKING_STATUS.ARRIVED_AT_DROPED &&
          response.subBookingData[i].bookingStatus !== this.constant.BOOKING_STATUS.DROPED
        ) {
          this.driverMessage = "Your rides have started.";
        }
        if (
          response.subBookingData[i].bookingStatus !== this.constant.BOOKING_STATUS.PICKED &&
          !response.subBookingData[i].isPickUp &&
          response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ARRIVED_AT_DROPED &&
          i !== this.bookingResponseData.subBookingData.length - 1
        ) {
          this.driverMessage = "Arrived at drop-off location " + this.constant.ALPHAB_STATUS[i] + ".";
        }
        if (response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.DROPED && i != response.subBookingData.length - 1) {
          this.driverMessage = "Reach drop off location " + this.constant.ALPHAB_STATUS[i + 1] + " in " + this.distanceDuration + ".";
        }
        if (
          i === this.bookingResponseData.subBookingData.length - 1 &&
          !response.subBookingData[i].isPickUp &&
          response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.DROPED
        ) {
          this.driverMessage = "Arrived at final destination.";
        }
      }
    }
  }
  //delievry module start

  goToDeliveryBookingStatus(response) {
    if (response.bookingType === this.constant.BOOKING_TYPE.DELIVERY) {
      // this.router.navigate(['/riderdeliverybooking']);
      if (response.bookingStatus === this.constant.BOOKING_STATUS.PENDING && !response.isSheduledBooking) {
        this.bookingId = response._id;
        for (let i = 0; i < response.subBookingData.length; i++) {
          if (response.subBookingData[0].isPickUp && response.subBookingData[0].bookingStatus === this.constant.BOOKING_STATUS.PENDING) {
            this.commonService.OpenNotificationModal("success", "top-end", "Your driver has canceled delivery we are searching new driver for you");
            this.nextPopup = 3;
          }
        }
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED) {
        this.commonService.bookingResponseData = response;
        this.bookingId = response._id;
        this.isDriveingStart = true;
        this.showDirections = true;
        this.bookingResponseData = response;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        this.bookingResponseData.subBookingData.forEach((element) => {
          if (element.isPickUp) {
            this.pickupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          } else {
            this.itemDropDetails = {
              deliveryTo: element.addressType,
              itemDropup: { lat: element.latitude, lng: element.longitude, address: element.address, distance: 0 },
              streetAdd: element.streetAddress,
              suiteAptOffice: element.suitAddress,
              city: element.city,
              province: element.province,
              zipCode: element.zipCode,
              additionalInfo: element.note,
              phoneNo: element.phoneNo,
              countryCode: element.countryCode,
            };
            this.allPackagesDetail.push({
              packageType: element.packageName + "," + element.packageTypeId,
              desc: element.itemDescription,
              quantity: element.itemQuantity,
              image: element.itemImage,
              addressDetails: this.itemDropDetails,
            });
          }
        });
        // this.calculateDistanceMatrix({ lat: response.driverId.latitude, lng: response.driverId.longitude }, { lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, null);
        this.matrix(
          { lat: response.driverId.latitude, lng: response.driverId.longitude },
          { lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng },
          null
        ).then((value: any) => {
          this.listenDeliveryLatLngEvent();
          this.timerStart.next(true);
          this.driverMessage = "Your driver is arriving in " + value.duration.toFixed() + " min.";
          this.duration = value.duration;
          this.continue(4);
          return;
        });
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.PARTIAL) {
        this.timerStart.next(false);
        this.cancelCounter = 0;
        this.isDriveingStart = true;
        this.showDirections = true;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        this.allPackagesDetail = [];
        var sortedSubBookingData = [];
        response.subBookingData.forEach((element) => {
          if (element.bookingStatus !== this.constant.BOOKING_STATUS.NOT_AVAILABLE && element.bookingStatus !== this.constant.BOOKING_STATUS.NONE) {
            sortedSubBookingData.push(element);
          }
          if (element.isPickUp) {
            this.pickupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          } else if (
            element.bookingStatus !== this.constant.BOOKING_STATUS.NOT_AVAILABLE &&
            element.bookingStatus !== this.constant.BOOKING_STATUS.NONE
          ) {
            this.itemDropDetails = {
              deliveryTo: element.addressType,
              itemDropup: { lat: element.latitude, lng: element.longitude, address: element.address, distance: 0 },
              streetAdd: element.streetAddress,
              suiteAptOffice: element.suitAddress,
              city: element.city,
              province: element.province,
              zipCode: element.zipCode,
              additionalInfo: element.note,
              phoneNo: element.phoneNo,
              countryCode: element.countryCode,
            };
            this.allPackagesDetail.push({
              packageType: element.packageName + "," + element.packageTypeId,
              desc: element.itemDescription,
              quantity: element.itemQuantity,
              image: element.itemImage,
              addressDetails: this.itemDropDetails,
              bookingStatus: element.bookingStatus,
            });
          }
        });
        response["subBookingData"] = sortedSubBookingData;
        this.commonService.bookingResponseData = response;
        this.bookingResponseData = response;
        // this.listenDeliveryLatLngEvent();
        this.matrix(
          { lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng },
          {
            lat: this.allPackagesDetail[this.allPackagesDetail.length - 1].addressDetails.itemDropup.lat,
            lng: this.allPackagesDetail[this.allPackagesDetail.length - 1].addressDetails.itemDropup.lng,
          },
          null
        ).then((value: any) => {
          this.listenDeliveryLatLngEvent();
          this.duration = value.duration;
          var bookingStatusArr = response.subBookingData.map((res) => res.bookingStatus);
          console.log("solution", bookingStatusArr);
          for (let i = 0; i < response.subBookingData.length; i++) {
            if (
              response.subBookingData.length > 2 &&
              !response.subBookingData[i].isPickUp &&
              response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.CANCELED &&
              i != response.subBookingData.length - 1 &&
              response.subBookingData[i + 1]?.bookingStatus === this.constant.BOOKING_STATUS.AVAILABLE
            ) {
              if (i !== response.subBookingData.length - 1) {
                this.driverMessage =
                  "Your driver has canceled delivery at drop off location " + this.constant.ALPHAB_STATUS[i] + " heading to next location.";
              } else {
                this.driverMessage = "Your driver has canceled delivery at drop off location " + this.constant.ALPHAB_STATUS[i] + ".";
              }
              this.isDriverLatLngUpdated$.next(false);
              this.driverNotFoundAlert("Delivery Canceled", "Reason : " + response.subBookingData[i].cancelReason, 4);
            }
            if (
              response.subBookingData.length > 2 &&
              !response.subBookingData[i].isPickUp &&
              response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.CANCELED &&
              i === response.subBookingData.length - 1 &&
              !bookingStatusArr.includes(this.constant.BOOKING_STATUS.START_RETURN) &&
              !bookingStatusArr.includes(this.constant.BOOKING_STATUS.END_RETURN)
            ) {
              if (i !== response.subBookingData.length - 1) {
                this.driverMessage =
                  "Your driver has canceled delivery at drop off location " + this.constant.ALPHAB_STATUS[i] + " heading to next location.";
              } else {
                this.driverMessage = "Your driver has canceled delivery at drop off location " + this.constant.ALPHAB_STATUS[i] + ".";
              }
              this.isDriverLatLngUpdated$.next(false);
              this.driverNotFoundAlert("Delivery Canceled", "Reason : " + response.subBookingData[i].cancelReason, 4);
            }
            if (
              response.subBookingData.length === 2 &&
              !response.subBookingData[i].isPickUp &&
              response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.CANCELED
            ) {
              this.driverMessage = "Your driver has canceled delivery at drop off location.";
              this.isDriverLatLngUpdated$.next(false);
              this.driverNotFoundAlert("Delivery Canceled", "Reason : " + response.subBookingData[i].cancelReason, 4);
            }
          }
          this.continue(4);
          this.setDeliveryStatus(response);
          this.isDriverLatLngUpdated.subscribe((value: boolean) => {
            if (value) {
              this.setDeliveryStatus(response);
            }
          });
          return;
        });
        // this.continue(5);
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.ARRIVED) {
        this.commonService.bookingResponseData = response;
        this.isDriveingStart = true;
        // this.markerOptions = {
        //   origin: {
        //     icon: this.iconObject(0)
        //   },
        //   destination: {
        //     icon: this.destinationMarkerIcon
        //   },
        // }
        // this.origin = { lat: response.pickUplatitude, lng: response.pickUplongitude };
        // this.destination = { lat: response.dropUplatitude, lng: response.dropUplongitude };
        // this.showDirections = true;
        // this.pickup.lat = response.pickUplatitude;
        // this.pickup.lng = response.pickUplongitude;
        // this.listenDeliveryLatLngEvent();
        // this.showDirections = false;
        this.showDirections = true;
        this.bookingResponseData = response;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        this.bookingResponseData.subBookingData.forEach((element) => {
          if (element.isPickUp) {
            this.pickupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          } else {
            this.dropupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          }
        });
        this.listenDeliveryLatLngEvent();
        this.matrix(
          { lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng },
          { lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng },
          null
        );
        this.continue(6);
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.PACKAGES_PICKED) {
        this.commonService.bookingResponseData = response;
        this.isDriveingStart = true;
        this.showDirections = true;
        this.bookingResponseData = response;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        this.bookingResponseData.subBookingData.forEach((element) => {
          if (element.isPickUp) {
            this.pickupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          } else {
            this.dropupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          }
        });
        this.listenDeliveryLatLngEvent();
        this.matrix(
          { lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng },
          { lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng },
          null
        );
        this.continue(7);
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.OUT_FOR_DELIVERY) {
        this.commonService.bookingResponseData = response;
        this.isDriveingStart = true;
        this.showDirections = true;
        this.bookingResponseData = response;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        this.bookingResponseData.subBookingData.forEach((element) => {
          if (element.isPickUp) {
            this.pickupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          } else {
            this.dropupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          }
        });
        this.listenDeliveryLatLngEvent();
        this.matrix(
          { lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng },
          { lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng },
          null
        );

        this.allPackagesDetail = [];
        this.continue(8);
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.COMPLETED) {
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        this.allPackagesDetail = [];
        var sortedSubBookingData = [];
        response.subBookingData.forEach((element) => {
          if (element.bookingStatus !== this.constant.BOOKING_STATUS.NOT_AVAILABLE && element.bookingStatus !== this.constant.BOOKING_STATUS.NONE) {
            sortedSubBookingData.push(element);
          }
          if (element.isPickUp) {
            this.pickupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          } else if (
            element.bookingStatus !== this.constant.BOOKING_STATUS.NOT_AVAILABLE &&
            element.bookingStatus !== this.constant.BOOKING_STATUS.NONE
          ) {
            this.itemDropDetails = {
              deliveryTo: element.addressType,
              itemDropup: { lat: element.latitude, lng: element.longitude, address: element.address, distance: 0 },
              streetAdd: element.streetAddress,
              suiteAptOffice: element.suitAddress,
              city: element.city,
              province: element.province,
              zipCode: element.zipCode,
              additionalInfo: element.note,
              phoneNo: element.phoneNo,
              countryCode: element.countryCode,
            };
            this.allPackagesDetail.push({
              packageType: element.packageName + "," + element.packageTypeId,
              desc: element.itemDescription,
              quantity: element.itemQuantity,
              image: element.itemImage,
              addressDetails: this.itemDropDetails,
            });
          }
        });
        response["subBookingData"] = sortedSubBookingData;
        this.commonService.bookingResponseData = response;
        this.bookingResponseData = response;
        this.listenDeliveryLatLngEvent();
        this.matrix(
          { lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng },
          {
            lat: this.allPackagesDetail[this.allPackagesDetail.length - 1].addressDetails.itemDropup.lat,
            lng: this.allPackagesDetail[this.allPackagesDetail.length - 1].addressDetails.itemDropup.lng,
          },
          null
        ).then((value) => {
          if (value) {
            this.bookingId = response._id;
            this.isDriveingStart = true;
            this.showDirections = false;
            this.showPickMarker = false;
            // this.getDriverRatingsReview();
            this.ngxService.stop();
            this.continue(9);
          }
        });
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.CANCELED) {
        this.isDriverLatLngUpdated$.next(false);
        this.commonService.bookingResponseData = response;
        this.bookingResponseData = response;
        // this.setDeliveryStatus(response);

        this.continue(4);
        if (response.isCanceledByDriver) {
          // this.driverNotFoundAlert('Your delivery has been cancelled by driver.', '', 0);
        } else if (response.isCanceledByCron) {
          // this.driverNotFoundAlert('Oops!.. No driver available nearby, please try again later.', '', 0);
        }
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.PENDING) {
        // this.driverNotFoundAlert('Oops!.. No driver available nearby, please try again later.', '', 0);
      }
    } else {
      this.commonService.bookingResponseData = response;
      this.router.navigate(["/riderequest"]);
    }
  }

  setDeliveryStatus(response) {
    if (response.bookingStatus === this.constant.BOOKING_STATUS.CANCELED) {
      this.isDriverLatLngUpdated$.next(false);
    }
    for (let i = 0; i < response.subBookingData.length; i++) {
      if (
        i === 0 &&
        response.subBookingData[i].isPickUp &&
        response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ARRIVED_AT_PICKED
      ) {
        this.driverMessage = "Your driver arrived to pick you up your delivery items.";
      }
      if (i === 0 && response.subBookingData[i].isPickUp && response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.PICKED) {
        this.driverMessage = "Driver picked up available items. For delivery status please check the App.";
      }
      if (
        !response.subBookingData[i].isPickUp &&
        response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ARRIVED_AT_DROPED &&
        this.allPackagesDetail.length < 2
      ) {
        this.driverMessage = "Driver has arrived at destination.";
      }
      if (
        !response.subBookingData[i].isPickUp &&
        response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.DROPED &&
        this.allPackagesDetail.length < 2
      ) {
        this.driverMessage = "Your package has been delivered.";
      }
      if (
        !response.subBookingData[i].isPickUp &&
        response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ARRIVED_AT_DROPED &&
        this.allPackagesDetail.length >= 2
      ) {
        this.driverMessage = "Your driver will reach the  drop off location " + this.constant.ALPHAB_STATUS[i] + " in " + this.distanceDuration + ".";
      }
      if (
        !response.subBookingData[i].isPickUp &&
        response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.DROPED &&
        this.allPackagesDetail.length >= 2
      ) {
        this.driverMessage = "Arrived at drop-off location " + this.constant.ALPHAB_STATUS[i] + ".";
      }
      if (
        !response.subBookingData[i].isPickUp &&
        response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.DROPED &&
        this.allPackagesDetail.length >= 2 &&
        i === response.subBookingData.length - 1
      ) {
        this.driverMessage = "Arrived at last drop-off location .";
      }
      if (
        response.subBookingData.length > 2 &&
        !response.subBookingData[i].isPickUp &&
        response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.CANCELED
      ) {
        if (i !== response.subBookingData.length - 1) {
          this.driverMessage =
            "Your driver has canceled delivery at drop off location " + this.constant.ALPHAB_STATUS[i] + " heading to next location.";
        } else {
          this.driverMessage = "Your driver has canceled delivery at drop off location " + this.constant.ALPHAB_STATUS[i] + ".";
        }
        this.isDriverLatLngUpdated$.next(false);
      }
      if (
        response.subBookingData.length === 2 &&
        !response.subBookingData[i].isPickUp &&
        response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.CANCELED
      ) {
        this.driverMessage = "Your driver has canceled delivery at drop off location.";
        this.isDriverLatLngUpdated$.next(false);
      }
      if (
        response.subBookingData.length === 2 &&
        !response.subBookingData[i].isPickUp &&
        response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.START_RETURN
      ) {
        this.driverMessage =
          "Your package could not be delivered because " + response.subBookingData[i].cancelReason + " and it is being returned to you";
        return;
      }
      if (
        response.subBookingData.length > 2 &&
        !response.subBookingData[i].isPickUp &&
        response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.START_RETURN
      ) {
        var bookingStatusArr = response.subBookingData.map((res) => res.bookingStatus);
        var arr = [];
        for (let j = 0; j < bookingStatusArr.length; j++) {
          if (bookingStatusArr[j] === this.constant.BOOKING_STATUS.START_RETURN) {
            arr.push(this.constant.ALPHAB_STATUS[j]);
          }
        }
        if (arr.length > 1) {
          this.driverMessage = "Delivery " + arr.join(", ") + " are being returned to you.";
        } else {
          this.driverMessage = "Delivery " + arr.join(", ") + " is being returned to you.";
        }
        return;
      }
      if (
        response.subBookingData.length > 2 &&
        !response.subBookingData[i].isPickUp &&
        response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.END_RETURN
      ) {
        var bookingStatusArr = response.subBookingData.map((res) => res.bookingStatus);
        var arr = [];
        for (let k = 0; k < bookingStatusArr.length; k++) {
          if (bookingStatusArr[k] === this.constant.BOOKING_STATUS.END_RETURN) {
            arr.push(this.constant.ALPHAB_STATUS[k]);
          }
        }
        this.driverMessage = "Delivery " + arr.join(", ") + " has been returned you.";
        return;
      }
      if (
        response.subBookingData.length === 2 &&
        !response.subBookingData[i].isPickUp &&
        response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.END_RETURN
      ) {
        this.driverMessage = "Your package has been returned to you";
        return;
      }
    }
  }

  listenDeliveryLatLngEvent() {
    this.socketService
      .onEvent(Event.updateDriverLatLongToUser)
      .pipe(takeUntil(this.notifier))
      .subscribe((response: any) => {
        // console.log(response);
        if (response.status == 200) {
          this.isDriveingStart = true;
          this.showDirections = true;
          this.showPickMarker = false;
          this.distanceDuration = response.distanceDuration;
          this.markerOptions.origin.icon = this.iconObject(response.bearing);
          this.bookingResponseData.subBookingData.forEach((element) => {
            if (element.bookingStatus === this.constant.BOOKING_STATUS.CANCELED) {
              this.isDriverLatLngUpdated$.next(false);
            }
          });
          if (this.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED) {
            var driverLatLng = { lat: response.latitude, lng: response.longitude };
            this.destination = { lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng };
            this.origin = { lat: response.latitude, lng: response.longitude };
            this.drawDeliveryPath(driverLatLng, this.pickupfieldsArr[this.pickupfieldsArr.length - 1], this.iconObject(response.bearing));
            this.waypoints = [];
            if (this.calculated === false) {
              this.calculated = true;
              this.matrix(driverLatLng, this.destination, null);
            }
          }
          if (this.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.PARTIAL) {
            this.isDriverLatLngUpdated$.next(true);
            var driverLatLng = { lat: response.latitude, lng: response.longitude };
            var dropLatLng = {
              lat: this.allPackagesDetail[this.allPackagesDetail.length - 1].addressDetails.itemDropup.lat,
              lng: this.allPackagesDetail[this.allPackagesDetail.length - 1].addressDetails.itemDropup.lng,
            };
            this.drawDeliveryPath(driverLatLng, dropLatLng, this.iconObject(response.bearing));
            clearInterval(this.intervalSubscription);
          }

          if (
            this.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.OUT_FOR_DELIVERY ||
            this.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.ARRIVED ||
            this.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.PACKAGES_PICKED
          ) {
            this.destination = { lat: this.drop.lat, lng: this.drop.lng };
          } else if (this.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.PACKAGES_PICKED) {
            this.showDirections = false;
            this.showPickMarker = false;
          }
        }
      });
  }

  drawDeliveryPath(origin, destination, originIcon) {
    var wayPontsMarkerArr = [];
    this.waypoints = [];
    if (this.pickupfieldsArr.length > 1) {
      for (let i = 0; i < this.pickupfieldsArr.length; i++) {
        if (i !== 0) {
          this.waypoints.push({
            location: { lat: this.pickupfieldsArr[i].lat, lng: this.pickupfieldsArr[i].lng },
            stopover: false,
          });
          wayPontsMarkerArr.push({ icon: this["marker" + [i + 1]] });
        } else {
          // wayPontsMarkerArr.push({ icon: 'http://i.imgur.com/7teZKif.png' });
        }
      }
      this.markerOptions = {
        origin: {
          icon: originIcon,
        },
        destination: {
          icon: this.destinationMarkerIcon,
        },
        waypoints: wayPontsMarkerArr,
      };
    } else if (this.allPackagesDetail.length > 1) {
      for (let i = 0; i < this.allPackagesDetail.length; i++) {
        if (i !== this.allPackagesDetail.length - 1) {
          this.waypoints.push({
            location: {
              lat: this.allPackagesDetail[i].addressDetails?.itemDropup?.lat,
              lng: this.allPackagesDetail[i].addressDetails?.itemDropup?.lng,
            },
            stopover: false,
          });
          wayPontsMarkerArr.push({ icon: this["marker" + [i + 2]] });
        } else {
          // wayPontsMarkerArr.push({ icon: 'http://i.imgur.com/7teZKif.png' });
        }
      }
      this.markerOptions = {
        origin: {
          icon: originIcon,
        },
        destination: {
          icon: this.destinationMarkerIcon,
        },
        waypoints: wayPontsMarkerArr,
      };
    }
    this.showDirections = true;
    this.showPickMarker = false;
    this.showDropMarker = false;
    this.origin = { lat: origin.lat, lng: origin.lng };
    if (destination?.addressDetails) {
      this.destination = { lat: destination?.addressDetails?.itemDropup?.lat, lng: destination?.addressDetails?.itemDropup?.lng };
    } else if (destination.lat) {
      this.destination = { lat: destination?.lat, lng: destination?.lng };
    }
  }

  //delivery module end

  driverNotFoundAlert(message, text, index) {
    Swal.fire({
      title: message,
      text: text,
      icon: "info",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.value) {
        this.continue(index);
      }
    });
  }

  preventBackButton() {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  iconObject(rotation: number) {
    return {
      path: "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z",
      scale: 0.7,
      strokeColor: "white",
      strokeWeight: 0.1,
      fillOpacity: 1,
      fillColor: "#404040",
      offset: "5%",
      rotation: rotation,
      flat: true,
      anchor: new google.maps.Point(10, 25),
    };
  }

  getDistanceMatrix(dropAddress, dropLatLng) {
    var origin1 = this.pickup;
    var origin2 = this.pickupAddress;
    var destinationA = dropAddress;
    var destinationB = dropLatLng;
    var service = new google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [origin1, origin2],
        destinations: [destinationA, destinationB],
        travelMode: "DRIVING",
        unitSystem: google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false,
      },
      (res: any) => {
        this.distanceInKM = res.rows[0].elements[0].distance.text;
        this.duration = res.rows[0].elements[0].duration.text;
        this.ngxService.stop();
      }
    );
  }
  matrix(pickup, drop, formField) {
    return new Promise((resolve, reject) => {
      let directionsService = new google.maps.DirectionsService();
      var request = {
        origin: pickup,
        destination: drop,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING,
      };
      directionsService.route(request, (response, status) => {
        this.directionStatus = status;
        if (status == google.maps.DirectionsStatus.OK) {
          this.distance = response?.routes[0]?.legs[0]?.distance.value / 1000; //convert m into km
          this.duration = response?.routes[0]?.legs[0]?.duration.value / 60; //convert seconds into minutes
          this.driverDistance = response?.routes[0]?.legs[0]?.distance.value / 1000; //convert m into km
          var obj = { distance: this.distance, duration: this.duration };
          return resolve(obj);
        } else {
          // this.commonService.OpenNotificationModal('error', 'top-end', 'No path found.');
          // if (formField !== null) {
          //   this.dropupForm.get(formField).patchValue('');
          // }
        }
        try {
        } catch (error) {
          console.log(error);
        }
      });
    });
  }
  continue(popupType) {
    this.ngxService.start();
    this.nextPopup = popupType;
    this.ngxService.stop();
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.pickup.lat = position.coords.latitude;
          this.pickup.lng = position.coords.longitude;
          this.zoom = 15;
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }
}

import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalFComponent implements OnInit, OnDestroy {
  title: string;
  message: string;
  id: NodeJS.Timeout;

  constructor(
    private ngZone: NgZone,
    public notification: NotificationService,
    private matDialog: MatDialog,
    public dialogRef: MatDialogRef<NotificationModalFComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log('notification',this.data);
      this.message = this.data.text;
    }

  ngOnInit(): void {
    // this.id = setTimeout(() => {
    //   this.dialogRef.close();
    // }, 5000);
  }

  close() {
    console.log(this.dialogRef.getState());
    this.ngZone.run(() => {
      this.matDialog.closeAll();
      this.dialogRef.close();
    });
  }

  ngOnDestroy() {
    // if (this.id) {
    //   clearInterval(this.id);
    // }
  }
}

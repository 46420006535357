import { Component, NgZone, OnInit } from '@angular/core';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpsService } from '../services/https/https.service';
import { CommonService } from '../services/common/common.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import { Router } from '@angular/router';
import { Constant } from '../core/constant';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-udeliverypartners',
  templateUrl: './udeliverypartners.component.html',
  styleUrls: ['./udeliverypartners.component.scss']
})
export class UdeliverypartnersComponent implements OnInit {
  slides = [
    { content: "No Driver GPS Off (Your package will be on time and delivered using the best route)", main: "No Driver GPS Off", img: "assets/images/gps-off.png" },
    { content: "Multi drop-off option (We can delivery multiple packages in a single route)", main: "Multi drop-off option", img: "assets/images/multiple-drop.png" },
    { content: "Favorite driver (you can select a driver as your favorite one and your dispatches will be sent to him/her first)", main: "Favorite driver", img: "assets/images/favourite-driver.png" }

  ];

  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 999,
    // arrows: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }
      },
    ]
  };
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Canada, CountryISO.India];
  address = {
    lat: 0,
    lng: 0
  }
  partnerForm: FormGroup;
  fullNameRequired: boolean = false;
  submitted = false;
  notifier = new Subject();
  geoCoder: any;
  bookingForm: FormGroup;
  pickup = {
    lat: 0,
    lng: 0
  }
  drop = {
    lat: 0,
    lng: 0
  }
  pickupAddress: any;
  zoom: number;
  dropAddress: any;
  origin: { lat: number; lng: number; };
  destination: { lat: number; lng: number; };
  distance: number;
  rideSubmited: boolean = false;
  currentCountry: any;
  charCodeArr: any = [96, 49, 50, 51, 52, 53, 54, 55, 56, 57, 48, 45, 61, 91, 93, 92, 59, 44, 46, 47, 126, 33, 64, 35, 36, 37, 94, 38, 42, 40, 41, 95, 43, 123, 125, 124, 58, 34, 60, 62, 63];
  validateFirstArr: any[];
  validateSecondArr: any[];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private http: HttpsService,
    private https: HttpClient,
    public commonService: CommonService,
    public constant: Constant,
    public translate: TranslateService
  ) {
    this.getCountry();
  }

  ngOnInit(): void {
    this.partnerForm = this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null, [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
      phoneNo: [null, Validators.required],
      comment: [null, Validators.required],
    });
    // this.partnerForm.get('fullName').valueChanges.subscribe((res: any) => {
    //   if (res) {
    //     var fullName = res.split(' ');
    //     if (fullName.length < 2 || fullName[fullName.length - 1] === "") {
    //       this.fullNameRequired = true;
    //     } else {
    //       this.fullNameRequired = false;
    //     }
    //   }
    // });
    this.bookingForm = this.fb.group({
      ridePickUpAddress: ['', Validators.required],
      rideDropAddress: ['', Validators.required]
    });
    this.getCurrentLocation();
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
    });
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
    });
  }

  getCountry() {
    return new Promise((resolve, reject) => {
      this.https.get('https://extreme-ip-lookup.com/json/').subscribe((location: any) => {
        console.log('location', location);
        this.currentCountry = location.countryCode;
        return resolve(location);
      });
    })
  }

  goToSignupPage() {
    this.commonService.isThisDeliveryPartner = true;
    this.router.navigate(['/delivery-partner-signup']);
  }

  onKeyUp(value) {
    if (value === "") {
      this.pickup.lat = 0;
      this.pickup.lng = 0;
      this.bookingForm.get('ridePickUpAddress').patchValue(null);
    }
  }

  pickupChange(value) {
    if (this.pickup.lat === 0 && this.pickup.lng === 0) {
      this.bookingForm.get('ridePickUpAddress').patchValue(null);
      this.rideSubmited = true;
    } else if (this.pickupAddress !== value) {
      this.bookingForm.get('ridePickUpAddress').patchValue(null);
      this.rideSubmited = true;
    }
  }

  handlePickUpChnage(address) {
    console.log(address);
    if (address.formatted_address) {
      this.pickup.lat = 0;
      this.pickup.lng = 0;
      this.pickup.lat = (address.geometry.location.lat())
      this.pickup.lng = (address.geometry.location.lng())
      this.pickupAddress = (address.formatted_address);
      this.bookingForm.get('ridePickUpAddress').patchValue(this.pickupAddress);
      // this.getPickupAddress(this.pickup, address.formatted_address);
    }
  }

  onKeyUpDrop(value) {
    if (value === "") {
      this.drop.lat = 0;
      this.drop.lng = 0;
      this.bookingForm.controls['rideDropAddress'].patchValue(null);
    }
  }

  dropupChange(value) {
    if (this.drop.lat === 0 && this.drop.lng === 0) {
      this.bookingForm.controls['rideDropAddress'].patchValue(null);
      this.rideSubmited = true;
    } else if (this.dropAddress !== value) {
      this.bookingForm.controls['rideDropAddress'].patchValue(null);
      this.rideSubmited = true;
    }
  }

  handleDropChnage(address) {
    console.log(address);
    this.drop.lat = 0;
    this.drop.lng = 0;
    if (address.formatted_address) {
      this.drop.lat = (address.geometry.location.lat());
      this.drop.lng = (address.geometry.location.lng());
      this.dropAddress = (address.formatted_address);
      this.bookingForm.controls['rideDropAddress'].patchValue(address.formatted_address);
      this.dropAddress = address.formatted_address;
      // this.getAddressDrop(this.drop, address.formatted_address);
    }
    this.getDirection();
  }

  getPickupAddress(latLng, formatted_address) {
    this.geoCoder.geocode({ 'location': { lat: latLng.lat, lng: latLng.lng } }, (results, status) => {
      if (status === 'OK' && results.length > 0) {
        var isAddSimilar = false;
        results.forEach(addresses => {
          if (addresses.formatted_address === formatted_address && !isAddSimilar) {
            isAddSimilar = true;
            console.log('exist');
            this.bookingForm.controls['ridePickUpAddress'].patchValue(addresses.formatted_address);
            this.pickupAddress = addresses.formatted_address;
            return false;
          }
        });
        if (!isAddSimilar) {
          console.log('not-exist');
          this.bookingForm.controls['ridePickUpAddress'].patchValue(results[0].formatted_address);
          this.pickupAddress = results[0].formatted_address;
        }
        this.commonService.origin = { lat: latLng.lat, lng: latLng.lng, address: this.pickupAddress };
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
    // this.checkstatusMap();
  }

  getAddressDrop(latLng, formatted_address) {
    this.geoCoder.geocode({ 'location': { lat: latLng.lat, lng: latLng.lng } }, (results, status) => {
      console.log(results);
      if (status === 'OK' && results.length > 0) {
        var isAddSimilar = false;
        results.forEach(addresses => {
          if (addresses.formatted_address === formatted_address && !isAddSimilar) {
            isAddSimilar = true;
            console.log('exist');
            this.bookingForm.controls['rideDropAddress'].patchValue(addresses.formatted_address);
            this.dropAddress = addresses.formatted_address;
            return false;
          }
        });
        if (!isAddSimilar) {
          console.log('not-exist');
          this.bookingForm.controls['rideDropAddress'].patchValue(results[0].formatted_address);
          this.dropAddress = results[0].formatted_address;
        }
        this.commonService.destination = { lat: latLng.lat, lng: latLng.lng, address: this.dropAddress };
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  getDirection() {
    if ((this.pickup.lng !== null) && (this.pickup.lng !== null) && (this.drop.lat !== null) && (this.drop.lng !== null)) {
      this.origin = { lat: this.pickup.lat, lng: this.pickup.lng };
      this.destination = { lat: this.drop.lat, lng: this.drop.lng };
      this.calculateDistance();
    }
  }

  calculateDistance() {
    const start = new google.maps.LatLng(this.pickup.lat, this.pickup.lng);
    const end = new google.maps.LatLng(this.drop.lat, this.drop.lng);
    this.distance = google.maps.geometry.spherical.computeDistanceBetween(start, end) / 1000; // distance in KM
    return this.distance;
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.pickup.lat = position.coords.latitude;
        this.pickup.lng = position.coords.longitude;
        this.zoom = 15;
        this.getPickupAddress(this.pickup, '');
      });
    }
  }

  goToriderequest() {
    this.rideSubmited = true
    if (this.bookingForm.valid) {
      this.commonService.isThisDeliveryPartner = true;
      this.commonService.origin = { lat: this.pickup.lat, lng: this.pickup.lng, address: this.pickupAddress };
      this.commonService.destination = { lat: this.drop.lat, lng: this.drop.lng, address: this.dropAddress };
      this.goToriderlogin();
      // this.router.navigate(["/riderlogin"]);
    }
  }

  goToriderlogin() {
    this.commonService.isThisDeliveryPartner = true;
    // this.router.navigate(["/riderlogin"]);
    if (localStorage.accessToken != null || localStorage.accessToken != undefined) {
      if (localStorage.getItem('userType') === 'DELIVERY_PARTNER') {
        this.http.httpGetWithHeaderUser('userProfile', "").subscribe((res: any) => {
          if (res.data.isEamilVerified === true || res.data.isPhoneVerified === true) {
            if (res.data.isAccountCompleted) {

              this.router.navigate(["/riderequest"]);
            } else {
              var detail = {
                isAddressInfo: true,
                isNewUser: false,
                isAccountCompleted: false
              }
              this.commonService.setRiderProfileAccessDetail(detail);
              this.router.navigate(["/riderdetail"]);
            }
          } else {
            // this.http.httpPostWithHeaderUser('sendOtp', { phoneNo: res.data.phoneNo }).subscribe((item: any) => {
            //   this.commonService.setVerificationData({ countryCode: item.data.countryCode, phoneNo: item.data.phoneNo, otpCode: item.data.otpCode })
            //   this.router.navigate(["/riderotppage"]);
            // });
            this.router.navigate(["/delivery-partner-login"]);
          }
        })
      } else {
        this.router.navigate(["/delivery-partner-login"]);
      }
    } else {
      this.router.navigate(["/delivery-partner-login"]);
    }
  }

  scrollToFormSection() {
    document.getElementById("partner").scrollIntoView();
  }

  handleAddressChange(address) {
    if (address.formatted_address) {
      this.address.lat = 0;
      this.address.lng = 0;
      this.address.lat = (address.geometry.location.lat());
      this.address.lng = (address.geometry.location.lng());
      this.getAddress(this.address);
    }
  }

  getAddress(latLng) {
    this.geoCoder.geocode({ 'location': { lat: latLng.lat, lng: latLng.lng } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.partnerForm.controls['address'].patchValue(results[0].formatted_address);
          var addLength = results[0].address_components.length;
          var zipCode = results[0].address_components[addLength - 1].long_name;
          this.partnerForm.controls['zipCode'].patchValue(zipCode);
        }
      }
    });
  }

  submit() {
    this.submitted = true;
    if (this.partnerForm.valid && !this.validateFirstArr.includes(true) && !this.validateSecondArr.includes(true)) {
      var codeLength = this.partnerForm.value.phoneNo.dialCode.length;
      var param = {
        "firstName": this.partnerForm.value.firstName.trim(),
        "lastName": this.partnerForm.value.lastName.trim(),
        "email": this.partnerForm.value.email.toLowerCase(),
        "phoneNo": this.partnerForm.value.phoneNo.e164Number.slice(codeLength),
        "comment": this.partnerForm.value.comment.trim(),
        "countryCode": this.partnerForm.value.phoneNo.dialCode,
        // "isPreLaunch": true
      }
      this.http.httpPostUser('becomeDeliveryPartner', param).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
        if (res.statusCode === 200) {
          this.commonService.OpenNotificationModal('success', 'top-end', 'Thank you for your inquiry. We will get back to you shortly.');
          this.partnerForm.reset();
          this.submitted = false;
        }
      });
    }
  }


  onKeyPress(event) {
    if (event.charCode !== 32 && event.charCode !== 39) {
      return (event.charCode > 64 &&
        event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)
    }
  }

  onChangeFirst(value) {
    this.validateFirstArr = [];
    for (let i = 0; i < value.length; i++) {
      var charCode = value.charCodeAt(i);
      this.validateFirstArr.push(this.charCodeArr.includes(charCode));
    }
  }

  onChangeSecond(value) {
    this.validateSecondArr = [];
    for (let i = 0; i < value.length; i++) {
      var charCode = value.charCodeAt(i);
      this.validateSecondArr.push(this.charCodeArr.includes(charCode));
    }
  }

}

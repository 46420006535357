<div class="profile_wrap">
  <div class="congrutions_page">
    <div class="congrutions_img">
      <figure>
        <img src="assets/uimages/Congratulations.svg" alt="" />
      </figure>
      <h3>{{ "Congratulations" | translate }}</h3>
      <p>{{ "Now that you're all signed up, Let's explore the world." | translate }}</p>
      <div class="btn_common_cls">
        <button class="btn btn-primary" (click)="goTouhomepage()">
          {{ "Continue" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

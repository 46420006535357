// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: "https://demo.appdukaan.com:4015/api/v1/",
  // baseUrl: 'http://192.168.1.51:4015/api/v1/',
  imageUrl: "https://demoapptunix.s3.ap-south-1.amazonaws.com",
  socketUrl: "https://demo.appdukaan.com:4015",
  webUrl: "https://demo.appdukaan.com/u2go/web",
  apiKey: "AIzaSyDV2qW4_ChypUzfm8VEsK3JsQrmA2eG7o8",
  docurl: "https://demoapptunix.s3.ap-south-1.amazonaws.com/beta",
  VAPID_PUBLIC_KEY: "BOHgs9OmBz8nWDcraBM_d8J2eVQdDloYd-RbwoVosgP7UdHO4xJUsuZDO5mL9z4aGP5nCsmon9x2XFTdKsZt_4U",
  firebaseConfig: {
    apiKey: "AIzaSyDXenj25XWjiMXwqjtm96myFwNnmy6l0Fw",
    authDomain: "u2godemo.firebaseapp.com",
    projectId: "u2godemo",
    storageBucket: "u2godemo.appspot.com",
    messagingSenderId: "253217092218",
    appId: "1:253217092218:web:c8a4593acab2afa99c41c3",
    measurementId: "G-WPRJ7JNYMJ",
  },
};

// {"publicKey":"BOHgs9OmBz8nWDcraBM_d8J2eVQdDloYd-RbwoVosgP7UdHO4xJUsuZDO5mL9z4aGP5nCsmon9x2XFTdKsZt_4U","privateKey":"6yQT2W9uRZAr1FxEtMeocsnm6LIPxh8PJz-WU_w1iJw"}
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

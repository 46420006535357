<div class="login_wrap top">
  <app-f-unavbar id="navbar"></app-f-unavbar>
  <!-- <div class="signupinner">
    <div class="conta_iner">
        <div class="flex">
            <div class="haeder_left">
                <figure class="logo_cm" style="cursor: pointer;" (click)="goTouhomepage()">
                    <img src="assets/uimages/logo.png" />
                </figure>
            </div>
            <div class="back_home">
                <button class="driverap_btn" style="cursor: pointer;" (click)="goTouhomepage()">
                    Back To Home
                </button>
            </div>
        </div>
    </div>
</div> -->
  <!-- <div class="back_home">
    <button class="driverap_btn" (click)="goTouhomepage()">Back To Home</button>
  </div> -->
  <div class="login">
    <div class="conta_iner">
      <div class="login_main">
        <div class="login_form center">
          <div class="login_img_sec">
            <figure class="login_img">
              <img src="assets/uimages/login.svg" />
            </figure>
            <h4>{{'Welcome Back' | translate}}</h4>
            <p>{{'Login to your existing account' | translate}}</p>
          </div>

          <form class="form_control_form login_form" [formGroup]="loginForm">
            <div class="form-group">
              <mat-form-field [floatLabel]="'never'">
                <label>{{'Email address or Phone' | translate}}</label>
                <input class="form-control" formControlName="userName" matInput type="text"
                  placeholder="{{'Email address or Phone' | translate}}" />
              </mat-form-field>
              <mat-error *ngIf="loginSubmit && loginForm.controls['userName'].hasError('required')"
              >{{'Please enter your email or phone' | translate}}</mat-error>
            </div>

            <div class="form-group">
              <mat-form-field [floatLabel]="'never'">
                <label>{{'Password' | translate}}</label>
                <input class="form-control" formControlName="password" matInput type="password"
                  placeholder="{{'Password' | translate}}" />
              </mat-form-field>
              <mat-error *ngIf="loginSubmit && loginForm.controls['password'].hasError('required')"
              >{{'Password is required' | translate}}</mat-error>
            </div>
            <div class="forget_text">
              <a style="cursor: pointer;" (click)="openForgotModal()">{{'Forgot password?' | translate}}</a>
            </div>

            <div class="flex forget">
              <div class="submit_btn">
                <button class="btn-primary btn" (click)="login()">
                  {{'Login' | translate}}
                </button>
              </div>
            </div>
            <div *ngIf="!commonService?.isThisDeliveryPartner">
              <p>{{'Or continue with Social' | translate}}</p>
              <div class="flex social_feedback">
                <div class="facebook_login">
                  <a class="flex" (click)="fbLogin()">
                    <figure>
                      <svg id="facebook-app-logo" xmlns="http://www.w3.org/2000/svg" width="17.913" height="17.913"
                        viewBox="0 0 17.913 17.913">
                        <path id="Path_333" data-name="Path 333"
                          d="M16.924,0H.989A.989.989,0,0,0,0,.99V16.925a.989.989,0,0,0,.989.989H9.568V10.977H7.234v-2.7H9.568V6.279a3.257,3.257,0,0,1,3.477-3.574,19.361,19.361,0,0,1,2.086.106V5.23H13.7c-1.122,0-1.339.534-1.339,1.316V8.273h2.678l-.35,2.7H12.359v6.937h4.564a.99.99,0,0,0,.99-.988V.989A.989.989,0,0,0,16.924,0Z"
                          transform="translate(0 -0.001)" fill="#fff" />
                      </svg>
                    </figure>
                    <span>{{'Facebook' | translate}}</span>
                  </a>
                </div>
                <!-- <div class="gmail"> -->
                  <!-- <a class="flex">
                    <figure>
                      <svg id="_003-apple" data-name="003-apple" xmlns="http://www.w3.org/2000/svg" width="16.87"
                        height="20.568" viewBox="0 0 16.87 20.568">
                        <path id="Path_12774" data-name="Path 12774"
                          d="M51.615,133.787c-3.061-.018-5.593-6.266-5.593-9.449,0-5.2,3.9-6.336,5.4-6.336a6.216,6.216,0,0,1,2.038.5,4.556,4.556,0,0,0,1.164.334,3.623,3.623,0,0,0,.835-.271,6.692,6.692,0,0,1,2.517-.608h.006a5.159,5.159,0,0,1,4.317,2.18l.315.473-.453.342a3.813,3.813,0,0,0-1.828,3.144,3.549,3.549,0,0,0,1.98,3.281c.284.171.577.347.577.731,0,.251-2.005,5.647-4.918,5.647a3.954,3.954,0,0,1-1.661-.4A3.368,3.368,0,0,0,54.836,133a3.8,3.8,0,0,0-1.17.317,5.733,5.733,0,0,1-2.03.474Z"
                          transform="translate(-46.022 -113.219)" fill="#fff" />
                        <path id="Path_12775" data-name="Path 12775"
                          d="M259.018,0c.076,2.735-1.88,4.632-3.833,4.513A4.358,4.358,0,0,1,259.018,0Z"
                          transform="translate(-246.753)" fill="#fff" />
                      </svg>
                    </figure>
                 <span>Apple</span>
                  </a> -->
                  <!-- <apple-signin   [state]=""
                     [redirectURI]=""
                     [scope]=""
                     [clientId]=""
                     color="light"
                     type="sign up" class="sugnup"></apple-signin> -->
                <!-- </div> -->
              </div>
            </div>
            
            <p class="dont_account">
              {{"Don't have an account?" | translate}} <a (click)="goToridersignup()">{{'Sign up' | translate}}</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade forget_password" tabindex="-1" id="forget_pass" role="dialog" [ngStyle]="{'display':display3,'opacity':1}">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="closeForgotModal()">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <h4>{{'Forgot Password?' | translate}}</h4>
        <p>{{'Please enter your email address. You will receive a link to verify' | translate}}.</p>
        <div class="form-group" [formGroup]="forgotPassForm">
          <mat-form-field [floatLabel]="'never'">
            <input class="form-control" formControlName="email" matInput type="email" placeholder="{{'Email address' | translate}}" />
          </mat-form-field>
          <mat-error *ngIf="passResetSubmit && forgotPassForm.controls['email'].hasError('required')">{{'Email is required' | translate}}.</mat-error>
          <mat-error *ngIf="passResetSubmit && forgotPassForm.controls['email'].hasError('pattern')">{{'Please enter valid email' | translate}}.</mat-error>
        </div>
        <div class="commen_btn_outer">
          <button class="btn btn-primary" (click)="passwordResetOTP()">{{'Submit' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="verification" class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}">
  <div class="modal-dialog">

      <div class="modal-content">
          <form [formGroup]="verificationTypeForm">
              <div class="modal-body">
                  <h4>{{"Let's verify your information" | translate}}</h4>
                  <p>{{'Security and safety are very important for us' | translate}}.</p>
                  <div class="rb">
                      <mat-radio-group aria-label="Select an option" formControlName="verifyBy">
                          <mat-radio-button value="email">{{email}}</mat-radio-button>
                          <mat-radio-button value="phone" [checked]="true">{{phoneNo}}</mat-radio-button>
                      </mat-radio-group>
                  </div>
                  <div class="flex signup_btn">
                      <button class="btn btn-primary" (click)="goToriderotppage(email,phoneNo)">
                          {{'Continue' | translate}}
                      </button>
                  </div>
              </div>
          </form>

      </div>

  </div>
</div>

<div id="verification2" class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display2}">
  <div class="modal-dialog">

      <div class="modal-content">
          <form >
              <div class="modal-body">
                <h4>{{"Let's verify your information" | translate}}</h4>
                <p>{{'Security and safety are very important for us' | translate}}.</p>
                  <div class="rb">
                      <mat-radio-group aria-label="Select an option">
                          <mat-radio-button value="phone" [checked]="true">{{verificationBy}}</mat-radio-button>
                      </mat-radio-group>
                  </div>
                  <div class="flex signup_btn">
                      <button class="btn btn-primary" (click)="sendOtp({ 'phoneNo': verificationBy })">
                          {{'Continue' | translate}}
                      </button>
                  </div>
              </div>
          </form>

      </div>

  </div>
</div>
import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";
import { HttpsService } from "../services/https/https.service";
import { CommonService } from "../services/common/common.service";
import { SearchCountryField, TooltipLabel, CountryISO } from "ngx-intl-tel-input";
import { HttpParams } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "src/app/services/language/language.service";
declare var $: any;
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginFComponent implements OnInit, AfterViewInit {
  loginForm: FormGroup;
  public phoneNo: FormControl = new FormControl(null, Validators.required);
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  loginSubmitted: boolean = false;
  requiredDocumentsData: any;
  profileCompletedMsg = "Profile completed successfully. Please wait for admin to approve your account.";

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private httpService: HttpsService,
    private commonService: CommonService,
    private http: HttpsService,
    public dialog: MatDialog,
    public translate: TranslateService,
    private languageService: LanguageService
  ) {
    this.loginForm = this.fb.group({
      phoneNo: [null, Validators.required],
      password: [null, Validators.required],
    });
    translate.addLangs(["en"]);
    translate.setDefaultLang("en");
  }

  ngOnInit(): void {
    this.languageService.isLangChange.subscribe((isChange: boolean) => {
      if (isChange) {
        this.getChange();
      }
    });
  }

  getChange() {
    this.translate.get(["Profile completed successfully. Please wait for admin to approve your account."]).subscribe((translations) => {
      this.profileCompletedMsg = translations["Profile completed successfully. Please wait for admin to approve your account."];
    });
  }

  ngAfterViewInit() {}

  checkUserLoggedIn() {
    if (localStorage.accessToken != null || localStorage.accessToken != undefined) {
      if (localStorage.getItem("userType") === "driver") {
        let param = new HttpParams().set("userId", localStorage.userId);
        this.http.httpGetWithHeader("getDriverProfile", param).subscribe((res: any) => {
          if (res.data.isPhoneVerified === true) {
            if (res.data.isUploadDocument && res.data.isAccountVerified) {
              // $("#app-download").modal('show');
              this.httpService.httpPostWithHeader("logout", "").subscribe((res: any) => {
                if (res.statusCode == 200) {
                  this.commonService.localStorageCLear();
                  this.router.navigate(["udriver"]);
                }
              });
            } else {
              this.router.navigate(["/profile"]);
            }
          }
        });
      }
    }
  }

  submitLogin() {
    this.loginSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    var param = {
      userName: this.loginForm.value.phoneNo.trim().toString(),
      password: this.loginForm.value.password.trim(),
      deviceType: "WEB",
      deviceToken: new Date(),
    };
    this.httpService.httpPost("login", param).subscribe((res: any) => {
      if (res.statusCode == 200) {
        localStorage.setItem("accessToken", res.data.accessToken);
        localStorage.setItem("userId", res.data.id);
        localStorage.setItem("userType", "driver");
        var details = {
          isFilledBankDetail: res.data.isFilledBankDetail,
          isDriverInfo: res.data.isDriverInfo,
          isVehicleInfo: res.data.isVehicleInfo,
          isRegularCompliance: res.data.isRegularCompliance,
          isNewUser: false,
        };
        this.commonService.setUserProfileAccessDetail(details);
        if (res.data.isPhoneVerified === true) {
          if (res.data.isUploadDocument && res.data.isAccountCompleted) {
            // this.dialog.open(DownloadApplicationComponent);
            this.httpService.httpPostWithHeader("logout", "").subscribe((res1: any) => {
              if (res1.statusCode == 200) {
                this.commonService.localStorageCLear();
                this.commonService.goToDownloadSection.next(true);
                this.router.navigate(["udriver"]);
              }
            });
          } else if (!res.data.zoneId && !res.data.geofenceId) {
            this.commonService.driverAlert();
          } else {
            if (res.data.isRegularCompliance && (!res.data.isUploadDocument || !res.data.isAccountCompleted)) {
              this.getDriverDetails(res).then((isRequired: Boolean) => {
                if (isRequired) {
                  this.commonService.OpenNotificationModal("success", "top-end", res.message);
                  this.router.navigate(["/profile"]);
                } else {
                  this.router.navigateByUrl("/udriver");
                  Swal.fire({
                    title: this.profileCompletedMsg,
                    // text: "You won't be able to revert this!",
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Okay",
                  }).then((result) => {
                    if (result.value) {
                      this.commonService.localStorageCLear();
                      this.commonService.goToDownloadSection.next(true);
                      this.router.navigate(["udriver"]);
                    }
                  });
                }
              });
            } else {
              this.commonService.OpenNotificationModal("success", "top-end", res.message);
              this.router.navigate(["/profile"]);
            }
          }
        } else {
          //   var param = {
          //     phoneNo: res.data.phoneNo,
          //     countryCode: res.data.countryCode,
          //   }
          //   this.httpService.httpPost('sendOtp',param).subscribe((res: any) => {
          //     if(res.statusCode == 200) {
          //       this.commonService.localStorageCLear();
          //       localStorage.setItem('phoneNo',res.data.phoneNo);
          //       localStorage.setItem('countryCode',res.data.countryCode);
          //       this.commonService.OpenNotificationModal('success','top-end',res.message);
          //       this.commonService.setVerificationData({countryCode: res.data.countryCode, phoneNo: res.data.phoneNo,otpCode: res.data.otpCode});
          //       this.router.navigate(["/verification"]);
          //     }
          //   });
        }
      }
    });
  }

  getDriverDetails(res) {
    return new Promise((resolve, reject) => {
      if (res.data.zoneId && res.data.geofenceId) {
        var params = {
          zoneId: res.data.zoneId,
          geofenceId: res.data.geofenceId,
          pageNo: 1,
        };
        this.httpService.httpPostWithHeader("getDefaultDriverDocument", params).subscribe((res: any) => {
          if (res.statusCode == 200) {
            this.requiredDocumentsData = res.data.documentData;
            var validateDocument = this.requiredDocumentsData.map((data) => data.isMandate);
            return resolve(validateDocument.includes(true));
          }
        });
      }
    });
  }

  forgotPassword() {
    if (this.phoneNo.valid) {
      var codeLength = this.phoneNo.value.dialCode.length;
      var param = {
        phoneNo: this.phoneNo.value.e164Number.slice(codeLength),
        countryCode: this.phoneNo.value.dialCode,
      };
      this.httpService.httpPost("forgotPassword", param).subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.commonService.OpenNotificationModal("success", "top-end", res.message);
          this.commonService.setVerificationData({ countryCode: res.data.countryCode, phoneNo: res.data.phoneNo, otpCode: res.data.otpCode });
          this.router.navigate(["/verification"]);
        }
      });
    }
  }

  goTouhomepage() {
    this.router.navigate(["uhomepage"]);
  }
  goTosignup() {
    this.router.navigate(["/signup"]);
  }
  goToriderequest() {
    this.router.navigate(["/riderequest"]);
  }
  goToforgetpassword() {
    this.router.navigate(["/forgetpassword"]);
  }
  // goTorideremail(){
  //   this.router.navigate(['/rideremail'])
  // }
}

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "src/app/services/language/language.service";
import { environment } from "src/environments/environment";
import { CommonService } from "../services/common/common.service";

@Component({
  selector: "app-unavbar",
  templateUrl: "./unavbar.component.html",
  styleUrls: ["./unavbar.component.scss"],
})
export class UnavbarComponent implements OnInit {

  navLinkArr = [
    { name: 'Home', url: 'uhomepage', isActiveClass: false },
    { name: 'Drivers', url: 'udriver', isActiveClass: false },
    { name: 'Riders', url: 'urider', isActiveClass: false },
    { name: 'Delivery Partners', url: 'udeliverypartners', isActiveClass: false },
    { name: 'Business Partners', url: 'ubusinesspartners', isActiveClass: false },
  ];
  languageCode: string;
  showLang: boolean = false;
  baseUrl = environment.imageUrl;

  constructor(private router: Router,
    public commonService: CommonService,
    public translate: TranslateService,
    public languageService: LanguageService

    ) {
      if (localStorage.languageCode !== undefined) {
        this.languageCode = localStorage.getItem('languageCode');
      }
      this.translate.use(this.languageCode);
      this.getChange();
    }

  ngOnInit(): void {
    this.activeClass();
    this.languageService.isLangChange.subscribe((isChange: boolean) => {
      if(isChange) {
       this.getChange();
      }
    })
  }

  selectLang(language) {
    this.languageService.selectedLang = language;
    this.languageService.translateLanguage(language.code);
    this.showLang = false;
  }

  getChange() {
    this.translate.get(['Home', 'Drivers', 'Riders', 'Delivery Partners', 'Business Partners']).subscribe(translations => {
      this.navLinkArr = [
        { name: translations['Home'], url: 'uhomepage', isActiveClass: false },
        { name: translations['Drivers'], url: 'udriver', isActiveClass: false },
        { name: translations['Riders'], url: 'urider', isActiveClass: false },
        { name: translations['Delivery Partners'], url: 'udeliverypartners', isActiveClass: false },
        { name: translations['Business Partners'], url: 'ubusinesspartners', isActiveClass: false },
      ];
    })
  }

  activeClass() {
    this.navLinkArr.forEach((data: any) => {
      if(this.router.url.search(data.url) === 1) {
        data.isActiveClass = true;
      } else {
        data.isActiveClass = false;
      }
    });
    if(this.router.url === '/pre-register') {
      this.navLinkArr[1].isActiveClass = true;
    }
    if((this.router.url === '/riderlogin' || this.router.url === '/ridersignup') && !this.commonService.isThisDeliveryPartner) {
      this.navLinkArr[2].isActiveClass = true;
    }
    if((this.router.url === '/delivery-partner-login' || this.router.url === '/delivery-partner-signup') && this.commonService.isThisDeliveryPartner) {
      this.navLinkArr[3].isActiveClass = true;
    }
  }

  
}

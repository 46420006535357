<div class="profile_wrap">
  <div class="signupinner">
    <div class="conta_iner">
      <div class="flex">
        <div class="haeder_left">
          <figure class="logo_cm" (click)="goBackToHome('uhomepage')">
            <img src="assets/uimages/logo.png " />
          </figure>
        </div>
        <!-- <div class="multiple_content">
                    <div class="language-select">
                        <select name="cars" id="cars">
                            <option [value]="null" disabled>Select Language</option>
                            <option value="en">English</option>
                            <option value="es">Spanish</option>
                        </select>
                    </div>
                </div> -->
        <div class="lm">
          <div class="language-select" (click)="showLang = showLang ? false : true">
            <div class="sel-bar" *ngIf="languageService?.selectedLang">
              <figure>
                <img *ngIf="languageService?.selectedLang?.logo" [src]="baseUrl + languageService?.selectedLang?.logo" />{{
                  languageService?.selectedLang?.code.toUpperCase()
                }}
              </figure>
            </div>
            <p *ngIf="!languageService?.selectedLang">Select Language</p>
            <div class="ndpicon">
              <i *ngIf="!showLang" class="fa fa-caret-down" aria-hidden="true"></i>
              <i *ngIf="showLang" class="fa fa-caret-up" aria-hidden="true"></i>
            </div>
          </div>
          <div class="dp-lang" *ngIf="showLang">
            <figure *ngFor="let language of languageService?.allLanguages" (click)="selectLang(language)">
              <img *ngIf="language?.logo" [src]="baseUrl + language?.logo" />{{ language?.code?.toUpperCase() }}
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="prfile">
    <div class="conta_iner">
      <div class="profile_main">
        <div class="toggle2">
          <svg class="ham hamRotate ham8" viewBox="0 0 100 100" width="80" onclick="this.classList.toggle('active')">
            <path class="line top" d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20" />
            <path class="line middle" d="m 30,50 h 40" />
            <path class="line bottom" d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20" />
          </svg>
        </div>
        <mat-horizontal-stepper [linear]="false" #stepper (selectionChange)="onStepChange($event)">
          <mat-step [stepControl]="personalFormGroup" [editable]="true">
            <form [formGroup]="personalFormGroup">
              <ng-template matStepLabel>{{ "Personal details" | translate }}</ng-template>
              <div class="deatil_form_outer">
                <h3 class="driver_title">{{ "Personal details" | translate }}</h3>

                <div class="detail_form">
                  <div class="driver_profile_img">
                    <div class="pr_man">
                      <figure *ngIf="!imageSrc">
                        <img (click)="fileUpload.click()" src="assets/images/user.png " />
                      </figure>
                      <figure *ngIf="imageSrc">
                        <img (click)="fileUpload.click()" [src]="imageSrc" />
                      </figure>
                    </div>
                    <div class="profile_camera">
                      <figure>
                        <img (click)="fileUpload.click()" src="assets/uimages/edit.svg " />
                      </figure>
                      <input class="upload_file" id="file-input" #fileUpload type="file" (change)="onProfileUpload($event, 'proImage')" />
                    </div>
                    <mat-error style="text-align: center; margin-top: 5px" *ngIf="profileFormSubmitted && !imageSrc"
                      >Profile image {{ "is required" | translate }}.</mat-error
                    >
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "First name" | translate }}<span class="red">*</span></label>
                    <mat-form-field [floatLabel]="'never'">
                      <mat-label>{{ "First name" | translate }}</mat-label>
                      <input matInput type="text" class="form-control" formControlName="firstName" name="name " />
                      <mat-error *ngIf="personalFormGroup.controls['firstName'].hasError('required')">
                        {{ "First name" | translate }} {{ "is required" | translate }}.
                      </mat-error>
                      <mat-error *ngIf="personalFormGroup.controls['firstName'].hasError('pattern')">
                        {{ "Please enter valid first name" | translate }}.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "Last name" | translate }}<span class="red">*</span></label>
                    <mat-form-field [floatLabel]="'never'">
                      <mat-label>{{ "Last name" | translate }}</mat-label>
                      <input matInput type="text" class="form-control" formControlName="lastName" name="name " />
                      <mat-error *ngIf="personalFormGroup.controls['lastName'].hasError('required')">
                        {{ "Last name" | translate }} {{ "is required" | translate }}.
                      </mat-error>
                      <mat-error *ngIf="personalFormGroup.controls['lastName'].hasError('pattern')">
                        {{ "Please enter valid last name" | translate }}.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "Email address" | translate }}<span class="red">*</span></label>
                    <mat-form-field [floatLabel]="'never'">
                      <mat-label>{{ "Email address" | translate }}</mat-label>
                      <input matInput type="text" class="form-control" formControlName="email" name="name " />
                      <mat-error *ngIf="personalFormGroup.controls['email'].hasError('required')">
                        {{ "Email address" | translate }} {{ "is required" | translate }}.
                      </mat-error>
                      <mat-error *ngIf="personalFormGroup.controls['email'].hasError('pattern')">
                        {{ "Sorry, only letters (a-z), number (0-9), and periods (.) are allowed" | translate }}.
                      </mat-error>
                    </mat-form-field>
                    <mat-error
                      *ngIf="
                        inValidEmail &&
                        !personalFormGroup.controls['email'].hasError('required') &&
                        !personalFormGroup.controls['email'].hasError('pattern')
                      "
                    >
                      {{ "Email address" | translate }} {{ "already exist" | translate }}.
                    </mat-error>
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "Phone number" | translate }}<span class="red">*</span></label>
                    <mat-form-field [floatLabel]="'never'">
                      <mat-label>{{ "Phone number" | translate }}</mat-label>
                      <input matInput type="text" class="form-control" formControlName="phoneNumber" name="name" readonly />
                      <mat-error *ngIf="personalFormGroup.controls['phoneNumber'].hasError('required')">
                        {{ "Phone number" | translate }} {{ "is required" | translate }}.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "Driver license number" | translate }}<span class="red">*</span></label>
                    <mat-form-field [floatLabel]="'never'">
                      <mat-label>{{ "Driver license number" | translate }}</mat-label>
                      <input matInput type="text" class="form-control" formControlName="dlNumber" name="name" />
                      <mat-error *ngIf="personalFormGroup.controls['dlNumber'].hasError('required')">
                        {{ "Driver license number" | translate }} {{ "is required" | translate }}.
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="dlNumberExist && !personalFormGroup.controls['dlNumber'].hasError('required')">
                      {{ "Driver license number" | translate }} {{ "already exist" | translate }}.</mat-error
                    >
                  </div>
                </div>
              </div>

              <div class="btn_outer">
                <button mat-button class="btn btn-primary" (click)="goToStepTwo()">{{ "Next" | translate }}</button>
              </div>

              <!-----start fileds-->

              <!-----End fileds-->
            </form>
          </mat-step>

          <mat-step [stepControl]="addressFormGroup" [editable]="true">
            <form [formGroup]="addressFormGroup">
              <ng-template matStepLabel>{{ "Address" | translate }}</ng-template>

              <div class="deatil_form_outer">
                <h3 class="driver_title">{{ "Address" | translate }}</h3>

                <div class="detail_form">
                  <div class="detail_form_items">
                    <mat-form-field [floatLabel]="'never'">
                      <label class="algn-lft">{{ "Street no. and name" | translate }}.</label>
                      <input
                        class="form-control"
                        formControlName="street"
                        matInput
                        type="text "
                        placeholder="{{ 'Street no. and name' | translate }}"
                        name="name "
                      />
                    </mat-form-field>
                    <mat-error *ngIf="submitted && addressFormGroup.controls['street'].hasError('required')">
                      {{ "Street no. and name" | translate }} {{ "are required" | translate }}.</mat-error
                    >
                  </div>
                  <div class="detail_form_items">
                    <mat-form-field [floatLabel]="'never'">
                      <label class="algn-lft">{{ "Additional details (unit #, name of business, etc)" | translate }}.</label>
                      <input
                        class="form-control"
                        formControlName="localAddress"
                        matInput
                        type="text "
                        placeholder="{{ 'Additional details (unit #, name of business, etc)' | translate }}"
                        name="name"
                      />
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items">
                    <mat-form-field [floatLabel]="'never'">
                      <label class="algn-lft">{{ "City, state/province and country" | translate }}.</label>
                      <input
                        matInput
                        placeholder="{{ 'Search for your' | translate }} {{ 'City, state/province and country' | translate }}"
                        autocorrect="off"
                        autocapitalize="off"
                        spellcheck="off"
                        type="text"
                        class="form-control"
                        ngx-google-places-autocomplete
                        #placesRef="ngx-places"
                        (keyup)="onKeyUp($event.target.value)"
                        (change)="onAddChanged($event.target.value)"
                        (onAddressChange)="handleLocationChnage($event)"
                        formControlName="location"
                      />
                    </mat-form-field>
                    <mat-error *ngIf="submitted && addressFormGroup.controls['location'].hasError('required')">
                      {{ "City, state/province and country" | translate }} {{ "are required" | translate }}.</mat-error
                    >
                  </div>
                  <div class="detail_form_items">
                    <mat-form-field [floatLabel]="'never'">
                      <label class="algn-lft">{{ "Postal/Zip Code" | translate }}.</label>
                      <input
                        class="form-control"
                        formControlName="zipCode"
                        matInput
                        type="text "
                        placeholder="{{ 'Postal/Zip Code' | translate }}"
                        name="name"
                      />
                    </mat-form-field>
                    <mat-error *ngIf="submitted && addressFormGroup.controls['zipCode'].hasError('required')">
                      {{ "Postal/Zip Code" | translate }} {{ "is required" | translate }}.</mat-error
                    >
                  </div>
                  <div class="detail_form_items">
                    <mat-form-field [floatLabel]="'never'">
                      <label class="algn-lft">{{ "Password" | translate }}.</label>
                      <!-- <mat-label>Password</mat-label> -->
                      <input
                        class="form-control"
                        placeholder="{{ 'Password' | translate }}"
                        (keydown)="$event.keyCode != 32 ? $event : $event.preventDefault()"
                        matInput
                        type="password"
                        formControlName="password"
                        name="name"
                      />
                      <mat-error *ngIf="submitted && addressFormGroup.controls['password'].hasError('required')">
                        {{ "Password" | translate }} {{ "is required" | translate }}.</mat-error
                      >
                      <mat-error *ngIf="submitted && addressFormGroup.controls['password'].hasError('minlength')">
                        {{ "Password should be 6 character long" | translate }}.</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items">
                    <mat-form-field [floatLabel]="'never'">
                      <label class="algn-lft">{{ "Confirm password" | translate }}.</label>
                      <!-- <mat-label>Confirm Password</mat-label> -->
                      <input
                        class="form-control"
                        placeholder="{{ 'Confirm password' | translate }}"
                        (keydown)="$event.keyCode != 32 ? $event : $event.preventDefault()"
                        type="password"
                        matInput
                        type="password"
                        formControlName="confirmPass"
                        name="name"
                      />
                      <mat-error *ngIf="submitted && addressFormGroup.controls['confirmPass'].hasError('required')">
                        {{ "Confirm password" | translate }} {{ "is required" | translate }}.</mat-error
                      >
                      <mat-error *ngIf="submitted && addressFormGroup.controls['confirmPass'].hasError('mustMatch')">
                        {{ "Password and confirm password must match" | translate }}</mat-error
                      >
                    </mat-form-field>
                  </div>
                </div>
                <div class="checkbox_outer">
                  <mat-checkbox formControlName="TandC"> </mat-checkbox>
                  <p>
                    {{ "By clicking here you agree to the" | translate }} APPTUNIX<sup>{{ "TM" | translate }}</sup>
                    <a style="cursor: pointer; color: #0171bd" (click)="goToCmsPages('/cms/driver-contracts')">{{ "Driver Contract" | translate }}</a>
                    {{ "and" | translate }}
                    <a style="cursor: pointer; color: #0171bd" (click)="goToCmsPages('/cms/terms-of-service')">{{
                      "Terms of Service" | translate
                    }}</a>
                    {{ "and" | translate }}
                    <a style="cursor: pointer; color: #0171bd" (click)="goToCmsPages('/cms/privacy-policy')">{{ "Privacy Policy" | translate }}</a>
                    {{ "as stated in the" | translate }}
                    <a
                      >APPTUNIX<sup>{{ "TM" | translate }}</sup> {{ "Policy Manual" | translate }}</a
                    >
                    {{ "without reservations" | translate }}.
                  </p>
                  <mat-error *ngIf="submitted && addressFormGroup.controls['TandC'].hasError('required')">
                    Terms and conditions {{ "are required" | translate }}.</mat-error
                  >
                </div>
              </div>
              <div class="btn_divide">
                <div class="btn_outer inactive">
                  <button mat-button (click)="goToPersonalTab()" class="btn btn-primary">
                    {{ "Back" | translate }}
                  </button>
                </div>
                <div class="btn_outer">
                  <button mat-button (click)="register(2, 'addressFormGroup', 'next')" class="btn btn-primary">
                    {{ "Next" | translate }}
                  </button>
                </div>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="bankFormGroup" [editable]="true">
            <form [formGroup]="bankFormGroup">
              <ng-template matStepLabel>{{ "Bank details" | translate }}</ng-template>
              <!-- <mat-form-field>
                        <mat-label>Address</mat-label>
                        <input matInput formControlName="secondCtrl " placeholder="Ex. 1 Main St, New York, NY " required />
                    </mat-form-field> -->
              <div class="deatil_form_outer">
                <h3 class="driver_title">{{ "Bank details" | translate }}</h3>

                <div class="detail_form">
                  <div class="detail_form_items">
                    <label>{{ "Account number" | translate }}<span class="red">*</span></label>
                    <mat-form-field [floatLabel]="'never'">
                      <input
                        class="form-control"
                        placeholder="{{ 'Account number' | translate }}"
                        matInput
                        type="text"
                        formControlName="accNumber"
                        name="name "
                      />
                      <mat-error *ngIf="bankFormGroup.controls['accNumber'].hasError('required')">
                        {{ "Account number" | translate }} {{ "is required" | translate }}.
                      </mat-error>
                      <mat-error
                        *ngIf="!bankFormGroup.controls['accNumber'].hasError('required') && bankFormGroup.controls['accNumber'].hasError('minlength')"
                      >
                        {{ "Please enter valid acc. no." | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "Transit" | translate }}<span class="red">*</span></label>
                    <mat-form-field [floatLabel]="'never'">
                      <mat-label>{{ "Transit" | translate }}</mat-label>
                      <input class="form-control" matInput type="text" formControlName="transit" name="name " />
                      <mat-error *ngIf="bankFormGroup.controls['transit'].hasError('required')">
                        {{ "Transit" | translate }} {{ "is required" | translate }}.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "Bank name" | translate }}<span class="red">*</span></label>
                    <mat-form-field [floatLabel]="'never'">
                      <mat-label>{{ "Bank name" | translate }}</mat-label>
                      <!-- <input class="form-control" matInput type="text" formControlName="bankName"
                                                name="name " /> -->
                      <mat-select class="form-control" formControlName="bankName">
                        <mat-option *ngFor="let bank of bankNameArr" (click)="changeBank(bank)" [value]="bank?.name">
                          {{ bank?.name }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="bankFormGroup.controls['bankName'].hasError('required')">
                        {{ "Bank name" | translate }} {{ "is required" | translate }}.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "Institution Number" | translate }}<span class="red">*</span></label>
                    <mat-form-field [floatLabel]="'never'">
                      <mat-label>{{ "Institution Number" | translate }}</mat-label>
                      <input class="form-control" matInput type="text" formControlName="institutionNumber" name="name " readonly />
                      <mat-error *ngIf="bankFormGroup.controls['institutionNumber'].hasError('required')">
                        {{ "Institution Number" | translate }} {{ "is required" | translate }}.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "Branch address" | translate }}<span class="red">*</span></label>
                    <mat-form-field [floatLabel]="'never'">
                      <mat-label>{{ "Branch address" | translate }}</mat-label>
                      <input class="form-control" matInput type="text" formControlName="branchAdd" name="name " />
                      <mat-error *ngIf="bankFormGroup.controls['branchAdd'].hasError('required')">
                        {{ "Branch address" | translate }} {{ "is required" | translate }}.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "Attach a copy of void cheque" | translate }}<span class="red">*</span></label>
                    <div class="data-b">
                      <input class="form-control" type="file" (change)="onFileChange($event, 'voidCheque')" #voidCheque />
                      <div class="paracc">
                        <p (click)="voidCheque.click()" *ngIf="allFileUploadUrl?.voidCheque === ''" class="preview-dta mt-0 mb-0 text-left">
                          {{ "Attach a copy of void cheque" | translate }}
                        </p>
                        <p (click)="voidCheque.click()" *ngIf="allFileUploadUrl?.voidCheque !== ''" class="preview-dta mt-0 mb-0 text-left">
                          {{ allFileUploadUrl?.voidCheque.split("/")[4] }}
                        </p>
                      </div>
                    </div>
                    <mat-error *ngIf="submitted && allFileUploadUrl.voidCheque == ''">
                      {{ "This field" | translate }} {{ "is required" | translate }}.
                    </mat-error>
                  </div>
                </div>
              </div>
              <div class="btn_divide">
                <div class="btn_outer inactive">
                  <button mat-button (click)="showBackMessage('udriver')" class="btn btn-primary">{{ "Back" | translate }}</button>
                </div>
                <div class="btn_outer">
                  <button mat-button (click)="bankFormSubmit()" class="btn btn-primary">{{ "Next" | translate }}</button>
                </div>
              </div>
            </form>
          </mat-step>
          <!------start driver info-->
          <mat-step [stepControl]="drivingFormGroup" [editable]="true">
            <form [formGroup]="drivingFormGroup">
              <ng-template matStepLabel>{{ "Driving info" | translate }}</ng-template>

              <div class="deatil_form_outer">
                <h3 class="driver_title">{{ "Driving info" | translate }}</h3>

                <div class="detail_form">
                  <div class="detail_form_items">
                    <label>{{ "Driver license number" | translate }}<span class="red">*</span></label>
                    <mat-form-field [floatLabel]="'never'">
                      <mat-label>{{ "Driver license number" | translate }}</mat-label>
                      <input class="form-control" matInput type="text" formControlName="dlNumber" name="name " readonly />
                      <mat-error *ngIf="drivingInfoSubmitted && drivingFormGroup.controls['dlNumber'].hasError('required')">
                        {{ "Driver license number" | translate }} {{ "is required" | translate }}.</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "Driver license expiry" | translate }}<span class="red">*</span></label>
                    <mat-form-field appearance="fill" class="date-picker-outer">
                      <mat-label>{{ "Select driver license expiry date" | translate }}</mat-label>
                      <input
                        matInput
                        formControlName="dlExpiry"
                        [min]="minDate"
                        class="form-control"
                        (dateChange)="getDlExpiryDate($event)"
                        [matDatepicker]="dp29"
                        disabled
                      />
                      <mat-datepicker-toggle matSuffix [for]="dp29"></mat-datepicker-toggle>
                      <mat-datepicker #dp29 disabled="false"></mat-datepicker>
                    </mat-form-field>
                    <mat-error *ngIf="drivingInfoSubmitted && drivingFormGroup.controls['dlExpiry'].hasError('required')">
                      {{ "Driver license expiry" | translate }} {{ "is required" | translate }}.</mat-error
                    >
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "Province/State" | translate }}<span class="red">*</span></label>
                    <mat-form-field [floatLabel]="'never'">
                      <!-- <mat-label>Search for Province/State</mat-label> -->
                      <input
                        matInput
                        [placeholder]="'Search for Province/State' | translate"
                        (change)="onStateAddChanged($event.target.value)"
                        (keyup)="onStateKeyUp($event.target.value)"
                        autocorrect="off"
                        autocapitalize="off"
                        spellcheck="off"
                        type="text"
                        class="form-control"
                        ngx-google-places-autocomplete
                        #placesRef="ngx-places"
                        (onAddressChange)="handleStateChnage($event)"
                        formControlName="dlState"
                      />
                      <mat-error *ngIf="drivingInfoSubmitted && drivingFormGroup.controls['dlState'].hasError('required')">
                        {{ "Province/State" | translate }} {{ "is required" | translate }}.</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "Country" | translate }}<span class="red">*</span></label>
                    <mat-form-field [floatLabel]="'never'">
                      <!-- <mat-label>Search for Country</mat-label> -->
                      <input
                        matInput
                        placeholder="{{ 'Search for country' | translate }}"
                        (change)="onCountryAddChanged($event.target.value, 'dlCountry', 'countryFullAddress')"
                        (keyup)="onCountryKeyUp($event.target.value, 'dlCountry', 'countryFullAddress')"
                        autocorrect="off"
                        autocapitalize="off"
                        spellcheck="off"
                        type="text"
                        class="form-control"
                        ngx-google-places-autocomplete
                        #placesRef="ngx-places"
                        (onAddressChange)="handleCountryChnage($event, 'dlCountry', 'countryFullAddress')"
                        formControlName="dlCountry"
                      />
                      <mat-error *ngIf="drivingInfoSubmitted && drivingFormGroup.controls['dlCountry'].hasError('required')">
                        {{ "Country" | translate }} {{ "is required" | translate }}.</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "City where you want to drive in" | translate }}<span class="red">*</span></label>
                    <mat-form-field [floatLabel]="'never'">
                      <!-- <mat-label>Search for Country</mat-label> -->
                      <input
                        matInput
                        placeholder="{{ 'Search for city where you want to drive in' | translate }}"
                        (change)="onCountryAddChanged($event.target.value, 'servingAddress', 'servingAddress')"
                        (keyup)="onCountryKeyUp($event.target.value, 'servingAddress', 'servingAddress')"
                        autocorrect="off"
                        autocapitalize="off"
                        spellcheck="off"
                        type="text"
                        class="form-control"
                        ngx-google-places-autocomplete
                        #placesRef="ngx-places"
                        (onAddressChange)="handleCountryChnage($event, 'servingAddress', 'servingAddress')"
                        formControlName="servingAddress"
                      />
                      <mat-error *ngIf="drivingInfoSubmitted && drivingFormGroup.controls['servingAddress'].hasError('required')">
                        {{ "City" | translate }} {{ "is required" | translate }}.</mat-error
                      >
                    </mat-form-field>
                  </div>

                  <div class="detail_form_items">
                    <label>{{ "Attache a copy of your driving license" | translate }}<span class="red">*</span></label>
                    <div class="data-b">
                      <input class="form-control" type="file" title="" (change)="onFileChange($event, 'dlImage')" #dlImage />
                      <!-- <label *ngIf="allFileUploadUrl?.dlImage !== ''"
                                  class="custom-file-label" for="dlImage">{{allFileUploadUrl?.dlImage.split('/')[4]}}</label>
                                  <label *ngIf="allFileUploadUrl?.dlImage == ''"
                                  class="custom-file-label" for="dlImage">Attache a copy of Your
                                  driving license</label> -->
                      <div class="paracc">
                        <p (click)="dlImage.click()" *ngIf="allFileUploadUrl?.dlImage === ''" class="preview-dta mt-0 mb-0 text-left">
                          {{ "Attache a copy of your driving license" | translate }}
                        </p>
                        <p (click)="dlImage.click()" *ngIf="allFileUploadUrl?.dlImage !== ''" class="preview-dta mt-0 mb-0 text-left">
                          {{ allFileUploadUrl?.dlImage.split("/")[4] }}
                        </p>
                      </div>
                    </div>
                    <mat-error *ngIf="drivingInfoSubmitted && allFileUploadUrl?.dlImage == ''">
                      {{ "Driving license" | translate }} {{ "is required" | translate }}.</mat-error
                    >
                  </div>
                </div>
              </div>
              <div class="btn_divide">
                <div class="btn_outer inactive">
                  <button mat-button (click)="goToBack()" class="btn btn-primary">{{ "Back" | translate }}</button>
                </div>
                <div class="btn_outer">
                  <button mat-button (click)="drivingFormSubmit()" class="btn btn-primary">{{ "Next" | translate }}</button>
                </div>
              </div>
            </form>
          </mat-step>
          <!------end driver info-->
          <!-----start vehicle detail-->
          <mat-step [stepControl]="vehicleFormGroup" [editable]="true">
            <form [formGroup]="vehicleFormGroup">
              <ng-template matStepLabel>{{ "Vehicle info" | translate }}</ng-template>

              <div class="deatil_form_outer">
                <h3 class="driver_title">{{ "Vehicle info" | translate }}</h3>

                <div class="detail_form">
                  <div class="detail_form_items">
                    <label>{{ "Select vehicle type" | translate }}<span class="red">*</span></label>
                    <mat-form-field class="select_bx">
                      <mat-label>{{ "Select vehicle type" | translate }}</mat-label>
                      <!-- <input type="text" matInput class="form-control"
                                                formControlName="vehicleMake" /> -->
                      <mat-select class="form-control" formControlName="vehicleType">
                        <mat-option *ngFor="let vehicle of getVehicleCategoryData" [value]="vehicle?.id">
                          {{ vehicle?.vehicleCategory }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="isVehicleSubmit && vehicleFormGroup.controls['vehicleType'].hasError('required')">
                        {{ "Select vehicle type" | translate }} {{ "is required" | translate }}.</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "Select vehicle make" | translate }}<span class="red">*</span></label>
                    <mat-form-field class="select_bx">
                      <mat-label>{{ "Select vehicle make" | translate }}</mat-label>
                      <!-- <input type="text" matInput class="form-control"
                                                formControlName="vehicleModel" /> -->
                      <mat-select class="form-control" formControlName="vehicleMake" (selectionChange)="onVehicleMakeChange($event.value)">
                        <mat-option [value]="vehicleMake" *ngFor="let vehicleMake of vehicleMakeData">{{ vehicleMake }}</mat-option>
                        <!-- <mat-option value="Ferrari-One">Ferrari-One</mat-option>
                                                <mat-option value="Octavia">Octavia</mat-option>
                                                <mat-option value="Maserati">Maserati</mat-option>
                                                <mat-option value="BMW">BMW</mat-option> -->
                      </mat-select>
                      <mat-error *ngIf="isVehicleSubmit && vehicleFormGroup.controls['vehicleMake'].hasError('required')">
                        {{ "Select vehicle make" | translate }} {{ "is required" | translate }}.</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "Select vehicle model" | translate }}<span class="red">*</span></label>
                    <mat-form-field class="select_bx">
                      <mat-label>{{ "Select vehicle model" | translate }}</mat-label>
                      <!-- <input type="text" matInput class="form-control"
                                                formControlName="vehicleModel" /> -->
                      <mat-select class="form-control" formControlName="vehicleModel">
                        <mat-option [value]="model" *ngFor="let model of vehicleModelData">{{ model }}</mat-option>
                        <!-- <mat-option value="Ferrari-One">Ferrari-One</mat-option>
                                                <mat-option value="Octavia">Octavia</mat-option>
                                                <mat-option value="Maserati">Maserati</mat-option>
                                                <mat-option value="BMW">BMW</mat-option> -->
                      </mat-select>
                      <mat-error *ngIf="isVehicleSubmit && vehicleFormGroup.controls['vehicleModel'].hasError('required')">
                        {{ "Select vehicle model" | translate }} {{ "is required" | translate }}.</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <!-- //vehicle name added -->
                  <div class="detail_form_items">
                    <label>{{ "Vehicle image" | translate }}<span class="red">*</span></label>
                    <div class="data-b">
                      <input type="file" class="form-control" (change)="onFileChange($event, 'vehicleImage')" #vehicleImage />
                      <div class="paracc">
                        <p (click)="vehicleImage.click()" *ngIf="allFileUploadUrl?.vehicleImage === ''" class="preview-dta mt-0 mb-0 text-left">
                          {{ "Vehicle image" | translate }}
                        </p>
                        <p (click)="vehicleImage.click()" *ngIf="allFileUploadUrl?.vehicleImage !== ''" class="preview-dta mt-0 mb-0 text-left">
                          {{ allFileUploadUrl?.vehicleImage.split("/")[4] }}
                        </p>
                      </div>
                    </div>
                    <mat-error *ngIf="isVehicleSubmit && allFileUploadUrl?.vehicleImage == ''">
                      {{ "Vehicle image" | translate }} {{ "is required" | translate }}.</mat-error
                    >
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "License plate number" | translate }}<span class="red">*</span></label>
                    <mat-form-field [floatLabel]="'never'">
                      <mat-label>{{ "License plate number" | translate }}</mat-label>
                      <input class="form-control" matInput type="text" formControlName="licensePlateNo" name="name" maxlength="10" />
                      <mat-error *ngIf="isVehicleSubmit && vehicleFormGroup.controls['licensePlateNo'].hasError('required')">
                        {{ "License plate number" | translate }} {{ "is required" | translate }}.</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "License plate image" | translate }}<span class="red">*</span></label>
                    <div class="data-b">
                      <input type="file" class="form-control" (change)="onFileChange($event, 'licensePlateImage')" #licensePlateImage />
                      <div class="paracc">
                        <p
                          (click)="licensePlateImage.click()"
                          *ngIf="allFileUploadUrl?.licensePlateImage === ''"
                          class="preview-dta mt-0 mb-0 text-left"
                        >
                          {{ "License plate image" | translate }}
                        </p>
                        <p
                          (click)="licensePlateImage.click()"
                          *ngIf="allFileUploadUrl?.licensePlateImage !== ''"
                          class="preview-dta mt-0 mb-0 text-left"
                        >
                          {{ allFileUploadUrl?.licensePlateImage.split("/")[4] }}
                        </p>
                      </div>
                    </div>
                    <mat-error *ngIf="isVehicleSubmit && allFileUploadUrl?.licensePlateImage == ''">
                      {{ "License plate image" | translate }} {{ "is required" | translate }}.</mat-error
                    >
                  </div>

                  <div class="detail_form_items">
                    <label>{{ "Select vehicle year" | translate }}<span class="red">*</span></label>
                    <mat-form-field class="select_bx">
                      <mat-label>{{ "Select vehicle year" | translate }}</mat-label>
                      <mat-select class="form-control" formControlName="vehicleYear">
                        <mat-option *ngFor="let year of vehicleYears" [value]="year">
                          {{ year }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="isVehicleSubmit && vehicleFormGroup.controls['vehicleYear'].hasError('required')">
                        {{ "Select vehicle year" | translate }} {{ "is required" | translate }}.</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "Registration/Ownership date" | translate }}<span class="red">*</span></label>
                    <mat-form-field [floatLabel]="'never'" class="date-picker-outer">
                      <mat-label>{{ "Registration/Ownership date" | translate }}</mat-label>
                      <input
                        matInput
                        formControlName="ownershipExpiry"
                        [max]="today"
                        class="form-control"
                        (dateChange)="getOwnershipDate($event)"
                        [matDatepicker]="dp19"
                        disabled
                      />
                      <mat-datepicker-toggle matSuffix [for]="dp19"></mat-datepicker-toggle>
                      <mat-datepicker #dp19 disabled="false"></mat-datepicker>
                      <mat-error *ngIf="isVehicleSubmit && vehicleFormGroup.controls['ownershipExpiry'].hasError('required')">
                        {{ "Registration/Ownership date" | translate }} {{ "is required" | translate }}.</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "Insurance policy number" | translate }}<span class="red">*</span></label>
                    <mat-form-field [floatLabel]="'never'">
                      <mat-label>{{ "Insurance policy number" | translate }}</mat-label>
                      <input class="form-control" matInput type="text " formControlName="policyNumber" name="name " />
                      <mat-error *ngIf="isVehicleSubmit && vehicleFormGroup.controls['policyNumber'].hasError('required')">
                        {{ "Insurance policy number" | translate }} {{ "is required" | translate }}.</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "Insurance name" | translate }}<span class="red">*</span></label>
                    <mat-form-field [floatLabel]="'never'">
                      <mat-label>{{ "Insurance name" | translate }}</mat-label>
                      <input class="form-control" matInput type="text " formControlName="insuranceName" name="name " />
                      <mat-error *ngIf="isVehicleSubmit && vehicleFormGroup.controls['insuranceName'].hasError('required')">
                        {{ "Insurance name" | translate }} {{ "is required" | translate }}.</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "Insurance expiry date" | translate }}<span class="red">*</span></label>
                    <mat-form-field [floatLabel]="'never'" class="date-picker-outer">
                      <mat-label>{{ "Insurance expiry date" | translate }}</mat-label>
                      <input
                        matInput
                        formControlName="insuranceExpiry"
                        [min]="minDate"
                        class="form-control"
                        (dateChange)="getInsuranceExpiryDate($event)"
                        [matDatepicker]="dp12"
                        disabled
                      />
                      <mat-datepicker-toggle matSuffix [for]="dp12"></mat-datepicker-toggle>
                      <mat-datepicker #dp12 disabled="false"></mat-datepicker>
                      <mat-error *ngIf="isVehicleSubmit && vehicleFormGroup.controls['insuranceExpiry'].hasError('required')">
                        {{ "Insurance expiry date" | translate }} {{ "is required" | translate }}.</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "Inspection expiry date" | translate }}<span class="red">*</span></label>
                    <mat-form-field [floatLabel]="'never'" class="date-picker-outer">
                      <mat-label>{{ "Inspection expiry date" | translate }}</mat-label>
                      <input
                        matInput
                        formControlName="inspectionExpiry"
                        [min]="minDate"
                        class="form-control"
                        (dateChange)="getInspectionExpiryDate($event)"
                        [matDatepicker]="dp13"
                        disabled
                      />
                      <mat-datepicker-toggle matSuffix [for]="dp13"></mat-datepicker-toggle>
                      <mat-datepicker #dp13 disabled="false"></mat-datepicker>
                      <mat-error *ngIf="isVehicleSubmit && vehicleFormGroup.controls['inspectionExpiry'].hasError('required')">
                        {{ "Inspection expiry date" | translate }} {{ "is required" | translate }}.</mat-error
                      >
                    </mat-form-field>
                  </div>

                  <div class="detail_form_items">
                    <label>{{ "Share drive coverage" | translate }}<span class="red">*</span></label>
                    <mat-form-field class="select_bx">
                      <mat-label>{{ "Share drive coverage" | translate }}</mat-label>
                      <!-- <input type="text" class="form-control" matInput
                                                formControlName="driveCoverage" > -->
                      <!-- <mat-radio-group aria-label="Select an option " class="form-control drive-coverage"
                                                formControlName="driveCoverage">
                                                <mat-radio-button [value]="true">{{'Yes' | translate}}</mat-radio-button>
                                                <mat-radio-button [value]="false">{{'No' | translate}}</mat-radio-button>
                                            </mat-radio-group> -->
                      <mat-select class="form-control" formControlName="driveCoverage">
                        <mat-option [value]="true">{{ "Yes" | translate }}</mat-option>
                        <mat-option [value]="false">{{ "No" | translate }}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="isVehicleSubmit && vehicleFormGroup.controls['driveCoverage'].hasError('required')">
                        {{ "Share drive coverage" | translate }} {{ "is required" | translate }}.</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "Attach a copy of your vehicle insurance" | translate }}<span class="red">*</span></label>
                    <div class="data-b">
                      <input class="form-control" type="file" (change)="onFileChange($event, 'insuranceImage')" #insuranceImage />
                      <div class="paracc">
                        <p (click)="insuranceImage.click()" *ngIf="allFileUploadUrl?.insuranceImage === ''" class="preview-dta mt-0 mb-0 text-left">
                          {{ "Attach a copy of your vehicle insurance" | translate }}
                        </p>
                        <p (click)="insuranceImage.click()" *ngIf="allFileUploadUrl?.insuranceImage !== ''" class="preview-dta mt-0 mb-0 text-left">
                          {{ allFileUploadUrl?.insuranceImage.split("/")[4] }}
                        </p>
                      </div>
                    </div>
                    <mat-error *ngIf="isVehicleSubmit && allFileUploadUrl?.insuranceImage == ''">
                      {{ "Vehicle insurance image" | translate }} {{ "is required" | translate }}.</mat-error
                    >
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "Attach a copy of your Registration/Ownership" | translate }}<span class="red">*</span></label>
                    <div class="data-b">
                      <input class="form-control" type="file" (change)="onFileChange($event, 'registrationImage')" #registrationImage />
                      <div class="paracc">
                        <p
                          (click)="registrationImage.click()"
                          *ngIf="allFileUploadUrl?.registrationImage === ''"
                          class="preview-dta mt-0 mb-0 text-left"
                        >
                          {{ "Attach a copy of your Registration/Ownership" | translate }}
                        </p>
                        <p
                          (click)="registrationImage.click()"
                          *ngIf="allFileUploadUrl?.registrationImage !== ''"
                          class="preview-dta mt-0 mb-0 text-left"
                        >
                          {{ allFileUploadUrl?.registrationImage.split("/")[4] }}
                        </p>
                      </div>
                    </div>
                    <mat-error *ngIf="isVehicleSubmit && allFileUploadUrl?.registrationImage == ''">
                      {{ "Registration/Ownership image" | translate }} {{ "is required" | translate }}.</mat-error
                    >
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "Attach a copy of vehicle inspection" | translate }}<span class="red">*</span></label>
                    <div class="data-b">
                      <input class="form-control" type="file" (change)="onFileChange($event, 'inspectionImage')" #inspectionImage />
                      <div class="paracc">
                        <p (click)="inspectionImage.click()" *ngIf="allFileUploadUrl?.inspectionImage === ''" class="preview-dta mt-0 mb-0 text-left">
                          {{ "Attach a copy of vehicle inspection" | translate }}
                        </p>
                        <p (click)="inspectionImage.click()" *ngIf="allFileUploadUrl?.inspectionImage !== ''" class="preview-dta mt-0 mb-0 text-left">
                          {{ allFileUploadUrl?.inspectionImage.split("/")[4] }}
                        </p>
                      </div>
                    </div>
                    <mat-error *ngIf="isVehicleSubmit && allFileUploadUrl?.inspectionImage == ''">
                      {{ "Vehicle inspection image" | translate }} {{ "is required" | translate }}.</mat-error
                    >
                  </div>

                  <div class="detail_form_items full_widthdetail">
                    <label>{{ "Does your vehicle have A/C and it is working?" | translate }}</label>
                    <div class="radio_component">
                      <mat-radio-group aria-label="Select an option " formControlName="isAcWorking">
                        <mat-radio-button [value]="true">{{ "Yes" | translate }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{ "No" | translate }}</mat-radio-button>
                      </mat-radio-group>
                      <mat-error *ngIf="isVehicleSubmit && vehicleFormGroup.controls['isAcWorking'].hasError('required')">
                        {{ "This field" | translate }} {{ "is required" | translate }}.</mat-error
                      >
                    </div>
                  </div>
                  <div class="detail_form_items full_widthdetail">
                    <label>{{ "Does your vehicle have heating and it is working?" | translate }}</label>
                    <div class="radio_component">
                      <mat-radio-group aria-label="Select an option " formControlName="isHeatingWorking">
                        <mat-radio-button [value]="true">{{ "Yes" | translate }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{ "No" | translate }}</mat-radio-button>
                      </mat-radio-group>
                      <mat-error *ngIf="isVehicleSubmit && vehicleFormGroup.controls['isHeatingWorking'].hasError('required')">
                        {{ "This field" | translate }} {{ "is required" | translate }}.</mat-error
                      >
                    </div>
                  </div>
                  <div class="detail_form_items full_widthdetail">
                    <label>{{ "Are there any dents or rust in the body of the vehicle?" | translate }} </label>
                    <div class="radio_component">
                      <mat-radio-group aria-label="Select an option " formControlName="isAnyDents">
                        <mat-radio-button [value]="true">{{ "Yes" | translate }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{ "No" | translate }}</mat-radio-button>
                      </mat-radio-group>
                      <mat-error *ngIf="isVehicleSubmit && vehicleFormGroup.controls['isAnyDents'].hasError('required')">
                        {{ "This field" | translate }} is {{ "is required" | translate }}.</mat-error
                      >
                    </div>
                  </div>
                  <div class="detail_form_items full_widthdetail">
                    <label>{{ "Is the interior clean and without rips/burns/smells?" || translate }} </label>
                    <div class="radio_component">
                      <mat-radio-group aria-label="Select an option " formControlName="isInteriorClean">
                        <mat-radio-button [value]="true">{{ "Yes" | translate }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{ "No" | translate }}</mat-radio-button>
                      </mat-radio-group>
                      <mat-error *ngIf="isVehicleSubmit && vehicleFormGroup.controls['isInteriorClean'].hasError('required')">
                        {{ "This field" | translate }} is {{ "is required" | translate }}.</mat-error
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="btn_divide">
                <div class="btn_outer inactive">
                  <button mat-button (click)="goToBack()" class="btn btn-primary">{{ "Back" | translate }}</button>
                </div>
                <div class="btn_outer">
                  <button mat-button (click)="vehicleFormSubmit()" class="btn btn-primary">{{ "Next" | translate }}</button>
                </div>
              </div>
            </form>
          </mat-step>
          <!----end vehicle detail-->
          <mat-step [stepControl]="regulatoryFormGroup" [editable]="true">
            <form [formGroup]="regulatoryFormGroup">
              <ng-template matStepLabel>{{ "Regulatory compliance" | translate }}</ng-template>
              <div class="deatil_form_outer">
                <h3 class="driver_title">{{ "Regulatory compliance" | translate }}</h3>

                <div class="detail_form">
                  <div class="detail_form_items">
                    <label>{{ "Attach a copy of your criminal background record check" | translate }}<span class="red">*</span></label>
                    <div class="data-b">
                      <input class="form-control" id="myfile " name="myfile" (change)="onFileChange($event, 'criminalBg')" type="file" #criminalBg />
                      <div class="paracc">
                        <p (click)="criminalBg.click()" *ngIf="allFileUploadUrl?.criminalBg === ''" class="preview-dta mt-0 mb-0 text-left">
                          {{ "Attach a copy of your criminal background record check" | translate }}
                        </p>
                        <p (click)="criminalBg.click()" *ngIf="allFileUploadUrl?.criminalBg !== ''" class="preview-dta mt-0 mb-0 text-left">
                          {{ allFileUploadUrl?.criminalBg.split("/")[4] }}
                        </p>
                      </div>
                    </div>
                    <mat-error *ngIf="regularityFormSubmitted && allFileUploadUrl?.criminalBg == ''">
                      {{ "Criminal background record image" }} {{ "is required" | translate }}.</mat-error
                    >
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "Expiry date" | translate }}<span class="red">*</span></label>
                    <mat-form-field class="select_bx date-picker-outer">
                      <mat-label>{{ "Select expiry date" | translate }}</mat-label>
                      <input
                        matInput
                        formControlName="criminalBgExpiry"
                        [min]="minDate"
                        class="form-control"
                        (dateChange)="getInspectionExpiryDate($event)"
                        [matDatepicker]="dp6"
                        disabled
                      />
                      <mat-datepicker-toggle matSuffix [for]="dp6"></mat-datepicker-toggle>
                      <mat-datepicker #dp6 disabled="false"></mat-datepicker>
                      <mat-error *ngIf="regularityFormSubmitted && regulatoryFormGroup.controls['criminalBgExpiry'].hasError('required')">
                        {{ "Expiry date" | translate }} {{ "is required" | translate }}.</mat-error
                      >
                    </mat-form-field>
                  </div>

                  <div class="detail_form_items">
                    <label>{{ "Attach a copy of your child abuse register check" | translate }}<span class="red">*</span></label>
                    <div class="data-b">
                      <input
                        class="form-control"
                        id="myfile "
                        (change)="onFileChange($event, 'abuseRegisterCheck')"
                        name="myfile "
                        #abuseRegisterCheck
                        type="file"
                      />
                      <div class="paracc">
                        <p
                          (click)="abuseRegisterCheck.click()"
                          *ngIf="allFileUploadUrl?.abuseRegisterCheck === ''"
                          class="preview-dta mt-0 mb-0 text-left"
                        >
                          {{ "Attach a copy of your child abuse register check" | translate }}
                        </p>
                        <p
                          (click)="abuseRegisterCheck.click()"
                          *ngIf="allFileUploadUrl?.abuseRegisterCheck !== ''"
                          class="preview-dta mt-0 mb-0 text-left"
                        >
                          {{ allFileUploadUrl?.abuseRegisterCheck.split("/")[4] }}
                        </p>
                      </div>
                    </div>
                    <mat-error *ngIf="regularityFormSubmitted && allFileUploadUrl?.abuseRegisterCheck == ''">
                      {{ "Image" | translate }} {{ "is required" | translate }}.</mat-error
                    >
                  </div>
                  <div class="detail_form_items">
                    <label>{{ "Expiry date" | translate }}<span class="red">*</span></label>
                    <mat-form-field class="select_bx date-picker-outer">
                      <mat-label>{{ "Select expiry date" | translate }}</mat-label>
                      <input
                        matInput
                        formControlName="childAbuseExpiry"
                        [min]="minDate"
                        class="form-control"
                        (dateChange)="getInspectionExpiryDate($event)"
                        [matDatepicker]="dp7"
                        disabled
                      />
                      <mat-datepicker-toggle matSuffix [for]="dp7"></mat-datepicker-toggle>
                      <mat-datepicker #dp7 disabled="false"></mat-datepicker>
                      <mat-error *ngIf="regularityFormSubmitted && regulatoryFormGroup.controls['childAbuseExpiry'].hasError('required')">
                        {{ "Expiry date" | translate }} {{ "is required" | translate }}.</mat-error
                      >
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <!-- <div class="checkbox_outer">
                                <mat-checkbox formControlName="TandC">
                                    <p>By clicking here you agree to the<a>APPTUNIX<sup>{{'TM' | translate}}</sup> Contract</a> and terms,
                                        conditions and
                                        policies as stated in the <a>APPTUNIX<sup>{{'TM' | translate}}</sup> Policy Manual</a> without
                                        reservations.</p>
                                </mat-checkbox>
                                <mat-error
                                    *ngIf="regularityFormSubmitted && regulatoryFormGroup.controls['TandC'].hasError('required')">
                                    Terms and Conditions {{'are required' | translate}}.</mat-error>
                            </div> -->
              <div class="btn_divide">
                <div class="btn_outer inactive">
                  <button mat-button (click)="goToBack()" class="btn btn-primary">{{ "Back" | translate }}</button>
                </div>
                <div class="btn_outer">
                  <button mat-button (click)="regularityFormSubmit()" class="btn btn-primary">{{ "Next" | translate }}</button>
                </div>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="documentFormGroup" [editable]="true">
            <form [formGroup]="documentFormGroup">
              <ng-template matStepLabel>{{ "Additional documents" | translate }}</ng-template>
              <div class="deatil_form_outer">
                <h3 class="driver_title">{{ "Additional documents" | translate }}</h3>

                <div class="detail_form">
                  <div class="driver_doc_img" *ngFor="let document of requiredDocumentsData">
                    <h3 *ngIf="document?.isFrontSideImage || document?.isBackSideImage || document?.isExpirydate">
                      {{ document?.documentName }}<span *ngIf="document?.isMandate">*</span>
                    </h3>
                    <div class="driver_doc_outer">
                      <div class="detail_form_items" *ngIf="document?.isFrontSideImage">
                        <label>{{ "Front Side" | translate }}<span class="red" *ngIf="document?.isMandate">*</span></label>
                        <input
                          class="form-control"
                          type="file"
                          [formControlName]="document?.documentName + 'FrontSide'"
                          (change)="onDocumentUpload($event, document?.documentName + 'FrontSide')"
                          [id]="document?.documentName + 'FrontSide'"
                        />
                        <label class="custom-file-label" [for]="document?.documentName + 'FrontSide'">{{
                          documentsImages[document?.documentName + "FrontSide"]
                        }}</label>
                        <mat-error
                          *ngIf="
                            documentSubmitted &&
                            document?.isMandate &&
                            documentFormGroup.controls[document?.documentName + 'FrontSide'].hasError('required')
                          "
                        >
                          {{ "Front side image of" | translate }} {{ document?.documentName }} {{ "is required" | translate }}.
                        </mat-error>
                      </div>
                      <div class="detail_form_items" *ngIf="document?.isBackSideImage">
                        <label>{{ "Back Side" | translate }}<span class="red" *ngIf="document?.isMandate">*</span></label>
                        <input
                          class="form-control"
                          type="file"
                          [formControlName]="document?.documentName + 'BackSide'"
                          (change)="onDocumentUpload($event, document?.documentName + 'BackSide')"
                          [id]="document?.documentName + 'BackSide'"
                        />
                        <label class="custom-file-label" [for]="document?.documentName + 'BackSide'">{{
                          documentsImages[document?.documentName + "BackSide"]
                        }}</label>
                        <mat-error
                          *ngIf="
                            documentSubmitted &&
                            document?.isMandate &&
                            documentFormGroup.controls[document?.documentName + 'BackSide'].hasError('required')
                          "
                        >
                          {{ "Back side image of" | translate }} {{ document?.documentName }} {{ "is required" | translate }}.
                        </mat-error>
                      </div>
                      <div class="detail_form_items" *ngIf="document?.isExpirydate">
                        <label>{{ "Expiry date" | translate }}<span class="red" *ngIf="document?.isMandate">*</span></label>
                        <mat-form-field class="select_bx date-picker-outer">
                          <mat-label>{{ "Expiry date" | translate }}</mat-label>
                          <input
                            matInput
                            [min]="minDate"
                            style="padding: 15px 15px !important"
                            [formControlName]="document?.documentName + 'Expiry'"
                            class="form-control"
                            (dateChange)="getExpiryDateOfDocuments($event, document?.documentName + 'Expiry')"
                            [matDatepicker]="dp8"
                            disabled
                          />
                          <mat-datepicker-toggle matSuffix [for]="dp8"> </mat-datepicker-toggle>
                          <mat-datepicker #dp8 disabled="false"></mat-datepicker>
                          <mat-error
                            *ngIf="
                              documentSubmitted &&
                              document?.isMandate &&
                              documentFormGroup.controls[document?.documentName + 'Expiry'].hasError('required')
                            "
                          >
                            {{ "Expiry date" | translate }} of {{ document?.documentName }} {{ "is required" | translate }}.
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="btn_divide">
                <div class="btn_outer inactive">
                  <button mat-button (click)="goToBack()" class="btn btn-primary">{{ "Back" | translate }}</button>
                </div>
                <div class="btn_outer">
                  <button mat-button (click)="documentFormSubmit()" class="btn btn-primary">{{ "Submit" | translate }}</button>
                </div>
              </div>
            </form>
          </mat-step>
        </mat-horizontal-stepper>
      </div>
    </div>
  </div>
</div>

<div id="addpayment" class="modal fade" role="dialog" [ngStyle]="{ display: recoverModal, opacity: 1 }">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button " class="close" (click)="recoverModals('none')">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
      <div class="modal-body">
        <h5>We will restore your account when we verify your information and identity</h5>
        <div class="seachbar-payment">
          <mat-form-field [floatLabel]="'never'">
            <input class="form-control" [formControl]="accRecoverPass" matInput type="password" placeholder="Please enter your old password" />
          </mat-form-field>
          <mat-error *ngIf="accRecoverPassSubmitted && accRecoverPass.hasError('required')">Password {{ "is required" | translate }}.</mat-error>
        </div>
      </div>
      <a style="cursor: pointer; color: #0271bc" (click)="forgotPassword()">Forgot password?</a>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="goToAccRecover()">Submit</button>
      </div>
    </div>
  </div>
</div>

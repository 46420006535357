import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { HttpsService } from '../services/https/https.service';
import { CommonService } from '../services/common/common.service';
import { HttpParams } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "src/app/services/language/language.service";
declare var $: any;

@Component({
  selector: "app-udriver",
  templateUrl: "./udriver.component.html",
  styleUrls: ["./udriver.component.scss"],
})
export class UdriverFComponent implements OnInit, AfterViewInit, OnDestroy {

  notifier = new Subject();
  slides = [
    { content: "Favorite driver (Once a rider or delivery partner sets you as a favorite driver, you will be first to receive their dispatch and make more money)", main: "Favorite driver", img: "assets/images/favourite-driver.png" },
    { content: "No GPS Off (You don’t have to care about choosing a route, we know where you are)", main: "No GPS Off", img: "assets/images/gps-off.png" },
    { content: "Multi pick-up and Multi-drop off option (Make more money by picking up or dropping off people or packages in one route)", main: "Multi pick-up and Multi-drop off", img: "assets/images/multiple-drop.png" },

  ];
  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 999,
    // arrows: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }
      },
    ]
  };
  constructor(private router: Router,
    private http: HttpsService,
    private commonService: CommonService,
    public translate: TranslateService,
    private languageService: LanguageService) { }

  ngOnInit(): void {
    console.log(this.router.url.search('our_app'))
    if (this.router.url.search('our_app') !== -1) {
      document.getElementById('down').click()
    }
    this.getChange();
    this.languageService.isLangChange.subscribe((isChange: boolean) => {
      if(isChange) {
       this.getChange();
      }
    })
  }

  ngAfterViewInit() {
    if (this.router.url.search('our_app') !== -1) {
      document.getElementById('down').click()
    }
    this.commonService.getDownloadSection.subscribe((value: any) => {
      if (value) {
        // document.getElementById('our_driver').scrollIntoView();
        // var element = document.getElementById('our_driver');
        var offset = document.getElementById('our_app').offsetTop;
        // var headerOffset = 250;
        // var elementPosition = element.getBoundingClientRect().top;
        // var offsetPosition = elementPosition - headerOffset;
        window.scrollTo({
          top: offset,
          behavior: "smooth"
        });
        this.commonService.goToDownloadSection.next(false);
      }
    });
  }

  goTologin() {
    // this.router.navigate(["/login"]);
    if (localStorage.accessToken != null || localStorage.accessToken != undefined) {
      if (localStorage.getItem('userType') === 'driver') {
        let param = new HttpParams().set('userId', localStorage.userId);
        this.http.httpGetWithHeader('getDriverProfile', param).subscribe((res: any) => {
          if (res.data.driverData.isPhoneVerified === true) {
            if (!res.data.driverData.isUploadDocument && res.data.driverData.address) {
              this.router.navigate(["/profile"]);
            } else {
              this.router.navigate(["/login"]);
            }
          }
        })
      } else {
        this.router.navigate(["/login"]);
      }
    } else {
      this.router.navigate(["/login"]);
    }
  }
  goTosignup() {
    this.router.navigate(["/signup"]);
  }

  getChange() {
    this.translate.get([
      'Favorite driver (Once a rider or delivery partner sets you as a favorite driver, you will be first to receive their dispatch and make more money)',
      'Favorite driver',
      'No GPS Off (You don’t have to care about choosing a route, we know where you are)',
      'No GPS Off',
      'Multi pick-up and Multi-drop off option (Make more money by picking up or dropping off people or packages in one route)',
      'Multi pick-up and Multi-drop off'
    ]).subscribe(translations => {
      this.slides = [
        {
          content: translations['Favorite driver (Once a rider or delivery partner sets you as a favorite driver, you will be first to receive their dispatch and make more money)'],
          main: translations['Favorite driver'],
          img: "assets/images/favourite-driver.png"
        },
        {
          content: translations['No GPS Off (You don’t have to care about choosing a route, we know where you are)'],
          main: translations['No GPS Off'],
          img: 'assets/images/gps-off.png',
        },
        {
          content: translations['Multi pick-up and Multi-drop off option (Make more money by picking up or dropping off people or packages in one route)'],
          main: translations['Multi pick-up and Multi-drop off'],
          img: 'assets/images/multiple-drop.png',
        }
      ];
    })
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }
}

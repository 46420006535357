import { AfterViewInit, Component, OnInit } from '@angular/core';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpsService } from '../services/https/https.service';
import { CommonService } from '../services/common/common.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-ubusinesspartners',
  templateUrl: './ubusinesspartners.component.html',
  styleUrls: ['./ubusinesspartners.component.scss']
})
export class UbusinesspartnersFComponent implements OnInit, AfterViewInit {

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Canada, CountryISO.India];
  address = {
    lat: 0,
    lng: 0
  }
  partnerForm: FormGroup;
  fullNameRequired: boolean = false;
  submitted = false;
  notifier = new Subject();
  geoCoder: google.maps.Geocoder;

  constructor(
    private fb: FormBuilder,
    private httpService: HttpsService,
    private commonService: CommonService,
    private mapsAPILoader: MapsAPILoader,
    private router: Router,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.partnerForm = this.fb.group({
      firstName: [null, [Validators.required,Validators.pattern(/^[a-zA-Z ]+$/)]],
      lastName: [null, [Validators.required,Validators.pattern(/^[a-zA-Z ]+$/)]],
      email: [null, [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
      phoneNo: [null, Validators.required],
      comment: [null, Validators.required],
    });
    
    // this.partnerForm.get('fullName').valueChanges.subscribe((res: any) => {
    //   if (res) {
    //     var fullName = res.split(' ');
    //     if (fullName.length < 2 || fullName[fullName.length - 1] === "") {
    //       this.fullNameRequired = true;
    //     } else {
    //       this.fullNameRequired = false;
    //     }
    //   }
    // });
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
    });
  }

  ngAfterViewInit() {
    var ele = document.getElementById("bp");
    localStorage.bpSection !== undefined && ele.click();localStorage.removeItem('bpSection');
  }

  scrollToFormSection() {
    document.getElementById("partner").scrollIntoView();
  }

  handleAddressChange(address) {
    if (address.formatted_address) {
      this.address.lat = 0;
      this.address.lng = 0;
      this.address.lat = (address.geometry.location.lat());
      this.address.lng = (address.geometry.location.lng());
      this.getAddress(this.address);
    }
  }

  getAddress(latLng) {
    this.geoCoder.geocode({ 'location': { lat: latLng.lat, lng: latLng.lng } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.partnerForm.controls['address'].patchValue(results[0].formatted_address);
          var addLength = results[0].address_components.length;
          var zipCode = results[0].address_components[addLength - 1].long_name;
          this.partnerForm.controls['zipCode'].patchValue(zipCode);
        }
      }
    });
  }

  submit() {
    this.submitted = true;
    if (this.partnerForm.valid) {
      var codeLength = this.partnerForm.value.phoneNo.dialCode.length;
      var param = {
        "firstName": this.partnerForm.value.firstName.trim(),
        "lastName": this.partnerForm.value.lastName.trim(),
        "email": this.partnerForm.value.email.toLowerCase(),
        "phoneNo": this.partnerForm.value.phoneNo.e164Number.slice(codeLength),
        "comment": this.partnerForm.value.comment.trim(),
        "countryCode": this.partnerForm.value.phoneNo.dialCode
      }
      this.httpService.httpPostUser('becomeBusinessPartner', param).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
        if (res.statusCode === 200) {
          this.commonService.OpenNotificationModal('success', 'top-end', 'Thank you for your inquiry. We will get back to you shortly.');
          this.partnerForm.reset();
          this.submitted = false;
        }
      })
    }
  }

}

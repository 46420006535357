import { LocationStrategy } from "@angular/common";
import { AfterViewInit, Component, OnInit, ViewChild, AfterViewChecked, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "src/app/services/language/language.service";
import { ParentService } from "src/app/services/parent/parent.service";
import { environment } from "src/environments/environment";
import { fConstant } from '../core/constant';
import { CommonService } from '../services/common/common.service';
import { HttpsService } from '../services/https/https.service';

@Component({
  selector: "app-riderotppage",
  templateUrl: "./riderotppage.component.html",
  styleUrls: ["./riderotppage.component.scss"],
})
export class RiderotppageFComponent implements OnInit,AfterViewInit, AfterViewChecked {
  otp: string;
  number: string;
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;
  code: any;
  otpInput: any = '';
  errorMsg: string = '';
  countryCode: string;
  phoneNo: string;
  languageCode: string;
  baseUrl: any;
  showLang:boolean = false;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpsService,
    private commonService: CommonService,
    private cdr: ChangeDetectorRef,
    private constant: fConstant,
    private location: LocationStrategy,
    private parentService: ParentService,
    public translate: TranslateService,
    public languageService: LanguageService
  ) {
    this.route.params.subscribe(res => {
      this.number = res.id;
      console.log(res);      
    });
    this.baseUrl = environment.imageUrl;
    if (localStorage.languageCode !== undefined) {
      this.languageCode = localStorage.getItem('languageCode');
    }
    this.translate.use(this.languageCode);
  }

  selectLang(language) {
    this.languageCode = language.code;
    this.languageService.selectedLang = language;
    this.languageService.translateLanguage(this.languageCode);
    this.showLang = false;
  }

  ngAfterViewInit() {
    this.getOtp();    
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  getOtp() {
    if(localStorage.phoneNo !== undefined && localStorage.countryCode !== undefined) {
      this.number = localStorage.phoneNo;
      this.code = localStorage.countryCode;
    } else {
      this.commonService.getVerificationData().subscribe((res:any) => {
        this.number = res.phoneNo;
        this.code = res.countryCode;
        this.otp = res.otpCode;
        // this.ngOtpInput.setValue(this.otp)
      })
    }
    
  }

  sendOTP() {
    if(localStorage.phone !== undefined) {
      this.number = localStorage.getItem('phone');
    }
    this.http.httpPostWithHeaderUser('sendOtp', { phoneNo: this.number }).subscribe((res: any) => {
      this.commonService.OpenNotificationModal('success', 'top-end', res.message);
      this.otp = res.data.otpCode;
      this.ngOtpInput.setValue("");
    });
  }

  sendOTPWithAuth() {
    if(localStorage.phoneNo !== undefined) {
      this.phoneNo = localStorage.getItem('phoneNo');
    }
    if(localStorage.countryCode !== undefined) {
      this.countryCode = localStorage.getItem('countryCode');
    }
    this.http.httpPostWithHeaderUser('sendOtpWithAuth', { countryCode: this.countryCode,phoneNo: this.phoneNo }).subscribe((res: any) => {
      this.commonService.OpenNotificationModal('success', 'top-end', res.message);
      this.otp = res.data.otpCode;
      this.ngOtpInput.setValue("");
    });
  }

  ngOnInit(): void {
    if(localStorage.isThisUpdatedUser !== undefined) {
      this.commonService.isThisUpdatedUser = JSON.parse(localStorage.getItem('isThisUpdatedUser'));
    }
    if(localStorage.userUpdateForm !== undefined) {
      this.commonService.userUpdateForm = JSON.parse(localStorage.getItem('userUpdateForm'));
    }
    if(localStorage.userType !== undefined) {
      this.commonService.isThisDeliveryPartner = localStorage.getItem('userType') === 'DELIVERY_PARTNER' ? true : false;
    }
    // this.parentService.getPrelaunchStatus.subscribe((prelaunch: any) => {
    //   this.commonService.isThisPreUser = prelaunch;
    // });
    this.preventBackButton();
  }
  
  preventBackButton() {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }
  
  onOtpChange(otp) {
    this.otpInput = otp;
  }
  goTouhomepage() {
    this.router.navigate(["/goTouhomepage"]);
  }

  verifyOtp() {
    if (this.otpInput.length < 4) {
      return;
    }
    let data = {
      otpCode: this.otpInput,
    }
    this.http.httpPostWithHeaderUser('verifyOtp', data).subscribe((res: any) => {
      if (res.statusCode === 200) {
        this.commonService.OpenNotificationModal('success', 'top-end', res.message);
        if (this.commonService.isThisDeliveryPartner && !this.commonService.isThisUpdatedUser && res.data.isUserDeleted) {
          // this.commonService.OpenNotificationModal('success', 'top-end', 'Your account has been created successfully.');
          // this.router.navigate(["/riderequest"]);
          // this.commonService.localStorageCLear();
          this.commonService.getUser().then(data => {
            if (data) {
              this.commonService.accRecover();
            }
          });
        } else if (this.commonService.isThisDeliveryPartner && this.commonService.isThisUpdatedUser) {
          this.verifyUpdate();
        } else if (this.commonService.isThisPreUser) {
          this.commonService.OpenNotificationModal('success', 'top-end', 'Your account has been created successfully.');
          this.commonService.preLaunchAlert(this.constant.MSGS.PRELAUNCH_DELIVERY);
          this.commonService.localStorageCLear();
        } else if (this.commonService.isThisUpdatedUser) {
          this.verifyUpdate();
        } else if (res.data.isAccountCompleted && !this.commonService.isThisUpdatedUser && res.data.isUserDeleted) {
          this.commonService.getUser().then(data => {
            if (data) {
              this.commonService.accRecover();
            }
          })
        } else if (res.data.isAccountCompleted && !res.data.isDeleted && !res.data.isUserDeleted) {
          this.router.navigate(["/riderequest"]);
        } else {
          this.router.navigate(["/riderdetail"]);
        }
        var detail = {
          isAddressInfo: false,
          isNewUser: true,
          isAccountCompleted: false
        }
        this.commonService.setRiderProfileAccessDetail(detail);
        // this.commonService.openCongratsModal();

      }
    })
  }

  verifyUpdate() {
    var formData = new FormData();
    var codeLength = this.commonService.userUpdateForm.phoneNo.dialCode.length;
    // formData.append('isPhoneVerified', "true");
    formData.append('phoneNo', this.commonService.userUpdateForm.phoneNo.e164Number.slice(codeLength));
    formData.append('countryCode', this.commonService.userUpdateForm.phoneNo.dialCode);
    this.http.httpPostWithFormDataHeader('updateUserProfile', formData).subscribe((res1: any) => {
      if (res1.statusCode === 200) {
        this.commonService.getUser().then(response => {
          if (response) {
            this.commonService.isPhoneVerified = true;
            this.router.navigate(["/profilesetup"]);
          }
        })
      }
    });
  }

  updateProfile() {
    var formData = new FormData();
    formData.append('isPhoneVerified', 'true');
    this.http.httpPostWithFormDataHeader('updateUserProfile', formData).subscribe((res: any) => {
      if (res.statusCode === 200) {
      }
    });
  }

  resend() {
    this.sendOTPWithAuth();
  }
}

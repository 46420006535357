<div class="riderequest">
  <div class="map map-request">
    <!-- <agm-map [latitude]="currentPosition?.lat" [longitude]="currentPosition?.lng"></agm-map> -->
    <agm-map [latitude]="pickup.lat" [longitude]="pickup.lng" [zoom]="zoom" (mapReady)="onMapReady($event)">
      <div *ngFor="let pick of pickupfieldsArr">
        <agm-marker
          *ngIf="showPickMarker && pick?.lat !== 0 && pick?.lng !== 0"
          [iconUrl]="originMarkerIcon"
          [latitude]="pick?.lat"
          [longitude]="pick?.lng"
          [markerDraggable]="false"
        ></agm-marker>
      </div>
      <div *ngFor="let drop of dropupfieldsArr">
        <agm-marker
          *ngIf="showDropMarker && drop?.lat !== 0 && drop?.lng !== 0"
          [iconUrl]="destinationMarkerIcon"
          [latitude]="drop?.lat"
          [longitude]="drop?.lng"
          [markerDraggable]="false"
        ></agm-marker>
      </div>
      <agm-direction
        *ngIf="showDirections"
        [origin]="origin"
        [destination]="destination"
        [renderOptions]="renderOptions"
        [markerOptions]="markerOptions"
        [waypoints]="waypoints"
      >
      </agm-direction>
    </agm-map>
  </div>

  <div class="waves1 ontheway">
    <div class="lod-main">
      <!-- <div class="loader_inner">
                <div class="loads-l">
                    <figure>
                        <img *ngIf="bookingResponseData?.driverId?.image === ''" src="assets/uimages/profile_image.png" />
                        <img *ngIf="bookingResponseData?.driverId?.image !== ''" [src]="baseUrl+bookingResponseData?.driverId?.image" />
                    </figure>
                </div>
            </div> -->
    </div>
    <div class="book-m">
      <!-- data-toggle="modal" data-target="#myModal2" -->
      <div class="booking-onway">
        <div class="man-outer">
          <div class="booking-man">
            <figure class="mr-20">
              <img *ngIf="!bookingData?.driverId?.image" src="assets/uimages/profile_image.png" />
              <img *ngIf="bookingData?.driverId?.image" [src]="baseUrl + bookingData?.driverId?.image" />
            </figure>
          </div>
          <div class="booking-r">
            <div class="man-name">
              <div>
                <h5>
                  {{ bookingData?.driverId?.firstName }} {{ bookingData?.driverId?.lastName }}
                  <!-- <span>{{driverMessage}}</span> -->
                </h5>
              </div>
              <!-- <div>
                                <figure>
                                    <img *ngIf="!bookingData?.driverId?.image" src="assets/uimages/profile_image.png" />
                                    <img *ngIf="bookingData?.driverId?.image" [src]="baseUrl+bookingData?.driverId?.image" />
                                </figure>
                            </div> -->
            </div>
            <div class="rate">
              <div class="rate-inner">
                <p class="mr-20">
                  <span class="blk">{{ "Vehicle" | translate }}</span> - {{ bookingData?.vehicleId?.vehicleModel }}
                  <span style="color: #007bff; font-weight: bold" *ngIf="bookingData?.vehicleId?.licensePlateNumber !== ''">{{
                    bookingData?.vehicleId?.licensePlateNumber
                  }}</span>
                </p>
                <p>
                  <span class="blk">{{ "Price" | translate }}</span> - ${{ bookingData?.booKingAmount }}
                </p>
              </div>
            </div>
            <div class="info-icon">
              <i class="fa fa-info-circle" aria-hidden="true" data-toggle="modal" data-target="#infoModal"></i>
            </div>
            <Div class="start_trip only-icon">
              <div class="start_trip_text">
                <div class="mm" *ngFor="let pick of bookingData?.subBookingData">
                  <div *ngIf="pick?.isPickUp">
                    <div class="blm">
                      <div class="bb"></div>
                    </div>
                    <div class="mkl">
                      <p>{{ pick?.address }}</p>
                    </div>
                  </div>
                </div>

                <div class="mm" *ngFor="let drop of bookingData?.subBookingData">
                  <div *ngIf="!drop?.isPickUp">
                    <div class="blm">
                      <div class="blc"></div>
                    </div>
                    <div class="mkl">
                      <p>{{ drop?.address }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Div>
            <!-- <div class="details">
                            <p>{{bookingData?.name}}</p>
                            <hr>
                            <p>{{bookingData?.phoneNo}}</p>
                            <hr>
                            <p *ngIf="bookingData?.additionalInfo">{{bookingData?.additionalInfo}}</p>
                            <hr *ngIf="bookingData?.additionalInfo">
                        </div> -->
          </div>
        </div>
        <!-- <div class="visa-maion">
                    <div class="visa-l">
                        <div>
                            <figure><img  src="assets/uimages/visa_card.png " /></figure>
                        </div>
                        <p>****7548</p>
                        <div class="left-arr">
                            <figure><img src="assets/images/left-arrow.svg" /></figure>
                        </div>
                    </div>
                    <div class="visa-r">
                        <div>
                            <figure>
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17.055" height="17.055" viewBox="0 0 17.055 17.055">
                                    <defs>
                                        <clipPath id="clip-path">
                                            <path
                                                id="Path_29"
                                                data-name="Path 29"
                                                d="M11.861-1.317a6.822,6.822,0,0,0,6.822-6.822,6.822,6.822,0,0,0-6.822-6.822A6.822,6.822,0,0,0,5.039-8.139,6.822,6.822,0,0,0,11.861-1.317Zm0,1.705A8.527,8.527,0,0,1,3.333-8.139a8.527,8.527,0,0,1,8.527-8.527,8.527,8.527,0,0,1,8.527,8.527A8.527,8.527,0,0,1,11.861.388Zm.853-9.38V-12.4a.853.853,0,0,0-.853-.853.853.853,0,0,0-.853.853v4.264a.853.853,0,0,0,.853.853h4.264a.853.853,0,0,0,.853-.853.853.853,0,0,0-.853-.853Z"
                                                transform="translate(-3.333 16.667)"
                                                fill="none"
                                                clip-rule="evenodd"
                                            />
                                        </clipPath>
                                        <clipPath id="clip-path-2">
                                            <path id="Path_28" data-name="Path 28" d="M0,5.582H25.582V-20H0Z" transform="translate(0 20)" fill="none" />
                                        </clipPath>
                                        <clipPath id="clip-path-3">
                                            <rect id="Rectangle_5" data-name="Rectangle 5" width="30.699" height="30.699" fill="none" />
                                        </clipPath>
                                        <clipPath id="clip-path-4">
                                            <path id="Path_27" data-name="Path 27" d="M3,.908H20.908V-17H3Z" transform="translate(-3 17)" fill="none" />
                                        </clipPath>
                                    </defs>
                                    <g id="Group_38" data-name="Group 38" transform="translate(-3.333 16.667)">
                                        <g id="Group_37" data-name="Group 37" transform="translate(3.333 -16.667)" clip-path="url(#clip-path)">
                                            <g id="Group_36" data-name="Group 36" transform="translate(-4.264 -4.264)" clip-path="url(#clip-path-2)">
                                                <g id="Group_35" data-name="Group 35" transform="translate(-2.558 -2.558)" clip-path="url(#clip-path-3)">
                                                    <g id="Group_34" data-name="Group 34" transform="translate(6.396 6.396)" clip-path="url(#clip-path-4)">
                                                        <path id="Path_26" data-name="Path 26" d="M-1.667-21.667H28.179V8.179H-1.667Z" transform="translate(-4.303 15.697)" fill="#FEC06D" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </figure>
                        </div>
                        <div><h5>{{duration}}.</h5></div>
                    </div>
                </div> -->
        <!-- <div class="btn-cancel"><button class="btn" routerLink="/uhomepage">Cancel</button></div> -->
      </div>
    </div>
  </div>
</div>
<div id="infoModal" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <Div class="start_trip">
          <div class="start_trip_text">
            <div class="mm" *ngFor="let pick of bookingData?.subBookingData">
              <div *ngIf="pick?.isPickUp">
                <div class="blm">
                  <div class="bb"></div>
                </div>
                <div class="mkl">
                  <p>{{ pick?.address }}</p>
                </div>
              </div>
            </div>

            <div class="mm" *ngFor="let drop of bookingData?.subBookingData">
              <div *ngIf="!drop?.isPickUp">
                <div class="blm">
                  <div class="blc"></div>
                </div>
                <div class="mkl">
                  <p>{{ drop?.address }}</p>
                </div>
              </div>
            </div>
          </div>
        </Div>
      </div>
    </div>
  </div>
</div>

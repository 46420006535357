<div class="riderequest">
  <div class="map">
    <agm-map [latitude]="pickup.lat" [longitude]="pickup.lng" [zoom]="zoom" (mapReady)="onMapReady($event)">
      <div *ngIf="!isDriveingStart">
        <agm-overlay
          *ngFor="let driver of nearByDriversData"
          [latitude]="driver?.latitude"
          [longitude]="driver?.longitude"
          [bounds]="{ x: { latitude: -0.003, longitude: -0.0052 }, y: { latitude: 0.003, longitude: 0.0052 } }"
        >
          <div>
            <img style="cursor: pointer; width: 50px; height: 50px" [src]="ubericon" />
          </div>
        </agm-overlay>
      </div>
      <div *ngFor="let pick of pickupfieldsArr">
        <agm-marker
          *ngIf="showPickMarker && pick?.lat !== 0 && pick?.lng !== 0"
          [iconUrl]="originMarkerIcon"
          [latitude]="pick?.lat"
          [longitude]="pick?.lng"
          [markerDraggable]="true"
          (dragEnd)="markerDragEndOrigin($event, pick)"
        ></agm-marker>
      </div>
      <div *ngFor="let drop of dropupfieldsArr">
        <agm-marker
          *ngIf="showDropMarker && drop?.lat !== 0 && drop?.lng !== 0"
          [iconUrl]="destinationMarkerIcon"
          [latitude]="drop?.lat"
          [longitude]="drop?.lng"
          [markerDraggable]="true"
          (dragEnd)="markerDragEndDestination($event, drop)"
        ></agm-marker>
      </div>
      <agm-direction
        *ngIf="showDirections"
        [origin]="origin"
        [destination]="destination"
        [renderOptions]="renderOptions"
        [markerOptions]="markerOptions"
        [waypoints]="waypoints"
      >
      </agm-direction>
    </agm-map>
  </div>
  <div class="login_map_toper flex" (click)="goToprofilesetup()">
    <div class="login_map_toper_left">
      <figure>
        <img class="proImage" *ngIf="!user?.image" src="assets/images/user.png" />
        <img class="proImage" *ngIf="user?.image" [src]="baseUrl + user?.image" />
      </figure>
    </div>
    <div class="login_map_toper_right">
      <h4>{{ user?.firstName ? user?.firstName : "" + " " + user?.lastName ? user?.lastName : "" }}</h4>
    </div>
    <div class="fa_arrow"><i class="fa fa-angle-down" aria-hidden="true"></i></div>
  </div>
  <div class="ride_request_main" *ngIf="!isThisOnlyTrackingPage">
    <div class="ride_request_right">
      <div class="ride_request_right2 fbtn">
        <figure class="ride_request_logo" style="cursor: pointer" (click)="goToHome()">
          <img src="assets/uimages/logo.png" />
        </figure>
        <div class="multiple_content">
          <div class="lm">
            <div class="language-select" (click)="showLang = showLang ? false : true">
              <div class="sel-bar" *ngIf="languageService?.selectedLang">
                <figure>
                  <img *ngIf="languageService?.selectedLang?.logo" [src]="baseUrl + languageService?.selectedLang?.logo" />{{
                    languageService?.selectedLang?.code?.toUpperCase()
                  }}
                </figure>
              </div>
              <p *ngIf="!languageService?.selectedLang">Select Language</p>
              <div class="ndpicon">
                <i *ngIf="!showLang" class="fa fa-caret-down" aria-hidden="true"></i>
                <i *ngIf="showLang" class="fa fa-caret-up" aria-hidden="true"></i>
              </div>
            </div>
            <div class="dp-lang" *ngIf="showLang">
              <figure *ngFor="let language of languageService?.allLanguages" (click)="selectLang(language)">
                <img *ngIf="language?.logo" [src]="baseUrl + language?.logo" />{{ language?.code?.toUpperCase() }}
              </figure>
            </div>
          </div>
          <div class="back_home hmbtn">
            <button class="driverap_btn" style="cursor: pointer" (click)="goToHome()">
              {{ "Back To Home" | translate }}
            </button>
          </div>
        </div>
      </div>

      <div class="request_ride_inner" *ngIf="nextPopup == 0">
        <div class="select_ride_cate">
          <mat-radio-group aria-label="Select an option">
            <mat-radio-button value="ride" style="margin-right: 15px" class="choose_ride_radio">
              <div class="delivery-tab activedt">
                <h4>{{ "Book a Ride" | translate }}</h4>
                <figure>
                  <img src="assets/images/2car.png" />
                </figure>
              </div>
            </mat-radio-button>
            <mat-radio-button value="delivery" class="choose_ride_radio" (click)="goToriderdeliverybooking()">
              <div class="delivery-tab">
                <h4>{{ "Book a Delivery" | translate }}</h4>
                <figure>
                  <img src="assets/images/2car.png" />
                </figure>
              </div>
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <!-- <form class="choose_pickup_input" [formGroup]="bookingForm"> -->
        <div class="title_bar">
          <h2>{{ "Request a Ride now" | translate }}</h2>
        </div>
        <form [formGroup]="pickupForm">
          <div class="form-group choose_pickup_outer">
            <div class="form_grp_request_left"></div>
            <div class="form_grp_request_right" *ngIf="pickupfieldsArr.length > 0">
              <mat-label class="ride_request">{{ "Choose your pick up" | translate }}</mat-label>
              <div class="multiple_add_outer" *ngFor="let pickup of pickupfieldsArr; let i = index">
                <mat-form-field class="example-full-width">
                  <div [ngClass]="i === pickupfieldsArr.length - 1 ? 'multiple-add last' : 'multiple-add'">
                    <div class="blue_circle"></div>
                    <input
                      matInput
                      placeholder="{{ 'Search for pick up location' | translate }}"
                      autocorrect="off"
                      autocapitalize="off"
                      spellcheck="off"
                      type="text"
                      [options]="{
                        types: [],
                        componentRestrictions: { country: [pickup.country] }
                      }"
                      ngx-google-places-autocomplete
                      #placesRef="ngx-places"
                      (change)="pickupChange(pickup, $event.target.value)"
                      (kayup)="pickeyUp(pickup, $event.target.value)"
                      (onAddressChange)="handlePickUpChnage($event, pickup)"
                      formControlName="{{ pickup?.name }}"
                    />
                    <div *ngIf="dropupfieldsArr.length === 1">
                      <button class="rotate" *ngIf="i !== pickupfieldsArr?.length - 1 || i > 2" (click)="removePickupField(i)">+</button>
                      <button
                        [disabled]="pickupForm.controls[pickup?.name].hasError('required')"
                        class="plusBtn"
                        *ngIf="i === pickupfieldsArr?.length - 1 && i <= 2"
                        (click)="addPickupField(pickup)"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </mat-form-field>
              </div>
              <p style="color: red" *ngIf="rideSubmited === true && pickupForm.status === 'INVALID'">
                {{ "Pick up address is required" | translate }}.
              </p>
            </div>
          </div>
        </form>
        <form [formGroup]="dropupForm">
          <div class="form-group choose_pickup_outer">
            <div class="form_grp_request_left"></div>
            <div class="form_grp_request_right sec" *ngIf="dropupfieldsArr.length > 0">
              <mat-label class="ride_request">{{ "Choose your drop off" | translate }} </mat-label>
              <div class="multiple_add_outer" *ngFor="let drop of dropupfieldsArr; let i = index">
                <mat-form-field class="example-full-width">
                  <!-- <mat-label>Search for drop off location </mat-label> -->
                  <div [ngClass]="i === dropupfieldsArr.length - 1 ? 'multiple-add last' : 'multiple-add'">
                    <div class="red_circle"></div>
                    <input
                      matInput
                      placeholder="{{ 'Search for drop off location' | translate }}"
                      autocorrect="off"
                      autocapitalize="off"
                      spellcheck="off"
                      type="text"
                      [options]="{
                        types: [],
                        componentRestrictions: { country: [drop.country] }
                      }"
                      ngx-google-places-autocomplete
                      #placesRef="ngx-places"
                      (change)="dropChange(drop, $event.target.value)"
                      (keyup)="dropKeyup(drop, $event.target.value)"
                      (onAddressChange)="handleDropChnage($event, drop)"
                      formControlName="{{ drop?.name }}"
                    />
                    <div *ngIf="pickupfieldsArr.length === 1">
                      <button class="rotate" *ngIf="i !== dropupfieldsArr?.length - 1 || i > 2" (click)="removeDropupField(i)">+</button>
                      <button
                        [disabled]="dropupForm.controls[drop?.name].hasError('required')"
                        class="plusBtn"
                        *ngIf="i === dropupfieldsArr?.length - 1 && i <= 2"
                        (click)="addDropupField(drop)"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </mat-form-field>
              </div>
              <p style="color: red" *ngIf="rideSubmited === true && dropupForm.status === 'INVALID'">
                {{ "Drop off address is required" | translate }}.
              </p>
            </div>
          </div>
        </form>
        <!-- </form> -->
        <div class="btn_divde margin_top25">
          <div class="btn_outer">
            <button [ngClass]="{ 'btn-active': activeBtn === 'Now', btn: true, 'btn-outline': activeBtn != 'Now' }" (click)="bookRideForNow()">
              {{ "Start now" | translate }}
            </button>
          </div>
          <div class="btn_outer">
            <button [ngClass]="{ 'btn-active': activeBtn === 'Later', btn: true, 'btn-outline': activeBtn != 'Later' }" (click)="openDateTimeModal()">
              {{ "Schedule a ride" | translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="request_ride_inner part2" *ngIf="nextPopup == 1">
        <div class="car_using_main">
          <div class="car_using_left">
            <h4>Suggested vehicle <span class="suggted-car">(options subject to availability)</span></h4>
            <ul class="car_using_left_inner">
              <li
                [ngClass]="{ active_car_bx: selectedItem === i }"
                *ngFor="let category of vehicleCategoryData; let i = index"
                (click)="selectVehicle(category, i)"
              >
                <div class="yellow_bg">
                  <figure>
                    <img [src]="baseUrl + category?.vehicleImage" />
                  </figure>
                </div>
                <h5>{{ category?.vehicleCategory }}</h5>
                <p>
                  US $<span>{{ category?.booKingAmount }}</span>
                </p>
                <!-- <p>US $<span>{{getFare(category?.pricePerMile)}}.00</span></p> -->
              </li>
            </ul>
          </div>
        </div>
        <div class="note_driver">
          <h3>Note to driver</h3>
          <span>Let your driver know more about your request.</span>
          <div class="note_driver_name">
            <form [formGroup]="driverNoteForm">
              <mat-form-field class="example-full-width">
                <input type="text" matInput formControlName="phoneNo" class="form-control" placeholder="Enter Your Phone Number" />
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <input type="text" matInput formControlName="name" class="form-control" placeholder="Enter Your Name" />
              </mat-form-field>
              <textarea placeholder="Note" maxlength="100" formControlName="notes" id="" cols="30" rows="10"></textarea>
            </form>
          </div>
        </div>
        <div class="add_promo_sec">
          <div class="visa_card">
            <figure (click)="continue('2')">
              <img src="assets/uimages/visa_card.png" alt="" />
            </figure>
          </div>
          <div class="add_promo">
            <a href="##" data-toggle="modal" data-target="#add_promo"
              ><span><img src="assets/uimages/promo_icon.png" alt="" /></span>Loyality Points</a
            >
          </div>
        </div>
        <div class="btn_divde margin_custom">
          <div class="btn_outer inactive">
            <button class="btn btn-primary" (click)="continue(0)">Back</button>
          </div>
          <div class="btn_outer">
            <button class="btn btn-primary" (click)="CreateRideBooking(3)">Request Booking</button>
          </div>
        </div>
      </div>

      <div class="request_ride_inner border_remove paymernt" *ngIf="nextPopup == 2">
        <div class="title_bar">
          <h2>Payment</h2>
        </div>
        <ul class="payment_last">
          <li class="borderbtm">
            <div class="list_inner">
              <mat-checkbox>Pay via APPTUNIX Wallet</mat-checkbox>
            </div>
          </li>
          <li class="borderbtm">
            <div class="list_inner">
              <mat-checkbox>Pay With Paypal</mat-checkbox>
            </div>
          </li>
          <li>
            <div class="list_inner">
              <mat-checkbox>Pay with Credit/Debit Card</mat-checkbox>
            </div>
          </li>
          <li class="half">
            <div data-toggle="modal" data-target="#add_payment">
              <a>Add new card</a>
            </div>
          </li>
          <li class="borderbtm">
            <mat-radio-group aria-label="Select an option">
              <mat-radio-button value="1">
                <div class="card_detailcs">
                  <h3>•••• •••• •••• 4561<span>Mastercard - 06/23</span></h3>
                </div>
              </mat-radio-button>
            </mat-radio-group>
          </li>
          <li>
            <mat-radio-group aria-label="Select an option">
              <mat-radio-button value="1">
                <div class="card_detailcs">
                  <h3>•••• •••• •••• 4561<span>Visacard - 06/23</span></h3>
                </div>
              </mat-radio-button>
            </mat-radio-group>
          </li>
        </ul>

        <div class="btn_outer">
          <button class="btn btn-primary" (click)="continue('1')">Next</button>
        </div>
      </div>
      <!-- Modal -->
      <div class="modal fade" id="exampleModal " tabindex="-1 " role="dialog " aria-labelledby="exampleModalLabel " aria-hidden="true ">
        <div class="modal-dialog" role="document ">
          <div class="modal-content">
            <div class="">
              <button type="button " class="close" data-dismiss="modal " aria-label="Close ">
                <span aria-hidden="true ">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="add_vehicle">
                <h4>Add vehicle</h4>
                <div class="vehicle_form">
                  <div class="form-group">
                    <mat-form-field [floatLabel]="'never'">
                      <input matInput type="text " placeholder="Vehicle make " name="name " />
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <mat-form-field [floatLabel]="'never'">
                      <input matInput type="text " placeholder="Vehicle Model " name="name " />
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <mat-form-field>
                      <mat-label>Vehicle type</mat-label>
                      <mat-select [(value)]="selected">
                        <mat-option value="option1 ">SUV</mat-option>
                        <mat-option value="option2 ">SUV</mat-option>
                        <mat-option value="option3 ">SUV</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="form-group upload_form_group">
                    <figure class="ipload_img_vehicle">
                      <svg xmlns="http://www.w3.org/2000/svg " width="114.195 " height="97.656 " viewBox="0 0 114.195 97.656 ">
                        <g id="surface1 " transform="translate(0) ">
                          <path
                            id="Path_24238 "
                            data-name="Path 24238 "
                            d="M110.78,160.375H3.416a3.39,3.39,0,0,0-2.583,1.164,3.25,3.25,0,0,0-.8,2.7l7.069,50.644a8.6,8.6,0,0,0,8.431,7.381H98.8a8.581,8.581,0,0,0,8.432-7.409l6.926-50.645a3.343,3.343,0,0,0-.795-2.7A3.461,3.461,0,0,0,110.78,160.375ZM75.267,191.744a2.266,2.266,0,0,1-1.533.6,2.418,2.418,0,0,1-1.675-.71l-12.69-13.655v32.448a2.271,2.271,0,0,1-4.543,0V177.976L42.137,191.6a2.269,2.269,0,1,1-3.321-3.094l16.635-17.857a2.272,2.272,0,0,1,1.675-.709,2.318,2.318,0,0,1,1.675.709l16.635,17.857A2.372,2.372,0,0,1,75.267,191.744Zm0,0 "
                            transform="translate(0 -124.605) "
                            fill="#ccc "
                          />
                          <path
                            id="Path_24239 "
                            data-name="Path 24239 "
                            d="M39.057,31.228h96.152V24.045a8.379,8.379,0,0,0-8.432-8.432H91.263A13.123,13.123,0,0,1,80.078,9.2L77.126,4.173A8.569,8.569,0,0,0,69.83,0H46.58a8.468,8.468,0,0,0-6.161,2.7,8.563,8.563,0,0,0-2.357,6.33Zm0,0 "
                            transform="translate(-29.566) "
                            fill="#ccc "
                          />
                        </g>
                      </svg>
                    </figure>
                    <h5>Upload insurance document</h5>
                    <input type="file " id="file-input " class="upload_file" />
                  </div>
                </div>
                <div class="driverap_btn">
                  <button>Add Now</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- request_modale -->

      <div class="modal fade" id="exampleModall" tabindex="-1 " role="dialog " aria-labelledby="exampleModalLabel " aria-hidden="true ">
        <div class="modal-dialog" role="document ">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button " class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true ">&times;</span>
              </button>
            </div>
            <div class="modal-body second">
              <div class="add_vehicle">
                <h4>Pick a day and time to book a vehicle</h4>
                <div class="vehicle_form">
                  <form [formGroup]="requestForLaterForm">
                    <div class="form-group">
                      <input
                        [owlDateTimeTrigger]="dt"
                        formControlName="dateTime"
                        placeholder="Select Date and Time"
                        [min]="mindate"
                        [owlDateTime]="dt"
                      />
                      <owl-date-time #dt></owl-date-time>
                      <mat-error *ngIf="isDateTimeSubmit && requestForLaterForm.controls['dateTime'].hasError('required')">
                        Please select Date and Time</mat-error
                      >
                    </div>
                  </form>
                </div>
                <div class="driverap_btn">
                  <button (click)="bookRideForLater()">Request For Later</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-----add payment card modal-->
      <!-- Modal -->
      <div class="modal fade" id="add_payment " role="dialog ">
        <div class="modal-dialog">
          <!-- Modal content-->
          <div class="modal-content">
            <div class="modal-header">
              <button type="button " class="close" data-dismiss="modal" aria-label="Close ">+</button>
            </div>
            <div class="modal-body">
              <h3 class="add_card_title">Add a card</h3>
              <div class="card_card_form">
                <div class="add_card_input_itms">
                  <input type="text " placeholder="card holder name " />
                </div>
                <div class="add_card_input_itms">
                  <input type="text " placeholder="XXX XXX XXX " />
                </div>
                <div class="add_card_input_itms">
                  <select>
                    <option>Month</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                  </select>
                </div>
                <div class="add_card_input_itms">
                  <select>
                    <option>year</option>
                    <option>2015</option>
                    <option>2016</option>
                    <option>2017</option>
                    <option>2018</option>
                    <option>2019</option>
                    <option>2020</option>
                  </select>
                </div>
                <div class="add_card_input_itms">
                  <input type="text " placeholder="CCV " />
                </div>
              </div>
            </div>
            <button class="add_card">Save</button>
          </div>
        </div>
      </div>

      <!----start promo card popup-->
    </div>
  </div>
  <div *ngIf="isThisOnlyTrackingPage">
    <h3>hello</h3>
  </div>
</div>

<!-- Modal -->
<div class="modal fade promo_page" id="add_promo" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true ">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h3 class="add_card_title">Add Promo code</h3>
        <div class="enter_promo_code">
          <input class="from-control" type="text " placeholder="Enter Your Promotion Code " />
          <div class="commen_btn_outer">
            <button class="btn btn-primary">Apply</button>
          </div>
        </div>
        <!----start promo code offer-->
        <div class="promo_offer_main">
          <div class="promo_offer_bx">
            <figure>
              <img src="assets/uimages/promo_code_icon.png " alt=" " />
            </figure>
            <h4>
              10% of base rate on your next rental
              <span>Valid to 10/10/2020</span>
            </h4>

            <mat-checkbox></mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!----end promo card popup-->
<!-- Modal -->
<div class="modal fade promo_page" id="add_moniter" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button " class="close" data-dismiss="modal" aria-label="Close">
          <span><i class="fa fa-times" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="monitorForm">
          <h3 class="add_card_title">Monitor your ride</h3>
          <p class="moniter_para">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim.
          </p>
          <div class="monitor_outer">
            <input type="text" class="from-control moniter_input" formControlName="name" placeholder="Name" />
            <mat-error class="monitor_err sec" *ngIf="monitorSubmit && monitorForm.controls['name'].hasError('required')">
              Name is required.
            </mat-error>
            <ngx-intl-tel-input
              [inputId]="'adsasd'"
              [cssClass]="'custom'"
              [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="false"
              [enablePlaceholder]="true"
              [searchCountryFlag]="true"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
              [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.Canada"
              [maxLength]="15"
              [tooltipField]="TooltipLabel.Name"
              [phoneValidation]="true"
              [separateDialCode]="separateDialCode"
              name="phone"
              formControlName="phoneNo"
            >
            </ngx-intl-tel-input>
            <mat-error class="monitor_err" *ngIf="monitorSubmit && monitorForm.controls['phoneNo'].hasError('required')"
              >Phone number is required.</mat-error
            >
            <mat-error class="monitor_err" *ngIf="monitorSubmit && monitorForm.controls['phoneNo'].hasError('validatePhoneNumber')">
              Enter valid number.</mat-error
            >
          </div>
          <div class="share_btn">
            <button>share</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!----end promo card popup-->

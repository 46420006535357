import { Component, OnInit, NgModule, NgZone, OnDestroy, Input, Output, EventEmitter, ViewEncapsulation } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { HttpsService } from "../services/https/https.service";
import { FormControl, FormGroup, FormBuilder, Validators, AbstractControl } from "@angular/forms";
import { MapsAPILoader } from "@agm/core";
import { SearchCountryField, TooltipLabel, CountryISO } from "ngx-intl-tel-input";
import { CommonService } from "../services/common/common.service";
import { environment } from "src/environments/environment";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { BehaviorSubject, interval, Observable, Subject } from "rxjs";
import { SocketioService } from "../services/sockets/socketio.service";
import { Event } from "../core/models/event";
import { Action } from "../core/models/action";
import Swal from "sweetalert2";
import { takeWhile, takeUntil } from "rxjs/operators";
import * as moment from "moment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { element } from "protractor";
import { DatePipe, LocationStrategy, ViewportScroller } from "@angular/common";
import { fConstant } from "../core/constant";
import { HttpClient } from "@angular/common/http";
import { luhnValidator } from "../core/validators/luhnValidator";
import { getValidationConfigFromCardNo } from "../core/helpers/card.helper";
import { IPayPalConfig, ICreateOrderRequest } from "ngx-paypal";
import { isObject } from "rxjs/internal-compatibility";
import { MessagingServiceService } from "src/app/services/messaging-service.service";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "src/app/services/language/language.service";
declare var google: any;
declare var $: any;

@Component({
  selector: "app-riderequest",
  templateUrl: "./riderequest.component.html",
  styleUrls: ["./riderequest.component.scss"],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.Emulated,
})
export class RiderequestFComponent implements OnInit, OnDestroy {
  selected = "label";
  lat: number = 51.678418;
  lng: number = 7.809007;
  public map: any = { lat: 51.678418, lng: 7.809007 };
  nextPopup = 0;
  user: any;
  bookingForm: FormGroup;
  pickup = {
    lat: 0,
    lng: 0,
  };
  pickupAddress = "";
  drop = {
    lat: 0,
    lng: 0,
  };
  current = {
    lat: 0,
    lng: 0,
  };
  dropAddress = "";
  geoCoder: any;
  zoom: number = 12;
  // origin: any;
  // destination: any;
  showPickMarker: boolean = true;
  showDropMarker: boolean = false;
  public renderOptions = {
    suppressMarkers: true,
  };
  originMarkerIcon: string = "assets/uimages/originmarker.png";
  destinationMarkerIcon: string = "assets/uimages/destination.png";
  marker1: string = "assets/markers/marker1.png";
  marker2: string = "assets/markers/marker2.png";
  marker3: string = "assets/markers/marker3.png";
  marker4: string = "assets/markers/marker4.png";
  marker5: string = "assets/markers/marker5.png";
  marker6: string = "assets/markers/marker6.png";
  marker7: string = "assets/markers/marker7.png";
  marker8: string = "assets/markers/marker8.png";
  marker9: string = "assets/markers/marker9.png";
  marker10: string = "assets/markers/marker10.png";
  marker11: string = "assets/markers/marker11.png";
  marker12: string = "assets/markers/marker12.png";
  marker13: string = "assets/markers/marker13.png";
  marker14: string = "assets/markers/marker14.png";
  marker15: string = "assets/markers/marker15.png";
  marker16: string = "assets/markers/marker16.png";
  marker17: string = "assets/markers/marker17.png";
  marker18: string = "assets/markers/marker18.png";
  marker19: string = "assets/markers/marker19.png";
  marker20: string = "assets/markers/marker20.png";
  marker21: string = "assets/markers/marker21.png";
  marker22: string = "assets/markers/marker22.png";
  marker23: string = "assets/markers/marker23.png";
  marker24: string = "assets/markers/marker24.png";
  marker25: string = "assets/markers/marker25.png";
  marker26: string = "assets/markers/marker26.png";
  markerOptions: any;
  // markerOptions = {
  //   origin: {
  //     icon: this.originMarkerIcon,
  //     label: this.pickupAddress,
  //   },
  //   destination: {
  //     icon: this.destinationMarkerIcon,
  //     label: this.dropAddress,
  //   },
  // }
  vehicleCategoryData: any = [];
  distance: any;
  rideSubmited = false;
  origin;
  destination;
  selectedItem = 0;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.Canada];
  driverNoteForm: FormGroup;
  DriverNoteFormSubmitted = false;
  selectedVehicleData: any;
  baseUrl: string;
  requestForLaterForm: FormGroup;
  mindate = new Date();
  bookingFor: string = "Now";
  isDateTimeSubmit: boolean = false;
  activeBtn: string = "Now";
  cancelCounter = 0;
  // timerStart: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  distanceInKM: any;
  duration: any;
  driverAddress: any;
  driverLatLng: { lat: any; lng: any };
  assignedDriverData: any;
  bookingId: any;
  showDirections: boolean = false;
  requestData: any;
  isDropChanged: boolean = false;
  isPickupChanged: boolean = false;
  nearByDriversData: any = [];
  bookingTimeInH: any;
  ubericon: string = "assets/images/ic_car.svg";
  isTollRoute: boolean = false;
  notifier = new Subject();
  bookingResponseData: any;
  //ratings
  @Input("rating") public rating: number = 0;
  @Input("starCount") public starCount: number = 5;
  @Input("color") private color: string = "accent";
  @Output() private ratingUpdated = new EventEmitter();
  reviewData = [
    { name: "Excellent", isActive: false },
    { name: "Very Good", isActive: false },
    { name: "Safe Driving", isActive: false },
    { name: "Good", isActive: false },
    { name: "Enjoy Full Ride", isActive: false },
  ];
  dummy: any[] = [
    { amount: 2, isActive: false },
    { amount: 5, isActive: false },
    { amount: 8, isActive: false },
    { amount: 10, isActive: false },
  ];
  selectedTip: number;

  private snackBarDuration: number = 2000;
  public ratingArr = [];
  isDriverFavourite: boolean = false;
  isFeedBackSelected: boolean = false;
  feedBackQue = [{ que: "Was the driver on time?" }, { que: "Did the driver make your ride comfortabel?" }, { que: "Was the ride safe?" }];
  selectedFeedbckQna: any = [];
  wasTheDriverOnTime: FormControl = new FormControl(null, [Validators.required]);
  driverMakeYouComfortabel: FormControl = new FormControl(null, Validators.required);
  wasTheRideSafe: FormControl = new FormControl(null, Validators.required);
  selectedDriverReview: number;
  customRate: FormControl = new FormControl(null);
  selectedTipAmount: any;
  comment: FormControl = new FormControl(null);
  ratingSubmitted: boolean = false;
  selectedReviewData: any = "";
  driverReviewData: any;
  isDriveingStart: boolean = false;
  sportCar = "assets/images/sport-car.svg";
  driverIcon: any;
  isContactDetailHide: boolean = true;
  isThisOnlyTrackingPage: boolean = false;
  monitorForm: FormGroup;
  monitorSubmit: boolean = false;
  review: FormControl = new FormControl("");
  // driverIcon: any;
  intervalSubscription;
  pickupFieldsCount: number = 1;
  dropupFieldsCount: number = 1;
  pickupfieldsArr: any = [{ name: "pickup1", lat: 0, lng: 0, address: "", distance: 0, isAirportRoute: false }];
  dropupfieldsArr: any = [{ name: "dropup1", lat: 0, lng: 0, address: "", distance: 0, isAirportRoute: false }];
  pickupForm: FormGroup;
  dropupForm: FormGroup;
  pickupFormObj: {};
  dropupFormObj: {};
  waypoints = [];
  distancesForPath: any = [];
  calculated: boolean = true;
  calculateSubscription: any;
  directionStatus: any;
  driverMessage: string;
  distanceDuration: any = "1";
  driverDistance: number;
  isDriverLatLngUpdated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isDriverLatLngUpdated = this.isDriverLatLngUpdated$.asObservable();
  currentCountry: any = "";
  display: string = "none";
  cardNumberGroup: FormGroup;
  cardOwnerFirstName: FormControl = new FormControl("", Validators.required);
  cardOwnerLastName: FormControl = new FormControl("");
  expiryMonth: FormControl = new FormControl(null, Validators.required);
  expiryYear: FormControl = new FormControl(null, Validators.required);
  cvv: FormControl = new FormControl(null, Validators.required);
  expiryYears = [];
  cardSubmitted: boolean = false;
  cvvLength: number = 3;
  charCodeArr: any = [
    96, 49, 50, 51, 52, 53, 54, 55, 56, 57, 48, 45, 61, 91, 93, 92, 59, 44, 46, 47, 126, 33, 64, 35, 36, 37, 94, 38, 42, 40, 41, 95, 43, 123, 125,
    124, 58, 34, 60, 62, 63,
  ];
  validateFirstArr = [];
  validateSecondArr = [];
  allSavedCard: any = [];
  defaultCardDetails: any;
  selectedCardDetails: any;
  allSavedCardData: any;
  addCardModal: string = "none";
  isWalletSelected: boolean = false;
  selectedWalletCardId: any;
  addWalletMoneyModal: string = "none";
  addWalletAmount: FormControl = new FormControl("", Validators.required);
  walletMoneySubmitted: boolean = false;
  loyalityModal: string = "none";
  loyalityData: any = [];
  public payPalConfig?: IPayPalConfig;
  monitorRideModal: string = "none";
  carouselConfig = {
    infinite: false,
    arrows: false,
    dots: true,
    slidesToScroll: 1,
    slidesToShow: 3.1,
  };
  totalLoyality: any = "";
  totalDistance: number;
  totalEta: number;
  messagesData = {
    YOUR_DRIVER_IS_ARIVING_IN: "Your Driver is ariving in",
    YOUR_DRIVER_ARRIVED_TO_PICK_UP_RIDER_IN_LOCATION: "Your driver arrived to pick up rider in location",
    YOUR_DRIVER_IS_ARRIVING: "Your driver is arriving",
    MIN: "min",
    YOUR_DRIVER_ARRIVED_TO_PICK_YOU_ALL_UP: "Your driver arrived to pick you all up",
    YOUR_DRIVER_ARRIVED_TO_PICK_YOU_UP: "Your driver arrived to pick you up",
    YOU_STARTED_YOUR_RIDE: "You started your ride",
    YOU_ARRIVED_AT_YOUR_DESTINATION: "You arrived at your destination",
    YOUR_RIDE_HAS_BEEN_CANCELLED_BY_DRIVER: "Your ride has been cancelled by driver",
    OOPS_NO_DRIVER_AVAILABLE_NEARBY_PLEASE_TRY_AGAIN_LATER: "Oops!.. No driver available nearby, please try again later",
    REACH_YOUR_DESTINATION_IN: "Reach your destination in",
    ARRIVED_AT_YOUR_DESTINATION: "Arrived at your destination",
    YOUR_RIDES_HAVE_STARTED: "Your rides have started",
    ARRIVED_AT_DROP_OFF_LOCATION: "Arrived at drop-off location",
    REACH_DROP_OFF_LOCATION: "Reach drop off location",
    ARRIVED_AT_FINAL_DESTINATION: "Arrived at final destination",
    IN: "in",
    YOU_HAVE_INSUFFICIENT_BALANCE_IN_YOUR_WALLET: "You have insufficient balance in your wallet",
    YOUR_PAYMENT_CARD_HAS_BEEN_ADDED_SUCCESSFULLY: "Your payment card has been added successfully",
    U2GO_MONEY: "U2go Money",
    LATER: "Later",
    NOW: "Now",
    COPIED_TO_CLIPBOARD: "Copied to clipboard",
  };
  languageCode: string;
  showLang = false;
  bookingTimeInMin: number;
  updateDriverLatLongToUser: any;
  currentPageA: any = 1;
  previousBtnEnabledA: boolean = false;
  nextBtnEnabledA: boolean = false;
  totalPagesA: any = 1;
  favAddData: any = [];
  selectedAddressIndex: number;
  isResetField: boolean = true;
  addressType: string = "pick";
  favAddPayload: any;
  favAddModal: string = "none";
  favAddressSubmitted: boolean = false;
  canceledAddress: any;
  cancelationMessage: any;
  cancelReasons: any;
  cancelDialog: string = "none";

  constructor(
    private router: Router,
    private http: HttpsService,
    private fb: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private ngxService: NgxUiLoaderService,
    private socketService: SocketioService,
    public commonService: CommonService,
    private snackBar: MatSnackBar,
    private location: LocationStrategy,
    private vps: ViewportScroller,
    public constant: fConstant,
    private https: HttpClient,
    public date: DatePipe,
    public messagingService: MessagingServiceService,
    public translate: TranslateService,
    public languageService: LanguageService
  ) {
    this.bookingTimeInMin = new Date().getMinutes() + 15;
    this.baseUrl = environment.imageUrl;
    if (localStorage.languageCode !== undefined) {
      this.languageCode = localStorage.getItem("languageCode");
    }
    // this.translate.use(this.languageCode);
    // this.languageService.translateLanguage(this.languageCode)
  }

  ngOnInit(): void {
    this.getChange();
    this.languageService.isLangChange.subscribe((isChange: boolean) => {
      if (isChange) {
        this.getChange();
        setTimeout(() => {
          this.refreshBookingStatus();
        }, 1000);
        console.log(this.router.url);
      }
    });
    this.payPalInitConfig("INR", 9.0);
    this.socketService.initSocket();
    this.getCountry().then((stats: any) => {
      console.log("stats", stats);
      if (stats.status === "success") {
        this.createForm(stats.countryCode);
      } else {
        this.createForm("CA");
      }
    });
    if (this.commonService.bookingResponseData) {
      this.nextPopup = 4;
    }
    this.markerOptions = {
      origin: {
        icon: this.originMarkerIcon,
      },
      destination: {
        icon: this.destinationMarkerIcon,
      },
    };
    this.refreshBookingStatus();

    this.calculateSubscription = setInterval(() => {
      this.calculated = false;
      this.updateDriverLocation();
      console.log("lat lng updated");
    }, 30000);
    this.preventBackButton();
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }

    // this.bookingForm = this.fb.group({
    //   ridePickUpAddress: [null, Validators.required],
    //   rideDropAddress: [null, Validators.required]
    // });
    this.driverNoteForm = this.fb.group({
      phoneNo: [""],
      name: [""],
      notes: [""],
    });
    this.monitorForm = this.fb.group({
      name: ["", Validators.required],
      phoneNo: ["", Validators.required],
    });
    this.requestForLaterForm = this.fb.group({
      dateTime: [null, Validators.required],
    });
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
      // this.initializeMapPosition();
    });
    this.getUser();
    // this.commonService.cancelationTimerStart.subscribe((data: any) => {
    //   if (data.isActive) {
    //     if (localStorage.isCancelCounterStopped !== undefined) {
    //       if (localStorage.isCancelCounterStopped === "true") {
    //         this.cancelCounter = 0;
    //         // localStorage.removeItem('isCancelCounterStopped');
    //       } else {
    //         this.startCounter(data.time);
    //       }
    //     } else {
    //       this.startCounter(data.time);
    //     }
    //     if (data.time >= 0) {
    //       this.startCounter(data.time);
    //     }
    //   } else {
    //     this.cancelCounter = 0;
    //   }
    // });

    this.customRate.valueChanges.subscribe((value: any) => {
      this.selectedTip = 10000;
    });
    this.listenSocketEvent();
    this.review.valueChanges.subscribe((value: any) => {
      if (value) {
        this.selectedReviewData = value;
      } else {
        this.selectedReviewData = "";
      }
    });
    this.socketService.onEvent("unreadNotificationCount").subscribe((res: any) => {
      console.log("----------->", res);
      this.messagingService.notificationCount = res.count;
    });
  }

  refreshBookingStatus() {
    if (this.commonService.bookingResponseData && (this.nextPopup == 8 || this.nextPopup == 4 || this.nextPopup == 3)) {
      if (
        this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED ||
        this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.ARRIVED ||
        this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.STARTED ||
        this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.ONGOING ||
        this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.PARTIAL ||
        this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.TRANSIT ||
        (this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.PENDING &&
          !this.commonService.bookingResponseData.isSheduledBooking)
      ) {
        this.bookingResponseData = this.commonService.bookingResponseData;
        console.log("Refrigerator ");
        this.goToBookingStatus(this.commonService.bookingResponseData);
        var obj = {
          bearing: 0,
          bookingId: null,
          distance: 0,
          driverId: this.bookingResponseData.driverId._id,
          isPickUp: true,
          latitude: this.bookingResponseData.driverId.latitude,
          longitude: this.bookingResponseData.driverId.longitude,
          status: 200,
          userId: this.bookingResponseData.userId._id,
        };
        this.updateDriverLatLongToUser = obj;
        this.calculated = false;
        this.updateDriverLocation();
        this.markerOptions.origin.icon = this.iconObject(0);
      }
      // this.commonService.cancelationTimerStart.next({ isActive: false, time: 0 });
      // this.timerStart.next(false);
    }
  }

  selectLang(language) {
    this.languageCode = language.code;
    this.languageService.selectedLang = language;
    this.languageService.translateLanguage(this.languageCode);
    this.showLang = false;
    this.getChange();
    if (this.nextPopup == 8 || this.nextPopup == 4 || this.nextPopup == 3) {
      this.commonService.bookingResponseData && this.goToBookingStatus(this.commonService.bookingResponseData);
    }
  }

  getChange() {
    this.translate
      .get([
        "Excellent",
        "Very Good",
        "Safe Driving",
        "Good",
        "Enjoy Full Ride",
        "Your Driver is ariving in",
        "Your driver arrived to pick up rider in location",
        "Your driver is arriving",
        "min",
        "Your driver arrived to pick you all up",
        "Your driver arrived to pick you up",
        "You started your ride",
        "You arrived at your destination",
        "Your ride has been cancelled by driver",
        "Oops!.. No driver available nearby, please try again later",
        "Reach your destination in",
        "Arrived at your destination",
        "Your rides have started",
        "Arrived at drop-off location",
        "Reach drop off location",
        "Arrived at final destination",
        "in",
        "You have insufficient balance in your wallet",
        "Your payment card has been added successfully",
        "U2go Money",
        "Later",
        "Now",
        "Copied to clipboard",
      ])
      .subscribe((translations) => {
        this.reviewData = [
          { name: translations["Excellent"], isActive: false },
          { name: translations["Very Good"], isActive: false },
          { name: translations["Safe Driving"], isActive: false },
          { name: translations["Good"], isActive: false },
          { name: translations["Enjoy Full Ride"], isActive: false },
        ];
        this.messagesData.YOUR_DRIVER_IS_ARIVING_IN = translations["Your Driver is ariving in"];
        this.messagesData.YOUR_DRIVER_ARRIVED_TO_PICK_UP_RIDER_IN_LOCATION = translations["Your driver arrived to pick up rider in location"];
        this.messagesData.YOUR_DRIVER_IS_ARRIVING = translations["Your driver is arriving"];
        this.messagesData.MIN = translations["min"];
        this.messagesData.YOUR_DRIVER_ARRIVED_TO_PICK_YOU_ALL_UP = translations["Your driver arrived to pick you all up"];
        this.messagesData.YOUR_DRIVER_ARRIVED_TO_PICK_YOU_UP = translations["Your driver arrived to pick you up"];
        this.messagesData.YOU_STARTED_YOUR_RIDE = translations["You started your ride"];
        this.messagesData.YOU_ARRIVED_AT_YOUR_DESTINATION = translations["You arrived at your destination"];
        this.messagesData.YOUR_RIDE_HAS_BEEN_CANCELLED_BY_DRIVER = translations["Your ride has been cancelled by driver"];
        this.messagesData.OOPS_NO_DRIVER_AVAILABLE_NEARBY_PLEASE_TRY_AGAIN_LATER =
          translations["Oops!.. No driver available nearby, please try again later"];
        this.messagesData.REACH_YOUR_DESTINATION_IN = translations["Reach your destination in"];
        this.messagesData.ARRIVED_AT_YOUR_DESTINATION = translations["Arrived at your destination"];
        this.messagesData.YOUR_RIDES_HAVE_STARTED = translations["Your rides have started"];
        this.messagesData.ARRIVED_AT_DROP_OFF_LOCATION = translations["Arrived at drop-off location"];
        this.messagesData.REACH_DROP_OFF_LOCATION = translations["Reach drop off location"];
        this.messagesData.ARRIVED_AT_FINAL_DESTINATION = translations["Arrived at final destination"];
        this.messagesData.IN = translations["in"];
        this.messagesData.YOU_HAVE_INSUFFICIENT_BALANCE_IN_YOUR_WALLET = translations["You have insufficient balance in your wallet"];
        this.messagesData.YOUR_PAYMENT_CARD_HAS_BEEN_ADDED_SUCCESSFULLY = translations["Your payment card has been added successfully"];
        this.messagesData.U2GO_MONEY = translations["U2go Money"];
        this.messagesData.LATER = translations["Later"];
        this.messagesData.NOW = translations["Now"];
        this.messagesData.COPIED_TO_CLIPBOARD = translations["Copied to clipboard"];
      });
  }

  startCounter(time) {
    console.log("startCounter------->", time);
    this.commonService.cancelCounter = time;
    if (this.intervalSubscription) {
      clearInterval(this.intervalSubscription);
      this.commonService.cancelCounter = time;
    }
    this.intervalSubscription = setInterval(() => {
      if (this.commonService.cancelCounter <= 0) {
        localStorage.isCancelCounterStopped = true;
        clearInterval(this.intervalSubscription);
      }
      this.commonService.cancelCounter--;
    }, 1000);
  }

  getCountry() {
    return new Promise((resolve, reject) => {
      this.https.get("https://extreme-ip-lookup.com/json/").subscribe((location: any) => {
        console.log("location", location);
        this.currentCountry = location.countryCode;
        return resolve(location);
      });
    });
  }

  createForm(country) {
    this.pickupfieldsArr = [{ name: "pickup1", lat: 0, lng: 0, address: "", distance: 0, country: country }];
    this.dropupfieldsArr = [{ name: "dropup1", lat: 0, lng: 0, address: "", distance: 0, country: country }];
    console.log(this.dropupfieldsArr);
    this.pickupFormObj = {};
    this.pickupfieldsArr.forEach((element) => {
      let key = element.name;
      this.pickupFormObj[key] = new FormControl("", Validators.required);
    });
    this.pickupForm = this.fb.group(this.pickupFormObj);
    this.pickupForm.get("pickup1").valueChanges.subscribe((val) => {
      if (val === "") {
        this.pickupfieldsArr[0].lat = 0;
        this.pickupfieldsArr[0].lng = 0;
        this.pickupfieldsArr[0].address = "";
        this.pickupfieldsArr[0].distance = 0;
      }
    });
    this.dropupFormObj = {};
    this.dropupfieldsArr.forEach((element) => {
      let key = element.name;
      this.dropupFormObj[key] = new FormControl("", Validators.required);
    });
    this.dropupForm = this.fb.group(this.dropupFormObj);

    if (this.commonService.origin && this.commonService.destination) {
      if (this.commonService.origin.lat !== 0 && this.commonService.destination.lat !== 0) {
        this.pickupForm.get("pickup1").patchValue(this.commonService.origin.address);
        this.dropupForm.get("dropup1").patchValue(this.commonService.destination.address);
        this.pickupfieldsArr[0].lat = this.commonService.origin.lat;
        this.pickupfieldsArr[0].lng = this.commonService.origin.lng;
        this.dropupfieldsArr[0].lat = this.commonService.destination.lat;
        this.dropupfieldsArr[0].lng = this.commonService.destination.lng;
        this.pickupfieldsArr[0].address = this.commonService.origin.address;
        this.dropupfieldsArr[0].address = this.commonService.destination.address;
        this.drawPath(this.commonService.origin, this.commonService.destination, this.marker1);
        this.directionStatus = "OK";
      }
    } else {
      this.getCurrentLocation();
    }
    this.cardNumberGroup = new FormGroup({
      cardNumber: new FormControl("", [Validators.required, Validators.minLength(12), luhnValidator()]),
    });
    //Determine the Current Year.
    var currentYear = new Date().getFullYear();
    for (var i = currentYear; i <= currentYear + 15; i++) {
      this.expiryYears.push(i.toString().slice(2));
    }
    // this.cvv.valueChanges.subscribe((value: any) => {
    //   if (value) {
    //     if (value.length !== this.cvvLength) {
    //       return false;
    //     }
    //   }
    // })
    this.removeDriverFromMap();
    this.getAllCard();
  }

  preventBackButton() {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  getNearbyDrivers(position) {
    var param = {
      latitude: position.lat,
      longitude: position.lng,
      pageNo: 1,
    };
    this.http
      .httpPostWithHeaderUser("getNearbyDriversWithGeoFenceId", param)
      .pipe(takeUntil(this.notifier))
      .pipe(takeUntil(this.notifier))
      .subscribe((res: any) => {
        if (res.statusCode === 200) {
          this.nearByDriversData = res.data.drivers;
        }
      });
  }

  getUser() {
    this.http
      .httpGetWithHeaderUser("userProfile", "")
      .pipe(takeUntil(this.notifier))
      .pipe(takeUntil(this.notifier))
      .subscribe((res: any) => {
        if (res.statusCode === 200) {
          this.user = res.data;
          if (res.data.role === "DELIVERY_PARTNER") {
            this.commonService.isThisDeliveryPartner = true;
            // this.router.navigate(['/riderdeliverybooking']);
          } else {
            this.commonService.isThisDeliveryPartner = false;
          }
        }
      });
  }

  initializeMapPosition() {
    this.commonService.getPosition().then((position) => {
      this.pickup = position;
      // this.getPickupAddress(this.pickup);
    });
    // this.currentPosition = this.commonService.getLocalStorageItemParsed(Key.CURRENT_POSITION);
    // if (this.currentPosition) {
    //   this.pickup = this.currentPosition;
    //   this.emitNearByDrivers(this.currentPosition);
    // }
  }

  getCurrentLocation() {
    console.log("current location");
    if (this.pickupfieldsArr[0].lat == 0 && this.pickupfieldsArr[0].lng == 0) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            if (this.pickupfieldsArr[0].lat == 0 && this.pickupfieldsArr[0].lng == 0) {
              this.pickupfieldsArr[0].lat = position.coords.latitude;
              this.pickupfieldsArr[0].lng = position.coords.longitude;
              this.pickup.lat = position.coords.latitude;
              this.pickup.lng = position.coords.longitude;
              this.current.lat = position.coords.latitude;
              this.current.lng = position.coords.longitude;
              this.zoom = 15;
              this.getPickupAddress({ lat: position.coords.latitude, lng: position.coords.longitude }, this.pickupfieldsArr[0], "");
              this.getNearbyDrivers({ lat: position.coords.latitude, lng: position.coords.longitude });
            }
          },
          (err) => {
            console.log(err);
          }
        );
      }
    }
  }

  onMapReady(map: any) {
    this.map = map;
    if (!this.commonService.origin && !this.commonService.destination) {
      this.getCurrentLocation();
    }
    if (map) {
      map.setOptions({
        streetViewControl: false,
      });
    }
  }

  markerDragEndOrigin(event: any, data) {
    if (event.latLng) {
      data.lat = event.latLng.lat();
      data.lng = event.latLng.lng();
      this.getPickupAddress({ lat: data.lat, lng: data.lng }, data, "");
      // this.getNearbyDrivers(this.pickup);
    } else if (event.coords) {
      data.lat = event.coords.lat;
      data.lng = event.coords.lng;
      this.getPickupAddress({ lat: data.lat, lng: data.lng }, data, "");
      // this.getPickupAddress(this.pickup);
      // this.getNearbyDrivers(this.pickup);
    }
  }
  markerDragEndDestination(event: any, data) {
    if (event.latLng) {
      data.lat = event.latLng.lat();
      data.lng = event.latLng.lng();
      this.getAddressDrop({ lat: data.lat, lng: data.lng }, data, "");
    } else if (event.coords) {
      data.lat = event.coords.lat;
      data.lng = event.coords.lng;
      // this.drop.lat = this.dropupfieldsArr[]
      this.getAddressDrop({ lat: data.lat, lng: data.lng }, data, "");
    }
  }

  handlePickUpChnage(address, data, favData: any = null) {
    data = favData ? this.pickupfieldsArr[this.selectedAddressIndex] : data;
    this.isPickupChanged = true;
    if (address.formatted_address || favData) {
      this.pickup.lat = 0;
      this.pickup.lng = 0;
      this.pickupAddress = favData ? favData.address : address.formatted_address;
      var lat = favData ? favData.latitude : address.geometry.location.lat();
      var lng = favData ? favData.longitude : address.geometry.location.lng();
      console.log("pick latLng", lat, lng);
      !favData &&
        this.pickupfieldsArr.forEach((element) => {
          if (element.name === data.name) {
            element.lat = lat;
            element.lng = lng;
          }
        });
      favData && (this.pickupfieldsArr[this.selectedAddressIndex].lat = lat);
      favData && (this.pickupfieldsArr[this.selectedAddressIndex].lng = lng);
      this.pickup.lat = lat;
      this.pickup.lng = lng;
      // this.getPickupAddress(this.pickup,data,address.formatted_address);
      // this.getNearbyDrivers(this.pickup);
      data.address = favData ? favData.address : address.formatted_address;
      this.pickupForm.get(data.name).patchValue(this.pickupAddress);
      this.matrix({ lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, { lat: lat, lng: lng }, data);
      if (address.types) {
        if (address.types.includes("airport")) {
          data.isAirportRoute = true;
        } else {
          data.isAirportRoute = false;
        }
      }
      console.log(this.pickupfieldsArr);
    }
  }

  handleDropChnage(address, data, favData: any = null) {
    console.log(address);
    data = favData ? this.dropupfieldsArr[this.selectedAddressIndex] : data;
    this.drop.lat = 0;
    this.drop.lng = 0;
    if (address.formatted_address || favData) {
      this.dropAddress = favData ? favData.address : address.formatted_address;
      var lat = favData ? favData.latitude : address.geometry.location.lat();
      var lng = favData ? favData.longitude : address.geometry.location.lng();
      console.log("Droplatlng", lat, lng);
      this.dropupfieldsArr.forEach((element) => {
        if (element.name === data.name) {
          element.lat = lat;
          element.lng = lng;
        }
      });
      favData && (this.dropupfieldsArr[this.selectedAddressIndex].lat = lat);
      favData && (this.dropupfieldsArr[this.selectedAddressIndex].lng = lng);
      var droplength = this.dropupfieldsArr.length;
      this.drop.lat = lat;
      this.drop.lng = lng;
      this.showDropMarker = true;
      // this.getDirection();
      // this.getDistanceMatrix(this.dropAddress, {lat: lat, lng: lng});
      this.matrix({ lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, { lat: this.drop.lat, lng: this.drop.lng }, data);
      // this.getAddressDrop(this.drop, data,address.formatted_address);
      data.address = favData ? favData.address : address.formatted_address;
      this.dropupForm.controls[data.name].patchValue(favData ? favData.address : address.formatted_address);
    }
    if (address.types) {
      if (address.types.includes("airport")) {
        data.isAirportRoute = true;
      } else {
        data.isAirportRoute = false;
      }
    }
  }

  dropKeyup(drop, value) {
    if (value === "") {
      drop.lat = 0;
      drop.lng = 0;
      drop.address = "";
      drop.distance = 0;
    }
  }

  dropChange(drop, value) {
    if (drop.lat === 0 && drop.lng === 0) {
      this.dropupForm.get(drop.name).patchValue(null);
      this.rideSubmited = true;
    } else if (drop.address !== value) {
      this.dropupForm.get(drop.name).patchValue(null);
      this.rideSubmited = true;
    }
  }

  pickeyUp(pickup, value) {
    if (value === "") {
      pickup.lat = 0;
      pickup.lng = 0;
      pickup.address = "";
      pickup.distance = 0;
    }
  }

  pickupChange(data, value) {
    if (data.lat === 0 && data.lng === 0) {
      this.pickupForm.get(data.name).patchValue(null);
      this.rideSubmited = true;
    } else if (data.address !== value) {
      this.pickupForm.get(data.name).patchValue(null);
      this.rideSubmited = true;
    }
  }

  getDirection() {
    if (this.pickup.lng !== null && this.pickup.lng !== null && this.drop.lat !== null && this.drop.lng !== null) {
      this.origin = { lat: this.pickup.lat, lng: this.pickup.lng };
      this.destination = { lat: this.drop.lat, lng: this.drop.lng };
      this.showDirections = true;
      this.showPickMarker = false;
      this.showDropMarker = false;
      this.calculateDistance();
    }
    this.showPickMarker = false;
    this.showDropMarker = false;
  }

  calculateDistance() {
    const start = new google.maps.LatLng(this.pickupfieldsArr[0].lat, this.pickupfieldsArr[0].lng);
    const end = new google.maps.LatLng(
      this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat,
      this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng
    );
    this.distance = google.maps.geometry.spherical.computeDistanceBetween(start, end); // distance in Meter
    return this.distance.toFixed(0);
  }

  getFare(pricePerMiles) {
    var d = this.distance / 1.609; //convert KM into MILES
    var fare = d * pricePerMiles;
    return Math.round(fare);
  }

  getPickupAddress(latLng, data, formatted_address) {
    this.geoCoder.geocode({ location: { lat: latLng.lat, lng: latLng.lng } }, (results, status) => {
      if (status === "OK" && results.length > 0) {
        var isAddSimilar = false;
        results.forEach((addresses) => {
          if (addresses.formatted_address === formatted_address && !isAddSimilar) {
            isAddSimilar = true;
            this.pickupForm.controls[data.name].patchValue(addresses.formatted_address);
            data.address = addresses.formatted_address;
            this.pickupAddress = addresses.formatted_address;
            return false;
          }
        });
        if (!isAddSimilar) {
          this.pickupForm.controls[data.name].patchValue(results[0].formatted_address);
          data.address = results[0].formatted_address;
          this.pickupAddress = results[0].formatted_address;
        }
        results[0].address_components.forEach((element) => {
          if (element.types.includes("country")) {
            this.currentCountry = element.short_name;
          }
        });
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }
    });
    // this.checkstatusMap();
  }

  getAddressDrop(latLng, data, formatted_address) {
    this.geoCoder.geocode({ location: { lat: latLng.lat, lng: latLng.lng } }, (results, status) => {
      console.log(results, status);
      if (status === "OK" && results.length > 0) {
        var isAddSimilar = false;
        results.forEach((addresses) => {
          if (addresses.formatted_address === formatted_address && !isAddSimilar) {
            isAddSimilar = true;
            console.log("exist");
            this.dropupForm.controls[data.name].patchValue(addresses.formatted_address);
            return false;
          }
        });
        if (!isAddSimilar) {
          console.log("not-exist");
          this.dropupForm.controls[data.name].patchValue(results[0].formatted_address);
        }
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  allowNewBooking() {
    return new Promise((resolve, reject) => {
      this.http.httpGetWithHeaderOnly("allowNewBooking").subscribe((response: any) => {
        if (response.statusCode == 200) {
          return resolve(true);
        } else {
          // Swal.fire({
          //   title: response.message,
          //   text: "",
          //   icon: "info",
          //   showCancelButton: false,
          //   showConfirmButton: false,
          //   confirmButtonColor: "#3085d6",
          //   cancelButtonColor: "#d33",
          //   confirmButtonText: "GO TO MY RIDES",
          //   cancelButtonText: "NO",
          //   allowOutsideClick: true,
          // }).then((result) => {
          //   if (result.value) {
          //     this.router.navigate(["/profilesetup/1"]);
          //   }
          // });
        }
      });
    });
  }

  bookRideForNow(type) {
    this.isResetField = true;
    type === "Now" &&
      this.allowNewBooking().then((result: any) => {
        if (result) {
          this.createRide(type);
        }
      });
    type === "Later" && this.createRide(type);
  }

  createRide(type) {
    this.rideSubmited = true;
    this.checkFormFields();
    if (this.pickupForm.valid && this.dropupForm.valid) {
      this.originPathOptimization();
      this.destinationPathOptimization();
      this.pickupfieldsArr.sort((a, b) => {
        return a.distance - b.distance;
      });
      this.dropupfieldsArr.sort((a, b) => {
        return a.distance - b.distance;
      });
      // this.drawPath(this.pickupfieldsArr[0], this.dropupfieldsArr[this.dropupfieldsArr.length - 1], this.marker1);
      // this.commonService.preLaunchAlert(this.constant.MSGS.PRELAUNCH_RIDE);
      this.bookingFor = type;
      var date = new Date();
      var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
      var offset = date.getTimezoneOffset() / 60;
      this.bookingTimeInH = date.getHours();
      // this.matrix({ lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, { lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng }, null)
      //   .then((value: any) => {
      //     if (value.duration) {
      // this.duration = value.duration;
      this.getVehicle();
      this.getAllCard();
      //   }
      // })
    }
  }

  checkFormFields() {
    for (let i = 0; i < this.pickupfieldsArr.length; i++) {
      if (this.pickupfieldsArr[i].lat === 0 && this.pickupfieldsArr[i].lng === 0) {
        if (this.pickupfieldsArr.length === 1) {
          this.pickupfieldsArr[i].address = "";
          this.pickupForm.get(this.pickupfieldsArr[i].name).patchValue(null);
        } else {
          this.removePickupField(i);
        }
      }
    }
    for (let i = 0; i < this.dropupfieldsArr.length; i++) {
      if (this.dropupfieldsArr[i].lat === 0 && this.dropupfieldsArr[i].lng === 0) {
        if (this.dropupfieldsArr.length === 1) {
          this.dropupfieldsArr[i].address = "";
          this.dropupForm.get(this.dropupfieldsArr[i].name).patchValue(null);
        } else {
          this.removeDropupField(i);
        }
      }
    }
  }

  getVehicle() {
    this.rideSubmited = true;
    this.activeBtn = "Now";
    this.getUser();
    if (this.pickupForm.valid && this.dropupForm.valid && this.directionStatus === "OK") {
      // this.originPathOptimization();
      // this.destinationPathOptimization();
      this.ngxService.start();
      // this.pickupfieldsArr.sort((a, b) => {
      //   return a.distance - b.distance;
      // });
      // this.dropupfieldsArr.sort((a, b) => {
      //   return a.distance - b.distance;
      // });
      // console.log('ok',this.pickupfieldsArr,this.dropupfieldsArr);
      setTimeout(() => {
        this.getTotalEtaAndDistance().then((total: any) => {
          console.log("Total", this.totalDistance, this.totalEta);
          this.distance = this.totalDistance;
          this.duration = this.totalEta;
          console.log("distance", this.distance);
          console.log("duration", parseFloat(this.duration.toFixed(2)));
          var param = {
            pageNo: 1,
            latitude: this.pickupfieldsArr[0].lat,
            longitude: this.pickupfieldsArr[0].lng,
          };
          this.ngxService.stop();
          this.http
            .httpPostWithHeaderUser("getVehicleCategoryInGeofence", param)
            .pipe(takeUntil(this.notifier))
            .subscribe((res: any) => {
              if (res.statusCode == 200) {
                this.ngxService.start();
                // this.vehicleCategoryData = res.data.vehicleCategoryData;
                // this.selectedVehicleData = res.data.vehicleCategoryData[0];
                this.driverNoteForm.get("name").patchValue(this.user.firstName);
                this.driverNoteForm.get("phoneNo").patchValue(this.user.phoneNo);
                this.continue(1);
                var arr = [];
                arr = res.data.vehicleCategoryData;
                this.vehicleCategoryData = [];
                var indexElement = 0;
                if (arr.length > 0) {
                  arr.forEach((element) => {
                    indexElement++;
                    var transactionFee = res.data.geofenceData.transactionFee ? res.data.geofenceData.transactionFee : 0;
                    var distancePrice = parseFloat((this.distance * 1 * res.data.geofenceData.pricePerKiloMeter).toFixed(2));
                    var discountDistancePrice; //For discount
                    var mins: number = parseFloat(this.duration.toFixed(2));
                    var priceOfMin = mins * res.data.geofenceData.pricePerMinutes;
                    var discountPriceOfMin; //For discount
                    var basicFee;
                    if (element.baseFareChangePercentage !== 0) {
                      basicFee = res.data.geofenceData.baseFare + res.data.geofenceData.baseFare * (0.01 * element.baseFareChangePercentage);
                    } else {
                      basicFee = res.data.geofenceData.baseFare;
                    }
                    var discountBasicFee; //For discount
                    var otherFee = res.data.geofenceData.otherFee ? res.data.geofenceData.otherFee : 0;
                    var stopesFee = 0;
                    var airpotRoutesCount = 0;
                    airpotRoutesCount += this.pickupfieldsArr.filter((val) => val.isAirportRoute).length;
                    if (this.pickupfieldsArr.length > 1) {
                      var fee = res.data.geofenceData.ridePickUpFee ? res.data.geofenceData.ridePickUpFee : 0;
                      stopesFee = this.pickupfieldsArr.length * fee;
                    }
                    airpotRoutesCount += this.pickupfieldsArr.filter((val) => val.isAirportRoute).length;
                    if (this.dropupfieldsArr.length > 1) {
                      var fee = res.data.geofenceData.rideDropUpFee ? res.data.geofenceData.rideDropUpFee : 0;
                      stopesFee = this.dropupfieldsArr.length * fee;
                    }
                    var airFee = 0;
                    for (let i = 1; i <= airpotRoutesCount; i++) {
                      airFee += res.data.geofenceData.airpotFess ? res.data.geofenceData.airpotFess : 0;
                    }
                    //This condition is for discount calculation
                    if (res.data.discountData.length > 0) {
                      if (res.data.discountData[0].applyTo.includes("USER")) {
                        if (res.data.discountData[0].discountData.keys[0] === this.constant.DISCOUNT_KEYS.BASE_FARE) {
                          if (res.data.discountData[0].discountData.discountType === this.constant.DISCOUNT_TYPES.FLAT) {
                            if (basicFee < res.data.discountData[0].discountData.discountValue) {
                              discountBasicFee = 0;
                            } else {
                              discountBasicFee = basicFee - res.data.discountData[0].discountData.discountValue;
                            }
                          } else {
                            let d = (basicFee * res.data.discountData[0].discountData.discountValue) / 100;
                            if (basicFee < d) {
                              discountBasicFee = 0;
                            } else {
                              discountBasicFee = basicFee - d;
                            }
                          }
                        } else if (res.data.discountData[0].discountData.keys[0] === this.constant.DISCOUNT_KEYS.PER_KM) {
                          if (res.data.discountData[0].discountData.discountType === this.constant.DISCOUNT_TYPES.FLAT) {
                            if (distancePrice < res.data.discountData[0].discountData.discountValue) {
                              discountDistancePrice = 0;
                            } else {
                              discountDistancePrice = distancePrice - res.data.discountData[0].discountData.discountValue;
                            }
                          } else {
                            let d = (distancePrice * res.data.discountData[0].discountData.discountValue) / 100;
                            discountDistancePrice = distancePrice - d;
                          }
                        } else if (res.data.discountData[0].discountData.keys[0] === this.constant.DISCOUNT_KEYS.PER_MIN) {
                          if (res.data.discountData[0].discountData.discountType === this.constant.DISCOUNT_TYPES.FLAT) {
                            if (priceOfMin < res.data.discountData[0].discountData.discountValue) {
                              discountPriceOfMin = 0;
                            } else {
                              discountPriceOfMin = priceOfMin - res.data.discountData[0].discountData.discountValue;
                            }
                          } else {
                            let d = (priceOfMin * res.data.discountData[0].discountData.discountValue) / 100;
                            discountPriceOfMin = priceOfMin - d;
                          }
                        }
                      }
                    }

                    var totalWithoutTax = parseFloat((distancePrice + priceOfMin + airFee + basicFee + otherFee + stopesFee).toFixed(2));
                    var totalWithoutTaxDiscount; //For discount
                    //If any one of discount is applied
                    if (discountDistancePrice !== undefined || discountPriceOfMin !== undefined || discountBasicFee !== undefined) {
                      let dPrice = discountDistancePrice !== undefined ? discountDistancePrice : distancePrice; //For discount
                      let dMin = discountPriceOfMin !== undefined ? discountPriceOfMin : priceOfMin; //For discount
                      let dBasicFee = discountBasicFee !== undefined ? discountBasicFee : basicFee; //For discount
                      totalWithoutTaxDiscount = parseFloat((dPrice + dMin + airFee + dBasicFee + otherFee + stopesFee).toFixed(2)); //For discount
                    }

                    var taxPercentage = res.data.geofenceData.taxPercentage ? res.data.geofenceData.taxPercentage : 0;
                    var tax = parseFloat(((totalWithoutTax * taxPercentage) / 100).toFixed(2));
                    var dtax;
                    totalWithoutTaxDiscount && (dtax = parseFloat(((totalWithoutTaxDiscount * taxPercentage) / 100).toFixed(2))); //For discount
                    var totalWithTax = totalWithoutTax + tax;
                    var totalWithTaxDiscount;
                    dtax && (totalWithTaxDiscount = totalWithoutTaxDiscount + dtax); //For discount
                    var isInTimeSlotPrice = false;
                    var timeSlotAmount = 0;
                    var timeOfDayPercentage = 0;
                    var date = new Date();
                    // var time = date.getTime();
                    var hourse = date.getHours();
                    var minutes: any = date.getMinutes();
                    var time;
                    if (this.digits_count(minutes) === 1) {
                      minutes = "0" + JSON.stringify(minutes);
                      time = JSON.parse(JSON.stringify(hourse) + minutes);
                    } else {
                      time = parseFloat(JSON.stringify(hourse) + JSON.stringify(minutes));
                    }
                    for (let i in res.data.geofenceData.slotTime) {
                      var data = res.data.geofenceData.slotTime[i];
                      if (data.startTime < time && data.endTime > time) {
                        isInTimeSlotPrice = true;
                        timeOfDayPercentage = data.timeOfDayPercentage;
                      }
                    }
                    if (isInTimeSlotPrice) {
                      timeSlotAmount = totalWithTax * (timeOfDayPercentage * 0.01);
                      var timeSlotAmountDiscount;
                      totalWithTaxDiscount && (timeSlotAmountDiscount = totalWithTaxDiscount * (timeOfDayPercentage * 0.01));
                    }
                    var totalWithTimeSlot = totalWithTax + timeSlotAmount;
                    var totalWithTimeSlotDiscount;
                    totalWithTaxDiscount && timeSlotAmountDiscount && (totalWithTimeSlotDiscount = totalWithTaxDiscount + timeSlotAmountDiscount); //For discount
                    totalWithTaxDiscount && !timeSlotAmountDiscount && (totalWithTimeSlotDiscount = totalWithTaxDiscount + timeSlotAmount); //For discount
                    !totalWithTaxDiscount && timeSlotAmountDiscount && (totalWithTimeSlot = totalWithTaxDiscount + timeSlotAmountDiscount); //For discount
                    !totalWithTaxDiscount && !timeSlotAmountDiscount && (totalWithTimeSlot = totalWithTax + timeSlotAmount); //For discount
                    var booKingDriverAmount = (totalWithTimeSlot * 75) / 100;
                    // /for discount of final fare
                    if (res.data.discountData.length > 0) {
                      if (res.data.discountData[0].applyTo.includes("USER")) {
                        if (res.data.discountData[0].discountData.keys[0] === this.constant.DISCOUNT_KEYS.FINAL_FARE) {
                          if (res.data.discountData[0].discountData.discountType === this.constant.DISCOUNT_TYPES.FLAT) {
                            if (!totalWithTaxDiscount) {
                              if (totalWithTimeSlot < res.data.discountData[0].discountData.discountValue) {
                                totalWithTimeSlotDiscount = 0;
                              } else {
                                totalWithTimeSlotDiscount = totalWithTimeSlot - res.data.discountData[0].discountData.discountValue;
                              }
                            } else {
                              if (totalWithTimeSlot < res.data.discountData[0].discountData.discountValue) {
                                totalWithTimeSlotDiscount = 0;
                              } else {
                                totalWithTimeSlotDiscount = totalWithTimeSlot - res.data.discountData[0].discountData.discountValue;
                              }
                            }
                          } else {
                            if (!totalWithTaxDiscount) {
                              let d = (totalWithTimeSlot * res.data.discountData[0].discountData.discountValue) / 100;
                              totalWithTimeSlotDiscount = totalWithTimeSlot - d;
                            } else {
                              let d = (totalWithTimeSlot * res.data.discountData[0].discountData.discountValue) / 100;
                              totalWithTimeSlotDiscount = totalWithTimeSlot - d;
                            }
                          }
                        }
                      }
                    }

                    totalWithTimeSlotDiscount !== undefined &&
                      (element["discount"] = this.commonService.currencyFormat(totalWithTimeSlotDiscount.toFixed(2))); //For discount
                    element["booKingAmount"] = this.commonService.currencyFormat(totalWithTimeSlot.toFixed(2));
                    element["booKingDriverAmount"] = this.commonService.currencyFormat(booKingDriverAmount.toFixed(2));
                    if (indexElement === 2) {
                      let secondElement = JSON.parse(JSON.stringify(element));
                      secondElement["id"] = null;
                      secondElement["vehicleCategory"] = "Any";
                      secondElement["vehicleImage"] = "assets/uimages/logo.png";
                      secondElement["booKingAmount"] = null;
                      secondElement["booKingDriverAmount"] = null;
                      this.vehicleCategoryData.push(secondElement);
                      // this.vehicleCategoryData.splice(0, 0, secondElement)
                      element["vehicleImage"] = this.baseUrl + element.vehicleImage;
                      this.vehicleCategoryData.push(element);
                      var temp = this.vehicleCategoryData[0];
                      this.vehicleCategoryData[0] = this.vehicleCategoryData[1];
                      this.vehicleCategoryData[1] = temp;
                    } else {
                      element["vehicleImage"] = this.baseUrl + element.vehicleImage;
                      this.vehicleCategoryData.push(element);
                    }
                  });
                }
                this.selectedVehicleData = this.vehicleCategoryData[0];
                this.ngxService.stop();
                this.pickupfieldsArr.sort((a, b) => {
                  return a.distance - b.distance;
                });
                this.dropupfieldsArr.sort((a, b) => {
                  return a.distance - b.distance;
                });
                this.drawPath(this.pickupfieldsArr[0], this.dropupfieldsArr[this.dropupfieldsArr.length - 1], this.marker1);
              }
            });
        });
      }, 1000);

      // this.calculateDistanceMatrix({ lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, { lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng }, null);
      // this.matrix({ lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, { lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng }, null)
      //   .then((value: any) => {
      //     if (value.duration) {
      // this.distance = value.distance;

      //   }
      // })
    }
  }

  digits_count(n) {
    var count = 0;
    if (n >= 1) ++count;

    while (n / 10 >= 1) {
      n /= 10;
      ++count;
    }

    return count;
  }

  selectVehicle(vehicle, index) {
    this.selectedVehicleData = vehicle;
    this.selectedItem = index;
  }

  CreateRideBooking(poupType) {
    if ((this.selectedCardDetails || this.isWalletSelected) && this.vehicleCategoryData.length > 0) {
      let amount =
        this.selectedVehicleData.booKingAmount != null
          ? this.selectedVehicleData.booKingAmount.includes("$")
            ? parseFloat(this.selectedVehicleData.booKingAmount.split("$")[1].replace(",", ""))
            : this.selectedVehicleData.booKingAmount
          : 0;
      if (this.isWalletSelected && !(amount < this.allSavedCardData.walletAmount)) {
        this.continue(2);
        this.commonService.OpenNotificationModal("error", "top-end", this.messagesData.YOU_HAVE_INSUFFICIENT_BALANCE_IN_YOUR_WALLET);
        return false;
      }
      var date = moment(this.requestForLaterForm.value.dateTime).format("YYYY-MM-DD");
      var time = moment(this.requestForLaterForm.value.dateTime).format("HH:mm:ss");
      var dateTime = date + "T" + time + "Z";
      this.ngxService.start();
      var d = new Date();
      var timeZone = d.getTimezoneOffset();
      if (Math.sign(timeZone) === 1) {
        timeZone = -Math.abs(timeZone);
      } else {
        timeZone = Math.abs(timeZone);
      }
      var param: any;
      var newParam: any;
      var latLngsArr = [];
      this.pickupfieldsArr.forEach((element) => {
        latLngsArr.push({
          address: element.address,
          latitude: element.lat,
          longitude: element.lng,
          isPickUp: true,
        });
      });
      this.dropupfieldsArr.forEach((element) => {
        latLngsArr.push({
          address: element.address,
          latitude: element.lat,
          longitude: element.lng,
          isPickUp: false,
        });
      });
      if (this.driverNoteForm.value.notes != null) {
        newParam = $.extend(
          {},
          {
            vehicleCategoryId: this.selectedVehicleData.id,
            totalDistance: this.distance * 1000,
            eta: parseFloat(this.duration.toFixed(2)),
            paymentMode: this.isWalletSelected ? this.constant?.PAYMENT_MODE?.WALLET : this.constant.PAYMENT_MODE?.CARD,
            cardId: !this.isWalletSelected ? this.selectedCardDetails.id : undefined,
            name: this.driverNoteForm.value.name != "" ? this.driverNoteForm.value.name : undefined,
            phoneNo: this.driverNoteForm.value.phoneNo != "" ? this.driverNoteForm.value.phoneNo : undefined,
            additionalInfo: this.driverNoteForm.value.notes != "" ? this.driverNoteForm.value.notes : undefined,
            note: this.driverNoteForm.value.notes != "" ? this.driverNoteForm.value.notes : undefined,
            pickUpDropUp: latLngsArr,
            timezone: timeZone,
            loyalityId: this.selectedVehicleData.loyalityId ? this.selectedVehicleData.loyalityId : undefined,
            bookingDate: this.bookingFor === "Later" ? this.requestForLaterForm.value.dateTime : undefined,
            // "booKingAmount": this.selectedVehicleData.booKingAmount,
            // "booKingDriverAmount": this.selectedVehicleData.booKingDriverAmount.toFixed(2)
          }
        );
      } else if (this.driverNoteForm.value.notes != "") {
        newParam = $.extend(
          {},
          {
            vehicleCategoryId: this.selectedVehicleData.id,
            totalDistance: this.distance * 1000,
            eta: parseFloat(this.duration.toFixed(2)),
            paymentMode: this.isWalletSelected ? this.constant?.PAYMENT_MODE?.WALLET : this.constant.PAYMENT_MODE?.CARD,
            cardId: !this.isWalletSelected ? this.selectedCardDetails.id : undefined,
            name: this.driverNoteForm.value.name != "" ? this.driverNoteForm.value.name : undefined,
            phoneNo: this.driverNoteForm.value.phoneNo != "" ? this.driverNoteForm.value.phoneNo : undefined,
            additionalInfo: this.driverNoteForm.value.notes != null ? this.driverNoteForm.value.notes : undefined,
            note: this.driverNoteForm.value.notes != null ? this.driverNoteForm.value.notes : undefined,
            pickUpDropUp: latLngsArr,
            timezone: timeZone,
            loyalityId: this.selectedVehicleData.loyalityId ? this.selectedVehicleData.loyalityId : undefined,
            bookingDate: this.bookingFor === "Later" ? this.requestForLaterForm.value.dateTime : undefined,
            // "booKingAmount": this.selectedVehicleData.booKingAmount,
            // "booKingDriverAmount": this.selectedVehicleData.booKingDriverAmount.toFixed(2)
          }
        );
      }
      if (this.bookingFor === "Later") {
        if (!this.getTimeForLater()) {
          return;
        }
      }
      this.http
        .httpPostWithHeaderUser("createRideBooking", newParam)
        .pipe(takeUntil(this.notifier))
        .subscribe((res: any) => {
          if (res.statusCode === 200) {
            this.bookingId = res.data.bookingId;
            // this.commonService.OpenNotificationModal('success', 'top-end', res.message);
            if (this.bookingFor === "Now") {
              this.continue(3);
              this.listenSocketEvent();
            } else {
              this.continue(0);
              this.listenSocketEvent();
            }
          } else {
            // Swal.fire({
            //   title: res.message,
            //   text: "",
            //   icon: "info",
            //   showCancelButton: false,
            //   showConfirmButton: false,
            //   confirmButtonColor: "#3085d6",
            //   cancelButtonColor: "#d33",
            //   confirmButtonText: "GO TO MY RIDES",
            //   allowOutsideClick: true,
            // }).then((result) => {
            //   if (result.value) {
            //     this.router.navigate(["/profilesetup/1"]);
            //   }
            // });
          }
        });
    }
  }

  listenSocketEvent() {
    this.socketService
      .onEvent(Event.sendNotificationToUser)
      .pipe(takeUntil(this.notifier))
      .subscribe((response: any) => {
        console.log("sendNotificationToUser", response);
        if (response.eventType != 32) {
          if (this.nextPopup == 8 || this.nextPopup == 4 || this.nextPopup == 3) {
            this.goToBookingStatus(response.data);
          }
          if (
            response.data.subBookingData[0].isPickUp &&
            (response?.pushType === 6 ||
              response?.pushType === 7 ||
              response?.pushType === 8 ||
              response?.pushType === 34 ||
              response?.pushType === 44)
          ) {
            let arr = response.data.subBookingData.filter((val) => val.cancelReason != "" && val.cancelReason != null);
            this.cancelReasons;
            this.cancelationMessage = response.message;
            this.canceledAddress;
            arr.length > 0 && (this.cancelReasons = arr[arr.length - 1].cancelReason);
            arr.length > 0 && (this.canceledAddress = arr[arr.length - 1].address);
            // response.cancelReason && (cancelReasons = response.cancelReason);
            (response?.pushType === 7 || response?.pushType === 8) && this.commonService.OpenNotificationModal("error", "top-end", response.message);
            response?.pushType !== 7 && response?.pushType !== 8 && (this.cancelDialog = "block");
            // response?.pushType !== 7 && response?.pushType !== 8 && this.cancelWithReason(response.message, "Reason:- " + this.cancelReasons, null);
            let arr2 = response.data.subBookingData.filter((val) => val.bookingStatus === 8);
            if (response?.pushType === 7 || response?.pushType === 8 || response.data.subBookingData.length === arr2.length) {
              this.commonService.bookingResponseData = null;
              this.bookingResponseData = null;
              this.continue(0);
            }
            response?.pushType !== 6 && response?.pushType !== 7 && response?.pushType !== 8 && (this.nextPopup = 3);
            if (response.data.subBookingData.length === arr.length) {
            }
            localStorage.removeItem("isCancelCounterStopped");
          }
          this.calculated = false;
          if (this.nextPopup == 8 || this.nextPopup == 4 || this.nextPopup == 3) {
            this.updateDriverLocation();
          }
        }
      });
  }

  removeDriverFromMap() {
    this.socketService
      .onEvent(Event.removeDriverFromMap)
      .pipe(takeUntil(this.notifier))
      .subscribe((response: any) => {
        console.log("removeDriverFromMap", response);
        var driversIds = this.nearByDriversData.map((values: any) => values.id);
        if (!response.isBusy && response.data.isOnline) {
          if (!driversIds.includes(response.data.id)) {
            this.nearByDriversData.push({ latitude: response.data.latitude, longitude: response.data.longitude, id: response.data.id });
          }
        } else if (!response.isBusy && !response.data.isOnline) {
          if (driversIds.includes(response.data.id)) {
            var index = this.nearByDriversData.findIndex((x) => x.id === response.data.id);
            this.nearByDriversData.splice(index, 1);
          }
        }
      });
  }

  goToBookingStatus(response) {
    // if (this.nextPopup == 8 || this.nextPopup == 4 || this.nextPopup == 3) {
    console.log(this.bookingResponseData);
    if (response.bookingType === this.constant.BOOKING_TYPE.RIDE) {
      this.listenDriverLatLngEvent();
      this.bookingResponseData = response;
      if (response.bookingStatus === this.constant.BOOKING_STATUS.PENDING && !response.isSheduledBooking) {
        this.bookingId = response._id;
        this.nextPopup = 3;
        var statusArr = response.subBookingData.map((val) => val.bookingStatus);
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED) {
        //this.commonService.bookingResponseData = null;

        this.bookingId = response._id;
        this.isDriveingStart = true;
        this.showDirections = true;
        this.bookingResponseData = response;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        this.bookingResponseData.subBookingData.forEach((element) => {
          if (element.isPickUp) {
            this.pickupfieldsArr.push({
              name: null,
              lat: element.latitude,
              lng: element.longitude,
              address: null,
              distance: 0,
              isAirportRoute: false,
            });
          } else {
            this.dropupfieldsArr.push({
              name: null,
              lat: element.latitude,
              lng: element.longitude,
              address: null,
              distance: 0,
              isAirportRoute: false,
            });
          }
        });
        this.matrix(
          { lat: response.driverId.latitude, lng: response.driverId.longitude },
          { lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng },
          null
        ).then((value: any) => {
          // this.listenDriverLatLngEvent();
          var isActive: boolean = true;
          if (localStorage.isCancelCounterStopped !== undefined) {
            if (localStorage.isCancelCounterStopped === "true") {
              this.commonService.cancelCounter = 0;
              isActive = false;
            }
          }
          let todayDate = moment(new Date(response.bookingAcceptedDate));
          let endDate = moment(new Date(new Date()));
          let diff = endDate.diff(todayDate, "seconds");
          console.log("diffrence in seconds", diff);
          if (diff <= 10 && diff > 0) {
            this.commonService.cancelCounter = diff;
            isActive = true;
          } else if (diff === 0) {
            this.commonService.cancelCounter = 10;
            isActive = true;
          } else {
            this.commonService.cancelCounter = 0;
            isActive = false;
          }
          this.commonService.cancelationTimerStart.next({ isActive: isActive, time: this.commonService.cancelCounter });
          // this.timerStart.next(true);
          for (let i = 0; i < response.subBookingData.length; i++) {
            if (
              response.subBookingData[i].isPickUp &&
              response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED &&
              this.pickupfieldsArr.length > 1 &&
              this.dropupfieldsArr.length === 1
            ) {
              if (value.duration.toFixed() !== "0") {
                this.driverMessage = this.messagesData.YOUR_DRIVER_IS_ARIVING_IN + value.duration.toFixed() + this.messagesData.MIN;
                console.log(this.driverMessage, "tester");
              } else {
                this.driverMessage = this.messagesData.YOUR_DRIVER_ARRIVED_TO_PICK_UP_RIDER_IN_LOCATION + this.constant.ALPHAB_STATUS[i + 1] + ".";
              }
              this.duration = value.duration;
              this.continue(4);
              return;
            } else if (
              response.subBookingData[i].isPickUp &&
              response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED &&
              this.pickupfieldsArr.length === 1 &&
              this.dropupfieldsArr.length > 1
            ) {
              if (value.duration.toFixed() !== "0") {
                this.driverMessage = this.messagesData.YOUR_DRIVER_IS_ARRIVING + value.duration.toFixed() + this.messagesData.MIN;
              } else {
                this.driverMessage = this.messagesData.YOUR_DRIVER_ARRIVED_TO_PICK_YOU_ALL_UP;
              }
              this.duration = value.duration;
              this.continue(4);
              return;
            } else if (
              response.subBookingData[i].isPickUp &&
              response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED &&
              this.pickupfieldsArr.length === 1 &&
              this.dropupfieldsArr.length === 1
            ) {
              if (value.duration.toFixed() !== "0") {
                this.driverMessage = this.messagesData.YOUR_DRIVER_IS_ARIVING_IN + value.duration.toFixed() + this.messagesData.MIN;
              } else {
                this.driverMessage = this.messagesData.YOUR_DRIVER_ARRIVED_TO_PICK_YOU_UP;
              }
              this.duration = value.duration;
              this.continue(4);
              return;
            }
          }
        });
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.TRANSIT) {
        //this.commonService.bookingResponseData = null;

        this.bookingId = response._id;
        this.isDriveingStart = true;
        this.showDirections = true;
        this.bookingResponseData = response;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        // this.duration = '';
        this.bookingResponseData.subBookingData.forEach((element) => {
          if (element.isPickUp) {
            this.pickupfieldsArr.push({
              name: null,
              lat: element.latitude,
              lng: element.longitude,
              address: null,
              distance: 0,
              isAirportRoute: false,
            });
          } else {
            this.dropupfieldsArr.push({
              name: null,
              lat: element.latitude,
              lng: element.longitude,
              address: null,
              distance: 0,
              isAirportRoute: false,
            });
          }
        });
        this.matrix(
          { lat: response.driverId.latitude, lng: response.driverId.longitude },
          { lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng },
          null
        ).then((value: any) => {
          if (response.subBookingData[0].bookingStatus === this.constant.BOOKING_STATUS.PICKED) {
            this.driverMessage = this.messagesData.YOU_STARTED_YOUR_RIDE;
          }
          if (response.subBookingData[0].bookingStatus === this.constant.BOOKING_STATUS.ARRIVED_AT_PICKED) {
            this.driverMessage = this.messagesData.YOUR_DRIVER_ARRIVED_TO_PICK_YOU_UP;
          }
          if (
            response.subBookingData[1].bookingStatus === this.constant.BOOKING_STATUS.ARRIVED_AT_DROPED ||
            response.subBookingData[1].bookingStatus === this.constant.BOOKING_STATUS.DROPED
          ) {
            this.driverMessage = this.messagesData.YOU_ARRIVED_AT_YOUR_DESTINATION;
            //this.commonService.bookingResponseData = null;
          }
        });
        this.listenDriverLatLngEvent();
        this.continue(4);
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.PARTIAL) {
        //this.commonService.bookingResponseData = null;

        this.isDriveingStart = true;
        this.showDirections = true;
        this.bookingResponseData = response;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        this.bookingResponseData.subBookingData.forEach((element) => {
          if (element.isPickUp) {
            this.pickupfieldsArr.push({
              name: null,
              lat: element.latitude,
              lng: element.longitude,
              address: null,
              distance: 0,
              isAirportRoute: false,
            });
          } else {
            this.dropupfieldsArr.push({
              name: null,
              lat: element.latitude,
              lng: element.longitude,
              address: null,
              distance: 0,
              isAirportRoute: false,
            });
          }
        });
        // this.listenDriverLatLngEvent();
        // this.matrix({ lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, { lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng }, null);
        this.continue(4);
        this.setDriverStatus(response);
        for (let i = 0; i < response.subBookingData.length; i++) {
          if (response.subBookingData.length > 2 && response.subBookingData[i].isPickUp) {
          }
        }
        this.isDriverLatLngUpdated.subscribe((value: boolean) => {
          if (value) {
            this.setDriverStatus(response);
          }
        });
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.STARTED) {
        //this.commonService.bookingResponseData = null;
        this.isDriveingStart = true;
        // this.markerOptions = {
        //   origin: {
        //     icon: this.iconObject(0)
        //   },
        //   destination: {
        //     icon: this.destinationMarkerIcon
        //   },
        // }
        // this.origin = { lat: response.pickUplatitude, lng: response.pickUplongitude };
        // this.destination = { lat: response.dropUplatitude, lng: response.dropUplongitude };
        this.showDirections = true;
        this.bookingResponseData = response;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        this.bookingResponseData.subBookingData.forEach((element) => {
          if (element.isPickUp) {
            this.pickupfieldsArr.push({
              name: null,
              lat: element.latitude,
              lng: element.longitude,
              address: null,
              distance: 0,
              isAirportRoute: false,
            });
          } else {
            this.dropupfieldsArr.push({
              name: null,
              lat: element.latitude,
              lng: element.longitude,
              address: null,
              distance: 0,
              isAirportRoute: false,
            });
          }
        });
        // this.listenDriverLatLngEvent();
        // this.matrix({ lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, { lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng }, null);
        this.continue(6);
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.COMPLETED) {
        this.monitorRideModal = "none";
        //this.commonService.bookingResponseData = null;
        this.bookingId = response._id;
        this.showDirections = false;
        this.showPickMarker = false;
        // this.pickup.lat = response.pickUplatitude;
        // this.pickup.lng = response.pickUplongitude;
        // this.drop.lat = response.dropUplatitude;
        // this.drop.lng = response.dropUplongitude;
        this.bookingResponseData = response;
        this.getDriverRatingsReview();
        this.ngxService.stop();
        this.continue(8);
        //this.commonService.bookingResponseData = null;
        localStorage.removeItem("isCancelCounterStopped");
      }
      // else if (response.bookingStatus === this.constant.BOOKING_STATUS.CANCELED) {
      //   //this.commonService.bookingResponseData = null;
      //   if (response.isCanceledByDriver && this.nextPopup !== 0) {
      //     this.driverNotFoundAlert(this.messagesData.YOUR_RIDE_HAS_BEEN_CANCELLED_BY_DRIVER);
      //     //this.commonService.bookingResponseData = null;
      //     this.bookingResponseData = null;
      //   } else if (response.isCanceledByCron && this.nextPopup !== 0) {
      //     this.driverNotFoundAlert(this.messagesData.OOPS_NO_DRIVER_AVAILABLE_NEARBY_PLEASE_TRY_AGAIN_LATER);
      //     //this.commonService.bookingResponseData = null;
      //     this.bookingResponseData = null;
      //   }
      // }
      else if (response.bookingStatus === this.constant.BOOKING_STATUS.PENDING && this.nextPopup !== 0) {
        this.driverNotFoundAlert(this.messagesData.OOPS_NO_DRIVER_AVAILABLE_NEARBY_PLEASE_TRY_AGAIN_LATER);
        this.commonService.bookingResponseData = null;
        this.bookingResponseData = null;
        localStorage.removeItem("isCancelCounterStopped");
      }
    } else {
      this.commonService.bookingResponseData = null;
      this.router.navigate(["/riderdeliverybooking"]);
      localStorage.removeItem("isCancelCounterStopped");
    }
    // }
  }

  setDriverStatus(response) {
    for (let i = 0; i < response.subBookingData.length; i++) {
      if (this.pickupfieldsArr.length > 1 && this.dropupfieldsArr.length === 1) {
        if (response.subBookingData[i].isPickUp && response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ARRIVED_AT_PICKED) {
          this.driverMessage = this.messagesData.YOUR_DRIVER_ARRIVED_TO_PICK_UP_RIDER_IN_LOCATION + this.constant.ALPHAB_STATUS[i + 1] + ".";
        } else if (
          response.subBookingData[i].isPickUp &&
          response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.PICKED &&
          response.subBookingData.length != i + 2
        ) {
          this.driverMessage = this.messagesData.YOUR_DRIVER_IS_ARIVING_IN + this.distanceDuration + this.messagesData.MIN;
        } else if (
          response.subBookingData[response.subBookingData.length - 2].isPickUp &&
          response.subBookingData[response.subBookingData.length - 2].bookingStatus === this.constant.BOOKING_STATUS.PICKED
        ) {
          this.driverMessage = this.messagesData.REACH_YOUR_DESTINATION_IN + this.distanceDuration + this.messagesData.MIN;
        }
        if (
          !response.subBookingData[response.subBookingData.length - 1].isPickUp &&
          (response.subBookingData[response.subBookingData.length - 1].bookingStatus === this.constant.BOOKING_STATUS.DROPED ||
            response.subBookingData[response.subBookingData.length - 1].bookingStatus === this.constant.BOOKING_STATUS.ARRIVED_AT_DROPED)
        ) {
          this.driverMessage = this.messagesData.ARRIVED_AT_YOUR_DESTINATION;
          this.monitorRideModal = "none";
        }
      } else if (this.pickupfieldsArr.length === 1 && this.dropupfieldsArr.length > 1) {
        if (
          i === 0 &&
          response.subBookingData[i].isPickUp &&
          response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED &&
          response.subBookingData[i].bookingStatus !== this.constant.BOOKING_STATUS.ARRIVED_AT_PICKED
        ) {
          this.driverMessage = this.messagesData.YOUR_DRIVER_IS_ARIVING_IN + this.duration + this.messagesData.MIN;
        }
        if (
          i === 0 &&
          response.subBookingData[i].isPickUp &&
          response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ARRIVED_AT_PICKED &&
          response.subBookingData[i].bookingStatus !== this.constant.BOOKING_STATUS.PICKED
        ) {
          this.driverMessage = this.messagesData.YOUR_DRIVER_ARRIVED_TO_PICK_YOU_ALL_UP;
        }
        if (
          i !== 1 &&
          response.subBookingData[i].isPickUp &&
          response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.PICKED &&
          response.subBookingData[i].bookingStatus !== this.constant.BOOKING_STATUS.ARRIVED_AT_DROPED &&
          response.subBookingData[i].bookingStatus !== this.constant.BOOKING_STATUS.DROPED
        ) {
          this.driverMessage = this.messagesData.YOUR_RIDES_HAVE_STARTED;
        }
        if (
          response.subBookingData[i].bookingStatus !== this.constant.BOOKING_STATUS.PICKED &&
          !response.subBookingData[i].isPickUp &&
          response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ARRIVED_AT_DROPED &&
          i !== this.bookingResponseData.subBookingData.length - 1
        ) {
          this.driverMessage = this.messagesData.ARRIVED_AT_DROP_OFF_LOCATION + this.constant.ALPHAB_STATUS[i] + ".";
        }
        if (response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.DROPED && i != response.subBookingData.length - 1) {
          this.driverMessage =
            this.messagesData.REACH_DROP_OFF_LOCATION +
            " " +
            this.constant.ALPHAB_STATUS[i + 1] +
            this.messagesData.IN +
            this.distanceDuration +
            this.messagesData.MIN;
        }
        if (
          i === this.bookingResponseData.subBookingData.length - 1 &&
          !response.subBookingData[i].isPickUp &&
          response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.DROPED
        ) {
          this.driverMessage = this.messagesData.ARRIVED_AT_FINAL_DESTINATION;
        }
      }
    }
  }

  listenDriverLatLngEvent() {
    this.socketService
      .onEvent(Event.updateDriverLatLongToUser)
      .pipe(takeUntil(this.notifier))
      .subscribe((response: any) => {
        this.updateDriverLatLongToUser = response;
      });
  }

  updateDriverLocation() {
    if (this.nextPopup == 8 || this.nextPopup == 4 || this.nextPopup == 3) {
      if (this.calculated === false) {
        this.calculated = true;
        var response = this.updateDriverLatLongToUser;
        if (!this.updateDriverLatLongToUser) {
          return;
        }
        console.log("updateDriverLatLongToUser", response);
        if (response.status == 200 && this.bookingResponseData.bookingStatus !== this.constant.BOOKING_STATUS.COMPLETED) {
          this.isDriveingStart = true;
          this.showDirections = true;
          this.showPickMarker = false;
          if (this.bookingResponseData.subBookingData.length > 0) {
            this.pickupfieldsArr = [];
            this.dropupfieldsArr = [];
            this.bookingResponseData.subBookingData.forEach((element) => {
              if (element.isPickUp) {
                this.pickupfieldsArr.push({
                  name: null,
                  lat: element.latitude,
                  lng: element.longitude,
                  address: null,
                  distance: 0,
                  isAirportRoute: false,
                });
              } else {
                this.dropupfieldsArr.push({
                  name: null,
                  lat: element.latitude,
                  lng: element.longitude,
                  address: null,
                  distance: 0,
                  isAirportRoute: false,
                });
              }
            });
          }
          response.distanceDuration &&
            (this.distanceDuration = Array.isArray(response.distanceDuration)
              ? response.distanceDuration.includes("mins")
              : (
                  typeof response.distanceDuration === "string"
                    ? response.distanceDuration.substring(0, response.distanceDuration.indexOf("m"))
                    : response.distanceDuration
                )
              ? response.distanceDuration
              : response.distanceDuration);
          if (this.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED) {
            var driverLatLng = { lat: response.latitude, lng: response.longitude };
            this.destination = { lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng };
            this.origin = { lat: response.latitude, lng: response.longitude };
            this.waypoints = [];
            this.drawPath(driverLatLng, this.pickupfieldsArr[this.pickupfieldsArr.length - 1], this.iconObject(response.bearing));
            // if (this.calculated === false) {
            //   this.calculated = true;
            this.matrix(driverLatLng, this.destination, null);
            // }
          }
          if (this.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.PARTIAL) {
            // this.destination = { lat: this.drop.lat, lng: this.drop.lng };
            this.isDriverLatLngUpdated$.next(true);
            var driverLatLng = { lat: response.latitude, lng: response.longitude };
            this.drawPath(driverLatLng, this.dropupfieldsArr[this.dropupfieldsArr.length - 1], this.iconObject(response.bearing));
            clearInterval(this.intervalSubscription);
          }
          if (this.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.TRANSIT) {
            var driverLatLng = { lat: response.latitude, lng: response.longitude };
            this.drawPath(driverLatLng, this.dropupfieldsArr[this.dropupfieldsArr.length - 1], this.iconObject(response.bearing));
            clearInterval(this.intervalSubscription);
          }
          // this.getDirection();
        } else {
          clearInterval(this.intervalSubscription);
        }
      }
    }
  }

  iconObject(rotation: number) {
    return {
      path: "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z",
      scale: 0.7,
      strokeColor: "white",
      strokeWeight: 0.1,
      fillOpacity: 1,
      fillColor: "#404040",
      offset: "5%",
      rotation: rotation,
      flat: true,
      anchor: new google.maps.Point(10, 25),
    };
  }

  getDriverRatingsReview() {
    this.http
      .httpPostWithHeaderUser("getDriverRatingsReview", { driverId: this.bookingResponseData.driverId.id })
      .pipe(takeUntil(this.notifier))
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.driverReviewData = res.data.driverData;
        }
      });
  }

  driverNotFoundAlert(message) {
    Swal.fire({
      title: message,
      // text: "You won't be able to revert this!",
      icon: "info",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.value) {
        this.continue(0);
      }
    });
  }

  cancelWithReason(message, text, index) {
    Swal.fire({
      title: message,
      text: text,
      icon: "info",
      showCancelButton: false,
      showConfirmButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      allowOutsideClick: true,
    }).then((result) => {
      if (result.value) {
        if (index !== null) {
          // this.continue(index);
        }
      }
    });
  }

  getDriverLocation(lat, lng) {
    this.geoCoder.geocode({ location: { lat: lat, lng: lng } }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          this.zoom = 13;
          this.driverAddress = results[0].formatted_address;
          this.driverLatLng = { lat: lat, lng: lng };
          // this.getDistanceMatrix(this.driverAddress, this.driverLatLng);
          // this.calculateDistanceMatrix();
          // this.getDirection();
          // this.displayWaypoints = false;
        } else {
          // window.alert('No results found');
        }
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  getDistanceMatrix(dropAddress, dropLatLng) {
    var origin1 = { lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng };
    var origin2 = this.pickupAddress;
    var destinationA = dropAddress;
    var destinationB = dropLatLng;
    var service = new google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [origin1, origin2],
        destinations: [destinationA, destinationB],
        travelMode: "DRIVING",
        unitSystem: google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false,
      },
      (res: any) => {
        this.distanceInKM = res.rows[0].elements[0].distance.text;
        this.duration = res.rows[0].elements[0].duration.text;
        this.ngxService.stop();
      }
    );
  }
  calculateDistanceMatrix(pickup, drop, formField) {
    let directionsService = new google.maps.DirectionsService();
    var request = {
      origin: pickup,
      destination: drop,
      optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.DRIVING,
    };
    directionsService.route(request, (response, status) => {
      this.directionStatus = status;
      if (status == google.maps.DirectionsStatus.OK) {
        this.distance = response?.routes[0]?.legs[0]?.distance.value / 1000; //convert m into km
        this.duration = response?.routes[0]?.legs[0]?.duration.value / 60; //convert seconds into minutes
        this.driverDistance = response?.routes[0]?.legs[0]?.distance.value / 1000; //convert m into km
        var obj = { distance: this.distance, duration: this.duration };
      } else {
        // this.commonService.OpenNotificationModal('error', 'top-end', 'No path found.');
        // if (formField !== null) {
        //   this.dropupForm.get(formField).patchValue('');
        // }
      }
      try {
      } catch (error) {
        console.log(error);
      }
    });
  }

  getTotalEtaAndDistance() {
    return new Promise((resolve, reject) => {
      if (this.pickupfieldsArr.length > this.dropupfieldsArr.length) {
        this.totalDistance = 0;
        this.totalEta = 0;
        this.matrix(
          { lat: this.pickupfieldsArr[this.pickupfieldsArr.length - 1].lat, lng: this.pickupfieldsArr[this.pickupfieldsArr.length - 1].lng },
          { lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng },
          null
        ).then((result: any) => {
          this.totalDistance += result.distance;
          this.totalEta += parseFloat(result.duration.toFixed(2));
          var counter = 0;
          for (let i = 0; i < this.pickupfieldsArr.length - 1; i++) {
            this.matrix(
              { lat: this.pickupfieldsArr[i].lat, lng: this.pickupfieldsArr[i].lng },
              { lat: this.pickupfieldsArr[i + 1].lat, lng: this.pickupfieldsArr[i + 1].lng },
              null
            ).then((result: any) => {
              this.totalDistance += result.distance;
              this.totalEta += parseFloat(result.duration.toFixed(2));
              counter++;
              if (counter === this.pickupfieldsArr.length - 1) {
                return resolve(true);
              }
            });
            // if (i === this.pickupfieldsArr.length - 1) {
            //   return resolve(true);
            // }
          }
        });
      } else if (this.pickupfieldsArr.length < this.dropupfieldsArr.length) {
        this.totalDistance = 0;
        this.totalEta = 0;
        this.matrix(
          { lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng },
          { lat: this.dropupfieldsArr[0].lat, lng: this.dropupfieldsArr[0].lng },
          null
        ).then((result: any) => {
          this.totalDistance += result.distance;
          this.totalEta += parseFloat(result.duration.toFixed(2));
          var counter = 0;
          for (let i = 0; i < this.dropupfieldsArr.length - 1; i++) {
            if (this.dropupfieldsArr.length - 1 >= i + 1) {
              this.matrix(
                { lat: this.dropupfieldsArr[i].lat, lng: this.dropupfieldsArr[i].lng },
                { lat: this.dropupfieldsArr[i + 1].lat, lng: this.dropupfieldsArr[i + 1].lng },
                null
              ).then((result: any) => {
                this.totalDistance += result.distance;
                this.totalEta += parseFloat(result.duration.toFixed(2));
                counter++;
                if (counter === this.dropupfieldsArr.length - 1) {
                  return resolve(true);
                }
                // if (i === this.dropupfieldsArr.length - 1 || this.dropupfieldsArr.length - 1 === i + 1) {
                //   return resolve(true);
                // }
              });
            }
          }
        });
      } else if (this.pickupfieldsArr.length === this.dropupfieldsArr.length) {
        this.totalDistance = 0;
        this.totalEta = 0;
        this.matrix(
          { lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng },
          { lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng },
          null
        ).then((result: any) => {
          this.totalDistance += result.distance;
          this.totalEta += parseFloat(result.duration.toFixed(2));
          return resolve(true);
        });
      }
    });
  }

  matrix(pickup, drop, formField) {
    return new Promise((resolve, reject) => {
      let directionsService = new google.maps.DirectionsService();
      var request = {
        origin: pickup,
        destination: drop,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING,
      };
      directionsService.route(request, (response, status) => {
        this.directionStatus = status;
        if (status == google.maps.DirectionsStatus.OK) {
          this.distance = response?.routes[0]?.legs[0]?.distance.value / 1000; //convert m into km
          this.duration = response?.routes[0]?.legs[0]?.duration.value / 60; //convert seconds into minutes
          this.driverDistance = response?.routes[0]?.legs[0]?.distance.value / 1000; //convert m into km
          var obj = { distance: this.distance, duration: this.duration };
          return resolve(obj);
        } else {
          // this.commonService.OpenNotificationModal('error', 'top-end', 'No path found.');
          // if (formField !== null) {
          //   this.dropupForm.get(formField).patchValue('');
          // }
        }
        try {
        } catch (error) {
          console.log(error);
        }
      });
    });
  }

  continue(popupType) {
    this.ngxService.start();
    if (popupType === 0 && this.isResetField) {
      this.selectedItem = 0;
      this.commonService.bookingResponseData = null;
      this.totalLoyality = "";
      this.selectedVehicleData = "";
      this.selectedCardDetails = "";
      this.selectedWalletCardId = "";
      this.isWalletSelected = false;
      this.showDirections = false;
      this.showPickMarker = true;
      this.showDropMarker = false;
      this.isDriveingStart = false;
      this.rideSubmited = false;
      this.DriverNoteFormSubmitted = false;
      this.rideSubmited = false;
      this.isDateTimeSubmit = false;
      this.driverNoteForm.reset();
      // this.bookingForm.reset();
      this.requestForLaterForm.reset();
      this.pickupFieldsCount = 1;
      this.dropupFieldsCount = 1;
      if (this.pickupfieldsArr[0].lat == 0 && this.pickupfieldsArr[0].lng == 0) {
      }
      this.pickupfieldsArr = [{ name: "pickup1", lat: 0, lng: 0, address: "", distance: 0 }];
      this.dropupfieldsArr = [{ name: "dropup1", lat: 0, lng: 0, address: "", distance: 0 }];
      this.dropupForm.get("dropup1").patchValue(null);
      this.getCurrentLocation();
      this.nextPopup = popupType;
      this.ngxService.stop();
      this.drop.lat = 0;
      this.drop.lng = 0;
      this.rating = 0;
      this.selectedReviewData = "";
    }
    this.nextPopup = popupType;
    this.ngxService.stop();
  }

  bookRideForLater() {
    this.isDateTimeSubmit = true;
    if (this.requestForLaterForm.valid) {
      // var selectedDate = this.requestForLaterForm.value.dateTime;
      // var minutesToAdd = 15;
      // var currentDate = new Date();
      // var futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);
      // var difference = futureDate.getTime() - selectedDate.getTime(); // This will give difference in milliseconds
      // var resultInMinutes = Math.round(difference / 60000);
      if (this.getTimeForLater()) {
        // $('#exampleModall').modal('hide');
        this.display = "none";
        this.bookingFor = "Later";
        // this.getVehicle();
        this.bookRideForNow("Later");
      }
    }
  }

  getTimeForLater() {
    var selectedDate = this.requestForLaterForm.value.dateTime;
    var minutesToAdd = 15;
    var currentDate = new Date();
    var futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);
    var difference = futureDate.getTime() - selectedDate.getTime(); // This will give difference in milliseconds
    var resultInMinutes = Math.round(difference / 60000);
    if (resultInMinutes <= 0) {
      return true;
    } else {
      this.commonService.OpenNotificationModal("success", "top-end", "Minimum time is 15 minutes from current time");
      return false;
    }
  }

  openDateTimeModal() {
    this.isResetField = true;
    this.allowNewBooking().then((allow: any) => {
      if (allow) {
        this.rideSubmited = true;
        this.activeBtn = "Later";
        if (this.pickupForm.valid && this.dropupForm.valid) {
          // $('#exampleModall').modal('show');
          this.display = "block";
        }
      }
    });
  }

  closeScheduleModal() {
    this.display = "none";
  }

  cancelConfirmToast(index) {
    if (this.commonService.cancelCounter > 0) {
      Swal.fire({
        title: "Are you sure you want to cancel?",
        text: "You canceled your booking within 10 sec. No cancelletion fees apply!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Cancel it!",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.cancelBooking(0);
          localStorage.removeItem("isCancelCounterStopped");
        }
      });
    } else {
      Swal.fire({
        title: "Are you sure you want to cancel?",
        text: index === null ? "You canceled your booking after 10 sec. A cancellation fee applies!" : "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Cancel it!",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.cancelBooking(0);
          localStorage.removeItem("isCancelCounterStopped");
        }
      });
    }
  }

  cancelBooking(index) {
    if (this.bookingId) {
      this.ngxService.start();
      this.http
        .httpPostWithHeaderUser("cancelBooking", { bookingId: this.bookingId })
        .pipe(takeUntil(this.notifier))
        .subscribe(
          (res: any) => {
            if (res.statusCode === 200) {
              this.ngxService.stop();
              this.commonService.bookingResponseData = "";
              this.bookingResponseData = "";
              this.continue(0);
              this.DriverNoteFormSubmitted = false;
              this.rideSubmited = false;
              this.isDateTimeSubmit = false;
              this.driverNoteForm.reset();
              // this.bookingForm.reset();
              this.requestForLaterForm.reset();
              this.getCurrentLocation();
              this.drop.lat = 0;
              this.drop.lng = 0;
              this.showDirections = false;
              this.showPickMarker = true;
              this.showDropMarker = false;
            }
          },
          (err) => this.ngxService.stop()
        );
    } else {
      console.error("Booking Id not found.");
    }
  }

  goTouhomepage() {
    this.router.navigate(["riderequest"]);
  }
  goToprofilesetup() {
    this.commonService.bookingResponseData = null;
    this.commonService.isThisUpdatedUser = false;
    this.router.navigate(["/profilesetup"]);
  }
  goTorequestaccept() {
    this.router.navigate(["/requestaccept"]);
  }
  goToriderdeliverybooking() {
    this.router.navigate(["/riderdeliverybooking"]);
  }

  addFavouriteDriver() {
    this.http
      .httpPostWithHeaderUser("addFavouriteDriver", { driverId: this.bookingResponseData.driverId.id, isFav: true })
      .pipe(takeUntil(this.notifier))
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.getDriverRatingsReview();
          this.commonService.OpenNotificationModal("success", "top-end", res.message);
        }
      });
  }

  removeFavouriteDriver() {
    this.http
      .httpPostWithHeaderUser("addFavouriteDriver", { driverId: this.bookingResponseData.driverId.id, isFav: false })
      .pipe(takeUntil(this.notifier))
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.getDriverRatingsReview();
          this.commonService.OpenNotificationModal("success", "top-end", res.message);
        }
      });
  }

  //for rating
  onClick(rating: number) {
    // console.log(rating)
    // this.snackBar.open('You rated ' + rating + ' / ' + this.starCount, '', {
    //   duration: this.snackBarDuration
    // });
    this.ratingUpdated.emit(rating);
    this.rating = rating;
    if (this.rating > 0 && this.rating < 3) {
      this.review.setValidators(Validators.required);
      this.review.updateValueAndValidity();
    } else {
      this.review.clearValidators();
      this.review.updateValueAndValidity();
    }
    return false;
  }

  showIcon(index: number) {
    if (this.rating >= index + 1) {
      return "star";
    } else {
      return "star_border";
    }
  }

  selectReview(index, data) {
    this.selectedDriverReview = index;
    this.selectedReviewData = data.isActive ? "" : data.name;
  }

  selectTip(i, amount) {
    if (this.selectedTip !== i) {
      this.selectedTip = i;
      this.selectedTipAmount = amount;
      if (this.customRate.value != null) {
        this.customRate.patchValue(null);
        this.selectedTip = i;
      }
    } else {
      this.selectedTip = null;
      this.selectedTipAmount = null;
      this.customRate.patchValue(null);
    }
  }

  onTipChange(value) {
    if (value <= 0) {
      this.customRate.patchValue(null);
    }
  }

  addRating(index) {
    this.ratingSubmitted = true;
    if (
      (this.rating > 0 && (this.rating <= 2 ? this.review.value != "" : this.selectedReviewData != "") && this.wasTheDriverOnTime.value != null) ||
      this.wasTheRideSafe.value != null ||
      this.driverMakeYouComfortabel.value != null
    ) {
      this.selectedFeedbckQna = [];
      this.selectedFeedbckQna.push({ question: "Was the driver on time?", answere: this.wasTheDriverOnTime.value });
      this.selectedFeedbckQna.push({ question: "Was the ride safe?", answere: this.wasTheRideSafe.value });
      this.selectedFeedbckQna.push({ question: "Did the driver make your ride comfortabel?", answere: this.driverMakeYouComfortabel.value });
      this.selectedFeedbckQna.forEach((element) => {
        if (element.answere === null) {
          const index = this.selectedFeedbckQna.indexOf(element);
          this.selectedFeedbckQna.splice(index, 1);
        }
      });
      this.selectedFeedbckQna.forEach((element) => {
        if (element.answere === null) {
          const index = this.selectedFeedbckQna.indexOf(element);
          this.selectedFeedbckQna.splice(index, 1);
        }
      });
      var selectedreview: any;
      this.reviewData.forEach((element) => {
        if (element.isActive) {
          selectedreview = element.name;
        }
      });
      var param = $.extend(
        {},
        {
          bookingId: this.bookingId,
          driverId: this.bookingResponseData.driverId.id,
          driverRating: this.rating,
          driverReview: this.selectedReviewData,
          tip: this.customRate.value ? this.customRate.value : this.selectedTipAmount,
          comment: this.comment.value ? this.comment.value : undefined,
          feedBack: this.selectedFeedbckQna,
        }
      );
      !param.tip && delete param.tip;
      this.http
        .httpPostWithHeaderUser("addRatings", param)
        .pipe(takeUntil(this.notifier))
        .subscribe((res: any) => {
          if (res.statusCode == 200) {
            // this.commonService.OpenNotificationModal('success', 'top-end', res.message);
            this.continue(index);
            this.rating = 0;
            this.ratingSubmitted = false;
            this.selectedTip = null;
            this.selectedDriverReview = null;
            this.selectedReviewData = null;
            this.selectedTipAmount = null;
            this.driverReviewData = null;
            this.comment.patchValue(null);
            this.customRate.patchValue(null);
            this.wasTheRideSafe.patchValue(null);
            this.driverMakeYouComfortabel.patchValue(null);
            this.wasTheDriverOnTime.patchValue(null);
            this.selectedFeedbckQna = [];
          }
        });
    }
  }

  goToContactDetail() {
    this.isContactDetailHide = this.isContactDetailHide ? false : true;
    if (!this.isContactDetailHide) {
      this.vps.scrollToAnchor("driverInfo");
      // document.getElementById("driverInfo").scrollIntoView();
    }
  }

  monitorRide() {
    this.monitorSubmit = true;
    if (this.bookingResponseData.bookingStatus === 19 || this.bookingResponseData.bookingStatus === 20) {
      this.commonService.OpenNotificationModal("error", "top-end", "Your booking has been completed.");
      return false;
    }
    if (this.monitorForm.valid) {
      var codeLength = this.monitorForm.value.phoneNo.dialCode.length;
      var param = {
        bookingId: this.bookingId,
        moniterRide: {
          name: this.monitorForm.value.name,
          phoneNo: this.monitorForm.value.phoneNo.e164Number.slice(codeLength),
          countryCode: this.monitorForm.value.phoneNo.dialCode,
        },
      };
      this.http
        .httpPostWithHeaderUser("shareYourRide", param)
        .pipe(takeUntil(this.notifier))
        .subscribe((res: any) => {
          if (res.statusCode === 200) {
            this.monitorForm.reset();
            this.monitorSubmit = false;
            this.commonService.OpenNotificationModal("success", "top-end", res.message);
            // $("#add_moniter").modal('hide');
            this.monitorRideModal = "none";
          }
        });
    }
  }

  openMonitorModal(bookingId) {
    this.bookingId = bookingId;
    this.monitorRideModal = "block";
    // $("#add_moniter").modal('show');
  }

  hideMonitorModal() {
    this.monitorRideModal = "none";
    // $("#add_moniter").modal('show');
  }

  addPickupField(pickup) {
    if (pickup.lat !== 0 && pickup.lng !== 0) {
      this.pickupFieldsCount++;
      if (!("pickup" + this.pickupFieldsCount in this.pickupFormObj)) {
        this.addFieldPick(pickup);
      } else {
        this.pickupFieldsCount++;
        this.addFieldPick(pickup);
      }
    } else {
      this.rideSubmited = true;
      pickup.address = "";
      this.pickupForm.get(pickup.name).patchValue(null);
    }
  }

  addFieldPick(pickup) {
    if (!("pickup" + this.pickupFieldsCount in this.pickupFormObj)) {
      this.pickupFormObj["pickup" + this.pickupFieldsCount] = new FormControl("", Validators.required);
      this.pickupForm = this.fb.group(this.pickupFormObj);
      this.pickupfieldsArr.push({
        name: "pickup" + this.pickupFieldsCount,
        lat: 0,
        lng: 0,
        address: "",
        distance: 0,
        country: this.currentCountry,
        isAirportRoute: false,
      });
      console.log("check2", this.pickupfieldsArr);
    } else {
      this.addPickupField(pickup);
    }
  }

  removePickupField(index) {
    this.pickupfieldsArr.splice(index, 1);
    Object.keys(this.pickupFormObj).forEach((element) => {
      let strArray = this.pickupfieldsArr.map((element) => element.name);
      if (!strArray.includes(element)) {
        delete this.pickupFormObj[element];
      }
    });
    this.pickupForm = this.fb.group(this.pickupFormObj);
    console.log("check1", this.pickupfieldsArr);
  }

  addDropupField(drop) {
    if (drop.lat !== 0 && drop.lng !== 0) {
      this.dropupFieldsCount++;
      if (!("dropup" + this.dropupFieldsCount in this.dropupFormObj)) {
        this.addFieldDrop(drop);
      } else {
        this.dropupFieldsCount++;
        this.addFieldDrop(drop);
      }
    } else {
      this.rideSubmited = true;
      drop.address = "";
      this.dropupForm.get(drop.name).patchValue(null);
    }
  }

  addFieldDrop(drop) {
    if (!("dropup" + this.dropupFieldsCount in this.dropupFormObj)) {
      this.dropupFormObj["dropup" + this.dropupFieldsCount] = new FormControl("", Validators.required);
      this.dropupForm = this.fb.group(this.dropupFormObj);
      this.dropupfieldsArr.push({
        name: "dropup" + this.dropupFieldsCount,
        lat: 0,
        lng: 0,
        address: "",
        distance: 0,
        country: this.currentCountry,
        isAirportRoute: false,
      });
    } else {
      this.addDropupField(drop);
    }
  }

  removeDropupField(index) {
    this.dropupfieldsArr.splice(index, 1);
    Object.keys(this.dropupFormObj).forEach((element) => {
      let strArray = this.dropupfieldsArr.map((element) => element.name);
      if (!strArray.includes(element)) {
        delete this.dropupFormObj[element];
      }
    });
    this.dropupForm = this.fb.group(this.dropupFormObj);
  }

  drawPath(origin, destination, originIcon) {
    var wayPontsMarkerArr = [];
    this.waypoints = [];
    this.origin = "";
    this.destination = "";
    if (this.pickupfieldsArr.length > 1) {
      for (let i = 0; i < this.pickupfieldsArr.length; i++) {
        if (i !== 0) {
          this.waypoints.push({
            location: { lat: this.pickupfieldsArr[i].lat, lng: this.pickupfieldsArr[i].lng },
            stopover: false,
          });
          wayPontsMarkerArr.push({ icon: this["marker" + [i + 1]] });
        } else {
          // wayPontsMarkerArr.push({ icon: 'http://i.imgur.com/7teZKif.png' });
        }
      }
    } else if (this.dropupfieldsArr.length > 1) {
      for (let i = 0; i < this.dropupfieldsArr.length; i++) {
        if (i !== this.dropupfieldsArr.length - 1) {
          this.waypoints.push({
            location: { lat: this.dropupfieldsArr[i].lat, lng: this.dropupfieldsArr[i].lng },
            stopover: false,
          });
          wayPontsMarkerArr.push({ icon: this["marker" + [i + 2]] });
        } else {
          // wayPontsMarkerArr.push({ icon: 'http://i.imgur.com/7teZKif.png' });
        }
      }
    }
    this.markerOptions = {
      origin: {
        icon: originIcon,
      },
      destination: {
        icon: this.destinationMarkerIcon,
      },
      waypoints: wayPontsMarkerArr,
    };
    this.showDirections = true;
    this.showPickMarker = false;
    this.showDropMarker = false;
    this.origin = { lat: origin.lat, lng: origin.lng };
    this.destination = { lat: destination.lat, lng: destination.lng };
  }

  originPathOptimization() {
    this.pickupfieldsArr.forEach((element) => {
      let destination = { lat: element.lat, lng: element.lng };
      this.calculateDistanceForPathOpt(this.current, destination, element);
    });
  }

  destinationPathOptimization() {
    this.dropupfieldsArr.forEach((element) => {
      let destination = { lat: element.lat, lng: element.lng };
      this.calculateDistanceForPathOpt(this.pickup, destination, element);
    });
  }

  calculateDistanceForPathOpt(pickup, drop, data) {
    let directionsService = new google.maps.DirectionsService();
    var request = {
      origin: pickup,
      destination: drop,
      optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.DRIVING,
    };
    directionsService.route(request, (response, status) => {
      if (status == google.maps.DirectionsStatus.OK) {
        data.distance = response?.routes[0]?.legs[0]?.distance.value;
      }
    });
  }

  goToHome() {
    if (this.nextPopup !== 0) {
      this.commonService.bookingResponseData = null;
      this.continue(0);
      return;
    } else {
      this.commonService.origin = { lat: 0, lng: 0, address: "" };
      this.commonService.destination = { lat: 0, lng: 0, address: "" };
      this.router.navigate(["/uhomepage"]);
    }
  }

  getCardNumberControl(): AbstractControl | null {
    return this.cardNumberGroup && this.cardNumberGroup.get("cardNumber");
  }

  cardMaskFunction(rawValue: string) {
    const card = getValidationConfigFromCardNo(rawValue);
    if (card) {
      return card.mask;
    }
    return [/\d/];
  }

  AddCardModal(display) {
    this.addCardModal = display;
  }

  addDebitCard() {
    this.cardSubmitted = true;
    if (
      this.cardNumberGroup.valid &&
      this.cardOwnerFirstName.valid &&
      this.expiryMonth.valid &&
      !this.validateFirstArr.includes(true) &&
      this.expiryYear.valid &&
      this.cvv.valid
    ) {
      var param = {
        cardNumber: this.cardNumberGroup.get("cardNumber").value,
        expDate: this.expiryMonth.value + this.expiryYear.value,
        cvv: this.cvv.value,
        firstName: this.cardOwnerFirstName.value,
        lastName: this.cardOwnerLastName.value,
      };
      this.ngxService.start();
      this.http.httpPostWithHeaderUser("userCard", param).subscribe(
        (res: any) => {
          this.ngxService.stop();
          if (res.statusCode == 200) {
            this.addCardModal = "none";
            this.commonService.presentsToast("success", "top-end", this.messagesData.YOUR_PAYMENT_CARD_HAS_BEEN_ADDED_SUCCESSFULLY);
            this.cardSubmitted = false;
            this.getAllCard();
            this.cardOwnerFirstName.reset();
            this.cardOwnerLastName.reset();
            this.expiryMonth.patchValue(null);
            this.expiryYear.patchValue(null);
            this.cvv.patchValue(null);
            this.cardNumberGroup.get("cardNumber").reset();
          }
        },
        (err) => this.ngxService.stop()
      );
    }
  }

  getAllCard() {
    this.http.httpGetWithHeaderOnly("userCard").subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.allSavedCard = [];
        this.allSavedCardData = res.data;
        if (res.data.cards.length > 0) {
          res.data.cards.forEach((data: any) => {
            if (data.isDefault) {
              this.defaultCardDetails = data;
              this.selectedCardDetails = data;
              data["isSelected"] = true;
              this.allSavedCard.push(data);
            } else {
              data["isSelected"] = false;
              this.allSavedCard.push(data);
            }
          });
        } else {
          this.defaultCardDetails = "";
          this.selectedCardDetails = "";
        }
        this.isWalletSelected = false;
      }
    });
  }

  changeDefaultCard(cardId) {
    this.http.httpPostWithHeaderUser("defaultCard", { id: cardId }).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.getAllCard();
      }
    });
  }

  deleteCard(cardId) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.http.httpDeleteByIdUser("userCard", cardId).subscribe((res: any) => {
          if (res.statusCode == 200) {
            Swal.fire("Deleted!", "Card has been deleted.", "success");
            this.getAllCard();
          }
        });
      }
    });
  }

  onCardChange(index) {
    this.allSavedCard = [];
    this.selectedCardDetails = this.allSavedCardData.cards[index - 1];
    this.isWalletSelected = false;
    this.allSavedCardData.cards.forEach((data: any) => {
      if (data.id === this.selectedCardDetails?.id) {
        data["isSelected"] = true;
        this.allSavedCard.push(data);
      } else {
        data["isSelected"] = false;
        this.allSavedCard.push(data);
      }
    });
  }

  onWalletSelected(value) {
    this.isWalletSelected = value;
    if (value) {
      this.allSavedCard = [];
      this.allSavedCardData.cards.forEach((data: any) => {
        data["isSelected"] = false;
        this.allSavedCard.push(data);
      });
    } else {
      this.getAllCard();
    }
  }

  addWalletModal(style) {
    if (!this.allSavedCardData) {
      this.commonService.OpenNotificationModal("success", "top-end", "Please add payment card first.");
      return false;
    }
    if (this.allSavedCardData.cards.length > 0 && style === "block") {
      this.addWalletMoneyModal = style;
      this.defaultCardDetails.id && (this.selectedWalletCardId = this.defaultCardDetails.id);
    } else if (this.allSavedCardData.cards.length === 0 && style === "block") {
      this.commonService.OpenNotificationModal("success", "top-end", "Please add payment card first.");
    }
    if (style === "none") {
      this.addWalletMoneyModal = style;
    }
  }

  onWalletCardChange(card) {
    this.selectedWalletCardId = card.id;
  }

  addWalletMoney() {
    this.walletMoneySubmitted = true;
    if (this.addWalletAmount.valid && parseInt(this.addWalletAmount.value) > 0) {
      this.ngxService.start();
      this.http.httpPostWithHeaderUser("wallet", { amount: this.addWalletAmount.value, cardId: this.selectedWalletCardId }).subscribe(
        (res: any) => {
          this.ngxService.stop();
          if (res.statusCode == 200) {
            this.addWalletModal("none");
            this.addWalletAmount.reset();
            this.getAllCard();
          }
        },
        (err) => this.ngxService.stop()
      );
    } else if (parseInt(this.addWalletAmount.value) <= 0) {
      this.addWalletAmount.patchValue(null);
    }
  }

  openPaymentOptions(index) {
    // this.getAllCard();
    this.continue(index);
  }

  keypresshandler(event) {
    console.log(event);
    var charCode = event.keyCode;
    if (charCode !== 46) {
      if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    }
    //Non-numeric character range
  }

  onKeyPress(event) {
    if (event.charCode !== 32 && event.charCode !== 39) {
      return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123);
    }
  }

  onChangeFirst(value) {
    this.validateFirstArr = [];
    for (let i = 0; i < value.length; i++) {
      var charCode = value.charCodeAt(i);
      this.validateFirstArr.push(this.charCodeArr.includes(charCode));
    }
  }

  onChangeSecond(value) {
    this.validateSecondArr = [];
    for (let i = 0; i < value.length; i++) {
      var charCode = value.charCodeAt(i);
      this.validateSecondArr.push(this.charCodeArr.includes(charCode));
    }
  }

  loyalityModals(style) {
    if (style === "block") {
      this.getLoyalityData();
    }
    this.loyalityModal = style;
  }

  getLoyalityData() {
    this.ngxService.start();
    this.http.httpGetWithHeaderOnly("getLoyalityDiscounts").subscribe(
      (res: any) => {
        this.ngxService.stop();
        if (res.statusCode == 200) {
          this.loyalityModal = "block";
          this.loyalityData = res.data;
        }
      },
      (err) => this.ngxService.stop()
    );
  }

  redeemCoupon(coupon) {
    if (!this.selectedVehicleData.loyalityId) {
      var arr = JSON.parse(JSON.stringify(this.vehicleCategoryData));
      this.vehicleCategoryData = [];
      arr.forEach((element) => {
        var discount;
        if (element.vehicleCategory !== "Any") {
          if (element.discount) {
            if ((element.discount !== null && coupon.couponType === 1) || coupon.couponType === 3) {
              if (element.discount < coupon.couponValue) {
                discount = 0.0;
              } else {
                discount = element.discount - coupon.couponValue;
              }
            } else if (element.discount !== null && coupon.couponType === 2) {
              discount = element.discount - (element.discount * coupon.couponValue) / 100;
            }
            element.discount !== null && (element["booKingAmount"] = element.discount);
            element.discount !== null && (element["discount"] = parseFloat(discount.toFixed(2)));
            element["loyalityId"] = coupon._id;
            this.selectedVehicleData["loyalityId"] = coupon._id;
            this.vehicleCategoryData.push(element);
          } else if (element.discount === 0) {
            this.vehicleCategoryData.push(element);
          } else {
            if ((element.booKingAmount !== null && coupon.couponType === 1) || coupon.couponType === 3) {
              if (element.booKingAmount < coupon.couponValue) {
                discount = 0.0;
              } else {
                discount = element.booKingAmount - coupon.couponValue;
              }
              // discount = (element.booKingAmount < coupon.couponValue) ? 0 : (element.booKingAmount - coupon.couponValue);
              // discount <= 0 && (discount = 0);
            } else if (element.booKingAmount !== null && coupon.couponType === 2) {
              discount = element.booKingAmount - (element.booKingAmount * coupon.couponValue) / 100;
            }
            element.booKingAmount !== null && (element["discount"] = parseFloat(discount.toFixed(2)));
            console.log(element.discount);
            // if (element.discount) {
            element["loyalityId"] = coupon._id;
            this.selectedVehicleData["loyalityId"] = coupon._id;
            // }f
            this.vehicleCategoryData.push(element);
          }
        } else {
          this.vehicleCategoryData.push(element);
        }
      });
      if (coupon.couponType === 1 || coupon.couponType === 3) {
        this.totalLoyality = "U2go Money ($" + coupon.couponValue.toFixed(2) + ")";
      } else {
        this.totalLoyality = "U2go Money (" + coupon.couponValue.toFixed(2) + "%)";
      }
      this.loyalityModal = "none";
    } else {
      this.loyalityModal = "none";
    }
  }

  private payPalInitConfig(currency, amount): void {
    this.payPalConfig = {
      currency: currency,
      clientId: "sb",
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: "CAPTURE",
          purchase_units: [
            {
              amount: {
                currency_code: currency,
                value: amount,
                breakdown: {
                  item_total: {
                    currency_code: currency,
                    value: amount,
                  },
                },
              },
              payee: {
                email_address: "abc@yopmail.com",
                merchant_id: "xxxx",
              },
              items: [
                {
                  name: "Enterprise Subscription",
                  quantity: "1",
                  category: "DIGITAL_GOODS",
                  unit_amount: {
                    currency_code: currency,
                    value: amount,
                  },
                },
              ],
            },
          ],
        },
      advanced: {
        commit: "true",
      },
      style: {
        label: "paypal",
        layout: "vertical",
        color: "blue",
      },
      onApprove: (data, actions) => {
        console.log("onApprove - transaction was approved, but not authorized", data, actions);
        actions.order.get().then((details) => {
          console.log("onApprove - you can get full order details inside onApprove: ", details);
        });
      },
      onClientAuthorization: (data) => {
        console.log("onClientAuthorization - you should probably inform your server about completed transaction at this point", data);
        // this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        console.log("OnCancel", data, actions);
      },
      onError: (err) => {
        console.log("OnError", err);
      },
      onClick: (data, actions) => {
        console.log("onClick", data, actions);
      },
    };
  }

  goToNotification() {
    localStorage.isNotificationActive = true;
    this.router.navigate(["profilesetup/5"]);
  }

  reviewDriver(index) {
    this.continue(index);
    clearInterval(this.calculateSubscription);
  }

  goToFavAdd(addType, index, arrIndex) {
    this.getFavAddress();
    this.isResetField = false;
    this.selectedAddressIndex = arrIndex;
    this.addressType = addType;
    this.continue(index);
  }

  getFavAddress() {
    var param = {
      pageNo: this.currentPageA,
    };
    this.http
      .httpPostWithHeaderUser("getAddress", param)
      .pipe(takeUntil(this.notifier))
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.favAddData = res.data.addressData;
          this.totalPagesA = res.data.totalPages;
          this.nextBtnEnabledA = this.currentPageA < this.totalPagesA;
          this.previousBtnEnabledA = this.currentPageA > 1;
        }
      });
  }

  patchFavAddress(index, data) {
    this.addressType === "pick" && this.handlePickUpChnage("", "", data);
    this.addressType === "drop" && this.handleDropChnage("", "", data);
    this.continue(index);
  }

  handleLocationChnage(address) {
    if (address.formatted_address) {
      console.log(address.formatted_address);
      let lat = address.geometry.location.lat();
      let lng = address.geometry.location.lng();
      this.favAddPayload.latitude = lat;
      this.favAddPayload.longitude = lng;
      this.getFavAddressDetail(lat, lng, address.formatted_address);
    }
  }

  onKeyUp(value) {
    if (value === "") {
      this.favAddPayload.latitude = 0;
      this.favAddPayload.longitude = 0;
      this.favAddPayload.address = "";
    }
  }

  onAddChanged(value) {
    if (this.favAddPayload.latitude === 0 && this.favAddPayload.longitude === 0) {
      this.favAddPayload.address = "";
    } else if (this.favAddPayload.localAddress !== value) {
      this.favAddPayload.address = "";
      this.favAddPayload.localAddress = "";
    }
  }

  getFavAddressDetail(lat, lng, formatted_address) {
    this.geoCoder.geocode({ location: { lat: lat, lng: lng } }, (results, status) => {
      if (status === "OK") {
        if (results.length > 0) {
          console.log(results);
          var isAddSimilar = false;
          let length = results[0].address_components.length;
          this.favAddPayload.latitude = lat;
          this.favAddPayload.longitude = lng;
          this.favAddPayload.address = formatted_address;
          this.favAddPayload.localAddress = formatted_address;
          setTimeout(() => {
            if (!this.favAddPayload.zipCode) {
              if (results[0].address_components[length - 1].types.includes("postal_code")) {
                this.favAddPayload.zipCode = results[0].address_components[length - 1].long_name;
              }
            }
          }, 1000);
          results.forEach((addresses) => {
            if (addresses.formatted_address === formatted_address && !isAddSimilar) {
              isAddSimilar = true;
              console.log("exist");
              this.fetchAddress(addresses.address_components);
              return false;
            }
          });
          if (!isAddSimilar) {
            console.log("not-exist");
            this.fetchAddress(results[0].address_components);
          }
        } else {
          // window.alert('No results found');
        }
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  fetchAddress(addressArr) {
    var city = null;
    var state = null;
    var zipCode = null;
    var country = null;
    addressArr.forEach((element) => {
      if (
        element.types.includes("locality") ||
        element.types.includes("postal_town") ||
        element.types.includes("sublocality") ||
        element.types.includes("sublocality_level_1") ||
        element.types.includes("sublocality_level_2") ||
        element.types.includes("sublocality_level_3") ||
        element.types.includes("sublocality_level_4") ||
        element.types.includes("sublocality_level_5")
      ) {
        city = element.long_name;
      }
      if (
        element.types.includes("administrative_area_level_1") ||
        element.types.includes("administrative_area_level_2") ||
        element.types.includes("administrative_area_level_3") ||
        element.types.includes("administrative_area_level_4") ||
        element.types.includes("administrative_area_level_5")
      ) {
        state = element.long_name;
      }
      if (element.types.includes("postal_code")) {
        zipCode = element.long_name;
      }
      if (element.types.includes("country")) {
        country = element.long_name;
      }
    });
    this.favAddPayload.city = city;
    this.favAddPayload.province = state;
    this.favAddPayload.country = country;
    console.log(this.favAddPayload, "favAddPayload");
    // this.secondFormGroup.controls['zipCode'].patchValue(zipCode);
  }

  createFavAddForm() {
    this.favAddPayload = {
      streetAddress: "",
      city: "",
      province: "",
      zipCode: "",
      country: "",
      name: "",
      latitude: 0,
      longitude: 0,
      address: "",
      localAddress: "",
    };
  }

  editfavAdd(data) {
    this.favAddPayload = {
      streetAddress: data.streetAddress,
      city: data.city,
      province: data.province,
      zipCode: data.zipCode,
      country: data.country,
      name: data.name,
      latitude: data.latitude,
      longitude: data.longitude,
      address: data.address,
      localAddress: data.localAddress,
      addressId: data._id,
    };
    this.continue(21);
  }

  submitFavAdd() {
    this.favAddressSubmitted = true;
    if (this.favAddPayload.name && this.favAddPayload.address) {
      if (this.favAddPayload.addressId) {
        this.http.httpPostWithHeaderUser("editAddress", this.favAddPayload).subscribe((res: any) => {
          if (res.statusCode === 200) {
            this.favAddModal = "none";
            this.favAddressSubmitted = false;
            this.getFavAddress();
            this.createFavAddForm();
            this.continue(20);
          }
        });
      } else {
        this.http.httpPostWithHeaderUser("addAddress", this.favAddPayload).subscribe((res: any) => {
          if (res.statusCode === 200) {
            this.favAddModal = "none";
            this.favAddressSubmitted = false;
            this.getFavAddress();
            this.createFavAddForm();
            this.continue(20);
          }
        });
      }
    }
  }

  showClipBoardMessage() {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 1 * 1000,
    });
    this.snackBar.open(this.messagesData.COPIED_TO_CLIPBOARD);

    setTimeout(() => {
      this.snackBar.dismiss();
    }, 1000);
    // this.commonService.presentsToast("success", "top-end", "Copied to clipboard");
  }

  onNextPageA() {
    this.currentPageA++;
    this.getFavAddress();
  }

  onChnagePageA(page) {
    this.currentPageA = page;
    this.getFavAddress();
  }

  onPrevPageA() {
    this.currentPageA--;
    this.getFavAddress();
  }

  updateTableA(currentPage) {
    this.previousBtnEnabledA = currentPage > 1;
    this.nextBtnEnabledA = currentPage < this.totalPagesA;
    this.getFavAddress();
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
    this.commonService.bookingResponseData = null;
    clearInterval(this.intervalSubscription);
    clearInterval(this.calculateSubscription);
  }
}

@Component({
  selector: "snack-bar-component-example-snack",
  template: `<span> Copied to clipboard.</span>`,
  styles: [
    `
      .example-pizza-party {
        color: hotpink;
      }
    `,
  ],
})
export class SnackbarComponent {}

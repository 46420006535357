<!------start homapge page-->
<div class="deliverpartners_page">
  <app-f-unavbar></app-f-unavbar>
  <div class="header_padding_class">
    <section class="riders_first_sec delivery_first_sec">
      <div class="conta_iner">
        <div class="riders_first_main">
          <div class="rider_firstsec_content">
            <div class="main_headings">
              <h2>{{ "We look forward to serving your needs" | translate }}</h2>
              <h3>
                {{ "Our Delivery Partners told us they want efficient" | translate }},
                {{ "transparent and reliable services as well as the ability to monitor and audit the process from start to finish" | translate }}.
                {{ "They also want to minimize capital expenditures (e.g. having their own vehicles)" | translate }}
                {{ "and recurring costs and risks (e.g. dedicated employees) so they can invest in their core competencies" | translate }}.
              </h3>
              <h3 class="delivery_pageh3">
                {{ "The APPTUNIX™ solution addresses what our Delivery Partners are looking for" | translate }}.
                {{ "Join us and experience the difference" | translate }}.
              </h3>
              <!-- <div class="two_btn">
                                <button (click)="goToSignupPage()">Become a Delivery Partners</button>
                            </div> -->
              <div class="two_btn">
                <button (click)="goToSignupPage()">{{ "Sign up as Delivery Partner" | translate }}</button>
                <button class="onlyborder_fillbtn" (click)="goToriderlogin()">
                  {{ "Login as Delivery Partner" | translate }}
                </button>
              </div>
            </div>
          </div>
          <div class="rider_firstsec_img">
            <figure>
              <img src="assets/uimages/delivery_partnersfirst_sec.png" alt="riders" />
            </figure>
          </div>
        </div>
      </div>
    </section>
    <div class="slider-img udriver">
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let slide of slides" class="slide">
          <h4 class="mainh">{{ slide.main }}</h4>
          <p>{{ slide.content }}</p>
          <figure><img src="{{ slide.img }}" alt="" width="100%" /></figure>
        </div>
      </ngx-slick-carousel>
    </div>
    <!-----start delivery partners secound sec-->
    <section class="all_sec riders_first_sec delivery_partners_secound">
      <div class="conta_iner">
        <div class="riders_first_main">
          <div class="rider_firstsec_content">
            <div class="main_headings">
              <h4>{{ "In this rapidly changing business environment" | translate }} {{ "companies need to find ways of increasing" | translate }}</h4>
              <p>
                {{ "their revenue and enabling better customer experiences and comfort while focusing on their core competencies" | translate }}.
                {{ "APPTUNIX™ unique applications and services allow our delivery" | translate }}
                {{ "partners to focus on what they do best, minimizing capital expenditure and recurring costs" | translate }}
                {{ "while APPTUNIX™ takes care of the delivery of their goods, whether it is food," | translate }}
                {{ "medicines, auto parts, parcels, furniture or other delivery items" | translate }}.
              </p>
            </div>
          </div>
          <div class="rider_firstsec_img">
            <figure>
              <img src="assets/uimages/delivery_partnerbusiness.png" alt="riders" />
            </figure>
          </div>
        </div>
      </div>
    </section>
    <!-----start login form-->
    <section class="riders_login" id="partner">
      <div class="conta_iner">
        <div class="riders_login_main">
          <div class="rider_img">
            <figure>
              <img src="assets/uimages/delivery_login.png" alt="" />
            </figure>
          </div>
          <!-- <div class="riders_login_form">
                        <h4 class="center">Let's Get Started <span>Become a Delivery Partner</span></h4>
                        <form class="form_control_form" [formGroup]="partnerForm">
                            <div class="form-group">
                                <mat-form-field [floatLabel]="'never'">
                                    <input class="form-control" formControlName="firstName" matInput type="text"
                                        placeholder="First Name" />
                                </mat-form-field>
                                <mat-error *ngIf="submitted && partnerForm.controls['firstName'].hasError('required')">First
                                    name is required.</mat-error>
                            </div>
                            <div class="form-group">
                                <mat-form-field [floatLabel]="'never'">
                                    <input class="form-control" formControlName="lastName" matInput type="text"
                                        placeholder="Last Name" />
                                </mat-form-field>
                                <mat-error *ngIf="submitted && partnerForm.controls['lastName'].hasError('required')">Last
                                    name is required.</mat-error>
                            </div>
                            <div class="form-group">
                                <mat-form-field [floatLabel]="'never'">
                                    <input class="form-control" matInput placeholder="Email" type="email"formControlName="email" />
                                </mat-form-field>
                                <mat-error *ngIf="submitted && partnerForm.controls['email'].hasError('required')">
                                    Email is
                                    required.</mat-error>
                                <mat-error *ngIf="submitted && partnerForm.controls['email'].hasError('email')">Enter valid
                                    email.</mat-error>
                            </div>
                            <div class="form-group">
                                <ngx-intl-tel-input [inputId]="'adsasd'" matInput [cssClass]="'custom'"
                                    [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true"
                                    [enablePlaceholder]="true" [searchCountryFlag]="true"
                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                    [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Canada"
                                    [maxLength]="15" [tooltipField]="TooltipLabel.Name" [phoneValidation]="true"
                                    [separateDialCode]="separateDialCode" name="phone" formControlName="phoneNo">
                                </ngx-intl-tel-input>
                                <mat-error *ngIf="submitted && partnerForm.controls['phoneNo'].hasError('required')" style="margin-top: 3px;">
                                    Phone
                                    number is required.</mat-error>
                                <mat-error
                                    *ngIf="submitted && partnerForm.controls['phoneNo'].hasError('validatePhoneNumber')" style="margin-top: 3px;">
                                    Enter valid number.</mat-error>
                            </div>
                            <div class="form-group">
                                <div class="fgi sec">
                                    <textarea id="w3review" class="from-control" placeholder="Enter your comment here"
                                        formControlName="comment"></textarea>
                                    <mat-error
                                        *ngIf="submitted && partnerForm.controls['comment'].hasError('required')">
                                        Comment is required.</mat-error>
                                </div>
                            </div>

                            <div class="flex submit_btn">
                                <button class="btn btn-primary" (click)="submit()">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div> -->
          <div class="riders_login_form">
            <form [formGroup]="bookingForm">
              <h3>{{ "Please Log In and request a delivery" | translate }}</h3>
              <div class="riders_pickupinput rider_bluedot">
                <label>{{ "Choose your pickup" | translate }}</label>
                <mat-form-field class="example-full-width">
                  <input
                    matInput
                    placeholder="{{ 'Search for pick up location' | translate }}"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="off"
                    type="text"
                    ngx-google-places-autocomplete
                    #placesRef="ngx-places"
                    (keyup)="onKeyUp($event.target.value)"
                    (change)="pickupChange($event.target.value)"
                    (onAddressChange)="handlePickUpChnage($event)"
                    formControlName="ridePickUpAddress"
                  />
                </mat-form-field>
                <p style="color: red" *ngIf="rideSubmited === true && bookingForm.controls['ridePickUpAddress'].hasError('required')">
                  {{ "Pickup address is required" | translate }}.
                </p>
              </div>
              <div class="riders_pickupinput rider_reddot">
                <label>{{ "Choose your destination" | translate }}</label>
                <mat-form-field class="example-full-width">
                  <mat-label>{{ "Choose your Drop off" | translate }} </mat-label>
                  <input
                    matInput
                    placeholder="{{ 'Search for drop off location' | translate }}"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="off"
                    type="text"
                    ngx-google-places-autocomplete
                    #placesRef="ngx-places"
                    (keyup)="onKeyUpDrop($event.target.value)"
                    (change)="dropupChange($event.target.value)"
                    (onAddressChange)="handleDropChnage($event)"
                    formControlName="rideDropAddress"
                  />
                </mat-form-field>
                <p style="color: red" *ngIf="rideSubmited === true && bookingForm.controls['rideDropAddress'].hasError('required')">
                  {{ "Drop off address is required" | translate }}.
                </p>
              </div>
              <div class="main_headings">
                <button (click)="goToriderequest()">{{ "Next" | translate }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <section id="partner">
      <div class="login">
        <div class="conta_iner">
          <div class="login_main">
            <div class="login_form center">
              <h4>
                {{ "Please contact us" | translate }}
                <!-- <span>Become a Delivery Partner</span> -->
              </h4>

              <form class="form_control_form" [formGroup]="partnerForm">
                <div class="form-group">
                  <mat-form-field [floatLabel]="'never'">
                    <input class="form-control" formControlName="firstName" matInput type="text" placeholder="{{ 'First name' | translate }}" />
                  </mat-form-field>
                  <mat-error *ngIf="submitted && partnerForm.controls['firstName'].hasError('required')"
                    >{{ "First name is required" | translate }}.</mat-error
                  >
                  <mat-error *ngIf="submitted && partnerForm.controls['firstName'].hasError('pattern')"
                    >{{ "Please enter valid first name" | translate }}.</mat-error
                  >
                </div>
                <div class="form-group">
                  <mat-form-field [floatLabel]="'never'">
                    <input class="form-control" formControlName="lastName" matInput type="text" placeholder="{{ 'Last name' | translate }}" />
                  </mat-form-field>
                  <mat-error *ngIf="submitted && partnerForm.controls['lastName'].hasError('required')"
                    >{{ "Last name is required" | translate }}.</mat-error
                  >
                  <mat-error *ngIf="submitted && partnerForm.controls['lastName'].hasError('pattern')"
                    >{{ "Please enter valid last name" | translate }}.</mat-error
                  >
                </div>
                <div class="form-group">
                  <mat-form-field [floatLabel]="'never'">
                    <input class="form-control" type="email" matInput placeholder="{{ 'Email address' | translate }}" formControlName="email" />
                  </mat-form-field>
                  <mat-error *ngIf="submitted && partnerForm.controls['email'].hasError('required')"
                    >{{ "Email is required" | translate }}.</mat-error
                  >
                  <mat-error *ngIf="submitted && partnerForm.controls['email'].hasError('pattern')"
                    >{{ "Sorry, only letters (a-z), number (0-9), and periods (.) are allowed" | translate }}.</mat-error
                  >
                </div>
                <div class="form-group">
                  <!-- <mat-form-field [floatLabel]="'never'"> -->
                  <ngx-intl-tel-input
                    [inputId]="'adsasd'"
                    matInput
                    [cssClass]="'custom'"
                    [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true"
                    [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                    [selectFirstCountry]="false"
                    [selectedCountryISO]="CountryISO.Canada"
                    [maxLength]="15"
                    [tooltipField]="TooltipLabel.Name"
                    [phoneValidation]="true"
                    [separateDialCode]="separateDialCode"
                    name="phone"
                    formControlName="phoneNo"
                  >
                  </ngx-intl-tel-input>
                  <!-- </mat-form-field> -->
                  <mat-error *ngIf="submitted && partnerForm.controls['phoneNo'].hasError('required')"
                    >{{ "Phone number is required" | translate }}.</mat-error
                  >
                  <mat-error *ngIf="submitted && partnerForm.controls['phoneNo'].hasError('validatePhoneNumber')">
                    {{ "Enter valid number" | translate }}.</mat-error
                  >
                </div>
                <div class="form-group">
                  <div class="fgi sec">
                    <textarea
                      id="w3review"
                      class="from-control"
                      placeholder="{{ 'Enter your comment here' | translate }}"
                      formControlName="comment"
                    ></textarea>
                    <mat-error *ngIf="submitted && partnerForm.controls['comment'].hasError('required')">
                      {{ "Comment is required" | translate }}.</mat-error
                    >
                  </div>
                </div>

                <div class="flex submit_btn">
                  <button class="btn btn-primary" (click)="submit()">
                    {{ "Submit" | translate }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!---------start why ride section-->
    <section class="all_sec riders_whyride">
      <div class="conta_iner">
        <div class="riders_whyride_main">
          <div class="main_headings">
            <h2>{{ "Why Deliver with APPTUNIX™?" | translate }}</h2>
          </div>
          <!------why ride u2go feature list-->
          <div class="why_rideu2go_list">
            <div class="u2go_ridefeat_item">
              <div class="u2go_ridefeat_itemimg">
                <figure>
                  <img src="assets/uimages/whydelivery1.svg" alt="whydelivery1" />
                </figure>
              </div>
              <h3>{{ "Free Transaction Fee For The First 6 Months" | translate }}</h3>
            </div>
            <div class="u2go_ridefeat_item">
              <div class="u2go_ridefeat_itemimg">
                <figure>
                  <img src="assets/uimages/whydelivery2.svg" alt="whydelivery1" />
                </figure>
              </div>
              <h3>{{ "Fast, Reliable And Controllable Service" | translate }}</h3>
            </div>
            <div class="u2go_ridefeat_item">
              <div class="u2go_ridefeat_itemimg">
                <figure>
                  <img src="assets/uimages/whydelivery3.svg" alt="whydelivery1" />
                </figure>
              </div>
              <h3>{{ "Safe And Secure" | translate }}</h3>
            </div>
            <div class="u2go_ridefeat_item">
              <div class="u2go_ridefeat_itemimg">
                <figure>
                  <img src="assets/uimages/whydelivery4.svg" alt="whydelivery1" />
                </figure>
              </div>
              <h3>{{ "True Partnership" | translate }}</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-----start our app section-->
    <section class="our_app">
      <div class="conta_iner">
        <div class="our_app_main">
          <div class="our_app_screen">
            <figure>
              <img src="assets/uimages/app_screen.png" alt="" />
            </figure>
          </div>
          <div class="our_app_content">
            <h2>
              {{ "Download our Apps and start experiencing a new world of possibilities" | translate }}
            </h2>
            <div class="app_downloadlink">
              <a href="">
                <img src="assets/uimages/google_store.png" alt="" />
              </a>
              <a href="">
                <img src="assets/uimages/app_store.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <app-footer></app-footer>
  </div>
</div>

import { Component, OnInit, NgZone, OnDestroy } from "@angular/core";
import { SocketioService } from "../services/sockets/socketio.service";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpsService } from "../services/https/https.service";
import { FormBuilder } from "@angular/forms";
import { MapsAPILoader } from "@agm/core";
import { CommonService } from "../services/common/common.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LocationStrategy, ViewportScroller } from "@angular/common";
import { environment } from "src/environments/environment";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
declare var google: any;
import { Event } from "../core/models/event";
import { Action } from "../core/models/action";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-tracking",
  templateUrl: "./tracking.component.html",
  styleUrls: ["./tracking.component.scss"],
})
export class TrackingComponent implements OnInit, OnDestroy {
  lat: number = 51.678418;
  lng: number = 7.809007;
  pickup = {
    lat: 0,
    lng: 0,
  };
  pickupAddress = "";
  drop = {
    lat: 0,
    lng: 0,
  };
  dropAddress = "";
  geoCoder: any;
  zoom: number = 12;
  nextPopup = 6;
  origin;
  destination;
  showDirections: boolean = false;
  showPickMarker: boolean = false;
  public renderOptions = {
    suppressMarkers: true,
  };
  originMarkerIcon: string = "assets/uimages/originmarker.png";
  destinationMarkerIcon: string = "assets/uimages/destination.png";
  markerOptions: any;
  baseUrl: string;
  distanceInKM: any;
  duration: any;
  driverLatLng: { lat: any; lng: any };
  notifier = new Subject();
  bookingResponseData: any;
  bookingId: any;
  bookingData: any;
  waypoints: any[];
  pickupfieldsArr: any = [{ name: "pickup1", lat: 0, lng: 0, address: "", distance: 0 }];
  dropupfieldsArr: any = [{ name: "dropup1", lat: 0, lng: 0, address: "", distance: 0 }];
  showDropMarker: boolean;

  constructor(
    private router: Router,
    private http: HttpsService,
    private fb: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private commonService: CommonService,
    private ngxService: NgxUiLoaderService,
    private socketService: SocketioService,
    private snackBar: MatSnackBar,
    private location: LocationStrategy,
    private vps: ViewportScroller,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    this.bookingId = this.route.snapshot.paramMap.get("bookingId");
    this.baseUrl = environment.imageUrl;
  }

  ngOnInit(): void {
    this.socketService.initSocket();
    this.markerOptions = {
      origin: {
        icon: this.iconObject(90),
      },
      destination: {
        icon: this.destinationMarkerIcon,
      },
    };
    this.preventBackButton();
    this.getCurrentLocation();
    this.getShareYourRide();
    this.socketService.emitAction(Action.bookingTracking, { bookingId: this.bookingId });
    this.socketService
      .onEvent(Event.bookingTracking)
      .pipe(takeUntil(this.notifier))
      .subscribe((response: any) => {
        console.log(response);
        this.goToBookingStatus(response);
      });
  }

  goToBookingStatus(response) {
    this.showDirections = true;
    this.showPickMarker = false;
    this.origin = { lat: response.latitude, lng: response.longitude };
    this.driverLatLng = { lat: response.latitude, lng: response.longitude };
    this.destination = { lat: this.drop.lat, lng: this.drop.lng };
    this.drawPath(this.driverLatLng, this.dropupfieldsArr[this.dropupfieldsArr.length - 1], this.iconObject(response.bearing));
  }

  drawPath(origin, destination, originIcon) {
    var wayPontsMarkerArr = [];
    this.waypoints = [];
    if (this.pickupfieldsArr.length > 1) {
      for (let i = 0; i < this.pickupfieldsArr.length; i++) {
        if (i !== 0) {
          this.waypoints.push({
            location: { lat: this.pickupfieldsArr[i].lat, lng: this.pickupfieldsArr[i].lng },
            stopover: false,
          });
          wayPontsMarkerArr.push({ icon: "http://i.imgur.com/7teZKif.png" });
        } else {
          wayPontsMarkerArr.push({ icon: "http://i.imgur.com/7teZKif.png" });
        }
      }
      this.markerOptions = {
        origin: {
          icon: originIcon,
        },
        destination: {
          icon: this.destinationMarkerIcon,
        },
        waypoints: wayPontsMarkerArr,
      };
    } else if (this.dropupfieldsArr.length > 1) {
      for (let i = 0; i < this.dropupfieldsArr.length; i++) {
        if (i !== this.dropupfieldsArr.length - 1) {
          this.waypoints.push({
            location: { lat: this.dropupfieldsArr[i].lat, lng: this.dropupfieldsArr[i].lng },
            stopover: false,
          });
          wayPontsMarkerArr.push({ icon: "http://i.imgur.com/7teZKif.png" });
        } else {
          wayPontsMarkerArr.push({ icon: "http://i.imgur.com/7teZKif.png" });
        }
      }
      this.markerOptions = {
        origin: {
          icon: originIcon,
        },
        destination: {
          icon: this.destinationMarkerIcon,
        },
        waypoints: wayPontsMarkerArr,
      };
    }
    this.showDirections = true;
    this.showPickMarker = false;
    this.showDropMarker = false;
    this.origin = { lat: origin.lat, lng: origin.lng };
    this.destination = { lat: destination.lat, lng: destination.lng };
  }

  getShareYourRide() {
    this.http.httpPostUser("getShareYourRide", { bookingId: this.bookingId }).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        this.bookingData = res.data.bookingData;
        this.bookingData.subBookingData.forEach((element) => {
          if (element.isPickUp) {
            this.pickupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          } else {
            this.dropupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          }
        });
        this.drawPath(this.pickupfieldsArr[0], this.dropupfieldsArr[this.dropupfieldsArr.length - 1], this.iconObject(90));
      }
    });
  }

  preventBackButton() {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  iconObject(rotation: number) {
    return {
      path: "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z",
      scale: 0.7,
      strokeColor: "white",
      strokeWeight: 0.1,
      fillOpacity: 1,
      fillColor: "#404040",
      offset: "5%",
      rotation: rotation,
      flat: true,
      anchor: new google.maps.Point(10, 25),
    };
  }

  getDistanceMatrix(dropAddress, dropLatLng) {
    var origin1 = this.pickup;
    var origin2 = this.pickupAddress;
    var destinationA = dropAddress;
    var destinationB = dropLatLng;
    var service = new google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [origin1, origin2],
        destinations: [destinationA, destinationB],
        travelMode: "DRIVING",
        unitSystem: google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false,
      },
      (res: any) => {
        this.distanceInKM = res.rows[0].elements[0].distance.text;
        this.duration = res.rows[0].elements[0].duration.text;
        this.ngxService.stop();
      }
    );
  }

  continue(popupType) {
    this.ngxService.start();
    this.nextPopup = popupType;
    this.ngxService.stop();
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.pickup.lat = position.coords.latitude;
          this.pickup.lng = position.coords.longitude;
          this.zoom = 15;
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }
}

<div class="profile_wrap">
  <div class="signupinner">
    <div class="conta_iner">
      <div class="flex">
        <div class="haeder_left">
          <figure class="logo_cm" (click)="goTouhomepage()">
            <img src="assets/uimages/logo.png " />
          </figure>
        </div>
        <div class="multiple_content">
          <!-- <div class="language-select"> -->
          <div class="lm">
            <div class="language-select" (click)="showLang = showLang ? false : true">
              <div class="sel-bar" *ngIf="languageService?.selectedLang">
                <figure><img [src]="baseUrl + languageService?.selectedLang?.logo" />{{ languageService?.selectedLang?.code?.toUpperCase() }}</figure>
              </div>
              <p *ngIf="!languageService?.selectedLang">Select Language</p>
              <div class="ndpicon">
                <i *ngIf="!showLang" class="fa fa-caret-down" aria-hidden="true"></i>
                <i *ngIf="showLang" class="fa fa-caret-up" aria-hidden="true"></i>
              </div>
            </div>
            <div class="dp-lang" *ngIf="showLang">
              <figure *ngFor="let language of languageService?.allLanguages" (click)="selectLang(language)">
                <img [src]="baseUrl + language?.logo" />{{ language?.code }}
              </figure>
            </div>
          </div>
          <div class="back_home hmbtn">
            <button class="driverap_btn" style="cursor: pointer" (click)="goTouhomepage()">
              {{ "Back To Home" | translate }}
            </button>
          </div>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="prfile">
    <div class="conta_iner">
      <div class="profile_main">
        <mat-horizontal-stepper [linear]="isLinear" #stepper class="mg-a">
          <mat-step class="" [stepControl]="firstFormGroup" [editable]="editable">
            <form [formGroup]="firstFormGroup">
              <ng-template matStepLabel>{{ "Personal details" | translate }}</ng-template>
              <!-- <mat-form-field>
                        <mat-label>Name</mat-label>
                        <input matInput placeholder="Last name, First name " formControlName="firstCtrl " required />
                    </mat-form-field> -->
              <div class="deatil_form_outer">
                <div class="detail_form">
                  <div class="driver_profile_img">
                    <div class="pr_man">
                      <figure *ngIf="!fileDataUrl">
                        <img src="assets/images/user.png " />
                      </figure>
                      <figure *ngIf="fileDataUrl">
                        <img [src]="baseUrl + fileDataUrl" />
                      </figure>
                    </div>
                    <div class="profile_camera">
                      <figure>
                        <img src="assets/uimages/edit.svg" (click)="fileInput.click()" />
                      </figure>
                      <input
                        class="upload_file"
                        formControlName="image"
                        #fileUpload
                        (change)="onFileUpload($event)"
                        id="file-input"
                        #fileInput
                        type="file"
                      />
                    </div>
                  </div>
                  <div class="detail_form_items">
                    <mat-form-field [floatLabel]="'never'">
                      <label>{{ "First name" | translate }}</label>
                      <input
                        class="form-control"
                        formControlName="firstName"
                        matInput
                        type="text"
                        maxlength="50"
                        (change)="onChangeFirst($event.target.value)"
                        (keypress)="onKeyPress($event)"
                        placeholder="{{ 'First name' | translate }} "
                        name="name "
                      />
                    </mat-form-field>
                    <mat-error *ngIf="submitted && firstFormGroup.controls['firstName'].hasError('required')"
                      >{{ "First name is required" | translate }}.</mat-error
                    >
                    <mat-error *ngIf="submitted && validateFirstArr.includes(true) && !firstFormGroup.controls['firstName'].hasError('required')"
                      >{{ "Please enter valid first name" | translate }}.</mat-error
                    >
                  </div>
                  <div class="detail_form_items">
                    <mat-form-field [floatLabel]="'never'">
                      <label>{{ "Last name" | translate }}</label>
                      <input
                        class="form-control"
                        maxlength="50"
                        (change)="onChangeSecond($event.target.value, 'firstFormGroup')"
                        (keypress)="onKeyPress($event)"
                        formControlName="lastName"
                        matInput
                        type="text "
                        placeholder="{{ 'Last name' | translate }} "
                        name="name "
                      />
                    </mat-form-field>
                    <mat-error *ngIf="submitted && firstFormGroup.controls['lastName'].hasError('required')"
                      >{{ "Last name is required" | translate }}.</mat-error
                    >
                    <mat-error *ngIf="submitted && validateSecondArr.includes(true) && !firstFormGroup.controls['lastName'].hasError('required')"
                      >{{ "Please enter valid last name" | translate }}.</mat-error
                    >
                  </div>
                  <div class="detail_form_items">
                    <mat-form-field [floatLabel]="'never'">
                      <label>{{ "Username" | translate }}</label>
                      <input
                        class="form-control"
                        formControlName="userName"
                        matInput
                        type="text "
                        placeholder="{{ 'Username' | translate }} "
                        name="name "
                      />
                      <mat-error>{{ "Username is required" | translate }}.</mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="userNameAlreadyExist">{{ "Username already exist" | translate }}.</mat-error>
                  </div>
                  <div class="detail_form_items" *ngIf="userData?.email !== ''">
                    <mat-form-field [floatLabel]="'never'">
                      <label>{{ "Email address" | translate }}</label>
                      <input
                        class="form-control"
                        formControlName="email"
                        matInput
                        type="email"
                        placeholder="{{ 'Email address' | translate }}"
                        name="name"
                        readonly
                      />
                      <mat-error *ngIf="submitted && firstFormGroup.controls['email'].hasError('required')">
                        {{ "Email address is required" | translate }}.
                      </mat-error>
                      <mat-error
                        *ngIf="
                          submitted && !firstFormGroup.controls['email'].hasError('required') && firstFormGroup.controls['email'].hasError('pattern')
                        "
                      >
                        {{ "Please Enter Valid Email" | translate }}.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items" *ngIf="userData?.email === ''">
                    <mat-form-field [floatLabel]="'never'">
                      <label>{{ "Email address" | translate }}</label>
                      <input
                        class="form-control"
                        formControlName="email"
                        matInput
                        type="email"
                        placeholder="{{ 'Email address' | translate }}"
                        name="name"
                      />
                      <mat-error *ngIf="submitted && firstFormGroup.controls['email'].hasError('required')">
                        {{ "Email address is required" | translate }}.
                      </mat-error>
                      <mat-error
                        *ngIf="
                          submitted && !firstFormGroup.controls['email'].hasError('required') && firstFormGroup.controls['email'].hasError('pattern')
                        "
                      >
                        {{ "Please Enter Valid Email" | translate }}.
                      </mat-error>
                    </mat-form-field>
                    <mat-error
                      *ngIf="
                        submitted &&
                        isEmailExist &&
                        !firstFormGroup.controls['email'].hasError('email') &&
                        !firstFormGroup.controls['email'].hasError('required')
                      "
                    >
                      {{ "Email address already exist" | translate }}.</mat-error
                    >
                  </div>
                  <div class="detail_form_items">
                    <mat-form-field [floatLabel]="'never'">
                      <label>{{ "Company name" | translate }}</label>
                      <input
                        class="form-control"
                        formControlName="companyName"
                        matInput
                        type="text "
                        placeholder="{{ 'Company name' | translate }}"
                        name="name "
                      />
                      <mat-error>{{ "Company name is required" | translate }}.</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items" *ngIf="userData?.phoneNo !== ''">
                    <mat-form-field [floatLabel]="'never'">
                      <label>{{ "Phone number" | translate }}</label>
                      <input class="form-control" formControlName="phone" matInput type="text " placeholder="Phone number" name="name " readonly />
                      <mat-error>{{ "Phone number is required" | translate }}.</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items" *ngIf="userData?.phoneNo === ''">
                    <label>{{ "Phone number" | translate }}</label>
                    <ngx-intl-tel-input
                      class="form-control"
                      [inputId]="'sprt'"
                      [cssClass]="'custom spprt'"
                      [preferredCountries]="preferredCountries"
                      [enablePlaceholder]="true"
                      [searchCountryFlag]="true"
                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                      [selectFirstCountry]="false"
                      [enableAutoCountrySelect]="true"
                      [selectedCountryISO]="CountryISO.Canada"
                      [maxLength]="15"
                      [tooltipField]="TooltipLabel.Name"
                      [phoneValidation]="true"
                      [separateDialCode]="separateDialCode"
                      name="phone"
                      formControlName="phone"
                    >
                    </ngx-intl-tel-input>
                    <mat-error *ngIf="submitted && firstFormGroup.controls['phone'].hasError('required')">
                      {{ "Phone number is required" | translate }}.</mat-error
                    >
                    <mat-error *ngIf="submitted && firstFormGroup.controls['phone'].hasError('validatePhoneNumber')">
                      {{ "Please enter valid phone number" | translate }}.</mat-error
                    >
                    <mat-error
                      *ngIf="
                        submitted &&
                        isPhoneExist &&
                        !firstFormGroup.controls['phone'].hasError('validatePhoneNumber') &&
                        !firstFormGroup.controls['phone'].hasError('required')
                      "
                    >
                      {{ "Phone number already exist" | translate }}.</mat-error
                    >
                  </div>
                </div>
              </div>

              <div class="driver_nex-btn mb-4">
                <button class="btn btn-primary" mat-button (click)="updateProfile('1', 'firstFormGroup', 'step1')">
                  {{ "Next" | translate }}
                </button>
              </div>

              <!-----start fileds-->

              <!-----End fileds-->
            </form>
          </mat-step>
          <mat-step class="" [stepControl]="secondFormGroup" [editable]="editable">
            <form [formGroup]="secondFormGroup">
              <ng-template matStepLabel>Address</ng-template>
              <div class="deatil_form_outer">
                <div class="detail_form">
                  <div class="detail_form_items">
                    <mat-form-field [floatLabel]="'never'">
                      <label class="algn-lft">{{ "Street no. and name" | translate }}.</label>
                      <input
                        class="form-control"
                        formControlName="street"
                        matInput
                        type="text "
                        placeholder="{{ 'Street no. and name' | translate }}"
                        name="name "
                      />
                    </mat-form-field>
                    <mat-error *ngIf="submitted && secondFormGroup.controls['street'].hasError('required')"
                      >{{ "Street no. and name" | translate }} {{ "are required" | translate }}.</mat-error
                    >
                  </div>
                  <div class="detail_form_items">
                    <mat-form-field [floatLabel]="'never'">
                      <label class="algn-lft">{{ "Additional details (unit #, name of business, etc)" | translate }}.</label>
                      <input
                        class="form-control"
                        formControlName="localAddress"
                        matInput
                        type="text "
                        placeholder="{{ 'Additional details (unit #, name of business, etc)' | translate }}"
                        name="name"
                      />
                    </mat-form-field>
                  </div>
                  <div class="detail_form_items">
                    <mat-form-field [floatLabel]="'never'">
                      <label class="algn-lft">{{ "City, state/province and country" | translate }}.</label>
                      <input
                        matInput
                        placeholder="{{ 'Search for your City, state/province and country' | translate }}"
                        autocorrect="off"
                        autocapitalize="off"
                        spellcheck="off"
                        type="text"
                        class="form-control"
                        ngx-google-places-autocomplete
                        #placesRef="ngx-places"
                        (keyup)="onKeyUp($event.target.value)"
                        (change)="onAddChanged($event.target.value)"
                        (onAddressChange)="handleLocationChnage($event)"
                        formControlName="location"
                      />
                    </mat-form-field>
                    <mat-error *ngIf="submitted && secondFormGroup.controls['location'].hasError('required')"
                      >{{ "City, state/province and country" | translate }} {{ "are required" | translate }}.</mat-error
                    >
                  </div>
                  <div class="detail_form_items">
                    <mat-form-field [floatLabel]="'never'">
                      <label class="algn-lft">{{ "Postal/Zip Code" | translate }}.</label>
                      <input
                        class="form-control"
                        formControlName="zipCode"
                        matInput
                        type="text "
                        placeholder="{{ 'Postal/Zip Code' | translate }}"
                        name="name"
                      />
                    </mat-form-field>
                    <mat-error *ngIf="submitted && secondFormGroup.controls['zipCode'].hasError('required')"
                      >{{ "Postal/Zip Code" | translate }} {{ "is required" | translate }}.</mat-error
                    >
                  </div>
                </div>
                <div class="agr agr--rider-detail mr-setup">
                  <mat-checkbox formControlName="agree"
                    >{{ "I agree to the APPTUNIX" | translate
                    }}<a class="ml-0 mr-0" style="cursor: default"
                      ><sup>{{ "TM" | translate }}</sup></a
                    >
                  </mat-checkbox>

                  <a style="cursor: pointer" (click)="goToCmsPages('/cms/terms-of-service')">{{ "Terms of Service" | translate }}</a
                  >, <a style="cursor: pointer" (click)="goToCmsPages('/cms/privacy-policy')"> {{ "Privacy Policy" | translate }}</a>
                  {{ "and" | translate }}
                  <a style="cursor: pointer" (click)="goToCmsPages('/cms/rider-contracts')"> {{ "Rider Contracts" | translate }}.</a>
                </div>
                <mat-error *ngIf="submitted && secondFormGroup.controls['agree'].invalid"> {{ "Checkbox is required" | translate }}.</mat-error>
              </div>
              <div class="address_btn">
                <div class="driver_nex-btn">
                  <button class="btn btn-secoundry" mat-button (click)="backToPrevious()">
                    {{ "Back" | translate }}
                  </button>
                </div>
                <div class="driver_nex-btn">
                  <button class="btn btn-primary" mat-button matStepperNext (click)="updateProfile('2', 'secondFormGroup', 'step2')">
                    {{ "Next" | translate }}
                  </button>
                </div>
              </div>
            </form>
          </mat-step>
          <!------start driver info-->
          <mat-step [stepControl]="fourFormGroup" [editable]="editable">
            <form [formGroup]="fourFormGroup">
              <ng-template matStepLabel>Payment</ng-template>

              <!-- <div class="deatil_form_outer"> -->
              <!-- <ul class="payment_last">
                                    <li class="borderbtm">
                                        <div class="list_inner fr">
                                            <mat-checkbox >Pay with APPTUNIX wallet $300</mat-checkbox>
                                        </div>
                                    </li>
                                    <li class="borderbtm">
                                        <div class="list_inner sc">
                                            <mat-checkbox >Pay With Paypal</mat-checkbox>
                                        </div>
                                    </li>
                                    <li class="half secv">
                                        <div >
                                            <a class="add-card">Add new card</a>
                                        </div>
                                    </li>
                                    <li class="borderbtm radio_cu">
                                        <mat-radio-group aria-label="Select an option">
                                            <mat-radio-button value="1">
                                                <div class="card_detailcs">
                                                    <h3>
                                                        •••• •••• •••• 4561<span>Mastercard - 06/23</span>
                                                    </h3>
                                                </div>
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </li>
                                    <li>
                                        <div class="redeem-promo-code">
                                            <mat-form-field [floatLabel]="'never'">
                                                <input class="form-control" matInput type="text "
                                                    placeholder="Redeem Loyalty points" name="name" readonly />
                                            </mat-form-field>
                                            <figure>
                                                <img src="assets/uimages/Shape.svg" alt="">
                                            </figure>
                                        </div>
                                    </li>
                                </ul> -->

              <!-- </div> -->
              <div class="request_ride_inner border_remove paymernt">
                <div class="title_bar sec">
                  <h2>{{ "Payment-Options" | translate }}</h2>
                </div>
                <ul class="payment_last">
                  <li>
                    <div class="card1">
                      <div class="card-innre-payment">
                        <div class="wallet-img">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-wallet2"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"
                            />
                          </svg>
                        </div>
                        <div class="balance">
                          <p>{{ commonService.currencyFormat(allSavedCardData?.walletAmount?.toFixed(2)) }}</p>
                          <p>{{ "Wallet Balance" | translate }}</p>
                        </div>
                      </div>
                      <div class="add-btn add-mny">
                        <i class="fa fa-plus" title="Add Money" style="cursor: pointer" aria-hidden="true" (click)="addWalletModal('block')"></i>
                      </div>
                    </div>
                  </li>
                  <li class="borderbtm waletli">
                    <div class="nsflex">
                      <div class="list_inner">
                        <mat-checkbox [checked]="isWalletSelected" (change)="onWalletSelected($event.checked)">
                          {{ "Pay via APPTUNIX Wallet" | translate }}</mat-checkbox
                        >
                      </div>
                      <div class="pay-op">
                        <div (click)="AddCardModal('block')">
                          <button class="btn btn-primary">{{ "Add Payment Card" | translate }}</button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="borderbtm">
                    <div class="list_inner">
                      <mat-checkbox>{{ "Pay With Paypal" | translate }}</mat-checkbox>
                    </div>
                  </li>
                  <li class="borderbtm newli" *ngIf="allSavedCardData.cards.length > 0">
                    <mat-radio-group aria-label="Select an option" (change)="onCardChange($event.value)">
                      <mat-radio-button [checked]="card?.isSelected" [value]="i + 1" *ngFor="let card of allSavedCard; let i = index">
                        <div class="card_detailcs">
                          <div>
                            <h3>
                              {{ card?.cardNumber
                              }}<span>{{ card?.brand }} - {{ card?.expiryDate.slice(0, 2) }}/{{ card?.expiryDate.slice(2) }}</span>
                            </h3>
                          </div>
                          <div class="dlt-radio">
                            <h3 *ngIf="card?.isDefault"><span class="text-success">PRIMARY CARD</span></h3>
                            <h3 *ngIf="!card?.isDefault" (click)="changeDefaultCard(card?._id)"><span style="color: #0171bd">MAKE PRIMARY</span></h3>
                            <i class="fa fa-trash" (click)="deleteCard(card?._id)" aria-hidden="true"></i>
                          </div>
                        </div>
                      </mat-radio-button>
                    </mat-radio-group>
                  </li>
                </ul>
              </div>
              <div class="address_btn sec">
                <div class="driver_nex-btn">
                  <button class="btn btn-secoundry" mat-button (click)="goToAddressTab(1)">
                    {{ "Back" | translate }}
                  </button>
                </div>
                <div class="driver_nex-btn">
                  <button class="btn btn-primary" mat-button matStepperNext (click)="goToridercongratulations()">
                    {{ "Done" | translate }}
                  </button>
                </div>
              </div>
            </form>
          </mat-step>
          <!------end driver info-->
          <!-----start vehicle detail-->
        </mat-horizontal-stepper>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="add_payment" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
      <div class="modal-body">
        <h3 class="add_card_title">{{ "Add a card" | translate }}</h3>
        <div class="card_card_form">
          <div class="add_card_input_itms">
            <input class="form-control" type="text" placeholder="{{ 'Card holder name' | translate }}" />
          </div>
          <div class="add_card_input_itms">
            <input class="form-control" type="text" placeholder="XXX XXX XXX " />
          </div>
          <div class="add_card_input_itms">
            <select class="form-control">
              <option>{{ "Month" | translate }}</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
              <option>6</option>
              <option>7</option>
              <option>8</option>
            </select>
          </div>
          <div class="add_card_input_itms">
            <select class="form-control">
              <option>{{ "year" | translate }}</option>
              <option>2020</option>
              <option>2021</option>
              <option>2022</option>
              <option>2023</option>
              <option>2024</option>
            </select>
          </div>
          <div class="add_card_input_itms">
            <input class="form-control" type="text" placeholder="CVV" />
          </div>
        </div>
      </div>
      <div class="btn_outer">
        <button class="btn btn-primary">{{ "Save" | translate }}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="add_payment" [ngStyle]="{ display: addCardModal, opacity: 1 }">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="AddCardModal('none')">
          <span aria-hidden="true ">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h3 class="add_card_title">{{ "Add Payment Card" | translate }}</h3>
        <div class="card_card_form">
          <div class="nsflex">
            <div class="add_card_input_itms mr-2">
              <label>{{ "First name" | translate }}</label>
              <input
                class="form-control"
                type="text"
                maxlength="50"
                (change)="onChangeFirst($event.target.value)"
                (keypress)="onKeyPress($event)"
                [formControl]="cardOwnerFirstName"
                [placeholder]="'First name' | translate"
              />
              <mat-error *ngIf="cardSubmitted && cardOwnerFirstName.hasError('required')"> {{ "First name is required" | translate }}.</mat-error>
              <mat-error *ngIf="cardSubmitted && validateFirstArr.includes(true)"> {{ "Please enter valid first name" | translate }}.</mat-error>
            </div>
            <div class="add_card_input_itms mr-2">
              <label>{{ "Last name" | translate }}</label>
              <input
                class="form-control"
                maxlength="50"
                (change)="onChangeSecond($event.target.value, 'cardOwnerLastName')"
                (keypress)="onKeyPress($event)"
                type="text"
                [formControl]="cardOwnerLastName"
                [placeholder]="'Last name' | translate"
              />
              <mat-error *ngIf="cardSubmitted && cardOwnerLastName.hasError('required')"> {{ "Last name is required" | translate }}.</mat-error>
              <mat-error *ngIf="cardSubmitted && cardOwnerLastName.value !== '' && validateSecondCardArr.includes(true)">
                {{ "Please enter valid last name" | translate }}.</mat-error
              >
            </div>
          </div>
          <div class="add_card_input_itms" style="width: 100%">
            <form [formGroup]="cardNumberGroup">
              <label>{{ "Card number" | translate }}</label>
              <input
                class="form-control"
                [formControl]="getCardNumberControl()"
                [textMask]="{ mask: cardMaskFunction, guide: false, showMask: true }"
                placeholder="**** **** **** ****"
              />
              <div *ngIf="cardSubmitted">
                <p
                  style="color: red; text-align: left !important"
                  *ngIf="cardNumberGroup.status === 'INVALID' && !cardNumberGroup.controls['cardNumber'].hasError('required')"
                >
                  {{ "Card number is invalid" | translate }}.
                </p>
                <!-- <p style="color: green; text-align: left !important;" *ngIf="cardNumberGroup.status === 'VALID'">
                                    Card number is valid.</p> -->
                <p style="color: red; text-align: left !important" *ngIf="cardNumberGroup.controls['cardNumber'].hasError('required')">
                  {{ "Card number is required" | translate }}.
                </p>
              </div>
            </form>
          </div>
          <div class="nsflex">
            <div class="add_card_input_itms months mr-2">
              <label>{{ "Month" | translate }}</label>
              <select class="form-control" [formControl]="expiryMonth">
                <option [value]="null" selected disabled>{{ "Month" | translate }}</option>
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
              <mat-error *ngIf="cardSubmitted && expiryMonth.hasError('required')"> {{ "Expiry month is required" | translate }}.</mat-error>
            </div>
            <div class="add_card_input_itms mr-2">
              <label>{{ "Year" | translate }}</label>
              <select class="form-control" [formControl]="expiryYear">
                <option [value]="null" selected disabled>{{ "Year" | translate }}</option>
                <option [value]="y" *ngFor="let y of expiryYears; let i = index">{{ y }}</option>
              </select>
              <mat-error *ngIf="cardSubmitted && expiryYear.hasError('required')"> {{ "Expiry year is required" | translate }}.</mat-error>
            </div>
            <div class="add_card_input_itms">
              <label>{{ "CCV/CVV" | translate }}</label>
              <input
                [formControl]="cvv"
                (copy)="(false)"
                (paste)="(false)"
                (keypress)="keypresshandler($event)"
                placeholder="{{ 'CCV/CVV' | translate }}"
                class="form-control"
                type="password"
                onKeyPress="if(this.value.length==4) return false;"
                placeholder=""
              />
              <mat-error *ngIf="cardSubmitted && cvv.hasError('required')">{{ "CCV/CVV" | translate }} {{ "is required" | translate }}.</mat-error>
            </div>
          </div>
        </div>
      </div>
      <button class="add_card" style="cursor: pointer" (click)="addDebitCard()">{{ "Save" | translate }}</button>
    </div>
  </div>
</div>

<div id="addpayment" class="modal fade" role="dialog" [ngStyle]="{ display: addWalletMoneyModal, opacity: 1 }">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button " class="close" (click)="addWalletModal('none')">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
      <div class="modal-body">
        <h4>{{ "Add Wallet Money" | translate }}</h4>
        <div class="seachbar-payment">
          <mat-form-field [floatLabel]="'never'">
            <input
              class="form-control"
              (keypress)="keypresshandler($event)"
              min="1"
              [formControl]="addWalletAmount"
              matInput
              type="text"
              placeholder="Amount"
            />
          </mat-form-field>
          <mat-error *ngIf="walletMoneySubmitted && addWalletAmount.hasError('required')">{{ "Wallet amount is required" | translate }}.</mat-error>
        </div>
        <ul class="payment-lt">
          <li>
            <div class="list_inner">
              <mat-radio-group aria-label="Select an option">
                <mat-radio-button
                  (change)="onWalletCardChange(card)"
                  [checked]="card?.isSelected"
                  [value]="i + 1"
                  *ngFor="let card of allSavedCard; let i = index"
                >
                  <div class="card_detailcs">
                    <div>
                      <h3>
                        {{ card?.cardNumber }}<span>{{ card?.brand }} - {{ card?.expiryDate.slice(0, 2) }}/{{ card?.expiryDate.slice(2) }}</span>
                      </h3>
                    </div>
                    <div class="dlt-radio">
                      <h3 *ngIf="card?.isDefault">
                        <span class="text-success" style="margin-left: 86px">{{ "PRIMARY CARD" | translate }}</span>
                      </h3>
                    </div>
                  </div>
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </li>
        </ul>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="addWalletMoney()">{{ "Proceed" | translate }}</button>
      </div>
    </div>
  </div>
</div>

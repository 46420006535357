import { Component, OnInit, NgZone, OnDestroy, Input, Output, EventEmitter, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { HttpsService } from "../services/https/https.service";
import { FormBuilder, Validators, FormGroup, FormControl, AbstractControl } from "@angular/forms";
import { MapsAPILoader } from "@agm/core";
import { CommonService } from "../services/common/common.service";
import { SearchCountryField, TooltipLabel, CountryISO } from "ngx-intl-tel-input";
import { environment } from "src/environments/environment";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { SocketioService } from "../services/sockets/socketio.service";
import { Event } from "../core/models/event";
import { Action } from "../core/models/action";
import Swal from "sweetalert2";
import * as moment from "moment";
import { BehaviorSubject, interval, Subject } from "rxjs";
import { takeWhile, takeUntil } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LocationStrategy, ViewportScroller } from "@angular/common";
import { fConstant } from "../core/constant";
import { luhnValidator } from "../core/validators/luhnValidator";
import { getValidationConfigFromCardNo } from "../core/helpers/card.helper";
import { IPayPalConfig, ICreateOrderRequest } from "ngx-paypal";
import { MessagingServiceService } from "src/app/services/messaging-service.service";
import { isDataSource } from "@angular/cdk/collections";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "src/app/services/language/language.service";
// import { param } from "jquery";
declare var $: any;
declare var google: any;
interface Food {
  value: string;
  viewValue: string;
}
@Component({
  selector: "app-riderdeliverybooking",
  templateUrl: "./riderdeliverybooking.component.html",
  styleUrls: ["./riderdeliverybooking.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class RiderdeliverybookingFComponent implements OnInit, OnDestroy {
  selected = "label";
  lat: number = 51.678418;
  lng: number = 7.809007;
  public map: any = { lat: 51.678418, lng: 7.809007 };
  nextPopup = 0;
  user: any;
  bookingForm: FormGroup;
  pickup = {
    lat: 39.85546,
    lng: -101.474162,
  };
  pickupAddress = "";
  dummy: any[] = [
    { amount: 2, isActive: false },
    { amount: 5, isActive: false },
    { amount: 8, isActive: false },
    { amount: 10, isActive: false },
  ];
  drop = {
    lat: 0,
    lng: 0,
  };
  current = {
    lat: 0,
    lng: 0,
  };
  // slides = [
  //   { img: "http://placehold.it/350x150/000000" },
  //   { img: "http://placehold.it/350x150/111111" },

  // ];

  slideConfig = { slidesToShow: 1.1, slidesToScroll: 1 };
  carouselConfig = {
    infinite: false,
    arrows: false,
    dots: true,
  };
  dropAddress = "";
  zoom: number = 12;
  // origin: any;
  // destination: any;
  showMarker: boolean = true;
  public renderOptions = {
    suppressMarkers: true,
  };
  originMarkerIcon: string = "assets/uimages/originmarker.png";
  destinationMarkerIcon: string = "assets/uimages/destination.png";
  marker1: string = "assets/markers/marker1.png";
  marker2: string = "assets/markers/marker2.png";
  marker3: string = "assets/markers/marker3.png";
  marker4: string = "assets/markers/marker4.png";
  marker5: string = "assets/markers/marker5.png";
  marker6: string = "assets/markers/marker6.png";
  marker7: string = "assets/markers/marker7.png";
  marker8: string = "assets/markers/marker8.png";
  marker9: string = "assets/markers/marker9.png";
  marker10: string = "assets/markers/marker10.png";
  marker11: string = "assets/markers/marker11.png";
  marker12: string = "assets/markers/marker12.png";
  marker13: string = "assets/markers/marker13.png";
  marker14: string = "assets/markers/marker14.png";
  marker15: string = "assets/markers/marker15.png";
  marker16: string = "assets/markers/marker16.png";
  marker17: string = "assets/markers/marker17.png";
  marker18: string = "assets/markers/marker18.png";
  marker19: string = "assets/markers/marker19.png";
  marker20: string = "assets/markers/marker20.png";
  marker21: string = "assets/markers/marker21.png";
  marker22: string = "assets/markers/marker22.png";
  marker23: string = "assets/markers/marker23.png";
  marker24: string = "assets/markers/marker24.png";
  marker25: string = "assets/markers/marker25.png";
  marker26: string = "assets/markers/marker26.png";
  markerOptions: any;
  foods: Food[] = [
    { value: "Home", viewValue: "Home" },
    { value: "Office", viewValue: "Office" },
    { value: "Other", viewValue: "Other" },
  ];

  i;
  packageType: any = [];
  requestForLaterForm: any;
  geoCoder: google.maps.Geocoder;
  distance: number;
  rideSubmited = false;
  origin;
  destination;
  isDateTimeSubmit: boolean;
  bookingFor: string = "Now";
  mindate = new Date();
  dropZipCode: string;
  dropProvince: string;
  dropCity: string;
  deliveryDetailForm: FormGroup;

  selectedCountryCode: string;

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.Canada];
  signupForm: FormGroup;
  submitted: boolean = false;
  isPhoneValid: boolean = true;
  detailSubmitted: boolean = false;
  packageSizeData: any = [];
  vehicleCategoryData: any = [];
  selectedVehicleData: any;
  baseUrl: string;
  selectedRideIndex = 0;
  selectedPackageSIze: any;
  selectedSizeIndex = 0;
  packageDetailForm: FormGroup;
  fileDataUrl: any;
  allFileUploadUrl: any = [];
  packageSubmitted: boolean = false;
  moreThenPackage: boolean = false;
  allPackagesDetail: any = [];
  activeBtn: string = "Now";
  negativeValidator: boolean = false;
  isDropChanged: boolean = false;
  isPickupChanged: boolean = false;
  assignedDriverData: any;
  requestData: any;
  distanceInKM: any;
  duration: any;
  showDirections: boolean = false;
  timerStart: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  bookingId: any;
  bookingTimeInH: number;
  cancelCounter = 0;
  nearByDriversData: any = [];
  ubericon = "assets/images/ic_car.svg";
  isTollRoute: boolean = false;
  notifier = new Subject();
  isDriverFavourite: boolean = false;
  //ratings
  @Input("rating") private partnerRating: number = 3;
  @Input("rating") private deliveryRating: number = 3;
  @Input("rating") private customerRating: number = 3;
  @Input("starCount") private starCount: number = 5;
  @Input("color") private color: string = "#0171bd";
  @Output() private ratingUpdated = new EventEmitter();
  reviewData = [
    { name: "Excellent", isActive: false },
    { name: "Very Good", isActive: false },
    { name: "Friendly", isActive: false },
    { name: "Good", isActive: false },
    { name: "Enjoy Service", isActive: false },
  ];
  selectedTip: number;

  private snackBarDuration: number = 2000;
  public ratingArr = [];
  isFeedBackSelected: boolean = false;
  feedBackQue = [{ que: "Was the driver on time?" }, { que: "Did the driver make your ride comfortabel?" }, { que: "Was the ride safe?" }];
  selectedFeedbckQna: any = [];
  wasTheDriverOnTime: FormControl = new FormControl(null, Validators.required);
  driverMakeYouComfortabel: FormControl = new FormControl(null, Validators.required);
  wasTheRideSafe: FormControl = new FormControl(null, Validators.required);
  selectedDriverReview: number;
  customRate: FormControl = new FormControl(null);
  selectedTipAmount: any;
  comment: FormControl = new FormControl(null);
  rating: number = 0;
  ratingSubmitted: boolean = false;
  selectedReviewData: any = "";
  driverReviewData: any;
  bookingResponseData: any;
  isDriveingStart: boolean = false;
  showPickMarker: boolean = true;
  driverLatLng: { lat: any; lng: any };
  driverIcon: {
    path: string;
    scale: number;
    strokeColor: string;
    strokeWeight: number;
    fillOpacity: number;
    fillColor: string;
    offset: string;
    rotation: number;
    flat: boolean;
    anchor: any;
  };
  isContactDetailHide: boolean = true;
  review: FormControl = new FormControl("");
  monitorForm: FormGroup;
  monitorSubmit: boolean = false;
  intervalSubscription: any;

  pickupFieldsCount: number = 1;
  dropupFieldsCount: number = 1;
  pickupfieldsArr: any = [{ name: "pickup1", lat: 0, lng: 0, address: "", distance: 0 }];
  // dropupfieldsArr: any = [{ name: 'dropup1', lat: 0, lng: 0, address: '', distance: 0 }];
  dropupfieldsArr: any = [];
  pickupForm: FormGroup;
  dropupForm: FormGroup;
  pickupFormObj: {};
  dropupFormObj: {};
  waypoints = [];
  distancesForPath: any = [];
  calculated: boolean = true;
  calculateSubscription: any;
  directionStatus: any;
  showDropMarker: boolean = false;
  itemDropAddress: { lat: any; lng: any; address: any; distance: number };
  itemDropDetails: {
    deliveryTo: any;
    itemDropup: { lat: any; lng: any; address: any; distance: number };
    streetAdd: any;
    suiteAptOffice: any;
    city: any;
    province: any;
    zipCode: any;
    additionalInfo: any;
    phoneNo: any;
    countryCode: any;
  };
  driverMessage: string;
  distanceDuration: any = "1 min";
  isDriverLatLngUpdated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isDriverLatLngUpdated = this.isDriverLatLngUpdated$.asObservable();
  myModal23_user: string = "none";
  display: string = "none";
  cardNumberGroup: FormGroup;
  cardOwnerFirstName: FormControl = new FormControl("", Validators.required);
  cardOwnerLastName: FormControl = new FormControl("");
  expiryMonth: FormControl = new FormControl(null, Validators.required);
  expiryYear: FormControl = new FormControl(null, Validators.required);
  cvv: FormControl = new FormControl(null, Validators.required);
  expiryYears = [];
  cardSubmitted: boolean = false;
  cvvLength: number = 3;
  charCodeArr: any = [
    96, 49, 50, 51, 52, 53, 54, 55, 56, 57, 48, 45, 61, 91, 93, 92, 59, 44, 46, 47, 126, 33, 64, 35, 36, 37, 94, 38, 42, 40, 41, 95, 43, 123, 125,
    124, 58, 34, 60, 62, 63,
  ];
  validateFirstArr = [];
  validateSecondArr = [];
  allSavedCard: any = [];
  defaultCardDetails: any;
  selectedCardDetails: any;
  allSavedCardData: any = [];
  addCardModal: string = "none";
  isWalletSelected: boolean = false;
  selectedWalletCardId: any;
  addWalletMoneyModal: string = "none";
  addWalletAmount: FormControl = new FormControl("", Validators.required);
  walletMoneySubmitted: boolean = false;
  public payPalConfig?: IPayPalConfig;
  loyalityModal: string = "none";
  loyalityData: any = [];
  monitorRideModal: string = "none";
  totalLoyality: string = "";
  totalDistance: number;
  totalEta: number;
  vehicleCarouselConfig = {
    infinite: false,
    arrows: false,
    dots: true,
    slidesToScroll: 1,
    slidesToShow: 3.1,
  };
  messagesData = {
    YOUR_DRIVER_IS_ARIVING_IN: "Your Driver is ariving in",
    YOUR_DRIVER_ARRIVED_TO_PICK_UP_RIDER_IN_LOCATION: "Your driver arrived to pick up rider in location",
    YOUR_DRIVER_IS_ARRIVING: "Your driver is arriving",
    MIN: "min",
    YOUR_DRIVER_ARRIVED_TO_PICK_YOU_ALL_UP: "Your driver arrived to pick you all up",
    YOUR_DRIVER_ARRIVED_TO_PICK_YOU_UP: "Your driver arrived to pick you up",
    YOU_STARTED_YOUR_RIDE: "You started your ride",
    YOU_ARRIVED_AT_YOUR_DESTINATION: "You arrived at your destination",
    YOUR_RIDE_HAS_BEEN_CANCELLED_BY_DRIVER: "Your ride has been cancelled by driver",
    YOUR_Delivery_HAS_BEEN_CANCELLED_BY_DRIVER: "Your delivery has been cancelled by driver",
    OOPS_NO_DRIVER_AVAILABLE_NEARBY_PLEASE_TRY_AGAIN_LATER: "Oops!.. No driver available nearby, please try again later",
    REACH_YOUR_DESTINATION_IN: "Reach your destination in",
    ARRIVED_AT_YOUR_DESTINATION: "Arrived at your destination",
    YOUR_RIDES_HAVE_STARTED: "Your rides have started",
    ARRIVED_AT_DROP_OFF_LOCATION: "Arrived at drop-off location",
    REACH_DROP_OFF_LOCATION: "Reach drop off location",
    ARRIVED_AT_FINAL_DESTINATION: "Arrived at final destination",
    IN: "in",
    YOU_HAVE_INSUFFICIENT_BALANCE_IN_YOUR_WALLET: "You have insufficient balance in your wallet",
    YOUR_PAYMENT_CARD_HAS_BEEN_ADDED_SUCCESSFULLY: "Your payment card has been added successfully",
    YOUR_DRIVER_HAS_CANCELED_DELIVERY_AT_DROP_OFF_LOCATION: "Your driver has canceled delivery at drop off location",
    HEADING_TO_NEXT_LOCATION: "heading to next location",
    Delivery_Canceled: "Delivery Canceled",
    REASON: "Reason",
    YOUR_DRIVER_ARRIVED_TO_PICK_YOU_UP_YOUR_DELIVERY_ITEMS: "Your driver arrived to pick you up your delivery items",
    DRIVER_PICKED_UP_AVAILABLE_ITEMS_FOR_DELIVERY_STATUS_PLEASE_CHECK_THE_APP:
      "Driver picked up available items. For delivery status please check the App",
    DRIVER_HAS_ARRIVED_AT_DESTINATION: "Driver has arrived at destination",
    YOUR_PACKAGE_HAS_BEEN_DELIVERED: "Your package has been delivered",
    YOUR_DRIVER_WILL_REACH_THE_DROP_OFF_LOCATION: "Your driver will reach the  drop off location",
    ARRIVED_AT_LAST_DROP_OFF_LOCATION: "Arrived at last drop-off location",
    YOUR_PACKAGE_COULD_NOT_BE_DELIVERED_BECAUSE: "Your package could not be delivered because",
    DELIVERY: "Delivery",
    ARE_BEING_RETURNED_TO_YOU: "are being returned to you",
    IS_BEING_RETURNED_TO_YOU: "is being returned to you",
    HAS_BEEN_RETURNED_YOU: "has been returned you",
    YOUR_PACKAGE_HAS_BEEN_RETURNED_TO_YOU: "Your package has been returned to you",
    AND_IT_IS_BEING_RETURNED_TO_YOU: "and it is being returned to you",
    COPIED_TO_CLIPBOARD: "Copied to clipboard",
  };
  showLang: boolean = false;
  updateDriverLatLongToUser: any;
  monitorModal: string = "none";

  currentPageA: any = 1;
  previousBtnEnabledA: boolean = false;
  nextBtnEnabledA: boolean = false;
  totalPagesA: any = 1;
  favAddData: any = [];
  selectedAddressIndex: number;
  isResetField: boolean = true;
  addressType: string = "pick";
  favAddPayload: any;
  favAddModal: string = "none";
  favAddressSubmitted: boolean = false;
  cancelReasons: any;
  cancelationMessage: any;
  canceledAddress: any;
  cancelDialog: string = "none";

  constructor(
    private router: Router,
    private http: HttpsService,
    private fb: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public commonService: CommonService,
    private ngxService: NgxUiLoaderService,
    private socketService: SocketioService,
    private location: LocationStrategy,
    private snackBar: MatSnackBar,
    public constant: fConstant,
    private vps: ViewportScroller,
    public messagingService: MessagingServiceService,
    public translate: TranslateService,
    public languageService: LanguageService
  ) {
    this.baseUrl = environment.imageUrl;
    var languageCode;
    if (localStorage.languageCode !== undefined) {
      languageCode = localStorage.getItem("languageCode");
    } else {
      languageCode = "en";
    }
    // this.translate.use(languageCode);
    // this.languageService.translateLanguage(languageCode);
  }

  ngOnInit(): void {
    this.getChange();
    this.languageService.isLangChange.subscribe((isChange: boolean) => {
      if (isChange) {
        console.log(this.router.url);
        if (this.nextPopup == 9 || this.nextPopup == 4 || this.nextPopup == 3) {
          this.getChange().then((result: any) => {
            this.commonService.bookingDataForLanguageUpdateOnly && this.goToBookingStatus(this.commonService.bookingDataForLanguageUpdateOnly);
          });
        }
      }
    });
    this.payPalInitConfig("INR", 9.0);
    this.socketService.initSocket();
    this.pickupFormObj = {};
    this.pickupfieldsArr.forEach((element) => {
      let key = element.name;
      this.pickupFormObj[key] = new FormControl("", Validators.required);
    });
    this.pickupForm = this.fb.group(this.pickupFormObj);

    this.dropupFormObj = {};
    this.dropupfieldsArr.forEach((element) => {
      let key = element.name;
      this.dropupFormObj[key] = new FormControl("", Validators.required);
    });
    this.dropupForm = this.fb.group(this.dropupFormObj);
    if (this.commonService.bookingResponseData) {
      if (
        this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED ||
        this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.ARRIVED ||
        this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.STARTED ||
        this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.ONGOING ||
        this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.PARTIAL ||
        (this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.PENDING &&
          !this.commonService.bookingResponseData.isSheduledBooking) ||
        this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.OUT_FOR_DELIVERY ||
        this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.PACKAGES_PICKED
      ) {
        this.goToBookingStatus(this.commonService.bookingResponseData);
      }
      this.timerStart.next(false);
    }
    this.markerOptions = {
      origin: {
        icon: this.originMarkerIcon,
      },
      destination: {
        icon: this.destinationMarkerIcon,
      },
    };
    this.calculateSubscription = setInterval(() => {
      this.calculated = false;
      this.updateDriverLocation();
      console.log("ok");
    }, 30000);
    this.preventBackButton();
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
    // this.bookingForm = this.fb.group({
    //   ridePickUpAddress: [null, Validators.required],
    //   rideDropAddress: [null, Validators.required]
    // });
    this.requestForLaterForm = this.fb.group({
      dateTime: [null, Validators.required],
    });
    this.deliveryDetailForm = this.fb.group({
      deliveryTo: ["", Validators.required],
      // itemDropup: ['', Validators.required],
      streetAdd: ["", Validators.required],
      suiteAptOffice: ["", Validators.required],
      city: ["", Validators.required],
      province: ["", Validators.required],
      zipCode: ["", Validators.required],
      phoneNo: ["", Validators.required],
      additionalInfo: [""],
    });
    this.packageDetailForm = this.fb.group({
      packageType: ["", Validators.required],
      itemDesc: ["", Validators.required],
      quantity: ["", Validators.required],
      file: ["", Validators.required],
      itemDropup: ["", Validators.required],
    });
    this.monitorForm = this.fb.group({
      name: ["", Validators.required],
      phoneNo: ["", Validators.required],
    });
    this.packageDetailForm
      .get("quantity")
      .valueChanges.pipe(takeUntil(this.notifier))
      .subscribe((val: any) => {
        if (val <= 0) {
          this.negativeValidator = true;
        } else if (val >= 1000) {
          this.negativeValidator = true;
        } else {
          this.negativeValidator = false;
        }
      });
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
      // this.initializeMapPosition();
    });
    this.getUser();
    if (this.commonService.origin) {
      if (this.commonService.origin.lat !== 0 && this.commonService.origin.lng !== 0) {
        this.pickupForm.get("pickup1").patchValue(this.commonService.origin.address);
        this.pickupfieldsArr[0].lat = this.commonService.origin.lat;
        this.pickupfieldsArr[0].lng = this.commonService.origin.lng;
        this.pickupfieldsArr[0].address = this.commonService.origin.address;
        this.pickup.lat = this.commonService.origin.lat;
        this.pickup.lng = this.commonService.origin.lng;
        this.current.lat = this.commonService.origin.lat;
        this.current.lng = this.commonService.origin.lng;
        this.zoom = 15;
        this.getPickupAddress({ lat: this.commonService.origin.lat, lng: this.commonService.origin.lng }, this.pickupfieldsArr[0]);
        this.getNearbyDrivers({
          lat: this.commonService.origin.lat,
          lng: this.commonService.origin.lng,
        });
      }
    }
    // this.timerStart.pipe(takeUntil(this.notifier)).subscribe((val: boolean) => {
    //   if (val) {
    //     if (localStorage.isCancelCounterStopped !== undefined) {
    //       if (localStorage.isCancelCounterStopped === "true") {
    //         this.cancelCounter = 0;
    //         localStorage.removeItem("isCancelCounterStopped");
    //       } else {
    //         this.startCounter();
    //       }
    //     } else {
    //       this.startCounter();
    //     }
    //   } else {
    //     this.cancelCounter = 0;
    //   }
    // });
    this.customRate.valueChanges.subscribe((value: any) => {
      this.selectedTip = 10000;
    });
    this.listenSocketEvent();
    this.review.valueChanges.subscribe((value: any) => {
      if (value) {
        this.selectedReviewData = value;
      } else {
        this.selectedReviewData = "";
      }
    });
    this.getAllCard();
    this.removeDriverFromMap();
  }

  selectLang(language) {
    this.languageService.selectedLang = language;
    this.languageService.translateLanguage(language.code);
    this.showLang = false;
  }

  startCounter() {
    this.cancelCounter = 10;
    if (this.intervalSubscription) {
      clearInterval(this.intervalSubscription);
      this.cancelCounter = 10;
    }
    this.intervalSubscription = setInterval(() => {
      if (this.cancelCounter <= 0) {
        localStorage.isCancelCounterStopped = true;
        clearInterval(this.intervalSubscription);
      }
      this.cancelCounter--;
    }, 1000);
  }

  removeDriverFromMap() {
    this.socketService
      .onEvent(Event.removeDriverFromMap)
      .pipe(takeUntil(this.notifier))
      .subscribe((response: any) => {
        console.log("removeDriverFromMap", response);
        var driversIds = this.nearByDriversData.map((values: any) => values.id);
        if (!response.isBusy && response.data.isOnline) {
          if (!driversIds.includes(response.data.id)) {
            this.nearByDriversData.push({
              latitude: response.data.latitude,
              longitude: response.data.longitude,
              id: response.data.id,
            });
          }
        } else if (!response.isBusy && !response.data.isOnline) {
          if (driversIds.includes(response.data.id)) {
            var index = this.nearByDriversData.findIndex((x) => x.id === response.data.id);
            this.nearByDriversData.splice(index, 1);
          }
        }
      });
  }

  preventBackButton() {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  getUser() {
    this.http
      .httpGetWithHeaderUser("userProfile", "")
      .pipe(takeUntil(this.notifier))
      .subscribe((res: any) => {
        if (res.statusCode === 200) {
          this.user = res.data;
          if (res.data.role === "DELIVERY_PARTNER") {
            this.commonService.isThisDeliveryPartner = true;
          } else if (res.data.role === "RIDER") {
            this.commonService.isThisDeliveryPartner = false;
          }
        }
      });
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.pickupfieldsArr[0].lat = position.coords.latitude;
          this.pickupfieldsArr[0].lng = position.coords.longitude;
          this.pickup.lat = position.coords.latitude;
          this.pickup.lng = position.coords.longitude;
          this.current.lat = position.coords.latitude;
          this.current.lng = position.coords.longitude;
          this.zoom = 15;
          this.getPickupAddress({ lat: position.coords.latitude, lng: position.coords.longitude }, this.pickupfieldsArr[0]);
          this.getNearbyDrivers({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  onMapReady(map: any) {
    this.map = map;
    if (!this.commonService.origin) {
      this.getCurrentLocation();
    }
    if (map) {
      map.setOptions({
        streetViewControl: false,
      });
    }
  }

  markerDragEndOrigin(event: any, data) {
    if (event.latLng) {
      data.lat = event.latLng.lat();
      data.lng = event.latLng.lng();
      this.getPickupAddress({ lat: data.lat, lng: data.lng }, data);
      // this.getNearbyDrivers(this.pickup);
    } else if (event.coords) {
      data.lat = event.coords.lat;
      data.lng = event.coords.lng;
      this.getPickupAddress({ lat: data.lat, lng: data.lng }, data);
      // this.getPickupAddress(this.pickup);
      // this.getNearbyDrivers(this.pickup);
    }
  }
  markerDragEndDestination(event: any, data) {
    if (event.latLng) {
      data.addressDetails.itemDropup.lat = event.latLng.lat();
      data.addressDetails.itemDropup.lng = event.latLng.lng();
      this.getAddressDrop(
        {
          lat: data.addressDetails?.itemDropup.lat,
          lng: data.addressDetails.itemDropup.lng,
        },
        data
      );
    } else if (event.coords) {
      data.addressDetails.itemDropup.lat = event.coords.lat;
      data.addressDetails.itemDropup.lng = event.coords.lng;
      // this.drop.lat = this.dropupfieldsArr[]
      this.getAddressDrop(
        {
          lat: data.addressDetails?.itemDropup.lat,
          lng: data.addressDetails.itemDropup.lng,
        },
        data
      );
    }
  }

  handlePickUpChnage(address, data, favData: any = null) {
    this.isPickupChanged = true;
    data = favData ? this.pickupfieldsArr[this.selectedAddressIndex] : data;
    if (address.formatted_address || favData) {
      this.pickup.lat = 0;
      this.pickup.lng = 0;
      this.pickupAddress = favData ? favData.address : address.formatted_address;
      var lat = favData ? favData.latitude : address.geometry.location.lat();
      var lng = favData ? favData.longitude : address.geometry.location.lng();
      !favData &&
        this.pickupfieldsArr.forEach((element) => {
          if (element.name === data.name) {
            element.lat = lat;
            element.lng = lng;
          }
        });
      favData && (this.pickupfieldsArr[this.selectedAddressIndex].lat = lat);
      favData && (this.pickupfieldsArr[this.selectedAddressIndex].lng = lng);
      this.pickup.lat = this.pickupfieldsArr[0].lat;
      this.pickup.lng = this.pickupfieldsArr[0].lng;
      // this.getPickupAddress(this.pickup);
      // this.getNearbyDrivers(this.pickup);
      // this.bookingForm.get('ridePickUpAddress').patchValue(this.pickupAddress);
      data.address = favData ? favData.address : address.formatted_address;
      this.pickupForm.get(data.name).patchValue(this.pickupAddress);
      console.log("data", data);
    }
  }

  handleDropChnage(address, data, favData: any = null) {
    console.log(address);
    data = favData ? this.pickupfieldsArr[this.selectedAddressIndex] : data;
    this.drop.lat = 0;
    this.drop.lng = 0;
    if (address.formatted_address || favData) {
      this.dropAddress = favData ? favData.address : address.formatted_address;
      var lat = favData ? favData.latitude : address.geometry.location.lat();
      var lng = favData ? favData.longitude : address.geometry.location.lng();
      !favData &&
        this.dropupfieldsArr.forEach((element) => {
          if (element.name === data.name) {
            element.lat = lat;
            element.lng = lng;
          }
        });

      favData && (this.dropupfieldsArr[this.selectedAddressIndex].lat = lat);
      favData && (this.dropupfieldsArr[this.selectedAddressIndex].lng = lng);
      var droplength = this.dropupfieldsArr.length;
      this.drop.lat = this.dropupfieldsArr[droplength - 1].lat;
      this.drop.lng = this.dropupfieldsArr[droplength - 1].lng;
      this.showDropMarker = true;

      data.address = favData ? favData.address : address.formatted_address;
      // this.getDirection();
      // this.getDistanceMatrix(this.dropAddress, {lat: lat, lng: lng});
      // this.matrix({ lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, { lat: this.dropupfieldsArr[droplength - 1].lat, lng: this.dropupfieldsArr[droplength - 1].lng }, data.name);
      this.getAddressDrop(this.drop, data, favData);
    }
    if (address.types) {
      if (address.types.includes("airport")) {
        this.isTollRoute = true;
      } else {
        this.isTollRoute = false;
      }
    }
  }

  getDirection() {
    if (this.pickup.lng !== null && this.pickup.lng !== null && this.drop.lat !== null && this.drop.lng !== null) {
      this.origin = { lat: this.pickup.lat, lng: this.pickup.lng };
      this.destination = { lat: this.drop.lat, lng: this.drop.lng };
      this.showDirections = true;
      this.showPickMarker = false;
      this.calculateDistance();
    }
    this.showMarker = false;
  }

  calculateDistance() {
    const start = new google.maps.LatLng(this.pickupfieldsArr[0].lat, this.pickupfieldsArr[0].lng);
    const end = new google.maps.LatLng(
      this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat,
      this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng
    );
    this.distance = google.maps.geometry.spherical.computeDistanceBetween(start, end); // distance in Meter
    return this.distance;
  }

  getFare(pricePerMiles: any) {
    var d = this.distance / 1.609; //convert KM into MILES
    var fare = d * pricePerMiles;
    return Math.round(fare);
  }

  getPickupAddress(latLng, data) {
    this.geoCoder.geocode({ location: { lat: latLng.lat, lng: latLng.lng } }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          this.zoom = 15;
          this.isPickupChanged = true;
          this.pickupAddress = results[0].formatted_address;
          data.address = results[0].formatted_address;
          if (data.name) {
            this.pickupForm.controls[data.name].patchValue(results[0].formatted_address);
          }
          // this.getDirection();
          // this.displayWaypoints = false;
        } else {
          // window.alert('No results found');
        }
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }
    });
    // this.checkstatusMap();
  }

  dropChange() {
    // this.isDropChanged = false;
  }

  onKeyUp(value, pickup) {
    if (value === "") {
      pickup.lat = 0;
      pickup.lng = 0;
      this.pickupForm.controls[pickup.name].patchValue(null);
    }
  }

  pickupChange(pickup) {
    if (pickup.lat === 0 && pickup.lng === 0) {
      this.pickupForm.controls[pickup.name].patchValue(null);
      this.submitted = true;
    }
  }

  getAddressDrop(latLng, data, favData: any = null) {
    this.geoCoder.geocode({ location: { lat: latLng.lat, lng: latLng.lng } }, (results, status) => {
      console.log(results);
      if (status === "OK") {
        if (results[0]) {
          this.zoom = 12;
          this.isDropChanged = true;
          this.dropAddress = favData ? favData?.address : results[0].formatted_address;
          var addLength = results[0].address_components.length;
          var streetName = favData
            ? favData?.streetAddress
            : results[0].address_components[0].long_name + " " + results[0].address_components[1].long_name;
          this.dropZipCode = favData ? favData?.zipCode : results[0].address_components[addLength - 1].long_name;
          this.dropProvince = favData ? favData?.province : results[0].address_components[addLength - 3].long_name;
          this.dropCity = favData ? favData?.city : results[0].address_components[addLength - 4].long_name;
          if (data) {
            data.addressDetails.streetAdd = streetName;
            data.addressDetails.city = this.dropCity;
            data.addressDetails.province = this.dropProvince;
            data.addressDetails.zipCode = this.dropZipCode;
            data.addressDetails.itemDropup.address = this.dropAddress;
          } else {
            this.deliveryDetailForm.controls["streetAdd"].patchValue(streetName);
            this.deliveryDetailForm.controls["city"].patchValue(this.dropCity);
            this.deliveryDetailForm.controls["province"].patchValue(this.dropProvince);
            this.deliveryDetailForm.controls["zipCode"].patchValue(this.dropZipCode);
            this.packageDetailForm.controls["itemDropup"].patchValue(this.dropAddress);
          }

          // data.itemDroupup.address = this.dropAddress;
        } else {
          // window.alert('No results found');
        }
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  getNearbyDrivers(position) {
    var param = {
      latitude: position.lat,
      longitude: position.lng,
      pageNo: 1,
    };
    this.http
      .httpPostWithHeaderUser("getNearbyDriversWithGeoFenceId", param)
      .pipe(takeUntil(this.notifier))
      .subscribe((res: any) => {
        if (res.statusCode === 200) {
          this.nearByDriversData = res.data.drivers;
        }
      });
  }

  startNow(index) {
    this.rideSubmited = true;
    this.activeBtn = "Now";
    if (this.pickupForm.valid) {
      // this.continue(index);
      this.goToNext(11);
      this.rideSubmited = false;
      var date = new Date();
      var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
      var offset = date.getTimezoneOffset() / 60;
      this.bookingTimeInH = date.getHours();
    }
  }

  openDateTimeModal() {
    this.rideSubmited = true;
    this.activeBtn = "Later";
    if (this.pickupForm.valid) {
      // $("#exampleModall").modal('show');
      this.display = "block";
      this.rideSubmited = false;
      var date = new Date();
      var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
      var offset = date.getTimezoneOffset() / 60;
      this.bookingTimeInH = date.getHours();
    }
  }

  closeDateTimeModal() {
    this.display = "none";
  }

  getVehicleForLater() {
    this.isDateTimeSubmit = true;
    if (this.requestForLaterForm.valid) {
      if (this.getTimeForLater()) {
        this.display = "none";
        this.bookingFor = "Later";
        this.http
          .httpPostWithHeaderUser("getPackageType", "")
          .pipe(takeUntil(this.notifier))
          .subscribe((res: any) => {
            if (res.statusCode == 200) {
              this.packageType = res.data.packageTypeData;
              this.continue(11);
            }
          });
      }
      // $('#exampleModall').modal('hide');

      // this.requestForLaterForm.reset();
      // this.isDateTimeSubmit = false;
      // this.bookingForm.reset();
      // this.rideSubmited = false;
    }
  }

  getTimeForLater() {
    var selectedDate = this.requestForLaterForm.value.dateTime;
    var minutesToAdd = 15;
    var currentDate = new Date();
    var futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);
    var difference = futureDate.getTime() - selectedDate.getTime(); // This will give difference in milliseconds
    var resultInMinutes = Math.round(difference / 60000);
    if (resultInMinutes <= 0) {
      return true;
    } else {
      this.commonService.OpenNotificationModal("success", "top-end", "Minimum time is 15 minutes from current time");
      return false;
    }
  }

  goToNext(index) {
    this.detailSubmitted = true;
    // if (this.deliveryDetailForm.valid) {
    this.packageType = [];
    this.http
      .httpPostWithHeaderUser("getPackageType", "")
      .pipe(takeUntil(this.notifier))
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.packageType = res.data.packageTypeData;
          this.continue(index);
        }
      });
    // }
  }

  goToPackageDetail(index) {
    this.detailSubmitted = true;
    if (this.deliveryDetailForm.valid) {
      var codeLength = this.deliveryDetailForm.value.phoneNo.dialCode.length;
      this.itemDropDetails = {
        deliveryTo: this.deliveryDetailForm.value.deliveryTo,
        itemDropup: this.itemDropAddress,
        streetAdd: this.deliveryDetailForm.value.streetAdd,
        suiteAptOffice: this.deliveryDetailForm.value.suiteAptOffice,
        city: this.deliveryDetailForm.value.city,
        province: this.deliveryDetailForm.value.province,
        zipCode: this.deliveryDetailForm.value.zipCode,
        additionalInfo: this.deliveryDetailForm.value.additionalInfo,
        phoneNo: this.deliveryDetailForm.value.phoneNo.e164Number.slice(codeLength),
        countryCode: this.deliveryDetailForm.value.phoneNo.dialCode,
      };
      console.log("itemDropDetails", this.itemDropDetails);
      this.getAllCard();
      this.continue(11);
    }
  }

  goToSelectPackageSize(index) {
    this.ngxService.start();
    console.log("testing", this.dropupfieldsArr);
    var type = this.packageDetailForm.get("packageType").value;
    var desc = this.packageDetailForm.get("itemDesc").value;
    var quantity = this.packageDetailForm.get("quantity").value;
    if (type != null || desc != null || quantity != null) {
      this.packageSubmitted = true;
      if (this.packageDetailForm.valid && this.packageDetailForm.value.quantity > 0 && this.packageDetailForm.value.quantity < 1000) {
        this.addItem();
        this.http
          .httpPostWithHeaderUser("getPackageSize", "")
          .pipe(takeUntil(this.notifier))
          .subscribe((res: any) => {
            if (res.statusCode === 200) {
              this.packageSizeData = res.data.packageSizeData;
              this.selectedPackageSIze = res.data.packageSizeData[0];
              this.ngxService.stop();
              this.destinationPathOptimization();
              // let pickup = { lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng };
              // let dropDetail = this.allPackagesDetail[this.allPackagesDetail.length - 1].addressDetails.itemDropup;
              // let drop = { lat: dropDetail.lat, lng: dropDetail.lng };
              // this.matrix(pickup, drop, null).then((value: any) => {
              //   if (value) {
              //     this.getVehicle(index);
              //   }
              // });
              setTimeout(() => {
                this.getTotalEtaAndDistance().then((total: any) => {
                  if (total) {
                    this.distance = this.totalDistance;
                    this.duration = this.totalEta;
                    this.getVehicle(index);
                    console.log("Total Distance", this.distance);
                    console.log("Total ETA", this.duration);
                  }
                });
              }, 1000);
            }
          });
      } else {
        this.ngxService.stop();
      }
    } else {
      if (this.allPackagesDetail.length >= 1) {
        this.packageSubmitted = false;
        // if (this.packageDetailForm.valid) {
        this.http
          .httpPostWithHeaderUser("getPackageSize", "")
          .pipe(takeUntil(this.notifier))
          .subscribe((res: any) => {
            if (res.statusCode === 200) {
              this.packageSizeData = res.data.packageSizeData;
              this.selectedPackageSIze = res.data.packageSizeData[0];
              this.ngxService.stop();
              // let pickup = { lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng };
              // let dropDetail = this.allPackagesDetail[this.allPackagesDetail.length - 1].addressDetails.itemDropup;
              // let drop = { lat: dropDetail.lat, lng: dropDetail.lng };
              // this.matrix(pickup, drop, null).then((value: any) => {
              //   if (value) {
              //     this.getVehicle(index);
              //   }
              // });
              setTimeout(() => {
                this.getTotalEtaAndDistance().then((total: any) => {
                  if (total) {
                    console.log("Total", this.totalDistance, this.totalEta);
                    this.distance = this.totalDistance;
                    this.duration = this.totalEta;
                    this.getVehicle(index);
                  }
                });
              }, 1000);
            }
          });
        // }
      } else {
        this.ngxService.stop();
      }
    }
  }

  getTotalEtaAndDistance() {
    return new Promise((resolve, reject) => {
      // if (this.pickupfieldsArr.length > this.dropupfieldsArr.length) {
      //   this.totalDistance = 0;
      //   this.totalEta = 0;
      //   this.matrix(
      //     { lat: this.pickupfieldsArr[this.pickupfieldsArr.length - 1].lat, lng: this.pickupfieldsArr[this.pickupfieldsArr.length - 1].lng },
      //     { lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng }, null).then((result: any) => {
      //       this.totalDistance += result.distance;
      //       this.totalEta += parseFloat((result.duration).toFixed(2));
      //       var counter = 0;
      //       for (let i = 0; i < this.pickupfieldsArr.length - 1; i++) {
      //         this.matrix(
      //           { lat: this.pickupfieldsArr[i].lat, lng: this.pickupfieldsArr[i].lng },
      //           { lat: this.pickupfieldsArr[i + 1].lat, lng: this.pickupfieldsArr[i + 1].lng }, null).then((result: any) => {
      //             this.totalDistance += result.distance;
      //             this.totalEta += parseFloat((result.duration).toFixed(2));
      //             counter++;
      //             if (counter === this.pickupfieldsArr.length - 1) {
      //               return resolve(true);
      //             }
      //           });
      //       }
      //     });
      // } else this.allPackagesDetail[this.allPackagesDetail.length - 1].addressDetails.itemDropup
      if (this.allPackagesDetail.length > 1) {
        this.totalDistance = 0;
        this.totalEta = 0;
        this.matrix(
          {
            lat: this.pickupfieldsArr[0].lat,
            lng: this.pickupfieldsArr[0].lng,
          },
          {
            lat: this.allPackagesDetail[0].addressDetails.itemDropup.lat,
            lng: this.allPackagesDetail[0].addressDetails.itemDropup.lng,
          },
          null
        ).then((result: any) => {
          this.totalDistance += result.distance;
          this.totalEta += parseFloat(result.duration.toFixed(2));
          var counter = 0;
          for (let i = 0; i < this.allPackagesDetail.length - 1; i++) {
            if (this.allPackagesDetail.length - 1 >= i + 1) {
              this.matrix(
                {
                  lat: this.allPackagesDetail[i].addressDetails.itemDropup.lat,
                  lng: this.allPackagesDetail[i].addressDetails.itemDropup.lng,
                },
                {
                  lat: this.allPackagesDetail[i + 1].addressDetails.itemDropup.lat,
                  lng: this.allPackagesDetail[i + 1].addressDetails.itemDropup.lng,
                },
                null
              ).then((result: any) => {
                this.totalDistance += result.distance;
                this.totalEta += parseFloat(result.duration.toFixed(2));
                counter++;
                if (counter === this.allPackagesDetail.length - 1) {
                  return resolve(true);
                }
              });
            }
          }
        });
      } else if (this.pickupfieldsArr.length === this.allPackagesDetail.length) {
        this.totalDistance = 0;
        this.totalEta = 0;
        this.matrix(
          {
            lat: this.pickupfieldsArr[0].lat,
            lng: this.pickupfieldsArr[0].lng,
          },
          {
            lat: this.allPackagesDetail[this.allPackagesDetail.length - 1].addressDetails.itemDropup.lat,
            lng: this.allPackagesDetail[this.allPackagesDetail.length - 1].addressDetails.itemDropup.lng,
          },
          null
        ).then((result: any) => {
          this.totalDistance += result.distance;
          this.totalEta += parseFloat(result.duration.toFixed(2));
          return resolve(true);
        });
      }
    });
  }

  getVehicle(poupType) {
    console.log(this.deliveryDetailForm.controls);
    this.rideSubmited = true;
    // if (this.pickupForm.valid) {
    // this.originPathOptimization();
    this.ngxService.start();
    var param = {
      pageNo: 1,
      latitude: this.pickupfieldsArr[0].lat,
      longitude: this.pickupfieldsArr[0].lng,
    };
    this.http
      .httpPostWithHeaderUser("getVehicleCategoryInGeofence", param)
      .pipe(takeUntil(this.notifier))
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.continue(poupType);
          var arr = [];
          arr = res.data.vehicleCategoryData;
          this.vehicleCategoryData = [];
          var indexElement = 0;
          if (arr.length > 0) {
            arr.forEach((element) => {
              indexElement++;
              var transactionFee = res.data.geofenceData.transactionFee ? res.data.geofenceData.transactionFee : 0;
              var transactionFeeDiscount;
              var distancePrice = parseFloat((this.distance * 1 * res.data.geofenceData.pricePerKiloMeter).toFixed(2));
              var discountDistancePrice; //For discount
              var mins: number = parseFloat(this.duration.toFixed(2));
              var priceOfMin = mins * res.data.geofenceData.pricePerMinutes;
              var discountPriceOfMin; //For discount
              var basicFee;
              if (element.baseFareChangePercentage !== 0) {
                basicFee = res.data.geofenceData.baseFare + res.data.geofenceData.baseFare * (0.01 * element.baseFareChangePercentage);
              } else {
                basicFee = res.data.geofenceData.baseFare;
              }
              var discountBasicFee; //For discount
              var otherFee = res.data.geofenceData.otherFee ? res.data.geofenceData.otherFee : 0;
              var stopesFee = 0;
              var airpotRoutesCount = 0;
              airpotRoutesCount += this.pickupfieldsArr.filter((val) => val.isAirportRoute).length;
              if (this.pickupfieldsArr.length > 1) {
                var fee = res.data.geofenceData.ridePickUpFee ? res.data.geofenceData.ridePickUpFee : 0;
                stopesFee = this.pickupfieldsArr.length * fee;
              }
              airpotRoutesCount += this.pickupfieldsArr.filter((val) => val.isAirportRoute).length;
              if (this.allPackagesDetail.length > 1) {
                var fee = res.data.geofenceData.rideDropUpFee ? res.data.geofenceData.rideDropUpFee : 0;
                stopesFee = this.allPackagesDetail.length * fee;
              }
              var airFee = 0;
              for (let i = 1; i <= airpotRoutesCount; i++) {
                airFee += res.data.geofenceData.airpotFess ? res.data.geofenceData.airpotFess : 0;
              }
              //This condition is for discount calculation
              if (res.data.discountData.length > 0) {
                if (res.data.discountData[0].applyTo.includes("USER")) {
                  if (res.data.discountData[0].discountData.keys[0] === this.constant.DISCOUNT_KEYS.BASE_FARE) {
                    if (res.data.discountData[0].discountData.discountType === this.constant.DISCOUNT_TYPES.FLAT) {
                      if (basicFee < res.data.discountData[0].discountData.discountValue) {
                        discountBasicFee = 0;
                      } else {
                        discountBasicFee = basicFee - res.data.discountData[0].discountData.discountValue;
                      }
                    } else {
                      let d = (basicFee * res.data.discountData[0].discountData.discountValue) / 100;
                      if (basicFee < d) {
                        discountBasicFee = 0;
                      } else {
                        discountBasicFee = basicFee - d;
                      }
                    }
                  } else if (res.data.discountData[0].discountData.keys[0] === this.constant.DISCOUNT_KEYS.PER_KM) {
                    if (res.data.discountData[0].discountData.discountType === this.constant.DISCOUNT_TYPES.FLAT) {
                      if (distancePrice < res.data.discountData[0].discountData.discountValue) {
                        discountDistancePrice = 0;
                      } else {
                        discountDistancePrice = distancePrice - res.data.discountData[0].discountData.discountValue;
                      }
                    } else {
                      let d = (distancePrice * res.data.discountData[0].discountData.discountValue) / 100;
                      discountDistancePrice = distancePrice - d;
                    }
                  } else if (res.data.discountData[0].discountData.keys[0] === this.constant.DISCOUNT_KEYS.PER_MIN) {
                    if (res.data.discountData[0].discountData.discountType === this.constant.DISCOUNT_TYPES.FLAT) {
                      if (priceOfMin < res.data.discountData[0].discountData.discountValue) {
                        discountPriceOfMin = 0;
                      } else {
                        discountPriceOfMin = priceOfMin - res.data.discountData[0].discountData.discountValue;
                      }
                    } else {
                      let d = (priceOfMin * res.data.discountData[0].discountData.discountValue) / 100;
                      discountPriceOfMin = priceOfMin - d;
                    }
                  } else if (res.data.discountData[0].discountData.keys[0] === this.constant.DISCOUNT_KEYS.DELIVERY_TRANSACTION_FEE) {
                    if (res.data.discountData[0].discountData.discountType === this.constant.DISCOUNT_TYPES.FLAT) {
                      if (transactionFee < res.data.discountData[0].discountData.discountValue) {
                        transactionFeeDiscount = 0;
                      } else {
                        transactionFeeDiscount = transactionFee - res.data.discountData[0].discountData.discountValue;
                      }
                    } else {
                      let d = (transactionFee * res.data.discountData[0].discountData.discountValue) / 100;
                      transactionFeeDiscount = transactionFee - d;
                    }
                  }
                }
              }

              var totalWithoutTax = parseFloat((transactionFee + distancePrice + priceOfMin + airFee + basicFee + otherFee + stopesFee).toFixed(2));
              var totalWithoutTaxDiscount; //For discount
              //If any one of discount is applied
              if (
                discountDistancePrice !== undefined ||
                discountPriceOfMin !== undefined ||
                discountBasicFee !== undefined ||
                transactionFeeDiscount !== undefined
              ) {
                let dPrice = discountDistancePrice !== undefined ? discountDistancePrice : distancePrice; //For discount
                let dMin = discountPriceOfMin !== undefined ? discountPriceOfMin : priceOfMin; //For discount
                let dBasicFee = discountBasicFee !== undefined ? discountBasicFee : basicFee; //For discount
                let dtransactionFee = transactionFeeDiscount !== undefined ? transactionFeeDiscount : transactionFee; //For discount
                totalWithoutTaxDiscount = parseFloat((dtransactionFee + dPrice + dMin + airFee + dBasicFee + otherFee + stopesFee).toFixed(2)); //For discount
              }

              var taxPercentage = res.data.geofenceData.taxPercentage ? res.data.geofenceData.taxPercentage : 0;
              var tax = parseFloat(((totalWithoutTax * taxPercentage) / 100).toFixed(2));
              var dtax;
              totalWithoutTaxDiscount && (dtax = parseFloat(((totalWithoutTaxDiscount * taxPercentage) / 100).toFixed(2))); //For discount
              var totalWithTax = totalWithoutTax + tax;
              var totalWithTaxDiscount;
              dtax && (totalWithTaxDiscount = totalWithoutTaxDiscount + dtax); //For discount
              var isInTimeSlotPrice = false;
              var timeSlotAmount = 0;
              var timeOfDayPercentage = 0;
              var date = new Date();
              // var time = date.getTime();
              var hourse = date.getHours();
              var minutes: any = date.getMinutes();
              var time;
              if (this.digits_count(minutes) === 1) {
                minutes = "0" + JSON.stringify(minutes);
                time = JSON.parse(JSON.stringify(hourse) + minutes);
              } else {
                time = JSON.parse(JSON.stringify(hourse) + JSON.stringify(minutes));
              }
              for (let i in res.data.geofenceData.slotTime) {
                var data = res.data.geofenceData.slotTime[i];
                if (data.startTime < time && data.endTime > time) {
                  isInTimeSlotPrice = true;
                  timeOfDayPercentage = data.timeOfDayPercentage;
                }
              }
              if (isInTimeSlotPrice) {
                timeSlotAmount = totalWithTax * (timeOfDayPercentage * 0.01);
                var timeSlotAmountDiscount;
                totalWithTaxDiscount && (timeSlotAmountDiscount = totalWithTaxDiscount * (timeOfDayPercentage * 0.01));
              }
              var totalWithTimeSlot = totalWithTax + timeSlotAmount;
              var totalWithTimeSlotDiscount;
              totalWithTaxDiscount && timeSlotAmountDiscount && (totalWithTimeSlotDiscount = totalWithTaxDiscount + timeSlotAmountDiscount); //For discount
              totalWithTaxDiscount && !timeSlotAmountDiscount && (totalWithTimeSlotDiscount = totalWithTaxDiscount + timeSlotAmount); //For discount
              !totalWithTaxDiscount && timeSlotAmountDiscount && (totalWithTimeSlot = totalWithTaxDiscount + timeSlotAmountDiscount); //For discount
              !totalWithTaxDiscount && !timeSlotAmountDiscount && (totalWithTimeSlot = totalWithTax + timeSlotAmount); //For discount
              var booKingDriverAmount = (totalWithTimeSlot * 75) / 100;
              // /for discount of final fare
              if (res.data.discountData.length > 0) {
                if (res.data.discountData[0].applyTo.includes("USER")) {
                  if (res.data.discountData[0].discountData.keys[0] === this.constant.DISCOUNT_KEYS.FINAL_FARE) {
                    if (res.data.discountData[0].discountData.discountType === this.constant.DISCOUNT_TYPES.FLAT) {
                      if (!totalWithTaxDiscount) {
                        if (totalWithTimeSlot < res.data.discountData[0].discountData.discountValue) {
                          totalWithTimeSlotDiscount = 0;
                        } else {
                          totalWithTimeSlotDiscount = totalWithTimeSlot - res.data.discountData[0].discountData.discountValue;
                        }
                      } else {
                        if (totalWithTimeSlot < res.data.discountData[0].discountData.discountValue) {
                          totalWithTimeSlotDiscount = 0;
                        } else {
                          totalWithTimeSlotDiscount = totalWithTimeSlot - res.data.discountData[0].discountData.discountValue;
                        }
                      }
                    } else {
                      if (!totalWithTaxDiscount) {
                        let d = (totalWithTimeSlot * res.data.discountData[0].discountData.discountValue) / 100;
                        totalWithTimeSlotDiscount = totalWithTimeSlot - d;
                      } else {
                        let d = (totalWithTimeSlot * res.data.discountData[0].discountData.discountValue) / 100;
                        totalWithTimeSlotDiscount = totalWithTimeSlot - d;
                      }
                    }
                  }
                }
              }

              totalWithTimeSlotDiscount !== undefined &&
                (element["discount"] = this.commonService.currencyFormat(totalWithTimeSlotDiscount.toFixed(2))); //For discount
              element["booKingAmount"] = this.commonService.currencyFormat(totalWithTimeSlot.toFixed(2));
              element["booKingDriverAmount"] = this.commonService.currencyFormat(booKingDriverAmount.toFixed(2));
              if (indexElement === 2) {
                let secondElement = JSON.parse(JSON.stringify(element));
                secondElement["id"] = null;
                secondElement["vehicleCategory"] = "Any";
                secondElement["vehicleImage"] = "assets/uimages/logo.png";
                secondElement["booKingAmount"] = null;
                secondElement["booKingDriverAmount"] = null;
                this.vehicleCategoryData.push(secondElement);
                // this.vehicleCategoryData.splice(0, 0, secondElement)
                element["vehicleImage"] = this.baseUrl + element.vehicleImage;
                this.vehicleCategoryData.push(element);
                var temp = this.vehicleCategoryData[0];
                this.vehicleCategoryData[0] = this.vehicleCategoryData[1];
                this.vehicleCategoryData[1] = temp;
              } else {
                element["vehicleImage"] = this.baseUrl + element.vehicleImage;
                this.vehicleCategoryData.push(element);
              }
            });
          }
          this.selectedVehicleData = this.vehicleCategoryData[0];
          this.ngxService.stop();
          // this.pickupfieldsArr.sort((a, b) => {
          //   return a.distance - b.distance;
          // });
          this.allPackagesDetail.sort((a, b) => {
            return a.addressDetails.itemDropup.distance - b.addressDetails.itemDropup.distance;
          });
          this.drawPath(this.pickupfieldsArr[0], this.allPackagesDetail[this.allPackagesDetail.length - 1], this.marker1);
        }
      });
    // }
  }

  digits_count(n) {
    var count = 0;
    if (n >= 1) ++count;

    while (n / 10 >= 1) {
      n /= 10;
      ++count;
    }

    return count;
  }

  onFileChange(fileInput: any) {
    const fileData = <File>fileInput.target.files[0];
    var mimeType = fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      this.commonService.OpenNotificationModal("error", "top-end", "Only valid image format is allowed.");
      return;
    }
    this.ngxService.start();
    this.http
      .userFileUpload(fileData)
      .pipe(takeUntil(this.notifier))
      .subscribe((res) => {
        this.fileDataUrl = JSON.parse(JSON.stringify(res)).data.orignal;
        this.ngxService.stop();
      });
  }

  addItem() {
    this.packageSubmitted = true;
    if (this.packageDetailForm.valid && this.packageDetailForm.value.quantity > 0 && this.packageDetailForm.value.quantity < 1000) {
      this.dropupFieldsCount++;
      this.allPackagesDetail.push({
        packageType: this.packageDetailForm.value.packageType,
        desc: this.packageDetailForm.value.itemDesc,
        quantity: this.packageDetailForm.value.quantity,
        image: this.fileDataUrl,
        addressDetails: this.itemDropDetails,
      });
      this.showDropMarker = true;
      console.log("ok tested", this.dropupfieldsArr);
      this.packageDetailForm.reset();
      this.deliveryDetailForm.reset();
      this.packageSubmitted = false;
      this.fileDataUrl = "";
    }
  }

  selectVehicle(vehicle, index) {
    this.selectedVehicleData = vehicle;
    this.selectedRideIndex = index;
  }

  selectPackageSize(packageSize, index) {
    this.selectedPackageSIze = packageSize;
    this.selectedSizeIndex = index;
  }

  editPackageDetail(pckg) {
    this.packageDetailForm.get("packageType").patchValue(pckg.packageType);
    this.packageDetailForm.get("itemDesc").patchValue(pckg.desc);
    this.packageDetailForm.get("quantity").patchValue(pckg.quantity);
    this.packageDetailForm.get("itemDropup").patchValue(pckg.addressDetails.itemDropup.address);
    this.packageDetailForm.get("file").clearValidators();
    this.packageDetailForm.get("file").updateValueAndValidity();
    this.fileDataUrl = pckg.image;
    this.allPackagesDetail.forEach((element) => {
      if (
        element.packageType === pckg.packageType &&
        element.desc === pckg.desc &&
        element.quantity === pckg.quantity &&
        element.image === pckg.image
      ) {
        const index = this.allPackagesDetail.indexOf(pckg);
        if (index > -1) {
          this.allPackagesDetail.splice(index, 1);
        }
      }
    });
  }

  deletePackageDetail(pckg) {
    this.allPackagesDetail.forEach((element) => {
      if (
        element.packageType === pckg.packageType &&
        element.desc === pckg.desc &&
        element.quantity === pckg.quantity &&
        element.image === pckg.image
      ) {
        const index = this.allPackagesDetail.indexOf(pckg);
        if (index > -1) {
          this.dropupFieldsCount--;
          this.allPackagesDetail.splice(index, 1);
        }
      }
    });
  }

  requestBooking(index) {
    if ((this.selectedCardDetails || this.isWalletSelected) && this.vehicleCategoryData.length > 0) {
      let amount =
        this.selectedVehicleData.booKingAmount != null
          ? this.selectedVehicleData.booKingAmount.includes("$")
            ? parseFloat(this.selectedVehicleData.booKingAmount.split("$")[1].replace(",", ""))
            : this.selectedVehicleData.booKingAmount
          : 0;
      if (!this.selectedCardDetails && !(amount < this.allSavedCardData.walletAmount)) {
        this.continue(2);
        this.commonService.OpenNotificationModal("error", "top-end", this.messagesData.YOU_HAVE_INSUFFICIENT_BALANCE_IN_YOUR_WALLET);
        return false;
      }
      var date = moment(this.requestForLaterForm.value.dateTime).format("YYYY-MM-DD");
      var time = moment(this.requestForLaterForm.value.dateTime).format("HH:mm:ss");
      var dateTime = date + "T" + time + "Z";
      this.ngxService.start();
      var d = new Date();
      var timeZone = d.getTimezoneOffset();
      if (Math.sign(timeZone) === 1) {
        timeZone = -Math.abs(timeZone);
      } else {
        timeZone = Math.abs(timeZone);
      }
      var packageDetails = [];
      packageDetails.push({
        address: this.pickupfieldsArr[0].address,
        streetAddress: this.pickupfieldsArr[0].address,
        latitude: this.pickupfieldsArr[0].lat,
        longitude: this.pickupfieldsArr[0].lng,
        isPickUp: true,
      });
      this.allPackagesDetail.forEach((element) => {
        if (element.addressDetails.additionalInfo != null) {
          packageDetails.push({
            address: element.addressDetails.itemDropup.address,
            addressType: element.addressDetails.deliveryTo,
            latitude: element.addressDetails.itemDropup.lat,
            longitude: element.addressDetails.itemDropup.lng,
            isPickUp: false,
            itemImage: element.image,
            itemDescription: element.desc,
            packageName: element.packageType.split(",")[1],
            packageTypeId: element.packageType.split(",")[0],
            itemQuantity: element.quantity,
            note: element.addressDetails.additionalInfo != "" ? element.addressDetails.additionalInfo : undefined,
            // "billImage": quantity,
            // "price": 1,
            streetAddress: element.addressDetails.streetAdd,
            suit: element.addressDetails.suiteAptOffice,
            city: element.addressDetails.city,
            province: element.addressDetails.province,
            zipCode: element.addressDetails.zipCode,
            phoneNo: element.addressDetails.phoneNo,
            countryCode: element.addressDetails.countryCode,
          });
        } else if (element.addressDetails.additionalInfo != "") {
          packageDetails.push({
            address: element.addressDetails.itemDropup.address,
            addressType: element.addressDetails.deliveryTo,
            latitude: element.addressDetails.itemDropup.lat,
            longitude: element.addressDetails.itemDropup.lng,
            isPickUp: false,
            itemImage: element.image,
            itemDescription: element.desc,
            packageName: element.packageType.split(",")[1],
            packageTypeId: element.packageType.split(",")[0],
            itemQuantity: element.quantity,
            note: element.addressDetails.additionalInfo != null ? element.addressDetails.additionalInfo : undefined,
            // "billImage": quantity,
            // "price": 1,
            streetAddress: element.addressDetails.streetAdd,
            suit: element.addressDetails.suiteAptOffice,
            city: element.addressDetails.city,
            province: element.addressDetails.province,
            zipCode: element.addressDetails.zipCode,
            phoneNo: element.addressDetails.phoneNo,
            countryCode: element.addressDetails.countryCode,
          });
        }
      });
      var param = $.extend(
        {},
        {
          vehicleCategoryId: this.selectedVehicleData.id,
          packageSizeId: this.selectedPackageSIze._id,
          bookingDate: this.bookingFor === "Later" ? this.requestForLaterForm.value.dateTime : undefined, //
          pickUpDropUp: packageDetails,
          timezone: timeZone,
          // "booKingAmount": this.selectedVehicleData.booKingAmount,
          // "booKingDriverAmount": this.selectedVehicleData.booKingDriverAmount.toFixed(2)
          totalDistance: this.distance * 1000,
          eta: parseFloat(this.duration.toFixed(2)),
          paymentMode: this.isWalletSelected ? this.constant?.PAYMENT_MODE?.WALLET : this.constant.PAYMENT_MODE?.CARD,
          cardId: !this.isWalletSelected ? this.selectedCardDetails.id : undefined,
          loyalityId: this.selectedVehicleData.loyalityId ? this.selectedVehicleData.loyalityId : undefined,
        }
      );
      this.http
        .httpPostWithHeaderUser("createDeliveryBooking", param)
        .pipe(takeUntil(this.notifier))
        .subscribe((res: any) => {
          if (res.statusCode == 200) {
            console.log(res.data);
            this.bookingId = res.data.bookingId;
            // this.commonService.OpenNotificationModal('success', 'top-end', res.message);
            if (this.bookingFor === "Now") {
              this.continue(3);
              this.listenSocketEvent();
            } else {
              this.packageDetailForm.reset();
              this.deliveryDetailForm.reset();
              this.packageSubmitted = false;
              this.detailSubmitted = false;
              this.rideSubmited = false;
              this.isDateTimeSubmit = false;
              // this.bookingForm.reset();
              this.requestForLaterForm.reset();
              this.getCurrentLocation();
              this.continue(0);
              this.showDirections = false;
              this.showMarker = true;
            }
          } else {
            Swal.fire({
              title: res.message,
              text: "",
              icon: "info",
              showCancelButton: false,
              showConfirmButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "GO TO MY RIDES",
              allowOutsideClick: true,
            }).then((result) => {
              if (result.value) {
                this.router.navigate(["/profilesetup/2"]);
              }
            });
          }
        });
    }
  }

  listenSocketEvent() {
    this.socketService
      .onEvent(Event.sendNotificationToUser)
      .pipe(takeUntil(this.notifier))
      .subscribe((response: any) => {
        console.log(response);
        if (
          response.data.subBookingData[0].isPickUp &&
          (response?.pushType === 6 || response?.pushType === 7 || response?.pushType === 8 || response?.pushType === 35 || response?.pushType === 45)
        ) {
          let arr = response.data.subBookingData.filter((val) => val.cancelReason != "" && val.cancelReason != null);
          // let cancelReasons;
          // arr.length > 0 && (cancelReasons = arr[0].cancelReason);

          this.cancelReasons;
          this.cancelationMessage = response.message;
          this.canceledAddress;
          arr.length > 0 && (this.cancelReasons = arr[arr.length - 1].cancelReason);
          arr.length > 0 && (this.canceledAddress = arr[arr.length - 1].address);

          response.cancelReason && (this.cancelReasons = response.cancelReason);
          (response?.pushType === 7 || response?.pushType === 8) && this.commonService.OpenNotificationModal("error", "top-end", response.message);
          // response?.pushType !== 7 &&
          //   response?.pushType !== 8 &&
          //   this.cancelWithReason(response.message, this.cancelReasons ? "Reason:- " + this.cancelReasons : "", null);
          response?.pushType !== 7 && response?.pushType !== 8 && (this.cancelDialog = "block");
          // this.commonService.OpenNotificationModal('error', 'top-end', response.message);
          (response?.pushType === 6 || response?.pushType === 7 || response?.pushType === 8) && this.continue(0);
          response?.pushType !== 6 && response?.pushType !== 7 && response?.pushType !== 8 && (this.nextPopup = 3);
        }
        if (this.nextPopup == 9 || this.nextPopup == 4 || this.nextPopup == 3) {
          this.goToBookingStatus(response.data);
          this.listenDriverLatLngEvent();
          this.calculated = false;
          this.updateDriverLocation();
        }
        localStorage.removeItem("isCancelCounterStopped");
      });
  }

  goToBookingStatus(response) {
    if (response.bookingType === this.constant.BOOKING_TYPE.DELIVERY) {
      this.commonService.bookingDataForLanguageUpdateOnly = response;
      // this.router.navigate(['/riderdeliverybooking']);
      if (response.bookingStatus === this.constant.BOOKING_STATUS.PENDING && !response.isSheduledBooking) {
        this.bookingId = response._id;
        this.nextPopup = 3;
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED) {
        this.commonService.bookingResponseData = "";
        this.bookingId = response._id;
        this.isDriveingStart = true;
        this.showDirections = true;
        this.bookingResponseData = response;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        this.bookingResponseData.subBookingData.forEach((element) => {
          if (element.isPickUp) {
            this.pickupfieldsArr.push({
              name: null,
              lat: element.latitude,
              lng: element.longitude,
              address: null,
              distance: 0,
            });
          } else {
            this.itemDropDetails = {
              deliveryTo: element.addressType,
              itemDropup: {
                lat: element.latitude,
                lng: element.longitude,
                address: element.address,
                distance: 0,
              },
              streetAdd: element.streetAddress,
              suiteAptOffice: element.suitAddress,
              city: element.city,
              province: element.province,
              zipCode: element.zipCode,
              additionalInfo: element.note,
              phoneNo: element.phoneNo,
              countryCode: element.countryCode,
            };
            this.allPackagesDetail.push({
              packageType: element.packageName + "," + element.packageTypeId,
              desc: element.itemDescription,
              quantity: element.itemQuantity,
              image: element.itemImage,
              addressDetails: this.itemDropDetails,
            });
          }
        });
        // this.calculateDistanceMatrix({ lat: response.driverId.latitude, lng: response.driverId.longitude }, { lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, null);
        this.matrix(
          { lat: response.driverId.latitude, lng: response.driverId.longitude },
          {
            lat: this.pickupfieldsArr[0].lat,
            lng: this.pickupfieldsArr[0].lng,
          },
          null
        ).then((value: any) => {
          this.listenDriverLatLngEvent();
          var isActive: boolean = true;
          if (localStorage.isCancelCounterStopped !== undefined) {
            if (localStorage.isCancelCounterStopped === "true") {
              this.commonService.cancelCounter = 0;
              isActive = false;
            }
          }
          let todayDate = moment(new Date(response.bookingAcceptedDate));
          let endDate = moment(new Date(new Date()));
          let diff = endDate.diff(todayDate, "seconds");
          console.log("diffrence in seconds", diff);
          if (diff <= 10 && diff > 0) {
            this.commonService.cancelCounter = diff;
            isActive = true;
          } else if (diff === 0) {
            this.commonService.cancelCounter = 10;
            isActive = true;
          } else {
            this.commonService.cancelCounter = 0;
            isActive = false;
          }
          this.commonService.cancelationTimerStart.next({ isActive: isActive, time: this.commonService.cancelCounter });
          this.driverMessage = this.messagesData.YOUR_DRIVER_IS_ARIVING_IN + value.duration.toFixed() + this.messagesData.MIN;
          this.duration = value.duration;
          this.continue(4);
          return;
        });
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.PARTIAL) {
        this.commonService.cancelationTimerStart.next({ isActive: false, time: 0 });
        localStorage.removeItem("isCancelCounterStopped");
        this.commonService.cancelCounter = 0;
        this.isDriveingStart = true;
        this.showDirections = true;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        this.allPackagesDetail = [];
        var sortedSubBookingData = [];
        response.subBookingData.forEach((element) => {
          if (element.bookingStatus !== this.constant.BOOKING_STATUS.NOT_AVAILABLE && element.bookingStatus !== this.constant.BOOKING_STATUS.NONE) {
            sortedSubBookingData.push(element);
          }
          if (element.isPickUp) {
            this.pickupfieldsArr.push({
              name: null,
              lat: element.latitude,
              lng: element.longitude,
              address: null,
              distance: 0,
            });
          } else if (
            element.bookingStatus !== this.constant.BOOKING_STATUS.NOT_AVAILABLE &&
            element.bookingStatus !== this.constant.BOOKING_STATUS.NONE
          ) {
            this.itemDropDetails = {
              deliveryTo: element.addressType,
              itemDropup: {
                lat: element.latitude,
                lng: element.longitude,
                address: element.address,
                distance: 0,
              },
              streetAdd: element.streetAddress,
              suiteAptOffice: element.suitAddress,
              city: element.city,
              province: element.province,
              zipCode: element.zipCode,
              additionalInfo: element.note,
              phoneNo: element.phoneNo,
              countryCode: element.countryCode,
            };
            this.allPackagesDetail.push({
              packageType: element.packageName + "," + element.packageTypeId,
              desc: element.itemDescription,
              quantity: element.itemQuantity,
              image: element.itemImage,
              addressDetails: this.itemDropDetails,
              bookingStatus: element.bookingStatus,
            });
          }
        });
        response["subBookingData"] = sortedSubBookingData;
        this.commonService.bookingResponseData = "";
        this.bookingResponseData = response;
        // this.listenDriverLatLngEvent();
        if (this.allPackagesDetail.length > 0) {
          this.matrix(
            {
              lat: this.pickupfieldsArr[0].lat,
              lng: this.pickupfieldsArr[0].lng,
            },
            {
              lat: this.allPackagesDetail[this.allPackagesDetail.length - 1].addressDetails.itemDropup.lat,
              lng: this.allPackagesDetail[this.allPackagesDetail.length - 1].addressDetails.itemDropup.lng,
            },
            null
          ).then((value: any) => {
            this.listenDriverLatLngEvent();
            this.duration = value.duration;
            var bookingStatusArr = response.subBookingData.map((res) => res.bookingStatus);
            console.log("solution", bookingStatusArr);
            for (let i = 0; i < response.subBookingData.length; i++) {
              if (
                response.subBookingData.length > 2 &&
                !response.subBookingData[i].isPickUp &&
                response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.CANCELED &&
                i != response.subBookingData.length - 1 &&
                response.subBookingData[i + 1]?.bookingStatus === this.constant.BOOKING_STATUS.AVAILABLE
              ) {
                if (i !== response.subBookingData.length - 1) {
                  this.driverMessage =
                    this.messagesData.YOUR_DRIVER_HAS_CANCELED_DELIVERY_AT_DROP_OFF_LOCATION +
                    this.constant.ALPHAB_STATUS[i] +
                    this.messagesData.HEADING_TO_NEXT_LOCATION;
                } else {
                  this.driverMessage =
                    this.messagesData.YOUR_DRIVER_HAS_CANCELED_DELIVERY_AT_DROP_OFF_LOCATION + this.constant.ALPHAB_STATUS[i] + ".";
                }
                this.isDriverLatLngUpdated$.next(false);
                this.driverNotFoundAlert(
                  this.messagesData.Delivery_Canceled,
                  this.messagesData.REASON + ": " + response.subBookingData[i].cancelReason,
                  null
                );
              }
              if (
                response.subBookingData.length > 2 &&
                !response.subBookingData[i].isPickUp &&
                response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.CANCELED &&
                i === response.subBookingData.length - 1 &&
                !bookingStatusArr.includes(this.constant.BOOKING_STATUS.START_RETURN) &&
                !bookingStatusArr.includes(this.constant.BOOKING_STATUS.END_RETURN)
              ) {
                if (i !== response.subBookingData.length - 1) {
                  this.driverMessage =
                    this.messagesData.YOUR_DRIVER_HAS_CANCELED_DELIVERY_AT_DROP_OFF_LOCATION +
                    this.constant.ALPHAB_STATUS[i] +
                    " heading to next location.";
                } else {
                  this.driverMessage =
                    this.messagesData.YOUR_DRIVER_HAS_CANCELED_DELIVERY_AT_DROP_OFF_LOCATION + this.constant.ALPHAB_STATUS[i] + ".";
                }
                this.isDriverLatLngUpdated$.next(false);
                this.driverNotFoundAlert(
                  this.messagesData.Delivery_Canceled,
                  this.messagesData.REASON + ": " + response.subBookingData[i].cancelReason,
                  null
                );
              }
              if (
                response.subBookingData.length === 2 &&
                !response.subBookingData[i].isPickUp &&
                response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.CANCELED
              ) {
                this.driverMessage = this.messagesData.YOUR_DRIVER_HAS_CANCELED_DELIVERY_AT_DROP_OFF_LOCATION;
                this.isDriverLatLngUpdated$.next(false);
                this.driverNotFoundAlert(
                  this.messagesData.Delivery_Canceled,
                  this.messagesData.REASON + ": " + response.subBookingData[i].cancelReason,
                  null
                );
              }
            }
            this.continue(4);
            this.setDriverStatus(response);
            this.isDriverLatLngUpdated.subscribe((value: boolean) => {
              if (value) {
                this.setDriverStatus(response);
              }
            });
            return;
          });
        }

        // this.continue(5);
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.ARRIVED) {
        this.commonService.bookingResponseData = "";
        this.isDriveingStart = true;
        // this.markerOptions = {
        //   origin: {
        //     icon: this.iconObject(0)
        //   },
        //   destination: {
        //     icon: this.destinationMarkerIcon
        //   },
        // }
        // this.origin = { lat: response.pickUplatitude, lng: response.pickUplongitude };
        // this.destination = { lat: response.dropUplatitude, lng: response.dropUplongitude };
        // this.showDirections = true;
        // this.pickup.lat = response.pickUplatitude;
        // this.pickup.lng = response.pickUplongitude;
        // this.listenDriverLatLngEvent();
        // this.showDirections = false;
        this.showDirections = true;
        this.bookingResponseData = response;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        this.bookingResponseData.subBookingData.forEach((element) => {
          if (element.isPickUp) {
            this.pickupfieldsArr.push({
              name: null,
              lat: element.latitude,
              lng: element.longitude,
              address: null,
              distance: 0,
            });
          } else {
            this.dropupfieldsArr.push({
              name: null,
              lat: element.latitude,
              lng: element.longitude,
              address: null,
              distance: 0,
            });
          }
        });
        this.listenDriverLatLngEvent();
        this.matrix(
          {
            lat: this.pickupfieldsArr[0].lat,
            lng: this.pickupfieldsArr[0].lng,
          },
          {
            lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat,
            lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng,
          },
          null
        );
        this.continue(6);
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.PACKAGES_PICKED) {
        this.commonService.bookingResponseData = "";
        this.isDriveingStart = true;
        this.showDirections = true;
        this.bookingResponseData = response;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        this.bookingResponseData.subBookingData.forEach((element) => {
          if (element.isPickUp) {
            this.pickupfieldsArr.push({
              name: null,
              lat: element.latitude,
              lng: element.longitude,
              address: null,
              distance: 0,
            });
          } else {
            this.dropupfieldsArr.push({
              name: null,
              lat: element.latitude,
              lng: element.longitude,
              address: null,
              distance: 0,
            });
          }
        });
        this.listenDriverLatLngEvent();
        this.matrix(
          {
            lat: this.pickupfieldsArr[0].lat,
            lng: this.pickupfieldsArr[0].lng,
          },
          {
            lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat,
            lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng,
          },
          null
        );
        this.continue(7);
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.OUT_FOR_DELIVERY) {
        this.commonService.bookingResponseData = "";
        this.isDriveingStart = true;
        this.showDirections = true;
        this.bookingResponseData = response;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        this.bookingResponseData.subBookingData.forEach((element) => {
          if (element.isPickUp) {
            this.pickupfieldsArr.push({
              name: null,
              lat: element.latitude,
              lng: element.longitude,
              address: null,
              distance: 0,
            });
          } else {
            this.dropupfieldsArr.push({
              name: null,
              lat: element.latitude,
              lng: element.longitude,
              address: null,
              distance: 0,
            });
          }
        });
        this.listenDriverLatLngEvent();
        this.matrix(
          {
            lat: this.pickupfieldsArr[0].lat,
            lng: this.pickupfieldsArr[0].lng,
          },
          {
            lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat,
            lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng,
          },
          null
        );

        this.allPackagesDetail = [];
        this.continue(8);
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.COMPLETED || response.pushType === 13) {
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        this.allPackagesDetail = [];
        var sortedSubBookingData = [];
        response.subBookingData.forEach((element) => {
          if (element.bookingStatus !== this.constant.BOOKING_STATUS.NOT_AVAILABLE && element.bookingStatus !== this.constant.BOOKING_STATUS.NONE) {
            sortedSubBookingData.push(element);
          }
          if (element.isPickUp) {
            this.pickupfieldsArr.push({
              name: null,
              lat: element.latitude,
              lng: element.longitude,
              address: null,
              distance: 0,
            });
          } else if (
            element.bookingStatus !== this.constant.BOOKING_STATUS.NOT_AVAILABLE &&
            element.bookingStatus !== this.constant.BOOKING_STATUS.NONE
          ) {
            this.itemDropDetails = {
              deliveryTo: element.addressType,
              itemDropup: {
                lat: element.latitude,
                lng: element.longitude,
                address: element.address,
                distance: 0,
              },
              streetAdd: element.streetAddress,
              suiteAptOffice: element.suitAddress,
              city: element.city,
              province: element.province,
              zipCode: element.zipCode,
              additionalInfo: element.note,
              phoneNo: element.phoneNo,
              countryCode: element.countryCode,
            };
            this.allPackagesDetail.push({
              packageType: element.packageName + "," + element.packageTypeId,
              desc: element.itemDescription,
              quantity: element.itemQuantity,
              image: element.itemImage,
              addressDetails: this.itemDropDetails,
              cancelReason: element.cancelReason,
              bookingStatus: element.bookingStatus,
            });
          }
        });
        response["subBookingData"] = sortedSubBookingData;
        this.commonService.bookingResponseData = "";
        this.bookingResponseData = response;
        this.listenDriverLatLngEvent();
        this.matrix(
          {
            lat: this.pickupfieldsArr[0].lat,
            lng: this.pickupfieldsArr[0].lng,
          },
          {
            lat: this.allPackagesDetail[this.allPackagesDetail.length - 1].addressDetails.itemDropup.lat,
            lng: this.allPackagesDetail[this.allPackagesDetail.length - 1].addressDetails.itemDropup.lng,
          },
          null
        ).then((value) => {
          if (value) {
            this.bookingId = response._id;
            this.isDriveingStart = true;
            this.showDirections = false;
            this.showPickMarker = false;
            this.getDriverRatingsReview();
            this.ngxService.stop();
            this.monitorModal = "none";
            this.continue(9);
            localStorage.removeItem("isCancelCounterStopped");
          }
        });
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.CANCELED) {
        this.isDriverLatLngUpdated$.next(false);
        this.commonService.bookingResponseData = "";
        this.bookingResponseData = response;
        // this.setDriverStatus(response);

        // this.continue(4);
        if (response.isCanceledByDriver && this.nextPopup !== 0) {
          this.driverNotFoundAlert(this.messagesData.YOUR_Delivery_HAS_BEEN_CANCELLED_BY_DRIVER, "", 0);
        } else if (response.isCanceledByCron && this.nextPopup !== 0) {
          this.driverNotFoundAlert(this.messagesData.OOPS_NO_DRIVER_AVAILABLE_NEARBY_PLEASE_TRY_AGAIN_LATER, "", 0);
        }
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.PENDING && this.nextPopup !== 0) {
        this.driverNotFoundAlert(this.messagesData.OOPS_NO_DRIVER_AVAILABLE_NEARBY_PLEASE_TRY_AGAIN_LATER, "", 0);
      }
    } else {
      this.commonService.bookingResponseData = "";
      this.router.navigate(["/riderequest"]);
    }
  }

  setDriverStatus(response) {
    if (response.bookingStatus === this.constant.BOOKING_STATUS.CANCELED) {
      this.isDriverLatLngUpdated$.next(false);
    }
    for (let i = 0; i < response.subBookingData.length; i++) {
      if (
        i === 0 &&
        response.subBookingData[i].isPickUp &&
        response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ARRIVED_AT_PICKED
      ) {
        this.driverMessage = this.messagesData.YOUR_DRIVER_ARRIVED_TO_PICK_YOU_UP_YOUR_DELIVERY_ITEMS;
      }
      if (i === 0 && response.subBookingData[i].isPickUp && response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.PICKED) {
        this.driverMessage = this.messagesData.DRIVER_PICKED_UP_AVAILABLE_ITEMS_FOR_DELIVERY_STATUS_PLEASE_CHECK_THE_APP;
      }
      if (
        !response.subBookingData[i].isPickUp &&
        response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ARRIVED_AT_DROPED &&
        this.allPackagesDetail.length < 2
      ) {
        this.driverMessage = this.messagesData.DRIVER_HAS_ARRIVED_AT_DESTINATION;
      }
      if (
        !response.subBookingData[i].isPickUp &&
        response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.DROPED &&
        this.allPackagesDetail.length < 2
      ) {
        this.driverMessage = this.messagesData.YOUR_PACKAGE_HAS_BEEN_DELIVERED;
      }
      if (
        !response.subBookingData[i].isPickUp &&
        response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.ARRIVED_AT_DROPED &&
        this.allPackagesDetail.length >= 2
      ) {
        this.driverMessage =
          this.messagesData.YOUR_DRIVER_WILL_REACH_THE_DROP_OFF_LOCATION +
          this.constant.ALPHAB_STATUS[i] +
          this.messagesData.IN +
          this.distanceDuration +
          this.messagesData.MIN;
      }
      if (
        !response.subBookingData[i].isPickUp &&
        response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.DROPED &&
        this.allPackagesDetail.length >= 2
      ) {
        this.driverMessage = this.messagesData.ARRIVED_AT_DROP_OFF_LOCATION + this.constant.ALPHAB_STATUS[i] + ".";
      }
      if (
        !response.subBookingData[i].isPickUp &&
        response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.DROPED &&
        this.allPackagesDetail.length >= 2 &&
        i === response.subBookingData.length - 1
      ) {
        this.driverMessage = this.messagesData.ARRIVED_AT_LAST_DROP_OFF_LOCATION;
      }
      if (
        response.subBookingData.length > 2 &&
        !response.subBookingData[i].isPickUp &&
        response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.CANCELED
      ) {
        if (i !== response.subBookingData.length - 1) {
          this.driverMessage =
            this.messagesData.YOUR_DRIVER_HAS_CANCELED_DELIVERY_AT_DROP_OFF_LOCATION +
            this.constant.ALPHAB_STATUS[i] +
            this.messagesData.HEADING_TO_NEXT_LOCATION;
        } else {
          this.driverMessage = this.messagesData.YOUR_DRIVER_HAS_CANCELED_DELIVERY_AT_DROP_OFF_LOCATION + this.constant.ALPHAB_STATUS[i] + ".";
        }
        this.isDriverLatLngUpdated$.next(false);
      }
      if (
        response.subBookingData.length === 2 &&
        !response.subBookingData[i].isPickUp &&
        response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.CANCELED
      ) {
        this.driverMessage = this.messagesData.YOUR_DRIVER_HAS_CANCELED_DELIVERY_AT_DROP_OFF_LOCATION;
        this.isDriverLatLngUpdated$.next(false);
      }
      if (
        response.subBookingData.length === 2 &&
        !response.subBookingData[i].isPickUp &&
        response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.START_RETURN
      ) {
        this.driverMessage =
          this.messagesData.YOUR_PACKAGE_COULD_NOT_BE_DELIVERED_BECAUSE +
          response.subBookingData[i].cancelReason +
          this.messagesData.AND_IT_IS_BEING_RETURNED_TO_YOU;
        return;
      }
      if (
        response.subBookingData.length > 2 &&
        !response.subBookingData[i].isPickUp &&
        response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.START_RETURN
      ) {
        var bookingStatusArr = response.subBookingData.map((res) => res.bookingStatus);
        var arr = [];
        for (let j = 0; j < bookingStatusArr.length; j++) {
          if (bookingStatusArr[j] === this.constant.BOOKING_STATUS.START_RETURN) {
            arr.push(this.constant.ALPHAB_STATUS[j]);
          }
        }
        if (arr.length > 1) {
          this.driverMessage = this.messagesData.DELIVERY + arr.join(", ") + this.messagesData.ARE_BEING_RETURNED_TO_YOU;
        } else {
          this.driverMessage = this.messagesData.DELIVERY + arr.join(", ") + this.messagesData.IS_BEING_RETURNED_TO_YOU;
        }
        return;
      }
      if (
        response.subBookingData.length > 2 &&
        !response.subBookingData[i].isPickUp &&
        response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.END_RETURN
      ) {
        var bookingStatusArr = response.subBookingData.map((res) => res.bookingStatus);
        var arr = [];
        for (let k = 0; k < bookingStatusArr.length; k++) {
          if (bookingStatusArr[k] === this.constant.BOOKING_STATUS.END_RETURN) {
            arr.push(this.constant.ALPHAB_STATUS[k]);
          }
        }
        this.driverMessage = this.messagesData.DELIVERY + arr.join(", ") + this.messagesData.HAS_BEEN_RETURNED_YOU;
        return;
      }
      if (
        response.subBookingData.length === 2 &&
        !response.subBookingData[i].isPickUp &&
        response.subBookingData[i].bookingStatus === this.constant.BOOKING_STATUS.END_RETURN
      ) {
        this.driverMessage = this.messagesData.YOUR_PACKAGE_HAS_BEEN_RETURNED_TO_YOU;
        return;
      }
    }
  }

  listenDriverLatLngEvent() {
    this.socketService
      .onEvent(Event.updateDriverLatLongToUser)
      .pipe(takeUntil(this.notifier))
      .subscribe((response: any) => {
        // console.log(response);
        if (this.nextPopup == 9 || this.nextPopup == 4 || this.nextPopup == 3) {
          this.updateDriverLatLongToUser = response;
        }
      });
  }

  updateDriverLocation() {
    if (this.calculated === false) {
      this.calculated = true;
      if (!this.updateDriverLatLongToUser) {
        return;
      }
      var response = this.updateDriverLatLongToUser;
      if (response.status == 200) {
        this.isDriveingStart = true;
        this.showDirections = true;
        this.showPickMarker = false;
        this.distanceDuration = response.distanceDuration;
        this.markerOptions.origin.icon = this.iconObject(response.bearing);
        this.bookingResponseData.subBookingData.forEach((element) => {
          if (element.bookingStatus === this.constant.BOOKING_STATUS.CANCELED) {
            this.isDriverLatLngUpdated$.next(false);
          }
        });
        if (this.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED) {
          var driverLatLng = {
            lat: response.latitude,
            lng: response.longitude,
          };
          this.destination = {
            lat: this.pickupfieldsArr[0].lat,
            lng: this.pickupfieldsArr[0].lng,
          };
          this.origin = { lat: response.latitude, lng: response.longitude };
          this.drawPath(driverLatLng, this.pickupfieldsArr[this.pickupfieldsArr.length - 1], this.iconObject(response.bearing));
          this.waypoints = [];
          // if (this.calculated === false) {
          // this.calculated = true;
          this.matrix(driverLatLng, this.destination, null);
          // }
        }
        if (this.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.PARTIAL) {
          this.isDriverLatLngUpdated$.next(true);
          var driverLatLng = {
            lat: response.latitude,
            lng: response.longitude,
          };
          var dropLatLng;
          if (this.allPackagesDetail.length > 0) {
            dropLatLng = {
              lat: this.allPackagesDetail[this.allPackagesDetail.length - 1].addressDetails.itemDropup.lat,
              lng: this.allPackagesDetail[this.allPackagesDetail.length - 1].addressDetails.itemDropup.lng,
            };
          }
          if (this.bookingResponseData.subBookingData.length > 1) {
            let data = this.bookingResponseData.subBookingData[this.bookingResponseData.subBookingData.length - 1];
            if (!data.isPickUp) {
              dropLatLng = {
                lat: data.latitude,
                lng: data.longitude,
              };
            }
          }
          driverLatLng && dropLatLng && this.drawPath(driverLatLng, dropLatLng, this.iconObject(response.bearing));
          clearInterval(this.intervalSubscription);
        }

        if (
          this.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.OUT_FOR_DELIVERY ||
          this.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.ARRIVED ||
          this.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.PACKAGES_PICKED
        ) {
          this.destination = { lat: this.drop.lat, lng: this.drop.lng };
        } else if (this.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.PACKAGES_PICKED) {
          this.showDirections = false;
          this.showPickMarker = false;
        }
      }
    }
  }

  iconObject(rotation: number) {
    return {
      path: "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z",
      scale: 0.7,
      strokeColor: "white",
      strokeWeight: 0.1,
      fillOpacity: 1,
      fillColor: "#404040",
      offset: "5%",
      rotation: rotation,
      flat: true,
      anchor: new google.maps.Point(10, 25),
    };
  }

  getDriverRatingsReview() {
    this.http
      .httpPostWithHeaderUser("getDriverRatingsReview", {
        driverId: this.bookingResponseData.driverId._id,
      })
      .pipe(takeUntil(this.notifier))
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.driverReviewData = res.data.driverData;
        }
      });
  }

  getDistanceMatrix(address, latLng) {
    var origin1 = this.pickup;
    var origin2 = this.pickupAddress;
    var destinationA = address;
    var destinationB = latLng;
    var service = new google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [origin1, origin2],
        destinations: [destinationA, destinationB],
        travelMode: "DRIVING",
        unitSystem: google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false,
      },
      (res: any) => {
        this.distanceInKM = res.rows[0].elements[0].distance.text;
        this.duration = res.rows[0].elements[0].duration.text;
        console.log(this.duration);
        this.ngxService.stop();
      }
    );
  }

  driverNotFoundAlert(message, text, index) {
    Swal.fire({
      title: message,
      text: text,
      icon: "info",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.value) {
        if (index !== null) {
          this.continue(index);
        }
      }
    });
  }

  cancelWithReason(message, text, index) {
    Swal.fire({
      title: message,
      text: text,
      icon: "info",
      showCancelButton: false,
      showConfirmButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      allowOutsideClick: true,
    }).then((result) => {
      if (result.value) {
        if (index !== null) {
          // this.continue(index);
        }
      }
    });
  }

  cancelConfirmToast(id, index) {
    if (this.commonService.cancelCounter > 0) {
      Swal.fire({
        title: "Are you sure you want to cancel?",
        text: "You canceled your booking within 10 sec. No cancelletion fees apply!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Cancel it!",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.cancelBooking(id);
          localStorage.removeItem("isCancelCounterStopped");
        }
      });
    } else {
      Swal.fire({
        title: "Are you sure you want to cancel?",
        text: index !== null ? "You canceled your booking after 10 sec. A cancellation fee applies!" : "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Cancel it!",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.cancelBooking(id);
          localStorage.removeItem("isCancelCounterStopped");
        }
      });
    }
  }

  cancelBooking(id) {
    this.ngxService.start();
    this.http
      .httpPostWithHeaderUser("cancelBooking", { bookingId: id })
      .pipe(takeUntil(this.notifier))
      .subscribe(
        (res: any) => {
          if (res.statusCode === 200) {
            this.ngxService.stop();
            this.commonService.bookingResponseData = "";
            this.bookingResponseData = "";
            this.continue(0);
          }
        },
        (err) => this.ngxService.stop()
      );
  }

  continue(popupType) {
    if (popupType === 0 && this.isResetField) {
      this.origin = null;
      this.destination = null;
      this.myModal23_user = "none";
      this.totalLoyality = "";
      this.packageDetailForm.reset();
      this.deliveryDetailForm.reset();
      this.packageSubmitted = false;
      this.detailSubmitted = false;
      this.rideSubmited = false;
      this.isDateTimeSubmit = false;
      // this.bookingForm.reset();
      this.requestForLaterForm.reset();
      this.pickupfieldsArr = [{ name: "pickup1", lat: 0, lng: 0, address: "", distance: 0 }];
      this.dropupfieldsArr = [{ name: "dropup1", lat: 0, lng: 0, address: "", distance: 0 }];
      this.getCurrentLocation();
      this.showDirections = false;
      this.showPickMarker = true;
      this.nextPopup = popupType;
      this.allPackagesDetail = [];
      this.drop.lat = 0;
      this.drop.lng = 0;
      $("#myModal23_user").modal("hide");
    }
    this.nextPopup = popupType;
  }
  goToprofilesetup() {
    this.commonService.isThisUpdatedUser = false;
    this.router.navigate(["/profilesetup"]);
  }
  goTorequestaccept() {
    this.router.navigate(["/requestaccept"]);
  }
  goToriderequest() {
    this.router.navigate(["/riderequest"]);
  }

  addFavouriteDriver() {
    this.http
      .httpPostWithHeaderUser("addFavouriteDriver", {
        driverId: this.bookingResponseData.driverId.id,
        isFav: true,
      })
      .pipe(takeUntil(this.notifier))
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.isDriverFavourite = true;
          this.commonService.OpenNotificationModal("success", "top-end", res.message);
          this.getDriverRatingsReview();
        }
      });
  }

  removeFavouriteDriver() {
    this.http
      .httpPostWithHeaderUser("addFavouriteDriver", {
        driverId: this.bookingResponseData.driverId.id,
        isFav: false,
      })
      .pipe(takeUntil(this.notifier))
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.isDriverFavourite = false;
          this.commonService.OpenNotificationModal("success", "top-end", res.message);
          this.getDriverRatingsReview();
        }
      });
  }

  //for rating
  onClick(rating: number) {
    // console.log(rating)
    // this.snackBar.open('You rated ' + rating + ' / ' + this.starCount, '', {
    //   duration: this.snackBarDuration
    // });
    this.ratingUpdated.emit(rating);
    this.rating = rating;
    if (this.rating > 0 && this.rating < 3) {
      this.review.setValidators(Validators.required);
      this.review.updateValueAndValidity();
    } else {
      this.review.clearValidators();
      this.review.updateValueAndValidity();
    }
    return false;
  }

  showIcon(index: number) {
    if (this.rating >= index + 1) {
      return "star";
    } else {
      return "star_border";
    }
  }

  selectReview(index, data) {
    this.selectedDriverReview = index;
    this.selectedReviewData = data.isActive ? "" : data.name;
  }

  selectTip(i, amount) {
    if (this.selectedTip !== i) {
      this.selectedTip = i;
      this.selectedTipAmount = amount;
      if (this.customRate.value != null) {
        this.customRate.patchValue(null);
        this.selectedTip = i;
      }
    } else {
      this.selectedTip = null;
      this.selectedTipAmount = null;
      this.customRate.patchValue(null);
    }
  }

  addRating(index) {
    this.ratingSubmitted = true;
    if (
      (this.rating > 0 && (this.rating <= 2 ? this.review.value != "" : this.selectedReviewData != "") && this.wasTheDriverOnTime.value != null) ||
      this.wasTheRideSafe.value != null ||
      this.driverMakeYouComfortabel.value != null
    ) {
      this.selectedFeedbckQna = [];
      this.selectedFeedbckQna.push({
        question: "Was the driver on time?",
        answere: this.wasTheDriverOnTime.value,
      });
      this.selectedFeedbckQna.push({
        question: "Was the ride safe?",
        answere: this.wasTheRideSafe.value,
      });
      this.selectedFeedbckQna.push({
        question: "Did the driver make your ride comfortabel?",
        answere: this.driverMakeYouComfortabel.value,
      });
      this.selectedFeedbckQna.forEach((element) => {
        if (element.answere === null) {
          const index = this.selectedFeedbckQna.indexOf(element);
          this.selectedFeedbckQna.splice(index, 1);
        }
      });
      this.selectedFeedbckQna.forEach((element) => {
        if (element.answere === null) {
          const index = this.selectedFeedbckQna.indexOf(element);
          this.selectedFeedbckQna.splice(index, 1);
        }
      });
      var param = $.extend(
        {},
        {
          bookingId: this.bookingId,
          driverId: this.bookingResponseData.driverId.id,
          driverRating: this.rating,
          driverReview: this.rating < 3 ? this.review.value : this.selectedReviewData,
          tip: this.customRate.value ? this.customRate.value : this.selectedTipAmount,
          comment: this.comment.value ? this.comment.value : undefined,
          feedBack: this.selectedFeedbckQna,
        }
      );
      !param.tip && delete param.tip;
      this.http
        .httpPostWithHeaderUser("addRatings", param)
        .pipe(takeUntil(this.notifier))
        .subscribe((res: any) => {
          if (res.statusCode == 200) {
            // this.commonService.OpenNotificationModal('success', 'top-end', res.message);gouravnadda
            this.continue(index);
            this.rating = 0;
            this.ratingSubmitted = false;
            this.selectedTip = null;
            this.selectedDriverReview = null;
            this.selectedReviewData = null;
            this.selectedTipAmount = null;
            this.driverReviewData = null;
            this.comment.patchValue(null);
            this.customRate.patchValue(null);
            this.wasTheRideSafe.patchValue(null);
            this.driverMakeYouComfortabel.patchValue(null);
            this.wasTheDriverOnTime.patchValue(null);
            this.selectedFeedbckQna = [];
          }
        });
    }
  }

  goToContactDetail() {
    this.isContactDetailHide = this.isContactDetailHide ? false : true;
    if (!this.isContactDetailHide) {
      this.vps.scrollToAnchor("driverInfo");
    }
  }

  monitorRide() {
    this.monitorSubmit = true;
    if (this.bookingResponseData.bookingStatus === 19 || this.bookingResponseData.bookingStatus === 20) {
      this.commonService.OpenNotificationModal("error", "top-end", "Your booking has been completed.");
      return false;
    }
    if (this.monitorForm.valid) {
      var codeLength = this.monitorForm.value.phoneNo.dialCode.length;
      var param = {
        bookingId: this.bookingId,
        moniterRide: {
          name: this.monitorForm.value.name,
          phoneNo: this.monitorForm.value.phoneNo.e164Number.slice(codeLength),
          countryCode: this.monitorForm.value.phoneNo.dialCode,
        },
      };
      this.http
        .httpPostWithHeaderUser("shareYourRide", param)
        .pipe(takeUntil(this.notifier))
        .subscribe((res: any) => {
          if (res.statusCode === 200) {
            this.monitorForm.reset();
            this.monitorSubmit = false;
            this.commonService.OpenNotificationModal("success", "top-end", res.message);
            // $("#add_moniter").modal('hide');
            this.monitorModal = "none";
          }
        });
    }
  }

  openMonitorModal(bookingId) {
    this.bookingId = bookingId;
    this.monitorModal = "block";
    // $("#add_moniter").modal('show');
  }

  calculateDistanceMatrix(pickup, drop, formField) {
    let directionsService = new google.maps.DirectionsService();
    var request = {
      origin: pickup,
      destination: drop,
      optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.DRIVING,
    };
    directionsService.route(request, (response, status) => {
      this.directionStatus = status;
      if (status == google.maps.DirectionsStatus.OK) {
        this.distance = response?.routes[0]?.legs[0]?.distance.value / 1000; //convert m into km
        this.duration = response?.routes[0]?.legs[0]?.duration.value / 60; //convert seconds into minutes
      } else {
        this.commonService.OpenNotificationModal("error", "top-end", "No path found.");
        if (formField !== null) {
          this.dropupForm.get(formField).patchValue("");
        }
      }
      try {
      } catch (error) {
        console.log(error);
      }
    });
  }

  matrix(pickup, drop, formField) {
    return new Promise((resolve, reject) => {
      let directionsService = new google.maps.DirectionsService();
      var request = {
        origin: pickup,
        destination: drop,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING,
      };
      directionsService.route(request, (response, status) => {
        this.directionStatus = status;
        if (status == google.maps.DirectionsStatus.OK) {
          this.distance = response?.routes[0]?.legs[0]?.distance.value / 1000; //convert m into km
          this.duration = response?.routes[0]?.legs[0]?.duration.value / 60; //convert seconds into minutes
          var obj = { distance: this.distance, duration: this.duration };
          return resolve(obj);
        } else {
          // this.commonService.OpenNotificationModal('error', 'top-end', 'No path found.');
          // if (formField !== null) {
          //   this.dropupForm.get(formField).patchValue('');
          // }
        }
        try {
        } catch (error) {
          console.log(error);
        }
      });
    });
  }

  addPickupField() {
    this.pickupFieldsCount++;
    if (!("pickup" + this.pickupFieldsCount in this.pickupFormObj)) {
      this.addFieldPick();
    } else {
      this.pickupFieldsCount++;
      this.addFieldPick();
    }
  }

  addFieldPick() {
    if (!("pickup" + this.pickupFieldsCount in this.pickupFormObj)) {
      this.pickupFormObj["pickup" + this.pickupFieldsCount] = new FormControl("", Validators.required);
      this.pickupForm = this.fb.group(this.pickupFormObj);
      this.pickupfieldsArr.push({
        name: "pickup" + this.pickupFieldsCount,
        lat: 0,
        lng: 0,
        address: "",
        distance: 0,
      });
    } else {
      this.addPickupField();
    }
  }

  removePickupField(index) {
    // this.pickupFieldsCount--;
    // console.log(this.pickupFieldsCount);
    // var size = Object.keys(this.pickupFormObj).length;
    // console.log('size',size);

    this.pickupfieldsArr.splice(index, 1);
    Object.keys(this.pickupFormObj).forEach((element) => {
      let strArray = this.pickupfieldsArr.map((element) => element.name);
      if (!strArray.includes(element)) {
        delete this.pickupFormObj[element];
      }
    });
    this.pickupForm = this.fb.group(this.pickupFormObj);
  }

  addDropupField() {
    this.dropupFieldsCount++;
    if (!("dropup" + this.dropupFieldsCount in this.dropupFormObj)) {
      this.addFieldDrop();
    } else {
      this.dropupFieldsCount++;
      this.addFieldDrop();
    }
  }

  addFieldDrop() {
    if (!("dropup" + this.dropupFieldsCount in this.dropupFormObj)) {
      this.dropupFormObj["dropup" + this.dropupFieldsCount] = new FormControl("", Validators.required);
      this.dropupForm = this.fb.group(this.dropupFormObj);
      this.dropupfieldsArr.push({
        name: "dropup" + this.dropupFieldsCount,
        lat: 0,
        lng: 0,
        address: "",
        distance: 0,
      });
    } else {
      this.addDropupField();
    }
  }

  removeDropupField(index) {
    // this.dropupFieldsCount--;
    // this.dropupfieldsArr.splice(index, 1);

    this.dropupfieldsArr.splice(index, 1);
    Object.keys(this.dropupFormObj).forEach((element) => {
      let strArray = this.dropupfieldsArr.map((element) => element.name);
      if (!strArray.includes(element)) {
        delete this.dropupFormObj[element];
      }
    });
    this.dropupForm = this.fb.group(this.dropupFormObj);
  }

  drawPath(origin, destination, originIcon) {
    var wayPontsMarkerArr = [];
    this.waypoints = [];
    if (this.pickupfieldsArr.length > 1) {
      for (let i = 0; i < this.pickupfieldsArr.length; i++) {
        if (i !== 0) {
          this.waypoints.push({
            location: {
              lat: this.pickupfieldsArr[i].lat,
              lng: this.pickupfieldsArr[i].lng,
            },
            stopover: false,
          });
          wayPontsMarkerArr.push({ icon: "http://i.imgur.com/7teZKif.png" });
        } else {
          wayPontsMarkerArr.push({ icon: "http://i.imgur.com/7teZKif.png" });
        }
      }
      this.markerOptions = {
        origin: {
          icon: originIcon,
        },
        destination: {
          icon: this.destinationMarkerIcon,
        },
        waypoints: wayPontsMarkerArr,
      };
    } else if (this.allPackagesDetail.length > 1) {
      for (let i = 0; i < this.allPackagesDetail.length; i++) {
        if (i !== this.allPackagesDetail.length - 1) {
          this.waypoints.push({
            location: {
              lat: this.allPackagesDetail[i].addressDetails?.itemDropup?.lat,
              lng: this.allPackagesDetail[i].addressDetails?.itemDropup?.lng,
            },
            stopover: false,
          });
          wayPontsMarkerArr.push({ icon: this["marker" + [i + 2]] });
        } else {
          // wayPontsMarkerArr.push({ icon: 'http://i.imgur.com/7teZKif.png' });
        }
      }
      this.markerOptions = {
        origin: {
          icon: originIcon,
        },
        destination: {
          icon: this.destinationMarkerIcon,
        },
        waypoints: wayPontsMarkerArr,
      };
    }
    this.showDirections = true;
    this.showPickMarker = false;
    this.showDropMarker = false;
    this.origin = { lat: origin.lat, lng: origin.lng };
    if (destination?.addressDetails) {
      this.destination = {
        lat: destination?.addressDetails?.itemDropup?.lat,
        lng: destination?.addressDetails?.itemDropup?.lng,
      };
    } else if (destination.lat) {
      this.destination = { lat: destination?.lat, lng: destination?.lng };
    }
  }

  originPathOptimization() {
    this.pickupfieldsArr.forEach((element) => {
      let destination = { lat: element.lat, lng: element.lng };
      this.calculateDistanceForPathOpt(this.current, destination, element);
    });
  }

  destinationPathOptimization() {
    this.allPackagesDetail.forEach((element) => {
      let destination = {
        lat: element.addressDetails?.itemDropup.lat,
        lng: element.addressDetails?.itemDropup.lng,
      };
      this.calculateDistanceForPathOpt(this.pickup, destination, element);
    });
  }

  calculateDistanceForPathOpt(pickup, drop, data) {
    let directionsService = new google.maps.DirectionsService();
    var request = {
      origin: pickup,
      destination: drop,
      optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.DRIVING,
    };
    directionsService.route(request, (response, status) => {
      if (status == google.maps.DirectionsStatus.OK) {
        data.addressDetails.itemDropup.distance = response?.routes[0]?.legs[0]?.distance.value;
      }
    });
  }

  handleItemDropChnage(address, favData: any = null) {
    if (address.formatted_address || favData) {
      var lat = favData ? favData.latitude : address.geometry.location.lat();
      var lng = favData ? favData.longitude : address.geometry.location.lng();
      // if(this.allPackagesDetail.length === this.dropupfieldsArr.length) {
      // this.dropupfieldsArr.push({ name: 'dropup' + this.allPackagesDetail.length, lat: lat, lng: lng, address: address.formatted_address, distance: 0 });
      // }
      this.itemDropAddress = {
        lat: lat,
        lng: lng,
        address: favData ? favData.address : address.formatted_address,
        distance: 0,
      };
      this.packageDetailForm.get("itemDropup").patchValue(favData ? favData.address : address.formatted_address);
      // this.deliveryDetailForm.get('itemDropup').patchValue(address.formatted_address);
      this.detailSubmitted = false;
      var drop = { lat: lat, lng: lng };
      this.getAddressDrop(drop, "", favData);
      this.continue(10);
    }
  }

  goToHome() {
    if (this.nextPopup !== 0) {
      this.commonService.bookingResponseData = null;
      this.continue(0);
      return;
    } else {
      this.commonService.origin = { lat: 0, lng: 0, address: "" };
      this.commonService.destination = { lat: 0, lng: 0, address: "" };
      this.router.navigate(["/uhomepage"]);
    }
  }

  getCardNumberControl(): AbstractControl | null {
    // console.log(this.cardNumberGroup)
    return this.cardNumberGroup && this.cardNumberGroup.get("cardNumber");
  }

  cardMaskFunction(rawValue: string) {
    const card = getValidationConfigFromCardNo(rawValue);
    console.log(card);
    // if (this.cvvLength) {
    //   this.cvvLength = card.cvvLength[0];
    // }
    if (card) {
      return card.mask;
    }
    return [/\d/];
  }

  onCvvPress(value) {
    console.log(value);
    // if (value.length !== this.cvvLength) {
    //   return false;
    // }
  }

  AddCardModal(display) {
    this.addCardModal = display;
  }

  addDebitCard() {
    this.cardSubmitted = true;
    if (
      this.cardNumberGroup.valid &&
      this.cardOwnerFirstName.valid &&
      this.expiryMonth.valid &&
      !this.validateFirstArr.includes(true) &&
      this.expiryYear.valid &&
      this.cvv.valid
    ) {
      var param = {
        cardNumber: this.cardNumberGroup.get("cardNumber").value,
        expDate: this.expiryMonth.value + this.expiryYear.value,
        cvv: this.cvv.value,
        firstName: this.cardOwnerFirstName.value,
        lastName: this.cardOwnerLastName.value,
      };
      this.http.httpPostWithHeaderUser("userCard", param).subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.addCardModal = "none";
          this.commonService.presentsToast("success", "top-end", "Your payment card has been added successfully.");
          this.cardSubmitted = false;
          this.getAllCard();
          this.cardOwnerFirstName.reset();
          this.cardOwnerLastName.reset();
          this.expiryMonth.patchValue(null);
          this.expiryYear.patchValue(null);
          this.cvv.patchValue(null);
          this.cardNumberGroup.get("cardNumber").reset();
        }
      });
    }
  }

  getAllCard() {
    this.http.httpGetWithHeaderOnly("userCard").subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.allSavedCard = [];
        this.allSavedCardData = res.data;
        if (res.data.cards.length > 0) {
          res.data.cards.forEach((data: any) => {
            if (data.isDefault) {
              this.defaultCardDetails = data;
              this.selectedCardDetails = data;
              data["isSelected"] = true;
              this.allSavedCard.push(data);
            } else {
              data["isSelected"] = false;
              this.allSavedCard.push(data);
            }
          });
        } else {
          this.defaultCardDetails = "";
          this.selectedCardDetails = "";
        }
        this.isWalletSelected = false;
      }
    });
  }

  changeDefaultCard(cardId) {
    this.http.httpPostWithHeaderUser("defaultCard", { id: cardId }).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.getAllCard();
      }
    });
  }

  deleteCard(cardId) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.http.httpDeleteByIdUser("userCard", cardId).subscribe((res: any) => {
          if (res.statusCode == 200) {
            Swal.fire("Deleted!", "Card has been deleted.", "success");
            this.getAllCard();
          }
        });
      }
    });
  }

  onCardChange(index) {
    this.allSavedCard = [];
    this.selectedCardDetails = this.allSavedCardData.cards[index - 1];
    this.isWalletSelected = false;
    this.allSavedCardData.cards.forEach((data: any) => {
      if (data.id === this.selectedCardDetails?.id) {
        data["isSelected"] = true;
        this.allSavedCard.push(data);
      } else {
        data["isSelected"] = false;
        this.allSavedCard.push(data);
      }
    });
  }

  onWalletSelected(value) {
    this.isWalletSelected = value;
    if (value) {
      this.allSavedCard = [];
      this.allSavedCardData.cards.forEach((data: any) => {
        data["isSelected"] = false;
        this.allSavedCard.push(data);
      });
    } else {
      this.getAllCard();
    }
  }

  addWalletModal(style) {
    if (!this.allSavedCardData) {
      this.commonService.OpenNotificationModal("success", "top-end", "Please add payment card first.");
      return false;
    }
    if (this.allSavedCardData.cards.length > 0 && style === "block") {
      this.addWalletMoneyModal = style;
      this.defaultCardDetails.id && (this.selectedWalletCardId = this.defaultCardDetails.id);
    } else if (this.allSavedCardData.cards.length === 0 && style === "block") {
      this.commonService.OpenNotificationModal("success", "top-end", "Please add payment card first.");
    }
    if (style === "none") {
      this.addWalletMoneyModal = style;
    }
  }

  onWalletCardChange(card) {
    this.selectedWalletCardId = card.id;
  }

  addWalletMoney() {
    this.walletMoneySubmitted = true;
    if (this.addWalletAmount.valid && parseInt(this.addWalletAmount.value) > 0) {
      this.http
        .httpPostWithHeaderUser("wallet", {
          amount: this.addWalletAmount.value,
          cardId: this.selectedWalletCardId,
        })
        .subscribe((res: any) => {
          if (res.statusCode == 200) {
            this.addWalletModal("none");
            this.addWalletAmount.reset();
            this.getAllCard();
          }
        });
    } else if (parseInt(this.addWalletAmount.value) <= 0) {
      this.addWalletAmount.patchValue(null);
    }
  }

  openPaymentOptions(index) {
    this.getAllCard();
    this.continue(index);
  }

  keypresshandler(event) {
    var charCode = event.keyCode;
    //Non-numeric character range
    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
  }

  onKeyPress(event) {
    if (event.charCode !== 32 && event.charCode !== 39) {
      return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123);
    }
  }

  onChangeFirst(value) {
    this.validateFirstArr = [];
    for (let i = 0; i < value.length; i++) {
      var charCode = value.charCodeAt(i);
      this.validateFirstArr.push(this.charCodeArr.includes(charCode));
    }
  }

  onChangeSecond(value) {
    this.validateSecondArr = [];
    for (let i = 0; i < value.length; i++) {
      var charCode = value.charCodeAt(i);
      this.validateSecondArr.push(this.charCodeArr.includes(charCode));
    }
  }

  goToPreviewBooking(index) {
    if ((this.selectedCardDetails || this.isWalletSelected) && this.vehicleCategoryData.length > 0) {
      let amount =
        this.selectedVehicleData.booKingAmount != null
          ? this.selectedVehicleData.booKingAmount.includes("$")
            ? parseFloat(this.selectedVehicleData.booKingAmount.split("$")[1].replace(",", ""))
            : this.selectedVehicleData.booKingAmount
          : 0;
      if (this.isWalletSelected && !(amount < this.allSavedCardData.walletAmount)) {
        this.continue(2);
        this.commonService.OpenNotificationModal("error", "top-end", this.messagesData.YOU_HAVE_INSUFFICIENT_BALANCE_IN_YOUR_WALLET);
        return false;
      } else {
        console.log("selectedVehicleData", this.selectedVehicleData);
        this.continue(index);
      }
    }
  }

  loyalityModals(style) {
    if (style === "block") {
      this.getLoyalityData();
    }
    this.loyalityModal = style;
  }

  getLoyalityData() {
    this.ngxService.start();
    this.http.httpGetWithHeaderOnly("getLoyalityDiscounts").subscribe(
      (res: any) => {
        this.ngxService.stop();
        if (res.statusCode == 200) {
          this.loyalityModal = "block";
          this.loyalityData = res.data;
        }
      },
      (err) => this.ngxService.stop()
    );
  }

  redeemCoupon(coupon) {
    if (!this.selectedVehicleData.loyalityId) {
      var arr = JSON.parse(JSON.stringify(this.vehicleCategoryData));
      this.vehicleCategoryData = [];
      arr.forEach((element) => {
        var discount;
        if (element.vehicleCategory !== "Any") {
          if (element.discount) {
            if ((element.discount !== null && coupon.couponType === 1) || coupon.couponType === 3) {
              if (element.discount < coupon.couponValue) {
                discount = 0.0;
              } else {
                discount = element.discount - coupon.couponValue;
              }
            } else if (element.discount !== null && coupon.couponType === 2) {
              discount = element.discount - (element.discount * coupon.couponValue) / 100;
            }
            element.discount !== null && (element["booKingAmount"] = element.discount);
            element.discount !== null && (element["discount"] = parseFloat(discount.toFixed(2)));
            element["loyalityId"] = coupon._id;
            this.selectedVehicleData["loyalityId"] = coupon._id;
            this.vehicleCategoryData.push(element);
          } else if (element.discount === 0) {
            this.vehicleCategoryData.push(element);
          } else {
            if ((element.booKingAmount !== null && coupon.couponType === 1) || coupon.couponType === 3) {
              if (element.booKingAmount < coupon.couponValue) {
                discount = 0.0;
              } else {
                discount = element.booKingAmount - coupon.couponValue;
              }
              // discount = (element.booKingAmount < coupon.couponValue) ? 0 : (element.booKingAmount - coupon.couponValue);
              // discount <= 0 && (discount = 0);
            } else if (element.booKingAmount !== null && coupon.couponType === 2) {
              discount = element.booKingAmount - (element.booKingAmount * coupon.couponValue) / 100;
            }
            element.booKingAmount !== null && (element["discount"] = parseFloat(discount.toFixed(2)));
            console.log(element.discount);
            // if (element.discount) {
            element["loyalityId"] = coupon._id;
            this.selectedVehicleData["loyalityId"] = coupon._id;
            // }f
            this.vehicleCategoryData.push(element);
          }
        } else {
          this.vehicleCategoryData.push(element);
        }
      });
      this.selectedVehicleData = this.vehicleCategoryData[0];
      if (coupon.couponType === 1 || coupon.couponType === 3) {
        this.totalLoyality = "U2go Money ($" + coupon.couponValue.toFixed(2) + ")";
      } else {
        this.totalLoyality = "U2go Money (" + coupon.couponValue.toFixed(2) + "%)";
      }
      this.loyalityModal = "none";
    } else {
      this.loyalityModal = "none";
    }
  }

  private payPalInitConfig(currency, amount): void {
    this.payPalConfig = {
      currency: currency,
      clientId: "sb",
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: "CAPTURE",
          purchase_units: [
            {
              amount: {
                currency_code: currency,
                value: amount,
                breakdown: {
                  item_total: {
                    currency_code: currency,
                    value: amount,
                  },
                },
              },
              payee: {
                email_address: "abc@yopmail.com",
                merchant_id: "xxxx",
              },
              items: [
                {
                  name: "Enterprise Subscription",
                  quantity: "1",
                  category: "DIGITAL_GOODS",
                  unit_amount: {
                    currency_code: currency,
                    value: amount,
                  },
                },
              ],
            },
          ],
        },
      advanced: {
        commit: "true",
      },
      style: {
        label: "paypal",
        layout: "vertical",
        color: "blue",
      },
      onApprove: (data, actions) => {
        console.log("onApprove - transaction was approved, but not authorized", data, actions);
        actions.order.get().then((details) => {
          console.log("onApprove - you can get full order details inside onApprove: ", details);
        });
      },
      onClientAuthorization: (data) => {
        console.log("onClientAuthorization - you should probably inform your server about completed transaction at this point", data);
        // this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        console.log("OnCancel", data, actions);
      },
      onError: (err) => {
        console.log("OnError", err);
      },
      onClick: (data, actions) => {
        console.log("onClick", data, actions);
      },
    };
  }

  getChange() {
    return new Promise((resolve, reject) => {
      this.translate
        .get([
          "Excellent",
          "Very Good",
          "Safe Driving",
          "Good",
          "Enjoy Service",
          "Your Driver is ariving in",
          "Your driver arrived to pick up rider in location",
          "Your driver is arriving",
          "min",
          "Your driver arrived to pick you all up",
          "Your driver arrived to pick you up",
          "You started your ride",
          "You arrived at your destination",
          "Your ride has been cancelled by driver",
          "Your delivery has been cancelled by driver",
          "Oops!.. No driver available nearby, please try again later",
          "Reach your destination in",
          "Arrived at your destination",
          "Your rides have started",
          "Arrived at drop-off location",
          "Reach drop off location",
          "Arrived at final destination",
          "in",
          "You have insufficient balance in your wallet",
          "Your payment card has been added successfully",
          "Your driver has canceled delivery at drop off location",
          "heading to next location",
          "Delivery Canceled",
          "Reason",
          "Your driver arrived to pick you up your delivery items",
          "Driver picked up available items. For delivery status please check the App",
          "Driver has arrived at destination",
          "Your package has been delivered",
          "Your driver will reach the  drop off location",
          "Arrived at last drop-off location",
          "Your package could not be delivered because",
          "Delivery",
          "are being returned to you",
          "is being returned to you",
          "has been returned you",
          "Your package has been returned to you",
          "and it is being returned to you",
          "Copied to clipboard",
        ])
        .subscribe((translations) => {
          this.reviewData = [
            { name: translations["Excellent"], isActive: false },
            { name: translations["Very Good"], isActive: false },
            { name: translations["Safe Driving"], isActive: false },
            { name: translations["Good"], isActive: false },
            { name: translations["Enjoy Service"], isActive: false },
          ];
          this.messagesData.YOUR_DRIVER_IS_ARIVING_IN = translations["Your Driver is ariving in"];
          this.messagesData.YOUR_DRIVER_ARRIVED_TO_PICK_UP_RIDER_IN_LOCATION = translations["Your driver arrived to pick up rider in location"];
          this.messagesData.YOUR_DRIVER_IS_ARRIVING = translations["Your driver is arriving"];
          this.messagesData.MIN = translations["min"];
          this.messagesData.YOUR_DRIVER_ARRIVED_TO_PICK_YOU_ALL_UP = translations["Your driver arrived to pick you all up"];
          this.messagesData.YOUR_DRIVER_ARRIVED_TO_PICK_YOU_UP = translations["Your driver arrived to pick you up"];
          this.messagesData.YOU_STARTED_YOUR_RIDE = translations["You started your ride"];
          this.messagesData.YOU_ARRIVED_AT_YOUR_DESTINATION = translations["You arrived at your destination"];
          this.messagesData.YOUR_RIDE_HAS_BEEN_CANCELLED_BY_DRIVER = translations["Your ride has been cancelled by driver"];
          this.messagesData.YOUR_Delivery_HAS_BEEN_CANCELLED_BY_DRIVER = translations["Your delivery has been cancelled by driver"];
          this.messagesData.OOPS_NO_DRIVER_AVAILABLE_NEARBY_PLEASE_TRY_AGAIN_LATER =
            translations["Oops!.. No driver available nearby, please try again later"];
          this.messagesData.REACH_YOUR_DESTINATION_IN = translations["Reach your destination in"];
          this.messagesData.ARRIVED_AT_YOUR_DESTINATION = translations["Arrived at your destination"];
          this.messagesData.YOUR_RIDES_HAVE_STARTED = translations["Your rides have started"];
          this.messagesData.ARRIVED_AT_DROP_OFF_LOCATION = translations["Arrived at drop-off location"];
          this.messagesData.REACH_DROP_OFF_LOCATION = translations["Reach drop off location"];
          this.messagesData.ARRIVED_AT_FINAL_DESTINATION = translations["Arrived at final destination"];
          this.messagesData.IN = translations["in"];
          this.messagesData.YOU_HAVE_INSUFFICIENT_BALANCE_IN_YOUR_WALLET = translations["You have insufficient balance in your wallet"];
          this.messagesData.YOUR_PAYMENT_CARD_HAS_BEEN_ADDED_SUCCESSFULLY = translations["Your payment card has been added successfully"];
          this.messagesData.YOUR_DRIVER_HAS_CANCELED_DELIVERY_AT_DROP_OFF_LOCATION =
            translations["Your driver has canceled delivery at drop off location"];
          this.messagesData.HEADING_TO_NEXT_LOCATION = translations["heading to next location"];
          this.messagesData.Delivery_Canceled = translations["Delivery Canceled"];
          this.messagesData.REASON = translations["Reason"];
          this.messagesData.YOUR_DRIVER_ARRIVED_TO_PICK_YOU_UP_YOUR_DELIVERY_ITEMS =
            translations["Your driver arrived to pick you up your delivery items"];
          this.messagesData.DRIVER_PICKED_UP_AVAILABLE_ITEMS_FOR_DELIVERY_STATUS_PLEASE_CHECK_THE_APP =
            translations["Driver picked up available items. For delivery status please check the App"];
          this.messagesData.DRIVER_HAS_ARRIVED_AT_DESTINATION = translations["Driver has arrived at destination"];
          this.messagesData.YOUR_PACKAGE_HAS_BEEN_DELIVERED = translations["Your package has been delivered"];
          this.messagesData.YOUR_DRIVER_WILL_REACH_THE_DROP_OFF_LOCATION = translations["Your driver will reach the  drop off location"];
          this.messagesData.ARRIVED_AT_LAST_DROP_OFF_LOCATION = translations["Arrived at last drop-off location"];
          this.messagesData.YOUR_PACKAGE_COULD_NOT_BE_DELIVERED_BECAUSE = translations["Your package could not be delivered because"];
          this.messagesData.DELIVERY = translations["Delivery"];
          this.messagesData.ARE_BEING_RETURNED_TO_YOU = translations["are being returned to you"];
          this.messagesData.IS_BEING_RETURNED_TO_YOU = translations["is being returned to you"];
          this.messagesData.HAS_BEEN_RETURNED_YOU = translations["has been returned you"];
          this.messagesData.YOUR_PACKAGE_HAS_BEEN_RETURNED_TO_YOU = translations["Your package has been returned to you"];
          this.messagesData.AND_IT_IS_BEING_RETURNED_TO_YOU = translations["and it is being returned to you"];
          this.messagesData.COPIED_TO_CLIPBOARD = translations["Copied to clipboard"];
          resolve(true);
        });
    });
  }

  goToNotification() {
    localStorage.isNotificationActive = true;
    this.router.navigate(["profilesetup"]);
  }

  goToFavAdd(addType, index, arrIndex) {
    this.getFavAddress();
    this.isResetField = false;
    this.selectedAddressIndex = arrIndex;
    this.addressType = addType;
    this.continue(index);
  }

  patchFavAddress(index, data) {
    this.addressType === "pick" && this.handlePickUpChnage("", "", data);
    this.addressType === "drop" && this.handleItemDropChnage("", data);
    this.addressType === "pick" && this.continue(index);
    // this.addressType === "drop" && this.continue(index);
  }

  getFavAddress() {
    var param = {
      pageNo: this.currentPageA,
    };
    this.http
      .httpPostWithHeaderUser("getAddress", param)
      .pipe(takeUntil(this.notifier))
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.currentPageA = 1;
          this.favAddData = res.data.addressData;
          this.totalPagesA = res.data.totalPages;
          this.nextBtnEnabledA = this.currentPageA < this.totalPagesA;
          this.previousBtnEnabledA = this.currentPageA > 1;
        }
      });
  }

  handleLocationChnage(address) {
    if (address.formatted_address) {
      console.log(address.formatted_address);
      let lat = address.geometry.location.lat();
      let lng = address.geometry.location.lng();
      this.favAddPayload.latitude = lat;
      this.favAddPayload.longitude = lng;
      this.getFavAddressDetail(lat, lng, address.formatted_address);
    }
  }

  onKeyUpAdd(value) {
    if (value === "") {
      this.favAddPayload.latitude = 0;
      this.favAddPayload.longitude = 0;
      this.favAddPayload.address = "";
    }
  }

  onAddChanged(value) {
    if (this.favAddPayload.latitude === 0 && this.favAddPayload.longitude === 0) {
      this.favAddPayload.address = "";
    } else if (this.favAddPayload.localAddress !== value) {
      this.favAddPayload.address = "";
      this.favAddPayload.localAddress = "";
    }
  }

  getFavAddressDetail(lat, lng, formatted_address) {
    this.geoCoder.geocode({ location: { lat: lat, lng: lng } }, (results, status) => {
      if (status === "OK") {
        if (results.length > 0) {
          console.log(results);
          var isAddSimilar = false;
          let length = results[0].address_components.length;
          this.favAddPayload.latitude = lat;
          this.favAddPayload.longitude = lng;
          this.favAddPayload.address = formatted_address;
          this.favAddPayload.localAddress = formatted_address;
          setTimeout(() => {
            if (!this.favAddPayload.zipCode) {
              if (results[0].address_components[length - 1].types.includes("postal_code")) {
                this.favAddPayload.zipCode = results[0].address_components[length - 1].long_name;
              }
            }
          }, 1000);
          results.forEach((addresses) => {
            if (addresses.formatted_address === formatted_address && !isAddSimilar) {
              isAddSimilar = true;
              console.log("exist");
              this.fetchAddress(addresses.address_components);
              return false;
            }
          });
          if (!isAddSimilar) {
            console.log("not-exist");
            this.fetchAddress(results[0].address_components);
          }
        } else {
          // window.alert('No results found');
        }
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  fetchAddress(addressArr) {
    var city = null;
    var state = null;
    var zipCode = null;
    var country = null;
    addressArr.forEach((element) => {
      if (
        element.types.includes("locality") ||
        element.types.includes("postal_town") ||
        element.types.includes("sublocality") ||
        element.types.includes("administrative_area_level_2") ||
        element.types.includes("sublocality_level_1") ||
        element.types.includes("sublocality_level_2") ||
        element.types.includes("sublocality_level_3") ||
        element.types.includes("sublocality_level_4") ||
        element.types.includes("sublocality_level_5")
      ) {
        city = element.long_name;
      }
      if (
        element.types.includes("administrative_area_level_1") ||
        element.types.includes("administrative_area_level_3") ||
        element.types.includes("administrative_area_level_4") ||
        element.types.includes("administrative_area_level_5")
      ) {
        state = element.long_name;
      }
      if (element.types.includes("postal_code")) {
        zipCode = element.long_name;
      }
      if (element.types.includes("country")) {
        country = element.long_name;
      }
    });
    this.favAddPayload.city = city;
    this.favAddPayload.province = state;
    this.favAddPayload.country = country;
    console.log(this.favAddPayload, "favAddPayload");
    // this.secondFormGroup.controls['zipCode'].patchValue(zipCode);
  }

  createFavAddForm() {
    this.favAddPayload = {
      streetAddress: "",
      city: "",
      province: "",
      zipCode: "",
      country: "",
      name: "",
      latitude: 0,
      longitude: 0,
      address: "",
      localAddress: "",
    };
  }

  editfavAdd(data) {
    this.favAddPayload = {
      streetAddress: data.streetAddress,
      city: data.city,
      province: data.province,
      zipCode: data.zipCode,
      country: data.country,
      name: data.name,
      latitude: data.latitude,
      longitude: data.longitude,
      address: data.address,
      localAddress: data.localAddress,
      addressId: data._id,
    };
    this.continue(21);
  }

  submitFavAdd() {
    this.favAddressSubmitted = true;
    if (this.favAddPayload.name && this.favAddPayload.address) {
      if (this.favAddPayload.addressId) {
        this.http.httpPostWithHeaderUser("editAddress", this.favAddPayload).subscribe((res: any) => {
          if (res.statusCode === 200) {
            this.favAddModal = "none";
            this.getFavAddress();
            this.createFavAddForm();
            this.continue(20);
          }
        });
      } else {
        this.http.httpPostWithHeaderUser("addAddress", this.favAddPayload).subscribe((res: any) => {
          if (res.statusCode === 200) {
            this.favAddModal = "none";
            this.getFavAddress();
            this.createFavAddForm();
            this.continue(20);
          }
        });
      }
    }
  }

  showClipBoardMessage() {
    // this.snackBar.openFromComponent(SnackbarComponent, {
    //   duration: 1 * 1000,
    // });
    this.snackBar.open(this.messagesData.COPIED_TO_CLIPBOARD);
    setTimeout(() => {
      this.snackBar.dismiss();
    }, 1000);
    // this.commonService.presentsToast("success", "top-end", "Copied to clipboard");
  }

  onNextPageA() {
    this.currentPageA++;
    this.getFavAddress();
  }

  onChnagePageA(page) {
    this.currentPageA = page;
    this.getFavAddress();
  }

  onPrevPageA() {
    this.currentPageA--;
    this.getFavAddress();
  }

  updateTableA(currentPage) {
    this.previousBtnEnabledA = currentPage > 1;
    this.nextBtnEnabledA = currentPage < this.totalPagesA;
    this.getFavAddress();
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
    clearInterval(this.intervalSubscription);
  }
}
@Component({
  selector: "snack-bar-component-example-snack",
  template: `<span> Copied to clipboard.</span>`,
  styles: [
    `
      .example-pizza-party {
        color: hotpink;
      }
    `,
  ],
})
export class SnackbarComponent {}

<!------start homapge page-->
<div class="homepage">
  <!-- <app-unavbar></app-unavbar> -->
  <app-f-unavbar id="navbar"></app-f-unavbar>
  <div class="header_padding_class">
    <section class="homepage_first_sec">
      <div class="conta_iner">
        <div class="hompage_fs_main">
          <div class="homepage_fs_left">
            <h1>APPTUNIX™ {{ "Ride and Delivery" | translate }}</h1>
            <p>
              {{ "services is a new generation company to serve you with the best customer service" | translate }},
              {{ "technology and costs in the market place where we operate" | translate }}.
            </p>
            <button (click)="goToWhySecton(); pushNotify()">{{ "Learn More" | translate }}</button>
          </div>
          <div class="homepage_fs_img">
            <figure>
              <img src="assets/uimages/home_first.png" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </section>
    <!-------start homepage secound sec-->
    <section class="homepage_ourstory">
      <div class="conta_iner">
        <div class="homepage_ourstory_main">
          <div class="homepage_ourstory_img">
            <figure>
              <img src="assets/uimages/our_story_new.svg" alt="our_story" />
            </figure>
          </div>
          <div class="homepage_ourstory_content">
            <div class="main_headings">
              <h2>{{ "Our Story" | translate }}</h2>
              <h3>
                {{ "We started" | translate }} APPTUNIX™ Enterprises Inc.
                {{ "in 2018 to serve the needs of riders and delivery services" | translate }}.
              </h3>
              <p>
                {{ "For the last 3 years we have been developing state-of-the-art GPS based and mobile applications that are" | translate }}
                {{ "being deployed on North American servers. We expect a soft launch in the winter of" | translate }} {{ currentYear }}.
                {{ "Our management team is highly motivated, innovative" | translate }},
                {{
                  "growth oriented and has a combined experience of more than 60 years delivering mobile solutions in the global marketplace"
                    | translate
                }}.
              </p>
              <button (click)="goToFbPage()">{{ "Learn More" | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <a href="#why_id" id="whyU2go"></a>
    <!------start homepage third section-->
    <section class="all_sec hompage_whysec" id="why_id">
      <div class="conta_iner">
        <div class="homepage_whysec_main">
          <div class="main_headings">
            <h2>{{ "Why" | translate }} APPTUNIX™?</h2>
          </div>
          <!----start our story bx-->
          <div class="home_ourstory_list">
            <div class="homstory_listouter">
              <div class="homstory_list_smbx">
                <div class="homestroy_icon">
                  <figure>
                    <img src="assets/uimages/why_home_icon1.svg" alt="why_home_icon1" />
                  </figure>
                </div>
                <div class="main_headings">
                  <p>{{ "Competitive lower costs and strong value propositions" | translate }}</p>
                </div>
              </div>
              <div class="homstory_list_smbx">
                <div class="homestroy_icon">
                  <figure>
                    <img src="assets/uimages/why_home_icon2.svg" alt="why_home_icon2" />
                  </figure>
                </div>
                <div class="main_headings">
                  <p>
                    {{ "Secure and safe multifactor authentication, data encryption and thorough checking of driver's records" | translate }}
                  </p>
                </div>
              </div>
              <div class="homstory_list_smbx">
                <div class="homestroy_icon">
                  <figure>
                    <img src="assets/uimages/why_home_icon3.svg" alt="why_home_icon1" />
                  </figure>
                </div>
                <div class="main_headings">
                  <p>{{ "Reliable and efficient rides and deliveries" | translate }}</p>
                </div>
              </div>
              <div class="homstory_list_smbx">
                <div class="homestroy_icon">
                  <figure>
                    <img src="assets/uimages/why_home_icon4.svg" alt="why_home_icon1" />
                  </figure>
                </div>
                <div class="main_headings">
                  <p>
                    {{ "Multiple payment options (credit, debit, digital wallet, etc)" | translate }}
                  </p>
                </div>
              </div>
              <div class="homstory_list_smbx">
                <div class="homestroy_icon">
                  <figure>
                    <img src="assets/uimages/why_home_icon5.svg" alt="why_home_icon1" />
                  </figure>
                </div>
                <div class="main_headings">
                  <p>
                    {{ "Excellent customer service and leading innovative technology" | translate }}
                  </p>
                </div>
              </div>
              <div class="homstory_list_smbx">
                <div class="homestroy_icon">
                  <figure>
                    <img src="assets/uimages/why_home_icon6.svg" alt="why_home_icon1" />
                  </figure>
                </div>
                <div class="main_headings">
                  <p>{{ "We give you control of your rides or deliveries" | translate }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!----start our our driver sec-->
    <section class="homepage_ourdriver home_page_items">
      <div class="conta_iner">
        <div class="homepage_ourdriver_main">
          <div class="homepage_ourdriver_img">
            <figure>
              <img src="assets/uimages/our_driver.png" alt="our_driver" />
            </figure>
          </div>
          <div class="homepage_ourdriver_content">
            <div class="main_headings">
              <h2>{{ "Our Drivers" | translate }}</h2>
              <h3>
                {{ "are customer oriented, professional and thoroughly checked to ensure best service in the industry" | translate }}.
                {{ "They benefit from" | translate }}:
              </h3>
              <ul class="ourdriver_list">
                <li>
                  <span>{{ "Competitive compensation and flexible hours" | translate }}</span>
                </li>
                <li>
                  <span>{{ "Opportunities to earn money from rides or deliveries or both" | translate }} </span>
                </li>
                <li>
                  <span>{{ "Company ownership possibilities" | translate }}</span>
                </li>
                <li>
                  <span>{{ "Tips opportunities for each ride or delivery" | translate }} ({{ "at the customer option" | translate }}) </span>
                </li>
                <li>
                  <span>{{ "Coupons, loyalty and other company benefits" | translate }} </span>
                </li>
              </ul>
              <div class="two_btn">
                <button (click)="goTosignup()">{{ "Sign up as Driver" | translate }}</button>
                <button class="onlyborder_fillbtn" (click)="goTologin()">
                  {{ "Login as Driver" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-----start our Riders section-->
    <section class="all_sec homepage_ourdriver homepage_ourriders">
      <div class="conta_iner">
        <div class="homepage_ourdriver_main">
          <div class="homepage_ourdriver_img" (click)="goToriderdeliverybooking()">
            <figure>
              <img src="assets/uimages/our_riders.png" alt="our_riders" />
            </figure>
          </div>
          <div class="homepage_ourdriver_content">
            <div class="main_headings">
              <h2>{{ "Our Riders" | translate }}</h2>
              <h3>APPTUNIX™ {{ "will provide our Riders with" | translate }}:</h3>
              <ul class="ourdriver_list">
                <li>
                  <span>{{ "Best rates and great drivers" | translate }} </span>
                </li>
                <li>
                  <span>{{ "Ride now or schedule a ride and choose your vehicle of choice (subject to availability)" | translate }} </span>
                </li>
                <li>
                  <span>{{ "Loyalty and coupon programs" | translate }} </span>
                </li>
                <li>
                  <span>{{ "Electronic commerce" | translate }} </span>
                </li>
                <li>
                  <span>{{ "Ability to monitor rides from acceptance to completion" | translate }} </span>
                  <div class="ourdriver_sublist">
                    <ul>
                      <li>
                        <span>{{ "From rider perspective" | translate }} </span>
                      </li>
                      <li>
                        <span>{{ "From a family or friend perspective" | translate }} </span>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
              <div class="two_btn">
                <button (click)="goToridersignup()">{{ "Sign up as Rider" | translate }}</button>
                <button class="onlyborder_fillbtn" (click)="goToriderlogin()">
                  {{ "Login as Rider" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--------------deilvery patners sec-->
    <section class="homepage_ourdriver homepage_deliverypat">
      <div class="conta_iner">
        <div class="homepage_ourdriver_main">
          <div class="homepage_ourdriver_img">
            <figure>
              <img src="assets/uimages/delivery_partners.png" alt="" />
            </figure>
          </div>
          <div class="homepage_ourdriver_content">
            <div class="main_headings">
              <h2>{{ "Delivery Partners" | translate }}</h2>
              <h3>
                {{ "Our Delivery Partners rely on us to deliver their goods to their customer in a cost efficient and" | translate }}
                {{ "reliable manner. We offer this service in a variety of industries such as food delivery, auto parts" | translate }},
                {{ "health care, banking, real estate and others. Delivery" | translate }}
                {{ "Partners will benefit as follows" | translate }}:
              </h3>
              <ul class="ourdriver_list">
                <li>
                  <span>{{ "Very low transaction costs (free for the first 6 months)" | translate }}</span>
                </li>
                <li>
                  <span>{{ "Reliable and efficient service" | translate }}</span>
                </li>
                <li>
                  <span>{{ "Loyalty and coupon programs" | translate }} </span>
                </li>
                <li>
                  <span>{{ "Electronic commerce" | translate }}</span>
                </li>
                <li>
                  <span>{{ "Ability to track and monitors all aspects of the delivery work" | translate }}</span>
                  <div class="ourdriver_sublist">
                    <ul>
                      <li>
                        <span>{{ "From a Delivery Partner perspective" | translate }} </span>
                      </li>
                      <li>
                        <span>{{ "From a Delivery Partner Customer perspective" | translate }} </span>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
              <!-- <div class="two_btn">
                <button class="onlyborder_fillbtn" routerLink="/udeliverypartners">
                  Sign up as Delivery Partner
                </button>
                <button class="onlyborder_fillbtn" routerLink="/udeliverypartners">
                  Login as Delivery Partner
                </button>
              </div> -->
              <div class="two_btn">
                <button (click)="goTosignup()">{{ "Sign up as Delivery Partner" | translate }}</button>
                <button class="onlyborder_fillbtn" (click)="goTologin()">
                  {{ "Login as Delivery Partner" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-----start business partners section css-->
  </div>
</div>
<section class="all_sec homepage_ourdriver homepage_ourriders homepage_business">
  <div class="conta_iner">
    <div class="homepage_ourdriver_main">
      <div class="homepage_ourdriver_img">
        <figure>
          <img src="assets/uimages/home_businesspartners.png" alt="" />
        </figure>
      </div>
      <div class="homepage_ourdriver_content">
        <div class="main_headings">
          <h2>{{ "Business Partners" | translate }}</h2>
          <h3>
            {{ "Our Business Partners can operate the APPTUNIX™ solution in an" | translate }}
            {{ "exclusive jurisdiction (e.g. a City, Province/State/Country)" | translate }}.
            {{ "It is a turn-key solution adapted to their jurisdiction" | translate }}.
            <span>{{ "To learn more please contact us at: sales@apptunix.com" | translate }}</span>
          </h3>

          <div class="two_btn">
            <button class="onlyborder_fillbtn" (click)="goToBP()">
              {{ "Become a Business Partner" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-----start our app section-->
<section class="our_app" id="our_app">
  <div class="conta_iner">
    <div class="our_app_main">
      <div class="our_app_screen">
        <figure>
          <img src="assets/uimages/app_screen.png" alt="" />
        </figure>
      </div>
      <div class="our_app_content">
        <h2>
          {{ "Download our Apps and start experiencing a new world of possibilities" | translate }}
        </h2>
        <div class="app_downloadlink">
          <a href="">
            <img src="assets/uimages/google_store.png" alt="" />
          </a>
          <a href="">
            <img src="assets/uimages/app_store.png" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>

<!-- Modal -->
<div class="modal fade promo_page" id="add_business" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button " class="close" data-dismiss="modal" aria-label="Close">
          <span><i class="fa fa-times" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h3 class="add_card_title">{{ "Become a business partner" | translate }}</h3>
        <div class="business_partner_input">
          <input type="text" class="form-control" placeholder="{{ 'Full name' | translate }}" />
          <input type="text" class="form-control" placeholder="{{ 'Address' | translate }}" />
          <input type="text" class="form-control" placeholder="{{ 'Phone' | translate }}" />
          <input type="text" class="form-control" placeholder="{{ 'City,State,Country,Region of interest' | translate }}" />
          <textarea name="" id="" placeholder="{{ 'Enter you comments here' | translate }}"></textarea>
        </div>
        <div class="btn_outer">
          <button class="btn btn-primary margin_top20">{{ "Submit" | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal_outer">
  <div id="add" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="main" style="text-align: center">
            <i class="fa fa-times close" data-dismiss="modal" aria-label="Close" (click)="closeModal()"></i>
            <h3 *ngIf="advertisementData?.text">{{ advertisementData?.text }}</h3>
            <video class="vds" id="video" #myVideo *ngIf="advertisementData?.videoclip" controls autoplay muted="true">
              <source [src]="baseUrl + advertisementData?.videoclip" />
            </video>
            <figure class="img-s" *ngIf="advertisementData?.picture">
              <img [src]="baseUrl + advertisementData?.picture" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

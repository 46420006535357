<router-outlet></router-outlet>
<ngx-ui-loader></ngx-ui-loader>
<div id="addpayment" class="modal fade" role="dialog" [ngStyle]="{'display':notification?.modal,'opacity':1}">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
            </div>
            <div class="modal-body">
                <div class="main">
                    <i class="fa fa-times close" (click)="notification.modal = 'none'" aria-hidden="true"></i>
                    <div class="succes-msg">
                        <h2>{{notification?.message}}</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

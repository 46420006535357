export class fConstant {
  BOOKING_STATUS: any = {
    PENDING: 1,
    ACCEPTED: 2,
    COMPLETED: 3,
    ARRIVED: 4,
    STARTED: 6,
    ONGOING: 7,
    CANCELED: 8,
    OUT_FOR_DELIVERY: 9,
    PACKAGES_PICKED: 10,
    TRANSIT: 11,
    PARTIAL: 12,
    UPCOMING: 13,
    canceled: 14,
    SCHEDULED: 15,
    ARRIVED_AT_PICKED: 16,
    PICKED: 17,
    ARRIVED_AT_DROPED: 19,
    DROPED: 20,
    START_RETURN: 21,
    END_RETURN: 22,
    AVAILABLE: 23,
    NOT_AVAILABLE: 24,
    NONE: 25,
  };
  SHOW_STATUS_BOOKING: any = {
    1: "PENDING",
    2: "ACCEPTED",
    3: "COMPLETED",
    4: "ARRIVED",
    6: "STARTED",
    7: "ONGOING",
    8: "CANCELED",
    9: "OUT FOR DELIVERY",
    10: "PACKAGES PICKED",
    11: "TRANSIT",
    12: "PARTIAL",
    13: "UPCOMING",
    14: "canceled",
    15: "SCHEDULED",
    16: "ARRIVED AT PICKED",
    17: "PICKED",
    19: "ARRIVED AT DROPED",
    20: "DROPED",
    21: "START_RETURN",
    22: "END RETURN",
    23: "AVAILABLE",
    24: "NOT AVAILABLE",
    25: "NONE",
  };
  BOOKING_TYPE: any = {
    RIDE: 1,
    DELIVERY: 2,
    DEFAULT: 0,
  };
  DISCOUNT_KEYS: any = {
    DEFAULT: 0,
    BASE_FARE: 1,
    DELIVERY_TRANSACTION_FEE: 2,
    PER_KM: 3,
    PER_MIN: 4,
    FINAL_FARE: 5,
  };
  DISCOUNT_TYPES: any = {
    FLAT: 1,
    PERCENTAGE: 2,
    PARTIAL: 3,
  };
  SHOW_BOOKING_TYPE: any = {
    1: "RIDE",
    2: "DELIVERY",
    0: "DEFAULT",
  };
  PAYMENT_MODE: any = {
    CASH: 1,
    CARD: 2,
    WALLET: 3,
  };
  PAYMENT_STATUS: any = {
    PENDING: 1,
    COMPLETED: 2,
  };
  ALPHAB_STATUS: any = {
    1: " A ",
    2: " B ",
    3: " C ",
    4: " D ",
    5: " E ",
    6: " F ",
    7: " G ",
    8: " H ",
    9: " I  ",
    10: " J ",
  };
  CANCELLATION_TIME: any = {
    SECONDS: 10,
  };
  MSGS: any = {
    CONGRATS:
      "Congratulations! We have not yet launched. This is a beta version and we look forward to your feedback. We will let you know when we will launch in your city.",
    PRELAUNCH_RIDE:
      "Fasten your seat belts, plan your rides, we will be launching soon!  This is a pre-launch website with basic functionality, but we will be launching soon!",
    PRELAUNCH_DELIVERY:
      "Fasten your seat belts, prepare your orders, we will be launching soon!  This is a pre-launch website with basic functionality, but we will be launching soon!",
    EMAIL_EXIST: "The specified email address already exists. Please sign up with another one or try to log in.",
    PRELAUNCH_DRIVER: "Fasten your seat belts, we will be launching soon!  This is a pre-launch website with basic functionality.",
    PHONE_EXIST: "The specified mobile number already exists. Please sign up with another one or try to log in.",
  };

  PUSH_TYPE: any = {
    0: {
      keys: [
        {
          key: "companyName",
          label: "Company Name",
          index: 0,
          value: null,
          isShow: true,
          dataType: "String",
        },
      ],
      message: {
        en: "You have a new booking request for a ride",
        ar: "You have a new booking request for a ride",
      },
      title: {
        en: "U2GoDriver",
        ar: "U2GoDriver",
      },
    },
    1: {
      keys: [],
      message: {
        en: "You have new ride {{userId.firstName}}",
        ar: "You have new ride {{userId.firstName}} ",
      },
      title: {
        en: "U2GoDriver",
        ar: "U2GoDriver",
      },
    },
    2: {
      keys: [],
      message: {
        en: "Your booking has been accepted by {{driverId.firstName}}",
        ar: "Your booking has been accepted by {{driverId.firstName}} ",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    3: {
      keys: [],
      message: {
        en: "Driver has arrived at pick-up location.",
        ar: "Driver has arrived at pick-up location. ar",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    4: {
      keys: [],
      message: {
        en: "Your ride has been started.",
        ar: "Your ride has been started.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    5: {
      keys: [],
      message: {
        en: "Your ride is completed.",
        ar: "Your ride is completed. ar",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    6: {
      keys: [],
      message: {
        en: "Your ride has been canceled by driver.",
        ar: "Your ride has been canceled by driver.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    7: {
      keys: [],
      message: {
        en: "No driver available nearby, please try again later.",
        ar: "No driver available nearby, please try again later. ar",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    8: {
      keys: [],
      message: {
        en: "Oops!.. No driver available nearby, please try again later.",
        ar: "Oops!.. No driver available nearby, please try again later. ar",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    9: {
      keys: [],
      message: {
        en: "Scheduling your ride.",
        ar: "Scheduling your ride. ar",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    10: {
      keys: [],
      message: {
        en: "Your booking has been accepted by {{driverId.firstName}}.",
        ar: "Your booking has been accepted by {{driverId.firstName}}.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    11: {
      keys: [],
      message: {
        en: "Driver has arrived at pick-up location.",
        ar: "Driver has arrived at pick-up location. ar",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    12: {
      keys: [],
      message: {
        en: "Your delivery has been started.",
        ar: "Your delivery has been started.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    13: {
      keys: [],
      message: {
        en: "Your delivery is completed.",
        ar: "Your delivery is completed. ar",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    14: {
      keys: [],
      message: {
        en: "Driver is out for delivery.",
        ar: "Driver is out for delivery. ar",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    15: {
      keys: [],
      message: {
        en: "Your delivery has been canceled by driver.",
        ar: "Your delivery has been canceled by driver.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    16: {
      keys: [],
      message: {
        en: "You have a new request for a delivery.",
        ar: "You have a new request for a delivery.",
      },
      title: {
        en: "U2GoDriver",
        ar: "U2GoDriver",
      },
    },
    17: {
      keys: [],
      message: {
        en: "Scheduling your delivery.",
        ar: "Scheduling your delivery. ar",
      },
      title: {
        en: "U2GoDriver",
        ar: "U2GoDriver",
      },
    },
    18: {
      keys: [],
      message: {
        en: "Your packages have been picked up.",
        ar: "Your packages have been picked up. ar",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    19: {
      keys: [],
      message: {
        en: "New lead of delivery partner.",
        ar: "New lead of delivery partner.",
      },
      title: {
        en: "New lead of delivery partner.",
        ar: "New lead of delivery partner.",
      },
    },
    20: {
      keys: [],
      message: {
        en: "New lead of business partner.",
        ar: "New lead of business partner.",
      },
      title: {
        en: "New lead of business partner.",
        ar: "New lead of business partner.",
      },
    },
    21: {
      keys: [],
      message: {
        en: "Your ride has been canceled by customer.",
        ar: "Your ride has been canceled by customer.",
      },
      title: {
        en: "U2GoDriver",
        ar: "U2GoDriver",
      },
    },
    22: {
      keys: [],
      message: {
        en: "Your delivery has been canceled by customer.",
        ar: "Your delivery has been canceled by customer.",
      },
      title: {
        en: "U2GoDriver",
        ar: "U2GoDriver",
      },
    },
    23: {
      keys: [],
      message: {
        en: "{{driverId.firstName}} thanked you for tip.",
        ar: "{{driverId.firstName}} thanked you for tip.",
      },
      title: {
        en: "U2GoDriver",
        ar: "U2GoDriver",
      },
    },
    24: {
      keys: [],
      message: {
        en: "Driver is on the way.",
        ar: "Driver is on the way.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    25: {
      keys: [],
      message: {
        en: "Driver is on the way.",
        ar: "Driver is on the way.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    26: {
      keys: [],
      message: {
        en: "Your ride has been started.",
        ar: "Your ride has been started.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    27: {
      keys: [],
      message: {
        en: "Your ride has been started.",
        ar: "Your ride has been started.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    28: {
      keys: [],
      message: {
        en: "Driver is on the way.",
        ar: "Driver is on the way.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    29: {
      keys: [],
      message: {
        en: "Driver is on the way.",
        ar: "Driver is on the way.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    30: {
      keys: [],
      message: {
        en: "You have arrived at your destination.",
        ar: "You have arrived at your destination.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    31: {
      keys: [],
      message: {
        en: "You have arrived at your destination.",
        ar: "You have arrived at your destination.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    32: {
      keys: [],
      message: {
        en: "Driver is offline.",
        ar: "Driver is offline.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    33: {
      keys: [],
      message: {
        en: "Out Of service.",
        ar: "Out Of service.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    34: {
      keys: [],
      message: {
        en: "Unfortunately your driver has canceled the ride. Now we are searching for a new driver.",
        ar: "Unfortunately your driver has canceled the ride. Now we are searching for a new driver.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    35: {
      keys: [],
      message: {
        en: "Unfortunately your driver has canceled the delivery. Now we are searching for a new driver.",
        ar: "Unfortunately your driver has canceled the delivery. Now we are searching for a new driver.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    36: {
      keys: [],
      message: {
        en: "Driver start to return the package.",
        ar: "Driver start to return the package.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    37: {
      keys: [],
      message: {
        en: "Driver returned the package.",
        ar: "Driver returned the package.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    38: {
      keys: [],
      message: {
        en: "Driver marked available on ride.",
        ar: "Driver marked available on ride.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    39: {
      keys: [],
      message: {
        en: "Driver marked available package.",
        ar: "Driver marked available package.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    40: {
      keys: [],
      message: {
        en: "Driver marked not available on ride.",
        ar: "Driver marked not available on ride.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    41: {
      keys: [],
      message: {
        en: "Driver marked not available package.",
        ar: "Driver marked not available package.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    42: {
      keys: [],
      message: {
        en: "Driver marked none ride.",
        ar: "Driver marked none ride.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    43: {
      keys: [],
      message: {
        en: "Driver marked none package.",
        ar: "Driver marked none package.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    44: {
      keys: [],
      message: {
        en: "Your ride has been canceled by driver.",
        ar: "Your ride has been canceled by driver.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    45: {
      keys: [],
      message: {
        en: "Your delivery has been canceled by driver.",
        ar: "Your delivery has been canceled by driver.",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    46: {
      keys: [],
      message: {
        en: "Banner",
        ar: "Banner",
      },
      title: {
        en: "Rider and Delivery Partner",
        ar: "Rider and Delivery Partner",
      },
    },
    47: {
      keys: [],
      message: {
        en: "Banner",
        ar: "Banner",
      },
      title: {
        en: "U2GoDriver",
        ar: "U2GoDriver",
      },
    },
  };
}

<!------start homapge page-->
<div class="businesspartners_page">
  <app-f-unavbar></app-f-unavbar>
  <div class="header_padding_class">
    <section class="riders_first_sec businesspartners_first_sec">
      <div class="conta_iner">
        <div class="riders_first_main">
          <div class="rider_firstsec_content">
            <div class="main_headings">
              <h2>{{ "Partner with us and run your own business" | translate }}</h2>
              <h3>
                {{ "APPTUNIX™ Enterprises Inc. is a leading, innovative and growth oriented company" | translate }}
                {{ "who is offering Business Partner (BP) opportunities in selected markets" | translate }}.
              </h3>
            </div>
            <div class="main_headings">
              <button (click)="scrollToFormSection()">{{ "Become a Businesses Partners" | translate }}</button>
            </div>
          </div>
          <div class="rider_firstsec_img">
            <figure>
              <img src="assets/uimages/business_partners_firstsec.png" alt="riders" />
            </figure>
          </div>
        </div>
      </div>
    </section>
    <!-----start delivery partners secound sec-->
    <section class="all_sec riders_first_sec businesspartners_secound">
      <div class="conta_iner">
        <div class="riders_first_main second">
          <div class="rider_firstsec_content">
            <div class="main_headings">
              <h2>{{ "Our state-of-the-art" | translate }}</h2>
              <p>
                {{
                  "reliable and secure technology enables our BPs to profitably grow their businesses without the large capital costs associated with know-how, technology, processes and infrastructure investments"
                    | translate
                }}.
              </p>
            </div>
          </div>
          <div class="rider_firstsec_img">
            <figure>
              <img src="assets/uimages/business_ourart.png" alt="riders" />
            </figure>
          </div>
        </div>
      </div>
    </section>
    <!-----cta sec-->
    <section class="u2go_opportunities">
      <div class="conta_iner">
        <div class="u2go_opportunities_main">
          <div class="u2go_opportunities_content">
            <div class="main_headings">
              <h2>{{ "If You Are Interested In Business Partner Opportunities" | translate }}</h2>
              <h3>
                {{ "please send us a quick email indicating your market of interest (e.g. City, Province/State, Country)" | translate }}
                {{
                  "and your stage in the process (e.g. investigating opportunities, investigating APPTUNIX™, ready to make a commitment with the next 3 months)"
                    | translate
                }}.
                <span>{{ "Please contact Apptunix at sales@apptunix.com" | translate }}</span>
              </h3>
            </div>
          </div>
          <div class="u2go_opportunities_img">
            <figure>
              <img src="assets/uimages/oppurinty.png" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </section>
    <a href="/ubusinesspartners#partner" id="bp"></a>
    <section id="partner">
      <div class="login">
        <div class="conta_iner">
          <div class="login_main">
            <div class="login_form center">
              <h4>
                {{ "Please contact us" | translate }}
                <!-- <span>Become a Delivery Partner</span> -->
              </h4>

              <form class="form_control_form" [formGroup]="partnerForm">
                <div class="form-group">
                  <mat-form-field [floatLabel]="'never'">
                    <input class="form-control" formControlName="firstName" matInput type="text" placeholder="{{ 'First name' | translate }}" />
                  </mat-form-field>
                  <mat-error *ngIf="submitted && partnerForm.controls['firstName'].hasError('required')"
                    >{{ "First name is required" | translate }}.</mat-error
                  >
                  <mat-error *ngIf="submitted && partnerForm.controls['firstName'].hasError('pattern')"
                    >{{ "Please enter valid first name" | translate }}.</mat-error
                  >
                </div>
                <div class="form-group">
                  <mat-form-field [floatLabel]="'never'">
                    <input class="form-control" formControlName="lastName" matInput type="text" placeholder="{{ 'Last name' | translate }}" />
                  </mat-form-field>
                  <mat-error *ngIf="submitted && partnerForm.controls['lastName'].hasError('required')"
                    >{{ "Last name is required" | translate }}.</mat-error
                  >
                  <mat-error *ngIf="submitted && partnerForm.controls['lastName'].hasError('pattern')"
                    >{{ "Please enter valid last name" | translate }}.</mat-error
                  >
                </div>
                <div class="form-group">
                  <mat-form-field [floatLabel]="'never'">
                    <input class="form-control" type="email" matInput placeholder="{{ 'Email address' | translate }}" formControlName="email" />
                  </mat-form-field>
                  <mat-error *ngIf="submitted && partnerForm.controls['email'].hasError('required')"
                    >{{ "Email is required" | translate }}.</mat-error
                  >
                  <mat-error *ngIf="submitted && partnerForm.controls['email'].hasError('pattern')"
                    >{{ "Sorry, only letters (a-z), number (0-9), and periods (.) are allowed" | translate }}.</mat-error
                  >
                </div>
                <div class="form-group">
                  <!-- <mat-form-field [floatLabel]="'never'"> -->
                  <ngx-intl-tel-input
                    [inputId]="'adsasd'"
                    matInput
                    [cssClass]="'custom'"
                    [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true"
                    [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                    [selectFirstCountry]="false"
                    [selectedCountryISO]="CountryISO.Canada"
                    [maxLength]="15"
                    [tooltipField]="TooltipLabel.Name"
                    [phoneValidation]="true"
                    [separateDialCode]="separateDialCode"
                    name="phone"
                    formControlName="phoneNo"
                  >
                  </ngx-intl-tel-input>
                  <!-- </mat-form-field> -->
                  <mat-error *ngIf="submitted && partnerForm.controls['phoneNo'].hasError('required')"
                    >{{ "Phone number is required" | translate }}.</mat-error
                  >
                  <mat-error *ngIf="submitted && partnerForm.controls['phoneNo'].hasError('validatePhoneNumber')">
                    {{ "Enter valid number" | translate }}.</mat-error
                  >
                </div>
                <div class="form-group">
                  <div class="fgi sec">
                    <textarea
                      id="w3review"
                      class="from-control"
                      placeholder="{{ 'Enter your comment here' | translate }}"
                      formControlName="comment"
                    ></textarea>
                    <mat-error *ngIf="submitted && partnerForm.controls['comment'].hasError('required')">
                      {{ "Comment is required" | translate }}.</mat-error
                    >
                  </div>
                </div>

                <div class="flex submit_btn">
                  <button class="btn btn-primary" (click)="submit()">
                    {{ "Submit" | translate }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <app-footer></app-footer>
  </div>
</div>

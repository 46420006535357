<div class="profile_wrap">
    <div class="congrutions_page">

        <div class="congrutions_img">
            <figure>
                <img id="main_img" src="assets/uimages/Congratulations.svg" alt="" />
            </figure>
            <h3 *ngIf="commonService?.isThisCongrats">{{'Congratulations' | translate}}</h3>
            <p>{{commonService?.alertMessage}}</p>
            <div class="btn_common_cls">
                <button class="btn btn-primary" (click)="goTouhomepage()">
                    {{'Okay' | translate}}
                </button>
            </div>
        </div>
    </div>

</div>
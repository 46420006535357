import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { apiFList } from "./api-list";
import { apiFListUser } from "./api-list";
import { LanguageService } from "src/app/services/language/language.service";

@Injectable({
  providedIn: "root",
})
export class HttpsService {
  baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient, private apiList: apiFList, private apiListUser: apiFListUser, private languageService: LanguageService) {}

  httpPost(url: string, params) {
    return this.http.post(this.baseUrl + this.apiList[url], params, this.languageHeader());
  }

  httpPostWithHeader(url: string, params: any) {
    return this.http.post(this.baseUrl + this.apiList[url], params, this.header());
  }

  httpGetWithHeader(url: string, params) {
    return this.http.get(this.baseUrl + this.apiList[url] + "?" + params, this.header());
  }

  httpGet(url: string) {
    return this.http.get(this.baseUrl + this.apiList[url], this.languageHeader());
  }

  fileUpload(fileData: any) {
    var formData = new FormData();
    formData.append("image", fileData, fileData.name);
    const headers = new HttpHeaders({
      mimeType: "multipart/form-data",
      authorization: localStorage.accessToken,
      "content-language": localStorage.languageCode ? localStorage.languageCode : "en",
    });
    const headersObj = { headers };
    return this.http.post(this.baseUrl + this.apiList["uploadFile"], formData, headersObj);
  }

  userFileUpload(fileData: any) {
    var formData = new FormData();
    formData.append("image", fileData, fileData.name);
    const headers = new HttpHeaders({
      mimeType: "multipart/form-data",
      authorization: localStorage.accessToken,
      "content-language": localStorage.languageCode ? localStorage.languageCode : "en",
    });
    const headersObj = { headers };
    return this.http.post(this.baseUrl + this.apiListUser["uploadFile"], formData, headersObj);
  }

  userFileUploadWithoutAuth(fileData: any) {
    var formData = new FormData();
    formData.append("image", fileData, fileData.name);
    const headers = new HttpHeaders({
      mimeType: "multipart/form-data",
      "content-language": localStorage.languageCode ? localStorage.languageCode : "en",
    });
    const headersObj = { headers };
    return this.http.post(this.baseUrl + this.apiListUser["uploadFileWithOutAuth"], formData, headersObj);
  }

  header() {
    if (localStorage.accessToken != undefined || localStorage.accessToken != null) {
      const headers = new HttpHeaders({
        "cache-control": "no-cache",
        "Content-Type": "application/json",
        // "mimeType": "multipart/form-data",
        "content-language": localStorage.languageCode ? localStorage.languageCode : "en",
        authorization: localStorage.accessToken,
      });
      const option = {
        headers,
      };
      return option;
    } else {
      const headers = new HttpHeaders({
        "cache-control": "no-cache",
        "content-language": localStorage.languageCode ? localStorage.languageCode : "en",
      });
      const option = {
        headers,
      };
      return option;
    }
  }

  multipartHeader() {
    if (localStorage.accessToken != undefined || localStorage.accessToken != null) {
      const headers = new HttpHeaders({
        "cache-control": "no-cache",
        // 'Content-Type': 'application/json',
        mimeType: "multipart/form-data",
        "content-language": localStorage.languageCode ? localStorage.languageCode : "en",
        authorization: localStorage.accessToken,
      });
      const option = {
        headers,
      };
      return option;
    } else {
      const headers = new HttpHeaders({
        "cache-control": "no-cache",
        "content-language": localStorage.languageCode ? localStorage.languageCode : "en",
      });
      const option = {
        headers,
      };
      return option;
    }
  }

  multipartHeaderWithoutAuth() {
    const headers = new HttpHeaders({
      "cache-control": "no-cache",
      // 'Content-Type': 'application/json',
      "content-language": localStorage.languageCode ? localStorage.languageCode : "en",
      mimeType: "multipart/form-data",
    });
    const option = {
      headers,
    };
    return option;
  }

  languageHeader() {
    const headers = new HttpHeaders({
      "cache-control": "no-cache",
      "content-language": localStorage.languageCode ? localStorage.languageCode : "en",
      // mimeType: "multipart/form-data",
    });
    const option = {
      headers,
    };
    return option;
  }

  // user

  httpPostUser(url: string, params) {
    return this.http.post(this.baseUrl + this.apiListUser[url], params, this.languageHeader());
  }

  httpPostWithHeaderUser(url: string, params: any) {
    return this.http.post(this.baseUrl + this.apiListUser[url], params, this.header());
  }

  httpPostWithHeaderonly(url: string, params: any) {
    return this.http.post(this.baseUrl + this.apiListUser[url], params, this.languageHeader());
  }

  httpDeleteByIdUser(url: string, Id: any) {
    return this.http.delete(this.baseUrl + this.apiListUser[url] + "/" + Id, this.header());
  }

  httpGetWithHeaderUser(url: string, params) {
    return this.http.get(this.baseUrl + this.apiListUser[url] + "?" + params, this.header());
  }

  httpGetWithHeaderOnly(url: string) {
    return this.http.get(this.baseUrl + this.apiListUser[url], this.header());
  }

  httpGetUser(url: string) {
    return this.http.get(this.baseUrl + this.apiListUser[url], this.languageHeader());
  }

  httpPostWithFormDataHeader(url: string, params: any) {
    return this.http.post(this.baseUrl + this.apiListUser[url], params, this.multipartHeader());
  }
  httpPostWithFormDataWithoutAuth(url: string, params: any) {
    return this.http.post(this.baseUrl + this.apiListUser[url], params, this.multipartHeaderWithoutAuth());
  }
}

<div class="login_wrap top">
  <div class="signupinner">
    <div class="conta_iner">
      <div class="flex">
        <div class="haeder_left">
          <figure class="logo_cm" style="cursor: pointer;" (click)="goTouhomepage()">
            <img src="assets/uimages/logo.png" />
          </figure>
        </div>
        <div class="multiple_content">
          <div class="lm">
            <div class="language-select" (click)="showLang = showLang?false:true">
              <div class="sel-bar" *ngIf="languageService?.selectedLang">
                <figure><img *ngIf="languageService?.selectedLang?.logo" [src]="baseUrl+languageService?.selectedLang?.logo">{{languageService?.selectedLang?.code?.toUpperCase()}}
                </figure>
              </div>
              <p *ngIf="!languageService?.selectedLang">Select Language</p>
              <div class="ndpicon">
                <i *ngIf="!showLang" class="fa fa-caret-down" aria-hidden="true"></i>
                <i *ngIf="showLang" class="fa fa-caret-up" aria-hidden="true"></i>
              </div>
            </div>
            <div class="dp-lang" *ngIf="showLang">
              <figure *ngFor="let language of languageService?.allLanguages" (click)="selectLang(language)">
                <img *ngIf="language?.logo" [src]="baseUrl+language?.logo">{{language?.code?.toUpperCase()}}
              </figure>
            </div>
          </div>
          <div class="back_home hmbtn">
              <button class="driverap_btn" style="cursor: pointer;" (click)="goTouhomepage()">
                  {{'Back To Home' | translate}}
              </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="login_sec">
    <div class="conta_iner">
      <div class="login_outer_cls">
        <div class="otp_img">
          <figure>
            <img src="assets/uimages/rider_otp.svg" alt="" />
          </figure>
        </div>
        <div class="login_content_sec">
          <h3 class="verify_number">{{'Change your password' | translate}}!</h3>
          <form [formGroup]="changePassForm">
            <ul class="">
              <li>
                <label> {{'New Password' | translate}}</label>
                <div class="form-group">
                  <mat-form-field [floatLabel]="'never'">
                    <input matInput formControlName="newPassword" class="form-control" type="password"
                      placeholder="{{'New Password' | translate}}" name="name" />
                    <mat-error
                      *ngIf="changePassSubmitted && changePassForm.controls['newPassword'].hasError('required')">
                      {{'New password is required' | translate}}.</mat-error>
                    <mat-error
                      *ngIf="changePassSubmitted && changePassForm.controls['newPassword'].hasError('minlength')">
                      {{'New password length must be 6 characters' | translate}}.</mat-error>
                  </mat-form-field>
                </div>
              </li>
              <li>
                <label>{{'Confirm new password' | translate}}</label>
                <div class="form-group">
                  <mat-form-field [floatLabel]="'never'">
                    <input matInput formControlName="confirmPassword" class="form-control" type="password"
                      placeholder="{{'Confirm new password' | translate}}" name="name" />
                    <mat-error
                      *ngIf="changePassSubmitted && changePassForm.controls['confirmPassword'].hasError('required')">
                      {{'Confirm new password is required' | translate}}.</mat-error>
                    <mat-error
                      *ngIf="changePassSubmitted && changePassForm.controls['confirmPassword'].hasError('mustMatch')">
                      {{'Password and confirm password must match' | translate}}.</mat-error>
                  </mat-form-field>
                </div>
              </li>
            </ul>
            <div class="commen_btn_outer">
              <button class="btn btn-primary" (click)="changePassword()">{{'Save' | translate}}</button>
            </div>
          </form>
          <!-- <div class="commen_btn_outer">
                   <button class="btn btn-primary" (click)="verifyOtp()">
                      Verify
                   </button>
                </div> -->
          <p class="resend_opt">
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
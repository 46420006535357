import { Injectable, Injector } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject, throwError, of } from "rxjs";
// import { request } from 'http';
import { catchError, filter, take, switchMap, finalize, retry, tap } from "rxjs/operators";
import { CommonService } from "../common/common.service";
import { Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { MatDialog } from "@angular/material/dialog";
import { NotificationModalComponent } from "../../common-modals/notification-modal/notification-modal.component";
import { NotificationService } from "src/app/services/notification/notification.service";
import { LanguageService } from "src/app/services/language/language.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class HttpInterceptorService implements HttpInterceptor {
  private AUTH_HEADER = "Authorization";
  private token: string;
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  messages = {
    YOU_HAVE_BEEN_LOGGEDOUT_FOR_SECURITY_PURPOSE: {
      en: "You have been logged out for security purpose",
      es: "Se ha cerrado la sesión por motivos de seguridad",
    },
    API_NOT_WORKING: {
      en: "Api not working",
      es: "La API no funciona",
    },
  };
  lang = localStorage.languageCode != undefined ? localStorage.languageCode : "en";

  constructor(
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private matDialog: MatDialog,
    private notification: NotificationService
  ) {
    this.ngxService.start();
    if (localStorage.accessToken != undefined || localStorage.accessToken != null) {
      this.token = localStorage.accessToken;
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      // retry(2),
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401) {
          // 401 handled in auth.interceptor
          this.OpenNotificationModal("error", this.messages.YOU_HAVE_BEEN_LOGGEDOUT_FOR_SECURITY_PURPOSE[this.lang]);
          this.localStorageCLear();
        }
        return throwError(error);
      }),
      finalize(() => {
        this.ngxService.stop();
      }),
      tap(
        (event) => {
          var eventRes = JSON.parse(JSON.stringify(event));
          if (eventRes.body) {
            if (eventRes.body.statusCode === 400) {
              if (eventRes.body.error) {
                this.OpenNotificationModal("error", eventRes.body.message);
              } else if (eventRes.body.message) {
                this.OpenNotificationModal("error", eventRes.body.message);
              }
            }
          }
        },
        (error) => {
          switch (error.status) {
            case 401:
              /***  Auto LogOut if Api response 401 ** */
              this.OpenNotificationModal("warning", this.messages.YOU_HAVE_BEEN_LOGGEDOUT_FOR_SECURITY_PURPOSE[this.lang]);
              this.router.navigate([`/taxi/login`]);
              this.localStorageCLear();
              break;
            case 500:
              /*** If api does not respond  ** */
              this.OpenNotificationModal("warning", this.messages.API_NOT_WORKING[this.lang]);
              break;

            default:
              if (error.error) {
                this.OpenNotificationModal("error", error.error.message);
              } else {
                this.OpenNotificationModal("error", error.message);
              }
          }
        }
      )
    );
  }

  OpenNotificationModal(type, text) {
    this.notification.errorType = type;
    this.notification.message = text;
    this.notification.message && (this.notification.modal = "block");

    // this.matDialog.open(NotificationModalComponent,{ data: { msg: text, type: type } });
  }

  localStorageCLear() {
    if (localStorage.languageCode !== undefined) {
      var languageCode = localStorage.languageCode;
      localStorage.clear();
      localStorage.languageCode = languageCode;
    } else {
      localStorage.clear();
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { AdvertiseComponent } from 'src/app/modules/functional/common-modals/advertise/advertise.component';
import { environment } from 'src/environments/environment';
interface modalData {
  style: string;
  data: string;
}

@Injectable({
  providedIn: 'root'
})
export class ParentService {
  isPreLaunch: Subject<boolean> = new Subject<boolean>();
  isFunctional: Subject<boolean> = new Subject<boolean>();
  isTokenUsed: Subject<boolean> = new Subject<boolean>();
  isAdvertise: Subject<boolean> = new Subject<boolean>();
  getPrelaunchStatus = this.isPreLaunch.asObservable();
  getFunctionalStatus = this.isFunctional.asObservable();
  getTokenStatus = this.isTokenUsed.asObservable();
  getAdvertise = this.isAdvertise.asObservable();
  isThisWhySectionScroll: boolean = false;
  advertisementData: any;
  latitude: number;
  longitude: number;
  allLanguages: any = [];
  languageFiles = ['en','es'];
  openNotificationModal = 'none';
  openNotificationModalData: string = '';
  // data: string = '';
  // modalData =  {
  //   style: 'none',
  //   data: ''
  // }
  // openNotificationModal$: BehaviorSubject<modalData> = new BehaviorSubject<modalData>({style: 'none',data: ''});
  // openNotificationModal = this.openNotificationModal$.asObservable();

  constructor(private http: HttpClient, 
    public matDialog: MatDialog,) { }

  getGeofenceStatus() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          var latitude = position.coords.latitude;
          var longitude = position.coords.longitude;
          console.log('location', latitude, longitude);
          if (latitude !== 0 && longitude !== 0) {
            this.http.post(environment.baseUrl + 'user/checkPrelaunchLocation', { latitude: latitude, longitude: longitude }).subscribe((res: any) => {
              if (res.statusCode == 200) {
                this.isPreLaunch = res.data.geofenceData.isPreLaunch;
                return resolve(res.data.geofenceData.isPreLaunch);
              }
            });
          }
        });
      }
    });
  }

  onAdvertise() {
    // this.OpenAdvertiseModal();
    this.isAdvertise.next(true);
  }

  OpenAdvertiseModal() {
    this.matDialog.open(AdvertiseComponent);
  }

}

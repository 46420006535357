import { NgModule } from "@angular/core";
import { Routes, RouterModule, ROUTES, Router } from "@angular/router";
import { LoginComponent } from "./modules/prelaunch/login/login.component";
import { SignupComponent } from "./modules/prelaunch/signup/signup.component";
import { VerificationComponent } from "./modules/prelaunch/verification/verification.component";
import { VechicleComponent } from "./modules/prelaunch/vechicle/vechicle.component";
import { WelcomeComponent } from "./modules/prelaunch/welcome/welcome.component";
import { RiderequestComponent } from "./modules/prelaunch/riderequest/riderequest.component";
import { ProfilesetupComponent } from "./modules/prelaunch/profilesetup/profilesetup.component";
import { ForgetpasswordComponent } from "./modules/prelaunch/forgetpassword/forgetpassword.component";
import { UhomepageComponent } from "./modules/prelaunch/uhomepage/uhomepage.component";
import { UdriverComponent } from "./modules/prelaunch/udriver/udriver.component";
import { UriderComponent } from "./modules/prelaunch/urider/urider.component";
import { UdeliverypartnersComponent } from "./modules/prelaunch/udeliverypartners/udeliverypartners.component";
import { UbusinesspartnersComponent } from "./modules/prelaunch/ubusinesspartners/ubusinesspartners.component";
import { RiderloginComponent } from "./modules/prelaunch/riderlogin/riderlogin.component";
import { RidersignupComponent } from "./modules/prelaunch/ridersignup/ridersignup.component";
import { RequestacceptComponent } from "./modules/prelaunch/requestaccept/requestaccept.component";
import { UnavbarComponent } from "./modules/prelaunch/unavbar/unavbar.component";
import { RiderdetailComponent } from "./modules/prelaunch/riderdetail/riderdetail.component";
import { RidercongratulationsComponent } from "./modules/prelaunch/ridercongratulations/ridercongratulations.component";
import { RiderotppageComponent } from "./modules/prelaunch/riderotppage/riderotppage.component";
import { RiderdeliverybookingComponent } from "./modules/prelaunch/riderdeliverybooking/riderdeliverybooking.component";
import { RideremailComponent } from "./modules/prelaunch/rideremail/rideremail.component";
import { VerifyemailComponent } from "./modules/prelaunch/verifyemail/verifyemail.component";
import { AuthFGuard } from "./modules/functional/guards/auth.guard";
import { AuthGuard } from "./modules/prelaunch/guards/auth.guard";
import { TrackingComponent } from "./modules/prelaunch/tracking/tracking.component";
import { PreRegisterComponent } from "./modules/prelaunch/pre-register/pre-register.component";
import { CustomerServiceComponent } from "./modules/prelaunch/customer-service/customer-service.component";
import { PolicyComponent } from "./modules/prelaunch/policy/policy.component";
import { TermsOfServiceComponent } from "./modules/prelaunch/terms-of-service/terms-of-service.component";
// import { FunctionalComponent } from "./modules/functional/functional.component";
// import { FunctionalFComponent } from './modules/functional/functional.component';
import { UhomepageFComponent } from "./modules/functional/uhomepage/uhomepage.component";
import { UnavbarFComponent } from "./modules/functional/unavbar/unavbar.component";
import { LoginFComponent } from "./modules/functional/login/login.component";
import { SignupFComponent } from "./modules/functional/signup/signup.component";
import { VerificationFComponent } from "./modules/functional/verification/verification.component";
import { ProfileFComponent } from "./modules/functional/profile/profile.component";
import { VechicleFComponent } from "./modules/functional/vechicle/vechicle.component";
import { WelcomeFComponent } from "./modules/functional/welcome/welcome.component";
import { RiderequestFComponent } from "./modules/functional/riderequest/riderequest.component";
import { TrackingFComponent } from "./modules/functional/tracking/tracking.component";
import { ProfilesetupFComponent } from "./modules/functional/profilesetup/profilesetup.component";
import { ForgetpasswordFComponent } from "./modules/functional/forgetpassword/forgetpassword.component";
import { UdriverFComponent } from "./modules/functional/udriver/udriver.component";
import { UriderFComponent } from "./modules/functional/urider/urider.component";
import { UdeliverypartnersFComponent } from "./modules/functional/udeliverypartners/udeliverypartners.component";
import { UbusinesspartnersFComponent } from "./modules/functional/ubusinesspartners/ubusinesspartners.component";
import { RiderloginFComponent } from "./modules/functional/riderlogin/riderlogin.component";
import { RidersignupFComponent } from "./modules/functional/ridersignup/ridersignup.component";
import { RequestacceptFComponent } from "./modules/functional/requestaccept/requestaccept.component";
import { RiderdetailFComponent } from "./modules/functional/riderdetail/riderdetail.component";
import { RidercongratulationsFComponent } from "./modules/functional/ridercongratulations/ridercongratulations.component";
import { RiderotppageFComponent } from "./modules/functional/riderotppage/riderotppage.component";
import { RiderdeliverybookingFComponent } from "./modules/functional/riderdeliverybooking/riderdeliverybooking.component";
import { RideremailFComponent } from "./modules/functional/rideremail/rideremail.component";
import { VerifyemailFComponent } from "./modules/functional/verifyemail/verifyemail.component";
import { AppComponent } from "./app.component";
import { ParentService } from "./services/parent/parent.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { PaymentComponent } from "./modules/functional/payment/payment.component";
import { LanguageService } from "./services/language/language.service";
import { AllJSONFile } from "./core/all-json-file";
import { element } from "protractor";

let guards: AuthGuard;
let service: ParentService;

const routes: Routes = [
  // {
  // path: '',
  // component: FunctionalFComponent,
  // redirectTo: '/homepage',
  // children: [
  {
    path: "uhomepage",
    component: UhomepageFComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: "unavbar",
    component: UnavbarFComponent,
  },
  {
    path: "login",
    component: LoginFComponent,
  },
  {
    path: "signup",
    component: SignupFComponent,
  },
  {
    path: "verification",
    component: VerificationFComponent,
  },

  {
    path: "profile",
    component: ProfileFComponent,
  },
  {
    path: "vechicle",
    component: VechicleFComponent,
  },
  {
    path: "welcome",
    component: WelcomeFComponent,
  },
  {
    path: "riderequest",
    component: RiderequestFComponent,
  },
  {
    path: "tracking/:bookingId",
    component: TrackingFComponent,
  },
  {
    path: "profilesetup",
    component: ProfilesetupFComponent,
  },
  {
    path: "profilesetup/:nextPopup",
    component: ProfilesetupFComponent,
  },
  {
    path: "forgetpassword",
    component: ForgetpasswordFComponent,
  },

  {
    path: "udriver",
    component: UdriverFComponent,
  },
  {
    path: "urider",
    component: UriderFComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: "udeliverypartners",
    component: UdeliverypartnersFComponent,
  },
  {
    path: "ubusinesspartners",
    component: UbusinesspartnersFComponent,
  },
  {
    path: "delivery-partner-login",
    component: RiderloginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "riderlogin",
    component: RiderloginFComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: "ridersignup",
    component: RidersignupFComponent,
  },
  {
    path: "delivery-partner-signup",
    component: RidersignupFComponent,
  },
  {
    path: "requestaccept",
    component: RequestacceptFComponent,
  },
  {
    path: "riderdetail",
    component: RiderdetailFComponent,
  },
  {
    path: "ridercongratulations",
    component: RidercongratulationsFComponent,
  },
  {
    path: "riderotppage",
    component: RiderotppageFComponent,
  },
  {
    path: "riderdeliverybooking",
    component: RiderdeliverybookingFComponent,
  },
  {
    path: "rideremail",
    component: RideremailFComponent,
  },
  {
    path: "customer-services",
    component: CustomerServiceComponent,
  },
  {
    path: "cms/:type",
    component: PolicyComponent,
  },
  {
    path: "verifyemail",
    component: VerifyemailFComponent,
  },
  {
    path: "payment",
    component: PaymentComponent,
  },
  {
    path: "**",
    redirectTo: "uhomepage",
    pathMatch: "full",
  },
  // ]
  // }
];

const PRELAUNCH_ROUTES: Routes = [
  {
    path: "uhomepage",
    component: UhomepageComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: "unavbar",
    component: UnavbarComponent,
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "signup",
    component: SignupComponent,
  },
  {
    path: "verification",
    component: VerificationComponent,
  },
  {
    path: "pre-register",
    component: PreRegisterComponent,
  },
  {
    path: "vechicle",
    component: VechicleComponent,
  },
  {
    path: "welcome",
    component: WelcomeComponent,
  },
  {
    path: "riderequest",
    component: RiderequestComponent,
  },
  {
    path: "tracking/:bookingId",
    component: TrackingFComponent,
  },
  {
    path: "profilesetup",
    component: ProfilesetupComponent,
  },
  {
    path: "profilesetup/:nextPopup",
    component: ProfilesetupFComponent,
  },
  {
    path: "forgetpassword",
    component: ForgetpasswordComponent,
  },

  {
    path: "udriver",
    component: UdriverComponent,
  },
  {
    path: "urider",
    component: UriderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "udeliverypartners",
    component: UdeliverypartnersComponent,
  },
  {
    path: "ubusinesspartners",
    component: UbusinesspartnersComponent,
  },
  {
    path: "riderlogin",
    component: RiderloginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "delivery-partner-login",
    component: RiderloginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "ridersignup",
    component: RidersignupComponent,
  },
  {
    path: "delivery-partner-signup",
    component: RidersignupComponent,
  },
  {
    path: "requestaccept",
    component: RequestacceptComponent,
  },
  {
    path: "riderdetail",
    component: RiderdetailComponent,
  },
  {
    path: "ridercongratulations",
    component: RidercongratulationsComponent,
  },
  {
    path: "riderotppage",
    component: RiderotppageComponent,
  },
  {
    path: "riderdeliverybooking",
    component: RiderdeliverybookingComponent,
  },
  {
    path: "rideremail",
    component: RideremailComponent,
  },
  {
    path: "verifyemail",
    component: VerifyemailComponent,
  },
  {
    path: "customer-services",
    component: CustomerServiceComponent,
  },
  {
    path: "cms/:type",
    component: PolicyComponent,
  },
  {
    path: "terms-of-service",
    component: TermsOfServiceComponent,
  },
  {
    path: "payment",
    component: PaymentComponent,
  },
  {
    path: "**",
    redirectTo: "uhomepage",
    pathMatch: "full",
  },
];
@NgModule({
  imports: [RouterModule.forRoot(PRELAUNCH_ROUTES)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  newRoute: any = [];
  permissionStatus;
  constructor(
    private http: HttpClient,
    private parentService: ParentService,
    private router: Router,
    private languageService: LanguageService,
    private jsonFiles: AllJSONFile
  ) {
    this.getCurrentLocation(this);
    this.changeRoute();
  }

  getCurrentLocation(self) {
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({ name: "geolocation" }).then(function (permissionStatus) {
        console.log("geolocation permission status is ", permissionStatus.state);
        self.permissionStatus = permissionStatus.state;
        if (permissionStatus.state === "granted") {
          // self.changeRoute();
        } else if (permissionStatus.state === "denied" || permissionStatus.state === "prompt") {
          alert("Please allow location");
          self.languageService.allLanguages = [{ type: "default", code: "en" }];
          self.languageService.selectedLang = { type: "default", code: "en" };
          self.languageService.translateLanguage("en");
          localStorage.languageCode = "en";
          self.router.resetConfig(PRELAUNCH_ROUTES);
          self.router.navigateByUrl(self.router.url);
        }
        permissionStatus.addEventListener("change", function () {
          console.log(this.state);
          if (this.state === "granted") {
            self.changeRoute();
          } else if (this.state === "denied" || this.state === "prompt") {
            alert("Please allow location");
            self.languageService.allLanguages = [{ type: "default", code: "en" }];
            self.languageService.selectedLang = {
              type: "default",
              code: "en",
            };
            self.languageService.translateLanguage("en");
            localStorage.languageCode = "en";
            self.router.resetConfig(PRELAUNCH_ROUTES);
            self.router.navigateByUrl(self.router.url);
          }
        });
      });
    }
  }

  changeRoute() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        var latitude = position.coords.latitude;
        var longitude = position.coords.longitude;
        console.log("location", latitude, longitude);
        if (latitude !== 0 && longitude !== 0) {
          this.http
            .post(environment.baseUrl + "user/checkPrelaunchLocation", {
              latitude: latitude,
              longitude: longitude,
            })
            .subscribe((res: any) => {
              if (res.statusCode == 200) {
                if (res.data.geofenceData.isPreLaunch === false && res.data.geofenceData.isPreLaunch !== undefined) {
                  this.router.resetConfig(routes);
                  this.router.navigateByUrl(this.router.url);
                  this.parentService.isFunctional.next(res.data.geofenceData.isPreLaunch);
                } else {
                  this.router.resetConfig(PRELAUNCH_ROUTES);
                  this.router.navigateByUrl(this.router.url);
                  this.parentService.isPreLaunch.next(res.data.geofenceData.isPreLaunch);
                }
                if (res.data.geofenceData.languageId !== undefined) {
                  if (res.data.geofenceData.languageId.length > 0) {
                    this.languageService.allLanguages = [];
                    res.data.geofenceData.languageId.forEach((element) => {
                      if (this.jsonFiles.jsonFileArr.includes(element.code)) {
                        this.languageService.allLanguages.push(element);
                      }
                    });
                    setTimeout(() => {
                      if (this.languageService.allLanguages.length === 0) {
                        this.languageService.allLanguages = [{ type: "default", code: "en" }];
                        this.languageService.selectedLang = {
                          type: "default",
                          code: "en",
                        };
                        localStorage.languageCode = "en";
                      }
                    }, 500);

                    if (this.languageService.allLanguages.length > 0) {
                      if (localStorage.languageCode !== undefined) {
                        this.languageService.allLanguages.forEach((element: any) => {
                          if (element.code === localStorage.languageCode) {
                            this.languageService.selectedLang = element;
                            this.languageService.translateLanguage(element.code);
                          }
                        });
                      } else {
                        this.languageService.selectedLang = this.languageService.allLanguages[0];
                        this.languageService.translateLanguage(this.languageService.selectedLang.code);
                        localStorage.languageCode = this.languageService.selectedLang.code;
                      }
                    }
                  } else {
                    this.languageService.allLanguages = [{ type: "default", code: "en" }];
                    this.languageService.selectedLang = {
                      type: "default",
                      code: "en",
                    };
                    localStorage.languageCode = "en";
                  }

                  // this.languageService.allLanguages = res.data.geofenceData.languageId;
                  // (res.data.geofenceData.languageId.length > 0) && (this.languageService.selectedLang = res.data.geofenceData.languageId[0]);
                  // arr.forEach(element => {
                  //   if(this.parentService.languageFiles.includes(element)) {
                  //     this.parentService.allLanguages.push(element);
                  //   }
                  // });
                }
              }
            });
          this.parentService.latitude = position.coords.latitude;
          this.parentService.longitude = position.coords.longitude;
        }
      });
    } else {
      console.log("block");
    }
  }
}

import { Injectable, Component } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { HttpsService } from '../services/https/https.service';
import { MatDialog } from '@angular/material/dialog';
import { DownloadApplicationComponent } from '../common-modals/download-application/download-application.component';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  latitude: number = 0;
  longitude: number = 0;

  constructor(
    private httpService: HttpsService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.getCurrentLocation();
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    return true;
  }

  getCurrentLocation():any {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        console.log('location',this.latitude,this.longitude);
        if(this.latitude !== 0 && this.longitude !== 0) {
          this.httpService.httpPostUser('checkPrelaunchLocation',{latitude: this.latitude, longitude: this.longitude}).subscribe((res: any) => {
            if(res.statusCode == 200) {
              if(res.data.geofenceData.isPreLaunch) {
                // this.router.navigate(['/functional/homepage']);
                return true;
              }
            }
          })
        }
      });
    }
  }

}


import { Component, OnInit, NgZone, OnDestroy, Input, Output, EventEmitter, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { HttpsService } from '../../prelaunch/services/https/https.service';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { CommonService } from '../../prelaunch/services/common/common.service';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { environment } from 'src/environments/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SocketioService } from '../../prelaunch/services/sockets/socketio.service';
import { Event } from '../../prelaunch/core/models/event';
import { Action } from '../../prelaunch/core/models/action';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { BehaviorSubject, interval, Subject } from 'rxjs';
import { takeWhile, takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocationStrategy, ViewportScroller } from '@angular/common';
import { Constant } from '../../prelaunch/core/constant';
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "src/app/services/language/language.service";
declare var $: any;
declare var google: any;
interface Food {
  value: string;
  viewValue: string;
}
@Component({
  selector: "app-riderdeliverybooking",
  templateUrl: "./riderdeliverybooking.component.html",
  styleUrls: ["./riderdeliverybooking.component.scss"],
  encapsulation: ViewEncapsulation.Emulated
})
export class RiderdeliverybookingComponent implements OnInit, OnDestroy {
  selected = "label";
  lat: number = 51.678418;
  lng: number = 7.809007;
  public map: any = { lat: 51.678418, lng: 7.809007 };
  nextPopup = 0;
  user: any;
  bookingForm: FormGroup;
  pickup = {
    lat: 39.855460,
    lng: -101.474162
  }
  pickupAddress = "";
  dummy: any[] = [
    { amount: 2, isActive: false },
    { amount: 5, isActive: false },
    { amount: 8, isActive: false },
    { amount: 10, isActive: false },
  ]
  drop = {
    lat: 0,
    lng: 0
  }
  current = {
    lat: 0,
    lng: 0
  }
  // slides = [
  //   { img: "http://placehold.it/350x150/000000" },
  //   { img: "http://placehold.it/350x150/111111" },

  // ];

  slideConfig = { "slidesToShow": 1.1, "slidesToScroll": 1 };
  carouselConfig = {
    infinite: false, centerMode: true, variableWidth: true
  };
  dropAddress = "";
  zoom: number = 12;
  // origin: any;
  // destination: any;
  showMarker: boolean = true;
  public renderOptions = {
    suppressMarkers: true,
  }
  originMarkerIcon: string = 'assets/uimages/originmarker.png';
  destinationMarkerIcon: string = 'assets/uimages/destination.png'
  markerOptions: any;
  foods: Food[] = [
    { value: "Home", viewValue: "Home" },
    { value: "Office", viewValue: "Office" },
    { value: "Other", viewValue: "Other" },
  ];

  i;
  packageType: any = []
  requestForLaterForm: any;
  geoCoder: google.maps.Geocoder;
  distance: number;
  rideSubmited = false;
  origin;
  destination;
  isDateTimeSubmit: boolean;
  bookingFor: string = 'Now';
  mindate = new Date();
  dropZipCode: string;
  dropProvince: string;
  dropCity: string;
  deliveryDetailForm: FormGroup;

  selectedCountryCode: string;

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.Canada];
  signupForm: FormGroup
  submitted: boolean = false;
  isPhoneValid: boolean = true;
  detailSubmitted: boolean = false;
  packageSizeData: any = [];
  vehicleCategoryData: any = [];
  selectedVehicleData: any;
  baseUrl: string;
  selectedRideIndex = 0;
  selectedPackageSIze: any;
  selectedSizeIndex = 0;
  packageDetailForm: FormGroup;
  fileDataUrl: any;
  allFileUploadUrl: any = [];
  packageSubmitted: boolean = false;
  moreThenPackage: boolean = false;
  allPackagesDetail: any = [];
  activeBtn: string = 'Now';
  negativeValidator: boolean = false;
  isDropChanged: boolean = false;
  isPickupChanged: boolean = false;
  assignedDriverData: any;
  requestData: any;
  distanceInKM: any;
  duration: any;
  showDirections: boolean = false;
  timerStart: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  bookingId: any;
  bookingTimeInH: number;
  cancelCounter = 0;
  nearByDriversData: any = [];
  ubericon = "assets/images/ic_car.svg";
  isTollRoute: boolean = false;
  notifier = new Subject();
  isDriverFavourite: boolean = false;
  //ratings
  @Input('rating') private partnerRating: number = 3;
  @Input('rating') private deliveryRating: number = 3;
  @Input('rating') private customerRating: number = 3;
  @Input('starCount') private starCount: number = 5;
  @Input('color') private color: string = '#0171bd';
  @Output() private ratingUpdated = new EventEmitter();
  reviewData = [
    { name: 'Excellent', isActive: false },
    { name: 'Very Good', isActive: false },
    { name: 'Friendly', isActive: false },
    { name: 'Good', isActive: false },
    { name: 'Enjoy Service', isActive: false }
  ];
  selectedTip: number;

  private snackBarDuration: number = 2000;
  public ratingArr = [];
  isFeedBackSelected: boolean = false;
  feedBackQue = [
    { que: 'Was the driver on time?' },
    { que: 'Did the driver make your ride comfortabel?' },
    { que: 'Was the ride safe?' }
  ]
  selectedFeedbckQna: any = [];
  wasTheDriverOnTime: FormControl = new FormControl(null);
  driverMakeYouComfortabel: FormControl = new FormControl(null);
  wasTheRideSafe: FormControl = new FormControl(null);
  selectedDriverReview: number;
  customRate: FormControl = new FormControl(null);
  selectedTipAmount: any;
  comment: FormControl = new FormControl(null);
  rating: number = 0;
  ratingSubmitted: boolean = false;
  selectedReviewData: any = '';
  driverReviewData: any;
  bookingResponseData: any;
  isDriveingStart: boolean = false;
  showPickMarker: boolean = true;
  driverLatLng: { lat: any; lng: any; };
  driverIcon: { path: string; scale: number; strokeColor: string; strokeWeight: number; fillOpacity: number; fillColor: string; offset: string; rotation: number; flat: boolean; anchor: any; };
  isContactDetailHide: boolean = true;
  review: FormControl = new FormControl('');
  monitorForm: FormGroup;
  monitorSubmit: boolean = false;
  intervalSubscription: any;

  pickupFieldsCount: number = 1;
  dropupFieldsCount: number = 1;
  // dropupfieldsArr: any = [{ name: 'dropup1', lat: 0, lng: 0, address: '', distance: 0 }];
  dropupfieldsArr: any = [];
  pickupForm: FormGroup;
  dropupForm: FormGroup;
  pickupFormObj: {};
  dropupFormObj: {};
  waypoints = [];
  distancesForPath: any = [];
  calculated: boolean = true;
  calculateSubscription: any;
  directionStatus: any;
  showDropMarker: boolean = false;
  itemDropAddress: { lat: any; lng: any; address: any; distance: number };
  itemDropDetails: { deliveryTo: any; itemDropup: { lat: any; lng: any; address: any; distance: number }; streetAdd: any; suiteAptOffice: any; city: any; province: any; zipCode: any; additionalInfo: any; phoneNo: any; countryCode: any; };
  options: any;
  currentCountry: string = '';
  pickupfieldsArr: any = [];
  showLang: boolean = false;


  constructor(private router: Router,
    private http: HttpsService,
    private https: HttpClient,
    private fb: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public commonService: CommonService,
    private ngxService: NgxUiLoaderService,
    private socketService: SocketioService,
    private location: LocationStrategy,
    private snackBar: MatSnackBar,
    private constant: Constant,
    private vps: ViewportScroller,
    public translate: TranslateService,
    public languageService: LanguageService) {
    this.baseUrl = environment.imageUrl;
    this.options = {
      types: [],
      componentRestrictions: { country: 'CA' }
    }
  }

  ngOnInit(): void {////
    this.socketService.initSocket();
    this.preventBackButton();
    this.getCountry().then((stats: any) => {
      console.log('stats', stats)
      if (stats.status === "success") {
        this.createForm(stats.countryCode);
      } else {
        this.createForm('CA');
      }
    });

    this.markerOptions = {
      origin: {
        icon: this.originMarkerIcon,
      },
      destination: {
        icon: this.destinationMarkerIcon,
      },
    }
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
    this.requestForLaterForm = this.fb.group({
      dateTime: [null, Validators.required]
    });
    this.deliveryDetailForm = this.fb.group({
      deliveryTo: ['', Validators.required],
      itemDropup: ['', Validators.required],
      streetAdd: ['', Validators.required],
      suiteAptOffice: ['', Validators.required],
      city: ['', Validators.required],
      province: ['', Validators.required],
      zipCode: ['', Validators.required],
      phoneNo: ['', Validators.required],
      additionalInfo: [''],
    });
    this.packageDetailForm = this.fb.group({
      packageType: ['', Validators.required],
      itemDesc: ['', Validators.required],
      quantity: ['', Validators.required],
      file: ['', Validators.required],
      itemDropup: ['', Validators.required]
    });
    this.monitorForm = this.fb.group({
      name: ['', Validators.required],
      phoneNo: ['', Validators.required],
    })
    this.packageDetailForm.get('quantity').valueChanges.pipe(takeUntil(this.notifier)).subscribe((val: any) => {
      if (val <= 0) {
        this.negativeValidator = true;
      } else {
        this.negativeValidator = false;
      }
    })
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      // this.initializeMapPosition();
    });
    this.getUser();
    this.timerStart.pipe(takeUntil(this.notifier)).subscribe((val: boolean) => {
      if (val) {
        this.cancelCounter = 10;
        if (this.intervalSubscription) {
          clearInterval(this.intervalSubscription);
          this.cancelCounter = 10;
        }
        this.intervalSubscription = setInterval(() => {
          if (this.cancelCounter <= 0) {
            clearInterval(this.intervalSubscription);
          }
          this.cancelCounter--;
        }, 1000);
      } else {
        this.cancelCounter = 0;
      }
    });
    this.customRate.valueChanges.subscribe((value: any) => {
      this.selectedTip = 10000;
    });
    this.listenSocketEvent();
    this.review.valueChanges.subscribe((value: any) => {
      if (value) {
        this.selectedReviewData = value;
      } else {
        this.selectedReviewData = '';
      }
    });

  }

  selectLang(language) {
    this.languageService.selectedLang = language;
    this.languageService.translateLanguage(language.code);
    this.showLang = false;
  }

  getCountry() {
    return new Promise((resolve, reject) => {
      this.https.get('https://extreme-ip-lookup.com/json/').subscribe((location: any) => {
        console.log('location', location);
        this.currentCountry = location.countryCode;
        return resolve(location);
      });
    })
  }

  createForm(countryCode) {
    this.pickupfieldsArr = [{ name: 'pickup1', lat: 0, lng: 0, address: '', distance: 0, country: countryCode }];
    this.pickupFormObj = {};
    this.pickupfieldsArr.forEach(element => {
      let key = element.name;
      this.pickupFormObj[key] = new FormControl('', Validators.required);
    });
    this.pickupForm = this.fb.group(this.pickupFormObj);

    this.dropupFormObj = {};
    this.dropupfieldsArr.forEach(element => {
      let key = element.name;
      this.dropupFormObj[key] = new FormControl('', Validators.required);
    });
    this.dropupForm = this.fb.group(this.dropupFormObj);
    if (this.commonService.bookingResponseData) {
      if (this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED
        || this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.ARRIVED
        || this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.STARTED
        || this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.ONGOING
        || this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.OUT_FOR_DELIVERY
        || this.commonService.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.PACKAGES_PICKED
      ) {
        this.goToBookingStatus(this.commonService.bookingResponseData);
      }
      this.timerStart.next(false);
    }
    if (this.commonService.origin && this.commonService.destination) {
      if (this.commonService.origin.lat !== 0 && this.commonService.destination.lat !== 0) {
        this.pickup.lat = 0;
        this.pickup.lng = 0;
        this.pickup.lat = this.commonService.origin.lat;
        this.pickup.lng = this.commonService.origin.lng;
        this.getPickupAddress({lat: this.commonService.origin.lat, lng: this.commonService.origin.lng}, this.pickupfieldsArr[0],this.commonService.origin.address)
        // this.pickupForm.get('pickup1').patchValue(this.commonService.origin.address);
        // this.pickupfieldsArr[0].lat = this.commonService.origin.lat;
        // this.pickupfieldsArr[0].lng = this.commonService.origin.lng;
        // this.drawPath(this.commonService.origin, this.commonService.destination, this.originMarkerIcon);
      } else {
        this.getCurrentLocation();
      }
    } else {
      this.getCurrentLocation();
    }
  }

  goToHome() {
    this.commonService.origin = { lat: 0, lng: 0, address: '' };
    this.commonService.destination = { lat: 0, lng: 0, address: '' };
    this.router.navigate(["/uhomepage"]);
  }

  preventBackButton() {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }


  getUser() {
    this.http.httpGetWithHeaderUser('userProfile', '').pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      if (res.statusCode === 200) {
        this.user = res.data;
        if (res.data.role === "DELIVERY_PARTNER") {
          this.commonService.isThisDeliveryPartner = true;
        } else if (res.data.role === "RIDER") {
          this.commonService.isThisDeliveryPartner = false;
        }
      }
    });
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.pickupfieldsArr[0].lat = position.coords.latitude;
        this.pickupfieldsArr[0].lng = position.coords.longitude;
        this.pickup.lat = position.coords.latitude;
        this.pickup.lng = position.coords.longitude;
        this.current.lat = position.coords.latitude;
        this.current.lng = position.coords.longitude;
        this.zoom = 15;
        this.getPickupAddress({ lat: position.coords.latitude, lng: position.coords.longitude }, this.pickupfieldsArr[0], '');
        // this.getNearbyDrivers({ lat: position.coords.latitude, lng: position.coords.longitude });
      }, err => {
        console.log(err)
      });
    }
  }

  onMapReady(map: any) {
    this.map = map;
    if (map) {
      map.setOptions({
        streetViewControl: false
      });
    }
  }

  markerDragEndOrigin(event: any, data) {
    if (event.latLng) {
      data.lat = event.latLng.lat();
      data.lng = event.latLng.lng();
      this.getPickupAddress({ lat: data.lat, lng: data.lng }, data, '');
      // this.getNearbyDrivers(this.pickup);
    } else if (event.coords) {
      data.lat = event.coords.lat;
      data.lng = event.coords.lng;
      this.getPickupAddress({ lat: data.lat, lng: data.lng }, data, '');
      // this.getPickupAddress(this.pickup);
      // this.getNearbyDrivers(this.pickup);
    }

  }
  markerDragEndDestination(event: any, data) {
    if (event.latLng) {
      data.addressDetails.itemDropup.lat = event.latLng.lat();
      data.addressDetails.itemDropup.lng = event.latLng.lng();
      this.getAddressDrop({ lat: data.addressDetails?.itemDropup.lat, lng: data.itemDropup.lng }, data);
    } else if (event.coords) {
      data.addressDetails.itemDropup.lat = event.coords.lat;
      data.addressDetails.itemDropup.lng = event.coords.lng;
      // this.drop.lat = this.dropupfieldsArr[]
      this.getAddressDrop({ lat: data.addressDetails?.itemDropup.lat, lng: data.itemDropup.lng }, data);
    }
  }

  handlePickUpChnage(address, data) {
    console.log(address);
    this.isPickupChanged = true;
    if (address.formatted_address) {
      this.pickup.lat = 0;
      this.pickup.lng = 0;
      this.pickupAddress = (address.formatted_address);
      var lat = (address.geometry.location.lat());
      var lng = (address.geometry.location.lng());
      this.pickupfieldsArr.forEach(element => {
        if (element.name === data.name) {
          element.lat = lat;
          element.lng = lng;
        }
      });
      this.pickup.lat = this.pickupfieldsArr[0].lat;
      this.pickup.lng = this.pickupfieldsArr[0].lng;
      // this.getPickupAddress(this.pickup, data, address.formatted_address);
      // this.getNearbyDrivers(this.pickup);
      // this.bookingForm.get('ridePickUpAddress').patchValue(this.pickupAddress);
      data.address = (address.formatted_address);
      this.pickupForm.get(data.name).patchValue(this.pickupAddress);
    }
  }

  handleDropChnage(address, data) {
    console.log(address);
    this.drop.lat = 0;
    this.drop.lng = 0;
    if (address.formatted_address) {
      this.dropAddress = (address.formatted_address);
      var lat = (address.geometry.location.lat());
      var lng = (address.geometry.location.lng());
      this.dropupfieldsArr.forEach(element => {
        if (element.name === data.name) {
          element.lat = lat;
          element.lng = lng;
        }
      });
      var droplength = this.dropupfieldsArr.length;
      this.drop.lat = this.dropupfieldsArr[droplength - 1].lat;
      this.drop.lng = this.dropupfieldsArr[droplength - 1].lng;
      this.showDropMarker = true;
      // this.getDirection();
      // this.getDistanceMatrix(this.dropAddress, {lat: lat, lng: lng});
      this.calculateDistanceMatrix({ lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, { lat: this.dropupfieldsArr[droplength - 1].lat, lng: this.dropupfieldsArr[droplength - 1].lng }, data.name);
      this.getAddressDrop(this.drop, data);
    }
    if (address.types) {
      if (address.types.includes("airport")) {
        this.isTollRoute = true;
      } else {
        this.isTollRoute = false;
      }
    }
  }

  getDirection() {
    if ((this.pickup.lng !== null) && (this.pickup.lng !== null) && (this.drop.lat !== null) && (this.drop.lng !== null)) {
      this.origin = { lat: this.pickup.lat, lng: this.pickup.lng };
      this.destination = { lat: this.drop.lat, lng: this.drop.lng };
      this.showDirections = true;
      this.showPickMarker = false;
      this.calculateDistance();
    }
    this.showMarker = false;
  }

  calculateDistance() {
    const start = new google.maps.LatLng(this.pickupfieldsArr[0].lat, this.pickupfieldsArr[0].lng);
    const end = new google.maps.LatLng(this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng);
    this.distance = google.maps.geometry.spherical.computeDistanceBetween(start, end); // distance in Meter
    return this.distance;
  }

  getFare(pricePerMiles: any) {
    var d = this.distance / 1.609; //convert KM into MILES
    var fare = d * pricePerMiles;
    return Math.round(fare);
  }

  getPickupAddress(latLng, data, formatted_address) {
    this.geoCoder.geocode({ 'location': { lat: latLng.lat, lng: latLng.lng } }, (results, status) => {
      if (status === 'OK' && results.length > 0) {
        console.log(results);
        var isAddSimilar = false;
        results.forEach(addresses => {
          if (addresses.formatted_address === formatted_address && !isAddSimilar) {
            isAddSimilar = true;
            console.log('exist');
            this.pickupForm.get('pickup1').patchValue(addresses.formatted_address);
            return false;
          }
        });
        if (!isAddSimilar) {
          console.log('not-exist');
          this.pickupForm.get('pickup1').patchValue(results[0].formatted_address);
        }
        data.lat = latLng.lat;
        data.lng = latLng.lng;
        console.log(this.pickupfieldsArr,'pickupfieldsArr');
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }

    });
    // this.checkstatusMap();
  }

  dropChange() {
    // this.isDropChanged = false;
  }

  onKeyUp(pickup, value) {
    if (value === "") {
      pickup.lat = 0;
      pickup.lng = 0;
      pickup.address = '';
      pickup.distance = 0;
      this.pickupForm.controls[pickup.name].patchValue(null);
    }
  }

  pickupChange(pickup) {
    if (pickup.lat === 0 && pickup.lng === 0) {
      this.pickupForm.controls[pickup.name].patchValue(null);
      this.submitted = true;
    }
  }

  getAddressDrop(latLng, data) {
    this.geoCoder.geocode({ 'location': { lat: latLng.lat, lng: latLng.lng } }, (results, status) => {
      console.log(results);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.isDropChanged = true;
          this.dropAddress = results[0].formatted_address;
          // var addLength = results[0].address_components.length;
          // this.dropZipCode = results[0].address_components[addLength - 1].long_name;
          // this.dropProvince = results[0].address_components[addLength - 3].long_name;
          // this.dropCity = results[0].address_components[addLength - 4].long_name;
          // this.deliveryDetailForm.controls['city'].patchValue(this.dropCity);
          // this.deliveryDetailForm.controls['province'].patchValue(this.dropProvince);
          // this.deliveryDetailForm.controls['zipCode'].patchValue(this.dropZipCode);
          data.itemDroupup.address = this.dropAddress;
          this.packageDetailForm.controls['itemDropup'].patchValue(this.dropAddress);
        } else {
          // window.alert('No results found');
        }
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  getNearbyDrivers(position) {
    var param = {
      "latitude": position.lat,
      "longitude": position.lng,
      "pageNo": 1
    }
    this.http.httpPostWithHeaderUser('getNearbyDriversWithGeoFenceId', param).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      if (res.statusCode === 200) {
        this.nearByDriversData = res.data.drivers;
      }
    });
  }

  startNow(index) {
    this.rideSubmited = true;
    this.activeBtn = 'Now';
    this.checkFormFields();
    if (this.pickupForm.valid) {
      this.commonService.preLaunchAlert(this.constant.MSGS.PRELAUNCH_DELIVERY);
      // this.continue(index);
      // this.goToNext(11);
      // this.rideSubmited = false;
      // var date = new Date();
      // var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
      // var offset = date.getTimezoneOffset() / 60;
      // this.bookingTimeInH = date.getHours();
    }
  }

  openDateTimeModal() {
    this.rideSubmited = true;
    this.activeBtn = 'Later';
    this.checkFormFields();
    if (this.pickupForm.valid) {
      this.commonService.preLaunchAlert(this.constant.MSGS.PRELAUNCH_DELIVERY);
      // $("#exampleModall").modal('show');
      // this.rideSubmited = false; var date = new Date();
      // var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
      // var offset = date.getTimezoneOffset() / 60;
      // this.bookingTimeInH = date.getHours();
    }
  }

  getVehicleForLater() {
    this.isDateTimeSubmit = true
    if (this.requestForLaterForm.valid) {
      $('#exampleModall').modal('hide');
      this.bookingFor = 'Later';
      this.continue('10');
      // this.requestForLaterForm.reset();
      // this.isDateTimeSubmit = false;
      // this.bookingForm.reset();
      // this.rideSubmited = false;
    }
  }

  goToNext(index) {
    this.detailSubmitted = true;
    // if (this.deliveryDetailForm.valid) {
    this.packageType = [];
    this.http.httpPostWithHeaderUser('getPackageType', "").pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.packageType = res.data.packageTypeData
        this.continue(index);
      }
    })
    // }
  }

  goToPackageDetail(index) {
    this.detailSubmitted = true;
    if (this.deliveryDetailForm.valid) {
      var codeLength = this.deliveryDetailForm.value.phoneNo.dialCode.length;
      this.itemDropDetails = {
        deliveryTo: this.deliveryDetailForm.value.deliveryTo,
        itemDropup: this.itemDropAddress,
        streetAdd: this.deliveryDetailForm.value.streetAdd,
        suiteAptOffice: this.deliveryDetailForm.value.suiteAptOffice,
        city: this.deliveryDetailForm.value.city,
        province: this.deliveryDetailForm.value.province,
        zipCode: this.deliveryDetailForm.value.zipCode,
        additionalInfo: this.deliveryDetailForm.value.additionalInfo,
        phoneNo: this.deliveryDetailForm.value.phoneNo.e164Number.slice(codeLength),
        countryCode: this.deliveryDetailForm.value.phoneNo.dialCode,
      }
      console.log('itemDropDetails', this.itemDropDetails);
      this.continue(11);
    }
  }

  goToSelectPackageSize(index) {
    this.ngxService.start();
    console.log('testing', this.dropupfieldsArr);
    var type = this.packageDetailForm.get('packageType').value;
    var desc = this.packageDetailForm.get('itemDesc').value;
    var quantity = this.packageDetailForm.get('quantity').value;
    if (type != null || desc != null || quantity != null) {
      this.packageSubmitted = true;
      if (this.packageDetailForm.valid && this.packageDetailForm.value.quantity > 0) {
        this.addItem();
        this.http.httpPostWithHeaderUser('getPackageSize', "").pipe(takeUntil(this.notifier)).subscribe((res: any) => {
          if (res.statusCode === 200) {
            this.packageSizeData = res.data.packageSizeData;
            this.selectedPackageSIze = res.data.packageSizeData[0];
            this.ngxService.stop();
            this.getVehicle(index);
          }
        });
      } else {
        this.ngxService.stop();
      }
    } else {
      if (this.allPackagesDetail.length >= 1) {
        this.packageSubmitted = false;
        // if (this.packageDetailForm.valid) {
        this.http.httpPostWithHeaderUser('getPackageSize', "").pipe(takeUntil(this.notifier)).subscribe((res: any) => {
          if (res.statusCode === 200) {
            this.packageSizeData = res.data.packageSizeData;
            this.selectedPackageSIze = res.data.packageSizeData[0];
            this.ngxService.stop();
            this.getVehicle(index);
          }
        });
        // }
      }
    }

  }

  getVehicle(poupType) {
    console.log(this.deliveryDetailForm.controls);
    this.rideSubmited = true;
    if (this.pickupForm.valid) {
      this.calculateDistanceMatrix({ lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, { lat: this.allPackagesDetail[this.allPackagesDetail.length - 1].addressDetails.itemDropup.lat, lng: this.allPackagesDetail[this.allPackagesDetail.length - 1].addressDetails.itemDropup.lng }, null);
      // this.originPathOptimization();
      this.destinationPathOptimization();
      this.ngxService.start();
      var param = {
        "pageNo": 1,
        "latitude": this.pickupfieldsArr[0].lat,
        "longitude": this.pickupfieldsArr[0].lng
      }
      this.http.httpPostWithHeaderUser('getVehicleCategoryInGeofence', param).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.continue(poupType);
          var arr = res.data.vehicleCategoryData;
          this.vehicleCategoryData = [];
          arr.forEach(element => {
            var totalFare: any;
            var d = this.distance;
            var priceA = d * element.pricePerMile;
            var pricePerMinute = element.pricePerHour / 60;
            let totalMinutesForRide = this.duration;
            var priceB = pricePerMinute * totalMinutesForRide;
            var basicFare = priceA * priceB + element.baseFare;
            var tax = (basicFare * 12) / 100;
            var booKingDriverAmount;
            if (this.bookingTimeInH > 9 && this.bookingTimeInH < 7) {
              var specialCharge = (basicFare * 11) / 100;
              totalFare = basicFare + tax + specialCharge;
              booKingDriverAmount = (totalFare * 75) / 100;
            } else {
              totalFare = basicFare + tax;
              booKingDriverAmount = (totalFare * 75) / 100;
            }
            if (this.isTollRoute) {
              totalFare = totalFare + 4;
            }
            this.vehicleCategoryData.push($.extend({}, {
              airpotFess: element.airpotFess,
              booKingAmount: 10,
              booKingDriverAmount: booKingDriverAmount,
              baseFare: element.baseFare,
              createdAt: element.createdAt,
              geofenceId: element.geofenceId,
              id: element.id,
              isBlocked: element.isBlocked,
              isDeleted: element.isDeleted,
              isDelivery: element.isDelivery,
              isRide: element.isRide,
              lateNightCharge: element.lateNightCharge,
              packageSizeId: element.packageSizeId,
              pricePerHour: element.pricePerHour,
              pricePerMile: element.pricePerMile,
              rideCapacity: element.rideCapacity,
              updatedAt: element.updatedAt,
              vehicleCategory: element.vehicleCategory,
              vehicleImage: element.vehicleImage,
              weatherEffectFees: element.weatherEffectFees,
              zoneId: element.zoneId,
              __v: element.__v,
              _id: element._id,
            }))
          });
          this.selectedVehicleData = this.vehicleCategoryData[0];
          console.log(this.vehicleCategoryData);
          this.ngxService.stop();
          // this.pickupfieldsArr.sort((a, b) => {
          //   return a.distance - b.distance;
          // });
          this.allPackagesDetail.sort((a, b) => {
            return a.addressDetails.itemDropup.distance - b.addressDetails.itemDropup.distance;
          });
          this.drawPath(this.pickupfieldsArr[0], this.allPackagesDetail[this.allPackagesDetail.length - 1], this.originMarkerIcon);
        }
      });
    }
  }

  onFileChange(fileInput: any) {
    const fileData = <File>fileInput.target.files[0];
    this.http.userFileUpload(fileData).pipe(takeUntil(this.notifier)).subscribe(res => {
      this.fileDataUrl = JSON.parse(JSON.stringify(res)).data.orignal;
    });
  }

  addItem() {
    this.packageSubmitted = true;
    if (this.packageDetailForm.valid && this.packageDetailForm.value.quantity > 0) {
      this.dropupFieldsCount++;
      this.allPackagesDetail.push({
        packageType: this.packageDetailForm.value.packageType,
        desc: this.packageDetailForm.value.itemDesc,
        quantity: this.packageDetailForm.value.quantity,
        image: this.fileDataUrl,
        addressDetails: this.itemDropDetails
      });
      this.showDropMarker = true;
      console.log('ok tested', this.dropupfieldsArr);
      this.packageDetailForm.reset();
      this.deliveryDetailForm.reset();
      this.packageSubmitted = false;
      this.fileDataUrl = '';
    }

  }

  selectVehicle(vehicle, index) {
    this.selectedVehicleData = vehicle;
    this.selectedRideIndex = index;
  }

  selectPackageSize(packageSize, index) {
    this.selectedPackageSIze = packageSize;
    this.selectedSizeIndex = index;
  }

  editPackageDetail(pckg) {
    this.packageDetailForm.get('packageType').patchValue(pckg.packageType);
    this.packageDetailForm.get('itemDesc').patchValue(pckg.desc);
    this.packageDetailForm.get('quantity').patchValue(pckg.quantity);
    this.packageDetailForm.get('itemDropup').patchValue(pckg.itemDropup.address);
    this.packageDetailForm.get('file').clearValidators();
    this.packageDetailForm.get('file').updateValueAndValidity();
    this.fileDataUrl = pckg.image;
    this.allPackagesDetail.forEach(element => {
      if (element.packageType === pckg.packageType && element.desc === pckg.desc && element.quantity === pckg.quantity && element.image === pckg.image) {
        const index = this.allPackagesDetail.indexOf(pckg);
        if (index > -1) {
          this.allPackagesDetail.splice(index, 1);
        }
      }
    });
  }

  deletePackageDetail(pckg) {
    this.allPackagesDetail.forEach(element => {
      if (element.packageType === pckg.packageType && element.desc === pckg.desc && element.quantity === pckg.quantity && element.image === pckg.image) {
        const index = this.allPackagesDetail.indexOf(pckg);
        if (index > -1) {
          this.dropupFieldsCount--;
          this.allPackagesDetail.splice(index, 1);
        }
      }
    });
  }

  requestBooking(index) {
    this.preLaunchAlert();
  }

  preLaunchAlert() {
    Swal.fire({
      title: 'This is the Pre Launch website... we will get back to you soon!',
      // text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Okay'
    }).then((result) => {
      if (result.value) {
        this.continue(0);
      }
    })
  }


  listenSocketEvent() {
    this.socketService.onEvent(Event.sendNotificationToUser).pipe(takeUntil(this.notifier)).subscribe((response: any) => {
      console.log(response);
      this.goToBookingStatus(response.data);
    });
  }

  goToBookingStatus(response) {
    if (response.bookingType === this.constant.BOOKING_TYPE.DELIVERY) {
      // this.router.navigate(['/riderdeliverybooking']);
      if (response.bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED) {
        this.commonService.bookingResponseData = response;
        this.bookingId = response._id;
        this.isDriveingStart = true;
        // this.markerOptions = {
        //   origin: {
        //     icon: this.iconObject(0)
        //   },
        //   destination: {
        //     icon: this.destinationMarkerIcon
        //   },
        // }
        // this.origin = { lat: response.pickUplatitude, lng: response.pickUplongitude };
        // this.destination = { lat: response.dropUplatitude, lng: response.dropUplongitude };
        this.showDirections = true;
        this.bookingResponseData = response;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        this.bookingResponseData.subBookingData.forEach(element => {
          if (element.isPickUp) {
            this.pickupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          }
        });
        this.calculateDistanceMatrix({ lat: response.driverId.latitude, lng: response.driverId.longitude }, { lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, null);
        this.listenDriverLatLngEvent();
        this.continue(4);
        this.timerStart.next(true);
        // this.ngxService.start();
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.STARTED) {
        this.commonService.bookingResponseData = response;
        this.isDriveingStart = true;
        this.showDirections = true;
        this.bookingResponseData = response;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        this.bookingResponseData.subBookingData.forEach(element => {
          if (element.isPickUp) {
            this.pickupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          } else {
            this.dropupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          }
        });
        this.listenDriverLatLngEvent();
        this.calculateDistanceMatrix({ lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, { lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng }, null);
        this.continue(5);
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.ARRIVED) {
        this.commonService.bookingResponseData = response;
        this.isDriveingStart = true;
        // this.markerOptions = {
        //   origin: {
        //     icon: this.iconObject(0)
        //   },
        //   destination: {
        //     icon: this.destinationMarkerIcon
        //   },
        // }
        // this.origin = { lat: response.pickUplatitude, lng: response.pickUplongitude };
        // this.destination = { lat: response.dropUplatitude, lng: response.dropUplongitude };
        // this.showDirections = true;
        // this.pickup.lat = response.pickUplatitude;
        // this.pickup.lng = response.pickUplongitude;
        // this.listenDriverLatLngEvent();
        // this.showDirections = false;
        this.showDirections = true;
        this.bookingResponseData = response;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        this.bookingResponseData.subBookingData.forEach(element => {
          if (element.isPickUp) {
            this.pickupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          } else {
            this.dropupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          }
        });
        this.listenDriverLatLngEvent();
        this.calculateDistanceMatrix({ lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, { lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng }, null);
        this.continue(6);
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.PACKAGES_PICKED) {
        this.commonService.bookingResponseData = response;
        this.isDriveingStart = true;
        this.showDirections = true;
        this.bookingResponseData = response;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        this.bookingResponseData.subBookingData.forEach(element => {
          if (element.isPickUp) {
            this.pickupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          } else {
            this.dropupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          }
        });
        this.listenDriverLatLngEvent();
        this.calculateDistanceMatrix({ lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, { lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng }, null);
        this.continue(7);
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.OUT_FOR_DELIVERY) {
        this.commonService.bookingResponseData = response;
        this.isDriveingStart = true;
        this.showDirections = true;
        this.bookingResponseData = response;
        this.pickupfieldsArr = [];
        this.dropupfieldsArr = [];
        this.bookingResponseData.subBookingData.forEach(element => {
          if (element.isPickUp) {
            this.pickupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          } else {
            this.dropupfieldsArr.push({ name: null, lat: element.latitude, lng: element.longitude, address: null, distance: 0 });
          }
        });
        this.listenDriverLatLngEvent();
        this.calculateDistanceMatrix({ lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng }, { lat: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lat, lng: this.dropupfieldsArr[this.dropupfieldsArr.length - 1].lng }, null);

        this.allPackagesDetail = [];
        this.continue(8);
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.COMPLETED) {
        this.commonService.bookingResponseData = response;
        this.bookingId = response._id;
        this.isDriveingStart = true;
        this.showDirections = false;
        this.showPickMarker = false;
        this.bookingResponseData = response;
        this.allPackagesDetail = [];
        this.getDriverRatingsReview();
        this.ngxService.stop();
        this.continue(9);
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.CANCELED) {
        this.commonService.bookingResponseData = response;
        if (response.isCanceledByDriver) {
          this.driverNotFoundAlert('Your delivery has been cancelled by driver.');
        } else if (response.isCanceledByCron) {
          this.driverNotFoundAlert('Oops!.. No driver available nearby, please try again later.');
        }
      } else if (response.bookingStatus === this.constant.BOOKING_STATUS.PENDING) {
        this.driverNotFoundAlert('Oops!.. No driver available nearby, please try again later.');
      }
    } else {
      this.commonService.bookingResponseData = response;
      this.router.navigate(['/riderequest']);
    }
  }

  listenDriverLatLngEvent() {
    this.socketService.onEvent(Event.updateDriverLatLongToUser).pipe(takeUntil(this.notifier)).subscribe((response: any) => {
      console.log(response);
      if (response.status == 200) {
        this.isDriveingStart = true;
        this.showDirections = true;
        this.showPickMarker = false;
        // this.markerOptions = {
        //   origin: {
        //     icon: this.iconObject(response.bearing)
        //   },
        //   destination: {
        //     icon: this.destinationMarkerIcon
        //   },
        // }
        this.markerOptions.origin.icon = this.iconObject(response.bearing);
        // this.destination = { lat: this.pickup.lat, lng: this.pickup.lng };
        // this.origin = { lat: response.latitude, lng: response.longitude };
        // this.driverLatLng = { lat: response.latitude, lng: response.longitude };
        if (this.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.ACCEPTED) {
          var driverLatLng = { lat: response.latitude, lng: response.longitude };
          this.destination = { lat: this.pickupfieldsArr[0].lat, lng: this.pickupfieldsArr[0].lng };
          this.origin = { lat: response.latitude, lng: response.longitude };
          this.waypoints = [];
          if (this.calculated === false) {
            this.calculated = true;
            this.calculateDistanceMatrix(driverLatLng, this.destination, null);
          }
        }
        if (this.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.OUT_FOR_DELIVERY
          || this.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.ARRIVED
          || this.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.PACKAGES_PICKED) {
          this.destination = { lat: this.drop.lat, lng: this.drop.lng };
        } else if (this.bookingResponseData.bookingStatus === this.constant.BOOKING_STATUS.PACKAGES_PICKED) {
          this.showDirections = false;
          this.showPickMarker = false;
        }
      }
    });
  }

  iconObject(rotation: number) {
    return {
      path: "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z",
      scale: 0.7,
      strokeColor: "white",
      strokeWeight: 0.1,
      fillOpacity: 1,
      fillColor: "#404040",
      offset: "5%",
      rotation: rotation,
      flat: true,
      anchor: new google.maps.Point(10, 25)
    };
  }

  getDriverRatingsReview() {
    this.http.httpPostWithHeaderUser('getDriverRatingsReview', { driverId: this.bookingResponseData.driverId._id }).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.driverReviewData = res.data.driverData;
      }
    });
  }

  getDistanceMatrix(address, latLng) {
    var origin1 = this.pickup;
    var origin2 = this.pickupAddress;
    var destinationA = address;
    var destinationB = latLng;
    var service = new google.maps.DistanceMatrixService;
    service.getDistanceMatrix({
      origins: [origin1, origin2],
      destinations: [destinationA, destinationB],
      travelMode: 'DRIVING',
      unitSystem: google.maps.UnitSystem.METRIC,
      avoidHighways: false,
      avoidTolls: false
    }, (res: any) => {
      this.distanceInKM = res.rows[0].elements[0].distance.text;
      this.duration = res.rows[0].elements[0].duration.text;
      console.log(this.duration)
      this.ngxService.stop();
    });
  }

  driverNotFoundAlert(message) {
    Swal.fire({
      title: message,
      // text: "You won't be able to revert this!",
      icon: 'info',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        this.packageDetailForm.reset();
        this.deliveryDetailForm.reset();
        this.packageSubmitted = false;
        this.allPackagesDetail = [];
        this.detailSubmitted = false;
        this.rideSubmited = false;
        this.isDateTimeSubmit = false;
        this.bookingForm.reset();
        this.requestForLaterForm.reset();
        this.getCurrentLocation();
        this.continue(0);
        this.showDirections = false;
        this.showMarker = true;
        this.drop.lat = 0;
        this.drop.lng = 0;
      }
    })
  }

  cancelBooking() {
    if (this.bookingId) {
      this.http.httpPostWithHeaderUser('cancelBooking', { bookingId: this.bookingId }).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
        if (res.statusCode === 200) {
          this.commonService.bookingResponseData = '';
          this.bookingResponseData = '';
          this.continue(0);
        }
      });
    } else {
      this.continue(0);
    }

  }

  continue(popupType) {
    if (popupType === 0) {
      this.packageDetailForm.reset();
      this.deliveryDetailForm.reset();
      this.packageSubmitted = false;
      this.detailSubmitted = false;
      this.rideSubmited = false;
      this.isDateTimeSubmit = false;
      // this.bookingForm.reset();
      this.requestForLaterForm.reset();
      this.pickupfieldsArr = [{ name: 'pickup1', lat: 0, lng: 0, address: '', distance: 0, country: this.currentCountry }];
      this.dropupfieldsArr = [{ name: 'dropup1', lat: 0, lng: 0, address: '', distance: 0 }];
      this.getCurrentLocation();
      this.showDirections = false;
      this.showPickMarker = true;
      this.nextPopup = popupType;
      this.allPackagesDetail = [];
      this.drop.lat = 0;
      this.drop.lng = 0;
      $("#myModal23_user").modal('hide');
    }
    this.nextPopup = popupType;
  }
  goToprofilesetup() {
    this.commonService.isThisUpdatedUser = false;
    this.router.navigate(["/profilesetup"]);
  }
  goTorequestaccept() {
    this.router.navigate(["/requestaccept"]);
  }
  goToriderequest() {
    this.router.navigate(["/riderequest"]);
  }

  addFavouriteDriver() {
    this.http.httpPostWithHeaderUser('addFavouriteDriver', { driverId: this.bookingResponseData.driverId.id, isFav: true }).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.isDriverFavourite = true;
        this.commonService.OpenNotificationModal('success', 'top-end', res.message);
        this.getDriverRatingsReview();
      }
    });
  }

  removeFavouriteDriver() {
    this.http.httpPostWithHeaderUser('addFavouriteDriver', { driverId: this.bookingResponseData.driverId.id, isFav: false }).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.isDriverFavourite = false;
        this.commonService.OpenNotificationModal('success', 'top-end', res.message);
        this.getDriverRatingsReview();
      }
    });
  }

  //for rating
  onClick(rating: number) {
    // console.log(rating)
    // this.snackBar.open('You rated ' + rating + ' / ' + this.starCount, '', {
    //   duration: this.snackBarDuration
    // });
    this.ratingUpdated.emit(rating);
    this.rating = rating;
    if (this.rating > 0 && this.rating < 3) {
      this.review.setValidators(Validators.required);
      this.review.updateValueAndValidity();
    } else {
      this.review.clearValidators();
      this.review.updateValueAndValidity();
    }
    return false;
  }

  showIcon(index: number) {
    if (this.rating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }

  selectReview(index, data) {
    this.selectedDriverReview = index;
    this.selectedReviewData = data.isActive ? '' : data.name;
  }

  selectTip(i, amount) {
    this.selectedTip = i;
    this.selectedTipAmount = amount;
  }

  addRating(index) {
    this.ratingSubmitted = true;
    if (this.rating > 0 && this.selectedReviewData != '') {
      this.selectedFeedbckQna = [];
      this.selectedFeedbckQna.push({ question: 'Was the driver on time?', answere: this.wasTheDriverOnTime.value });
      this.selectedFeedbckQna.push({ question: 'Was the ride safe?', answere: this.wasTheRideSafe.value });
      this.selectedFeedbckQna.push({ question: 'Did the driver make your ride comfortabel?', answere: this.driverMakeYouComfortabel.value });
      this.selectedFeedbckQna.forEach(element => {
        if (element.answere === null) {
          const index = this.selectedFeedbckQna.indexOf(element);
          this.selectedFeedbckQna.splice(index, 1);
        }
      });
      this.selectedFeedbckQna.forEach(element => {
        if (element.answere === null) {
          const index = this.selectedFeedbckQna.indexOf(element);
          this.selectedFeedbckQna.splice(index, 1);
        }
      });
      var param = $.extend({}, {
        "bookingId": this.bookingId,
        "driverId": this.bookingResponseData.driverId.id,
        "driverRating": this.rating,
        "driverReview": this.rating < 3 ? this.review.value : this.selectedReviewData,
        "tip": this.customRate.value ? this.customRate.value : this.selectedTipAmount,
        "comment": this.comment.value ? this.comment.value : undefined,
        "feedBack": this.selectedFeedbckQna
      })
      this.http.httpPostWithHeaderUser('addRatings', param).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.commonService.OpenNotificationModal('success', 'top-end', res.message);
          this.continue(index);
        }
      });
    }
  }

  goToContactDetail() {
    this.isContactDetailHide = this.isContactDetailHide ? false : true;
    if (!this.isContactDetailHide) {
      this.vps.scrollToAnchor("driverInfo");
    }
  }

  monitorRide() {
    this.monitorSubmit = true;
    if (this.monitorForm.valid) {
      var codeLength = this.monitorForm.value.phoneNo.dialCode.length;
      var param = {
        "bookingId": this.bookingId,
        "moniterRide": {
          "name": this.monitorForm.value.name,
          "phoneNo": this.monitorForm.value.phoneNo.e164Number.slice(codeLength),
          "countryCode": this.monitorForm.value.phoneNo.dialCode
        }
      }
      this.http.httpPostWithHeaderUser('shareYourRide', param).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
        if (res.statusCode === 200) {
          this.monitorForm.reset();
          this.monitorSubmit = false;
          this.commonService.OpenNotificationModal('success', 'top-end', res.message);
          $("#add_moniter").modal('hide');
        }
      });
    }
  }

  openMonitorModal(bookingId) {
    this.bookingId = bookingId;
    $("#add_moniter").modal('show');
  }

  calculateDistanceMatrix(pickup, drop, formField) {
    let directionsService = new google.maps.DirectionsService();
    var request = {
      origin: pickup,
      destination: drop,
      optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.DRIVING,
    };
    directionsService.route(request, (response, status) => {
      this.directionStatus = status;
      if (status == google.maps.DirectionsStatus.OK) {
        this.distance = response?.routes[0]?.legs[0]?.distance.value / 1000; //convert m into km
        this.duration = response?.routes[0]?.legs[0]?.duration.value / 60; //convert seconds into minutes
      } else {
        this.commonService.OpenNotificationModal('error', 'top-end', 'No path found.');
        if (formField !== null) {
          this.dropupForm.get(formField).patchValue('');
        }
      }
      try {

      } catch (error) {
        console.log(error);
      }
    });
  }

  addPickupField() {
    this.pickupFieldsCount++;
    if (!('pickup' + this.pickupFieldsCount in this.pickupFormObj)) {
      this.addFieldPick();
    } else {
      this.pickupFieldsCount++;
      this.addFieldPick();
    }
  }

  addFieldPick() {
    if (!('pickup' + this.pickupFieldsCount in this.pickupFormObj)) {
      this.pickupFormObj['pickup' + this.pickupFieldsCount] = new FormControl('', Validators.required);
      this.pickupForm = this.fb.group(this.pickupFormObj);
      this.pickupfieldsArr.push({ name: 'pickup' + this.pickupFieldsCount, lat: 0, lng: 0, address: '', distance: 0 });
    } else {
      this.addPickupField();
    }
  }

  removePickupField(index) {
    // this.pickupFieldsCount--;
    // console.log(this.pickupFieldsCount);
    // var size = Object.keys(this.pickupFormObj).length;
    // console.log('size',size);

    this.pickupfieldsArr.splice(index, 1);
    Object.keys(this.pickupFormObj).forEach(element => {
      let strArray = this.pickupfieldsArr.map(element => element.name)
      if (!(strArray.includes(element))) {
        delete this.pickupFormObj[element];
      }
    });
    this.pickupForm = this.fb.group(this.pickupFormObj);
  }

  addDropupField() {
    this.dropupFieldsCount++;
    if (!('dropup' + this.dropupFieldsCount in this.dropupFormObj)) {
      this.addFieldDrop();
    } else {
      this.dropupFieldsCount++;
      this.addFieldDrop();
    }
  }

  addFieldDrop() {
    if (!('dropup' + this.dropupFieldsCount in this.dropupFormObj)) {
      this.dropupFormObj['dropup' + this.dropupFieldsCount] = new FormControl('', Validators.required);
      this.dropupForm = this.fb.group(this.dropupFormObj);
      this.dropupfieldsArr.push({ name: 'dropup' + this.dropupFieldsCount, lat: 0, lng: 0, address: '', distance: 0 });
    } else {
      this.addDropupField();
    }
  }

  removeDropupField(index) {
    // this.dropupFieldsCount--;
    // this.dropupfieldsArr.splice(index, 1);

    this.dropupfieldsArr.splice(index, 1);
    Object.keys(this.dropupFormObj).forEach(element => {
      let strArray = this.dropupfieldsArr.map(element => element.name)
      if (!(strArray.includes(element))) {
        delete this.dropupFormObj[element];
      }
    });
    this.dropupForm = this.fb.group(this.dropupFormObj);
  }

  drawPath(origin, destination, originIcon) {
    var wayPontsMarkerArr = [];
    this.waypoints = [];
    if (this.pickupfieldsArr.length > 1) {
      for (let i = 0; i < this.pickupfieldsArr.length; i++) {
        if (i !== 0) {
          this.waypoints.push({
            location: { lat: this.pickupfieldsArr[i].lat, lng: this.pickupfieldsArr[i].lng },
            stopover: false
          });
          wayPontsMarkerArr.push({ icon: 'http://i.imgur.com/7teZKif.png' });
        } else {
          wayPontsMarkerArr.push({ icon: 'http://i.imgur.com/7teZKif.png' });
        }
      }
      this.markerOptions = {
        origin: {
          icon: originIcon
        },
        destination: {
          icon: this.destinationMarkerIcon
        },
        waypoints: wayPontsMarkerArr
      }
    } else if (this.allPackagesDetail.length > 1) {
      for (let i = 0; i < this.allPackagesDetail.length; i++) {
        if (i !== this.allPackagesDetail.length - 1) {
          this.waypoints.push({
            location: { lat: this.allPackagesDetail[i].addressDetails?.itemDropup?.lat, lng: this.allPackagesDetail[i].addressDetails?.itemDropup?.lng },
            stopover: false
          });
          wayPontsMarkerArr.push({ icon: 'http://i.imgur.com/7teZKif.png' });
        } else {
          wayPontsMarkerArr.push({ icon: 'http://i.imgur.com/7teZKif.png' });
        }
      }
      this.markerOptions = {
        origin: {
          icon: originIcon
        },
        destination: {
          icon: this.destinationMarkerIcon
        },
        waypoints: wayPontsMarkerArr
      }
    }
    this.showDirections = true;
    this.showPickMarker = false;
    this.showDropMarker = false;
    this.origin = { lat: origin.lat, lng: origin.lng };
    this.destination = { lat: destination.lat, lng: destination.lng };
  }

  originPathOptimization() {
    this.pickupfieldsArr.forEach(element => {
      let destination = { lat: element.lat, lng: element.lng };
      this.calculateDistanceForPathOpt(this.current, destination, element);
    });
  }

  destinationPathOptimization() {
    this.allPackagesDetail.forEach(element => {
      let destination = { lat: element.addressDetails?.itemDropup.lat, lng: element.addressDetails?.itemDropup.lng };
      this.calculateDistanceForPathOpt(this.pickup, destination, element);
    });
  }

  calculateDistanceForPathOpt(pickup, drop, data) {
    let directionsService = new google.maps.DirectionsService();
    var request = {
      origin: pickup,
      destination: drop,
      optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.DRIVING,
    };
    directionsService.route(request, (response, status) => {
      if (status == google.maps.DirectionsStatus.OK) {
        data.addressDetails.itemDropup.distance = response?.routes[0]?.legs[0]?.distance.value;
      }
    });
  }

  handleItemDropChnage(address) {
    if (address.formatted_address) {
      var lat = (address.geometry.location.lat());
      var lng = (address.geometry.location.lng());
      // if(this.allPackagesDetail.length === this.dropupfieldsArr.length) {
      // this.dropupfieldsArr.push({ name: 'dropup' + this.allPackagesDetail.length, lat: lat, lng: lng, address: address.formatted_address, distance: 0 });
      // }
      this.itemDropAddress = { lat: lat, lng: lng, address: address.formatted_address, distance: 0 };
      this.packageDetailForm.get('itemDropup').patchValue(address.formatted_address);
      this.deliveryDetailForm.get('itemDropup').patchValue(address.formatted_address);
      this.detailSubmitted = false;
      this.continue(10);
    }
  }

  checkFormFields() {
    for (let i = 0; i < this.pickupfieldsArr.length; i++) {
      if (this.pickupfieldsArr[i].lat === 0 && this.pickupfieldsArr[i].lng === 0) {
        if (this.pickupfieldsArr.length === 1) {
          this.pickupfieldsArr[i].address = '';
          this.pickupForm.get(this.pickupfieldsArr[i].name).patchValue(null);
        } else {
          this.removePickupField(i);
        }
      }
    }
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
    clearInterval(this.intervalSubscription);
  }
}

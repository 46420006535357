import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpsService } from '../../prelaunch/services/https/https.service';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../prelaunch/services/common/common.service';
import { MustMatch } from '../../prelaunch/core/must-match.validator';
import Swal from 'sweetalert2';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { Constant } from '../../prelaunch/core/constant';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';
import { LocationStrategy } from "@angular/common";
import { SocialAuthService } from "angularx-social-login";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "src/app/services/language/language.service";
declare var $: any;
@Component({
  selector: "app-profilesetup",
  templateUrl: "./profilesetup.component.html",
  styleUrls: ["./profilesetup.component.scss"],
})
export class ProfilesetupComponent implements OnInit, OnDestroy {
  public show: boolean = false;
  public buttonName: any = 'Show';
  selected3 = "";
  selected1 = "";
  selected2 = "";
  selected = "";
  name = "Angular 4";
  nextPopup: number = 0;
  private _opened: boolean = false;
  changePassForm: any;
  firstName: string;
  user: any;
  editable: boolean;
  baseUrl: string;
  imageUrl: string;
  profileForm: FormGroup
  bookingStatusData: any = [];
  tgshow: boolean = true;
  currentPageUpcomingRide = 1;
  currentPageCompletedRide = 1;
  currentPageCanceledRide = 1;
  currentPageUpcomingDelivery = 1;
  currentPageCompletedDelivery = 1;
  currentPageCanceledDelivery = 1;
  currentPageDelivery = 1;
  rowsPerPage = 10;
  totalPages = 1;
  previousBtnEnabled = false;
  nextBtnEnabled = false;
  totalPagesUpcomingRide = 1;
  totalPagesCompletedRide = 1;
  totalPagesCanceledRide = 1;
  totalPagesUpcomingDelivery = 1;
  totalPagesCompletedDelivery = 1;
  totalPagesCanceledDelivery = 1;
  bookingStatusDataRide = [];
  bookingStatusDataDelivery: any = [];
  linkArr = [
    { name: 'My Rides', isActive: false },
    { name: 'My Deliveries', isActive: false },
    { name: 'Payment', isActive: false },
    { name: 'Loyalty Points', isActive: false },
    { name: 'Notifications', isActive: false },
    // { name: 'Manage Addresses', isActive: false },
    { name: 'Wallet', isActive: false },
    { name: 'Change Password', isActive: false },
    { name: 'Favourite Drivers', isActive: false },
    { name: 'Logout', isActive: false },
    { name: 'Delete Account', isActive: false },
  ]
  selectedItem: any;
  nextScreen: any;
  favouriteDriverData: any = [];
  isDriverFavourite: boolean = true;
  selectedTabIndex = 0;
  bookingDataUpcomingRide: any = [];
  bookingDataCanceledRide: any = [];
  bookingDataCompletedRide: any = [];
  bookingDataUpcomingDelivery: any = [];
  bookingDataCanceledDelivery: any = [];
  bookingDataCompletedDelivery: any = [];

  public ratingArr = [];
  isFeedBackSelected: boolean = false;
  feedBackQue = [
    { que: 'Was the driver on time?' },
    { que: 'Did the driver make your ride comfortabel?' },
    { que: 'Was the ride safe?' }
  ]
  selectedFeedbckQna: any = [];
  wasTheDriverOnTime: FormControl = new FormControl(null);
  driverMakeYouComfortabel: FormControl = new FormControl(null);
  wasTheRideSafe: FormControl = new FormControl(null);
  selectedDriverReview: number;
  customRate: FormControl = new FormControl(null);
  selectedTipAmount: any;
  comment: FormControl = new FormControl(null);
  rating: number = 0;
  ratingSubmitted: boolean = false;
  selectedReviewData: any = '';
  driverReviewData: any;
  dummy: any[] = [
    { amount: 2, isActive: false },
    { amount: 5, isActive: false },
    { amount: 8, isActive: false },
    { amount: 10, isActive: false },
  ]
  bookingId: any;
  selectedTip: any;

  @Input('starCount') private starCount: number = 5;
  @Input('color') private color: string = '#0171bd';
  @Output() private ratingUpdated = new EventEmitter();
  reviewData = [
    { name: 'Excellent', isActive: false },
    { name: 'Very Good', isActive: false },
    { name: 'Friendly', isActive: false },
    { name: 'Good', isActive: false },
    { name: 'Enjoy Service', isActive: false }
  ];
  bookingResponseData: any;

  review: FormControl = new FormControl('');
  notifier = new Subject();
  changePassSubmitted: boolean = false;
  bookingsData: any;
  redirectTo: string = '';
  submitted: boolean = false;
  cursor = 'pointer';
  isPhoneValid: boolean = true;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.Canada];
  verifyBy: any;
  display: string = 'none';
  redirectToVerification: any;
  isPhoneVerified: boolean = false;
  isEamilVerified: boolean = false;
  isPhoneExist: boolean = false;
  isEmailExist: boolean = false;
  editButtonClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  getEdiButtonClicked = this.editButtonClicked.asObservable();
  verificationData: any;
  charCodeArr: any = [96,49,50,51,52,53,54,55,56,57,48,45,61,91,93,92,59,44,46,47,126,33,64,35,36,37,94,38,42,40,41,95,43,123,125,124,58,34,60,62,63];
  validateFirstArr = [];
  validateSecondArr = [];
  ratingModal: string = 'none';
  myModal2: string = 'none';
  languageCode: string;
  showLang: boolean = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private http: HttpsService,
    public commonService: CommonService,
    public constant: Constant,
    private ngxService: NgxUiLoaderService,
    private location: LocationStrategy,
    private authService: SocialAuthService,
    public translate: TranslateService,
    public languageService: LanguageService
  ) {
    this.baseUrl = environment.imageUrl;
    if (localStorage.languageCode !== undefined) {
      this.languageCode = localStorage.getItem('languageCode');
    }
    this.translate.use(this.languageCode);
    this.languageService.translateLanguage(this.languageCode);
  }

  ngOnInit() {
    this.languageService.isLangChange.subscribe((isChange: boolean) => {
      if (isChange) {
        this.getChange();
      }
    })
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
    this.customRate.valueChanges.subscribe((value: any) => {
      this.selectedTip = 10000;
    });
    this.getRatingAndReview();
    this.changePasswordFormSetup();
    this.createProfileForm();
    this.preventBackButton();
    this.getFavDrivers();
    this.review.valueChanges.subscribe((value: any) => {
      if (value) {
        this.selectedReviewData = value;
      } else {
        this.selectedReviewData = '';
      }
    });
    if(localStorage.isThisUpdatedUser !== undefined) {
      this.commonService.isThisUpdatedUser = JSON.parse(localStorage.isThisUpdatedUser);
    } else {
      this.commonService.isThisUpdatedUser = false;
    }
    if (this.commonService.isThisUpdatedUser) {
      this.patchValue();
    } else {
      this.commonService.getUser().then(response => {
        if (response) {
          this.isPhoneVerified = this.commonService.loggedInUser.isPhoneVerified;
          this.isEamilVerified = this.commonService.loggedInUser.isEamilVerified;
        }
      });
    }
    if (this.commonService.loggedInUser) {
      this.isPhoneVerified = this.commonService.loggedInUser.isPhoneVerified;
      this.isEamilVerified = this.commonService.loggedInUser.isEamilVerified;
    }
    this.profileForm.get('phoneNo').valueChanges.subscribe((value: any) => {
      if (value.e164Number) {
        this.isPhoneExist = false;
        // this.isPhoneVerified = this.commonService.loggedInUser.isPhoneVerified;
        if (this.commonService.loggedInUser.countryCode + this.commonService.loggedInUser.phoneNo === value.e164Number && this.commonService.loggedInUser.isPhoneVerified) {
          // this.commonService.loggedInUser.isPhoneVerified = true;
          this.isPhoneVerified = true;
        } else {
          // this.commonService.loggedInUser.isPhoneVerified = false;
          this.isPhoneVerified = false;
        }
        // if(this.commonService.isPhoneVerified) {
        //   this.commonService.loggedInUser.isPhoneVerified = true;
        // }
        // this.getEdiButtonClicked.subscribe((value: boolean) => {
        //   if (value) {
        //     this.isEamilVerified = this.commonService.loggedInUser.isEamilVerified;
        //     this.isPhoneVerified = this.commonService.loggedInUser.isPhoneVerified;
        //   }
        // });
      }
    });
    this.profileForm.get('email').valueChanges.subscribe((value: any) => {
      if (value) {
        this.isEmailExist = false;
        // this.isEamilVerified = this.commonService.loggedInUser.isEamilVerified;
        if (this.commonService.loggedInUser.email === value.toLowerCase() && this.commonService.loggedInUser.isEamilVerified) {
          // this.commonService.loggedInUser.isEamilVerified = true;
          this.isEamilVerified = true;
        } else {
          // this.commonService.loggedInUser.isEamilVerified = false;
          this.isEamilVerified = false;
        }
        // if(this.commonService.isEamilVerified) {
        //   this.commonService.loggedInUser.isEamilVerified = true;
        // }
      }
    });
    console.log(this.commonService.userUpdateImage, 'ifvmimvgimgi');

  }

  getChange() {
    this.translate.get(['My Rides', 'My Deliveries', 'Payment', 'Loyalty Points', 'Notifications', 'Manage Addresses', 'Wallet', 'Change Password', 'Favourite Drivers', 'Logout', 'Delete Account']).subscribe(translations => {
      this.linkArr = [
        { name: translations['My Rides'], isActive: false },
        { name: translations['My Deliveries'], isActive: false },
        { name: translations['Payment'], isActive: false },
        { name: translations['Loyalty Points'], isActive: false },
        { name: translations['Notifications'], isActive: false },
        // { name: translations['Manage Addresses'], isActive: false },
        { name: translations['Wallet'], isActive: false },
        { name: translations['Change Password'], isActive: false },
        { name: translations['Favourite Drivers'], isActive: false },
        { name: translations['Logout'], isActive: false },
        { name: translations['Delete Account'], isActive: false },
      ]
    })
  }

  selectLang(language) {
    this.languageCode = language.code;
    this.languageService.selectedLang = language;
    this.languageService.translateLanguage(this.languageCode);
    this.showLang = false;
  }


  preventBackButton() {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  patchValue() {
    this.editable = true;
    this.imageUrl = this.commonService.userUpdateImage;
    if(localStorage.userUpdateForm !== undefined) {
      this.commonService.userUpdateForm = JSON.parse(localStorage.getItem('userUpdateForm'));
    }
    if(localStorage.userUpdateImage !== undefined) {
      this.commonService.userUpdateImage = localStorage.getItem('userUpdateImage');
    }
    this.profileForm.patchValue({ firstName: this.commonService.userUpdateForm.firstName, lastName: this.commonService.userUpdateForm.lastName, email: this.commonService.userUpdateForm.email });
    // var codeLength = this.commonService.userUpdateForm.value.phoneNo.dialCode.length;
    // var param = {
    //   phoneNo: this.profileForm.value.phoneNo.e164Number.slice(codeLength),
    //   countryCode: this.profileForm.value.phoneNo.dialCode
    // }
    this.profileForm.get('phoneNo').patchValue(this.commonService.userUpdateForm.phoneNo);
    
  }

  getRatingAndReview() {
    var param = {
      pageNo: 1
    }
    this.http.httpPostWithHeaderUser('getRatingAndReview', param).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      if (res.statusCode == 200) {

      }
    });
  }

  getFavDrivers() {
    this.http.httpPostWithHeaderUser('getAllFavouriteDriver', { pageNo: 1 }).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.favouriteDriverData = res.data.favouriteDriverData;
      }
    });
  }

  toggle() {
    this.tgshow = !this.tgshow;
  }

  toggleClick(index, data) {
    // var itemIndex = this.commonService.isThisDeliveryPartner ? 2 : 1;
    var itemIndex = 1;
    if (data.name !== 'Payment' && data.name !== 'Loyalty Points' && data.name !== 'Notifications' && data.name !== 'Wallet') {
      this.cursor = 'pointer';
      if (data.name === 'My Rides') {
        this.getBookingHistory(this.constant.BOOKING_TYPE.RIDE, 'currentPageUpcomingRide', 3);
        this.nextPopup = index + itemIndex;
        this.selectedItem = index;
        return;
      } else if (data.name === 'My Deliveries') {
        this.getBookingHistory(this.constant.BOOKING_TYPE.DELIVERY, 'currentPageUpcomingRide', 3);
        this.nextPopup = index + itemIndex;
        this.selectedItem = index;
        return;
      } else if (data.name === 'Delete Account') {
        this.Alert(10, 'Are you sure you want to delete your account?', 'accDelete');
        return;
      }
      this.nextPopup = index + itemIndex;
      this.selectedItem = index;
    } else {
      this.cursor = 'not-allowed';
    }
  }

  Alert(index, title, type) {
    // if(type === 'logout') {
    this.selectedItem = index;
    // }
    Swal.fire({
      title: title,
      // text: "You won't be able to revert this!",
      icon: 'info',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        if (type === 'logout') {
          this.logout();
        } else if (type === 'accDelete') {
          this.deleteAccount();
        }
      }
    });
  }

  deleteAccount() {
    this.http.httpPostWithHeaderUser('deleteUser', "").subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.commonService.localStorageCLear();
        this.router.navigate(["/riderlogin"]);
      }
    })
  }

  getUser() {
    this.ngxService.start();
    this.http.httpGetWithHeaderUser('userProfile', '').subscribe((res: any) => {
      if (res.statusCode === 200) {
        this.user = res.data;
        this.imageUrl = this.user.image;
        if (res.data.role === 'DELIVERY_PARTNER') {
          this.commonService.isThisDeliveryPartner = true;
        } else if (res.data.role === 'RIDER') {
          this.commonService.isThisDeliveryPartner = false;
        }
        this.ngxService.stop();
      }
    });
  }

  createProfileForm() {
    this.profileForm = this.fb.group({
      proImage: [''],
      phoneNo: [null, Validators.required],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: ["", [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
      // gender: [null, Validators.required],
    });
  }

  edit() {
    this.editable = true;
    this.imageUrl = this.commonService.userUpdateImage;
    this.profileForm.patchValue({ firstName: this.commonService.loggedInUser.firstName, lastName: this.commonService.loggedInUser.lastName, email: this.commonService.loggedInUser.email });
    this.profileForm.get('phoneNo').patchValue({
      dialCode: this.commonService?.loggedInUser?.countryCode,
      e164Number: this.commonService?.loggedInUser?.countryCode + this.commonService?.loggedInUser?.phoneNo,
      number: this.commonService?.loggedInUser?.countryCode + ' ' + this.commonService?.loggedInUser?.phoneNo
    });
    this.isEamilVerified = this.commonService.loggedInUser.isEamilVerified;
    this.isPhoneVerified = this.commonService.loggedInUser.isPhoneVerified;
    this.editButtonClicked.next(true);
  }

  changePasswordFormSetup() {
    this.changePassForm = this.fb.group({
      password: ["", Validators.required],
      newPassword: ["", [Validators.required, Validators.minLength(6)]],
      confirmPassword: ["", Validators.required]
    }, {
      validator: MustMatch('newPassword', 'confirmPassword')
    });
  }

  changePassword() {
    this.changePassSubmitted = true;
    if (this.changePassForm.valid) {
      let data = {
        password: this.changePassForm.value.password.trim(),
        newPassword: this.changePassForm.value.newPassword.trim(),
      }
      this.http.httpPostWithHeaderUser('changePassword', data).subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.commonService.OpenNotificationModal('success', 'top-end', 'User password changed successfully.');
          this.changePassForm.reset();
          this.changePassSubmitted = false;
        }
      });
    }
  }

  onFileUpload(fileInput: any) {
    const fileData = <File>fileInput.target.files[0];
    this.http.userFileUpload(fileData).subscribe(res => {
      this.imageUrl = JSON.parse(JSON.stringify(res)).data.orignal;
      this.commonService.userUpdateImage = this.imageUrl;
      localStorage.setItem('userUpdateImage',this.imageUrl);
    });
  }

  goTouhomepage() {
    if (this.commonService.isThisDeliveryPartner) {
      this.router.navigate(["riderdeliverybooking"]);
    } else {
      this.router.navigate(["/riderequest"]);
    }
  }

  goTowelcome() {
    this.router.navigate(["/welcome"]);
  }
  goToriderequest() {
    this.router.navigate(["/riderequest"]);
  }

  logout() {
    this.commonService.isThisUpdatedUser = false;
    localStorage.removeItem('isThisUpdatedUser');
    if(this.commonService.loggedInUser.isSocialLogin) {
      this.authService.signOut();
      FB.logout();
    }
    this.http.httpPostWithHeaderUser('logout', "").subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.commonService.localStorageCLear();
        this.router.navigate(["/riderlogin"]);
      }
    });
    this.commonService.localStorageCLear();
    this.router.navigate(["/riderlogin"]);
    this.commonService.origin = { lat: 0, lng: 0, address: '' };
    this.commonService.destination = { lat: 0, lng: 0, address: '' };
  }
  goToriderdetail() {
    this.router.navigate(["/riderdetail"]);
  }

  continue(popupType) {
    if (popupType === 0) {
      this.selectedItem = 1000;
      this.editable = false;
      this.commonService.getUser().then(response => {
        if (response) {
          this.isPhoneVerified = this.commonService.loggedInUser.isPhoneVerified;
          this.isEamilVerified = this.commonService.loggedInUser.isEamilVerified;
        }
      });
    }
    this.nextPopup = popupType;
  }

  verifyPhone() {
    this.submitted = true;
    if (this.profileForm.get('phoneNo').valid) {
      var codeLength = this.profileForm.value.phoneNo.dialCode.length;
      var param = $.extend({}, {
        phoneNo: this.profileForm.value.phoneNo.e164Number.slice(codeLength),
        userId: localStorage.userId !== undefined ? localStorage.userId : undefined
      });
      this.http.httpPostUser('checkIfPhoneNumberExist', param).subscribe((val: any) => {
        if (val.data.isValid) {
          this.isPhoneExist = false;
          this.submitted = false;
          this.verifyBy = {
            type: 'PHONE',
            phoneNo: this.profileForm.value.phoneNo.e164Number.slice(codeLength),
            countryCode: this.profileForm.value.phoneNo.dialCode
          };
          this.verificationData = this.profileForm.value.phoneNo.dialCode + ' ' + this.profileForm.value.phoneNo.e164Number.slice(codeLength);
          this.display = 'block';
          this.editable = false;

        } else {
          this.submitted = true;
          this.isPhoneExist = true;
        }
      })

    }
  }

  verifyEmail() {
    this.submitted = true;
    if (this.profileForm.get('email').valid) {
      var param = $.extend({}, {
        email: this.profileForm.value.email.toLowerCase(),
        userId: localStorage.userId !== undefined ? localStorage.userId : undefined
      });
      this.http.httpPostUser('checkIfEmailExist', param).subscribe((value: any) => {
        if (value.data.isValid) {
          this.submitted = false;
          this.verifyBy = {
            type: 'EMAIL',
            email: this.profileForm.value.email
          };
          this.verificationData = this.profileForm.value.email;
          var codeLength = this.profileForm.value.phoneNo.dialCode.length;
          var param = {
            phoneNo: this.profileForm.value.phoneNo.e164Number.slice(codeLength),
            countryCode: this.profileForm.value.phoneNo.dialCode
          }
          this.display = 'block';
          this.editable = false;
          // this.http.httpPostWithHeaderUser('sendOtpWithAuth', { email: this.profileForm.value.email }).subscribe((res: any) => {
          //   if (res.statusCode == 200) {
          //     this.commonService.setVerificationData({ countryCode: param?.countryCode, phoneNo: param?.phoneNo, otpCode: res.data.otpCode })
          //     this.commonService.userUpdateForm = this.profileForm;
          //     // this.commonService.userUpdateImage = this.imageUrl;
          //     this.commonService.emailForVerification = this.profileForm.value.email;
          //     this.commonService.isThisUpdatedUser = true;
          //     this.redirectToVerification = '/verifyemail';
          //     this.display = 'block';
          //     this.editable = false;
          //   }
          // });
        } else {
          this.submitted = true;
          this.isEmailExist = true;
        }
      })

    }
  }

  sendOtp() {
    var newParam;
    if (this.verifyBy.type === 'EMAIL') {
      newParam = {
        email: this.verifyBy.email,
        isPrelaunch: true
      }
    } else if (this.verifyBy.type === 'PHONE') {
      newParam = {
        phoneNo: this.verifyBy.phoneNo,
        countryCode: this.verifyBy.countryCode
      }
    }
    this.http.httpPostWithHeaderUser('sendOtpWithAuth', newParam).subscribe((res: any) => {
      if (res.statusCode == 200) {
        if (this.verifyBy.type === 'PHONE') {
          localStorage.setItem('phone', this.verifyBy.phoneNo);
          localStorage.setItem('phoneNo', this.verifyBy.phoneNo);
          localStorage.setItem('countryCode', this.verifyBy.countryCode);
          this.redirectToVerification = '/riderotppage';
        } else {
          this.redirectToVerification = '/verifyemail';
          this.commonService.isThisUpdatedUser = true;
          localStorage.setItem('email', this.profileForm.value.email);
          this.commonService.emailForVerification = this.profileForm.value.email;
        }
        this.commonService.setVerificationData({ countryCode: this.verifyBy?.countryCode, phoneNo: this.verifyBy?.phoneNo, otpCode: res.data.otpCode })
        this.commonService.userUpdateForm = this.profileForm.value;
        localStorage.setItem('userUpdateForm',JSON.stringify(this.profileForm.value));
        this.commonService.userUpdateImage = this.imageUrl;
        localStorage.setItem('userUpdateImage',this.imageUrl);
        localStorage.setItem('isThisUpdatedUser', 'true');
        this.commonService.isThisUpdatedUser = true;
        this.display = 'none';
        this.editable = false;
        // var formData = new FormData();
        // formData.append('isEamilVerified', "false");
        // formData.append('email', this.commonService.userUpdateForm.value.email);
        // this.http.httpPostWithFormDataHeader('updateUserProfile', formData).subscribe();
        this.goToriderotppage();
      }
    });
  }

  verification() {
    this.submitted = true;
    if (this.profileForm.valid && this.isEamilVerified && this.isPhoneVerified && !this.isEmailExist && !this.isPhoneExist 
      && (this.validateFirstArr.length === 0 || !this.validateFirstArr.includes(true))
       && (this.validateSecondArr.length === 0 || !this.validateSecondArr.includes(true))) {
      this.editable = false;
      this.commonService.userUpdateForm = this.profileForm.value;
      localStorage.setItem('userUpdateForm',JSON.stringify(this.profileForm.value));
      if(localStorage.userUpdateImage !== undefined) {
        this.commonService.userUpdateImage = localStorage.getItem('userUpdateImage');
      }
      this.commonService.updateUser();
      // var codeLength = this.profileForm.value.phoneNo.dialCode.length;
      // if (this.commonService.loggedInUser.isPhoneVerified && this.commonService.loggedInUser.phoneNo !== this.profileForm.value.phoneNo.e164Number.slice(codeLength)) {
      //   this.verifyBy = this.profileForm.value.phoneNo.dialCode+' '+this.profileForm.value.phoneNo.e164Number.slice(codeLength);
      //   var param = {
      //     phoneNo: this.profileForm.value.phoneNo.e164Number.slice(codeLength),
      //     countryCode: this.profileForm.value.phoneNo.dialCode
      //   }
      //   this.http.httpPostWithHeaderUser('sendOtpWithAuth', param).subscribe((res: any) => {
      //     if (res.statusCode == 200) {
      //       this.commonService.setVerificationData({ countryCode: param.countryCode, phoneNo: param.phoneNo, otpCode: res.data.otpCode })
      //       this.commonService.userUpdateForm = this.profileForm;
      //       this.commonService.userUpdateImage = this.imageUrl;
      //       this.commonService.isThisUpdatedUser = true;
      //       this.redirectToVerification = '/riderotppage';
      //       this.display = 'block';
      //       this.editable = false;
      //     }
      //   });
      // } else if (this.commonService.loggedInUser.isEamilVerified && this.commonService.loggedInUser.email !== this.profileForm.value.email) {
      //   this.verifyBy = this.profileForm.value.email;
      //   this.http.httpPostWithHeaderUser('sendOtpWithAuth', { email: this.profileForm.value.email }).subscribe((res: any) => {
      //     if (res.statusCode == 200) {
      //       this.commonService.setVerificationData({ countryCode: param?.countryCode, phoneNo: param?.phoneNo, otpCode: res.data.otpCode })
      //       this.commonService.userUpdateForm = this.profileForm;
      //       this.commonService.userUpdateImage = this.imageUrl;
      //       this.commonService.emailForVerification = this.profileForm.value.email;
      //       this.commonService.isThisUpdatedUser = true;
      //       this.redirectToVerification = '/verifyemail';
      //       this.display = 'block';
      //       this.editable = false;
      //     }
      //   });
      // } else {
      //   this.commonService.userUpdateForm = this.profileForm;
      //   this.commonService.userUpdateImage = this.imageUrl;
      //   this.editable = false;
      //   this.commonService.updateUser();
      // }
    }
  }

  goToriderotppage() {
    this.commonService.isThisUpdatedUser = true;
    localStorage.setItem('isThisUpdatedUser', 'true');
    this.router.navigate([this.redirectToVerification]);
  }

  cancel() {
    this.editable = false;
    this.submitted = false;
    this.commonService.getUser().then(response => {
      if (response) {
        this.isPhoneVerified = this.commonService.loggedInUser.isPhoneVerified;
        this.isEamilVerified = this.commonService.loggedInUser.isEamilVerified;
        this.commonService.userUpdateImage = this.commonService.loggedInUser.image;
        localStorage.setItem('userUpdateImage',this.commonService.loggedInUser.image);
      }
    });
  }

  getBookingHistory(type, currentPage, bookingStatus) {
    var param = {
      bookingType: type,
      pageNo: this[currentPage],
      bookingStatus: bookingStatus
    }
    this.http.httpPostWithHeaderUser('getBookingHistory', param).subscribe((res: any) => {
      if (res.statusCode === 200) {
        // this.bookingStatusDataRide = [];
        // this.bookingStatusDataDelivery = [];
        if (res.data.bookingType === this.constant.BOOKING_TYPE.RIDE) {
          // this.bookingStatusDataRide = res.data.bookingData;
          if (res.data.bookingStatus === 3) {
            this.bookingDataUpcomingRide = [];
            this.bookingDataUpcomingRide = res.data.bookingData;
            this.totalPagesUpcomingRide = res.data.totalPages;
          } else if (res.data.bookingStatus === 2) {
            this.bookingDataCanceledRide = [];
            this.bookingDataCanceledRide = res.data.bookingData;
            this.totalPagesCanceledRide = res.data.totalPages;
          } else if (res.data.bookingStatus === 1) {
            this.bookingDataCompletedRide = [];
            this.bookingDataCompletedRide = res.data.bookingData;
            this.totalPagesCompletedRide = res.data.totalPages;
          }
        } else if (res.data.bookingType === this.constant.BOOKING_TYPE.DELIVERY) {
          // this.bookingStatusDataDelivery = res.data.bookingData;
          if (res.data.bookingStatus === 3) {
            this.bookingDataUpcomingDelivery = [];
            this.bookingDataUpcomingDelivery = res.data.bookingData;
            this.totalPagesUpcomingDelivery = res.data.totalPages;
          } else if (res.data.bookingStatus === 2) {
            this.bookingDataCanceledDelivery = [];
            this.bookingDataCanceledDelivery = res.data.bookingData;
            this.totalPagesCanceledDelivery = res.data.totalPages;
          } else if (res.data.bookingStatus === 1) {
            this.bookingDataCompletedDelivery = [];
            this.bookingDataCompletedDelivery = res.data.bookingData;
            this.totalPagesCompletedDelivery = res.data.totalPages;
          }
        }
      }
    });
  }

  onNextPage(type, totalPages, currentPage, bookingStatus) {
    if (type === 'RIDE') {
      this[currentPage]++;
      this.updateTableRide(totalPages, currentPage, bookingStatus);
    } else {
      this[currentPage]++;
      this.updateTableDelivery(totalPages, currentPage, bookingStatus);
    }

  }
  onChnagePage(page, type, totalPages, currentPage, bookingStatus) {
    if (type === 'RIDE') {
      this[currentPage] = page;
      this.updateTableRide(totalPages, currentPage, bookingStatus);
    } else {
      this[currentPage] = page;
      this.updateTableDelivery(totalPages, currentPage, bookingStatus);
    }
  }
  onPrevPage(type, totalPages, currentPage, bookingStatus) {
    if (type === 'RIDE') {
      this[currentPage]--;
      this.updateTableRide(totalPages, currentPage, bookingStatus);
    } else {
      this[currentPage]--;
      this.updateTableDelivery(totalPages, currentPage, bookingStatus);
    }
  }

  updateTableRide(totalPages, currentPage, bookingStatus) {
    this.previousBtnEnabled = this[currentPage] > 1;
    this.nextBtnEnabled = this[currentPage] < this[totalPages];
    this.getBookingHistory(this.constant.BOOKING_TYPE.RIDE, currentPage, bookingStatus);
  }

  updateTableDelivery(totalPages, currentPage, bookingStatus) {
    this.previousBtnEnabled = this[currentPage] > 1;
    this.nextBtnEnabled = this[currentPage] < this[totalPages];
    this.getBookingHistory(this.constant.BOOKING_TYPE.DELIVERY, currentPage, bookingStatus);
  }

  onTabChange(type, event) {
    this.selectedTabIndex = event.index;
    if (type === 'RIDE') {
      if (event.index === 0) {
        this.onChnagePage(1, type, 'totalPagesUpcomingRide', 'currentPageUpcomingRide', 3);
      } else if (event.index === 1) {
        this.onChnagePage(1, type, 'totalPagesCompletedRide', 'currentPageCompletedRide', 1);
      } else if (event.index === 2) {
        this.onChnagePage(1, type, 'totalPagesCanceledRide', 'currentPageCanceledRide', 2);
      }
    } else if (type === 'DELIVERY') {
      if (event.index === 0) {
        this.onChnagePage(1, type, 'totalPagesUpcomingDelivery', 'currentPageUpcomingDelivery', 3);
      } else if (event.index === 1) {
        this.onChnagePage(1, type, 'totalPagesCompletedDelivery', 'currentPageCompletedDelivery', 1);
      } else if (event.index === 2) {
        this.onChnagePage(1, type, 'totalPagesCanceledDelivery', 'currentPageCanceledDelivery', 2);
      }
    }

  }

  favouriteDriver(driverId, isFav) {
    if (isFav) {
      this.http.httpPostWithHeaderUser('addFavouriteDriver', { driverId: driverId, isFav: isFav }).subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.getFavDrivers();
        }
      });
    } else {
      this.http.httpPostWithHeaderUser('addFavouriteDriver', { driverId: driverId, isFav: isFav }).subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.getFavDrivers();
        }
      });
    }

  }

  addFavouriteDriver() {
    this.http.httpPostWithHeaderUser('addFavouriteDriver', { driverId: this.bookingResponseData.driverId.id, isFav: true }).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.isDriverFavourite = true;
        this.commonService.OpenNotificationModal('success', 'top-end', res.message);
        this.getDriverRatingsReview();
      }
    });
  }

  removeFavouriteDriver() {
    this.http.httpPostWithHeaderUser('addFavouriteDriver', { driverId: this.bookingResponseData.driverId.id, isFav: false }).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.isDriverFavourite = false;
        this.commonService.OpenNotificationModal('success', 'top-end', res.message);
        this.getDriverRatingsReview();
      }
    });
  }

  //for rating
  onClick(rating: number) {
    // console.log(rating)
    // this.snackBar.open('You rated ' + rating + ' / ' + this.starCount, '', {
    //   duration: this.snackBarDuration
    // });
    this.ratingUpdated.emit(rating);
    this.rating = rating;
    if (this.rating > 0 && this.rating < 3) {
      this.review.setValidators(Validators.required);
      this.review.updateValueAndValidity();
    } else {
      this.review.clearValidators();
      this.review.updateValueAndValidity();
    }
    return false;
  }

  showIcon(index: number) {
    if (this.rating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }

  selectReview(index, data) {
    this.selectedDriverReview = index;
    this.selectedReviewData = data.isActive ? '' : data.name;
  }

  selectTip(i, amount) {
    this.selectedTip = i;
    this.selectedTipAmount = amount;
    if (this.customRate.value != null) {
      this.customRate.patchValue(null);
      this.selectedTip = i;
    }

  }

  onTipChange(value) {
    if (value <= 0) {
      this.customRate.patchValue(null);
    }
  }

  keypresshandler(event) {
    var charCode = event.keyCode;
    //Non-numeric character range
    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return false;
  }

  addRating(index) {
    this.ratingSubmitted = true;
    if (this.rating > 0 && this.selectedReviewData != '') {
      this.selectedFeedbckQna = [];
      this.selectedFeedbckQna.push({ question: 'Was the driver on time?', answere: this.wasTheDriverOnTime.value });
      this.selectedFeedbckQna.push({ question: 'Was the ride safe?', answere: this.wasTheRideSafe.value });
      this.selectedFeedbckQna.push({ question: 'Did the driver make your ride comfortabel?', answere: this.driverMakeYouComfortabel.value });
      this.selectedFeedbckQna.forEach(element => {
        if (element.answere === null) {
          const index = this.selectedFeedbckQna.indexOf(element);
          this.selectedFeedbckQna.splice(index, 1);
        }
      });
      this.selectedFeedbckQna.forEach(element => {
        if (element.answere === null) {
          const index = this.selectedFeedbckQna.indexOf(element);
          this.selectedFeedbckQna.splice(index, 1);
        }
      });
      var param = $.extend({}, {
        "bookingId": this.bookingId,
        "driverId": this.bookingResponseData.driverId.id,
        "driverRating": this.rating,
        "driverReview": this.rating < 3 ? this.review.value : this.selectedReviewData,
        "tip": this.customRate.value ? this.customRate.value : this.selectedTipAmount,
        "comment": this.comment.value ? this.comment.value : undefined,
        "feedBack": this.selectedFeedbckQna
      })
      this.http.httpPostWithHeaderUser('addRatings', param).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.commonService.OpenNotificationModal('success', 'top-end', res.message);
          this.closeratingModal();
          this.onChnagePage(1, 'RIDE', 'totalPagesCompletedRide', 'currentPageCompletedRide', 1);
          this.onChnagePage(1, 'DELIVERY', 'totalPagesCompletedDelivery', 'currentPageCompletedDelivery', 1);
        }
      });
    }
  }

  getDriverRatingsReview() {
    this.http.httpPostWithHeaderUser('getDriverRatingsReview', { driverId: this.bookingResponseData.driverId._id }).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.driverReviewData = res.data.driverData;
      }
    });
  }

  goToStatusPageOfRide(bookingData) {
    this.commonService.bookingResponseData['subBookingData'] = bookingData.subBookingData;
    // this.commonService.bookingResponseData = bookingData;
    // this.openBookingStatusModal(bookingData);
    this.router.navigate([this.redirectTo]);
  }

  goToStatusPageOfDelivery(bookingData) {
    // this.commonService.bookingResponseData = bookingData;
    this.commonService.bookingResponseData['subBookingData'] = bookingData.subBookingData;
    // this.openBookingStatusModal(bookingData);
    this.router.navigate(['/riderdeliverybooking']);
  }

  openRatingModal(response) {
    this.bookingResponseData = response;
    this.getDriverRatingsReview();
    console.log(response);
    this.bookingId = response.id;
    this.driverReviewData = response.driverId;
    this.ratingModal = 'block';
    // $("#ratingModal").modal('show');
  }

  closeratingModal() {
    this.ratingModal = 'none';
    // $("#ratingModal").modal('hide');
  }

  openBookingStatusModal(response, bookingType) {
    this.http.httpPostWithHeaderUser('getBookingDetails', { bookingId: response.id }).pipe(takeUntil(this.notifier)).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.bookingsData = res.data;
        this.bookingId = response.id;
        this.driverReviewData = response.driverId;
        this.commonService.bookingResponseData = response;
        this.commonService.bookingResponseData['subBookingData'] = res.data.subBookingData;
        if (bookingType === 'RIDE') {
          this.redirectTo = 'riderequest';
        } else {
          this.redirectTo = 'riderdeliverybooking';
        }
        this.myModal2 = 'block';
        // $("#myModal2").modal('show');
      }
    });
  }

  openModal() {
    this.commonService.preLaunchAlert(this.constant.MSGS.PRELAUNCH_RIDE);
  }

  closeBookingStatusModal() {
    $("#myModal2").modal('hide');
  }

  goToverification(url) {
    var codeLength = this.profileForm.value.phoneNo.dialCode.length;
    // var param = {
    var phoneNo = this.profileForm.value.phoneNo.e164Number.slice(codeLength);
    // countryCode: this.profileForm.value.phoneNo.dialCode
    // }
    this.http.httpPostWithHeaderUser('sendOtp', { phoneNo: phoneNo }).subscribe((item: any) => {
      if (item.statusCode == 200) {
        this.commonService.setVerificationData({ countryCode: item.data.countryCode, phoneNo: item.data.phoneNo, otpCode: item.data.otpCode })
        this.router.navigate([url]);
      }
    });
  }

  onKeyPress(event) {
    if(event.charCode !== 32 && event.charCode !== 39) {
      return (event.charCode > 64 && 
        event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)
    }
  }

  onChangeFirst(value) {
    this.validateFirstArr = [];
    for (let i = 0; i < value.length; i++) {
      var charCode = value.charCodeAt(i);
      this.validateFirstArr.push(this.charCodeArr.includes(charCode));
    }
  }

  onChangeSecond(value) {
    this.validateSecondArr = [];
    for (let i = 0; i < value.length; i++) {
      var charCode = value.charCodeAt(i);
      this.validateSecondArr.push(this.charCodeArr.includes(charCode));
    }
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }

}

<div class="login_wrap top">
  <div class="signupinner">
    <div class="conta_iner">
      <div class="flex">
        <div class="haeder_left">
          <figure class="logo_cm" style="cursor: pointer;" (click)="goTouhomepage()">
            <img src="assets/uimages/logo.png" />
          </figure>
        </div>
        <div class="multiple_content">
          <div class="lm">
            <div class="language-select" (click)="showLang = showLang?false:true">
              <div class="sel-bar" *ngIf="languageService?.selectedLang">
                <figure><img *ngIf="languageService?.selectedLang?.logo"
                    [src]="baseUrl+languageService?.selectedLang?.logo">{{languageService?.selectedLang?.code?.toUpperCase()}}
                </figure>
              </div>
              <p *ngIf="!languageService?.selectedLang">Select Language</p>
              <div class="ndpicon">
                <i *ngIf="!showLang" class="fa fa-caret-down" aria-hidden="true"></i>
                <i *ngIf="showLang" class="fa fa-caret-up" aria-hidden="true"></i>
              </div>
            </div>
            <div class="dp-lang" *ngIf="showLang">
              <figure *ngFor="let language of languageService?.allLanguages" (click)="selectLang(language)">
                <img *ngIf="language?.logo" [src]="baseUrl+language?.logo">{{language?.code?.toUpperCase()}}
              </figure>
            </div>
          </div>
          <div class="back_home">
            <button class="driverap_btn" style="cursor: pointer;" (click)="goTouhomepage()">
              {{'Back To Home' | translate}}
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="login_sec">
    <div class="conta_iner">
      <div class="verify-email">
        <figure><img src="assets/images/verify_email_logo.png"></figure>
        <h3>{{'Verifying your email' | translate}}</h3>
        <p>{{'Please check your email account for' | translate}} <b>{{commonService?.emailForVerification}}</b> {{'and
          click the' | translate}} "{{'verify link inside' | translate}}".</p>
        <div class="btn_outer">
          <button class="btn btn-primary margin_top20" (click)="goToNextPage()">
            {{'Next' | translate}}
          </button>
          <button class="blue-c" style="cursor: pointer;" (click)="sendOTP()">{{'Resend' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<br>
<form name="getSessionTokenForm" [formGroup]="paymentForm">
    <!-- First Name: <input type="text" id="name" name="ssl_first_name" size="25" value="John"> <br><br>
    Last Name: <input type="text" id="lastname" name="ssl_last_name" size="25" value="Smith"> <br><br>
    Transaction Amount: <input type="text" id="ssl_amount" name="ssl_amount" value="25.00"> <br> <br>
    <button onclick="return initiateCheckoutJS();">Click to Confirm Order</button> <br> -->
    <!-- </form> -->
   
    <!-- Transaction Token: <input id="token" type="text" name="token"> <br /> -->

    Card Number: <input id="card" formControlName="cardNumber" type="text" name="card" value="4124939999999990" /> <br />
    Expiry Date: <input id="exp" formControlName="expiry" type="text" name="exp" value="1219" /> <br />
    CVV2: <input id="cvv" formControlName="cvv" type="text" name="cvv" value="123"> <br />
    <!-- Merchant TXN ID: <input id="merchanttxnid" type="text" name="merchanttxnid" value="MerchantTXNIDHere" /> <br /> -->
    <input id="gettoken" type="hidden" name="gettoken" value="y" />
    <input id="addtoken" type="hidden" name="addtoken" value="y" />
    <button (click)="pay()">Process Payment</button>

</form>
<br>
<br>
<br>
<br>
Transaction Status:<div id="txn_status"></div>
<br>
Transaction Response:<div id="txn_response"></div>
<div class="login_wrap signup">
  <app-unavbar id="navbar"></app-unavbar>
  <!-- <div class="signupinner">
        <div class="conta_iner">
            <div class="flex">
                <div class="haeder_left">
                    <figure class="logo_cm" (click)="goTouhomepage()">
                        <img src="assets/uimages/logo.png" />
                    </figure>
                </div>
                <div class="back_home">
                    <button class="driverap_btn" (click)="goTouhomepage()">
                        Back To Home
                    </button>
                </div>
            </div>
        </div>
    </div> -->

  <div class="login" *ngIf="nextPopup == 0">
    <div class="conta_iner">
      <div class="login_main">
        <div class="login_form driver-type">
          <div class="login_img_sec">
            <figure class="login_img">
              <img src="assets/uimages/signup-icon.svg" />
            </figure>
            <div class="type-text">
              <h4>{{ "Select your driver type" | translate }}</h4>
            </div>

            <!-- <p>Create an account</p> -->
          </div>

          <button (click)="goToSignupStep('RIDE')" class="btn btn-primary margin_top20">
            {{ "Rides" | translate }}
          </button>
          <button (click)="goToSignupStep('DELIVERY')" class="btn btn-primary margin_top20">
            {{ "Deliveries" | translate }}
          </button>
          <button (click)="goToSignupStep('BOTH')" class="btn btn-primary margin_top20">
            {{ "Rides and Deliveries" | translate }}
          </button>

          <p class="dont_account">
            {{ "Do you have an account?" | translate }} <a (click)="goTologin()">{{ "Login" | translate }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="login" *ngIf="nextPopup == 1">
    <div class="conta_iner">
      <div class="login_main">
        <div class="login_form">
          <div class="login_img_sec">
            <figure class="login_img">
              <img src="assets/uimages/signup-icon.svg" />
            </figure>
            <h4>{{ "Enter your phone number" | translate }}</h4>
            <p>{{ "Create an account" | translate }}</p>
          </div>
          <!-- <h4>
                        Enter Your Phone Number
                       
                        <span style="display: block;">create your account</span>
                    </h4> -->
          <!-- <form class="form_control_form enter_email" *ngIf="nextPopup == 1">
                        <div class="form-group">
                            <mat-form-field class="example-full-width">
                                <mat-label>Your email</mat-label>
                                <input matInput placeholder="Your email" [formControl]="email" required />
                            </mat-form-field>
                        </div>
                        <mat-error *ngIf="email.touched && email.invalid">{{ getErrorMessage() }}
                        </mat-error>
                        <div class="driverap_btn">
                            <button (click)="goToverification()">Next</button>
                        </div>
                        <div class="driverap_btn inactive">
                            <button (click)="continue('1')">
                Sign Up With Mobile Number
              </button>
                        </div>
                    </form> -->
          <form [formGroup]="signupForm">
            <div class="form_control_form enter_email" *ngIf="nextPopup == 1">
              <ngx-intl-tel-input
                [inputId]="'adsasd'"
                [cssClass]="'custom'"
                [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="false"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="CountryISO.Canada"
                [maxLength]="15"
                [tooltipField]="TooltipLabel.Name"
                [phoneValidation]="true"
                [separateDialCode]="separateDialCode"
                name="phone"
                formControlName="phoneNo"
              >
              </ngx-intl-tel-input>
              <mat-error *ngIf="submitted && signupForm.controls['phoneNo'].hasError('required')">
                {{ "Phone number is required" | translate }}.</mat-error
              >
              <mat-error *ngIf="submitted && signupForm.controls['phoneNo'].hasError('validatePhoneNumber')">
                {{ "Please enter valid phone number" | translate }}.</mat-error
              >
              <mat-error
                *ngIf="
                  submitted &&
                  !isPhoneValid &&
                  !signupForm.controls['phoneNo'].hasError('validatePhoneNumber') &&
                  !signupForm.controls['phoneNo'].hasError('required')
                "
              >
                {{ "Phone number already exist" | translate }}.</mat-error
              >
              <div class="submit_btn">
                <button (click)="goToverification()" class="btn btn-primary margin_top20">
                  {{ "Next" | translate }}
                </button>
              </div>
              <p class="dont_account">
                {{ "Do you have an account?" | translate }} <a (click)="goTologin()">{{ "Login" | translate }}</a>
              </p>
              <!-- <div class="driverap_btn inactive">
                            <button>Sign Up With Email</button>
                        </div> -->
            </div>
          </form>
          <!-- <p class="dont_account">
                        Do you have an account? <a (click)="goTologin()">Login</a>
                    </p> -->
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="login_images"></div> -->
</div>

<div class="profile_wrap">
    <div class="signupinner">
        <div class="conta_iner">
            <div class="flex">
                <div class="haeder_left">
                    <figure class="logo_cm" (click)="goTohome()"><img src="../../assets/images/logo.png"></figure>
                </div>
                <div class="multiple_content">
                    <div class="language-select">
                        <select name="cars" id="cars">
                            <option [value]="null" disabled>Select Language</option>
                            <option value="en">English</option>
                            <option value="es">Spanish</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="prfile">
        <div class="conta_iner">
            <div class="profile_main">
                <div class="login_form">
                    <div class="add_vehicle">
                        <h4>Add vehicle</h4>
                        <div class="add_vehicle_honda flex" *ngIf=" nextPopup==1">
                            <div class="add_honda_left">
                                <div class="file">
                                    <figure>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="29.346" height="39.845"
                                            viewBox="0 0 29.346 39.845">
                                            <g id="file" transform="translate(-63.488)">
                                                <g id="Group_11776" data-name="Group 11776"
                                                    transform="translate(85.763 0.365)">
                                                    <g id="Group_11775" data-name="Group 11775"
                                                        transform="translate(0)">
                                                        <path id="Path_24242" data-name="Path 24242"
                                                            d="M332.873,4.412v6.706h6.707Z"
                                                            transform="translate(-332.873 -4.412)" fill="#fff" />
                                                    </g>
                                                </g>
                                                <g id="Group_11778" data-name="Group 11778"
                                                    transform="translate(63.488)">
                                                    <g id="Group_11777" data-name="Group 11777"
                                                        transform="translate(0)">
                                                        <path id="Path_24243" data-name="Path 24243"
                                                            d="M85.14,8.316a.623.623,0,0,1-.623-.623V0H64.111a.623.623,0,0,0-.623.623v38.6a.623.623,0,0,0,.623.623h28.1a.623.623,0,0,0,.623-.623V8.316ZM67.646,22.726H80.48v1.245H67.646Zm9.262,12.491H67.646V33.972h9.262Zm11.768-3.749H67.646V30.223h21.03Zm0-3.749H67.646V26.474h21.03Zm0-7.5H67.646V18.977h21.03Zm0-3.749H67.646V15.228h21.03Zm0-3.749H67.646V11.479h21.03Z"
                                                            transform="translate(-63.488)" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </figure>
                                    <h5>Honda<span>Honda city</span><span class="capitaAL_SVU">SUV</span></h5>
                                </div>
                            </div>
                            <div class="add_vehicle_right flex">
                                <div class="add_vehicle_right_icon">
                                    <figure>
                                        <svg id="pencil" xmlns="http://www.w3.org/2000/svg" width="15.229"
                                            height="15.23" viewBox="0 0 15.229 15.23">
                                            <g id="Group_11780" data-name="Group 11780" transform="translate(0 0)">
                                                <g id="Group_11779" data-name="Group 11779" transform="translate(0 0)">
                                                    <path id="Path_24244" data-name="Path 24244"
                                                        d="M11.288,81.128a.356.356,0,0,0-.489,0L4.215,87.717a.346.346,0,0,0,.09.555l.54.271v.91l-1.656.474L1.518,88.254,1.991,86.6H2.9l.27.541a.347.347,0,0,0,.254.187.342.342,0,0,0,.055,0,.345.345,0,0,0,.245-.1l6.583-6.589a.347.347,0,0,0,0-.49L9.036,78.874a.346.346,0,0,0-.245-.1h0a.346.346,0,0,0-.245.1L1.485,86.006s0,.009-.007.012a.339.339,0,0,0-.081.137l-.6,2.1L.013,91.005a.347.347,0,0,0,.333.442.352.352,0,0,0,.095-.013l2.743-.784,2.1-.6c.006,0,.011-.007.017-.009a.641.641,0,0,0,.117-.069s.01-.005.015-.01l7.125-7.067a.347.347,0,0,0,0-.491Z"
                                                        transform="translate(0 -76.217)" fill="#7e7e7e" />
                                                    <path id="Path_24245" data-name="Path 24245"
                                                        d="M295.708,2.474,293.631.4a1.415,1.415,0,0,0-1.959,0L290.4,1.677a.347.347,0,0,0,0,.489L293.939,5.7a.346.346,0,0,0,.488,0l1.28-1.27a1.386,1.386,0,0,0,0-1.959Z"
                                                        transform="translate(-280.884 -0.002)" fill="#7e7e7e" />
                                                </g>
                                            </g>
                                        </svg>

                                    </figure>
                                </div>
                                <div class="add_vehicle_right_icon">
                                    <figure>
                                        <svg id="delete_2_" data-name="delete (2)" xmlns="http://www.w3.org/2000/svg"
                                            width="14.169" height="16.193" viewBox="0 0 14.169 16.193">
                                            <path id="Path_24246" data-name="Path 24246"
                                                d="M64.444,139.01a2.026,2.026,0,0,0,2.024,2.024h8.1a2.026,2.026,0,0,0,2.024-2.024V128.889H64.444Z"
                                                transform="translate(-63.432 -124.841)" fill="#7e7e7e" />
                                            <path id="Path_24247" data-name="Path 24247"
                                                d="M41.331,1.012V0H37.282V1.012h-5.06V3.036H46.391V1.012Z"
                                                transform="translate(-32.222)" fill="#7e7e7e" />
                                        </svg>


                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div class="vehicle_form">
                            <div class="form-group">
                                <mat-form-field [floatLabel]="'never'">
                                    <input matInput type="text" placeholder="Vehicle make" name="name">
                                </mat-form-field>
                            </div>
                            <div class="form-group">
                                <mat-form-field [floatLabel]="'never'">
                                    <input matInput type="text" placeholder="Vehicle Model" name="name">
                                </mat-form-field>
                            </div>
                            <div class="form-group">
                                <mat-form-field>
                                    <mat-label>Vehicle type</mat-label>
                                    <mat-select [(ngModel)]="selected1">
                                        <mat-option value="option1">lorem</mat-option>
                                        <mat-option value="option2">lorem</mat-option>
                                        <mat-option value="option3">lorem</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="form-group">
                                <mat-form-field>
                                    <mat-label>Transmission Type</mat-label>
                                    <mat-select [(ngModel)]="selected2">
                                        <mat-option value="option1">text</mat-option>
                                        <mat-option value="option2">text</mat-option>
                                        <mat-option value="option3">text</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="form-group">
                                <mat-form-field>
                                    <mat-label>Gender Preference</mat-label>
                                    <mat-select [(ngModel)]="selected3">
                                        <mat-option value="option1">lorem</mat-option>
                                        <mat-option value="option2">lorem</mat-option>
                                        <mat-option value="option3">lorem</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="form-group teatarea">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Vehicle description</mat-label>
                                    <textarea matInput placeholder=""></textarea>
                                </mat-form-field>
                            </div>
                            <div class="form-group upload_form_group">
                                <figure class="ipload_img_vehicle">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="114.195" height="97.656"
                                        viewBox="0 0 114.195 97.656">
                                        <g id="surface1" transform="translate(0)">
                                            <path id="Path_24238" data-name="Path 24238"
                                                d="M110.78,160.375H3.416a3.39,3.39,0,0,0-2.583,1.164,3.25,3.25,0,0,0-.8,2.7l7.069,50.644a8.6,8.6,0,0,0,8.431,7.381H98.8a8.581,8.581,0,0,0,8.432-7.409l6.926-50.645a3.343,3.343,0,0,0-.795-2.7A3.461,3.461,0,0,0,110.78,160.375ZM75.267,191.744a2.266,2.266,0,0,1-1.533.6,2.418,2.418,0,0,1-1.675-.71l-12.69-13.655v32.448a2.271,2.271,0,0,1-4.543,0V177.976L42.137,191.6a2.269,2.269,0,1,1-3.321-3.094l16.635-17.857a2.272,2.272,0,0,1,1.675-.709,2.318,2.318,0,0,1,1.675.709l16.635,17.857A2.372,2.372,0,0,1,75.267,191.744Zm0,0"
                                                transform="translate(0 -124.605)" fill="#ccc" />
                                            <path id="Path_24239" data-name="Path 24239"
                                                d="M39.057,31.228h96.152V24.045a8.379,8.379,0,0,0-8.432-8.432H91.263A13.123,13.123,0,0,1,80.078,9.2L77.126,4.173A8.569,8.569,0,0,0,69.83,0H46.58a8.468,8.468,0,0,0-6.161,2.7,8.563,8.563,0,0,0-2.357,6.33Zm0,0"
                                                transform="translate(-29.566)" fill="#ccc" />
                                        </g>
                                    </svg>
                                </figure>
                                <h5>Upload insurance document</h5>
                                <input type="file" id="file-input" class="upload_file">
                            </div>
                        </div>
                        <div class="driverap_btn">
                            <button (click)="goTowelcome()">Next</button>
                            <!-- <button (click)="goTowelcome()"
                                [disabled]="!selected1 || !selected2 || !selected3">Next</button> -->

                        </div>
                        <div class="driverap_btn inactive">
                            <button (click)="continue('1')">Add More car</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  message;
  errorType;
  modal: string = 'none';

  constructor() { }

  
}

export class Constant {
    BOOKING_STATUS: any = {
        PENDING: 1,
        ACCEPTED: 2,
        COMPLETED: 3,
        ARRIVED: 4,
        STARTED: 6,
        ONGOING: 7,
        CANCELED: 8,
        OUT_FOR_DELIVERY: 9,
        PACKAGES_PICKED: 10,
        TRANSIT: 11,
        PARTIAL: 12,
        UPCOMING: 13,
        CANCELLED: 14,
        SCHEDULED: 15,
        ARRIVED_AT_PICKED: 16,
        PICKED: 17,
        ARRIVED_AT_DROPED: 19,
        DROPED: 20
    };
    SHOW_STATUS_BOOKING: any = {
        1: 'PENDING',
        2: 'ACCEPTED',
        3: 'COMPLETED',
        4: 'ARRIVED',
        6: 'STARTED',
        7: 'ONGOING',
        8: 'CANCELED',
        9: 'OUT_FOR_DELIVERY',
        10: 'PACKAGES_PICKED',
        11: 'TRANSIT',
        12: 'PARTIAL',
        13: 'UPCOMING',
        14: 'CANCELLED',
        15: 'SCHEDULED',
        16: 'ARRIVED_AT_PICKED',
        17: 'PICKED',
        19: 'ARRIVED_AT_DROPED',
        20: 'DROPED'
    };
    BOOKING_TYPE: any = {
        RIDE: 1,
        DELIVERY: 2,
        DEFAULT: 0
    };
    SHOW_BOOKING_TYPE: any = {
        1: 'RIDE',
        2: 'DELIVERY',
        0: 'DEFAULT'
    };
    PAYMENT_MODE: any = {
        CASH: 1,
        CARD: 2,
        WALLET: 3
    };
    PAYMENT_STATUS: any = {
        PENDING: 1,
        COMPLETED: 2
    };
    MSGS: any = {
        CONGRATS: 'Congratulations! We have not yet launched. This is a beta version and we look forward to your feedback. We will let you know when we will launch in your city.',
        PRELAUNCH_RIDE: 'Fasten your seat belts, plan your rides, we will be launching soon!  This is a pre-launch website with basic functionality, but we will be launching soon!',
        PRELAUNCH_DELIVERY: 'Fasten your seat belts, prepare your orders, we will be launching soon!  This is a pre-launch website with basic functionality, but we will be launching soon!',
        EMAIL_EXIST: 'The specified email address already exists. Please sign up with another one or try to log in.',
        PRELAUNCH_DRIVER: 'Fasten your seat belts, we will be launching soon!  This is a pre-launch website with basic functionality.',
        PHONE_EXIST: 'The specified mobile number already exists. Please sign up with another one or try to log in.'
    }
}
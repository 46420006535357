import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { MaterialModule } from "./material/material.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LoginComponent } from "./modules/prelaunch/login/login.component";
import { SignupComponent } from "./modules/prelaunch/signup/signup.component";
import { VerificationComponent } from "./modules/prelaunch/verification/verification.component";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { NgOtpInputModule } from "ng-otp-input";
import { VechicleComponent } from "./modules/prelaunch/vechicle/vechicle.component";
import { WelcomeComponent } from "./modules/prelaunch/welcome/welcome.component";
import { RiderequestComponent } from "./modules/prelaunch/riderequest/riderequest.component";
import { AgmCoreModule } from "@agm/core";
import { ProfilesetupComponent } from "./modules/prelaunch/profilesetup/profilesetup.component";
import { SidebarModule } from "ng-sidebar";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { ForgetpasswordComponent } from "./modules/prelaunch/forgetpassword/forgetpassword.component";
import { UhomepageComponent } from "./modules/prelaunch/uhomepage/uhomepage.component";
import { UnavbarComponent } from "./modules/prelaunch/unavbar/unavbar.component";
import { UdriverComponent } from "./modules/prelaunch/udriver/udriver.component";
import { UfooterComponent } from "./modules/prelaunch/ufooter/ufooter.component";
import { UriderComponent } from "./modules/prelaunch/urider/urider.component";
import { UdeliverypartnersComponent } from "./modules/prelaunch/udeliverypartners/udeliverypartners.component";
import { UbusinesspartnersComponent } from "./modules/prelaunch/ubusinesspartners/ubusinesspartners.component";
import { RiderloginComponent } from "./modules/prelaunch/riderlogin/riderlogin.component";
import { RidersignupComponent } from "./modules/prelaunch/ridersignup/ridersignup.component";
import { RequestacceptComponent } from "./modules/prelaunch/requestaccept/requestaccept.component";
import { RiderdetailComponent } from "./modules/prelaunch/riderdetail/riderdetail.component";
import { RidercongratulationsComponent } from "./modules/prelaunch/ridercongratulations/ridercongratulations.component";
import { RiderotppageComponent } from "./modules/prelaunch/riderotppage/riderotppage.component";
import { RiderdeliverybookingComponent } from "./modules/prelaunch/riderdeliverybooking/riderdeliverybooking.component";
import { apiList, apiListUser } from "./modules/prelaunch/services/https/api-list";
import { HttpInterceptorService } from "./modules/prelaunch/services/interceptor/http-interceptor.service";
import { CurrencyPipe, DatePipe } from "@angular/common";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { AgmDirectionModule } from "agm-direction";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { MatDialogRef } from "@angular/material/dialog";
import { RideremailComponent } from "./modules/prelaunch/rideremail/rideremail.component";
import { VerifyemailComponent } from "./modules/prelaunch/verifyemail/verifyemail.component";
import { MatExpansionModule } from "@angular/material/expansion";

import { DownloadApplicationComponent } from "./modules/prelaunch/common-modals/download-application/download-application.component";
import { AgmOverlays } from "agm-overlays";
import { TrackingComponent } from "./modules/prelaunch/tracking/tracking.component";
import { Constant } from "./modules/prelaunch/core/constant";
import { fConstant } from "./modules/functional/core/constant";
// import { PreRegisterComponent } from './pre-register/pre-register.component';
import { SlickCarouselModule } from "ngx-slick-carousel";
import { PreRegisterComponent } from "./modules/prelaunch/pre-register/pre-register.component";
import { CustomerServiceComponent } from "./modules/prelaunch/customer-service/customer-service.component";
import { PolicyComponent } from "./modules/prelaunch/policy/policy.component";
import { SocialLoginModule, SocialAuthServiceConfig, FacebookLoginProvider } from "angularx-social-login";
// import { AppleSigninModule } from 'ngx-apple-signin';
import { TermsOfServiceComponent } from "./modules/prelaunch/terms-of-service/terms-of-service.component";
import { NotificationModalComponent } from "./modules/prelaunch/common-modals/notification-modal/notification-modal.component";
import { NumberModalComponent } from "./modules/prelaunch/common-modals/number-modal/number-modal.component";
import { HttpsService } from "./modules/prelaunch/services/https/https.service";
import { CommonService } from "./modules/prelaunch/services/common/common.service";
import { SocketioService } from "./modules/prelaunch/services/sockets/socketio.service";
////////////////////////////////////////////////////////////////////////
import { UhomepageFComponent } from "./modules/functional/uhomepage/uhomepage.component";
import { UnavbarFComponent } from "./modules/functional/unavbar/unavbar.component";
import { LoginFComponent } from "./modules/functional/login/login.component";
import { SignupFComponent } from "./modules/functional/signup/signup.component";
import { VerificationFComponent } from "./modules/functional/verification/verification.component";
import { ProfileFComponent } from "./modules/functional/profile/profile.component";
import { VechicleFComponent } from "./modules/functional/vechicle/vechicle.component";
import { WelcomeFComponent } from "./modules/functional/welcome/welcome.component";
import { RiderequestFComponent } from "./modules/functional/riderequest/riderequest.component";
import { TrackingFComponent } from "./modules/functional/tracking/tracking.component";
import { ProfilesetupFComponent } from "./modules/functional/profilesetup/profilesetup.component";
import { ForgetpasswordFComponent } from "./modules/functional/forgetpassword/forgetpassword.component";
import { UdriverFComponent } from "./modules/functional/udriver/udriver.component";
import { UriderFComponent } from "./modules/functional/urider/urider.component";
import { UdeliverypartnersFComponent } from "./modules/functional/udeliverypartners/udeliverypartners.component";
import { UbusinesspartnersFComponent } from "./modules/functional/ubusinesspartners/ubusinesspartners.component";
import { RiderloginFComponent } from "./modules/functional/riderlogin/riderlogin.component";
import { RidersignupFComponent } from "./modules/functional/ridersignup/ridersignup.component";
import { RequestacceptFComponent } from "./modules/functional/requestaccept/requestaccept.component";
import { RiderdetailFComponent } from "./modules/functional/riderdetail/riderdetail.component";
import { RidercongratulationsFComponent } from "./modules/functional/ridercongratulations/ridercongratulations.component";
import { RiderotppageFComponent } from "./modules/functional/riderotppage/riderotppage.component";
import { RiderdeliverybookingFComponent } from "./modules/functional/riderdeliverybooking/riderdeliverybooking.component";
import { RideremailFComponent } from "./modules/functional/rideremail/rideremail.component";
import { VerifyemailFComponent } from "./modules/functional/verifyemail/verifyemail.component";
import { apiFList, apiFListUser } from "./modules/functional/services/https/api-list";
import { CommonModule } from "@angular/common";
import { environment } from "src/environments/environment";
import { ParentService } from "./services/parent/parent.service";
import { Router, Routes } from "@angular/router";
import { NotificationModalFComponent } from "./modules/functional/common-modals/notification-modal/notification-modal.component";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { PdfViewerComponent } from "ng2-pdf-viewer";
import { PaymentComponent } from "./modules/functional/payment/payment.component";
import { NgxDocViewerModule } from "ngx-doc-viewer";
// import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { RichTextEditorAllModule, RichTextEditorModule } from "@syncfusion/ej2-angular-richtexteditor";
import { TextMaskModule } from "angular2-text-mask";
import { NgxPayPalModule } from "ngx-paypal";
import { UfooterFComponent } from "./modules/functional/ufooter/ufooter.component";
import { AdvertiseComponent } from "./modules/functional/common-modals/advertise/advertise.component";
import { NgxBarcodeModule } from "ngx-barcode";
// import { AngularFireMessagingModule } from '@angular/fire/messaging';
// import { AngularFireDatabaseModule } from '@angular/fire/database';
// import { AngularFireAuthModule } from '@angular/fire/auth';
// import { AngularFireModule } from '@angular/fire';
import { MessagingServiceService } from "./services/messaging-service.service";
import { TranslateModule, TranslatePipe } from "@ngx-translate/core";

// import ngx-translate and the http loader
import { TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AllJSONFile } from "./core/all-json-file";
import { ServiceWorkerModule, SwUpdate } from "@angular/service-worker";
import { NgxFreshdeskWebwidgetConfig, NgxFreshdeskWebwidgetModule } from "ngx-freshdesk-webwidget";
import { ShareModule } from "ngx-sharebuttons";
import { ShareButtonsModule } from "ngx-sharebuttons/buttons";

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
export class FreshdeskConfig extends NgxFreshdeskWebwidgetConfig {
  widgetId = 69000002396;
  locale = "en";
  callback(FreshworksWidget) {
    FreshworksWidget("hide");
  }
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    VerificationComponent,
    VechicleComponent,
    WelcomeComponent,
    RiderequestComponent,
    ProfilesetupComponent,
    ForgetpasswordComponent,
    UhomepageComponent,
    UnavbarComponent,
    UdriverComponent,
    UfooterComponent,
    UriderComponent,
    UdeliverypartnersComponent,
    UbusinesspartnersComponent,
    RiderloginComponent,
    RidersignupComponent,
    RequestacceptComponent,
    RiderdetailComponent,
    RidercongratulationsComponent,
    RiderotppageComponent,
    RiderdeliverybookingComponent,
    RideremailComponent,
    VerifyemailComponent,
    DownloadApplicationComponent,
    TrackingComponent,
    PreRegisterComponent,
    CustomerServiceComponent,
    PolicyComponent,
    TermsOfServiceComponent,
    // NotificationModalComponent,
    NumberModalComponent,
    //////////////////
    LoginFComponent,
    SignupFComponent,
    VerificationFComponent,
    VechicleFComponent,
    WelcomeFComponent,
    RiderequestFComponent,
    ProfilesetupFComponent,
    ForgetpasswordFComponent,
    UhomepageFComponent,
    UnavbarFComponent,
    UdriverFComponent,
    UfooterFComponent,
    UriderFComponent,
    UdeliverypartnersFComponent,
    UbusinesspartnersFComponent,
    RiderloginFComponent,
    RidersignupFComponent,
    RequestacceptFComponent,
    RiderdetailFComponent,
    RidercongratulationsFComponent,
    RiderotppageFComponent,
    RiderdeliverybookingFComponent,
    RideremailFComponent,
    VerifyemailFComponent,
    DownloadApplicationComponent,
    TrackingFComponent,
    CustomerServiceComponent,
    PolicyComponent,
    TermsOfServiceComponent,
    // NotificationModalFComponent,
    NumberModalComponent,
    ProfileFComponent,
    PaymentComponent,
    AdvertiseComponent,
  ],
  entryComponents: [RidercongratulationsComponent, NotificationModalFComponent, NotificationModalComponent],
  imports: [
    BrowserModule,
    CommonModule,
    SlickCarouselModule,
    NgOtpInputModule,
    MaterialModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    HttpClientModule,
    FormsModule,
    SidebarModule,
    MatExpansionModule,
    NgxMaterialTimepickerModule,
    AgmCoreModule.forRoot({
      apiKey: environment.apiKey,
      libraries: ["drawing", "places", "geometry"],
    }),
    GooglePlaceModule,
    AgmDirectionModule,
    NgxIntlTelInputModule,
    BsDropdownModule,
    NgxUiLoaderModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AgmOverlays,
    SocialLoginModule,
    PdfViewerModule,
    NgxDocViewerModule,
    RichTextEditorAllModule,
    TextMaskModule,
    NgxPayPalModule,
    NgxBarcodeModule,
    // AngularFireDatabaseModule,
    // AngularFireAuthModule,
    // AngularFireMessagingModule,
    // AngularFireModule.initializeApp(environment.firebaseConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register("/ngsw-worker.js", { scope: "/firebase-cloud-messaging-push-scope" }),
    ShareModule,
    ShareButtonsModule,
    // ShareIconsModule
  ],
  exports: [TranslateModule],
  providers: [
    CurrencyPipe,
    SwUpdate,
    MessagingServiceService,
    {
      provide: MatDialogRef,
      useValue: {},
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "fill" },
    },
    apiList,
    apiFList,
    apiFListUser,
    apiListUser,
    DatePipe,
    Constant,
    fConstant,
    AllJSONFile,
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            // provider: new FacebookLoginProvider('678379329643360')
            provider: new FacebookLoginProvider("794354501390217"),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

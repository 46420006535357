import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-vechicle',
  templateUrl: './vechicle.component.html',
  styleUrls: ['./vechicle.component.scss']
})
export class VechicleComponent implements OnInit {
  selected3 = '';
  selected1 = '';
  selected2 = '';
  nextPopup = 0;
  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  goTowelcome() {
    this.router.navigate(['welcome']);

  };
  goTohome() {
    this.router.navigate(['/home']);

  };
  continue(popupType) {
    this.nextPopup = popupType;
  }
}

import { Injectable } from "@angular/core";
import Swal from "sweetalert2";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { HttpsService } from "../https/https.service";
// import swal from 'sweetalert2';
import { apiFList } from "../https/api-list";
import { BehaviorSubject, Observable } from "rxjs";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
// import { RidercongratulationsComponent } from 'src/app/modules/prelaunch/ridercongratulations/ridercongratulations.component';
import { Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { NumberModalComponent } from "../../../prelaunch/common-modals/number-modal/number-modal.component";
import { NotificationModalFComponent } from "../../common-modals/notification-modal/notification-modal.component";
import { RidercongratulationsFComponent } from "../../ridercongratulations/ridercongratulations.component";
import { Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "src/app/services/language/language.service";
import { NotificationService } from "src/app/services/notification/notification.service";
import { ParentService } from "src/app/services/parent/parent.service";
import { fConstant } from "src/app/modules/functional/core/constant";
import { CurrencyPipe } from "@angular/common";

// import { LocalStorageService } from 'angular-web-storage';
interface verificationDetails {
  countryCode: string;
  phoneNo: string;
  otpCode: string;
}
interface userProfileAccessDetail {
  isFilledBankDetail: boolean;
  isDriverInfo: boolean;
  isVehicleInfo: boolean;
  isRegularCompliance: boolean;
  isNewUser: boolean;
}
interface riderProfileAccessDetail {
  isAddressInfo: boolean;
  isNewUser: boolean;
  isAccountCompleted: boolean;
}
interface cancelTimer {
  isActive: boolean;
  time: number;
}

@Injectable({
  providedIn: "root",
})
export class CommonService {
  zoneData = [];
  geofenceData = [];
  selectedZoneID: string;
  selectedGeofenceId: string;
  backToListUrl = "/taxi/admin";
  private riderProfileAccessDetail$: BehaviorSubject<riderProfileAccessDetail> = new BehaviorSubject(null);
  private userProfileAccessDetail$: BehaviorSubject<userProfileAccessDetail> = new BehaviorSubject(null);
  private verificationDetail$: BehaviorSubject<verificationDetails> = new BehaviorSubject(null);
  emailForVerification: string = "";
  bookingResponseData: any;
  routerScroll: Subject<boolean> = new Subject();
  goToDownloadSection: BehaviorSubject<boolean> = new BehaviorSubject(false);
  getDownloadSection = this.goToDownloadSection.asObservable();
  driverType: any;
  isThisDeliveryPartner: boolean = false;
  isThisPreUser: boolean = false;
  isThisUpdatedUser: boolean = false;
  userUpdateForm: any;
  userUpdateImage: any;
  loggedInUser: any;
  linkArr: { name: string; isActive: boolean }[];
  origin: { lat: number; lng: number; address: any };
  destination: { lat: number; lng: number; address: any };
  isPhoneVerified: boolean = false;
  isEamilVerified: boolean = false;
  isUserRecovered: boolean = false;
  phoneNoForVerification: any;
  countryCodeForVerification: any;
  alertMessage: any = "";
  isThisCongrats: boolean = false;
  notificationText: any;
  notificationType: any;
  socialLogiNumber$: BehaviorSubject<{ phoneNo: any; countryCode: any }> = new BehaviorSubject({ phoneNo: "", countryCode: "" });
  getSocialLoginNumber = this.socialLogiNumber$.asObservable();
  langChange: Subject<boolean> = new Subject();
  isLangChange = this.langChange.asObservable();
  allLanguages = [];
  selectedLang: any;
  dialogref: MatDialogRef<NotificationModalFComponent, any>;
  cancelationTimerStart: Subject<cancelTimer> = new Subject();
  cancelCounter = this.constant.CANCELLATION_TIME.SECONDS;
  intervalSubscription: any;
  bookingDataForLanguageUpdateOnly: any;

  constructor(
    private https: HttpsService,
    private apiList: apiFList,
    public matDialog: MatDialog,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private translate: TranslateService,
    private language: LanguageService,
    private notification: NotificationService,
    private parent: ParentService,
    private constant: fConstant,
    private currencyPipe: CurrencyPipe
  ) {
    this.language.isLangChange.subscribe((isChange: boolean) => {
      if (isChange) {
        this.getChange();
      }
    });
    this.cancelationTimerStart.subscribe((data: any) => {
      if (data.isActive) {
        if (localStorage.isCancelCounterStopped !== undefined) {
          if (localStorage.isCancelCounterStopped === "true") {
            this.cancelCounter = 0;
          } else {
            this.startCounter(data.time);
            localStorage.removeItem("isCancelCounterStopped");
          }
        } else {
          this.startCounter(data.time);
        }
        if (data.time >= 0) {
          this.startCounter(data.time);
        }
      } else {
        this.cancelCounter = 0;
      }
    });
  }

  startCounter(time) {
    console.log("startCounter------->", time);
    this.cancelCounter = time;
    if (this.intervalSubscription) {
      clearInterval(this.intervalSubscription);
      this.cancelCounter = time;
    }
    this.intervalSubscription = setInterval(() => {
      if (this.cancelCounter <= 0) {
        localStorage.isCancelCounterStopped = true;
        clearInterval(this.intervalSubscription);
      }
      this.cancelCounter--;
    }, 1000);
  }

  getChange() {
    this.translate
      .get([
        "My Rides",
        "My Deliveries",
        "Loyalty Points",
        "Notification",
        "Manage Addresses",
        "Payment Options",
        "Change Password",
        "Favourite Drivers",
        "Logout",
        "Delete Account",
      ])
      .subscribe((translations) => {
        this.linkArr = [
          { name: translations["My Rides"], isActive: false },
          { name: translations["My Deliveries"], isActive: false },
          { name: translations["Loyalty Points"], isActive: false },
          { name: translations["Notification"], isActive: false },
          { name: translations["Manage Addresses"], isActive: false },
          { name: translations["Payment Options"], isActive: false },
          { name: translations["Change Password"], isActive: false },
          { name: translations["Favourite Drivers"], isActive: false },
          { name: translations["Logout"], isActive: false },
          { name: translations["Delete Account"], isActive: false },
        ];
      });
  }

  openCongratsModal(msg, type) {
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    // dialogConfig.id = "modal-component";
    // dialogConfig.height = "350px";
    // dialogConfig.width = "900px";
    this.alertMessage = msg;
    this.isThisCongrats = type;
    this.matDialog.open(RidercongratulationsFComponent);
  }

  OpenNotificationModal(type, position, msg) {
    // console.log(this.dialogref.getState())
    // this.dialogref && this.dialogref.close();
    this.notification.errorType = type;
    this.notification.message = msg;
    this.notificationText = msg;
    this.notificationType = type;
    this.notification.errorType = type;
    this.notification.modal = "block";
    this.notification.message = msg;
    // this.dialogref = this.matDialog.open(NotificationModalFComponent,{ data: { msg: msg, type: type } });
  }

  OpenNumberModal() {
    this.matDialog.open(NumberModalComponent);
  }

  getVerificationData() {
    return this.verificationDetail$.asObservable();
  }

  setVerificationData(details: verificationDetails) {
    this.verificationDetail$.next(details);
  }

  getUserProfileAccessDetail() {
    return this.userProfileAccessDetail$.asObservable();
  }

  setUserProfileAccessDetail(details: userProfileAccessDetail) {
    this.userProfileAccessDetail$.next(details);
  }

  getRiderProfileAccessDetail() {
    return this.riderProfileAccessDetail$.asObservable();
  }

  setRiderProfileAccessDetail(details: riderProfileAccessDetail) {
    this.riderProfileAccessDetail$.next(details);
  }

  presentsToast(type: any, position: any, message: any) {
    const Toast = Swal.mixin({
      toast: true,
      position: position,
      timerProgressBar: true,
      showCancelButton: false,
      showCloseButton: true,
      showConfirmButton: false,
      timer: 3000,
    });
    Toast.fire({
      title: message,
      icon: type,
    });
  }

  confirmToast() {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  }

  driverAlert() {
    Swal.fire({
      title: "We are not providing service in your area... please contact to admin",
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Okay",
    }).then((result) => {
      if (result.value) {
        this.router.navigate(["/udriver"]);
      }
    });
  }

  preLaunchAlert(meg) {
    this.openCongratsModal(meg, false);
  }

  getZoneData() {
    this.zoneData = [];
    let params = new HttpParams();
    this.https.httpGetWithHeader("getAllZone", params).subscribe((res) => {
      var response = JSON.parse(JSON.stringify(res));
      this.zoneData = response.data.zoneData;
      if (response.data.zoneData.length > 0) {
        this.getGeofenceData(this.zoneData[0].id);
      }
    });
  }

  getGeofenceData(zoneId: string) {
    if (!zoneId) {
      return;
    }
    this.geofenceData = [];
    let params = new HttpParams().set("zoneId", zoneId);
    this.https.httpGetWithHeader("getGeofence", params).subscribe((res2) => {
      var response2 = JSON.parse(JSON.stringify(res2));
      if (response2.data.geofenceData.length == 0) {
        this.geofenceData.push({ geofenceName: "No Geofencing available select another zone", id: null });
      } else {
        this.geofenceData = response2.data.geofenceData;
      }
    });
  }

  // addToLocalStorageStringFied(key: Key, payload: any): void {
  //   this.localStorage.set(key, JSON.stringify(payload));
  // }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          console.log(resp);

          resolve({ lat: resp.coords.latitude, lng: resp.coords.longitude });
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  setScrollOnNavigation(data: boolean) {
    this.routerScroll.next(data);
  }

  getScrollId() {
    return this.routerScroll.asObservable();
  }

  goToPlayStore() {
    window.open("https://play.google.com/store/apps", "_blank");
  }
  goToAppStore() {
    window.open("https://www.apple.com/in/app-store/", "_blank");
  }

  updateUser() {
    if (localStorage.userUpdateForm !== undefined) {
      this.userUpdateForm = JSON.parse(localStorage.userUpdateForm);
    }
    if (localStorage.userUpdateImage !== undefined) {
      this.userUpdateImage = localStorage.getItem("userUpdateImage");
    }
    var codeLength = this.userUpdateForm.phoneNo.dialCode.length;
    var formData = new FormData();
    if (this.userUpdateImage) {
      formData.append("image", this.userUpdateImage);
    }
    formData.append("firstName", this.userUpdateForm.firstName.trim());
    formData.append("lastName", this.userUpdateForm.lastName.trim());
    formData.append("email", this.userUpdateForm.email.toLowerCase());
    formData.append("phoneNo", this.userUpdateForm.phoneNo.e164Number.slice(codeLength));
    formData.append("countryCode", this.userUpdateForm.phoneNo.dialCode);
    this.https.httpPostWithFormDataHeader("updateUserProfile", formData).subscribe((res: any) => {
      if (res.statusCode === 200) {
        this.isThisUpdatedUser = false;
        this.getUser();
        this.OpenNotificationModal("success", "top-end", res.message);
      }
    });
  }

  getUser() {
    return new Promise((resolve, reject) => {
      this.ngxService.start();
      this.https.httpGetWithHeaderUser("userProfile", "").subscribe((res: any) => {
        if (res.statusCode === 200) {
          this.loggedInUser = res.data;
          this.userUpdateImage = res.data.image;
          if (res.data.role === "RIDER" || res.data.role === "DELIVERY_PARTNER") {
            this.isThisDeliveryPartner = res.data.role === "RIDER" ? false : true;
            this.linkArr = [
              { name: "My Rides", isActive: false },
              { name: "My Deliveries", isActive: false },
              { name: "Loyalty Points", isActive: false },
              { name: "Notification", isActive: false },
              { name: "Manage Addresses", isActive: false },
              { name: "Payment Options", isActive: false },
              { name: "Change Password", isActive: false },
              { name: "Favourite Drivers", isActive: false },
              { name: "Logout", isActive: false },
              { name: "Delete Account", isActive: false },
            ];
          }
          this.ngxService.stop();
          return resolve(true);
        }
      });
    });
  }

  accRecover() {
    Swal.fire({
      title: "Do you want to restore your previous account?",
      // text: "You won't be able to revert this!",
      icon: "info",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Restore",
      cancelButtonText: "Close",
      allowOutsideClick: false,
      showCancelButton: true,
    }).then((result) => {
      this.isUserRecovered = true;
      if (result.value) {
        var formData = new FormData();
        formData.append("isUserDeleted", "false");
        this.https.httpPostWithFormDataHeader("updateUserProfile", formData).subscribe((res1: any) => {
          if (res1.statusCode === 200) {
            if (res1.data.role === "RIDER") {
              this.router.navigate(["riderdetail"]);
            } else if (res1.data.role === "DELIVERY_PARTNER") {
              this.router.navigate(["riderequest"]);
            }
          }
        });
      } else {
        this.router.navigateByUrl("/riderlogin");
        this.localStorageCLear();
      }
    });
  }

  localStorageCLear() {
    if (localStorage.languageCode !== undefined) {
      var languageCode = localStorage.languageCode;
      localStorage.clear();
      localStorage.languageCode = languageCode;
    } else {
      localStorage.clear();
    }
  }

  currencyFormat(number) {
    // if (number == 0 || number || !number) {
    //   return "$0.00";
    // }
    // return new Intl.NumberFormat().format(number);
    // if (number == 0) {
    //   return "0.00";
    // } else {
    return this.currencyPipe.transform(number, "USD");
    // return number.toLocaleString("en-US");
    // }
  }
}

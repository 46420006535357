<!------start homapge page-->
<div class="riders_page">
  <app-unavbar></app-unavbar>
  <div class="header_padding_class">
    <section class="riders_first_sec riders_img_handle">
      <div class="conta_iner">
        <div class="riders_first_main">
          <div class="rider_firstsec_content">
            <div class="main_headings">
              <h2>{{ "We look forward to serving your needs" | translate }}</h2>
              <h3>
                {{ "Easy, comfortable, safe and reliable transportation at a good price. APPTUNIX™ unmatched solution" | translate }},
                {{ "security features and drivers’ screening process" | translate }}
                {{ "offers our riders an excellent" | translate }}
                {{ "and safe customer experience at a great value" | translate }}.
              </h3>
              <div class="two_btn">
                <button (click)="goToridersignup()">{{ "Sign up As Rider" | translate }}</button>
                <button class="onlyborder_fillbtn" (click)="goToriderlogin()">
                  {{ "Login As Rider" | translate }}
                </button>
              </div>
            </div>
          </div>
          <div class="rider_firstsec_img">
            <figure>
              <img src="assets/uimages/riders.png" alt="riders" />
            </figure>
          </div>
        </div>
      </div>
    </section>
    <div class="slider-img udriver">
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let slide of slides" class="slide">
          <h4 class="mainh">{{ slide.main }}</h4>
          <p>{{ slide.content }}</p>
          <figure><img src="{{ slide.img }}" alt="" width="100%" /></figure>
        </div>
      </ngx-slick-carousel>
    </div>

    <!-----start login form-->
    <section class="riders_login">
      <div class="conta_iner">
        <div class="riders_login_main">
          <div class="rider_img">
            <figure>
              <img src="assets/uimages/riders_login.png" alt="" />
            </figure>
          </div>
          <div class="riders_login_form">
            <form [formGroup]="bookingForm">
              <h3>{{ "Please Log In and request a delivery" | translate }}</h3>
              <div class="riders_pickupinput rider_bluedot">
                <label>{{ "Choose your pickup" | translate }}</label>
                <mat-form-field class="example-full-width">
                  <input
                    matInput
                    placeholder="Search for pick up location"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="off"
                    type="text"
                    ngx-google-places-autocomplete
                    #placesRef="ngx-places"
                    [options]="{
                      types: [],
                      componentRestrictions: { country: [currentCountry] }
                    }"
                    (keyup)="onKeyUp($event.target.value)"
                    (change)="pickupChange($event.target.value)"
                    (onAddressChange)="handlePickUpChnage($event)"
                    formControlName="ridePickUpAddress"
                  />
                </mat-form-field>
                <p style="color: red" *ngIf="rideSubmited === true && bookingForm.controls['ridePickUpAddress'].hasError('required')">
                  {{ "Pickup address is required" | translate }}.
                </p>
              </div>
              <div class="riders_pickupinput rider_reddot" *ngIf="currentCountry">
                <label>{{ "Choose your destination" | translate }}</label>
                <mat-form-field class="example-full-width">
                  <mat-label>{{ "Choose your Drop off" | translate }} </mat-label>
                  <input
                    matInput
                    placeholder="Search for drop off location"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="off"
                    type="text"
                    [options]="{
                      types: [],
                      componentRestrictions: { country: [currentCountry] }
                    }"
                    ngx-google-places-autocomplete
                    #placesRef="ngx-places"
                    (keyup)="onKeyUpDrop($event.target.value)"
                    (change)="dropupChange($event.target.value)"
                    (onAddressChange)="handleDropChnage($event)"
                    formControlName="rideDropAddress"
                  />
                </mat-form-field>
                <p style="color: red" *ngIf="rideSubmited === true && bookingForm.controls['rideDropAddress'].hasError('required')">
                  {{ "Drop off address is required" | translate }}.
                </p>
              </div>
              <div class="main_headings">
                <button (click)="goToriderequest()">{{ "Next" | translate }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <!---------start why ride section-->
    <section class="all_sec riders_whyride">
      <div class="conta_iner">
        <div class="riders_whyride_main">
          <div class="main_headings">
            <h2>{{ "Why ride with APPTUNIX™?" | translate }}</h2>
          </div>
          <!------why ride u2go feature list-->
          <div class="why_rideu2go_list">
            <div class="u2go_ridefeat_item">
              <div class="u2go_ridefeat_itemimg">
                <svg xmlns="http://www.w3.org/2000/svg" width="112.941" height="80" viewBox="0 0 112.941 80">
                  <path
                    id="Union_5"
                    data-name="Union 5"
                    d="M96.469,80a7.068,7.068,0,0,1-7.059-7.059V69.8c-10.21.478-22.607.788-32.941.788s-22.73-.311-32.94-.788v3.141A7.067,7.067,0,0,1,16.471,80H7.059A7.067,7.067,0,0,1,0,72.941V63.529a2.35,2.35,0,0,1,.914-1.862A66.123,66.123,0,0,1,.122,53.9l2.042.211c.287.019,29.4,2.359,54.306,2.359S110.489,54.131,110.9,54.1l1.92-.257a66,66,0,0,1-.795,7.824,2.351,2.351,0,0,1,.918,1.865v9.412A7.068,7.068,0,0,1,105.881,80ZM32.964,51.219c.062-1.84-.056-6.565-2.55-9.262A6.026,6.026,0,0,0,25.881,40C20.7,40,5.35,37.718,5.275,37.718L.626,36.556c.56-3.441,1.572-5.331,3.416-5.873,1.054-.306,1.605-1.074,2.65-2.636l.8-1.182c1.407-1.962,4.127-2.042,6.754-2.116a19.644,19.644,0,0,0,2.571-.185c.117-.274.272-.623.458-1.035H11.764a2.353,2.353,0,1,1,0-4.705h7.752c3.046-6.186,7.65-14.795,9.848-16.128C32.861.57,53.779,0,56.471,0S80.079.57,83.576,2.7c2.2,1.336,6.8,9.943,9.848,16.128h7.752a2.353,2.353,0,1,1,0,4.705H95.662c.186.41.34.758.457,1.031a19.624,19.624,0,0,0,2.567.184c2.631.075,5.351.159,6.758,2.121l.805,1.177c1.044,1.562,1.595,2.33,2.649,2.635,1.807.528,2.819,2.344,3.384,5.644l-6.735,1.349C101.073,38.32,88.786,40,85.741,40a4.525,4.525,0,0,0-3.346,1.469c-2.33,2.461-2.447,7.67-2.395,9.75-7.581.315-15.761.546-23.529.546S40.535,51.535,32.964,51.219Zm4.683-6.513A2.354,2.354,0,0,0,40,47.059H72.941a2.353,2.353,0,0,0,0-4.706H40A2.354,2.354,0,0,0,37.647,44.706ZM23.4,21.954c7.4-1.715,19.932-3.131,33.073-3.131S82.14,20.24,89.543,21.954a2.345,2.345,0,0,1-.131-.7c-2.783-5.577-7.054-13.454-8.4-14.618C79.19,5.8,64.531,4.705,56.471,4.705S33.75,5.8,31.76,6.747c-1.23,1.085-5.46,8.927-8.231,14.5A2.342,2.342,0,0,1,23.4,21.954ZM85.741,44.706c4,0,19.807-2.277,20.6-2.4l6.419-1.285c.127,2.273.175,4.937.179,8.056l-2.537.344c-.178.014-11.346.913-25.689,1.59C84.65,48.183,85.166,45.163,85.741,44.706ZM2.593,49.421,0,49.153c0-3.027.046-5.638.164-7.868l4.194,1.045c.649.094,15.972,2.377,21.524,2.377a1.317,1.317,0,0,1,1.073.442c1.115,1.205,1.342,4.165,1.294,5.863C13.906,50.33,2.744,49.435,2.593,49.421Z"
                    fill="#0171bd"
                  />
                </svg>
              </div>
              <h3>{{ "Best Rates And Great Drivers" | translate }}</h3>
            </div>
            <div class="u2go_ridefeat_item">
              <div class="u2go_ridefeat_itemimg">
                <svg id="stopwatch" xmlns="http://www.w3.org/2000/svg" width="75.445" height="80" viewBox="0 0 75.445 80">
                  <g id="Group_11669" data-name="Group 11669" transform="translate(0)">
                    <g id="Group_11668" data-name="Group 11668" transform="translate(0)">
                      <path
                        id="Path_24098"
                        data-name="Path 24098"
                        d="M80.632,60c.413-.867.787-1.76,1.12-2.667H75.9A1.337,1.337,0,0,1,74.566,56V49.333A1.337,1.337,0,0,1,75.9,48h7.893c.067-.867.107-1.76.107-2.667a34.45,34.45,0,0,0-7.226-21.16l2.453-2.44a2.688,2.688,0,0,0,0-3.787l-3.773-3.773a2.671,2.671,0,0,0-3.773,0l-2.653,2.653a34.338,34.338,0,0,0-15.692-5.933V8a4,4,0,0,0,0-8h-8a4,4,0,0,0,0,8v2.893a34.3,34.3,0,0,0-15.919,6.093L26.9,14.573a2.765,2.765,0,0,0-3.786,0l-3.773,3.76a2.688,2.688,0,0,0,0,3.787l2.28,2.28A34.656,34.656,0,0,0,72.78,70.773l-1.827-1.827a1.336,1.336,0,0,1-.28-1.453,1.317,1.317,0,0,1,1.227-.827h2.667V61.333A1.337,1.337,0,0,1,75.9,60h4.733Zm-59.4-39.773,3.773-3.76,2.147,2.147a35.948,35.948,0,0,0-3.826,3.707Zm5.333,26.44H21.236a1.333,1.333,0,0,1,0-2.667h5.333a1.333,1.333,0,0,1,0,2.667Zm46.89-30.6,3.773,3.773-2.28,2.267a33.532,33.532,0,0,0-3.866-3.667ZM43.9,4a1.337,1.337,0,0,1,1.333-1.333h8a1.333,1.333,0,0,1,0,2.667h-8A1.337,1.337,0,0,1,43.9,4Zm6.666,69.333a1.333,1.333,0,1,1-2.666,0V68a1.333,1.333,0,1,1,2.666,0ZM49.234,46.667A1.337,1.337,0,0,1,47.9,45.333v-28A1.337,1.337,0,0,1,49.234,16,29.367,29.367,0,0,1,78.566,45.333a1.337,1.337,0,0,1-1.333,1.333Z"
                        transform="translate(-14.579)"
                        fill="#0171bd"
                      />
                      <rect
                        id="Rectangle_3583"
                        data-name="Rectangle 3583"
                        width="10.675"
                        height="4.003"
                        transform="translate(62.648 50.665)"
                        fill="#0171bd"
                      />
                      <path
                        id="Path_24099"
                        data-name="Path 24099"
                        d="M416.149,407.739a1.338,1.338,0,0,1-1.334-1.334v-5.337H404.14V406.4a1.338,1.338,0,0,1-1.334,1.334h-.787l7.459,7.459,7.459-7.459Z"
                        transform="translate(-341.492 -338.414)"
                        fill="#0171bd"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <h3>{{ "Fast, Reliable And Controllable Service" | translate }}</h3>
            </div>
            <div class="u2go_ridefeat_item">
              <div class="u2go_ridefeat_itemimg">
                <svg id="surface1" xmlns="http://www.w3.org/2000/svg" width="68.125" height="80" viewBox="0 0 68.125 80">
                  <path
                    id="Path_24100"
                    data-name="Path 24100"
                    d="M135.684,158.457A15.243,15.243,0,1,0,150.927,173.7,15.26,15.26,0,0,0,135.684,158.457Zm9.05,12.372-10.92,10.92a2.347,2.347,0,0,1-3.32,0l-4.173-4.173a2.347,2.347,0,1,1,3.319-3.319l2.513,2.513,9.261-9.26a2.347,2.347,0,0,1,3.319,3.319Zm0,0"
                    transform="translate(-101.622 -133.698)"
                    fill="#0171bd"
                  />
                  <path
                    id="Path_24101"
                    data-name="Path 24101"
                    d="M68.046,21.705l0-.062c-.035-.768-.058-1.581-.072-2.486a8.479,8.479,0,0,0-7.983-8.306c-9.2-.513-16.314-3.513-22.393-9.438l-.052-.049a5.12,5.12,0,0,0-6.958,0l-.052.049c-6.08,5.926-13.2,8.925-22.393,9.439A8.478,8.478,0,0,0,.156,19.157c-.013.9-.037,1.711-.072,2.485l0,.145c-.179,9.379-.4,21.052,3.5,31.647a40.285,40.285,0,0,0,9.665,15.054A50.56,50.56,0,0,0,32.163,79.676a5.849,5.849,0,0,0,.775.211,5.727,5.727,0,0,0,2.251,0,5.853,5.853,0,0,0,.778-.212A50.591,50.591,0,0,0,54.861,68.482a40.345,40.345,0,0,0,9.665-15.058C68.446,42.8,68.224,31.1,68.046,21.705ZM34.062,59.937A19.937,19.937,0,1,1,54,40,19.959,19.959,0,0,1,34.062,59.937Zm0,0"
                    transform="translate(0 0.001)"
                    fill="#0171bd"
                  />
                </svg>
              </div>
              <h3>{{ "Safe And Secure" | translate }}</h3>
            </div>
            <div class="u2go_ridefeat_item">
              <div class="u2go_ridefeat_itemimg">
                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
                  <g id="Group_60749" data-name="Group 60749" transform="translate(16878 3987)">
                    <rect
                      id="Rectangle_18521"
                      data-name="Rectangle 18521"
                      width="80"
                      height="80"
                      rx="10"
                      transform="translate(-16878 -3987)"
                      fill="#0171bd"
                    />
                    <g id="pointer" transform="translate(-16864.631 -3972.63)">
                      <path
                        id="Path_79426"
                        data-name="Path 79426"
                        d="M43.776,28.808H32.039a4.816,4.816,0,0,0-9.124,0H14.339a3.409,3.409,0,1,1,0-6.818H43.41a3.467,3.467,0,0,0,2.531-1.1c3.115-3.352,6.828-8.073,6.828-11.53a9.36,9.36,0,0,0-18.719,0c0,2.816,2.464,6.471,5.062,9.542H14.336a6.5,6.5,0,1,0,0,13h8.577a4.816,4.816,0,0,0,9.124,0H43.776a5.9,5.9,0,0,1,0,11.8H38.652a4.816,4.816,0,0,0-9.124,0H14.882a7.519,7.519,0,1,0,0,3.092H29.528a4.816,4.816,0,0,0,9.124,0h5.124a8.994,8.994,0,0,0,0-17.987ZM43.41,3.092a6.274,6.274,0,0,1,6.268,6.265c0,1.831-2.3,5.442-6,9.425a.363.363,0,0,1-.533,0c-3.7-3.983-6-7.595-6-9.425A6.274,6.274,0,0,1,43.41,3.092ZM27.478,32.08A1.726,1.726,0,1,1,29.2,30.354,1.728,1.728,0,0,1,27.478,32.08ZM7.521,49.677a4.428,4.428,0,1,1,4.429-4.428A4.433,4.433,0,0,1,7.521,49.677Zm26.569-2.7a1.726,1.726,0,1,1,1.726-1.726A1.728,1.728,0,0,1,34.09,46.975Z"
                        fill="#fff"
                      />
                      <path
                        id="Path_79427"
                        data-name="Path 79427"
                        d="M409.269,78.231a1.546,1.546,0,1,0-1.546,1.546A1.573,1.573,0,0,0,409.269,78.231Z"
                        transform="translate(-364.313 -68.781)"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <h3>{{ "Multi Drop" | translate }}</h3>
            </div>
            <div class="u2go_ridefeat_item">
              <div class="u2go_ridefeat_itemimg">
                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
                  <g id="Group_60751" data-name="Group 60751" transform="translate(16805 3987)">
                    <g id="Group_60750" data-name="Group 60750" transform="translate(73)">
                      <rect
                        id="Rectangle_18521"
                        data-name="Rectangle 18521"
                        width="80"
                        height="80"
                        rx="40"
                        transform="translate(-16878 -3987)"
                        fill="#0171bd"
                      />
                    </g>
                    <g id="driving" transform="translate(-16859.494 -3977)">
                      <path
                        id="Path_79428"
                        data-name="Path 79428"
                        d="M238.515,387.981a2.344,2.344,0,1,0,2.344,2.344A2.346,2.346,0,0,0,238.515,387.981Z"
                        transform="translate(-144.227 -341.878)"
                        fill="#fff"
                      />
                      <path
                        id="Path_79429"
                        data-name="Path 79429"
                        d="M191.793,302.55a12.45,12.45,0,0,0-8.644,3.482l3.015.863-2.074,6.368h2.071a5.908,5.908,0,0,1,11.266,0H199.5l-2.074-6.368,3.016-.863A12.452,12.452,0,0,0,191.793,302.55Z"
                        transform="translate(-97.505 -266.599)"
                        fill="#fff"
                      />
                      <path
                        id="Path_79430"
                        data-name="Path 79430"
                        d="M163.286,422.7h-3.232l-.926,2.842-1.938-.318a12.532,12.532,0,0,0,9.947,8.061v-6.735A5.935,5.935,0,0,1,163.286,422.7Z"
                        transform="translate(-74.631 -372.475)"
                        fill="#fff"
                      />
                      <path
                        id="Path_79431"
                        data-name="Path 79431"
                        d="M115.789,210.172l-5.012-12.4a4.719,4.719,0,0,0-4.394-2.963H82.194a4.719,4.719,0,0,0-4.394,2.963l-5.012,12.4a3.983,3.983,0,0,0,1.127,4.547l6.869,5.795,1.271.208,2.069-6.352-1.849-.529-2.216.061-.1-3.563,1.625-.045a16.039,16.039,0,0,1,25.4,0l1.627.045-.1,3.563-2.216-.061-1.849.529,2.069,6.352,1.271-.208,6.869-5.795A3.982,3.982,0,0,0,115.789,210.172Z"
                        transform="translate(0 -171.663)"
                        fill="#fff"
                      />
                      <path
                        id="Path_79432"
                        data-name="Path 79432"
                        d="M277.978,422.7h-3.232a5.934,5.934,0,0,1-3.851,3.851v6.735a12.532,12.532,0,0,0,9.946-8.061l-1.937.318Z"
                        transform="translate(-174.825 -372.475)"
                        fill="#fff"
                      />
                      <circle
                        id="Ellipse_1191"
                        data-name="Ellipse 1191"
                        cx="10.683"
                        cy="10.683"
                        r="10.683"
                        transform="translate(83.605 0)"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <h3>{{ "Preferred Driver" | translate }}</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-----start our app section-->
    <section class="our_app">
      <div class="conta_iner">
        <div class="our_app_main">
          <div class="our_app_screen">
            <figure>
              <img src="assets/uimages/app_screen.png" alt="" />
            </figure>
          </div>
          <div class="our_app_content">
            <h2>
              {{ "Download our Apps and start experiencing a new world of possibilities" | translate }}
            </h2>
            <div class="app_downloadlink">
              <a style="cursor: pointer" (click)="commonService?.preLaunchAlert(constant?.MSGS?.PRELAUNCH_RIDE)">
                <img src="assets/uimages/google_store.png" alt="" />
              </a>
              <a style="cursor: pointer" (click)="commonService?.preLaunchAlert(constant?.MSGS?.PRELAUNCH_RIDE)">
                <img src="assets/uimages/app_store.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <app-ufooter></app-ufooter>
  </div>
</div>

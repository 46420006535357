import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  langChange: Subject<boolean> = new Subject();
  isLangChange = this.langChange.asObservable();
  allLanguages: any = [];
  selectedLang: any;

  constructor(private translate: TranslateService) {
    if (this.allLanguages.length > 0) {
      this.selectedLang = this.allLanguages[0];
      let currentLang = this.allLanguages.length > 0 ? this.allLanguages[0].code : "en";
      let langCodes = this.allLanguages.length > 0 ? this.allLanguages.map((val) => val.code) : ["en"];
      translate.addLangs(langCodes);
      translate.setDefaultLang(currentLang);
      translate.use(currentLang);
    }

    // localStorage.languageCode = currentLang;
  }

  translateLanguage(code) {
    this.translate.use(code);
    this.langChange.next(true);
    localStorage.languageCode = code;
  }
}

import { Injectable } from '@angular/core';
import * as socketIo from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { Event } from '../../core/models/event';
import { Action } from '../../core/models/action';

@Injectable({
  providedIn: 'root'
})
export class SocketioService {
  socket: any;
  websiteConnect: Subject<boolean> = new Subject<boolean>();
  isWebsiteConnect = this.websiteConnect.asObservable();

  constructor() { }

  initSocket() {
    // this.socket = socketIo(environment.socketUrl);
    this.socket = socketIo.connect(environment.socketUrl, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 3000,
      reconnectionAttempts: Infinity
    });
    this.socket.on('connect', (res: any) => {
      console.log('debug connect', res);
      if (localStorage.length > 0) {
        const userId = localStorage.getItem('userId');
        if (userId != undefined || userId != null) {
          const data = {
            userId: userId
          }
          this.emitAction(Action.onlineUser, data);
          this.emitAction('unreadNotificationCount',{type :"USER",userId: userId });
        }
      }
    });

    this.socket.on('disconnect', (message: any) => {
      console.log('debug disconnect', message);
    });
  }

  initAdSocket(lat, lng, timezone) {
    this.socket = socketIo.connect(environment.socketUrl, {
      // reconnection: true,
      // reconnectionDelay: 1000,
      // reconnectionDelayMax: 3000,
      // reconnectionAttempts: Infinity
    });
    this.socket.on('connect', (res: any) => {
      
      const data = {
        longitude: lng,
        latitude: lat,
        timezone: timezone
      }
      this.emitAction('website', data);
      console.log('website connect');
      this.websiteConnect.next(true);
    });
    this.socket.on('disconnect', (message: any) => {
      console.log('website disconnect', message);
    });
  }

  emitAction(action: any, payload: any): void {
    console.log('check')
    this.socket.emit(action, payload)
  }

  public onEvent(event: any): Observable<any> {
    console.log(event);

    return new Observable<any>(observer => {
      this.socket.on(event, (data) => observer.next(data));
    });
  }

  close() {
    this.socket.close();
  }
}

<div class="login_wrap top">
    <div class="signupinner">
        <div class="conta_iner">
            <div class="flex">
                <div class="haeder_left">
                    <figure class="logo_cm" style="cursor: pointer;" (click)="goTouhomepage()">
                        <img src="assets/uimages/logo.png" />
                    </figure>
                </div>
                <div class="multiple_content">
                    <!-- <div class="language-select"> -->
                        <div class="lm">
                            <div class="language-select" (click)="showLang = showLang?false:true">
                               <div class="sel-bar" *ngIf="languageService?.selectedLang">
                                   <figure><img [src]="baseUrl+languageService?.selectedLang?.logo">{{languageService?.selectedLang?.code?.toUpperCase()}}</figure>
                               </div>
                                <p *ngIf="!languageService?.selectedLang">Select Language</p>
                                <div class="ndpicon">
                                    <i *ngIf="!showLang" class="fa fa-caret-down" aria-hidden="true"></i>
                                    <i *ngIf="showLang" class="fa fa-caret-up" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="dp-lang" *ngIf="showLang">
                                <figure *ngFor="let language of languageService?.allLanguages" (click)="selectLang(language)"><img
                                        [src]="baseUrl+language?.logo">{{language?.code}}
                                </figure>
                            </div>
                        </div>
                    <!-- </div> -->
                    <div class="back_home">
                        <button class="driverap_btn" style="cursor: pointer;" (click)="goTouhomepage()">
                            {{'Back To Home' | translate}}
                        </button>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <div class="login_sec">
        <div class="conta_iner">
            <div class="login_outer_cls">
                <div class="otp_img">
                    <figure>
                        <img src="assets/uimages/rider_otp.svg" alt="" />
                    </figure>
                </div>
                <!-----end otp img class-->
                <!----start text -->
                <div class="login_content_sec">
                    <h3 class="verify_number">{{'Verifying Your Phone Number' | translate}}!</h3>
                    <p class="user_num">
                        {{'Please enter the verification code sent to' | translate}}
                        <span>{{code}} {{number}}</span>
                    </p>
                    <div class="rider_otp">
                        <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="{ length: 4 }">
                        </ng-otp-input>
                    </div>
                    <p style="color: red;" *ngIf="otpInput.length === 0">Please enter OTP.</p>
                    <p style="color: red;" *ngIf="otpInput.length > 0 && otpInput.length < 4">{{'Please enter valid OTP' | translate}}.</p>
                    <!-- <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{ length: 5 }"></ng-otp-input> -->
                    <div class="commen_btn_outer">
                        <button class="btn btn-primary" (click)="verifyOtp()">
              {{'Verify' | translate}}
            </button>
                    </div>
                    <p class="resend_opt">
                        {{"Didn't Receive SMS?" | translate}}<a (click)="resend()" class="lnkk"> {{'Resend Code' | translate}}</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpsService } from '../services/https/https.service';
import { PdfViewerComponent } from "ng2-pdf-viewer";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, QuickToolbarService } from '@syncfusion/ej2-angular-richtexteditor';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language/language.service';
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, QuickToolbarService]
})
export class PolicyComponent implements OnInit {
  cmsType: string;
  cms: string;
  cmsData: string = '';
  latitude: number = 0;
  longitude: number = 0;
  zoneId: any;
  geofenceId: any;
  isThisPdf: boolean = false;
  baseUrl = environment.imageUrl;
  docUrl = environment.docurl;
  isThisCms: boolean;
  pdfData: any;
  languageCode: string;
  showLang: boolean = false;
  // public Editor = ClassicEditor;

  constructor(private route: ActivatedRoute,
    private http: HttpClient,
    private https: HttpsService,
    private ngxService: NgxUiLoaderService,
    private router: Router,
    public translate: TranslateService,
    public languageService: LanguageService) {
    this.cmsType = this.route.snapshot.paramMap.get('type');
    if (localStorage.languageCode !== undefined) {
      this.languageCode = localStorage.getItem('languageCode');
    } else {
      this.languageCode = 'en';
    }
    this.translate.use(this.languageCode);
  }

  ngOnInit(): void {
    this.getCurrentLocation();
  }

  selectLang(language) {
    this.languageService.selectedLang = language;
    this.languageService.translateLanguage(language.code);
    this.showLang = false;
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.getZone();
      })
      setTimeout(() => {
        if(this.latitude == 0 && this.longitude == 0) {
          this.getCmsData();
        }
      }, 1000);
    }
  }

  getZone() {
    if (this.latitude !== 0 && this.longitude !== 0) {
      this.ngxService.start();
      this.http.post(environment.baseUrl + 'user/checkPrelaunchLocation', { latitude: this.latitude, longitude: this.longitude }).subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.ngxService.stop();
          this.zoneId = res.data.geofenceData.zoneId;
          this.geofenceId = res.data.geofenceData._id;
          var param = { zoneId: this.zoneId, geofenceId: this.geofenceId };
          this.getHelpCenter(param)
        }
      }, err => this.ngxService.stop());
    } else {
      this.getCmsData();
    }
  }

  getHelpCenter(param) {
    this.ngxService.start();
    this.https.httpPostUser('getHelpCenter', param).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.ngxService.stop();
        if (this.cmsType === 'privacy-policy' && (res.data[0].privacyPolicy || res.data[0].privacyPolicyLink)) {
          this.cmsData = res.data[0].privacyPolicy;
          this.pdfData = res.data[0].privacyPolicyLink;
        } else if (this.cmsType === 'contact-support' && (res.data[0].contactSupport || res.data[0].contactSupportLink)) {
          this.isThisCms = true;
          this.cmsData = res.data[0].contactSupport;
          this.pdfData = res.data[0].contactSupportLink;
        } else if (this.cmsType === 'legal' && (res.data[0].legal || res.data[0].legalLink)) {
          this.isThisCms = true;
          this.cmsData = res.data[0].legal;
          this.pdfData = res.data[0].legalLink;
        } else if (this.cmsType === 'FAQs' && (res.data[0].faqs || res.data[0].faqsLink)) {
          this.isThisCms = true;
          this.cmsData = res.data[0].faqs;
          this.pdfData = res.data[0].faqsLink;
        } else if (this.cmsType === 'terms-of-service' && (res.data[0].termsOfService || res.data[0].termsOfServiceLink)) {
          this.isThisCms = true;
          this.cmsData = res.data[0].termsOfService;
          this.pdfData = res.data[0].termsOfServiceLink;
        } else if (this.cmsType === 'rider-contracts' && (res.data[0].riderContract || res.data[0].riderContractLink)) {
          this.isThisCms = true;
          this.cmsData = res.data[0].riderContract;
          this.pdfData = res.data[0].riderContractLink;
        } else if (this.cmsType === 'service-delivery-contracts' && (res.data[0].serviceDeliveryContract || res.data[0].serviceDeliveryContractLink)) {
          this.isThisCms = true;
          this.cmsData = res.data[0].serviceDeliveryContract;
          this.pdfData = res.data[0].serviceDeliveryContractLink;
        } else if (this.cmsType === 'driver-contracts' && (res.data[0].driverContract || res.data[0].driverContractLink)) {
          this.isThisCms = true;
          this.cmsData = res.data[0].driverContract;
          this.pdfData = res.data[0].driverContractLink;
        } else {
          this.latitude = 0;
          this.longitude = 0;
          this.getCmsData();
        }
      } else {
        this.latitude = 0;
        this.longitude = 0;
        this.getCmsData();
      }
      console.log(this.cmsData, 'test');
    }, err => this.ngxService.stop())
  }

  getCmsData() {
    if (this.latitude === 0 && this.longitude === 0) {
      this.ngxService.start();
      this.http.get(environment.baseUrl + 'admin/getCms').subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.ngxService.stop();
          if (this.cmsType === 'privacy-policy' && (res.data[0].privacyPolicy || res.data[0].privacyPolicyLink)) {
            this.cmsData = res.data[0].privacyPolicy;
            this.pdfData = res.data[0].privacyPolicyLink;
          } else if (this.cmsType === 'contact-support' && (res.data[0].contactSupport || res.data[0].contactSupportLink)) {
            this.isThisCms = true;
            this.cmsData = res.data[0].contactSupport;
            this.pdfData = res.data[0].contactSupportLink;
          } else if (this.cmsType === 'legal' && (res.data[0].legal || res.data[0].legalLink)) {
            this.isThisCms = true;
            this.cmsData = res.data[0].legal;
            this.pdfData = res.data[0].legalLink;
          } else if (this.cmsType === 'FAQs' && (res.data[0].faqs || res.data[0].faqsLink)) {
            this.isThisCms = true;
            this.cmsData = res.data[0].faqs;
            this.pdfData = res.data[0].faqsLink;
          } else if (this.cmsType === 'terms-of-service' && (res.data[0].termsOfService || res.data[0].termsOfServiceLink)) {
            this.isThisCms = true;
            this.cmsData = res.data[0].termsOfService;
            this.pdfData = res.data[0].termsOfServiceLink;
          } else if (this.cmsType === 'rider-contracts' && (res.data[0].riderContract || res.data[0].riderContractLink)) {
            this.isThisCms = true;
            this.cmsData = res.data[0].riderContract;
            this.pdfData = res.data[0].riderContractLink;
          } else if (this.cmsType === 'service-delivery-contracts' && (res.data[0].serviceDeliveryContract || res.data[0].serviceDeliveryContractLink)) {
            this.isThisCms = true;
            this.cmsData = res.data[0].serviceDeliveryContract;
            this.pdfData = res.data[0].serviceDeliveryContractLink;
          } else if (this.cmsType === 'driver-contracts' && (res.data[0].driverContract || res.data[0].driverContractLink)) {
            this.isThisCms = true;
            this.cmsData = res.data[0].driverContract;
            this.pdfData = res.data[0].driverContractLink;
          } else {
            this.latitude = 0;
            this.longitude = 0;
            // this.router.navigateByUrl('/homepage');
          }
        }
      }, err => this.ngxService.stop());
    }
  }

  onProgress(event) {
    this.ngxService.start();
  }
  callBackFn(event) {
    this.ngxService.stop();
  }

}

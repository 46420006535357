import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ParentService } from 'src/app/services/parent/parent.service';
import { environment } from 'src/environments/environment';
import { CommonService } from '../services/common/common.service';

@Component({
  selector: 'app-ufooter',
  templateUrl: './ufooter.component.html',
  styleUrls: ['./ufooter.component.scss']
})
export class UfooterComponent implements OnInit {
  currentYear: number;

  constructor(
    public router: Router,
    private commonService: CommonService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    var d = new Date();
    this.currentYear = d.getFullYear();
  }

  scrollUp(id) {
    localStorage.whyU2go = true;
    if (this.router.url != '/uhomepage') {
      this.router.navigate(['/uhomepage']);
      this.commonService.setScrollOnNavigation(true);
    } else {
      this.commonService.setScrollOnNavigation(true);
    }
  }

  scrollOnSamePage() {
    location.reload();
  }

  goToTracking() {
    if (localStorage.length > 0) {
      if (localStorage.getItem('accessToken') !== undefined) {
        this.router.navigate(['/profilesetup']);
      } else {
        this.router.navigate(['/riderlogin']);
      }
    } else {
      this.router.navigate(['/riderlogin']);
    }
  }

  goToFbPage() {
    window.open('https://www.facebook.com/u2gocanada', '_blank');
  }
  goToInstaPage() {
    window.open('https://www.instagram.com/', '_blank');
  }
  goToTwitterPage() {
    window.open('https://twitter.com/explore', '_blank');
  }
  goToLinkedInPage() {
    window.open('https://www.linkedin.com/company/u2go-enterprises-inc/', '_blank');
  }

  goToCmsPages(url) {
    window.open(environment.webUrl + url, '_blank');
  }

}
